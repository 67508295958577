import React, {useState} from 'react';
import styled from 'styled-components/macro';

import validationHelper from 'helpers/validationHelper';

import FormHeader from 'components/common/FormHeader';
import TextInput from 'components/common/TextInput';
import Flex from 'components/common/Flex';
import Button from 'components/common/Button';

import extras from '../extras';

import * as Styled from 'styles/modal';
import {buttonRightMargin, authFont} from 'styles/shared';

const StyledText = styled.div`
  ${authFont};
  margin-top: 2.4rem;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 0;
`;

const StyledButtonsRow = styled(Flex)`
  margin-top: 2rem;
`;

interface Props {
  visible: boolean;
  user: User;
  close: () => void;
  onChangePassword: (password: string) => void;
}

function ChangePasswordModal({visible, user, close, onChangePassword}: Props) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({password: '', confirmPassword: ''});

  function formIsValid() {
    let newErrors = {
      password: '',
      confirmPassword: ''
    };

    if (!password) {
      newErrors.password = 'Password field is required.';
    } else if (password.length < 8) {
      newErrors.password = 'This value is too short. It should have 8 characters or more.';
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = 'Confirm password field is required.';
    }

    if (password && confirmPassword && password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match.';
    }

    setErrors(newErrors);

    return validationHelper.isEmptyErrorObject(newErrors);
  }

  async function createNewPassword(e) {
    if (e) e.preventDefault();

    if (!formIsValid()) return;

    onChangePassword(password);
  }

  function render() {
    const receiver = extras.getReceiverInfo(user);

    return (
      <Styled.CustomModal show={visible} backdrop="static" width="50.1rem" onHide={close}>
        <FormHeader>Change Password</FormHeader>

        <StyledText>
          You are changing the password for <b>{receiver}.</b> Passwords should be a minimum of 8 characters.
        </StyledText>

        <StyledTextInput
          name="password"
          label="Password"
          type="password"
          value={password}
          placeholder="********"
          onChange={(field, value) => setPassword(value)}
          error={errors.password}
        />

        <StyledTextInput
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          value={confirmPassword}
          onChange={(field, value) => setConfirmPassword(value)}
          placeholder="********"
          error={errors.confirmPassword}
        />

        <StyledButtonsRow justify="center">
          <Button type="secondary" autoWidth margin={buttonRightMargin} onClick={close}>
            Cancel
          </Button>
          <Button autoWidth onClick={createNewPassword}>
            Change Password
          </Button>
        </StyledButtonsRow>
      </Styled.CustomModal>
    );
  }

  return render();
}

export default ChangePasswordModal;

export const LOAD_PAYMENT_METHODS = 'payment/LOAD_PAYMENT_METHODS';
export const MODIFY_CREDIT_CARD = 'payment/MODIFY_CREDIT_CARD';
export const MODIFY_ACH_ACCOUNT = 'payment/MODIFY_ACH_ACCOUNT';
export const MODIFY_BILLING_ADDRESS = 'payment/MODIFY_BILLING_ADDRESS';
export const SELECT_BILLING_ADDRESS = 'payment/SELECT_BILLING_ADDRESS';
export const UPDATE_PAYMENT_ID = 'payment/UPDATE_PAYMENT_ID';
export const CHANGE_PAYMENT_METHOD = 'payment/CHANGE_PAYMENT_METHOD';
export const SET_PAYMENT_ERRORS = 'payment/SET_PAYMENT_ERRORS';
export const CLEAR_PAYMENT_STATE = 'payment/CLEAR_PAYMENT_STATE';
export const CHANGE_PAYMENT_TIME = 'payment/CHANGE_PAYMENT_TIME';
export const MODIFY_BILLING_CONTACT_INFORMATION = 'payment/MODIFY_BILLING_CONTACT_INFORMATION';
export const CLEAR_CREDIT_CARD_AND_ACH_ACCOUNT = 'payment/CLEAR_CREDIT_CARD_AND_ACH_ACCOUNT';

import React from 'react';
import styled from 'styled-components/macro';

import {colors, mainFont} from 'styles/shared';

const StyledLabel = styled.div`
  color: ${props => (props.disabled ? colors.grey_main : colors.darkBlue_main)};
  margin-bottom: 0.8rem;
  ${mainFont};
`;

const StyledSplat = styled.span`
  color: ${props => (props.disabled ? colors.grey_main : colors.orange_main)};
`;

interface Props extends BaseProps {
  required?: boolean;
  extraLabel?: string;
  disabled?: boolean;
  id: string;
}

FormLabel.defaultProps = {
  id: '',
  className: '',
  disabled: false
};

function FormLabel({required, extraLabel, disabled, children, id, className}: Props) {
  return (
    <StyledLabel id={id} className={className} disabled={disabled}>
      <strong>{children}</strong>
      {required && <StyledSplat disabled={disabled}>*</StyledSplat>}
      {extraLabel && <StyledSplat> {extraLabel}</StyledSplat>}
    </StyledLabel>
  );
}

export default FormLabel;

import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import draftActions from 'actions/draftActions';

import PrepareShipmentPage from '../prepare_shipment/PrepareShipmentPage';

interface EditDraftPageParams {
  id: string;
}

function EditDraftPage() {
  const dispatch = useDispatch();

  const {id: draftId} = useParams<EditDraftPageParams>();

  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    preloadDraft();
  }, []);

  async function preloadDraft() {
    let isSuccess: any = await dispatch(draftActions.loadDraft(draftId));

    if (isSuccess) setReadyToRender(true);
  }

  if (!readyToRender) return null;

  return <PrepareShipmentPage />;
}

export default EditDraftPage;

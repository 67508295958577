import React, {useState} from 'react';
import styled, {css} from 'styled-components/macro';

import userInfoService from 'services/userInfoService';

import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';
import Button from 'components/common/Button';
import CheckBoxInput from 'components/common/CheckboxInput';
import ShipmentsTable from '../../ShipmentsTable';

import ItemContainerMobile from 'components/view_history/mobile/components/ItemContainerMobile';
import ShipmentRowMobile from 'components/view_history/mobile/components/ShipmentRowMobile';

import {colors, mediaQueries, opacity, middleFont} from 'styles/shared';

import * as StyledModal from 'styles/modal';

const StyledHeader = styled(Flex)`
  padding: 2.6rem;
`;

const StyledTitle = styled.div`
  font-weight: bold;
  color: ${colors.darkBlue_main};
  ${middleFont};
`;

const StyledButtonsRow = styled(Flex)`
  margin: 2rem 0;

  @media ${mediaQueries.mobileMax} {
    box-shadow: 0 0 0.4rem ${opacity(0.15)};
    border-radius: 0 0 0.4rem 0.4rem;
    padding: 2rem 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    background: ${colors.white};
  }
`;

const StyledMobileBody = styled.div`
  overflow-y: auto;
  margin-bottom: 8.6rem;
  padding: 0 1rem;
`;

const StyledCheckboxContainer = styled.div`
  padding: 1rem 0 1rem 0.8rem;
  background: ${colors.grey_concrete};
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 1rem;

  ${props =>
    props.active &&
    css`
      background: ${colors.bg_orange_accent};
    `}
`;

interface Props {
  visible: boolean;
  pickupShipments: Shipment[];
  close: () => void;
  onSave: (checkedShipments: string[]) => void;
}

function EditShipmentsModal({visible, pickupShipments, close, onSave}: Props) {
  const [checkedShipments, setCheckedShipments] = useState<string[]>([]);

  function onCheckboxClick(checkedId) {
    const index = checkedShipments.findIndex(id => id === checkedId);

    if (index === -1) {
      setCheckedShipments(prevArray => [...prevArray, checkedId]);
    } else {
      setCheckedShipments(checkedShipments.filter(id => id !== checkedId));
    }
  }

  function renderShipmentsMobile() {
    return (
      <StyledMobileBody>
        {pickupShipments.map((shipment: Shipment) => {
          const id = shipment.id;

          const isChecked = checkedShipments.find(i => i === id) ? true : false;

          return (
            <ItemContainerMobile key={id}>
              <StyledCheckboxContainer active={isChecked}>
                <CheckBoxInput name={id} value={isChecked} onToggle={onCheckboxClick} />
              </StyledCheckboxContainer>

              <ShipmentRowMobile shipment={shipment} />
            </ItemContainerMobile>
          );
        })}
      </StyledMobileBody>
    );
  }

  function render() {
    let disabled = true;

    const isChecked = Object.keys(checkedShipments).find(key => {
      return checkedShipments[key];
    });

    if (isChecked) disabled = false;

    const isMobile = userInfoService.isMobile();

    return (
      <StyledModal.CustomModal show={visible} width="100rem" height={isMobile && '60.9rem'} padding="0" onHide={close}>
        <StyledHeader justify="space-between">
          <StyledTitle>Shipments in this Pickup</StyledTitle>
          <CloseButton onClick={close} />
        </StyledHeader>

        {isMobile ? (
          renderShipmentsMobile()
        ) : (
          <ShipmentsTable shipments={pickupShipments} checkedItems={checkedShipments} onCheck={onCheckboxClick} />
        )}

        <StyledButtonsRow justify="center">
          <Button autoWidth disabled={disabled} onClick={() => onSave(Object.keys(checkedShipments))}>
            Remove From Pickup
          </Button>
        </StyledButtonsRow>
      </StyledModal.CustomModal>
    );
  }

  return render();
}

export default EditShipmentsModal;

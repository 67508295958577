import React from 'react';

function PurposeOfShipmentTooltip() {
  return (
    <span>
      This question helps determine the <strong>type of customs</strong> <br />
      invoice that will be generated for the shipment. Dutiable <br />
      shipments will most commonly be considered <strong>commercial</strong>, <br />
      meaning that there is a point of sale between the shipper and <br />
      receiver. However, if the commodity being shipped is a <strong>Gift</strong>, <br />
      <strong>Sample</strong>, or <strong>Return</strong>/<strong>Repair</strong> - it will be considered{' '}
      <strong>Pro Forma</strong>.
    </span>
  );
}

export default PurposeOfShipmentTooltip;

import React, {useState, useEffect} from 'react';
import {Row, Col} from 'components/bootstrap';
import {useDispatch} from 'react-redux';
import styled from 'styled-components/macro';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';
import shipmentOptionsActions from 'actions/shipmentOptionsActions';

import UNIT from 'constants/units';
import MEASUREMENT_SYSTEM from 'constants/measurementSystems';

import uiHelper from 'helpers/uiHelper';
import validationHelper from 'helpers/validationHelper';
import unitHelper from 'helpers/unitHelper';

import Button from 'components/common/Button';
import AppIcon from 'components/common/AppIcon';
import QuestionMark from 'components/common/Marks/QuestionMark';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import SystemUnitsToggler from 'components/common/SystemUnitsToggler';

import {colors, mainFont, secondaryFont} from 'styles/shared';

const StyledDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
`;

const StyledMonths = styled.div`
  max-width: 26rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 3.2rem;
`;

const StyledMonth = styled.div`
  font-weight: bold;
  color: ${colors.darkBlue_main};
  ${mainFont};
`;

const StyledIconContainer = styled.div`
  cursor: pointer;
`;

const StyledWeekdays = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const StyledWeekday = styled.div`
  font-weight: bold;
  color: ${colors.darkBlue_main};
  ${mainFont};
  width: calc(100% / 7);
  text-align: center;
`;

const StyledDays = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const StyledDayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% / 7);
  flex-basis: calc(100% / 7);
  margin-bottom: 1rem;
  height: 4rem;
`;

const StyledDay = styled.div`
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.selected ? colors.white : props.isToday ? colors.orange_main : colors.darkBlue_main)};
  border: ${props => (props.isToday ? `0.1rem solid ${colors.orange_main}` : 'none')};
  border-radius: 50%;
  background-color: ${props => (props.selected ? colors.orange_main : 'none')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${secondaryFont};
  color: ${props => props.disabled && colors.darkBlue_light};

  &:hover {
    background-color: ${props => !props.disabled && !props.selected && !props.isToday && colors.grey_concrete};
    border-radius: 50%;
  }
`;

const StyledEvent = styled.div`
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  content: ' ';
  display: block;
  background: ${props => (props.active ? colors.orange_main : colors.darkBlue_light)};
  margin-top: 0.3rem;
`;

function UISpecPage() {
  const dispatch = useDispatch();

  const currencyOptions = useSelector((state: AppState) => state.shipmentOptions.currencyOptions);

  const weekdays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const measurementSystemOptions: BasicOption[] = unitHelper.getMeasurementSystemOptions();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [doCrash, setDoCrash] = useState(false);
  const [currency, setCurrency] = useState(UNIT.CURRENCY_USD);
  const [measurementsSystem, setMeasurementsSystem] = useState(MEASUREMENT_SYSTEM.IMPERIAL);

  useEffect(() => {
    if (isEmpty(currencyOptions)) dispatch(shipmentOptionsActions.loadOptionsData());
  }, []);

  let days: number[] = [];
  while (days.length < 42) {
    for (let i = 1; i < 32; i++) {
      if (days.length === 42) break;

      days.push(i);
    }
  }

  function handleConfirmAction() {
    dispatch(
      commonActions.confirmAction({
        title: 'A you sure you want to do some action.',
        action: () => {
          uiHelper.showNotice('On action');
        },
        close: () => {
          uiHelper.showNotice('On close');
        }
      })
    );
  }

  function handleNotice() {
    uiHelper.showNotice('Some useful information for a user about some important stuff. No Action.');
  }

  function handleInfo() {
    dispatch(
      commonActions.infoAction({
        text:
          'If you would like a FedEx Quote, please contact our customer service',
        type: 'info',
        close: () => {
          uiHelper.logMessage('On close');
        }
      })
    );
  }

  function handleWarning() {
    dispatch(
      commonActions.infoAction({
        text: 'Some useful information for a user about some important stuff. Action Required.',
        type: 'warning',
        close: () => {
          uiHelper.logMessage('On close');
        }
      })
    );
  }

  function handleIssue() {
    dispatch(
      commonActions.infoAction({
        text:
          'The destination location is invalid. Please updated with valid information.  If the problem persists, please contact customer service. ',
        type: 'issue',
        close: () => {
          uiHelper.logMessage('On close');
        },
        action: () => {
          uiHelper.logMessage('On action');
        }
      })
    );
  }

  function handleError() {
    dispatch(
      commonActions.infoAction({
        text:
          'Attention, Your account number seems to be expired! Please enter your new account number or contact customer suport.',
        type: 'error',
        close: () => {
          uiHelper.logMessage('On close');
        },
        action: () => {
          uiHelper.logMessage('On action');
        }
      })
    );
  }

  function handleCritical() {
    dispatch(
      commonActions.infoAction({
        text: (
          <div>
            <span>Attention, your account number seems to be hacked!</span>
            <br />
            <br />
            <span>Please reach out to customer service, they will gladly help you resolve this.</span>
          </div>
        ),
        type: 'critical',
        close: () => {
          uiHelper.logMessage('On close');
        }
      })
    );
  }

  function renderDivider() {
    return (
      <>
        <br />
        <hr />
        <br />
      </>
    );
  }

  function render() {
    if (doCrash) {
      const x: any = undefined;
      // eslint-disable-next-line
      const y = x.z;
    }

    return (
      <div>
        <Row>
          <Col sm={3}>
            <Button onClick={uiHelper.notImplemented}>Primary Button</Button>
          </Col>
          <Col sm={3}>
            <Button disabled onClick={uiHelper.notImplemented}>
              Primary Disabled
            </Button>
          </Col>
          <Col sm={3}>
            <Button theme="error" onClick={uiHelper.notImplemented}>
              Primary Error
            </Button>
          </Col>
          <Col sm={3}>
            <Button theme="success" onClick={uiHelper.notImplemented}>
              Primary Success
            </Button>
          </Col>
        </Row>

        <br />

        <Row>
          <Col sm={3}>
            <Button type="secondary" onClick={uiHelper.notImplemented}>
              Secondary Button
            </Button>
          </Col>
          <Col sm={3}>
            <Button type="secondary" disabled onClick={uiHelper.notImplemented}>
              Secondary Disabled
            </Button>
          </Col>
          <Col sm={3}>
            <Button type="secondary" theme="error" onClick={uiHelper.notImplemented}>
              Secondary Error
            </Button>
          </Col>
        </Row>

        <br />

        <Row>
          <Col sm={3}>
            <Button type="tertiary" onClick={uiHelper.notImplemented}>
              Tertiary Button
            </Button>
          </Col>
          <Col sm={3}>
            <Button type="tertiary" disabled onClick={uiHelper.notImplemented}>
              Tertiary Disabled
            </Button>
          </Col>
          <Col sm={3}>
            <Button type="tertiary" theme="error" onClick={uiHelper.notImplemented}>
              Tertiary Error
            </Button>
          </Col>
        </Row>

        {renderDivider()}

        <Row>
          <Col lg={3}>
            <SystemUnitsToggler
              name="currency"
              label="Currency"
              title="Select Currency"
              value={currency}
              options={currencyOptions}
              onChange={(field, value) => setCurrency(value)}
            />
          </Col>
          <Col lg={3}>
            <SystemUnitsToggler
              name="measurementSystem"
              label="Measurement system"
              title="Select System"
              value={measurementsSystem}
              options={measurementSystemOptions}
              onChange={(field, value) => setMeasurementsSystem(value)}
            />
          </Col>
        </Row>

        {renderDivider()}

        <h1>System Messages</h1>
        <br />
        <br />

        <Row>
          <Col>
            <Button onClick={handleConfirmAction}>Confirm Action</Button>
          </Col>
        </Row>

        <br />

        <Row>
          <Col sm={2}>
            <Button theme="success" onClick={handleNotice}>
              Show Notice
            </Button>
          </Col>

          <Col sm={2}>
            <Button onClick={handleInfo}>Show Info</Button>
          </Col>

          <Col sm={2}>
            <Button onClick={handleWarning}>Show Warning</Button>
          </Col>
          <Col sm={2}>
            <Button theme="error" onClick={handleIssue}>
              Show Issue
            </Button>
          </Col>
          <Col sm={2}>
            <Button theme="error" onClick={handleError}>
              Show Error
            </Button>
          </Col>
          <Col sm={2}>
            <Button theme="error" onClick={handleCritical}>
              Show Critical
            </Button>
          </Col>
        </Row>

        {renderDivider()}

        <Row>
          <Col sm={6}>
            <StyledDatePicker>
              <StyledMonths>
                <StyledIconContainer>
                  <AppIcon name="left-arrow" />
                </StyledIconContainer>

                <StyledMonth>JUL 2020</StyledMonth>

                <StyledIconContainer>
                  <AppIcon name="right-arrow" />
                </StyledIconContainer>
              </StyledMonths>

              <StyledWeekdays>
                {weekdays.map(day => {
                  return <StyledWeekday key={day}>{day}</StyledWeekday>;
                })}
              </StyledWeekdays>

              <StyledDays>
                {days.map((day, i) => {
                  let isToday = i === 10;

                  let selected = i === 13;

                  let disabled = i < 5;

                  let event = i === 13 || i === 12;

                  return (
                    <StyledDayContainer key={i}>
                      <StyledDay isToday={isToday} selected={selected} disabled={disabled}>
                        {day}
                      </StyledDay>

                      {event && <StyledEvent active={selected} />}
                    </StyledDayContainer>
                  );
                })}
              </StyledDays>
            </StyledDatePicker>
            <div>
              <QuestionMark id="demo-tooltip" tooltip="Some demo tooltip"></QuestionMark>
            </div>

            <div style={{margin: 40}}>
              <PhoneNumberInput
                name="phoneNumber"
                label="Telephone Number"
                required={true}
                countryCode={'us'}
                value={phoneNumber}
                onChange={(name, value) => {
                  setPhoneNumber(value);
                }}
              />
              <Button
                onClick={() => {
                  const isValid: boolean = validationHelper.isValidPhoneNumber(phoneNumber);
                  if (isValid) {
                    uiHelper.showNotice('Valid!');
                  } else {
                    handleError();
                  }
                }}>
                Validate phone
              </Button>
              <div style={{marginTop: 40}}>
                <Button
                  onClick={() => {
                    setDoCrash(true);
                  }}>
                  Do Crash
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return render();
}

export default UISpecPage;

import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="34" fill="none" viewBox="0 0 26 34">
      <path stroke={colors.darkBlue_main} d="M21 6V3a2 2 0 00-2-2H3a2 2 0 00-2 2v26a2 2 0 002 2h3"></path>
      <rect width="20" height="28" x="5.5" y="5.5" stroke={colors.darkBlue_main} rx="1.5"></rect>
      <rect width="15" height="1" x="8" y="9" fill={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="15" height="1" x="8" y="13" fill={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="15" height="1" x="8" y="17" fill={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="15" height="1" x="8" y="21" fill={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="7" height="1" x="8" y="25" fill={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="5" height="4" x="17.5" y="25.5" stroke={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="7" height="1" x="8" y="29" fill={colors.darkBlue_main} rx="0.5"></rect>
    </svg>
  );
}

export default Icon;

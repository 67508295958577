import styled, {css} from 'styled-components/macro';

import Container from 'components/common/Container';

import {errorInfoTitle, mediaQueries, largeFont, pageTitle, colors, opacity} from 'styles/shared';

export const containerStandalone = styled(Container)`
  margin-bottom: 2rem;
  padding: 0 10rem 0 6rem;

  & img {
    max-height: 33.2rem;
  }

  @media ${mediaQueries.mobileMax} {
    padding: 1.6rem 1.6rem 0;

    > div {
      flex-direction: column;
    }
  }
`;

export const containerDefault = styled.div`
  margin-bottom: 2rem;
  padding-left: 2.6rem;
  background: ${colors.white};
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};

  & img {
    max-height: 25rem;
  }

  @media ${mediaQueries.mobileMax} {
    padding: 1.6rem 1.6rem 0;

    > div {
      flex-direction: column;
    }
  }
`;

export const content = styled.div`
  ${props =>
    props.isStandaloneQuote &&
    css`
      max-width: 41.5rem;
      height: 100%;
    `}
`;

export const title = styled.div`
  ${props =>
    props.isStandaloneQuote
      ? css`
          ${errorInfoTitle};

          @media ${mediaQueries.mobileMax} {
            margin-bottom: 1rem;
          }
        `
      : css`
          ${pageTitle};
          margin: 0 0 1rem;

          @media ${mediaQueries.mobileMax} {
            margin: 0 0 1rem;
          }
        `}
`;

export const text = styled.div`
  ${largeFont};
`;

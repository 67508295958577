import React from 'react';

function OptionalChargesTooltip() {
  return (
    <span>
      Any additional charges you would like to add <br />
      to your invoice. <br />
      <i>
        Examples: Shipping Cost; Packing costs; <br /> Insurance; processing fees, etc.
      </i>
    </span>
  );
}

export default OptionalChargesTooltip;

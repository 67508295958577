export const LOAD_DRAFTS = 'draft/LOAD_DRAFTS';
export const CHANGE_DRAFTS_PAGE = 'draft/CHANGE_DRAFTS_PAGE';
export const LOAD_RATES = 'draft/LOAD_RATES';
export const LOAD_RATE = 'draft/LOAD_RATE';

//draft pickup
export const LOAD_DRAFT_PICKUPS = 'draft/LOAD_DRAFT_PICKUPS';
export const SET_PICKUP_DATA_TO_SAVE = 'draft/SET_PICKUP_DATA_TO_SAVE';
export const PICKUP_VALIDATE = 'draft/PICKUP_VALIDATE';
export const CHECK_PICKUP_CAPABILITY = 'draft/CHECK_PICKUP_CAPABILITY';

//draft
export const DRAFT_CREATE_NEW = 'draft/DRAFT_CREATE_NEW';
export const DRAFT_CREATE_FROM_ORDER = 'draft/DRAFT_CREATE_FROM_ORDER';
export const DRAFT_CREATE_FROM_SHIPMENT = 'draft/DRAFT_CREATE_FROM_SHIPMENT';
export const DRAFT_CREATE_FROM_QUOTE = 'draft/DRAFT_CREATE_FROM_QUOTE';
export const DRAFT_EDIT_EXISTING = 'draft/DRAFT_EDIT_EXISTING';
export const DRAFT_UPLOAD_FROM_LOCAL_STORAGE = 'draft/DRAFT_UPLOAD_FROM_LOCAL_STORAGE';
export const DRAFT_CANCEL = 'draft/DRAFT_CANCEL';
export const MODIFY_DRAFT = 'draft/MODIFY_DRAFT';
export const CLEAN_DRAFT = 'draft/CLEAN_DRAFT';
export const SET_DRAFT_ADDRESS_ERRORS = 'draft/SET_DRAFT_ADDRESS_ERRORS';
export const SET_DRAFT = 'draft/SET_DRAFT';
export const DRAFT_VALIDATE = 'draft/validate';
export const CLEAR_DRAFT_STATE = 'draft/CLEAR_DRAFT_STATE';

//steps
export const DRAFT_GO_TO_PREVIOUS_STEP = 'draft/DRAFT_GO_TO_PREVIOUS_STEP';
export const DRAFT_GO_TO_SECTION = 'draft/DRAFT_GO_TO_SECTION';
export const DRAFT_GO_TO_NEXT_VALID_STEP = 'draft/DRAFT_GO_TO_NEXT_VALID_STEP';
export const DRAFT_GO_TO_STEP = 'draft/DRAFT_GO_TO_STEP';

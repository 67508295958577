import React from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';

import FormLabel from 'components/common/FormLabel';
import TextInput from 'components/common/TextInput';
import RadioButton from 'components/common/RadioInput/RadioButton';
import RadioLabel from 'components/common/RadioInput/RadioLabel';
import ActionLink from 'components/common/ActionLink';
import Flex from 'components/common/Flex';

import {colors, opacity} from 'styles/shared';

const PADDING_OVERRIDE = '0.4rem';

const StyledContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};
  border-radius: 0.6rem;
  margin-bottom: 2rem;
  padding: 2rem 2.6rem 2.9rem;
`;

const StyledInput = styled(TextInput)`
  min-height: auto;
  margin: 0;
`;

const StyledRow = styled(Row)`
  margin-right: -${PADDING_OVERRIDE};
  margin-left: -${PADDING_OVERRIDE};
`;

const StyledCol = styled(Col)`
  padding-right: ${PADDING_OVERRIDE};
  padding-left: ${PADDING_OVERRIDE};
`;

const StyledSectionSeparator = styled.div`
  height: 3.2rem;
`;

const StyledSectionFooter = styled.div`
  margin-top: 2.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledRadioLabel = styled(RadioLabel)`
  margin-bottom: 0;
`;

const StyledActionLink = styled(ActionLink)`
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
  color: ${props => (props.disabled ? colors.grey_main : colors.orange_main)};

  &:hover {
    text-decoration: ${props => (props.disabled ? 'none' : 'underline')};
  }
`;

interface Props {
  address: any;
  index: number;
  onChange: (index: number, field: string, value: any) => void;
  onRemove: (index: number) => void;
}

function AddressSection({address, index, onChange, onRemove}: Props) {
  let radioId = `isDefaultAddress-${index}`;

  let disabled = address.isDefaultAddress;

  function removeAddress() {
    if (disabled) return;

    onRemove(index);
  }

  return (
    <StyledContainer>
      <FormLabel>Shipping Origin Address</FormLabel>

      <StyledInput
        name="addressLine1"
        placeholder="Address line 1"
        value={address.addressLine1}
        onChange={(field, value) => onChange(index, field, value)}
      />

      <StyledInput
        name="addressLine2"
        placeholder="Address line 2"
        value={address.addressLine2}
        onChange={(field, value) => onChange(index, field, value)}
      />

      <StyledInput
        name="addressLine3"
        placeholder="Address line 3"
        value={address.addressLine3}
        onChange={(field, value) => onChange(index, field, value)}
      />

      <StyledRow>
        <StyledCol lg={6}>
          <StyledInput
            name="postalCode"
            placeholder="Postal Code"
            value={address.postalCode}
            onChange={(field, value) => onChange(index, field, value)}
          />
        </StyledCol>
        <StyledCol lg={6}>
          <StyledInput
            name="city"
            placeholder="City"
            value={address.city}
            onChange={(field, value) => onChange(index, field, value)}
          />
        </StyledCol>
      </StyledRow>

      <StyledSectionSeparator />

      <StyledInput
        name="addressNickname"
        label="Address Nickname (optional)"
        placeholder="eg. Shop1, Shop2"
        value={address.addressNickname}
        onChange={(field, value) => onChange(index, field, value)}
      />

      <StyledSectionFooter>
        <Flex align="center">
          <RadioButton
            id={radioId}
            name="isDefaultAddress"
            isChecked={address.isDefaultAddress}
            value={address.isDefaultAddress}
            onChange={(field, value) => onChange(index, field, !value)}
          />
          <StyledRadioLabel id={radioId} label="This is my Default Address" />
        </Flex>

        <StyledActionLink title="Delete" disabled={disabled} onClick={removeAddress} />
      </StyledSectionFooter>
    </StyledContainer>
  );
}

export default AddressSection;

let useStubs = false;
let useLocalErrorTracking = false;
let isDevLocal = window['isLocalhost'];

const API_URL_BY_ENV = {
  stubs_local: 'http://localhost:5000',
  stubs: '',
  qa: 'https://api.qa-h47ppbq-wpnpfgzezhaus.us-2.platformsh.site',
  staging: 'https://api.staging2-5zxmgzy-wpnpfgzezhaus.us-2.platformsh.site/',
  production: 'https://api.platform.optimalship.com/'
};

let API_ENV = isDevLocal && useStubs ? 'stubs_local' : process.env.REACT_APP_API_ENV ?? '';
if (API_ENV === 'stubs') useStubs = true;

let isProduction = !isDevLocal && API_ENV === 'production';
let isProductionOrStaging = !isDevLocal && (API_ENV === 'production' || API_ENV === 'staging');

let baseUrl = API_URL_BY_ENV[API_ENV];
baseUrl = baseUrl?.replace(/\/*$/, '');

let rollbarEnv = isDevLocal ? 'development' : API_ENV;

export default {
  isDevLocal,
  isProduction,
  isProductionOrStaging,
  useStubs,
  baseUrl,
  baseNodeUrl: isDevLocal ? API_URL_BY_ENV.stubs_local : '',
  supportUrl: 'customerservice@optimalship.com',
  supportPhone: {
    label: '(972) 383-9901',
    value: '9723839901'
  },
  supportQuoteUrl: 'quotedesk@optimalship.com',
  supportQuotePhone: {
    label: '(603) 555-0123',
    value: '6035550123'
  },
  supportDomesticQuoteExceedsSizeLimitUrl: 'usaship@optimalship.com',
  commodityCodeUrl: 'https://uscensus.prod.3ceonline.com/ui/',
  portalPayment: 'https://portal.optimalship.com/dashboard',
  portalChangePassword: 'https://portal.optimalship.com/dashboard/account',
  extendedAreaLocationUrl: 'https://www.ups.com/us/en/shipping/surcharges/delivery-extended-area.page',
  dhlTrackingNumberUrl: 'https://www.dhl.com/us-en/home/tracking/tracking-express.html',
  format: {
    shortDate: 'dd MMM yyyy',
    time: 'h:mma',
    dayDate: 'E, dd MMM, yyyy',
    deliveryDate: "E MM/dd 'by' h:mm a",
    estimatedDeliveryDate: 'MMM do',
    estimatedDeliveryDateDomestic: "MMM do 'by' ha",
    dayMonth: 'dd MMM',
    monthDay: 'MMM dd',
    datePicker: 'yyyy-MM-dd',
    apiFormat: "yyyy-MM-dd'T'00:00:00.000",
    dayOfWeek: 'EEEE'
  },
  rollbar: {
    enabled: useLocalErrorTracking || !isDevLocal,
    accessToken: isDevLocal ? 'fd4d000fc42240afaaf16488e3cf8d2a' : '9ff5d7a4992e47a1b8e5f37a0d91f9a6',
    payload: {
      environment: rollbarEnv
    }
  },
  intercom: {
    appId: isProduction ? 'jv8aleo1' : 'p0jel25w'
  }
};

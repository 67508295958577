import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {Row, Col} from 'components/bootstrap';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';

import { AppState } from 'reducers';

import quoteActions from 'actions/quoteActions';

import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import PATH from 'constants/path';
import FREIGHT_SERVICE_TYPE_OPTIONS from 'constants/freightServiceTypes/freightServiceTypeOptions';

import validationHelper from 'helpers/validationHelper';
import navigationHelper from 'helpers/navigationHelper';
import uiHelper from 'helpers/uiHelper';

import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';
import FormHeader from 'components/common/FormHeader';
import TextInput from 'components/common/TextInput';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import TextAreaInput from 'components/common/TextAreaInput';
import Button from 'components/common/Button';
import DatePicker from 'components/common/DatePicker';
import SelectInput from 'components/common/SelectInput/SelectInput';

import * as Styled from 'styles/modal';
import {secondaryFont} from 'styles/shared';

const StyledFormHeader = styled(FormHeader)`
  max-width: 37rem;
  margin-bottom: 1.6rem;
`;

const StyledCloseButton = styled(CloseButton)`
  margin-top: 0.6rem;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 0;
`;

const StyledPhoneNumberInput = styled(PhoneNumberInput)`
  margin-bottom: 0;
`;

const StyledText = styled.div`
  ${secondaryFont};
  text-align: center;
  margin-bottom: 2.8rem;
`;

interface ContactData {
  contactName: string;
  countryCode: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  email: string;
  freightService: string;
  readyDate: Date;
  otherComments: string;
}

interface Props {
  visible: boolean;
  standaloneQuote: StandaloneQuote;
  isGuest?: boolean;
  close: () => void;
}

function FreightQuoteModal({visible, standaloneQuote, isGuest, close}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const isQuickAccessAuth = useSelector((state: AppState) => state.common.isQuickAccessAuth);

  const [contactData, setContactData] = useState<ContactData>({
    contactName: '',
    countryCode: COUNTRY_CODE.USA,
    phoneNumber: '',
    phoneNumberExtension: '',
    email: '',
    readyDate: new Date(),
    freightService: '',
    otherComments: ''
  });
  const [errors, setErrors] = useState({});

  function onChange(field, value) {
    setContactData({...contactData, [field]: value});
  }

  function formIsValid() {
    let newErrors = {
      phoneNumber: '',
      email: ''
    };

    if (!contactData.phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required.';
    } else if (!validationHelper.isValidPhoneNumber(contactData.phoneNumber)) {
      newErrors.phoneNumber = 'Phone number is not valid.';
    }

    if (contactData.email && !validationHelper.isValidEmail(contactData.email)) {
      newErrors.email = 'Email address is not valid.';
    }

    setErrors(newErrors);

    return validationHelper.isEmptyErrorObject(newErrors);
  }

  async function submitForQuoteAction(e) {
    if (e) e.preventDefault();

    if (!formIsValid()) return;

    const isSuccess: any = await dispatch(
      quoteActions.submitForFreightQuote(standaloneQuote, JSON.stringify(contactData))
    );

    if (!isSuccess) return;

    const link = isGuest ? PATH.GUEST_LANDING : navigationHelper.getViewHistoryPageLink();

    if (!isGuest) {
      uiHelper.showNotice(
        'You have successfully requested a freight quote! We will create it shortly and get back to you.'
      );
    }

    // Do not redirect quick access as their view history will be blank
    if (isQuickAccessAuth) {
      close();
      return;
    }

    history.push(link);
  }

  return (
    <Styled.CustomModal show={visible} backdrop="static" padding="3.2rem 2.6rem 4.4rem" onHide={close}>
      <Flex justify="space-between">
        <StyledFormHeader>We need some more details to submit your shipment for a freight quote!</StyledFormHeader>
        <StyledCloseButton onClick={close} />
      </Flex>

      <StyledTextInput
        name="contactName"
        label="Contact Name"
        value={contactData.contactName}
        onChange={onChange}
        placeholder="John Doe"
      />

      <Row>
        <Col lg={7}>
          <StyledPhoneNumberInput
            name="phoneNumber"
            label="Telephone Number"
            required={true}
            countryCode={contactData.countryCode}
            value={contactData.phoneNumber}
            onChange={onChange}
            error={get(errors, 'phoneNumber', '')}
          />
        </Col>
        <Col lg={5}>
          <StyledTextInput
            name="phoneNumberExtension"
            label="Extension"
            placeholder="000"
            value={contactData.phoneNumberExtension}
            onChange={onChange}
          />
        </Col>
      </Row>

      <StyledTextInput
        name="email"
        type="email"
        label="Email Address"
        value={contactData.email}
        onChange={onChange}
        placeholder="john.doe@gmail.com"
        error={get(errors, 'email', '')}
      />

      <DatePicker
        name="readyDate"
        label="Ready Date"
        placeholder="YYYYMMDD"
        value={contactData.readyDate}
        onChange={onChange}
      />

      <SelectInput
        name="freightService"
        label="Type of Freight Service"
        value={contactData.freightService}
        options={FREIGHT_SERVICE_TYPE_OPTIONS}
        onChange={onChange}
      />

      <TextAreaInput
        name="otherComments"
        label="Other Comments"
        placeholder="Type a comment"
        value={contactData.otherComments}
        onChange={onChange}
      />

      <StyledText>
        Your information will be sent to our freight team. Due to the nature of freight shipping, quotes may take 24-48
        hours to come back. They will reach out to you via phone or email if they need any additional information.
      </StyledText>

      <Flex justify="center">
        <Button autoWidth onClick={submitForQuoteAction}>
          Submit for Quote!
        </Button>
      </Flex>
    </Styled.CustomModal>
  );
}

export default FreightQuoteModal;

import React, {useState} from 'react';

import TabComponentMobile from './components/TabComponentMobile';
import ShipmentStatusesNeedAttentionMobile from './components/shipment_issues/ShipmentStatusesNeedAttentionMobile';
import ScheduledPickups from '../components/pickups/ScheduledPickups';
import ShipmentStatuses from '../components/statuses/ShipmentStatuses';
import Quotes from '../components/quote/Quotes';
import Drafts from '../components/drafts/Drafts';

const SHIPMENTS_THAT_NEED_ATTENTION = 'shipments_that_need_attention';
const SCHEDULED_PICKUPS = 'scheduled_pickups';
const SHIPMENT_STATUS = 'shipment_status';
const STANDALONE_QUOTES = 'standalone_quotes';
const DRAFTS = 'drafts';

interface Props {
  statusesNeedAttention: Shipment[];
  pickups: Pickup[];
  shipmentStatuses: Shipment[];
  reloadShipments: () => void;
  reloadPickups: () => void;
  quotes: Quote[];
  drafts: any[];
  onDraftListChange: () => void;
}

function ViewHistoryMobile({
  pickups,
  statusesNeedAttention,
  shipmentStatuses,
  reloadShipments,
  reloadPickups,
  quotes,
  drafts,
  onDraftListChange
}: Props) {
  const [selectedTab, setSelectedTab] = useState<string>(SCHEDULED_PICKUPS);

  const tabs: BasicOption[] = [
    {value: SHIPMENTS_THAT_NEED_ATTENTION, label: 'Shipments That Need Attention'},
    {value: SCHEDULED_PICKUPS, label: 'Scheduled Pickups'},
    {value: SHIPMENT_STATUS, label: 'Shipment Status'},
    {value: STANDALONE_QUOTES, label: 'Standalone Quotes'},
    {value: DRAFTS, label: 'Drafts'}
  ];

  function renderContent() {
    switch (selectedTab) {
      case SHIPMENTS_THAT_NEED_ATTENTION:
        return <ShipmentStatusesNeedAttentionMobile statusesNeedAttention={statusesNeedAttention} />;
      case SCHEDULED_PICKUPS:
        return <ScheduledPickups pickups={pickups} />;
      case SHIPMENT_STATUS:
        return (
          <ShipmentStatuses
            shipmentStatuses={shipmentStatuses}
            pickups={pickups}
            reloadShipments={reloadShipments}
            reloadPickups={reloadPickups}
          />
        );
      case STANDALONE_QUOTES:
        return <Quotes quotes={quotes} />;
      case DRAFTS:
        return <Drafts drafts={drafts} onDraftListChange={onDraftListChange} />;
      default:
        return null;
    }
  }

  function render() {
    return (
      <>
        <TabComponentMobile tabs={tabs} activeTab={selectedTab} onChange={(tab: string) => setSelectedTab(tab)} />

        {renderContent()}
      </>
    );
  }

  return render();
}

export default ViewHistoryMobile;

import React from 'react';
import styled from 'styled-components/macro';

import {colors, mainFont} from 'styles/shared';

const StyledStatusPanel = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => (props.success ? colors.green_light : colors.red_lighter)};
  height: 11.6rem;
  margin: 3rem 0;
  border-radius: 0.6rem;
  font-weight: bold;
  ${mainFont};

  img {
    margin-left: 2rem;
    margin-right: 3.2rem;
  }
`;

interface Props {
  isPickupExists: boolean;
}

function StatusPanel({isPickupExists}: Props) {
  let image = isPickupExists ? 'delivery-box.png' : 'empty-box.png';
  let title = isPickupExists ? 'You have successfully scheduled pickup!' : 'You have no scheduled Pickup!';

  return (
    <StyledStatusPanel success={isPickupExists}>
      <img alt="Delivery Service" src={`/images/${image}`} width={96} height={96} />
      <span>{title}</span>
    </StyledStatusPanel>
  );
}

export default StatusPanel;

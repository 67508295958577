export default {
  handleActions
};

function handleActions<TState = any>(state, action, handlers: {[key: string]: (state: TState, payload: any) => void}) {
  if (!handlers) return state;

  let handler = handlers[action.type];

  if (!handler) return state;

  let newState = {...state};

  handler(newState, action.payload);

  return newState;
}

import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';
import userActions from 'actions/userActions';

import config from 'config';
import validationHelper from 'helpers/validationHelper';
import uiHelper from 'helpers/uiHelper';
import mailHelper from 'helpers/mailHelper';

import FormHeader from 'components/common/FormHeader';
import TextInput from 'components/common/TextInput';
import Flex from 'components/common/Flex';
import Button from 'components/common/Button';
import AppIcon from 'components/common/AppIcon';
import ActionLink from 'components/common/ActionLink';

import * as Styled from 'styles/modal';
import {colors, mainFont, buttonLeftMargin, buttonLeftRightMargin, middleFont} from 'styles/shared';

const StyledFormHeader = styled(FormHeader)`
  text-align: center;
`;

const StyledIconContainer = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1.1rem;
  text-align: center;
`;

const StyledTitle = styled.div`
  ${middleFont};
  margin-bottom: 2.5rem;
  font-family: 'Nunito Sans Italic';
  text-align: center;

  > span {
    font-weight: bold;
  }
`;

const StyledInput = styled(TextInput)`
  margin-bottom: 0;
`;

const StyledDivider = styled.div`
  border-top: 0.1rem dashed ${colors.darkBlue_light};
  width: 100%;
`;

const StyledDividerText = styled.div`
  ${mainFont};
  font-family: 'Nunito Sans Italic';
  font-weight: bold;
  min-width: 4rem;
  text-align: center;
`;

const StyledButtonsRow = styled(Flex)`
  margin-top: 3.6rem;
`;

const StyledLinkRow = styled.div`
  ${mainFont};
  display: flex;
  margin-top: 1rem;
`;

interface Props {
  visible: boolean;
  close: () => void;
}

function ResetPasswordModal({visible, close}: Props) {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [errors, setErrors] = useState({email: '', accountNumber: ''});

  const [errorMessage, setErrorMessage] = useState<string>('');

  function formIsValid() {
    let newErrors = {
      email: '',
      accountNumber: ''
    };

    if (accountNumber && accountNumber.length < 6) {
      newErrors.accountNumber = 'This value is too short. It should have 6 characters or more.';
    }

    if (!validationHelper.isValidEmail(email)) {
      newErrors.email = 'Email is not valid.';
    }

    setErrors(newErrors);

    return validationHelper.isEmptyErrorObject(newErrors);
  }

  async function createNewPassword(e) {
    if (e) e.preventDefault();

    if (!formIsValid()) return;

    const response: any = await dispatch(userActions.resetPassword(accountNumber || email));
    const result = response as ResetPasswordResponse;

    if (result?.success) {
      const successMessage = result?.message || 'An email has been sent to reset your password.';
      uiHelper.showNotice(successMessage);
      close();
      return;
    }

    dispatch(
      commonActions.infoAction({
        type: 'error',
        text: result?.message || 'Something went wrong. Please reload the page and try again.',
        rollbarContext: 'Reset password email unable to be sent - API error response',
        close: () => close()
      })
    );
  }

  function callAction() {
    window.open(`tel:${config.supportPhone.value}`);
  }

  function sendEmail() {
    const supportLink = mailHelper.getHelpEmailLink();

    window.location.href = supportLink;

    close();
  }

  function startChat() {
    dispatch(commonActions.toggleIntercomChat());
    close();
  }

  function renderIcon() {
    return (
      <StyledIconContainer>
        <AppIcon name="bulb" />
      </StyledIconContainer>
    );
  }

  function renderErrorMode() {
    return (
      <>
        <StyledFormHeader>{errorMessage}</StyledFormHeader>

        {renderIcon()}

        <StyledTitle>
          If you do not remember your Import/Export Number or Email, please contact our{' '}
          <span>Customer Support Reps</span>
        </StyledTitle>

        <StyledLinkRow>
          Phone: &nbsp; <ActionLink title={config.supportPhone.label} onClick={callAction} />
        </StyledLinkRow>
        <StyledLinkRow>
          Email: &nbsp; <ActionLink title={config.supportUrl} onClick={sendEmail} />
        </StyledLinkRow>
        <StyledLinkRow>
          Chat: &nbsp; <ActionLink title="Start chat" onClick={startChat} />
        </StyledLinkRow>

        <StyledButtonsRow justify="flex-end">
          <Button type="secondary" autoWidth margin={buttonLeftRightMargin} onClick={close}>
            Cancel
          </Button>
          <Button autoWidth margin={buttonLeftMargin} onClick={() => setErrorMessage('')}>
            Back
          </Button>
        </StyledButtonsRow>
      </>
    );
  }

  function renderInitial() {
    const disabled = !email && !accountNumber;

    return (
      <>
        <StyledFormHeader>Need a new password?</StyledFormHeader>

        {renderIcon()}

        <StyledTitle>
          Please type in <span>Import/Export Number</span> or <span>Email</span> and we will send you new one!
        </StyledTitle>

        <StyledInput
          name="accountNumber"
          label="Import/Export Number"
          value={accountNumber}
          placeholder="Type in Import or Export Number"
          onChange={(field, value) => setAccountNumber(value)}
          error={errors.accountNumber}
        />

        <Flex justify="space-between" align="center">
          <StyledDivider /> <StyledDividerText>Or</StyledDividerText> <StyledDivider />
        </Flex>

        <StyledInput
          name="email"
          type="email"
          label="Email"
          value={email}
          onChange={(field, value) => setEmail(value)}
          placeholder="Type in your Email"
          error={errors.email}
        />

        <StyledButtonsRow justify="flex-end">
          <Button type="secondary" autoWidth margin={buttonLeftRightMargin} onClick={close}>
            Cancel
          </Button>
          <Button autoWidth margin={buttonLeftMargin} disabled={disabled} onClick={createNewPassword}>
            Create New Password
          </Button>
        </StyledButtonsRow>
      </>
    );
  }

  function render() {
    const isErrorMode = errorMessage ? true : false;

    return (
      <Styled.CustomModal show={visible} backdrop="static" padding="2.6rem 2.6rem 4rem" onHide={close}>
        {isErrorMode ? renderErrorMode() : renderInitial()}
      </Styled.CustomModal>
    );
  }

  return render();
}

export default ResetPasswordModal;

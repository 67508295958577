import styled from 'styled-components/macro';
import TextField from '@material-ui/core/TextField';

import inputStyles from 'styles/customization/input';
import {colors, secondaryFont} from 'styles/shared';

export const MaterialTextField = styled(TextField)`
  .MuiInputLabel-filled {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: ${colors.darkBlue_light}!important;
    transform: translate(1.2rem, 1.7rem) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(1.2rem, 0.5rem) scale(0.75);
    }
  }

  .MuiInputBase-root {
    background-color: transparent !important;
    border: 0.1rem solid
      ${props =>
        props.error ? colors.red_main : props?.className === 'isWarning' ? colors.orange_main : colors.darkBlue_light};
    border-radius: 0.6rem;
    color: ${colors.darkBlue_main};
    margin-bottom: ${props => (props.error ? '0.8rem' : 0)};

    &:hover {
      background-color: transparent;
    }
    &.Mui-focused {
      ${inputStyles.common.inputFocus};
    }
  }

  .MuiInputBase-input {
    ${secondaryFont};
    color: ${colors.darkBlue_main};
    padding-top: 2.1rem;
  }

  .MuiFilledInput-underline {
    ::before,
    ::after {
      border-bottom: none !important;
    }
  }

  .MuiInputAdornment-positionEnd {
    > p {
      ${secondaryFont};
      color: ${colors.orange_main};
      cursor: pointer;
    }
  }
`;

import React from 'react';
import styled from 'styled-components/macro';

import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';

import * as Styled from 'styles/modal';

const StyledHeader = styled(Flex)`
  margin-bottom: 2rem;
`;

const StyledIframe = styled.iframe`
  border: none;
  height: 117.5rem;
`;

interface Props {
  visible: boolean;
  close: () => void;
}

function SurveyModal({visible, close}: Props) {
  return (
    <Styled.CustomModal show={visible} backdrop="static" padding="2rem 3rem" width="64rem" onHide={close}>
      <StyledHeader justify="flex-end">
        <CloseButton onClick={close} />
      </StyledHeader>

      <StyledIframe id="iframe_id" src="/survey-form" title="Survey Form" />
    </Styled.CustomModal>
  );
}

export default SurveyModal;

import React from 'react';
import styled, {css} from 'styled-components/macro';

import validationHelper from 'helpers/validationHelper';

import SignComponent from './SignComponent';
import FormLabel from '../FormLabel';
import InputError from 'components/common/InputComponents/InputError';
import Flex from '../Flex';
import InputContainer from 'components/common/InputComponents/InputContainer';

import inputStyles from 'styles/customization/input';
import {colors} from 'styles/shared';

const StyledInput = styled.input`
  ${inputStyles.numberInput.inputField};

  ${props =>
    props.error &&
    css`
      border-color: ${colors.red_main};
    `}

  &:disabled {
    background-color: initial;
    color: ${colors.grey_main};
  }
`;

const PLUS_SIGN = 'plus';
const MINUS_SIGN = 'minus';

interface Props extends BaseProps {
  name: string;
  label: string;
  onChange: OnChangeHandler;
  value: any;
  placeholder: string;
  error?: string | ValidationError[];
  required: boolean;
  withSignButtons: boolean;
  disabled: boolean;
  autoHeight: boolean;
}

NumberInput.defaultProps = {
  required: false,
  withSignButtons: true,
  placeholder: '0',
  disabled: false,
  autoHeight: false
};

function NumberInput({
  name,
  label,
  onChange,
  value,
  error,
  required,
  withSignButtons,
  placeholder,
  className,
  disabled,
  autoHeight
}: Props) {
  let inputOnChange = e => {
    let val = e.target.value;

    val = !!val && Math.abs(val) >= 0 ? Math.abs(val) : null;

    onChange(e.target.name, val);
  };

  const inputOnBlur = e => {
    if (String(value).includes(".")) {
      value = Math.floor(value);
    }

    onChange(name, Number(value));
  };

  let onSignClick = sign => {
    if (disabled) return;

    let val = value || 0;

    if (sign === PLUS_SIGN) val++;

    if (sign === MINUS_SIGN && val !== 0) val--;

    onChange(name, val);
  };

  const inputError: string = validationHelper.getInputError(error);

  return (
    <InputContainer autoHeight={autoHeight} className={className}>
      {label && (
        <FormLabel required={required} disabled={disabled}>
          {label}
        </FormLabel>
      )}

      <Flex>
        <StyledInput
          type="number"
          name={name}
          min="0"
          step="1"
          disabled={disabled}
          placeholder={placeholder}
          value={value || ''}
          onChange={inputOnChange}
          onBlur={inputOnBlur}
        />

        {withSignButtons && (
          <Flex>
            <SignComponent icon={PLUS_SIGN} disabled={disabled} onClick={onSignClick} />
            <SignComponent icon={MINUS_SIGN} disabled={disabled} onClick={onSignClick} />
          </Flex>
        )}
      </Flex>

      {inputError && <InputError>{inputError}</InputError>}
    </InputContainer>
  );
}

export default NumberInput;

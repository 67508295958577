import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import {get} from 'lodash';

import PICKUP_OPTION from 'constants/pickupOptions';
import FEATURE from 'constants/features';

import RadioInput from 'components/common/RadioInput/RadioInput';
import DatePicker from 'components/common/DatePicker';
import DropOffComponent from './components/DropOffComponent';
import ReminderItem from './components/ReminderItem';

import {colors, secondaryFont} from 'styles/shared';

const StyledPickupLabel = styled.div`
  font-weight: bold;
  color: ${colors.black};
  margin-bottom: 2rem;
  ${secondaryFont};
`;

const StyledDroppingPickupContainer = styled.div`
  margin-bottom: 2rem;
`;

const StyledReminderLabel = styled(StyledPickupLabel)`
  margin-bottom: 1rem;
  font-weight: normal;
`;

const StyledReminderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

interface Props {
  pickupOption: string;
  pickupReminder: number;
  shipmentDate: any;
  onChange: OnChangeHandler;
  errors: any;
}

function PickupOptions({pickupOption, pickupReminder, shipmentDate, onChange, errors}: Props) {
  useEffect(() => {
    if (FEATURE.PICKUP_OPTIONS) return;

    if (!pickupOption) onChange('custom.pickupOption', PICKUP_OPTION.NOT_READY_SCHEDULE);
  }, []);

  const pickupOptions: BasicOption[] = [
    {value: PICKUP_OPTION.PERSONAL_DROPPING, label: 'Personally dropping off in nearby Store'},
    {value: PICKUP_OPTION.DAILY_ROUTINE, label: 'I have daily pickup routine'},
    {value: PICKUP_OPTION.NOT_READY_SCHEDULE, label: 'I am not ready to schedule it just yet'}
  ];

  const futurePickups: {value: number; label: string}[] = [
    {value: 1, label: '1 day'},
    {value: 2, label: '2 days'},
    {value: 7, label: '1 week'},
    {value: 14, label: '2 weeks'},
    {value: 30, label: '1 month'}
  ];

  ///Fix-API: get real stores from API
  let stores = [
    {
      name: 'Bachendorf’s - Official Rolex Jeweler',
      address: '8400 Preston Rd. Dallas, Texas 75225',
      country: 'United States',
      workingHours: {
        isOpened: true,
        openingTime: '10:00 AM',
        closingTime: '23:00 PM'
      }
    },
    {
      name: 'Spec’s Wines, Spirits & Finer Foods',
      address: '8123 Preston Rd. Dallas, Texas 75225',
      country: 'United States',
      workingHours: {
        isOpened: true,
        openingTime: '10:00 AM',
        closingTime: '18:00 PM'
      }
    },
    {
      name: 'Cebolla Fine Flowers',
      address: '4415 Lovers Ln. Dallas, Texas 75225',
      country: 'United States',
      workingHours: {
        isOpened: false,
        openingTime: '11:00 AM',
        closingTime: '17:00 PM'
      }
    },
    {
      name: 'Bachendorf’s - Official Rolex Jeweler Second',
      address: '8400 Preston Rd. Dallas, Texas 75225',
      country: 'United States',
      workingHours: {
        isOpened: true,
        openingTime: '10:00 AM',
        closingTime: '23:00 PM'
      }
    }
  ];

  stores = [];

  let showPickupReminders = false;

  return (
    <>
      <DatePicker
        name="shipmentDate"
        label="Select Shipment date"
        placeholder="YYYYMMDD"
        value={shipmentDate}
        onChange={onChange}
      />

      {FEATURE.PICKUP_OPTIONS && (
        <>
          <RadioInput
            name="custom.pickupOption"
            label="Select Pickup Option"
            required={true}
            value={pickupOption}
            options={pickupOptions}
            onChange={onChange}
            error={get(errors, 'pickupDelivery.custom.pickupOption', '')}
          />

          {pickupOption === PICKUP_OPTION.PERSONAL_DROPPING && stores.length > 0 && (
            <>
              <StyledPickupLabel>Here are some Stores nearby where you can drop off your Shipment:</StyledPickupLabel>

              <StyledDroppingPickupContainer>
                {stores.map(store => {
                  return <DropOffComponent key={store.name} store={store} />;
                })}
              </StyledDroppingPickupContainer>
            </>
          )}

          {pickupOption === PICKUP_OPTION.NOT_READY_SCHEDULE && showPickupReminders && (
            <>
              <StyledReminderLabel>Remind me to set pickup in:</StyledReminderLabel>

              <StyledReminderContainer>
                {futurePickups.map(item => {
                  const value = item.value;

                  let isActive = value === pickupReminder;

                  return (
                    <ReminderItem key={value} name="pickupReminder" item={item} active={isActive} onChange={onChange} />
                  );
                })}
              </StyledReminderContainer>
            </>
          )}
        </>
      )}
    </>
  );
}

export default PickupOptions;

import React from 'react';

function DHLTooltip() {
  return (
    <span>
      DHL typically does not allow pickup windows <br />
      before 2PM. The time window can’t be shorter <br />
      than 1h 30min. If you would like to know more <br />
      about pickups, feel free to reach out to <br />
      <strong>Customer Service</strong> at (972) 383-9901.
    </span>
  );
}

export default DHLTooltip;

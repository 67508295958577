import React from 'react';
import styled from 'styled-components/macro';

import AppIcon from 'components/common/AppIcon/AppIcon';
import Flex from 'components/common/Flex';
import MobileToggler from 'components/common/AppPage/components/navigation/components/MobileToggler';

import {colors} from 'styles/shared';

const StyledAuthLogoContainer = styled(Flex)`
  padding: 3.5rem 1.6rem 3.5rem 1rem;
  background-color: ${colors.white};
`;

interface Props {
  publicMode?: boolean;
}

function LogoHeader({publicMode}: Props) {
  return (
    <StyledAuthLogoContainer justify="space-between">
      <AppIcon name="logo" />

      {!publicMode && <MobileToggler />}
    </StyledAuthLogoContainer>
  );
}

export default LogoHeader;

import React from 'react';
import styled from 'styled-components/macro';

import Flex from 'components/common/Flex';

import {colors, mainFont} from 'styles/shared';

const StyledContainer = styled(Flex)`
  margin: 4rem 0;
`;

const StyledDivider = styled.div`
  border-top: 0.1rem dashed ${colors.darkBlue_light};
  width: 100%;
  flex: 1;
`;

const StyledText = styled.div`
  ${mainFont};
  font-family: 'Nunito Sans Italic';
  font-weight: bold;
  margin: 0 1rem;
`;

interface Props extends BaseProps {
  message?: string;
}

Divider.defaultProps = {
  message: 'Or',
  className: ''
};

function Divider({message, className}: Props) {
  return (
    <StyledContainer justify="space-between" align="center" className={className}>
      <StyledDivider /> <StyledText>{message}</StyledText> <StyledDivider />
    </StyledContainer>
  );
}

export default Divider;

export const ASYNC_ACTION_START = 'common/ASYNC_ACTION_START';
export const ASYNC_ACTION_END = 'common/ASYNC_ACTION_END';

export const TOGGLE_GLOBAL_OVERLAY = 'common/TOGGLE_GLOBAL_OVERLAY';

export const CONFIRM_ACTION = 'common/CONFIRM_ACTION';
export const CONFIRM_ACTION_CANCEL = 'common/CONFIRM_ACTION_CANCEL';

export const INFO_ACTION = 'common/INFO_ACTION';
export const INFO_ACTION_CANCEL = 'common/INFO_ACTION_CANCEL';

export const GLOBAL_MENU = 'common/GLOBAL_MENU';
export const GLOBAL_MENU_CLOSE = 'common/GLOBAL_MENU_CLOSE';

export const TOGGLE_SAVE_DRAFT_MODAL = 'common/TOGGLE_SAVE_DRAFT_MODAL';

export const TUTORIAL_INFO = 'common/TUTORIAL_INFO';
export const TUTORIAL_INFO_CLOSE = 'common/TUTORIAL_INFO_CLOSE';

export const TOGGLE_HOUSE_ACCOUNT_USAGE = 'common/TOGGLE_HOUSE_ACCOUNT_USAGE';

export const TOGGLE_INTERCOM_CHAT = 'common/TOGGLE_INTERCOM_CHAT';

export const TOGGLE_PASSWORD_PROTECTED_DATA_MODAL = 'common/TOGGLE_PASSWORD_PROTECTED_DATA_MODAL';
export const TOGGLE_RESET_PASSWORD_MODAL = 'common/TOGGLE_RESET_PASSWORD_MODAL';
export const TOGGLE_CHANGE_PASSWORD_MODAL = 'common/TOGGLE_CHANGE_PASSWORD_MODAL';

export const SET_IS_QUICK_ACCESS_AUTH = 'common/SET_IS_QUICK_ACCESS_AUTH';

export const TOGGLE_GUEST_AUTH_MODAL = 'common/TOGGLE_GUEST_AUTH_MODAL';
export const TOGGLE_GUEST_SIGN_UP_MODAL = 'common/TOGGLE_GUEST_SIGN_UP_MODAL';

export const APP_ERROR = 'common/APP_ERROR';
export const APP_MESSAGE = 'common/APP_MESSAGE';

import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  const color = colors.darkBlue_main;

  return (
    <svg width="44" height="44" fill="none" viewBox="0 0 44 44">
      <g clipPath="url(#clip0)">
        <path
          stroke={color}
          d="M29.753 32.085l.235.441-.235-.44a1.5 1.5 0 01-.991.149l-.787-.152a2.499 2.499 0 00-.95 0l-.787.152a1.5 1.5 0 01-.991-.15l-.235.441.235-.44-.707-.378a2.501 2.501 0 00-.908-.28l-.797-.087a1.5 1.5 0 01-.903-.434l-.355.352.355-.352-.564-.57a2.501 2.501 0 00-.785-.535l-.736-.317a1.5 1.5 0 01-.735-.682l-.372-.71a2.5 2.5 0 00-.592-.743l-.324.38.324-.38-.61-.52a1.5 1.5 0 01-.5-.868l-.146-.788a2.5 2.5 0 00-.347-.884l-.43-.677a1.5 1.5 0 01-.223-.978l.093-.796a2.498 2.498 0 00-.07-.947l-.211-.773a1.5 1.5 0 01.075-1l.323-.733c.13-.293.2-.607.212-.926l.026-.802a1.5 1.5 0 01.366-.933l.526-.605c.21-.241.37-.52.475-.822l.261-.758a1.5 1.5 0 01.625-.784l.68-.424c.272-.168.508-.388.697-.646l.473-.646a1.5 1.5 0 01.828-.565l.776-.204c.309-.082.599-.222.855-.413l.643-.478a1.5 1.5 0 01.958-.296l.801.034a2.5 2.5 0 00.94-.142l-.168-.47.167.47.756-.268a1.5 1.5 0 011.002 0l.756.268.166-.47-.166.47a2.5 2.5 0 00.939.142l.8-.034a1.5 1.5 0 01.959.296l.643.478c.256.191.546.331.855.412l.775.205a1.5 1.5 0 01.829.565l.473.646c.189.258.425.478.696.646l.68.424a1.5 1.5 0 01.626.784l.261.758c.105.302.266.58.475.822l.378-.328-.378.328.526.605c.225.26.354.59.366.934l.027.8c.01.32.082.634.21.927l.458-.202-.457.201.323.734a1.5 1.5 0 01.075 1l.483.131-.483-.131-.21.773c-.084.308-.108.63-.071.947l.093.796a1.5 1.5 0 01-.223.978l-.43.677a2.5 2.5 0 00-.346.884l-.146.788a1.5 1.5 0 01-.502.868l-.61.52a2.5 2.5 0 00-.591.743l-.372.71a1.5 1.5 0 01-.735.682l-.736.317.198.46-.198-.46c-.293.127-.56.309-.785.535l-.564.57a1.5 1.5 0 01-.903.434l-.797.087c-.318.034-.626.13-.908.28l-.707.377z"></path>
        <path
          fill={color}
          d="M25.012 22.947H24c.043 1.174.348 2.06.916 2.658.578.597 1.494.953 2.747 1.066V28h.626v-1.314c.471 0 .926-.066 1.366-.2.45-.124.846-.315 1.188-.572.354-.258.632-.577.836-.958.214-.392.321-.84.321-1.345 0-.546-.107-.994-.321-1.344a2.772 2.772 0 00-.852-.896 4.16 4.16 0 00-1.188-.556c-.44-.134-.89-.248-1.35-.34v-4.45c.707 0 1.28.231 1.719.695.44.453.664 1.066.675 1.838h1.012a3.197 3.197 0 00-.257-1.375 2.875 2.875 0 00-.74-1.05 3.116 3.116 0 00-1.092-.68 3.476 3.476 0 00-1.317-.248V14h-.626v1.205c-.46 0-.9.072-1.318.217a3.124 3.124 0 00-1.076.602c-.3.258-.54.572-.723.943a2.95 2.95 0 00-.257 1.252c0 .535.091.983.273 1.344.182.35.423.644.723.88.31.227.67.408 1.076.541.418.124.852.232 1.302.325v4.574c-.846-.093-1.5-.376-1.96-.85-.45-.484-.68-1.18-.691-2.086zm2.65-6.923v4.311c-.3-.072-.594-.149-.883-.231a2.935 2.935 0 01-.755-.34 1.792 1.792 0 01-.53-.556c-.128-.227-.193-.52-.193-.881 0-.402.06-.747.177-1.036.128-.298.3-.54.514-.726.225-.185.476-.32.755-.402.29-.092.594-.139.916-.139zm.627 9.859v-4.45c.31.072.627.16.948.262.321.103.61.237.867.402.257.155.466.355.627.603.171.247.257.556.257.927 0 .402-.08.747-.241 1.035a2.04 2.04 0 01-.61.696c-.247.185-.53.319-.852.401a3.992 3.992 0 01-.996.124z"></path>
        <circle
          cx="18.914"
          cy="20.728"
          r="8.5"
          fill={colors.white}
          stroke={color}
          transform="rotate(45 18.914 20.728)"></circle>
        <path stroke={color} strokeLinecap="round" d="M23.156 24.97a6 6 0 000-8.485"></path>
        <path stroke={color} d="M11.843 25.678l2.121 2.121-8.132 8.132a1.5 1.5 0 11-2.12-2.122l8.13-8.131z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill={colors.white} d="M0 0H44V44H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;

import React from 'react';
import styled from 'styled-components/macro';

import FormLabel from 'components/common/FormLabel';

import {secondaryFont, colors} from 'styles/shared';

const StyledText = styled.div`
  ${secondaryFont};
  color: ${colors.darkBlue_main};
  margin-bottom: 2rem;
`;

interface Props {
  label: string;
  value: any;
  required: boolean;
}

PickupRowDisplay.defaultProps = {
  required: true
};

function PickupRowDisplay({label, value, required}: Props) {
  return (
    <div>
      <FormLabel required={required}>{label}</FormLabel>

      <StyledText>{value}</StyledText>
    </div>
  );
}

export default PickupRowDisplay;

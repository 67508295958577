import React from 'react';

interface Props {
  items: any[];
  currencyList: any[];
  addItem: () => void;
  updateItem: (index: number, field: string, value: any) => void;
  removeItem: (index: number) => void;
}

function OrderLineItems({items, currencyList, addItem, updateItem, removeItem}: Props) {
  function render() {
    return <></>;
  }

  return render();
}

export default OrderLineItems;

import React from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';
import {isEmpty} from 'lodash';

import SummarySection from '../SummarySection';
import SummaryItem from '../../../SummaryItem';
import CommodityItem from './components/CommodityItem';

import {secondaryFont} from 'styles/shared';

const StyledTitle = styled.div`
  font-weight: 900;
  margin-bottom: 2rem;
  ${secondaryFont}
`;
interface Props {
  shipment: Shipment;
}

function InvoiceSummary({shipment}: Props) {
  const products = shipment.shipmentDetails.products;

  return (
    <SummarySection header="Custom Invoice Details">
      <Row>
        <Col lg={6}>
          <SummaryItem label="Purpose" value={products.purposeOfShipment || products.invoiceType} />
          <SummaryItem label="Invoice Nr." value={products.invoiceNumber} />
        </Col>
        <Col lg={6}>
          <SummaryItem label="3rd Party Payer Info" value={shipment.payment.dutyAccountNumber} />
        </Col>
      </Row>

      {!isEmpty(products.items) && (
        <>
          <StyledTitle>Commodity Information</StyledTitle>

          <Row>
            {products.items.map((item, index) => {
              return <CommodityItem key={index} item={item} itemNumber={index + 1} currency={shipment.currency} measurementSystem={shipment.measurementSystem} />;
            })}
          </Row>
        </>
      )}
    </SummarySection>
  );
}

export default InvoiceSummary;

import React from 'react';
import styled from 'styled-components/macro';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';
import userActions from 'actions/userActions';

import PATH from 'constants/path';

import navigationHelper from 'helpers/navigationHelper';

import authService from 'services/authService';
import stateStorageService from 'services/stateStorageService';

import ActionLink from 'components/common/ActionLink';

import {colors, mainFont, opacity, mainBorder, secondaryFont} from 'styles/shared';

const ARROW_SIZE = '0.8rem';
const ARROW_BORDER = `0.1rem solid ${opacity(0.08)}`;

const StyledContainer = styled.div`
  width: 28.7rem;
  background: ${colors.white};
  box-shadow: 0 0.2rem 0.6rem ${opacity(0.14)};
  border-radius: 0.6rem;
  position: fixed;
  right: 2.8rem;
  top: 8.6rem;
  z-index: 1200;

  &::before {
    content: '';
    display: block;
    width: ${ARROW_SIZE};
    height: ${ARROW_SIZE};
    position: absolute;
    top: calc(-${ARROW_SIZE} / 2);
    right: 2rem;
    transform: rotate(45deg);
    background: transparent;
    z-index: 1200;
    border-top: ${ARROW_BORDER};
    border-left: ${ARROW_BORDER};
    background-color: ${colors.white};
  }
`;

const StyleLinksContainer = styled.div`
  padding: 0.4rem 0 0.4rem 1.75rem;
`;

const StyledLink = styled(ActionLink)`
  padding: 1.2rem 0;
  display: block;
`;

const StyledTextContainer = styled.div`
  padding: 1.4rem 0 0.8rem 1.6rem;
  border-bottom: ${mainBorder};
  ${mainFont};
`;

const StyledName = styled.div`
  font-weight: bold;
`;

const StyledEmail = styled.div`
  padding: 0.8rem 0 0.8rem 1.6rem;
  ${secondaryFont};
  border-bottom: ${mainBorder};
`;

interface Props {
  user: User;
  onToggle: () => void;
}

function NavigationDropdown({user, onToggle}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  function navigateToSettingsPage() {
    history.push(PATH.USER_SETTINGS);
    onToggle();
  }

  function onSignOut() {
    dispatch(userActions.signOut());
    history.push(PATH.SIGN_IN);
    onToggle();
  }

  function guestSignIn(e) {
    if (e) e.preventDefault();
    dispatch(commonActions.toggleGuestAuthModal(true));
    onToggle();
  }

  function guestSignUp(e) {
    if (e) e.preventDefault();
    dispatch(commonActions.toggleGuestSignUpModal(true));
    onToggle();
  }

  function signOutFromProfile() {
    let authContext: AuthContext = authService.getAuthContext();

    authContext.creatorId = user.id;
    authContext.ownerId = user.id;
    authContext.ownerName = user?.name;
    authContext.ownerCompanyName = user?.companyName;

    authService.setAuthContext(authContext);
    stateStorageService.clearUnsavedDraft();
  }

  function signOutFromUserAccount() {
    signOutFromProfile();

    const viewHistoryPath = navigationHelper.getViewHistoryPageLink();

    history.push(viewHistoryPath);

    if (history?.location?.pathname === viewHistoryPath) window.location.reload();

    onToggle();
  }

  function switchUser() {
    signOutFromProfile();

    history.push(PATH.USERS);
    onToggle();
  }

  function renderUserInfo() {
    let authContext: AuthContext = authService.getAuthContext();

    let name = user?.name;
    let company = '';
    let email = user?.email;

    if (authContext?.isCsrUserAccount) {
      name = authContext.ownerName;
      company = authContext.ownerCompanyName;
      email = '';
    }

    return (
      <>
        <StyledTextContainer>
          <StyledName>{name}</StyledName>
          {company && <span>{company}</span>}
        </StyledTextContainer>

        {email && <StyledEmail>{email}</StyledEmail>}
      </>
    );
  }

  function renderGuestDropdown() {
    return (
      <StyledContainer>
        <StyleLinksContainer>
          <StyledLink title="Sign In" onClick={guestSignIn} />
          <StyledLink title="Sign Up" onClick={guestSignUp} />
        </StyleLinksContainer>
      </StyledContainer>
    );
  }

  function render() {
    if (user.isGuest) return renderGuestDropdown();

    const authContext: AuthContext = authService.getAuthContext();

    return (
      <StyledContainer>
        {renderUserInfo()}

        {authContext?.isCsrUserAccount ? (
          <StyleLinksContainer>
            <StyledLink title="Sign out from Profile" onClick={signOutFromUserAccount} />
            <StyledLink title="Switch User" onClick={switchUser} />
          </StyleLinksContainer>
        ) : (
          <StyleLinksContainer>
            <StyledLink title="Settings" onClick={navigateToSettingsPage} />
            <StyledLink title="Sign Out" onClick={onSignOut} />
          </StyleLinksContainer>
        )}
      </StyledContainer>
    );
  }

  return render();
}

export default NavigationDropdown;

import React from 'react';
import styled from 'styled-components/macro';

import uiHelper from 'helpers/uiHelper';
import dateHelper from 'helpers/dateHelper';

import {colors, mediaQueries, secondaryFont, middleFont} from 'styles/shared';

const StyledPickupButton = styled.div`
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
  width: 17rem;
  height: 8.2rem;
  background: ${colors.orange_main};
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  ${middleFont};
  color: ${colors.white};
  cursor: pointer;

  @media ${mediaQueries.mobileMax} {
    position: initial;
    border-radius: 3.2rem;
    ${secondaryFont};
    font-weight: normal;
    width: 11rem;
    height: 5rem;
  }
`;

function PickupButton() {
  const today = dateHelper.displayShortDate(new Date());

  return <StyledPickupButton onClick={uiHelper.notImplemented}>{today}</StyledPickupButton>;
}

export default PickupButton;

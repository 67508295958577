const ITEM_QUANTITY_LABEL_MAPPING = {
  'UOM': 'Description',
  'BOX': 'Boxes',
  '2GM': 'Centigram',
  '2M': 'Centimeters',
  '2M3': 'Cubic Centimeters',
  '3M3': 'Cubic Feet',
  'M3': 'Cubic Meters',
  'DPR': 'Dozen Pairs',
  'DOZ': 'Dozen',
  '2NO': 'Each',
  'PCS': 'Pieces',
  'GM': 'Grams',
  'GRS': 'Gross',
  'KG': 'Kilograms',
  'L': 'Liters',
  'M': 'Meters',
  '3GM': 'Milligrams',
  '3L': 'Milliliters',
  'X': 'No Unit Required',
  'NO': 'Number',
  '2KG': 'Ounces',
  'PRS': 'Pairs',
  '2L': 'Gallons',
  '3KG': 'Pounds',
  'CM2': 'Square Centimeters',
  '2M2': 'Square Feet',
  '3M2': 'Square Inches',
  'M2': 'Square Meters',
  '4M2': 'Square Yards',
  '3M': 'Yards',
  'CM': 'Centimeters',
  'CT': 'Count',
  'EA': 'Each',
  'LBS': 'Pounds',
  'SET': 'Set',
  'YDS': 'Yards'
};

export default ITEM_QUANTITY_LABEL_MAPPING;

import React from 'react';

function DocumentDeclaredValueTooltip() {
  return (
    <span>
      Total value of the items being sent. <br />
      This does not include taxes, <br />
      shipping costs, or any other fees. <br />
      You are not required to declare <br />
      a value for a documents shipment <br />
      unless you insure the shipment.
    </span>
  );
}

export default DocumentDeclaredValueTooltip;

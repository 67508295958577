import React from 'react';
import styled from 'styled-components/macro';

const StyledContainer = styled.div`
  padding: 0 1rem;
`;

function ListContainerMobile(props) {
  return <StyledContainer>{props.children}</StyledContainer>;
}

export default ListContainerMobile;

import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';

//reduce bundle size by importing required icons only
import {faSignInAlt, faSpinner, faShareSquare, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';

import logoIcon from './icons/logoIcon';
import moneyIcon from './icons/moneyIcon';
import directionIcon from './icons/directionIcon';
import closeIcon from './icons/closeIcon';
import paidIcon from './icons/paidIcon';
import unpaidIcon from './icons/unpaidIcon';
import checkIcon from './icons/checkIcon';
import archiveIcon from './icons/archiveIcon';
import duplicateIcon from './icons/duplicateIcon';
import viewIcon from './icons/viewIcon';
import cancelPickupIcon from './icons/cancelPickupIcon';
import reschedulePickupIcon from './icons/reschedulePickupIcon';
import editShipmentIcon from './icons/editShipmentIcon';
import deleteShipmentIcon from './icons/deleteShipmentIcon';
import phoneIcon from './icons/phoneIcon';
import phonePressedIcon from './icons/phonePressedIcon';
import phoneHoverIcon from './icons/phoneHoverIcon';
import mailIcon from './icons/mailIcon';
import mailPressedIcon from './icons/mailPressedIcon';
import mailHoverIcon from './icons/mailHoverIcon';
import chatIcon from './icons/chatIcon';
import chatPressedIcon from './icons/chatPressedIcon';
import chatHoverIcon from './icons/chatHoverIcon';
import chatActiveIcon from './icons/chatActiveIcon';
import chatActiveHoverIcon from './icons/chatActiveHoverIcon';
import chatActivePressedIcon from './icons/chatActivePressedIcon';
import documentIcon from './icons/documentIcon';
import downloadIcon from './icons/downloadIcon';
import printIcon from './icons/printIcon';
import leftArrowIcon from './icons/leftArrowIcon';
import rightArrowIcon from './icons/rightArrowIcon';
import arrowIcon from './icons/arrowIcon';
import trackingIcon from './icons/trackingIcon';
import bulbIcon from './icons/bulbIcon';
import quoteIcon from './icons/quoteIcon';
import cancelShipmentIcon from './icons/cancelShipmentIcon';
import backIcon from './icons/backIcon';

const unknownIcon = faQuestionCircle;

let map = {
  'sign-in': faSignInAlt,
  loading: faSpinner,
  share: faShareSquare,
  'chevron-left': faChevronLeft,
  'chevron-right': faChevronRight
};

export type IconName =
  | 'sign-in'
  | 'loading'
  | 'share'
  | 'chevron-left'
  | 'chevron-right'
  | 'logo'
  | 'money'
  | 'direction'
  | 'close'
  | 'paid'
  | 'unpaid'
  | 'check'
  | 'archive'
  | 'duplicate'
  | 'view'
  | 'cancel-pickup'
  | 'reschedule-pickup'
  | 'edit-shipment'
  | 'delete-shipment'
  | 'phone'
  | 'phone-hover'
  | 'phone-pressed'
  | 'mail'
  | 'mail-hover'
  | 'mail-pressed'
  | 'chat'
  | 'chat-hover'
  | 'chat-pressed'
  | 'active-chat'
  | 'active-chat-hover'
  | 'active-chat-pressed'
  | 'document'
  | 'download'
  | 'print'
  | 'left-arrow'
  | 'right-arrow'
  | 'summary-arrow'
  | 'tracking'
  | 'bulb'
  | 'quote'
  | 'cancel-shipment'
  | 'back';

interface Props extends BaseProps {
  name: IconName;
  height?: number;
  width?: number;
  size?: SizeProp;
  color?: string;
  spin?: boolean;
}

const AppIcon = (props: Props) => {
  let IconSvg: any = null;

  //switch for svg icons
  switch (props.name) {
    case 'logo':
      IconSvg = logoIcon;
      break;
    case 'money':
      IconSvg = moneyIcon;
      break;
    case 'direction':
      IconSvg = directionIcon;
      break;
    case 'close':
      IconSvg = closeIcon;
      break;
    case 'paid':
      IconSvg = paidIcon;
      break;
    case 'unpaid':
      IconSvg = unpaidIcon;
      break;
    case 'check':
      IconSvg = checkIcon;
      break;
    case 'archive':
      IconSvg = archiveIcon;
      break;
    case 'duplicate':
      IconSvg = duplicateIcon;
      break;
    case 'view':
      IconSvg = viewIcon;
      break;
    case 'cancel-pickup':
      IconSvg = cancelPickupIcon;
      break;
    case 'reschedule-pickup':
      IconSvg = reschedulePickupIcon;
      break;
    case 'edit-shipment':
      IconSvg = editShipmentIcon;
      break;
    case 'delete-shipment':
      IconSvg = deleteShipmentIcon;
      break;
    case 'phone':
      IconSvg = phoneIcon;
      break;
    case 'phone-hover':
      IconSvg = phoneHoverIcon;
      break;
    case 'phone-pressed':
      IconSvg = phonePressedIcon;
      break;
    case 'mail':
      IconSvg = mailIcon;
      break;
    case 'mail-hover':
      IconSvg = mailHoverIcon;
      break;
    case 'mail-pressed':
      IconSvg = mailPressedIcon;
      break;
    case 'chat':
      IconSvg = chatIcon;
      break;
    case 'chat-hover':
      IconSvg = chatHoverIcon;
      break;
    case 'chat-pressed':
      IconSvg = chatPressedIcon;
      break;
    case 'active-chat':
      IconSvg = chatActiveIcon;
      break;
    case 'active-chat-hover':
      IconSvg = chatActiveHoverIcon;
      break;
    case 'active-chat-pressed':
      IconSvg = chatActivePressedIcon;
      break;
    case 'document':
      IconSvg = documentIcon;
      break;
    case 'download':
      IconSvg = downloadIcon;
      break;
    case 'print':
      IconSvg = printIcon;
      break;
    case 'left-arrow':
      IconSvg = leftArrowIcon;
      break;
    case 'right-arrow':
      IconSvg = rightArrowIcon;
      break;
    case 'summary-arrow':
      IconSvg = arrowIcon;
      break;
    case 'tracking':
      IconSvg = trackingIcon;
      break;
    case 'bulb':
      IconSvg = bulbIcon;
      break;
    case 'quote':
      IconSvg = quoteIcon;
      break;
    case 'cancel-shipment':
      IconSvg = cancelShipmentIcon;
      break;
    case 'back':
      IconSvg = backIcon;
      break;

    default:
      break;
  }

  if (IconSvg) {
    let svgProps = {};

    let addPropIfPresent = propName => {
      let value = props[propName];

      if (value) {
        svgProps[propName] = propName === 'width' || propName === 'height' ? `${value / 10}rem` : value;
      }
    };

    addPropIfPresent('width');
    addPropIfPresent('height');
    addPropIfPresent('className');
    addPropIfPresent('color');

    return <IconSvg {...svgProps} />;
  }

  let icon = unknownIcon;

  if (map[props.name]) {
    icon = map[props.name];
  }

  return <FontAwesomeIcon {...props} icon={icon} />;
};

export default AppIcon;

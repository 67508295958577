import {v4 as uuidv4} from 'uuid';

export default {
  getRandomUid,
  setTimeout
};

function getRandomUid() {
  return uuidv4();
}

function setTimeout(action, delay) {
  return global.setTimeout(() => {
    action();
  }, delay);
}

import React from 'react';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';

import {colors} from 'styles/shared';

const StyledLinearProgress = styled(LinearProgress)`
  background-color: ${colors.blue_main}!important;
  margin: 1.4rem 0.8rem 0 1.2rem;
  height: 0.5rem !important;

  .MuiLinearProgress-bar {
    background: ${colors.orange_main};
  }
`;

const StyledPlug = styled.div`
  margin: 1.4rem 0.8rem 0 1.2rem;
  height: 0.5rem;
`;

interface Props {
  hidden: boolean;
}

function ProgressIndicator({hidden}: Props) {
  if (hidden) return <StyledPlug />;

  return <StyledLinearProgress />;
}

export default ProgressIndicator;

import React from 'react';
import styled, {css} from 'styled-components/macro';

import ORDER_STATUS from 'constants/orderStatuses';

import {colors, leftPanelWidth, ordersNavBarHeight, secondaryFont, degree, opacity} from 'styles/shared';

const StyledPanel = styled.div`
  height: ${ordersNavBarHeight};
  position: fixed;
  left: ${leftPanelWidth};
  right: 0;
  background: ${colors.white};
  box-shadow: 0 0 0.5rem ${opacity(0.05)};
  padding-left: 4rem;
  display: flex;
`;

const StyledTabButton = styled.div`
  display: flex;
  padding: 1.5rem 1.5rem 1rem;
  cursor: pointer;
  ${secondaryFont};
  line-height: 2.3rem;

  ${props =>
    props.active &&
    css`
      border-bottom: 0.2rem solid ${colors.orange_main};
    `};
`;

const StyledOrdersNumber = styled.span`
  ${degree};
`;

interface Props {
  activeTab: string;
  orders: any[];
  onChange: (value) => void;
}

function OrdersNavPanel({activeTab, orders, onChange}: Props) {
  const tabs: BasicOption[] = [
    {value: ORDER_STATUS.AWAITING_PAYMENT, label: 'Awaiting Payment'},
    {value: ORDER_STATUS.ON_HOLD, label: 'On Hold'},
    {value: ORDER_STATUS.AWAITING_SHIPMENT, label: 'Awaiting Shipment'},
    {value: ORDER_STATUS.PROCESSED, label: 'Processed'},
    {value: ORDER_STATUS.CANCELLED, label: 'Cancelled'}
  ];

  function getOrdersNumber(tab) {
    return orders.filter(order => order.status === tab).length;
  }

  return (
    <StyledPanel>
      {tabs.map(tab => {
        const value = tab.value;

        let active = value === activeTab;

        let ordersNumber = getOrdersNumber(value) || null;

        return (
          <StyledTabButton key={value} active={active} onClick={() => onChange(value)}>
            {tab.label}

            {ordersNumber && <StyledOrdersNumber>{ordersNumber}</StyledOrdersNumber>}
          </StyledTabButton>
        );
      })}
    </StyledPanel>
  );
}

export default OrdersNavPanel;

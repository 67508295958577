import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {useSelector} from 'react-redux';

import {AppState} from 'reducers';

import MobilePopup from './MobilePopup';

import {mediaQueries} from 'styles/shared';

const StyledToggler = styled.div`
  display: none;

  @media ${mediaQueries.mobileMax} {
    display: inline;
    cursor: pointer;
  }
`;

function MobileToggler() {
  const user = useSelector((state: AppState) => state.user.current as User);

  const [mobilePopupVisible, setMobilePopupVisible] = useState(false);

  function toggleMobilePopup() {
    setMobilePopupVisible(!mobilePopupVisible);
  }

  return (
    <>
      <StyledToggler onClick={toggleMobilePopup}>
        <img alt="Toggler" src="/images/hamburger.png" />
      </StyledToggler>

      {mobilePopupVisible && <MobilePopup user={user} onToggle={toggleMobilePopup} />}
    </>
  );
}

export default MobileToggler;

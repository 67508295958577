import WHO_PAYS from 'constants/payment/whoPays';

import authService from 'services/authService';

export default {
  handleUserWithoutImportExportStatus,
  csrHouseAccountWarningVisible,
  getWhoPaysOptionsList
};

function handleUserWithoutImportExportStatus(user: User, isExport, isImport) {
  if (user.isCSR) return false;

  if (isExport && !user.setupForExports) return true;

  if (isImport && !user.setupForImports) return true;

  return false;
}

function csrHouseAccountWarningVisible(isCSR, isHouseAccountUsed, isExport, isImport) {
  if (!isCSR) return false;

  if (isHouseAccountUsed) return false;

  let authContext: AuthContext = authService.getAuthContext();

  if (isExport && !authContext.ownerSetupForExports) return true;

  if (isImport && !authContext.ownerSetupForImports) return true;

  return false;
}

function getWhoPaysOptionsList(billingInfo, whoPaysOptions, isExport, isImport, isHouseAccountUsed) {
  const exportNumber = billingInfo?.dhlExportNumber;
  const importNumber = billingInfo?.dhlImportNumber;

  let whoPaysList: BasicOption[] = [];

  for (let item of whoPaysOptions) {
    let option = {value: item, label: item};

    if (item !== WHO_PAYS.MY_ACCOUNT) {
      whoPaysList.push(option);
      continue;
    }

    if (isExport) {
      if (isHouseAccountUsed) {
        option.label = WHO_PAYS.HOUSE_ACCOUNT;
        whoPaysList.push(option);
        continue;
      } else if (exportNumber) {
        option.label = `${item} - #${exportNumber}`;
        whoPaysList.push(option);
        continue;
      }
    }

    if (isImport) {
      if (isHouseAccountUsed) {
        option.label = WHO_PAYS.HOUSE_ACCOUNT;
        whoPaysList.push(option);
        continue;
      } else if (importNumber) {
        option.label = `${item} - #${importNumber}`;
        whoPaysList.push(option);
        continue;
      }
    }

    whoPaysList.push(option);
  }

  return whoPaysList;
}

import React from 'react';
import styled from 'styled-components/macro';

import {colors, headerFont} from 'styles/shared';

const StyledHeader = styled.div`
  ${headerFont};
  color: ${colors.black};
`;

function FormHeader({children, className}: BaseProps) {
  return <StyledHeader className={className}>{children}</StyledHeader>;
}

export default FormHeader;

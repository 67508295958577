import * as actionTypes from 'action_types/addressActionTypes';

import initialState from './initialState';
import helper from './reducerHelper';

export interface AddressState {
  list: SavedAddress[];
  pagination: Pagination;
}

const addressReducer = (state: AddressState = initialState.address, action) => {
  return helper.handleActions<AddressState>(state, action, {
    [actionTypes.LOAD_ADDRESSES](state, payload: {addresses: SavedAddress[]; totalItems: number}) {
      state.list = payload.addresses;
      state.pagination.totalCount = payload.totalItems;
    },
    [actionTypes.REMOVE_ADDRESS](state, payload: {id: string}) {
      state.list = [...state.list.filter(item => item.id !== payload.id)];
      state.pagination.totalCount = state.pagination.totalCount - 1;
    },
    [actionTypes.CHANGE_ADDRESSES_PAGE](state, payload: {activePage: number}) {
      state.pagination.activePage = payload.activePage;
    },
    [actionTypes.SELECT_ADDRESS_FROM_SEARCH](state, payload: {address: SavedAddress}) {
      state.list = [payload.address];
      state.pagination.totalCount = 1;
      state.pagination.activePage = 1;
    }
  });
};

export default addressReducer;

import React from 'react';
import {get} from 'lodash';
import styled from 'styled-components/macro';

import validationHelper from 'helpers/validationHelper';

import AlertComponent from 'components/common/AlertComponent';
import Toggler from 'components/common/Toggler';
import FormLabel from 'components/common/FormLabel';

const StyledTogglerRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
` ;

interface Props {
  value: boolean,
  errors: object,
  onChange: OnChangeHandler
}

function DangerousGoodsToggler({value, errors, onChange}: Props) {
  function render() {
    const isShipmentContainsDangerousGoodsError = get(
      errors,
      'shipmentDetails.products.isShipmentContainsDangerousGoods'
    );

    return (
      <>
        <StyledTogglerRow>
          <FormLabel>This shipment contains dangerous goods</FormLabel>
          <Toggler
            name="isShipmentContainsDangerousGoods"
            value={value}
            namePrefix="products"
            onToggle={onChange}
          />
        </StyledTogglerRow>

        {isShipmentContainsDangerousGoodsError && (
          <AlertComponent
            header="Warning!"
            text={validationHelper.getInputError(isShipmentContainsDangerousGoodsError)}
          />
        )}
      </>
    );
  }

  return render();
}

export default DangerousGoodsToggler;

import React from 'react';
import styled from 'styled-components/macro';

import {colors} from 'styles/shared';

const StyledDevider = styled.div`
  border-bottom: 0.1rem dashed ${colors.darkBlue_light};
  margin-bottom: 3rem;
`;

function Devider() {
  return <StyledDevider />;
}

export default Devider;

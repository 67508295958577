import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import {Row, Col} from 'components/bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {get, isArray} from 'lodash';

import {AppState} from 'reducers';

import shipmentActions from 'actions/shipmentActions';

import LICENSE_TYPE from 'constants/licenseTypes';
import FEATURE from 'constants/features';
import WARNING from 'constants/literals/warnings';
import ERROR from 'constants/literals/errors';

import uiHelper from 'helpers/uiHelper';
import formatHelper from 'helpers/formatHelper';

import FormHeader from 'components/common/FormHeader';
import TextInput from 'components/common/TextInput';
import NumberInput from 'components/common/NumberInput/NumberInput';
import SelectInput from 'components/common/SelectInput/SelectInput';
import CurrencyInput from 'components/common/CurrencyInput';
import Button from 'components/common/Button';
import DatePicker from 'components/common/DatePicker';
import CheckboxInput from 'components/common/CheckboxInput';
import QuestionMark from 'components/common/Marks/QuestionMark';
import CommoditySearchModal from 'components/common/CommoditySearch';
import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';

import ScheduleBTooltip from 'components/tooltips/ScheduleBTooltip';
import LineItemDescriptionTooltip from 'components/tooltips/LineItemDescriptionTooltip';
import LineItemMadeTooltip from 'components/tooltips/LineItemMadeTooltip';
import EccnTooltip from 'components/tooltips/EccnTooltip';
import ExportCodeTooltip from 'components/tooltips/ExportCodeTooltip';
import LicenseTooltip from 'components/tooltips/LicenseTooltip';
import InputWithUnits from 'components/common/InputWithUnits';

import * as Styled from 'styles/modal';

import {buttonLeftMargin, buttonLeftRightMargin} from 'styles/shared';

const StyledHeader = styled(Flex)`
  margin-bottom: 1.9rem;
`;

const StyledTextInput = styled(TextInput)`
  min-height: auto;
`;

const StyledSelectInput = styled(SelectInput)`
  min-height: auto;

  > div > div {
    white-space: nowrap;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  min-height: auto;
`;

const StyledLicenseContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
`;

const StyledLicenseInput = styled(TextInput)`
  flex: 1;
`;

interface Props {
  visible: boolean;
  lineItem: LineItem;
  currency: string;
  measurementSystem: string;
  close: () => void;
  onChange: (field: string, value: any) => void;
  onSave: () => void;
  errors: object;
}

function SaveLineItem({visible, lineItem, currency, measurementSystem, close, onChange, onSave, errors}: Props) {
  const dispatch = useDispatch();

  const itemQuantityOptions = useSelector((state: AppState) => state.shipmentOptions.itemQuantityOptions);
  const countryList = useSelector((state: AppState) => state.shipmentOptions.countryList);

  const [commodityError, setCommodityError] = useState('');
  const [searchModalVisible, setSearchModalVisible] = useState(false);

  useEffect(() => {
    let error = get(errors, 'commodityCode');

    if (!error && commodityError === WARNING.COMMODITY_CODE) return;

    setCommodityError(error);
  }, [errors]);

  const licenseNumberOptions: BasicOption[] = [
    {value: LICENSE_TYPE.NO_LICENSE_REQUIRED, label: 'No License needed'},
    {value: '1', label: 'License nr. 1'},
    {value: '2', label: 'License nr. 2'},
    {value: '3', label: 'License nr. 3'},
    {value: '4', label: 'License nr. 4'},
    {value: '5', label: 'License nr. 5'}
  ];

  function toggleSearchModal() {
    setSearchModalVisible(!searchModalVisible);
  }

  function applyCommodityCode() {
    uiHelper.notImplemented();
  }

  async function onBlur() {
    if (!lineItem.commodityCode) return;

    let update = value => {
      let formattedValue = formatHelper.formatCommodityCode(value);
      onChange('commodityCode', formattedValue);
    };

    let code = formatHelper.replaceNonDigitsInString(lineItem.commodityCode);
    if (code.length !== 10) {
      update(code);
      setCommodityError(ERROR.COMMODITY_CODE);
      return;
    }

    let response: any = await dispatch(shipmentActions.loadCommodityCodeDetails(code));

    if (!response) {
      update(code);
      setCommodityError(WARNING.COMMODITY_CODE);
      return;
    }

    if (isArray(response)) {
      let formatedCommodityCode = response[0];

      update(formatedCommodityCode);
    } else {
      update(lineItem.commodityCode);
    }

    setCommodityError('');
  }

  function render() {
    const actionButton = FEATURE.COMMODITY_CODE_SEARCH
      ? {title: 'Search', action: () => toggleSearchModal()}
      : undefined;

    return (
      <Styled.CustomModal show={visible} backdrop="static" onHide={close}>
        <StyledHeader justify="space-between">
          <FormHeader>New Item</FormHeader>
          <CloseButton onClick={close} />
        </StyledHeader>

        <StyledTextInput
          name="name"
          label="Name"
          placeholder="Name the Item"
          required={true}
          value={lineItem.name}
          onChange={onChange}
          error={get(errors, 'name')}
        />

        <StyledTextInput
          name="commodityCode"
          label="Schedule B"
          tooltip={ScheduleBTooltip()}
          actionButton={actionButton}
          placeholder="0000.00.0000"
          required={true}
          value={lineItem.commodityCode}
          onChange={onChange}
          onBlur={onBlur}
          error={commodityError}
        />

        <StyledTextInput
          name="itemDescription"
          label="Item Description"
          tooltip={LineItemDescriptionTooltip()}
          placeholder="Short description of the Item"
          required={true}
          value={lineItem.itemDescription}
          onChange={onChange}
          error={get(errors, 'itemDescription')}
        />

        <Row>
          <Col>
            <NumberInput
              name="quantity"
              label="Quantity"
              withSignButtons={false}
              autoHeight
              required={true}
              value={lineItem.quantity}
              onChange={onChange}
              error={get(errors, 'quantity')}
            />
          </Col>
          <Col>
            <StyledSelectInput
              name="units"
              label="Units"
              required={true}
              options={itemQuantityOptions}
              value={lineItem.units}
              onChange={onChange}
              error={get(errors, 'units')}
            />
          </Col>
        </Row>

        <Row>
        <Col>
            <CurrencyInput
              name="itemValue"
              label="Value per item"
              value={lineItem.itemValue}
              currency={currency}
              required={true}
              onChange={onChange}
              error={get(errors, 'itemValue')}
            />
          </Col>
          <Col>
            <InputWithUnits
                name="weight"
                label="Weight per item"
                value={lineItem.weight}
                measurementSystem={measurementSystem}
                isWeightUnit={true}
                required={true}
                onChange={onChange}
                skipTabForUnits={false}
                error={get(errors, 'weight')}
              />
          </Col>
        </Row>

        <Row>
          <Col>
            <StyledSelectInput
              name="itemMade"
              label="Where was the item made"
              tooltip={LineItemMadeTooltip()}
              options={countryList}
              required={true}
              value={lineItem.itemMade}
              onChange={onChange}
              error={get(errors, 'itemMade')}
            />
          </Col>
        </Row>

        <StyledTextInput
          name="eccnValue"
          label="ECCN"
          value={lineItem.eccnValue}
          tooltip={EccnTooltip()}
          onChange={onChange}
        />

        <Row>
          <Col>
            <StyledSelectInput
              name="exportLicenseNumber"
              label="Export License Nr."
              value={lineItem.exportLicenseNumber}
              tooltip={ExportCodeTooltip()}
              options={licenseNumberOptions}
              onChange={onChange}
            />
          </Col>

          <Col>
            <StyledDatePicker
              name="exportLicenseExpiryDate"
              label="Export License Expiry Date"
              placeholder="YYYYMMDD"
              value={lineItem.exportLicenseExpiryDate}
              onChange={onChange}
            />
          </Col>
        </Row>

        <StyledLicenseContainer>
          <CheckboxInput
            name="isLicenseSymbolRequired"
            label="License Symbol"
            value={lineItem.isLicenseSymbolRequired}
            onToggle={onChange}
          />

          <StyledLicenseInput
            name="licenseSymbol"
            placeholder="abc"
            size="small"
            value={lineItem.licenseSymbol}
            onChange={onChange}
          />

          <QuestionMark id="license-tooltip" tooltip={LicenseTooltip()} />
        </StyledLicenseContainer>

        <Flex justify="flex-end">
          <Button autoWidth margin={buttonLeftRightMargin} onClick={onSave}>
            Save Item
          </Button>
          <Button type="secondary" autoWidth margin={buttonLeftMargin} onClick={close}>
            Cancel
          </Button>
        </Flex>

        {searchModalVisible && (
          <CommoditySearchModal visible={searchModalVisible} close={toggleSearchModal} onApply={applyCommodityCode} />
        )}
      </Styled.CustomModal>
    );
  }

  return render();
}

export default SaveLineItem;

import React from 'react';
import styled from 'styled-components/macro';
import {isEmpty} from 'lodash';

import formatHelper from 'helpers/formatHelper';

import FormHeader from 'components/common/FormHeader';
import CountrySummary from './components/CountrySummary';
import ElectronicExportInformation from './components/ElectronicExportInformation';
import PaymentSummary from './components/PaymentSummary';
import SendingSummary from './components/SendingSummary';
import DetailsSummary from './components/DetailsSummary';
import InvoiceSummary from './components/invoice_summary/InvoiceSummary';
import TrackingNumberSummary from './components/TrackingNumberSummary';

import {colors} from 'styles/shared';

const StyledOverviewContainer = styled.div`
  border-top: 0.1rem dashed ${colors.darkBlue_light};
`;

const StyledHeader = styled(FormHeader)`
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

interface Props {
  shipment: Shipment;
  pickup?: Pickup;
}

function ShipmentOverview({shipment, pickup}: Props) {
  function render() {
    if (!shipment || isEmpty(shipment)) return null;

    const insuranceValue = formatHelper.displayPrice(shipment.additionalServices.insuranceValue, shipment.currency);

    return (
      <StyledOverviewContainer>
        <StyledHeader>Shipment Information Overview</StyledHeader>

        <StyledRow>
          <CountrySummary countryFrom={shipment.shipmentFrom.country} countryTo={shipment.shipmentTo.country} />

          {shipment.isExport && <ElectronicExportInformation data={shipment?.shipmentDetails?.products} />}
        </StyledRow>

        {shipment?.trackingNumber && <TrackingNumberSummary trackingNumber={shipment.trackingNumber} />}

        <PaymentSummary payment={shipment.payment} isDomestic={shipment.isDomestic} insuranceValue={insuranceValue} />

        <SendingSummary
          origin={shipment.shipmentFrom}
          isDomestic={shipment.isDomestic}
          destination={shipment.shipmentTo}
        />

        <DetailsSummary shipment={shipment} pickup={pickup} />

        {shipment.isDutiable && <InvoiceSummary shipment={shipment} />}
      </StyledOverviewContainer>
    );
  }

  return render();
}

export default ShipmentOverview;

import styled from 'styled-components';

import ActionLink from 'components/common/ActionLink';

import {colors, mainFont} from 'styles/shared';

export const container = styled.div`
  margin-top: 0.7rem;
`;

export const header = styled.div`
  background: ${props => props.color === 'grey' ? `${colors.grey_main}` : `${colors.red_main}`};
  padding: 1.6rem;
  ${mainFont};
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  color: ${props => props.color === 'grey' ? `${colors.black}` : `${colors.white}`};
`;

export const body = styled.div`
  ${mainFont};
  background: ${props => props.color === 'grey' ? `${colors.grey_concrete}` : `${colors.red_selected}`};
  padding: 1.6rem;
  color: ${props => props.color === 'grey' ? `${colors.black}` : `${colors.darkBlue_main}`};
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  border: 0.1rem solid ${props => props.color === 'grey' ? `${colors.grey_main}` : `${colors.red_main}`};
  border-top: none;
`;

export const actionLink = styled(ActionLink)`
  color: ${colors.red_main};
`;

import React from 'react';
import styled from 'styled-components/macro';

import SearchInput from 'components/common/SearchInput';
import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import {colors, maxUsersContainerWidth} from 'styles/shared';

const StyledPanel = styled(Flex)`
  width: 100%;
  height: 10.6rem;
  background: ${colors.grey_light};
  margin-bottom: 1.5rem;
`;

const StyledSearchContainer = styled(Flex)`
  width: ${maxUsersContainerWidth};
`;

const StyledSearchInput = styled(SearchInput)`
  min-height: auto;
  margin: 0 1.6rem 0 0;

  & input {
    width: 50rem;
    margin-bottom: 0;
  }
`;

interface Props {
  searchStr: string;
  onChange: (field: string, value: any) => void;
  onSearch: () => void;
}

function UsersSearchPanel({searchStr, onChange, onSearch}: Props) {
  function handleKeyDown(e) {
    if (e.key === 'Enter') onSearch();
  }

  return (
    <StyledPanel justify="center" align="center">
      <StyledSearchContainer>
        <StyledSearchInput
          name="searchStr"
          placeholder="Search term"
          value={searchStr}
          onKeyDown={handleKeyDown}
          onChange={onChange}
        />

        <Button autoWidth onClick={onSearch}>
          Search
        </Button>
      </StyledSearchContainer>
    </StyledPanel>
  );
}

export default UsersSearchPanel;

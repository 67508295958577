import React from 'react';
import styled from 'styled-components/macro';
import {Modal} from 'components/bootstrap';

import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';

import * as Styled from 'styles/modal';
import modalStyles from 'styles/customization/modal';
import {colors} from 'styles/shared';

const StyledHeader = styled(Flex)`
  margin-bottom: 2rem;
`;

const StyledCloseButton = styled(CloseButton)`
  margin-right: 2rem;
`;

const StyledBody = styled(Modal.Body)`
  ${modalStyles.prohibitedItems.modalBody};
`;

const StyledList = styled.ul`
  list-style: none;
  padding-left: 1rem;
  margin-top: 2rem;
`;

const StyledItem = styled.li`
  &:before {
    content: '${props => '\\2022'}';
    color: ${colors.orange_main};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -0.7em;
  }
`;

interface Props {
  visible: boolean;
  close: () => void;
}

function ProhibitedItemsModal({visible, close}: Props) {
  return (
    <Styled.CustomModal show={visible} width="45.2rem" padding="2.3rem 2.6rem 2.6rem 2.6rem" onHide={close}>
      <StyledHeader justify="flex-end">
        <StyledCloseButton onClick={close} />
      </StyledHeader>

      <StyledBody>
        <p>
          The following commodities are <strong>PROHIBITED</strong> and are not acceptable for transport by DHL under
          any circumstances. They will be refused for transport by DHL Express, no exceptions.
        </p>
        <StyledList>
          <StyledItem>Live animals</StyledItem>
          <StyledItem>
            Hunting (Animal) trophies, animal parts such as ivory and shark fin, animal remains, or Animal-by-Products
            and derived products not intended for human consumption, prohibited for movement by the CITES Convention
            and/or local law.
          </StyledItem>
          <StyledItem>Human remains or ashes</StyledItem>
          <StyledItem>Bullion (of any precious metal)</StyledItem>
          <StyledItem>Cash (current legal tender)</StyledItem>
          <StyledItem>Loose precious and semi-precious stones</StyledItem>
          <StyledItem>Complete firearms, ammunition, explosives / explosive devices</StyledItem>
          <StyledItem>Illegal goods, such as counterfeit goods and narcotics</StyledItem>
        </StyledList>
      </StyledBody>
    </Styled.CustomModal>
  );
}

export default ProhibitedItemsModal;

const ELECTRONIC_EXPORT_FILING = {
  EXEMPTED: 'Exempted',
  OTHER: 'other',
  NO: 'no',
  DEFAULT_EXEMPTION: '30.37(a)',
  ITN: 'Internal Transaction Number (ITN)',
  EIN: 'Employer Identification Number (EIN)'
};

export default ELECTRONIC_EXPORT_FILING;

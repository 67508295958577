import React, {useState, useEffect, useRef} from 'react';
import {Row, Col} from 'components/bootstrap';
import {get, startCase} from 'lodash';
import {useSelector} from 'react-redux';
import $ from 'jquery';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';

import uiHelper from 'helpers/uiHelper';
import addressHelper from 'helpers/addressHelper';

import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import INCOMPATIBLE_COUNTRY_CODE_MAPPING from 'constants/countryCodes/incompatibleCountryCodeMapping';
import US_STATES_SELECT_OPTIONS from 'constants/usStates/usStatesSelectOptions';

import TextInput from 'components/common/TextInput';
import FormLabel from 'components/common/FormLabel';
import AutosuggestInput from './components/AutosuggestInput';
import SelectInput from 'components/common/SelectInput/SelectInput';

const StyledSelectInput = styled(SelectInput)`
  margin-top: 0.7rem;
`;

interface Props {
  autocompleteId: string;
  label: string;
  address: Address;
  countrySelected: string;
  path?: string;
  oneColumnDisplay: boolean;
  onChange: (field: string, value: any) => void;
  onAddressSelect: (address: object) => void;
  errors: object;
}

function PhysicalAddress({
  autocompleteId,
  label,
  address,
  countrySelected,
  path = '',
  oneColumnDisplay,
  onChange,
  onAddressSelect,
  errors
}: Props) {
  const countryOptions: CountryOption[] = useSelector((state: AppState) => state.shipmentOptions.countryOptions);

  const [readyToRender, setReadyToRender] = useState<boolean>(false);
  const [countryOption, setCountryOption] = useState({} as CountryOption);

  let autocompleteRef = useRef<any>();
  let autocompleteListener;

  const fieldsMap = {
    city: {map: ['locality', 'neighborhood'], format: 'long_name'},
    division: {
      map: ['administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3'],
      format: 'short_name'
    },
    postalCode: {map: ['postal_code'], format: 'short_name'}
  };

  useEffect(() => {
    try {
      createAutocomplete();

      //hack to override autocomplete attribute for address line 1
      let element = $(`#${autocompleteId}`);
      element.focus(() => {
        element.attr('autocomplete', 'nope');
      });

      setReadyToRender(true);
    } catch (err) {
      uiHelper.logError(`Cannot initialize google autocomplete library`);
      uiHelper.logError(err);
    }
  }, []);

  useEffect(() => {
    if (autocompleteRef?.current) {
      autocompleteListener = autocompleteRef.current.addListener('place_changed', fillInAddress);
    }

    return () => {
      if (autocompleteRef?.current) google.maps.event.clearInstanceListeners(autocompleteRef.current);
      if (autocompleteListener) google.maps.event.removeListener(autocompleteListener);
    };
  });

  useEffect(() => {
    if (!readyToRender || !countrySelected) return;

    let selectedCountryOption = countryOptions.find(item => item.code === countrySelected);

    if (!selectedCountryOption) return;

    setCountryOption(selectedCountryOption);

    if (autocompleteRef.current && countrySelected) {
      let autocompleteCountry = countrySelected;

      if (INCOMPATIBLE_COUNTRY_CODE_MAPPING.hasOwnProperty(countrySelected)) {
        autocompleteCountry = INCOMPATIBLE_COUNTRY_CODE_MAPPING[countrySelected];
      }

      autocompleteRef.current.setComponentRestrictions({
        country: [autocompleteCountry.toLowerCase()]
      });
    }
  }, [countrySelected, readyToRender]);

  function createAutocomplete() {
    // Create the autocomplete object, restricting the search predictions to
    let autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(autocompleteId) as HTMLInputElement,
      {
        types: ['address']
      }
    );

    // Avoid paying for data that you don't need by restricting the set of
    autocomplete.setFields(['address_component', 'name']);

    autocompleteRef.current = autocomplete;
  }

  function fillInAddress() {
    const place = autocompleteRef.current.getPlace();

    if (!place || !place.address_components) return;

    let selectedAddress: any = {
      addressLine1: place?.name
    };

    let componentLookup: {[x: string]: google.maps.GeocoderAddressComponent} = {};

    for (let component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      componentLookup[component.types[0]] = component;
    }

    // Handle city mapping for Taiwan
    // There may be other countries where this also needs to be done
    const fieldsMapLocal = addressHelper.remapAddressComponentsByCountryForGoogleAutofill(
      Object.assign(fieldsMap), componentLookup, countryOption.code);

    for (let field of Object.keys(fieldsMapLocal)) {
      let fieldInfo = fieldsMap[field];
      for (let fieldMap of fieldInfo.map) {
        let component = componentLookup[fieldMap];
        if (component) {
          let value = component[fieldInfo.format];
          if (value) {
            selectedAddress[field] = value;
            break;
          }
        }
      }
    }

    // Handle postal code mapping for Argentina
    // There may be other countries where this also needs to be done
    if (selectedAddress.postalCode && typeof selectedAddress.postalCode === 'string') {
      selectedAddress.postalCode =
        addressHelper.reformatPostalCodeByCountry(selectedAddress.postalCode, countryOption.code);
    }

    onAddressSelect(selectedAddress);
  }

  function autofillAddressField(suggestion) {
    // Map US States to two letter format for new select inputs
    const division = countrySelected === COUNTRY_CODE.USA ?
      addressHelper.reformatUSState(suggestion.countryDivisionName) :
      suggestion.countryDivisionName;

    let selectedAddress: any = {};

    selectedAddress.postalCode = suggestion.postalCode;

    const elements = countryOption.elements;

    if (suggestion.city && elements?.city) selectedAddress.city = startCase(suggestion.city.toLowerCase());
    if (division && division !== '*' && isDivision()) selectedAddress.division = division;

    onAddressSelect(selectedAddress);
  }

  function isDivision() {
    const elements = countryOption.elements;

    return (
      elements.county !== null || elements.district !== null || elements.province !== null || elements.state !== null
    );
  }

  function getError(key) {
    if (path) return get(errors, `${path}.${key}`);

    return get(errors, key);
  }

  function renderAddressElements() {
    const elements = countryOption.elements;

    if (!countrySelected || !elements) return null;

    let renderCityInput = () => {
      return (
        <TextInput
          name="city"
          placeholder="City"
          value={address.city}
          size="small"
          onChange={onChange}
          error={getError('address.city')}
        />
      );
    };

    let renderSuburbInput = () => {
      return (
        <TextInput
          name="suburb"
          placeholder="Suburb"
          value={address.suburb}
          size="small"
          onChange={onChange}
          error={getError('address.suburb')}
        />
      );
    };

    let renderPostalCodeInput = () => {
      return (
        <AutosuggestInput
          name="postalCode"
          placeholder="Postal Code"
          value={address.postalCode}
          countrySelected={countrySelected}
          onChange={onChange}
          onAutoFill={autofillAddressField}
          error={getError('address.postalCode')}
        />
      );
    };

    const isCity = elements.city !== null;
    const isPostalCode = elements.postalCode !== null;
    const isSuburb = elements.suburb !== null;

    const columnSize = oneColumnDisplay ? 12 : 6;

    let cityColumnSize = isPostalCode || isSuburb ? columnSize : 12;
    let suburbColumnSize = isCity || isPostalCode ? columnSize : 12;
    let postalCodeColumnSize = isCity || isSuburb ? columnSize : 12;
    if (isCity && isSuburb && !isDivision()) postalCodeColumnSize = 12;

    let divisionColumnSize = isCity && isSuburb && isPostalCode ? columnSize : 12;

    return (
      <Row>
        {isCity && <Col lg={cityColumnSize}>{renderCityInput()}</Col>}
        {isSuburb && <Col lg={suburbColumnSize}>{renderSuburbInput()}</Col>}
        {isPostalCode && <Col lg={postalCodeColumnSize}>{renderPostalCodeInput()}</Col>}
        {isDivision() && <Col lg={divisionColumnSize}>{renderDivisionInput()}</Col>}
      </Row>
    );
  }

  function renderDivisionInput() {
    const elements = countryOption.elements;

    if (!countrySelected || !elements) return null;

    let divisionInput: any = null;

    let renderDivisionInput = placeHolder => {
      return (
        <TextInput
          name="division"
          placeholder={placeHolder}
          value={address.division}
          size="small"
          onChange={onChange}
          error={getError('address.division')}
        />
      );
    };

    let renderUSStateSelect = () => {
      return (
        <StyledSelectInput
          name="division"
          required={true}
          options={US_STATES_SELECT_OPTIONS}
          value={address.division}
          onChange={onChange}
          error={getError('address.division')}
        />
      );
    };

    if (countrySelected === COUNTRY_CODE.USA) {
      return renderUSStateSelect();
    }

    if (elements.county !== null) {
      divisionInput = renderDivisionInput('County');
    } else if (elements.district !== null) {
      divisionInput = renderDivisionInput('District');
    } else if (elements.province !== null) {
      divisionInput = renderDivisionInput('Province');
    } else if (elements.state !== null) {
      divisionInput = renderDivisionInput('State');
    }

    return divisionInput;
  }

  function render() {
    return (
      <>
        <FormLabel required={true}>{label}</FormLabel>

        <TextInput
          id={autocompleteId}
          name="addressLine1"
          placeholder="Address line 1"
          value={address.addressLine1}
          size="small"
          onChange={onChange}
          error={getError('address.addressLine1')}
        />

        <TextInput
          name="addressLine2"
          placeholder="Address line 2"
          value={address.addressLine2}
          size="small"
          onChange={onChange}
          error={getError('address.addressLine2')}
        />

        <TextInput
          name="addressLine3"
          placeholder="Address line 3"
          value={address.addressLine3}
          size="small"
          onChange={onChange}
          error={getError('address.addressLine3')}
        />

        {renderAddressElements()}
      </>
    );
  }

  return render();
}

export default PhysicalAddress;

import React from 'react';
import styled from 'styled-components/macro';

import {colors, defaultInputHeight, opacity} from 'styles/shared';

const StyledSignContainer = styled.div`
  width: ${defaultInputHeight};
  height: ${defaultInputHeight};
  border-radius: 50%;
  background: ${props => (props.disabled ? colors.grey_main : colors.orange_main)};
  box-shadow: 0 0.4rem 2rem ${opacity(0.15)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  margin-left: 0.8rem;

  &:before {
    content: '${props => (props.sign === 'minus' ? '\\2212' : '\\002B')}';
    top: 0.3rem;
    left: 0.9rem;
    font-size: 3rem;
    font-weight: bold;
    color: ${colors.white};
  }
`;

interface Props {
  icon: string;
  onClick: (icon: string) => void;
  disabled: boolean;
}

function SignComponent({icon, onClick, disabled}: Props) {
  return <StyledSignContainer sign={icon} disabled={disabled} onClick={() => onClick(icon)} />;
}

export default SignComponent;

import React, {useState} from 'react';
import styled, {css} from 'styled-components/macro';
import {isNil} from 'lodash';

import validationHelper from 'helpers/validationHelper';
import formatHelper from 'helpers/formatHelper';

import Flex from 'components/common/Flex';
import FormLabelContainer from 'components/common/InputComponents/FormLabelContainer';
import InputError from 'components/common/InputComponents/InputError';

import inputStyles from 'styles/customization/input';
import {colors} from 'styles/shared';

const StyledInputContainer = styled.div`
  ${inputStyles.currencyInput.inputContainer};
`;

const StyledInputBox = styled(Flex)`
  position: relative;
  width: 100%;
`;

const StyledInputStyled = styled.input`
  border: 0.1rem solid ${props => (props.error ? colors.red_main : colors.darkBlue_light)};
  ${inputStyles.common.inputFieldWithUnits};

  ${props =>
    props.isWarning &&
    css`
      border-color: ${colors.orange_main};
    `}
`;

const StyledSelect = styled.div`
  border: 0.1rem solid ${props => (props.error ? colors.red_main : colors.darkBlue_light)};
  ${inputStyles.common.inputDropdown};

  ${props =>
    props.isWarning &&
    css`
      border-color: ${colors.orange_main};
    `}
`;

interface Props extends BaseProps {
  name: string;
  label?: string;
  onChange: OnChangeHandler;
  value: number;
  tooltip: string | JSX.Element;
  error?: string | ValidationError[];
  placeholder?: string;
  required?: boolean;
  isWarning: boolean;
  disabled: boolean;
  currency: string;
  onBlur?: () => void;
}

CurrencyInput.defaultProps = {
  required: false,
  tooltip: '',
  placeholder: '00',
  className: '',
  isWarning: false,
  disabled: false
};

function CurrencyInput({
  name,
  label,
  onChange,
  value,
  error,
  required,
  tooltip,
  placeholder,
  className,
  isWarning,
  disabled,
  currency,
  onBlur
}: Props) {
  const [isInitialValue, setIsInitialValue] = useState(true);

  let inputValue: any = null;

  if (isInitialValue) {
    inputValue = value || '';
  } else {
    if (!isNil(value)) {
      inputValue = value;
    }
  }

  const inputOnChange = e => {
    let val = e.target.value;

    val = String(val).replace(/[/-]/g, '');

    onChange(name, val);
  };

  const inputOnBlur = e => {
    if (String(value).includes(".") && String(value).split(".")[1].length > 2) {
      value = formatHelper.calculateAccuratePrecision(Number(value), 2);
    }

    onChange(name, Number(value));

    setIsInitialValue(false);
    if (onBlur) onBlur();
  };

  const inputError: string = validationHelper.getInputError(error);

  return (
    <StyledInputContainer className={className}>
      {label && (
        <FormLabelContainer label={label} name={name} required={required} disabled={disabled} tooltip={tooltip} />
      )}

      <Flex>
        <StyledInputBox>
          <StyledInputStyled
            type="number"
            min="0"
            step="0.01"
            name={name}
            value={inputValue}
            placeholder={placeholder}
            onChange={inputOnChange}
            disabled={disabled}
            onBlur={inputOnBlur}
            isWarning={isWarning}
            autoComplete="nope"
            error={inputError}
          />
        </StyledInputBox>

        <StyledSelect id={name} name={name} value={currency} isWarning={isWarning} error={inputError}>
          {currency}
        </StyledSelect>
      </Flex>

      {inputError && <InputError>{inputError}</InputError>}
    </StyledInputContainer>
  );
}

export default CurrencyInput;

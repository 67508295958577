import { useRef, useEffect } from 'react';

export default {
  useFirstRender,
  useEffectWhen
};

// When you need to have an action performed on all but first render or on first render in addition
// to other conditions
// Example usage:
// const firstRender = customHooks.useFirstRender();
// const phoneNumberRef = useRef(null);

// useEffect(() => {
//   if (firstRender || errors.phoneNumber) {
//     phoneNumberRef.current.focus();
//   }
// }, [firstRender, errors.phoneNumber]);
function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
}

// When you need an effect to only trigger after some initial condition is met and then other dependencies change
// Example usage:
// customHooks.useEffectWhen(() => {
//   if (autofillingPhoneNumber && autofillingPhoneNumber === phoneNumber) {
//     setAutofillingPhoneNumber('');
//   }
// }, [ phoneNumber, autofillingPhoneNumber ], [autofillingPhoneNumber])
// Function will trigger only when autofillingPhoneNumber changes
// Creates a useEffect that will trigger on phoneNumber or autofillingPhoneNumber changes
// Tracks changes to notify phone number input when it needs to populate

function useEffectWhen(effect, deps, whenDeps) {
  const whenRef = useRef(whenDeps || []);
  const initial = whenRef.current === whenDeps;
  const whenDepsChanged = initial || !whenRef.current.every((w, i) => w === whenDeps[i]);
  whenRef.current = whenDeps;
  const nullDeps = deps.map(() => null);

  return useEffect(
    whenDepsChanged ? effect : () => {},
    whenDepsChanged ? deps : nullDeps
  );
}

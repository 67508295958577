import React from 'react';
import styled from 'styled-components/macro';
import {range} from 'lodash';

import Flex from 'components/common/Flex';
import FormLabel from 'components/common/FormLabel';
import RadioLabel from 'components/common/RadioInput/RadioLabel';

import {colors, secondaryFont} from 'styles/shared';

const StyledLabelContainer = styled.div`
  margin-bottom: 1.4rem;
`;

const StyledOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const StyledRadioLabel = styled(RadioLabel)`
  font-weight: bold;
`;

const StyledRadioInput = styled.input`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 0.1rem solid ${colors.darkBlue_light};
  position: relative;
  display: inline-block;
  outline: none;
  appearance: none;
  cursor: pointer;

  &:after {
    content: '${props => '\\00A0'}';
    position: absolute;
    top: 0.7rem;
    left: 0.7rem;
    width: 1.4rem;
    height: 1.4rem;
    background-color: ${props => (props.checked ? colors.orange_main : '')};
    border-radius: 50%;
  }
`;

const StyledOptionLabel = styled.div`
  margin-top: 1.7rem;
  ${secondaryFont};
  text-align: center;
`;

interface Props extends BaseProps {
  id: string;
  value?: number;
  name: string;
  label: string;
  firstOptionLabel?: string;
  lastOptionLabel?: string;
  scaleNumber?: number;
  onChange: OnChangeHandler;
}

LinearScale.defaultProps = {
  scaleNumber: 6,
  id: '',
  className: '',
  firstOptionLabel: '',
  lastOptionLabel: ''
};

function LinearScale({
  name,
  label,
  scaleNumber,
  firstOptionLabel,
  lastOptionLabel,
  onChange,
  value,
  id,
  className
}: Props) {
  let options = range(1, scaleNumber);

  return (
    <div id={id} className={className}>
      <StyledLabelContainer>
        <FormLabel>{label}</FormLabel>
      </StyledLabelContainer>

      <Flex justify="space-between">
        {options.map((option, index) => {
          let id = `${name}-${option}`;

          let isFirstIndex = index === 0;
          let isLastIndex = options.length - 1 === index;

          let isChecked = option === value;

          return (
            <StyledOptionContainer key={option}>
              <StyledRadioLabel id={id} label={option.toString()} />

              <StyledRadioInput
                type="radio"
                id={id}
                name={name}
                checked={isChecked}
                value={option}
                onChange={() => onChange(name, option)}
              />

              {isFirstIndex && firstOptionLabel && <StyledOptionLabel>{firstOptionLabel}</StyledOptionLabel>}
              {isLastIndex && lastOptionLabel && <StyledOptionLabel>{lastOptionLabel}</StyledOptionLabel>}
            </StyledOptionContainer>
          );
        })}
      </Flex>
    </div>
  );
}

export default LinearScale;

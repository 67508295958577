import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components/macro';

import utils from 'helpers/utils';

import Button from 'components/common/Button';
import TextInput from 'components/common/TextInput';
import FormHeader from 'components/common/FormHeader';
import RadioWithTextInput from 'components/common/RadioWithTextInput';
import LinearScale from 'components/common/LinearScale';

import {mainFont} from 'styles/shared';

const StyledSubtitle = styled.div`
  ${mainFont};
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 0.1rem;
  background: #c4c4c4;
  margin: 2rem 0;
`;

const StyledRadioWithTextInput = styled(RadioWithTextInput)`
  margin-bottom: 0 !important;
`;

const StyledOtherInput = styled(TextInput)`
  margin: 0;
  min-height: auto;
  margin-left: 0.5rem;
`;

const StyledTextInput = styled(TextInput)`
  min-height: auto;
`;

interface Survey {
  usageReason: string;
  otherUsageReason?: string;
  toolRate?: number;
  futureUsageReason: string;
  otherFuruteUsageReason?: string;
  whatFeatureYouLike: string;
  additionalFeature: string;
}

function SurveyForm() {
  const ACTION_LINK =
    'https://docs.google.com/forms/d/e/1FAIpQLScvyw24N9o41cpsYL7w-VFZCQJvpcUVnu8nTOnUKB0FwZrrFQ/formResponse';

  const FORM_KEY = {
    usageReason: 'entry.871993744',
    otherUsageReason: 'entry.871993744.other_option_response',
    toolRate: 'entry.1249303771',
    futureUsageReason: 'entry.1633914466',
    otherFuruteUsageReason: 'entry.1633914466.other_option_response',
    whatFeatureYouLike: 'entry.287164291',
    additionalFeature: 'entry.1785151151'
  };

  const [formData, setFormData] = useState<Survey>({
    usageReason: '',
    otherUsageReason: undefined,
    toolRate: undefined,
    futureUsageReason: '',
    otherFuruteUsageReason: undefined,
    whatFeatureYouLike: '',
    additionalFeature: ''
  });

  const timer = useRef<NodeJS.Timeout | null>(null);

  const usageOptions = [
    {
      value: 'I had shipment to prepare.',
      label: 'I had shipment to prepare.'
    },
    {
      value: 'I was just looking around.',
      label: 'I was just looking around.'
    },
    {
      value: 'Both.',
      label: 'Both.'
    },
    {
      value: '__other_option__',
      label: 'Other',
      alwaysVisible: true,
      element: (
        <StyledOtherInput
          name={FORM_KEY.otherUsageReason}
          value={formData.otherUsageReason}
          placeholder="Tell us about it!"
          onChange={(field, value) => onChange('otherUsageReason', value)}
        />
      )
    }
  ];

  const futureUsageOptions = [
    {
      value: 'Very likely.',
      label: 'Very likely.'
    },
    {
      value: 'This was one time shipping, so I will not use it any more.',
      label: 'This was one time shipping, so I will not use it any more.'
    },
    {
      value: 'I will not use it again because it did not help me.',
      label: 'I will not use it again because it did not help me.'
    },
    {
      value: '__other_option__',
      label: 'Other',
      alwaysVisible: true,
      element: (
        <StyledOtherInput
          name={FORM_KEY.otherFuruteUsageReason}
          value={formData.otherFuruteUsageReason}
          placeholder="Tell us about it!"
          onChange={(field, value) => onChange('otherFuruteUsageReason', value)}
        />
      )
    }
  ];

  useEffect(() => {
    return () => {
      if (timer?.current) clearInterval(timer.current);
    };
  }, []);

  function onSubmit() {
    timer.current = utils.setTimeout(() => {
      let event = new CustomEvent('surveySubmitted', {detail: {successful: true}});
      window.parent.document.dispatchEvent(event);
    }, 100);
  }

  function onChange(field, value) {
    setFormData({...formData, [field]: value});
  }

  function render() {
    return (
      <form action={ACTION_LINK} onSubmit={onSubmit}>
        <FormHeader>First Shipment Preparation</FormHeader>
        <StyledSubtitle>Thank you for taking time to share your thoughts & experience</StyledSubtitle>

        <StyledDivider />

        <StyledRadioWithTextInput
          name={FORM_KEY.usageReason}
          label="Did you have a shipment to prepare, or were just looking around?"
          options={usageOptions}
          value={formData.usageReason}
          onChange={(field, value) => onChange('usageReason', value)}
        />

        <StyledDivider />

        <LinearScale
          name={FORM_KEY.toolRate}
          label="If you were preparing shipment, how helpful was this tool?"
          firstOptionLabel="Not at all"
          lastOptionLabel="It made shipment preparation a lot easier"
          value={formData.toolRate}
          onChange={(field, value) => onChange('toolRate', value)}
        />

        <StyledDivider />

        <StyledRadioWithTextInput
          name={FORM_KEY.futureUsageReason}
          label="How likely is it that you will use this tool in the future?"
          options={futureUsageOptions}
          value={formData.futureUsageReason}
          onChange={(field, value) => onChange('futureUsageReason', value)}
        />

        <StyledDivider />

        <StyledTextInput
          name={FORM_KEY.whatFeatureYouLike}
          label="What is the one thing you liked the least about this tool, and why?"
          placeholder="Tell us about it!"
          value={formData.whatFeatureYouLike}
          onChange={(field, value) => onChange('whatFeatureYouLike', value)}
        />

        <StyledDivider />

        <StyledTextInput
          name={FORM_KEY.additionalFeature}
          label="If you could ask for one additional thing that this tool should offer you - what would that be?"
          placeholder="Tell us about it!"
          value={formData.additionalFeature}
          onChange={(field, value) => onChange('additionalFeature', value)}
        />

        <Button width={11.3} margin="4rem 0 0">
          Submit
        </Button>
      </form>
    );
  }

  return render();
}

export default SurveyForm;

const USER_ROLE = {
  CSR: 'ROLE_CSR',
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ADMIN: 'ROLE_ADMIN',
  CLIENT: 'ROLE_CLIENT',
  USER: 'ROLE_USER',
  GUEST: 'ROLE_GUEST'
};

export default USER_ROLE;

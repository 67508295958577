import React from 'react';
import styled from 'styled-components/macro';

import {colors, secondaryFont} from 'styles/shared';

const StyledReminderContainer = styled.div`
  padding: 1rem 2rem;
  height: 3.9rem;
  border: 0.1rem solid ${props => (props.active ? colors.green_main : colors.orange_main)};
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${props => (props.active ? colors.white : colors.darkBlue_main)};
  background-color: ${props => (props.active ? colors.green_main : 'transparent')};
  ${secondaryFont};
`;

interface Props {
  name: string;
  item: any;
  active: boolean;
  onChange: (field: string, value: any) => void;
}

function ReminderItem({name, item, active, onChange}: Props) {
  let onClick = () => {
    onChange(name, item.value);
  };

  return (
    <StyledReminderContainer active={active} onClick={onClick}>
      {item.label}
    </StyledReminderContainer>
  );
}

export default ReminderItem;

import {QUERY_KEY_DICTIONARY} from '../queryKeyDictionary';
import {useQueries} from '@tanstack/react-query';
import {getDefaultRequestOptions, BASE_URL} from '../temporaryQueryHelper';
import axios from 'axios';

export const useGetDocuments = (documentIds: string[], action: string) => {
  return useQueries(getDocumentsQuery(documentIds, action))
}

const getDocumentsQuery = (documentIds: string[], action: string) => ({
  queries: documentIds.map(documentId => {
    return {
      queryKey: [QUERY_KEY_DICTIONARY.GET_DOCUMENT_QUERY_KEY, documentId, action],
      queryFn: () => getDocument(documentId),
      enabled: !!(documentId && action),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 3
    }
  })
});

async function getDocument(documentId: string) {
  const { data } = await axios.get(`${BASE_URL}/shipment_documents/${documentId}`, getDefaultRequestOptions({}));
  return data
}
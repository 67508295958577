import {cloneDeep} from 'lodash';

import {
  SENDING_INITIAL,
  SHIPMENT_DETAILS_INITIAL,
  ADDITIONAL_SERVICES_INITIAL,
  PAYMENT_INITIAL
} from './shipmentDataEntity';

import shipmentDataEntity from './shipmentDataEntity';
import extras from './draftExtras';
import quoteExtras from 'components/get_quote/extras';

import dateHelper from 'helpers/dateHelper';
import unitHelper from 'helpers/unitHelper';
import addressHelper from 'helpers/addressHelper';

import ELECTRONIC_EXPORT_FILING from 'constants/electronicExportFilings';
import UNIT from 'constants/units';
import INVOICE_TYPE from 'constants/invoiceTypes';
import PICKUP_OPTION from 'constants/pickupOptions';
import COMMON from 'constants/common';
import MEASUREMENT_SYSTEM from 'constants/measurementSystems';
import CARRIER from 'constants/carriers';
import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import PACKAGING_TYPE from 'constants/packagingTypes/packagingTypes';
import lineItemHelper from 'helpers/lineItemHelper';

const DRAFT_VERSION: string = '0.5';
const SUPPORTED_VERSIONS: string[] = ['0.2', '0.3', '0.4'];

export default {
  createNew,
  createFromData,
  parse,
  mapFromShipment,
  mapFromPreparedOrderRequest,
  mapFromStandaloneQuote,
  mapToStandaloneQuote,
  getDefaultInvoiceItem: extras.getDefaultInvoiceItem,
  getDefaultPackage: extras.getDefaultPackage,
  createPackage: extras.createPackage,
  createInvoiceItem: extras.createInvoiceItem
};

const PICKUP_INITIAL = {
  shipmentDate: new Date(),
  isPickupRequired: true,
  carrierProduct: CARRIER.UPS_GROUND, //pre-select UPS Ground for domestic shipments
  pickupId: null,
  pickup: null,
  custom: null
};

function createNew() {
  let draftData = shipmentDataEntity.createNew();

  let draft = createFromData({
    ...draftData,
    version: DRAFT_VERSION,
    pickupDelivery: PICKUP_INITIAL,
    currency: UNIT.CURRENCY_USD,
    measurementSystem: MEASUREMENT_SYSTEM.IMPERIAL
  });

  return draft;
}

interface DraftLocal extends Draft {
  _steps: Step[];
}

function createFromData(draftData): Draft {
  let draft = shipmentDataEntity.createFromData(draftData) as DraftLocal;

  draft.shipmentDetails.products.packages = draft.shipmentDetails.products.packages.map(packageItem =>
    extras.createPackage(packageItem)
  );

  draft.shipmentDetails.products.items = draft.shipmentDetails.products.items.map(item =>
    extras.createInvoiceItem(item, draft.shipmentTo.country)
  );

  draft = addExtraMethods(draft);

  clearAlternativeDataSelection(draft);

  return draft;
}

function parse(jsonString) {
  try {
    const result = JSON.parse(jsonString);

    if (result.version === DRAFT_VERSION) return result;

    if (SUPPORTED_VERSIONS.includes(result.version)) {
      return convertDraftToLatestVersion(result);
    }

    return null;
  } catch (err) {
    return null;
  }
}

function mapFromShipment(shipment: Shipment, pickups: Pickup[]) {
  let pickupId: string | null = null;
  let pickup: Optional<PickupData>;

  for (let pickupItem of pickups) {
    let pickupShipment = pickupItem.shipments.find(shipmentId => shipmentId === shipment.id);

    if (pickupShipment) {
      pickupId = pickupItem.id;
      pickup = pickupItem;
    }
  }

  let shipmentDate = new Date();
  if (shipment?.targetShipDate && !dateHelper.isPastDate(shipment.targetShipDate)) {
    shipmentDate = shipment?.targetShipDate;
  }

  // For domestic shipments, pre-select UPS Ground, unless carrier product already selected.
  const carrierProduct: string = shipment.isDomestic && !shipment?.carrier?.productCode ?
    CARRIER.UPS_GROUND : shipment?.carrier?.productCode;

  const pickupDelivery: PickupDelivery = {
    shipmentDate,
    isPickupRequired: true,
    pickupId: pickupId,
    pickup,
    custom: pickup ? null : {pickupOption: PICKUP_OPTION.NOT_READY_SCHEDULE},
    carrierProduct
  };

  let draftData = {
    version: DRAFT_VERSION,
    shipmentFrom: cloneDeep(shipment.shipmentFrom),
    shipmentTo: cloneDeep(shipment.shipmentTo),
    shipmentDetails: cloneDeep(shipment.shipmentDetails),
    additionalServices: cloneDeep(shipment.additionalServices),
    payment: cloneDeep(shipment.payment),
    pickupDelivery,
    isDocumentsShipment: shipment.isDocumentsShipment,
    currency: shipment.currency,
    measurementSystem: shipment.measurementSystem
  };

  // Because we no longer allow external invoices, we must rewrite some values to defaults
  if (draftData.shipmentDetails.products.invoiceType === INVOICE_TYPE.EXTERNAL) {
    draftData.shipmentDetails.products.invoiceType = SHIPMENT_DETAILS_INITIAL.products.invoiceType;
    draftData.shipmentDetails.products.exportType = SHIPMENT_DETAILS_INITIAL.products.exportType;
  }

  draftData.shipmentDetails.products.items =
    lineItemHelper.removeUnusedQuantityUnits(draftData.shipmentDetails.products.items);

  // Map US States to two letter format for new select inputs
  draftData = mapUSStatesForDrafts(draftData);

  return createFromData(draftData);
}

function mapFromPreparedOrderRequest(shipmentRequest: ShipmentRequest) {
  const origin = shipmentRequest.origin;
  const destination = shipmentRequest.destination;
  const shipper = shipmentRequest.shipper;
  const receiver = shipmentRequest.receiver;
  const international = shipmentRequest.international;

  let shipmentFrom: Sending = {
    companyName: origin.companyName,
    contactName: origin.contactName,
    country: origin.country,
    phoneNumber: origin.phoneNumber,
    phoneNumberExtension: origin.phoneNumberExtension || '',
    email: origin.email,
    address: {
      addressLine1: origin.address,
      addressLine2: origin.addressLine2,
      addressLine3: origin.addressLine3,
      city: origin.city,
      postalCode: origin.postalCode,
      division: origin.division,
      suburb: origin.dependentLocality
    },
    formattedAddress: '',
    isResidential: origin.isResidential,
    isVATIncluded: shipper.vatNumber ? true : false,
    isTaxIdIncluded: shipper.taxId ? true : false,
    isEORINumberIncluded: shipper.eoriNumber ? true : false,
    VATNumber: shipper.vatNumber || '',
    taxId: shipper.taxId || '',
    EORINumber: shipper.eoriNumber || ''
  };

  let shipmentTo: Sending = {
    companyName: destination.companyName,
    contactName: destination.contactName,
    country: destination.country,
    phoneNumber: destination.phoneNumber,
    phoneNumberExtension: destination.phoneNumberExtension || '',
    email: destination.email,
    address: {
      addressLine1: destination.address,
      addressLine2: destination.addressLine2,
      addressLine3: destination.addressLine3,
      city: destination.city,
      postalCode: destination.postalCode,
      division: destination.division,
      suburb: destination.dependentLocality
    },
    formattedAddress: '',
    isResidential: destination.isResidential,
    isVATIncluded: receiver.vatNumber ? true : false,
    isTaxIdIncluded: receiver.taxId ? true : false,
    isEORINumberIncluded: receiver.eoriNumber ? true : false,
    VATNumber: receiver.vatNumber || '',
    taxId: receiver.taxId || '',
    EORINumber: receiver.eoriNumber || ''
  };

  let shipmentDetails: any = {...SHIPMENT_DETAILS_INITIAL};

  let isDocumentsShipment =
    shipmentRequest?.pieces?.length === 1 && shipmentRequest?.pieces[0]?.isDocument ? true : false;
  let piece: PieceRequest = shipmentRequest?.pieces[0];

  if (isDocumentsShipment) {
    shipmentDetails.documents.isDocumentWeightAboveHalfPound =
      piece?.weight?.value > COMMON.EXPRESS_DOCUMENTS_MAX_WEIGHT;

    shipmentDetails.documents.documentWeight = piece.weight;
    shipmentDetails.documents.isItJustDocument = true;
    if (piece?.quantity) shipmentDetails.documents.numberOfPackages = piece.quantity;
    if (piece?.packagingType) shipmentDetails.documents.typeOfPackaging = piece.packagingType;

    if (piece?.documentDeclaredValue) {
      shipmentDetails.documents.declaredShipmentValue = piece.documentDeclaredValue.amount;
    }

    shipmentDetails.documents.generalDescription = shipmentRequest.description;
    shipmentDetails.documents.isShipmentContainsDangerousGoods = false;
  } else {
    shipmentDetails.products.packages = shipmentRequest.pieces.map(pieceItem => {
      return extras.createPackage({
        weight: pieceItem.weight,
        height: pieceItem.depth,
        width: pieceItem.width,
        length: pieceItem.length,
        numberOfPackages: pieceItem.quantity
      });
    });

    shipmentDetails.products.isAnyBatteries = shipmentRequest.hasBatteries ? true : false;
    if (international?.declaredValue) {
      shipmentDetails.products.declaredShipmentValue = international.declaredValue.amount;
    }
    shipmentDetails.products.generalDescription = shipmentRequest.description;
    shipmentDetails.products.isShipmentContainsDangerousGoods = shipmentRequest.hasDangerousGoods ? true : false;

    let isShipmentReferenceNumberExists = shipmentRequest?.externalSourceReference ? true : false;
    shipmentDetails.products.isShipmentReferenceNumberExists = isShipmentReferenceNumberExists;
    if (isShipmentReferenceNumberExists) {
      shipmentDetails.products.shipmentReferenceNumber = shipmentRequest.externalSourceReference;
    }

    let isInvoiceNumberExists = international?.invoiceNumber ? true : false;
    shipmentDetails.products.isInvoiceNumberExists = isInvoiceNumberExists;
    if (isInvoiceNumberExists) shipmentDetails.products.invoiceNumber = international?.invoiceNumber;

    let isReceiverReferenceExists = shipmentRequest?.receiverReference ? true : false;
    shipmentDetails.products.isReceiverReferenceExists = isReceiverReferenceExists;
    if (isReceiverReferenceExists) shipmentDetails.products.receiverReference = shipmentRequest.receiverReference;

    let isAnyPackageMarks = international?.packageMarks ? true : false;
    shipmentDetails.products.isAnyPackageMarks = isAnyPackageMarks;
    if (isAnyPackageMarks) shipmentDetails.products.packageMarks = international?.packageMarks;

    let isAnyOtherRemarks = international?.packageRemarks1 ? true : false;
    shipmentDetails.products.isAnyOtherRemarks = isAnyOtherRemarks;
    if (isAnyOtherRemarks) shipmentDetails.products.otherRemarks = international?.packageRemarks1;

    if (international?.freightInvoiceCharge)
      shipmentDetails.products.freightCost = international.freightInvoiceCharge.amount;
    if (international?.insuranceInvoiceCharge) {
      shipmentDetails.products.insuranceCost = international.insuranceInvoiceCharge.amount;
    }
    if (international?.miscInvoiceCharge) shipmentDetails.products.miscCost = international.miscInvoiceCharge.amount;

    if (international?.exportLineItems) {
      shipmentDetails.products.items = international?.exportLineItems.map(exportItem => {
        let license = exportItem?.license;

        let invoiceData = {
          commodityCode: exportItem.commodityCode,
          itemDescription: exportItem.description,
          quantity: exportItem.quantity.amount,
          units: exportItem.quantity.unit,
          itemValue: exportItem.value.amount,
          itemMade: exportItem.countryOfOrigin,
          eccnValue: exportItem.eccn,
          exportLicenseNumber: license?.number || '',
          exportLicenseExpiryDate: license?.expiry || undefined,
          isLicenseSymbolRequired: license?.symbol ? true : false,
          licenseSymbol: license?.symbol || '',
          weight: exportItem.weight?.value ?? 0
        };
        return extras.createInvoiceItem(invoiceData, destination?.country);
      });
    }

    if (international?.exportType) shipmentDetails.products.exportType = international?.exportType;

    let invoiceType = international?.invoiceType;
    let isProformaInvoiceType = invoiceType?.includes(INVOICE_TYPE.PROFORMA);

    shipmentDetails.products.invoiceType = isProformaInvoiceType ? INVOICE_TYPE.PROFORMA : invoiceType;
    shipmentDetails.products.purposeOfShipment = isProformaInvoiceType ? invoiceType : '';

    let eeiFiling = international?.eeiFiling;
    if (eeiFiling) {
      let isItnNumberExists: any = undefined;
      if (eeiFiling.itn) isItnNumberExists = true;
      if (!eeiFiling.itn && eeiFiling.ein) isItnNumberExists = false;

      let electronicExportFiling = '';
      let foreignTradeRegulations = '';
      if (eeiFiling?.exemption) {
        electronicExportFiling = ELECTRONIC_EXPORT_FILING.OTHER;
        foreignTradeRegulations = eeiFiling?.exemption;
      } else {
        electronicExportFiling = ELECTRONIC_EXPORT_FILING.NO;
      }

      if (eeiFiling?.exemption === ELECTRONIC_EXPORT_FILING.DEFAULT_EXEMPTION) {
        electronicExportFiling = ELECTRONIC_EXPORT_FILING.EXEMPTED;
      }

      shipmentDetails.products.electronicExportFiling = electronicExportFiling;
      shipmentDetails.products.foreignTradeRegulations = foreignTradeRegulations;
      shipmentDetails.products.isItnNumberExists = isItnNumberExists;
      shipmentDetails.products.ITNNumber = eeiFiling.itn || '';
      shipmentDetails.products.EINNumber = eeiFiling.ein || '';
    }
  }

  let additionalServices: AdditionalServices = {
    doYouNeedInsurance: shipmentRequest.insuredValue ? true : false,
    insuranceValue: shipmentRequest?.insuredValue?.amount || 0,
    isNeutralDeliveryServiceRequired: shipmentRequest.neutralDeliveryServiceAdded ? true : false,
    isCertificateOfOriginRequired: shipmentRequest.certificateOfOriginAdded ? true : false,
    signature: '', //Fix-Mapping:
    isReturnLabel: undefined //Fix-Mapping:
  };

  let payment: Payment = {
    freightPayment: shipmentRequest.whoPaysShipping,
    shipperAccountNumber: shipmentRequest.billingAccountNumber,
    dutiesAndTaxesPayment: international?.whoPaysDuties || '',
    dutyAccountNumber: international?.dutyAccountNumber || '',
    customsTermsOfTrade: international?.termsOfTrade?.code || '',
    billingAccountNumberPostalCode: shipmentRequest?.billingAccountNumberPostalCode || '',
    termsAndConditionsAgreement: false
  };

  let pickupDelivery: PickupDelivery = {
    shipmentDate: shipmentRequest?.targetShipDate || new Date(),
    isPickupRequired: true,
    pickupId: null,
    custom: null,
    carrierProduct: shipmentRequest.carrierProduct
  };

  let draftData = {
    version: DRAFT_VERSION,
    shipmentFrom,
    shipmentTo,
    shipmentDetails,
    additionalServices,
    payment,
    pickupDelivery,
    isDocumentsShipment,
    currency: UNIT.CURRENCY_USD,
    measurementSystem: MEASUREMENT_SYSTEM.IMPERIAL
  };

  return createFromData(draftData);
}

function mapFromStandaloneQuote(quote: StandaloneQuote) {
  const sendingFrom = quote.sendingFrom;
  const sendingTo = quote.sendingTo;
  const isDomestic = sendingFrom.country === COUNTRY_CODE.USA && sendingTo.country === COUNTRY_CODE.USA;

  let shipmentFrom: Sending = {
    companyName: '',
    contactName: '',
    country: sendingFrom.country,
    phoneNumber: '',
    phoneNumberExtension: '',
    email: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: sendingFrom.city,
      postalCode: sendingFrom.postalCode,
      suburb: ''
    },
    formattedAddress: '',
    isResidential: false,
    isVATIncluded: false,
    isTaxIdIncluded: false,
    isEORINumberIncluded: false,
    VATNumber: '',
    taxId: '',
    EORINumber: ''
  };

  let shipmentTo: Sending = {
    companyName: '',
    contactName: '',
    country: sendingTo.country,
    phoneNumber: '',
    phoneNumberExtension: '',
    email: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: sendingTo.city,
      postalCode: sendingTo.postalCode,
      suburb: ''
    },
    formattedAddress: '',
    isResidential: sendingTo.isResidential,
    isVATIncluded: false,
    isTaxIdIncluded: false,
    isEORINumberIncluded: false,
    VATNumber: '',
    taxId: '',
    EORINumber: ''
  };

  let shipmentDetails: any = cloneDeep(SHIPMENT_DETAILS_INITIAL);

  const isDocumentsShipment = quote.isDocument;

  if (isDocumentsShipment) {
    let quantity = 0;
    let totalWeight = 0;

    quote.items.forEach(item => {
      quantity += item.quantity;
      totalWeight += item.weight * item.quantity;
    });

    shipmentDetails.documents.isItJustDocument = true;
    shipmentDetails.documents.typeOfPackaging = quote.usesExpressEnvelope ?
      PACKAGING_TYPE.DHL_EXPRESS_ENVELOPE : PACKAGING_TYPE.DOCUMENT;
    shipmentDetails.documents.isDocumentWeightAboveHalfPound = totalWeight > 0.5;
    shipmentDetails.documents.documentWeight = totalWeight > 0.5 ? totalWeight : 0;
    shipmentDetails.documents.numberOfPackages = quantity;
    shipmentDetails.documents.isShipmentContainsDangerousGoods = false;
  } else {
    shipmentDetails.products.packages = quote.items.map(packageItem => {
      return extras.createPackage({
        weight: packageItem.weight,
        height: packageItem.height,
        width: packageItem.width,
        length: packageItem.length,
        numberOfPackages: packageItem.quantity,
        declaredValue: isDomestic ? packageItem.documentDeclaredValue : undefined
      });
    });
    shipmentDetails.products.isShipmentContainsDangerousGoods = quote.hasDangerousGoods;
  }

  let pickupDelivery: PickupDelivery = {
    shipmentDate: quote.shipDate,
    isPickupRequired: true,
    pickupId: null,
    custom: null,
    carrierProduct: quote.carrierProduct
  };

  const insuranceValue = !isDomestic ? quote.insuranceValue : 0;

  let additionalServices: AdditionalServices = {
    doYouNeedInsurance: quote.insuranceValue ? true : false,
    insuranceValue: insuranceValue,
    isNeutralDeliveryServiceRequired: undefined,
    isCertificateOfOriginRequired: undefined,
    signature: '',
    isReturnLabel: undefined
  };

  let draftData = {
    version: DRAFT_VERSION,
    shipmentFrom,
    shipmentTo,
    shipmentDetails,
    additionalServices,
    payment: {...PAYMENT_INITIAL},
    pickupDelivery,
    isDocumentsShipment,
    currency: UNIT.CURRENCY_USD,
    measurementSystem: MEASUREMENT_SYSTEM.IMPERIAL
  };

  return createFromData(draftData);
}

function mapToStandaloneQuote(draft: Draft | undefined): StandaloneQuote {
  if (!draft) return quoteExtras.getInitialStandaloneQuote();

  const isDomestic = draft.shipmentFrom.country === COUNTRY_CODE.USA && draft.shipmentTo.country === COUNTRY_CODE.USA;

  let items = [] as Array<StandaloneItem>;
  const defaultPackage = extras.getDefaultPackage();

  if (draft.isDocumentsShipment) {
    items = [{
      quantity: draft.shipmentDetails.documents.numberOfPackages,
      weight: draft.shipmentDetails.documents.documentWeight,
      length: defaultPackage.length,
      width: defaultPackage.width,
      height: defaultPackage.height
    }];
  } else {
    items = draft.shipmentDetails.products.packages.map(packageItem => {
      return {
        weight: packageItem.weight,
        height: packageItem.height,
        width: packageItem.width,
        length: packageItem.length,
        quantity: packageItem.numberOfPackages,
        documentDeclaredValue: isDomestic ? packageItem.declaredValue : undefined
      }
    });
  }

  return {
    sendingFrom: {
      country: draft.shipmentFrom.country,
      city: draft.shipmentFrom.address.city,
      postalCode: draft.shipmentFrom.address.postalCode,
      isResidential: draft.shipmentFrom.isResidential
    },
    sendingTo: {
      country: draft.shipmentTo.country,
      city: draft.shipmentTo.address.city,
      postalCode: draft.shipmentTo.address.postalCode,
      isResidential: draft.shipmentTo.isResidential
    },
    shipDate: draft.pickupDelivery.pickup?.date || draft.pickupDelivery.shipmentDate,
    items: items,
    hasDangerousGoods: draft.hasDangerousGoods,
    isDocument: draft.isDocumentsShipment,
    usesExpressEnvelope: draft.shipmentDetails.documents.typeOfPackaging
      === PACKAGING_TYPE.DHL_EXPRESS_ENVELOPE,
    insuranceValue: !isDomestic ? draft.additionalServices.insuranceValue : 0,
    currency: draft.currency,
    measurementSystem: draft.measurementSystem,
    carrierProduct: draft.pickupDelivery.carrierProduct
  } as StandaloneQuote;
}

function addExtraMethods(draftToExtend: DraftLocal) {
  let draft: DraftLocal = {
    ...draftToExtend,
    clearAlternativeDataSelection() {
      clearAlternativeDataSelection(draft);
    }
  };

  return draft;
}

//helper methods

function clearAlternativeDataSelection(draft: Draft) {
  //shipment from section
  let shipmentFrom = draft.shipmentFrom;

  if (!shipmentFrom.isVATIncluded) shipmentFrom.VATNumber = SENDING_INITIAL.VATNumber;
  if (!shipmentFrom.isTaxIdIncluded) shipmentFrom.taxId = SENDING_INITIAL.taxId;
  if (!shipmentFrom.isEORINumberIncluded) shipmentFrom.EORINumber = SENDING_INITIAL.EORINumber;

  //shipment to section
  let shipmentTo = draft.shipmentTo;

  if (!shipmentTo.isVATIncluded) shipmentTo.VATNumber = SENDING_INITIAL.VATNumber;
  if (!shipmentTo.isTaxIdIncluded) shipmentTo.taxId = SENDING_INITIAL.taxId;
  if (!shipmentTo.isEORINumberIncluded) shipmentTo.EORINumber = SENDING_INITIAL.EORINumber;

  //shipment details section
  let shipmentDetails = draft.shipmentDetails;

  if (draft.isDocumentsShipment) {
    shipmentDetails.products = SHIPMENT_DETAILS_INITIAL.products;
  } else {
    shipmentDetails.documents = SHIPMENT_DETAILS_INITIAL.documents;

    let electronicExportFiling = shipmentDetails.products.electronicExportFiling;

    if (electronicExportFiling === ELECTRONIC_EXPORT_FILING.EXEMPTED) {
      shipmentDetails.products.foreignTradeRegulations = SHIPMENT_DETAILS_INITIAL.products.foreignTradeRegulations;
      shipmentDetails.products.isItnNumberExists = SHIPMENT_DETAILS_INITIAL.products.isItnNumberExists;
      shipmentDetails.products.EINNumber = SHIPMENT_DETAILS_INITIAL.products.EINNumber;
      shipmentDetails.products.ITNNumber = SHIPMENT_DETAILS_INITIAL.products.ITNNumber;
    }
    if (electronicExportFiling === ELECTRONIC_EXPORT_FILING.OTHER) {
      shipmentDetails.products.isItnNumberExists = SHIPMENT_DETAILS_INITIAL.products.isItnNumberExists;
      shipmentDetails.products.EINNumber = SHIPMENT_DETAILS_INITIAL.products.EINNumber;
      shipmentDetails.products.ITNNumber = SHIPMENT_DETAILS_INITIAL.products.ITNNumber;
    }
    if (electronicExportFiling === ELECTRONIC_EXPORT_FILING.NO) {
      shipmentDetails.products.foreignTradeRegulations = SHIPMENT_DETAILS_INITIAL.products.foreignTradeRegulations;
    }

    if (shipmentDetails.products.isItnNumberExists) {
      shipmentDetails.products.EINNumber = SHIPMENT_DETAILS_INITIAL.products.EINNumber;
    }
    if (shipmentDetails.products.isItnNumberExists === false) {
      shipmentDetails.products.ITNNumber = SHIPMENT_DETAILS_INITIAL.products.ITNNumber;
    }

    if (!draft.isProformaInvoiceType) {
      shipmentDetails.products.purposeOfShipment = SHIPMENT_DETAILS_INITIAL.products.purposeOfShipment;
    }

    if (!shipmentDetails.products.isShipmentReferenceNumberExists) {
      shipmentDetails.products.shipmentReferenceNumber = SHIPMENT_DETAILS_INITIAL.products.shipmentReferenceNumber;
    }

    if (!shipmentDetails.products.isInvoiceNumberExists) {
      shipmentDetails.products.invoiceNumber = SHIPMENT_DETAILS_INITIAL.products.invoiceNumber;
    }

    if (!shipmentDetails.products.isReceiverReferenceExists) {
      shipmentDetails.products.receiverReference = SHIPMENT_DETAILS_INITIAL.products.receiverReference;
    }

    if (!shipmentDetails.products.isAnyPackageMarks) {
      shipmentDetails.products.packageMarks = SHIPMENT_DETAILS_INITIAL.products.packageMarks;
    }

    if (!shipmentDetails.products.isAnyOtherRemarks) {
      shipmentDetails.products.otherRemarks = SHIPMENT_DETAILS_INITIAL.products.otherRemarks;
    }

    shipmentDetails.products.items.forEach((item: ProductItem) => {
      if (draft.isLicenseRequired) {
        if (!item.isLicenseDetailsRequired) {
          item.exportLicenseExpiryDate = undefined;
          item.isLicenseSymbolRequired = undefined;
          item.licenseSymbol = '';
        }

        if (!item.isLicenseSymbolRequired) item.licenseSymbol = '';

        if (!item.isEccnRequired) item.eccnValue = '';
      } else {
        item.eccnValue = '';
        item.exportLicenseNumber = '';
        item.exportLicenseExpiryDate = undefined;
        item.isLicenseSymbolRequired = undefined;
        item.licenseSymbol = '';
      }
    });
  }

  //additional section
  let additionalServices = draft.additionalServices;

  if (!additionalServices.doYouNeedInsurance) {
    additionalServices.insuranceValue = ADDITIONAL_SERVICES_INITIAL.insuranceValue;
  }

  //pickup delivery section
  let pickupDelivery = draft.pickupDelivery;

  if (!pickupDelivery.shipmentDate) {
    pickupDelivery.shipmentDate = new Date();
  }

  if (pickupDelivery.isPickupRequired) {
    pickupDelivery.custom = null;
  } else {
    delete pickupDelivery.pickup;
    pickupDelivery.pickupId = null;
  }

  if (pickupDelivery.isPickupRequired === false) {
    delete pickupDelivery.pickup;
  }

  //payment section
  let payment = draft.payment;

  if (!draft.isShipperAccountNumberRequired) {
    payment.shipperAccountNumber = PAYMENT_INITIAL.shipperAccountNumber;
  }

  if (!draft.isAssociatedZipCodeRequired) {
    payment.billingAccountNumberPostalCode = PAYMENT_INITIAL.billingAccountNumberPostalCode;
  }

  if (!draft.isDutyAccountNumberRequired) {
    payment.dutyAccountNumber = PAYMENT_INITIAL.dutyAccountNumber;
  }
}

function convertDraftToLatestVersion(payload): Draft {
  let result = cloneDeep(payload);

  if (result.version === '0.2') {
    const isDocumentsShipment = payload.isDocumentsShipment;
    const firstPackage = payload.shipmentDetails.products.packages[0];
    const weightUnit = payload.shipmentDetails.documents.documentWeight.unit;

    const measurementSystem: string = unitHelper.defineMeasurementSystem(isDocumentsShipment, firstPackage, weightUnit);

    result.shipmentDetails.documents.documentWeight = result.shipmentDetails.documents.documentWeight.value;
    result.shipmentDetails.products.packages.forEach(item => {
      item.height = item.height.value;
      item.width = item.width.value;
      item.length = item.length.value;
      item.weight = item.weight.value;
    });

    result.measurementSystem = measurementSystem;
  }

  if (result.version === '0.2' || result.version === '0.3') {
    // Map US States to two letter format for new select inputs
    result = mapUSStatesForDrafts(result);
  }

  result.shipmentDetails.products.items = lineItemHelper.removeUnusedQuantityUnits(result.shipmentDetails.products.items);

  result.version = DRAFT_VERSION;

  return result;
}

function mapUSStatesForDrafts(draft) {
  if (draft.shipmentFrom.country === COUNTRY_CODE.USA) {
    if (draft.shipmentFrom.address.division) {
      draft.shipmentFrom.address.division = addressHelper.reformatUSState(draft.shipmentFrom.address.division);
    }
  }

  if (draft.shipmentTo.country === COUNTRY_CODE.USA) {
    if (draft.shipmentTo.address.division) {
      draft.shipmentTo.address.division = addressHelper.reformatUSState(draft.shipmentTo.address.division);
    }
  }
  return draft;
}

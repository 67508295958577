import React from 'react';
import styled from 'styled-components/macro';

import {colors, mainFont, opacity, navBarHeight, houseAccountMessageHeight} from 'styles/shared';

const StyledContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: ${navBarHeight};
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${houseAccountMessageHeight};
  color: ${colors.white};
  ${mainFont};
  font-weight: bold;
  background-color: ${colors.orange_main};
  box-shadow: 0 0.4rem 0.5rem ${opacity(0.05)};
`;

function HouseAccountMessage() {
  return <StyledContainer>You are using House Account!</StyledContainer>;
}

export default HouseAccountMessage;

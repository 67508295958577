import React from 'react';
import styled from 'styled-components/macro';

import {colors, opacity} from 'styles/shared';

const StyledContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};
  border-radius: 0.4rem;
  margin-bottom: 3rem;
  padding: 2rem;
  min-height: 11.6rem;
  min-width: 25.8rem;
`;

SummaryContainer.defaultProps = {
  className: ''
};

function SummaryContainer({children, className}) {
  return <StyledContainer className={className}>{children}</StyledContainer>;
}

export default SummaryContainer;

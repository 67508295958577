import React from 'react';
import styled from 'styled-components/macro';

import EEI from 'constants/electronicExportFilings';
import COMMON from 'constants/common';

import FormHeader from 'components/common/FormHeader';
import SummaryContainer from './SummaryContainer';

import {colors, mainFont} from 'styles/shared';

const StyledContainer = styled(SummaryContainer)`
  max-height: 11.6rem;
  max-width: 25.8rem;
`;

const StyledType = styled.div`
  font-weight: 900;
  text-transform: uppercase;
  ${mainFont};
  color: ${colors.green_main};
  margin-top: 0.5rem;
`;

const StyledExemption = styled.div`
  ${mainFont};
`;
interface Props {
  data: Products;
}

function ElectronicExportInformation({data}: Props) {
  if (data.electronicExportFiling === EEI.NO && !data.ITNNumber && !data.EINNumber) return null;

  let type = '';
  let valueDisplay = COMMON.EMPTY_FIELD;

  if (data.ITNNumber) {
    type = 'ITN';
    valueDisplay = `by ITN: ${data.ITNNumber}`;
  } else if (data.EINNumber) {
    type = 'EIN';
    valueDisplay = `by EIN: ${data.EINNumber}`;
  } else {
    let exemption = '';

    if (data.electronicExportFiling === EEI.EXEMPTED) {
      exemption = EEI.DEFAULT_EXEMPTION;
    }
    if (data.electronicExportFiling === EEI.OTHER) {
      exemption = data.foreignTradeRegulations;
    }

    type = EEI.EXEMPTED;
    valueDisplay = `FTR ${exemption}`;
  }

  return (
    <StyledContainer>
      <FormHeader>EEI:</FormHeader>
      <StyledType>{type}</StyledType>
      <StyledExemption>{valueDisplay}</StyledExemption>
    </StyledContainer>
  );
}

export default ElectronicExportInformation;

import React from 'react';
import {Modal} from 'components/bootstrap';
import styled, {createGlobalStyle} from 'styled-components/macro';

import {GlobalMenuAction} from 'reducers/commonReducer';

import AppIcon from 'components/common/AppIcon';

import modalStyles from 'styles/customization/modal';

const StyledGlobalMenu = styled(Modal)`
  ${modalStyles.globalMenu};
`;

const StyledMenuAction = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledMenuTitle = styled.div`
  margin-right: 2rem;
`;

//global style to show body scroll
const GlobalStyle = createGlobalStyle`
  body {
    overflow: initial!important;
    padding-right: 0!important;
  }
`;

interface Props {
  visible: boolean;
  actions: GlobalMenuAction[];
  close?: () => void;
}

function GlobalMenu({visible, actions, close}: Props) {
  return (
    <>
      <StyledGlobalMenu show={visible} onHide={close}>
        {actions.map(item => (
          <StyledMenuAction key={item.title} onClick={item.action}>
            <StyledMenuTitle>{item.title}</StyledMenuTitle>
            <AppIcon name={item.icon} />
          </StyledMenuAction>
        ))}
      </StyledGlobalMenu>

      <GlobalStyle />
    </>
  );
}

export default GlobalMenu;

import React, {useState} from 'react';
import styled, {css} from 'styled-components/macro';

import ActionsPopupDropdown from './components/ActionsPopupDropdown';
import ContextMenu from 'components/common/ContextMenu';

import {colors} from 'styles/shared';

const StyledActionsBox = styled.div`
  color: ${colors.orange_main};
  display: flex;
  align-items: center;
  height: 4.6rem;
  padding: 0.1rem 0 0;
  cursor: pointer;
`;

const StyledDotsBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 3.6rem;
  width: 3.6rem;
  border-radius: 50%;
  background-color: ${colors.bg_orange_main};
  margin-left: 0.4rem;
  margin-right: 1rem;

  &:hover {
    background-color: ${colors.bg_orange_hover};

    > div {
      background-color: ${colors.orange_light};
    }
  }

  ${props =>
    props.isSelected &&
    css`
      background-color: ${colors.bg_orange_selected};

      > div {
        background-color: ${colors.orange_dark};
      }
    `};
`;

const StyledText = styled.div`
  align-self: center;
  font-weight: bold;
`;

const StyledDot = styled.div`
  background-color: ${colors.orange_main};
  border-radius: 50%;
  width: 0.4rem;
  height: 0.4rem;
  min-width: 0.4rem;
  min-height: 0.4rem;

  ${props =>
    props.isCentral &&
    css`
      margin-bottom: 0.4rem;
      margin-top: 0.4rem;
    `};
`;

interface Props extends BaseProps {
  actions: DropdownAction[];
  activeItem: User | Shipment | Draft | Pickup | Quote;
  labelVisible: boolean;
}

ActionsPopup.defaultProps = {
  labelVisible: true
};

function ActionsPopup({actions, activeItem, labelVisible, className}: Props) {
  const [isActionsPopupOpened, setIsActionsPopupOpened] = useState(false);

  function toggleActionsPopup(e) {
    setIsActionsPopupOpened(!isActionsPopupOpened);

    return e && e.preventDefault();
  }

  function hideActionsPopup() {
    setIsActionsPopupOpened(false);
  }

  return (
    <StyledActionsBox className={className} onClick={toggleActionsPopup}>
      {labelVisible && <StyledText>Actions</StyledText>}

      <ContextMenu onHide={hideActionsPopup}>
        {isActionsPopupOpened && <ActionsPopupDropdown actions={actions} activeItem={activeItem} />}
      </ContextMenu>

      <StyledDotsBox isSelected={isActionsPopupOpened}>
        <StyledDot />
        <StyledDot isCentral />
        <StyledDot />
      </StyledDotsBox>
    </StyledActionsBox>
  );
}

export default ActionsPopup;

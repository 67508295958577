import React from 'react';

function UPSTooltip() {
  return (
    <span>
      UPS prefers at least a 3 hour window for pickups.
    </span>
  );
}

export default UPSTooltip;

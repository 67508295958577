import React from 'react';
import ReactTooltip, {Place} from 'react-tooltip';
import styled from 'styled-components/macro';

import {colors, secondaryFont} from 'styles/shared';

const StyledContainer = styled.div`
  .tooltip-text {
    background-color: ${colors.darkBlue_main};
    color: ${colors.white};
    padding: 1.3rem 1.2rem;
    border-radius: 0.6rem;
    font-weight: 300;
    ${secondaryFont};
  }

  a {
    color: ${colors.orange_main};
  }
`;

interface Props extends BaseProps {
  id: string;
  placement: Place;
}

Tooltip.defaultProps = {
  placement: 'right'
};

function Tooltip({id, placement, children}: Props) {
  return (
    <StyledContainer>
      <ReactTooltip id={id} place={placement} effect="solid" clickable={true} delayHide={200} className="tooltip-text">
        {children}
      </ReactTooltip>
    </StyledContainer>
  );
}

export default Tooltip;

import React from 'react';
import styled from 'styled-components/macro';

import SearchInput from 'components/common/SearchInput';

import {colors, secondaryFont, leftPanel} from 'styles/shared';

const StyledContainer = styled.div`
  ${leftPanel};
  padding: 1.6rem 0.8rem;
`;

const StyledSearchInput = styled(SearchInput)`
  margin: 0 0 2rem;
  min-height: auto;

  & input {
    color: ${colors.blue_main};
    border-color: ${colors.blue_main};
    margin-bottom: 0;
    ${secondaryFont};
  }
`;

function SearchPanel() {
  return (
    <StyledContainer>
      <StyledSearchInput name="search" placeholder="Search Orders" value={''} onChange={() => null} />
    </StyledContainer>
  );
}

export default SearchPanel;

import {debounce} from 'lodash';

import * as actions from 'action_types/draftActionTypes';
import {APP_MESSAGE, APP_ERROR} from 'action_types/commonActionTypes';
import {DraftItem} from 'reducers/draftReducer';
import {AppState} from 'reducers';

import ERROR from 'constants/literals/errors';
import MESSAGE from 'constants/literals/messages';
import TIMER from 'constants/timers';
import PAYMENT_TIME from 'constants/paymentTime';

import helper from './actionHelper';

import uiHelper from 'helpers/uiHelper';
import utils from 'helpers/utils';
import config from 'config';
import validationHelper from 'helpers/validationHelper';

import authService from 'services/authService';
import heapAnalyticsService from 'services/heapAnalyticsService';

import apis from 'domain/apis';
import entities from 'domain/entities';

export default {
  createNewDraft,
  createDraftFromOrder,
  createDraftFromShipment,
  createDraftFromQuote,
  editExistingDraft,
  uploadDraftFromLocalStorage,
  cancelDraft,
  modifyDraft,
  validateDraft,
  validatePickupDataToSave,
  setPickupDataToSave,
  clearDraftState,
  loadDrafts,
  updateDraftsPageNumber,
  loadDraft,
  createDraft,
  updateDraft,
  removeDraft,
  loadDraftPickups,
  rateShipment,
  updateDisplayedRate,
  goToPreviousStep,
  goToDraftSection,
  goToStep,
  goToNextValidStep
};

function createNewDraft() {
  let draft = entities.draft.createNew();
  let pickupData = entities.pickup.getDefault();

  heapAnalyticsService.track('Shipment Prep Started');

  return helper.getAction(actions.DRAFT_CREATE_NEW, {draft, pickupData});
}

function createDraftFromOrder(draftFromOrder) {
  return helper.dispatchAsyncAction(dispatch => {
    dispatch(helper.getAction(actions.DRAFT_CREATE_FROM_ORDER));

    dispatch(initDraft(draftFromOrder));
  });
}

function createDraftFromShipment(draftFromShipment) {
  return helper.dispatchAsyncAction(dispatch => {
    dispatch(helper.getAction(actions.DRAFT_CREATE_FROM_SHIPMENT));

    dispatch(initDraft(draftFromShipment));
  });
}

function createDraftFromQuote(draftFromQuote) {
  return helper.dispatchAsyncAction(dispatch => {
    dispatch(helper.getAction(actions.DRAFT_CREATE_FROM_QUOTE));

    dispatch(initDraft(draftFromQuote));
  });
}

function editExistingDraft(existingDraft) {
  return helper.dispatchAsyncAction(dispatch => {
    dispatch(helper.getAction(actions.DRAFT_EDIT_EXISTING));

    const draftId = existingDraft?.id;

    let draftObject = entities.draft.parse(existingDraft.payload);

    //Fix-API: remove in future (server should create draft with client draft id)
    if (draftObject?.id && draftId && draftObject.id !== draftId) draftObject.id = draftId;

    dispatch(initDraft(draftObject, draftId));
  });
}

function uploadDraftFromLocalStorage(unsavedDraft) {
  return helper.dispatchAsyncAction(dispatch => {
    dispatch(helper.getAction(actions.DRAFT_UPLOAD_FROM_LOCAL_STORAGE));

    dispatch(initDraft(unsavedDraft));
  });
}

function cancelDraft() {
  return helper.dispatchAsyncAction(dispatch => {
    dispatch(helper.getAction(actions.DRAFT_CANCEL));

    dispatch(createNewDraft());
  });
}

let validateDebounced = debounce(dispatch => {
  dispatch(validateDraft());
}, TIMER.VALIDATION_SCHEDULE_DELAY);

function modifyDraft(innerObjectName: string | null, field: string, value: any) {
  return helper.dispatchAsyncAction(async (dispatch, getState) => {
    let state: AppState = getState();
    const getAddressSection = (state: AppState) => state.draft.current.lastModifiedAddressSection;
    const previousAddressSection = getAddressSection(state);

    dispatch(helper.getAction(actions.MODIFY_DRAFT, {innerObjectName, field, value}));

    state = getState();
    const draft = state.draft.current.data;
    const currentAddressSection = getAddressSection(state);

    if (previousAddressSection && !currentAddressSection && draft) {
      let response = await apis.validateAddress(draft[previousAddressSection].address);

      if (!response?.isValidAddress) {
        dispatch(helper.getAction(actions.SET_DRAFT_ADDRESS_ERRORS, {previousAddressSection}));
      }
    }

    if (state.draft.current.stepWasSubmitted) {
      validateDebounced(dispatch);
    }
  });
}

function validateDraft() {
  return helper.dispatchAsyncAction((dispatch, getState) => {
    dispatch(helper.getAction(actions.CLEAN_DRAFT));

    const state: AppState = getState();

    const draft = state.draft.current.data;
    const currentStep = state.draft.current.step;
    const countryOptions = state.shipmentOptions.countryOptions;
    const rate = state.draft.rate.current;
    const rates = state.draft.rate.list;
    const billingInfo = state.user.billingInfo;
    const paymentId = state.payment.paymentId;
    const paymentTime = state.payment.paymentTime;

    let paymentRequired = !paymentId;
    if (billingInfo?.canPayLater && paymentTime === PAYMENT_TIME.PAY_LATER) paymentRequired = false;

    if (!draft) return;

    const validationResult: ValidationResult = validationHelper.validateShipment(
      draft,
      currentStep,
      countryOptions,
      paymentRequired,
      paymentTime,
      rates,
      rate?.carrier
    );

    dispatch(helper.getAction(actions.DRAFT_VALIDATE, {errors: validationResult.errors}));

    return validationResult;
  });
}

function validatePickupDataToSave() {
  return helper.dispatchAsyncAction((dispatch, getState) => {
    const state: AppState = getState();

    const carrier = state.draft.rate.current?.carrier;
    const carrierProduct = state.draft.current?.data?.pickupDelivery.carrierProduct;
    const pickupData = state.draft.pickup.dataToSave;

    const validationResult: ValidationResult = validationHelper.validatePickupToSave(carrier, pickupData, carrierProduct);

    dispatch(helper.getAction(actions.PICKUP_VALIDATE, {errors: validationResult.errors}));

    return validationResult;
  });
}

const validateDebouncedPickup = debounce(dispatch => {
  dispatch(validatePickupDataToSave());
}, TIMER.VALIDATION_SCHEDULE_DELAY);

function setPickupDataToSave(pickupData?: PickupData) {
  return helper.dispatchAsyncAction((dispatch, getState) => {
    const state: AppState = getState();

    if (state.draft.pickup.pickupWasSubmitted) {
      validateDebouncedPickup(dispatch);
    }

    dispatch(helper.getAction(actions.SET_PICKUP_DATA_TO_SAVE, {pickupData}));
  });
}

function clearDraftState() {
  return helper.getAction(actions.CLEAR_DRAFT_STATE);
}

function loadDrafts(activePage: number, pageSize: number) {
  return helper.dispatchAsyncAction(async dispatch => {
    const result = await apis.draft.getDrafts(activePage, pageSize);

    let validDrafts: DraftItem[] = [];

    result.drafts.forEach(draft => {
      const draftObject = entities.draft.parse(draft.payload);

      if (draftObject) validDrafts.push(draft);
    });

    dispatch(helper.getAction(actions.LOAD_DRAFTS, {drafts: validDrafts, totalItems: result.totalItems}));
  });
}

function updateDraftsPageNumber(activePage: number) {
  return helper.getAction(actions.CHANGE_DRAFTS_PAGE, {activePage});
}

function loadDraft(id) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.draft.getDraft(id);

    const isDraftResponse = (object: any): object is DraftResponse => {
      return 'payload' in object;
    };

    if (isDraftResponse(response)) {
      dispatch(editExistingDraft(response));

      await authService.logCsrAsDraftOwner(response?.ownedById);

      return true;
    } else {
      dispatch(helper.getAction(APP_ERROR, {error: ERROR.DRAFT_NOT_FOUND}));
      return null;
    }
  });
}

function createDraft(draft) {
  return helper.dispatchAsyncAction(async () => {
    return await apis.draft.createDraft(draft);
  });
}

function updateDraft(draft) {
  return helper.dispatchAsyncAction(async () => {
    return await apis.draft.updateDraft(draft);
  });
}

function removeDraft(id) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.draft.deleteDraft(id);

    if (response?.success) dispatch(helper.getAction(APP_MESSAGE, {message: MESSAGE.REMOVE_DRAFT}));

    return response?.success ? true : false;
  });
}

function loadDraftPickups(address: Address, carrier?: string) {
  return helper.dispatchAsyncAction(async (dispatch, getState) => {
    const addressQueryString = getAddressQueryString(address);

    const state: AppState = getState();
    const pagination = state.pickup.pagination;

    const result = await apis.pickup.getPickups(
      pagination.activePage,
      pagination.pageSize,
      addressQueryString,
      carrier
    );

    dispatch(helper.getAction(actions.LOAD_DRAFT_PICKUPS, {pickups: result.pickups}));
  }, false);
}

function rateShipment(data) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.shipment.rateShipment(data);

    dispatch(helper.getAction(actions.LOAD_RATES, {rateInfo: response.rates, errorMessage: response.errorMessage}));
  }, false);
}

function updateDisplayedRate() {
  return helper.getAction(actions.LOAD_RATE);
}

function goToPreviousStep() {
  return helper.getAction(actions.DRAFT_GO_TO_PREVIOUS_STEP);
}

function goToDraftSection(step: string) {
  return helper.getAction(actions.DRAFT_GO_TO_SECTION, {step});
}

function goToNextValidStep() {
  return helper.getAction(actions.DRAFT_GO_TO_NEXT_VALID_STEP);
}

function goToStep(step: string, stepNumber: number) {
  return helper.dispatchAsyncAction(async (dispatch, getState) => {
    const state: AppState = getState();

    const currentStep = state.draft.current.step;
    const steps = state.draft.current.steps;

    const currentStepItem = steps.find(item => item.step === currentStep);

    if (currentStepItem && currentStepItem.stepNumber < stepNumber) {
      const validationResult: ValidationResult = await dispatch(validateDraft());

      if (!validationResult.valid) return;
    }

    dispatch(helper.getAction(actions.DRAFT_GO_TO_STEP, {step}));
  });
}

//helper methods

function getAddressQueryString(address: Address) {
  if (!address.addressLine1) return '';

  let addressParts = [address.addressLine1, address.city, address.division, address.postalCode];

  let addressLine = addressParts.join('`');

  return addressLine;
}

function initDraft(draftData, draftId = null) {
  return helper.dispatchAsyncAction(async dispatch => {
    if (!draftId) draftId = utils.getRandomUid();

    if (!draftData.id) draftData.id = draftId;

    let draftObject: Draft = entities.draft.createFromData(draftData);

    await dispatch(loadDraftPickups(draftObject.shipmentFrom.address));

    heapAnalyticsService.track('Shipment Prep Started');

    dispatch(helper.getAction(actions.SET_DRAFT, {draftObject}));

    //set initial data for pickupData object
    if (!draftObject.pickupDelivery?.pickup && !draftObject.pickupDelivery?.pickupId) {
      dispatch(setPickupDataToSave(entities.pickup.getDefault()));
    }

    if (config.isDevLocal) uiHelper.logMessage(draftObject);
  });
}

import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {isEmpty} from 'lodash';

import orderActions from 'actions/orderActions';

import ORDER_STATUS from 'constants/orderStatuses';
import PATH from 'constants/path';

import NoOrders from './components/NoOrders';
import ActionsPanel from './components/ActionsPanel';
import OrdersList from './components/OrdersList';

import {colors, mainFont, middleFont} from 'styles/shared';

const StyledWrapper = styled.div`
  background-color: ${colors.white};
  padding: 2.6rem 0;
  margin-bottom: 3rem;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: 1.6rem;
`;

const StyledTitle = styled.div`
  ${middleFont};
  font-weight: bold;
  color: ${colors.darkBlue_main};
  margin: 0 2rem 0 1rem;
`;

const StyledLink = styled.div`
  ${mainFont};
  color: ${colors.orange_main};
  cursor: pointer;
`;

interface Props {
  displayOrders: any[];
  activeTab: string;
  onReload: () => void;
  onTabChange: (tab: string) => void;
  onAddNewOrder: () => void;
}

function OrdersWrapper({displayOrders, activeTab, onReload, onTabChange, onAddNewOrder}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [checkedOrders, setCheckedOrders] = useState({});

  async function shipSelectedOrder() {
    if (!onlyOneOrderSelected()) return;

    let orderId = Object.keys(checkedOrders).find(key => checkedOrders[key]);

    let isSuccess: any = await dispatch(orderActions.getPreparedRequestForOrder(orderId));

    if (isSuccess) history.push(PATH.PREPARE_SHIPMENT);
  }

  function onCheckboxClick(name, value) {
    setCheckedOrders({...checkedOrders, [name]: value});
  }

  function onlyOneOrderSelected() {
    if (isEmpty(checkedOrders)) return false;

    let checkedCount = Object.keys(checkedOrders).filter(key => checkedOrders[key]).length;

    if (checkedCount === 1) return true;

    return false;
  }

  function getTitle() {
    switch (activeTab) {
      case ORDER_STATUS.AWAITING_PAYMENT:
        return 'Awaiting Payment';
      case ORDER_STATUS.ON_HOLD:
        return 'On Hold';
      case ORDER_STATUS.AWAITING_SHIPMENT:
        return 'Awaiting Shipment';
      case ORDER_STATUS.PROCESSED:
        return 'Shipped';
      case ORDER_STATUS.CANCELLED:
        return 'Cancelled';
      default:
        return '';
    }
  }

  function anyOrders() {
    return displayOrders.length > 0;
  }

  function render() {
    let title = getTitle();

    return (
      <StyledWrapper>
        <StyledHeader>
          <StyledTitle>{title}</StyledTitle>
          <StyledLink onClick={onReload}>Reload</StyledLink>
        </StyledHeader>

        <ActionsPanel action={shipSelectedOrder} disabled={!onlyOneOrderSelected()} onAddNewOrder={onAddNewOrder} />

        {anyOrders() ? (
          <OrdersList orders={displayOrders} selectedOrders={checkedOrders} onCheck={onCheckboxClick} />
        ) : (
          <NoOrders title={title} activeTab={activeTab} onTabChange={onTabChange} />
        )}
      </StyledWrapper>
    );
  }

  return render();
}

export default OrdersWrapper;

import React from 'react';
import {Row, Col} from 'components/bootstrap';

import FEATURE from 'constants/features';
import SHIPMENT_STATUS from 'constants/shipmentStatuses';

import dateHelper from 'helpers/dateHelper';

import ListItemMobile from 'components/view_history/mobile/components/ListItemMobile';

interface Props {
  shipment: Shipment;
}

function ShipmentRowMobile({shipment}: Props) {
  const shipmentFrom = shipment.shipmentFrom;
  const shipmentTo = shipment.shipmentTo;

  const shipmentDate = dateHelper.displayShortDate(shipment.targetShipDate);
  const status = shipment?.primaryStatusFlag;

  return (
    <Row>
      <Col>
        <ListItemMobile label="Origin" value={shipmentFrom.country} />
        <ListItemMobile label="Contact" value={shipmentFrom.contactName} />
        <ListItemMobile label="Ship Date" value={shipmentDate} />
        <ListItemMobile label="Track Nr." value={shipment?.trackingNumber} bold />
      </Col>
      <Col>
        <ListItemMobile label="Destination" value={shipmentTo.country} />
        <ListItemMobile label="Receiver" value={shipmentTo.contactName} />
        <ListItemMobile label="Service" value={shipment?.carrier?.name} />
        {FEATURE.UNUSED_HISTORY_COLUMNS_VISIBLE && (
          <ListItemMobile
            label="Status"
            value={shipment?.primaryStatusFlagLabel?.toUpperCase()}
            bold
            error={status === SHIPMENT_STATUS.ERROR}
            success={status === SHIPMENT_STATUS.DELIVERED}
            info={status === SHIPMENT_STATUS.AWAITING_PICKUP || status === SHIPMENT_STATUS.IN_TRANSIT}
          />
        )}
      </Col>
    </Row>
  );
}

export default ShipmentRowMobile;

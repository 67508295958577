import {isEmpty} from 'lodash';

import {LOAD_PICKUPS, CHANGE_PICKUPS_PAGE} from 'action_types/pickupsActonTypes';
import {CHECK_PICKUP_CAPABILITY} from 'action_types/draftActionTypes';
import {APP_ERROR, APP_MESSAGE} from 'action_types/commonActionTypes';

import draftActions from 'actions/draftActions';
import uiHelper from 'helpers/uiHelper';

import MESSAGE from 'constants/literals/messages';
import ERROR from 'constants/literals/errors';
import CARRIER from 'constants/carriers';

import helper from './actionHelper';
import apis from 'domain/apis';

export default {
  loadPickups,
  updatePickupsPageNumber,
  cancelPickupAction,
  schedulePickup,
  scheduleStandalonePickup,
  schedulePickupForShipment,
  editPickup,
  addToPickupAction,
  checkPickupCapability,
  validatePickup
};

function loadPickups(activePage: number, pageSize: number) {
  return helper.dispatchAsyncAction(async dispatch => {
    const result = await apis.pickup.getPickups(activePage, pageSize);

    dispatch(helper.getAction(LOAD_PICKUPS, {...result}));
  });
}

function updatePickupsPageNumber(activePage: number) {
  return helper.getAction(CHANGE_PICKUPS_PAGE, {activePage});
}

function cancelPickupAction(pickupId) {
  return helper.dispatchAsyncAction(async (dispatch, getState) => {
    const response = await apis.pickup.cancelPickup(pickupId);

    if (response?.successful && isEmpty(response?.errors)) {
      const state = getState();
      const pagination = state.pickup.pagination;

      await dispatch(loadPickups(pagination.activePage, pagination.pageSize));

      dispatch(helper.getAction(APP_MESSAGE, {message: MESSAGE.CANCEL_PICKUP}));
    } else {
      const errorMessage = getErrorMessage(response?.errors);

      dispatch(helper.getAction(APP_ERROR, {error: errorMessage || ERROR.CANCEL_PICKUP_ERROR}));
    }
  });
}

function schedulePickup(pickup: ConfirmPickupDto) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.pickup.schedulePickup(pickup);
    if (!response) return;

    handlePickupResponse(dispatch, response);
  });
}

function scheduleStandalonePickup(data) {
  return helper.dispatchAsyncAction(async () => {
    const response = await apis.pickup.scheduleStandalonePickup(data);
    return response;
  });
}

function schedulePickupForShipment(pickup) {
  return helper.dispatchAsyncAction(async (dispatch, getState) => {
    const response = await apis.pickup.schedulePickupForShipment(pickup);

    handlePickupResponse(dispatch, response);

    if (response?.successful && isEmpty(response?.errors)) {
      const state = getState();
      const pagination = state.pickup.pagination;

      await dispatch(loadPickups(pagination.activePage, pagination.pageSize));
    }
  });
}

function editPickup(pickup: PickupData, pickupId) {
  return helper.dispatchAsyncAction(async (dispatch, getState) => {
    const response = await apis.pickup.editPickup(pickup, pickupId);

    handlePickupResponse(dispatch, response, true);

    if (response?.successful && isEmpty(response?.errors)) {
      const state = getState();
      const pagination = state.pickup.pagination;

      await dispatch(loadPickups(pagination.activePage, pagination.pageSize));
    }
  });
}

function addToPickupAction(data: AddShipmentToPickupDto) {
  return helper.dispatchAsyncAction(async () => {
    const response = await apis.pickup.addToPickup(data);

    if (response?.success) uiHelper.showNotice(MESSAGE.ADD_SHIPMENT_TO_PICKUP);
  });
}

function checkPickupCapability(data, returnInsteadOfStoreValue?: boolean) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.pickup.checkPickupCapability(data);

    if (returnInsteadOfStoreValue) return response;

    dispatch(helper.getAction(CHECK_PICKUP_CAPABILITY, {data: response}));
  }, false);
}

function validatePickup(data: ValidatePickupDto) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.pickup.validatePickup(data);

    if (!response) {
      dispatch(helper.getAction(APP_ERROR, {error: 'Pickup cannot be scheduled.'}));
      return;
    }

    const newPickupDelivery = {...data.draft.pickupDelivery};

    newPickupDelivery.pickup = data.pickup;
    newPickupDelivery.pickupId = null;

    await dispatch(draftActions.modifyDraft(null, 'pickupDelivery', newPickupDelivery));

    await dispatch(draftActions.setPickupDataToSave(undefined));

    let message = MESSAGE.VALIDATE_PICKUP;

    if (data.carrier === CARRIER.UPS || data.carrier === CARRIER.FED_EX) {
      message = MESSAGE.VALIDATE_PICKUP_DOMESTIC;
    }

    dispatch(
      helper.getAction(APP_MESSAGE, {message: message})
    );
  });
}

//helper methods

function handlePickupResponse(dispatch, response: PickupSchedulingResult, editMode = false) {
  if (!response.successful || !isEmpty(response.errors)) {
    let errorMessage =
      'Sorry, there was a problem getting pickup for your shipment. Please make sure your information is correct and contact Customer Service if you need help.';

    if (!isEmpty(response.errors)) {
      errorMessage = getErrorMessage(response.errors);
    }

    dispatch(helper.getAction(APP_ERROR, {error: errorMessage}));
    return;
  }

  const successMessage = editMode ? MESSAGE.UPDATE_PICKUP : MESSAGE.CONFIRM_PICKUP;

  dispatch(helper.getAction(APP_MESSAGE, {message: successMessage}));
}

function getErrorMessage(errors: PickupSchedulingError[]) {
  return isEmpty(errors) ? '' : errors[0].message;
}

import React from 'react';
import styled from 'styled-components/macro';

import AppIcon from 'components/common/AppIcon';

const StyledContainer = styled.div`
  cursor: pointer;
`;

interface Props extends BaseProps {
  onClick: () => void;
}

function CloseButton({onClick, className}: Props) {
  return (
    <StyledContainer onClick={onClick} className={className}>
      <AppIcon name="close" />
    </StyledContainer>
  );
}

export default CloseButton;

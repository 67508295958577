import React from 'react';
import styled, {css} from 'styled-components/macro';

import formatHelper from 'helpers/formatHelper';

import {colors, secondaryFont, mainFont} from 'styles/shared';

export const StyledLabel = styled.div`
  ${secondaryFont};
  color: ${colors.darkBlue_light};
`;

export const StyledValue = styled.div`
  margin-bottom: 1rem;
  ${mainFont};

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}

  ${props =>
    props.error &&
    css`
      color: ${colors.red_main};
    `}

  ${props =>
    props.success &&
    css`
      color: ${colors.green_main};
    `}

  ${props =>
    props.info &&
    css`
      color: ${colors.orange_main};
    `}
`;

interface Props {
  label: string;
  value: any;
  bold?: boolean;
  error?: boolean;
  success?: boolean;
  info?: boolean;
}

ListItemMobile.defaultProps = {
  bold: false,
  error: false,
  success: false,
  info: false
};

function ListItemMobile({label, value, bold, error, success, info}: Props) {
  const displayValue = formatHelper.displayValue(value);

  return (
    <>
      <StyledLabel>{label}</StyledLabel>
      <StyledValue bold={bold} error={error} success={success} info={info}>
        {displayValue}
      </StyledValue>
    </>
  );
}

export default ListItemMobile;

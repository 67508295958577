import React from 'react';
import styled from 'styled-components/macro';

import AppIcon from 'components/common/AppIcon';
import Checkbox from 'components/common/Checkbox';

import {colors, mainFont, opacity} from 'styles/shared';

const StyledContainer = styled.div`
  margin-top: 2rem;
  width: 16.2rem;
  height: 24.4rem;
  background: ${colors.white};
  box-sizing: border-box;
  border-radius: 0.4rem;
  box-shadow: ${props => (props.active ? `0 0.4rem 2.4rem ${opacity(0.25)}` : `0 0.4rem 2rem ${opacity(0.12)}`)};
  padding: 1rem;
  cursor: pointer;
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13rem;
  background: ${colors.grey_light};
  margin-top: 1rem;
  margin-bottom: 0.7rem;
`;

const StyledTitleContainer = styled.div`
  height: 4.4rem;
`;

const StyledTitle = styled.div`
  margin-top: 1rem;
  font-weight: bold;
  ${mainFont};
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.7rem;
`;

interface Props {
  id: string;
  title: string;
  isSelected: boolean;
  selectAction: (id: string) => void;
}

function DocumentItem({id, title, isSelected, selectAction}: Props) {
  let checkboxName = `cb_${id}`;

  let onSelect = () => {
    selectAction(id);
  };

  return (
    <StyledContainer htmlFor={checkboxName} active={isSelected} onClick={onSelect}>
      <StyledTitleContainer>
        <StyledTitle>{title}</StyledTitle>
      </StyledTitleContainer>

      <StyledIconContainer>
        <AppIcon name="document" />
      </StyledIconContainer>

      <StyledFooter>
        <Checkbox key={id} name={checkboxName} value={isSelected} onToggle={onSelect} />
      </StyledFooter>
    </StyledContainer>
  );
}

export default DocumentItem;

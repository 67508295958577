import React from 'react';

import styled from 'styled-components/macro';
import {Row, Col} from 'components/bootstrap';
import {get} from 'lodash';

import FormLabel from 'components/common/FormLabel';
import Toggler from 'components/common/Toggler';
import TextInput from 'components/common/TextInput';
import TogglerWithLabels from 'components/common/TogglerWithLabels';
import Flex from 'components/common/Flex';
import QuestionMark from 'components/common/Marks/QuestionMark';
import VATTooltip from 'components/tooltips/VATTooltip';
import TaxIdTooltip from 'components/tooltips/TaxIdTooltip';
import EORITooltip from 'components/tooltips/EORITooltip';

import {defaultInputHeight} from 'styles/shared';

const StyledTogglerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 3.4rem;
`;

const StyledInternationalTogglers = styled.div`
  margin-bottom: 4rem;
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: baseline;
`;

const StyledFormLabel = styled(FormLabel)`
  margin-left: 2rem;
`;

const StyledTextInput = styled(TextInput)`
  margin: 0;
  min-height: calc(${defaultInputHeight} + 4rem);
  width: 90%;
`;

interface Props {
  sending: Sending;
  path?: string;
  isDomestic: boolean;
  isShipper: boolean;
  errors: object;
  onChange: OnChangeHandler;
}

function Togglers({sending, path = '', isDomestic, isShipper, errors, onChange}: Props) {
  const destinationLabel = isShipper ? 'Shipper' : 'Receivers';

  function getError(key) {
    if (path) return get(errors, `${path}.${key}`);

    return get(errors, key);
  }

  function render() {
    const tooltipPlacement = 'left';

    return (
      <>
        <StyledTogglerRow>
          <FormLabel required={true}>Is it Residential?</FormLabel>
          <TogglerWithLabels name="isResidential" value={sending.isResidential} namePrefix={path} onToggle={onChange} />
        </StyledTogglerRow>

        {!isDomestic && (
          <StyledInternationalTogglers>
            <StyledRow>
              <Col lg={6}>
                <Flex>
                  <Toggler name="isVATIncluded" value={sending.isVATIncluded} namePrefix={path} onToggle={onChange} />
                  <StyledFormLabel>{`Include ${destinationLabel} VAT?`}</StyledFormLabel>
                </Flex>
              </Col>

              <Col lg={6}>
                <Flex justify="space-between" align="baseline">
                  <StyledTextInput
                    name="VATNumber"
                    placeholder="00000000000"
                    value={sending.VATNumber}
                    disabled={!sending.isVATIncluded}
                    onChange={onChange}
                    error={getError('VATNumber')}
                  />

                  <QuestionMark id="vat-tooltip" tooltip={VATTooltip()} placement={tooltipPlacement} />
                </Flex>
              </Col>
            </StyledRow>

            <StyledRow>
              <Col lg={6}>
                <Flex>
                  <Toggler
                    name="isTaxIdIncluded"
                    value={sending.isTaxIdIncluded}
                    namePrefix={path}
                    onToggle={onChange}
                  />
                  <StyledFormLabel>{`Include ${destinationLabel} TAX ID?`}</StyledFormLabel>
                </Flex>
              </Col>

              <Col lg={6}>
                <Flex justify="space-between" align="baseline">
                  <StyledTextInput
                    name="taxId"
                    placeholder="000-000-0000"
                    value={sending.taxId}
                    disabled={!sending.isTaxIdIncluded}
                    onChange={onChange}
                    error={getError('taxId')}
                  />

                  <QuestionMark id="tax-tooltip" tooltip={TaxIdTooltip()} placement={tooltipPlacement} />
                </Flex>
              </Col>
            </StyledRow>

            <StyledRow>
              <Col lg={6}>
                <Flex>
                  <Toggler
                    name="isEORINumberIncluded"
                    value={sending.isEORINumberIncluded}
                    namePrefix={path}
                    onToggle={onChange}
                  />
                  <StyledFormLabel>{`Include ${destinationLabel} EORI nr.?`}</StyledFormLabel>
                </Flex>
              </Col>

              <Col lg={6}>
                <Flex justify="space-between" align="baseline">
                  <StyledTextInput
                    name="EORINumber"
                    placeholder="AB 000 000 000 00000"
                    value={sending.EORINumber}
                    disabled={!sending.isEORINumberIncluded}
                    onChange={onChange}
                    error={getError('EORINumber')}
                  />

                  <QuestionMark id="eori-tooltip" tooltip={EORITooltip()} placement={tooltipPlacement} />
                </Flex>
              </Col>
            </StyledRow>
          </StyledInternationalTogglers>
        )}
      </>
    );
  }

  return render();
}

export default Togglers;

import React from 'react';

import styled from 'styled-components/macro';

import {colors, secondaryFont} from 'styles/shared';

const StyledOptionLabel = styled.label`
  color: ${colors.darkBlue_main};
  cursor: pointer;
  ${secondaryFont};
`;

interface Props extends BaseProps {
  id: string;
  label: string;
}

function RadioLabel({id, label, className}: Props) {
  return (
    <StyledOptionLabel htmlFor={id} className={className}>
      {label}
    </StyledOptionLabel>
  );
}

export default RadioLabel;

import React from 'react';
import styled from 'styled-components/macro';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import userActions from 'actions/userActions';

import FEATURE from 'constants/features';
import PATH from 'constants/path';

import FormHeader from 'components/common/FormHeader';
import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import {mediaQueries, mainFont} from 'styles/shared';

const StyledPageContainer = styled.div`
  text-align: center;
  margin-top: 6rem;

  @media ${mediaQueries.mobileMax} {
    margin-top: 3rem;
    width: 100%;
    padding: 0 1.5rem;

    & img {
      width: inherit;
    }
  }
`;

const StyledHeader = styled(FormHeader)`
  margin-bottom: 2rem;
`;

const StyledText = styled.div`
  ${mainFont};

  & span {
    font-weight: bold;
  }
`;

const StyledButtonContainer = styled(Flex)`
  margin: 3rem 0 6rem;
`;

function GuestLandingPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  async function signUp() {
    await dispatch(userActions.signOut());
    history.push(PATH.SIGN_UP);
  }

  return (
    <StyledPageContainer>
      {FEATURE.GUEST_LANDING_PAGE_3_0 ? (
        <>
          <StyledHeader>Thank you for your interest in Shipping with us!</StyledHeader>
          <StyledText>
            <span>We will create Freight Quote shortly and get back to you.</span>
            <br />
            <br />
            Did you know that our customers can <span>save 40% on average</span> versus retail? <br />
            Additionally, as a customer, you can use a whole suite of free tools: <br />
            <span>Quote Tool, Pickup-Scheduling Tool, Shipment Tracking</span> on top of <br />
            our friendly and knowledgeable <span>customer service team</span>!
          </StyledText>
        </>
      ) : (
        <>
          <StyledHeader>Thank you for your interest in shipping with OptimalShip!</StyledHeader>
          <StyledText>
            <span>Please allow 1-2 days for us to source the best freight quote option for you.</span>
            <br />
            <br />
            Did you know that in addition to freight, Optimal Ship can help you ship small parcel <br />
            (under 2200 lbs internationally & 150 lbs domestically)?
            <br />
            <br />
            We are here to help you with all of your shipping needs, big or small. We have an unbeatable <br />
            customer service team that is always happy to help. Our goal is to simplify the entire shipping <br />
            process for you.
          </StyledText>
        </>
      )}

      <StyledButtonContainer justify="center">
        <Button autoWidth onClick={signUp}>
          Sign Up to become a customer
        </Button>
      </StyledButtonContainer>

      <img src="/images/landing.png" alt="Landing" />
    </StyledPageContainer>
  );
}

export default GuestLandingPage;

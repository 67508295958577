import React, {useState} from 'react';
import {isEmpty} from 'lodash';
import styled from 'styled-components/macro';
import {Row, Col} from 'components/bootstrap';

import dateHelper from 'helpers/dateHelper';

import Flex from 'components/common/Flex';
import Button from 'components/common/Button';
import NoIssueDetected from './components/NoIssueDetected';
import IssueDetailsMobile from './components/IssueDetailsMobile';
import ListItemMobile from '../ListItemMobile';

import {colors} from 'styles/shared';

const StyledContainer = styled.div`
  margin-top: 2.2rem;
`;

const StyledIssueContainer = styled.div`
  margin: 1rem;
  background: ${colors.red_lighter};
  border: 0.1rem solid ${colors.red_main};
  box-sizing: border-box;
  border-radius: 0.4rem;
  padding: 0.8rem 1rem 0;
`;

const StyledButtonRow = styled(Flex)`
  margin: 1.2rem 0.6rem 2rem;
`;

interface Props {
  statusesNeedAttention: Shipment[];
}

function ShipmentStatusesNeedAttentionMobile({statusesNeedAttention}: Props) {
  const [issueDetailsVisible, setIssueDetailsVisible] = useState<boolean>(false);

  function toggleIssueDetails() {
    setIssueDetailsVisible(!issueDetailsVisible);
  }

  function renderIssueItem(shipment: Shipment) {
    const shipmentFrom = shipment.shipmentFrom;
    const shipmentTo = shipment.shipmentTo;
    const shipmentDate = dateHelper.displayShortDate(shipment.targetShipDate);

    return (
      <StyledIssueContainer key={shipment.id}>
        <Row>
          <Col>
            <ListItemMobile label="Origin" value={shipmentFrom.country} />
            <ListItemMobile label="Contact" value={shipmentFrom.contactName} />
            <ListItemMobile label="Ship Date" value={shipmentDate} />
            <ListItemMobile label="Track Nr." value={shipment.trackingNumber} bold />
          </Col>
          <Col>
            <ListItemMobile label="Destination" value={shipmentTo.country} />
            <ListItemMobile label="Receiver" value={shipmentTo.contactName} />
            <ListItemMobile label="Service" value={shipment?.carrier?.name} />
            <ListItemMobile label="Status" value={shipment?.primaryStatusFlagLabel?.toUpperCase()} bold error />
          </Col>
        </Row>

        <StyledButtonRow justify="flex-end">
          <Button autoWidth theme="error" onClick={toggleIssueDetails}>
            See Details
          </Button>
        </StyledButtonRow>
      </StyledIssueContainer>
    );
  }

  function render() {
    if (isEmpty(statusesNeedAttention)) return <NoIssueDetected />;

    return (
      <StyledContainer>
        {statusesNeedAttention.map((shipment: Shipment) => {
          return renderIssueItem(shipment);
        })}

        {issueDetailsVisible && <IssueDetailsMobile visible={issueDetailsVisible} close={toggleIssueDetails} />}
      </StyledContainer>
    );
  }
  return render();
}

export default ShipmentStatusesNeedAttentionMobile;

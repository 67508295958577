import React from 'react';

function Icon() {
  return (
    <svg width="12" height="14" fill="none" viewBox="0 0 12 14">
      <path
        fill="#fff"
        d="M1 4v-.5H.5V4H1zm10.354.354a.5.5 0 000-.708L8.172.464a.5.5 0 10-.708.708L10.293 4 7.464 6.828a.5.5 0 10.708.708l3.182-3.182zM1.5 14V4h-1v10h1zM1 4.5h10v-1H1v1z"></path>
    </svg>
  );
}

export default Icon;

import React from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';

import dateHelper from 'helpers/dateHelper';

import Flex from 'components/common/Flex';
import ButtonActionsPopup from 'components/common/ActionsPopup/ButtonActionsPopup';
import ItemContainerMobile from '../ItemContainerMobile';
import ListItemMobile from '../ListItemMobile';

const StyledButtonContainer = styled(Flex)`
  padding: 1rem 0.6rem 2rem 0;
`;

interface Props {
  quotes: Quote[];
  actions: DropdownAction[];
}

function QuotesListMobile({quotes, actions}: Props) {
  return (
    <>
      {quotes.map((quote: Quote) => {
        const dateDisplay = dateHelper.displayShortDate(quote.createdOn);

        return (
          <ItemContainerMobile key={quote.id}>
            <Row>
              <Col>
                <ListItemMobile label="Origin" value={quote.requestOriginCountryCode} />
                <ListItemMobile label="Date Created" value={dateDisplay} />
              </Col>
              <Col>
                <ListItemMobile label="Destination" value={quote.requestDestinationCountryCode} />
                <StyledButtonContainer justify="flex-end">
                  <ButtonActionsPopup actions={actions} activeItem={quote} />
                </StyledButtonContainer>
              </Col>
            </Row>
          </ItemContainerMobile>
        );
      })}
    </>
  );
}

export default QuotesListMobile;

import React from 'react';

function TypeOfPackagingTooltip() {
  return (
    <span>
      You may be charged at 1 lb rate if not in a <br /> DHL Express Envelope. You can request the
      <br />
      driver bring one, and OS is always happy to
      <br /> order you DHL shipping supplies
    </span>
  );
}

export default TypeOfPackagingTooltip;

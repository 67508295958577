import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  const color = colors.darkBlue_main;

  return (
    <svg width="44" height="45" fill="none" viewBox="0 0 44 45">
      <rect width="25" height="23" x="9.5" y="12.441" stroke={color} rx="1.5"></rect>
      <rect width="1" height="26" x="35" y="15.941" fill={color} rx="0.5" transform="rotate(90 35 15.941)"></rect>
      <rect width="2" height="5" x="15" y="8.941" fill={color} rx="1"></rect>
      <rect width="2" height="5" x="27" y="8.941" fill={color} rx="1"></rect>
      <mask id="path-5-inside-1" fill={colors.white}>
        <path
          fillRule="evenodd"
          d="M16.697 30.195a.498.498 0 000 .706.502.502 0 00.708 0L22 26.32l4.583 4.596a.498.498 0 00.706 0 .502.502 0 000-.708l-4.581-4.594 4.612-4.596a.498.498 0 000-.706.502.502 0 00-.709 0l-4.61 4.594-4.597-4.611a.498.498 0 00-.706 0 .502.502 0 000 .708l4.595 4.609-4.596 4.582z"
          clipRule="evenodd"></path>
      </mask>
      <path
        fill={colors.white}
        fillRule="evenodd"
        d="M16.697 30.195a.498.498 0 000 .706.502.502 0 00.708 0L22 26.32l4.583 4.596a.498.498 0 00.706 0 .502.502 0 000-.708l-4.581-4.594 4.612-4.596a.498.498 0 000-.706.502.502 0 00-.709 0l-4.61 4.594-4.597-4.611a.498.498 0 00-.706 0 .502.502 0 000 .708l4.595 4.609-4.596 4.582z"
        clipRule="evenodd"></path>
      <path
        fill={color}
        d="M16.697 30.9l-.706.709.706-.708zm0-.705l-.706-.709.706.709zm.708.706l.706.708-.706-.708zM22 26.32l.708-.706-.706-.708-.708.706.706.708zm4.583 4.596l-.708.707.708-.706zm.706 0l.708.707-.708-.706zm0-.708l.708-.706-.708.706zm-4.581-4.594l-.706-.708-.709.706.707.708.708-.706zm4.612-4.596l-.706-.709.706.709zm0-.706l.706-.708-.706.708zm-.709 0l.706.708-.706-.708zm-4.61 4.594l-.707.706.706.708.708-.706-.706-.708zm-4.597-4.611l-.709.706.709-.706zm-.706 0l-.709-.706.709.706zm0 .708l.708-.706-.708.706zm4.595 4.609l.706.708.709-.706-.706-.708-.709.706zm-3.89 4.58a.502.502 0 010 .71l-1.412-1.417a1.498 1.498 0 000 2.123l1.412-1.417zm-.704 0a.498.498 0 01.703 0l-1.411 1.416a1.502 1.502 0 002.12 0l-1.412-1.417zm4.595-4.58l-4.595 4.58 1.412 1.416 4.595-4.58-1.412-1.416zm5.997 4.599l-4.583-4.597-1.417 1.412 4.584 4.597 1.416-1.412zm-.71 0a.502.502 0 01.71 0l-1.416 1.412a1.498 1.498 0 002.122 0l-1.416-1.412zm0 .704a.498.498 0 010-.704l1.416 1.412a1.502 1.502 0 000-2.12l-1.416 1.412zM22 26.32l4.58 4.595 1.417-1.413-4.581-4.594L22 26.321zm4.614-6.01l-4.612 4.596 1.412 1.416 4.611-4.596-1.411-1.417zm0 .71a.502.502 0 010-.71l1.412 1.416a1.498 1.498 0 000-2.122l-1.412 1.416zm.703 0a.498.498 0 01-.703 0l1.412-1.416a1.502 1.502 0 00-2.12 0l1.411 1.416zm-4.61 4.594l4.61-4.594-1.412-1.416-4.61 4.594 1.413 1.416zm-6.012-4.613l4.599 4.611 1.416-1.412-4.598-4.611-1.417 1.412zm.71 0a.502.502 0 01-.71 0l1.417-1.412a1.498 1.498 0 00-2.123 0l1.417 1.412zm0-.704a.498.498 0 010 .704L15.99 19.59a1.502 1.502 0 000 2.12l1.417-1.412zm4.597 4.609l-4.596-4.61-1.417 1.413 4.596 4.61 1.417-1.413zm-4.6 5.996L22 26.321l-1.412-1.416-4.596 4.581 1.412 1.417z"
        mask="url(#path-5-inside-1)"></path>
    </svg>
  );
}

export default Icon;

import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {useSelector, useDispatch} from 'react-redux';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';

import config from 'config';
import mailHelper from 'helpers/mailHelper';

import supportService from 'services/supportService';
import stateStorageService from 'services/stateStorageService';

import FormLabel from 'components/common/FormLabel';
import Button from 'components/common/Button';
import ActionLink from 'components/common/ActionLink';

import {colors, opacity, mediaQueries, secondaryFont} from 'styles/shared';

const StyledContainer = styled.div`
  position: fixed;
  top: 13.2rem;
  right: 1rem;
  background: ${colors.white};
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};
  border-radius: 0.6rem;
  width: 25.8rem;
  padding: 1.6rem 1.6rem 2rem;

  @media ${mediaQueries.mobileMax} {
    display: none;
  }

  @media ${mediaQueries.laptopMin} {
    right: 8%;
  }
`;

const StyledText = styled.div`
  ${secondaryFont};
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.8rem;
`;

const StyledRow = styled.div`
  ${secondaryFont};
  margin-top: 1rem;
`;

function BrowserHelp() {
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user.current as User);
  const currentStep = useSelector((state: AppState) => state.draft.current?.step);
  const rate = useSelector((state: AppState) => state.draft.rate.current);
  const draft = useSelector((state: AppState) => state.draft.current.data);
  const isExistingDraft = useSelector((state: AppState) => state.draft.current.isExistingDraft);

  const [previewMode, setPreviewMode] = useState(true);

  const text = previewMode
    ? 'Experiencing any problems with performance? Switch to Chrome and let us know!'
    : 'Thank you! If you need any additional help just reach out to our Customer Support!';

  async function sendReport() {
    const metadata = supportService.getMetadata(
      user,
      draft?.id,
      isExistingDraft,
      rate?.id,
      currentStep,
      stateStorageService.getRequestId()
    );

    supportService.logToRollbar('Support Requested', metadata, user);
    setPreviewMode(false);
  }

  function callAction() {
    window.open(`tel:${config.supportPhone.value}`);
  }

  function sendEmail() {
    const supportLink = mailHelper.getHelpEmailLink();

    window.location.href = supportLink;
  }

  function startChat() {
    dispatch(commonActions.toggleIntercomChat());
  }

  return (
    <StyledContainer>
      <FormLabel>OptimalShip shipping tools work best with Google Chrome!</FormLabel>

      <StyledText>{text}</StyledText>

      {previewMode ? (
        <StyledButtonContainer>
          <Button width={19.4} onClick={sendReport}>
            Send Report
          </Button>
        </StyledButtonContainer>
      ) : (
        <>
          <StyledRow>
            <div>Phone:</div>
            <ActionLink title={config.supportPhone.label} isInheritFont={true} onClick={callAction} />
          </StyledRow>
          <StyledRow>
            <div>Email:</div>{' '}
            <ActionLink title={config.supportUrl} isInheritFont={true} onClick={sendEmail} />
          </StyledRow>
          <StyledRow>
            <div>Chat:</div> <ActionLink title="Start chat" isInheritFont={true} onClick={startChat} />
          </StyledRow>
        </>
      )}
    </StyledContainer>
  );
}

export default BrowserHelp;

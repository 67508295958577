import React, {Fragment} from 'react';
import formatHelper from 'helpers/formatHelper';

import CheckBoxInput from 'components/common/CheckboxInput';

import * as Styled from 'styles/table';

interface Props {
  orders: any[];
  selectedOrders?: any;
  onCheck: (name: string, value: any) => void;
}

function OrdersList({orders, selectedOrders, onCheck}: Props) {
  return (
    <Styled.Table>
      <thead>
        <Styled.TRowHead topBorder>
          <Styled.THeadCell />

          <Styled.THeadCell>Destination</Styled.THeadCell>
          <Styled.THeadCell>Name</Styled.THeadCell>
          <Styled.THeadCell>Order Date</Styled.THeadCell>
          <Styled.THeadCell>Order Content</Styled.THeadCell>
          <Styled.THeadCell>Total Weight</Styled.THeadCell>
          <Styled.THeadCell>Carrier / Service</Styled.THeadCell>
          <Styled.THeadCell>Ship. Cost</Styled.THeadCell>
          <Styled.THeadCell>Order ID</Styled.THeadCell>
          <Styled.THeadCell />
        </Styled.TRowHead>
      </thead>

      <tbody>
        {orders.map(order => {
          const id = order.id;
          const address = order.shippingAddress;

          let checkboxValue = selectedOrders[id] || false;

          let destination = `${address.city}, ${address.country}`;

          return (
            <Fragment key={id}>
              <Styled.TRowBody>
                <Styled.TBodyCell>
                  <CheckBoxInput name={id} value={checkboxValue} onToggle={onCheck} />
                </Styled.TBodyCell>
                <Styled.TBodyCell>{destination}</Styled.TBodyCell>
                <Styled.TBodyCell>{formatHelper.displayValue(order.customerName)}</Styled.TBodyCell>
                <Styled.TBodyCell>{formatHelper.displayValue(order.orderDate)}</Styled.TBodyCell>
                <Styled.TBodyCell>{formatHelper.displayValue(order.description)}</Styled.TBodyCell>
                <Styled.TBodyCell>{formatHelper.displayValue(order.totalWeight)}</Styled.TBodyCell>
                <Styled.TBodyCell>{formatHelper.displayValue(order.service)}</Styled.TBodyCell>
                <Styled.TBodyCell bold>{formatHelper.displayValue(order.totalSum)}</Styled.TBodyCell>
                <Styled.TBodyCell>{id}</Styled.TBodyCell>
              </Styled.TRowBody>
            </Fragment>
          );
        })}
      </tbody>
    </Styled.Table>
  );
}

export default OrdersList;

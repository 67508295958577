import config from '../../config';
import authService from '../../services/authService';

export const BASE_URL = `${config.baseUrl}`;

export function getDefaultRequestOptions(options) {
  let contentType = config.useStubs ? 'application/json' : 'application/ld+json';

  let requestOptions: any = {
    headers: {
      'Content-Type': contentType,
      accept: contentType
    },
    credentials: 'same-origin'
  };

  const authorization = options.auth !== false;

  if (authorization) {
    requestOptions.headers.Authorization = getAuthHeader();
  }

  return requestOptions;
}

function getAuthHeader() {
  let jwt = authService.getToken();
  return `Bearer ${jwt}`;
}

import React from 'react';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';

import config from 'config';
import mailHelper from 'helpers/mailHelper';

import * as styled from './ContactUs.styled';

interface Props {
  close: () => void;
  message?: string;
  color?: 'red' | 'grey';
}

function ContactUs({close, message, color = 'red'}: Props) {
  const dispatch = useDispatch();

  function callAction() {
    window.open(`tel:${config.supportPhone.value}`);
  }

  function sendEmail() {
    const supportLink = mailHelper.getHelpEmailLink();

    window.location.href = supportLink;
  }

  function startChat() {
    dispatch(commonActions.toggleIntercomChat());
    close();
  }

  return (
    <styled.container>
      <styled.header color={color}>
        <strong>Contact Us</strong>
      </styled.header>
      <styled.body color={color}>
        <div>
          <strong>{message || 'Please contact our Customer Service team for assistance.'}</strong>
        </div>
        <div>
          Call us now: <styled.actionLink title={config.supportPhone.label} onClick={callAction} />
        </div>
        <div>
          Live chat with a Person: <styled.actionLink title="Start chat" onClick={startChat} />
        </div>
        <div>
          Email: <styled.actionLink title={config.supportUrl} onClick={sendEmail} />
        </div>
      </styled.body>
    </styled.container>
  );
}

export default ContactUs;

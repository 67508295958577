import LICENSE_TYPE from 'constants/licenseTypes';
import COUNTRY_CODE from 'constants/countryCodes/countryCodes';

export default {
  createPackage,
  createInvoiceItem,
  //NOTE: consider to be inside Draft object
  getDefaultPackage,
  getDefaultInvoiceItem,
  calculatePackagesTotalWeight,
  calculateLineItemsTotalWeight,
  hasNewPickupRequest
};

function createPackage(packageData) {
  let packageItem: Package = {
    ...packageData,
    get isEmpty() {
      return !packageItem?.height && !packageItem?.width && !packageItem?.length && !packageItem?.weight;
    }
  };

  return packageItem;
}

function createInvoiceItem(invoiceItemData, countryTo = '') {
  let invoiceItem: ProductItem = {
    ...invoiceItemData,
    get isLicenseDetailsRequired() {
      return (
        invoiceItemData.exportLicenseNumber && invoiceItemData.exportLicenseNumber !== LICENSE_TYPE.NO_LICENSE_REQUIRED
      );
    },
    get isEccnRequired() {
      if (invoiceItem?.isLicenseDetailsRequired) return true;

      return (
        countryTo === COUNTRY_CODE.RUSSIA ||
        countryTo === COUNTRY_CODE.CHINA ||
        countryTo === COUNTRY_CODE.VENEZUELA ||
        countryTo === COUNTRY_CODE.HONG_KONG
      );
    },
    get isEmpty() {
      return (
        !invoiceItem?.commodityCode &&
        !invoiceItem?.itemDescription &&
        !invoiceItem?.quantity &&
        !invoiceItem?.units &&
        !invoiceItem?.itemValue &&
        !invoiceItem?.itemMade &&
        !invoiceItem?.weight
      );
    }
  };

  return invoiceItem;
}

function getDefaultPackage() {
  const packageDefault = {
    weight: 0,
    height: 0,
    width: 0,
    length: 0,
    numberOfPackages: 1,
    declaredValue: 0
  };

  return createPackage(packageDefault);
}

function getDefaultInvoiceItem() {
  const itemDefault = {
    commodityCode: '',
    itemDescription: '',
    quantity: 0,
    units: '',
    itemValue: 0,
    itemMade: '',
    eccnValue: '',
    exportLicenseNumber: '',
    exportLicenseExpiryDate: undefined,
    isLicenseSymbolRequired: undefined,
    licenseSymbol: '',
    weight: 0
  };

  return createInvoiceItem(itemDefault);
}

function calculatePackagesTotalWeight(packages: Package[]): number {
  let totalWeight = 0;

  packages.forEach(item => {
    totalWeight += item.numberOfPackages * item.weight;
  });

  return totalWeight;
}

function calculateLineItemsTotalWeight(items: ProductItem[]): number {
  const totalWeight = items.reduce((total: number, item: ProductItem): number => {
    return total + item.quantity * (item.weight ?? 0);
  }, 0);

  return totalWeight;
}

function hasNewPickupRequest(draft: Draft): boolean {
  if (draft.pickupDelivery.pickup && !draft.pickupDelivery.pickupId) return true;

  return false;
}

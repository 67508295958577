import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.css';
import 'flatpickr/dist/flatpickr.min.css';
import 'rc-slider/assets/index.css';

import {routes} from './routes';
import App from './components/App';
import configureStore from './store';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

export const store = configureStore();
const queryClient = new QueryClient({})

ReactDOM.render(
  <Provider store={store}>
      <BrowserRouter basename="/">
        <QueryClientProvider client={queryClient}>
          <App routes={routes} />
        </QueryClientProvider>
      </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

import React, {useState} from 'react';
import {Nav, Navbar} from 'components/bootstrap';
import {Link, useLocation, useHistory} from 'react-router-dom';
import styled from 'styled-components/macro';
import {useSelector, useDispatch} from 'react-redux';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';

import FEATURE from 'constants/features';
import PATH from 'constants/path';
import config from 'config';

import navigationHelper, {
  ALLOWED_INACTIVE_USER_URLS,
  ALLOWED_URLS,
  DISALLOWED_SUSPENDED_USER_URLS
} from 'helpers/navigationHelper';

import authService from 'services/authService';

import Flex from 'components/common/Flex';
import ContextMenu from 'components/common/ContextMenu';
import AppIcon from 'components/common/AppIcon/AppIcon';
import NavigationDropdown from './components/NavigationDropdown';
import MobileToggler from './components/MobileToggler';
import TrackingNumber from './components/TrackingNumber';

import {colors, navBarHeight, secondaryFont, opacity, mediaQueries} from 'styles/shared';
import uiHelper from 'helpers/uiHelper';

const MARGIN_X_MAX = '2.8rem';
const MARGIN_X_MIN = '1rem';

const StyledNavbar = styled(Navbar)`
  background: ${colors.white};
  box-shadow: 0 0 0.5rem ${opacity(0.05)};
  height: ${navBarHeight};
  padding: 0;
  padding-left: 2.2rem;

  @media ${mediaQueries.mobileMax} {
    padding: 0 1rem 0 1.6rem;
  }

  @media ${mediaQueries.desktopMax} {
    padding-left: 0;
  }
`;

const StyledLogo = styled(Navbar.Brand)`
  cursor: pointer;
  margin-right: 8rem;

  @media ${mediaQueries.desktopLargeMax} {
    margin-right: 3rem;
  }

  @media ${mediaQueries.desktopMax} {
    margin: ${MARGIN_X_MIN};
  }
`;

const StyledNavLinkContainer = styled(Flex)`
  height: ${navBarHeight};
  padding: 0 1rem;
  margin: 0 0.5rem;
  border-bottom: ${props => (props.active ? `0.4rem solid ${colors.orange_main}` : `0.4rem solid transparent`)};

  @media ${mediaQueries.desktopLargeMax} {
    margin: 0;
  }
`;

const StyledNavLink = styled(Nav.Link)`
  text-align: center;
  color: ${colors.darkBlue_main};
  ${secondaryFont};

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const StyledBillPayButton = styled.div`
  background: ${colors.white};
  box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.15);
  border-radius: 0.8rem;
  padding: 0.5rem 0.8rem 0.5rem 1.6rem;
  display: flex;
  align-items: center;
  margin-right: ${MARGIN_X_MAX};
  white-space: nowrap;
  cursor: pointer;
  ${secondaryFont};

  &:hover {
    background-color: ${colors.orange_light};
  }

  @media ${mediaQueries.desktopMax} {
    margin-right: ${MARGIN_X_MIN};
    padding: 1.6rem 0.8rem;
    text-align: center;
    white-space: normal;
    min-width: 5rem;

    > svg {
      display: none;
    }
  }
`;

const StyledNavbarNav = styled(Nav)`
  display: flex;
  align-items: center;
`;

const StyledAvatar = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  margin: 0 ${MARGIN_X_MAX};
  border: 0.2rem solid ${colors.darkBlue_light};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  @media ${mediaQueries.desktopMax} {
    margin: 0 ${MARGIN_X_MIN};
  }
`;

const StyledNoAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #ffdcd2;
  font-weight: bold;
  font-size: 2.4rem;
  letter-spacing: 0.3px;
  color: ${colors.orange_main};
`;

interface Props {
  hideNavLinks?: boolean;
}

export function goToBillPay() {
  window.open(config.portalPayment, '_blank');
}

function Navigation({hideNavLinks}: Props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user.current as User);
  const isQuickAccessAuth = useSelector((state: AppState) => state.common.isQuickAccessAuth);

  const [navDropdownVisible, setNavDropdownVisible] = useState(false);

  function handleClickAction(e, url: string) {
    // Show inactive user notice if inactive user tries to navigate to prepare shipment or schedule pickup
    if (user && !user.active) {
      if (!ALLOWED_INACTIVE_USER_URLS.includes(url)) {
        dispatch(commonActions.infoAction(uiHelper.getInactiveUserNotice()));
      }
    }
    else if (user && (user.suspended && !user.whitelisted)) {
      if (DISALLOWED_SUSPENDED_USER_URLS.includes(url)) {
        dispatch(commonActions.infoAction(uiHelper.getSuspendedUserNotice()));
        return;
      }
    }

    //show Quote Sign in/up Module for guest users OR show Password Protected Modal for Quick Quote users, when they are trying to navigate
    if (!user.isGuest && !isQuickAccessAuth) return;

    if (ALLOWED_URLS.includes(url)) return;

    if (e) e.preventDefault();

    if (user.isGuest) dispatch(commonActions.toggleGuestAuthModal(true, url));
    if (isQuickAccessAuth) dispatch(commonActions.togglePasswordProtectedModal(true, url));
  }

  function renderNavLink(url, text) {
    const active = location.pathname === url;

    return (
      <StyledNavLinkContainer align="center" active={active}>
        <StyledNavLink as={Link} href={url} to={url} onClick={e => handleClickAction(e, url)}>
          {text}
        </StyledNavLink>
      </StyledNavLinkContainer>
    );
  }

  function renderBillPayButton() {
    return (
      <StyledBillPayButton onClick={goToBillPay}>
        Go to Bill Pay
        <AppIcon name="paid" />
      </StyledBillPayButton>
    );
  }

  function toggleDropdown() {
    setNavDropdownVisible(!navDropdownVisible);
  }

  function redirectToHomePage() {
    if (user.isGuest) {
      dispatch(commonActions.toggleGuestAuthModal(true, PATH.HOME));
      return;
    }
    if (isQuickAccessAuth) {
      dispatch(commonActions.togglePasswordProtectedModal(true, PATH.HOME));
      return;
    }

    const authContext: AuthContext = authService.getAuthContext();

    const historyPath = navigationHelper.getLandingUrl(authContext?.isCsrPersonalAccount);
    history.push(historyPath);
  }

  function render() {
    return (
      <>
        <StyledNavbar expand="md" fixed="top">
          <StyledLogo onClick={redirectToHomePage}>
            <AppIcon name="logo" />
          </StyledLogo>

          <MobileToggler />

          <Navbar.Collapse>
            {!hideNavLinks && (
              <Nav>
                {renderNavLink(navigationHelper.getViewHistoryPageLink(), 'View History')}

                {renderNavLink(PATH.PREPARE_SHIPMENT, 'Prepare a Shipment')}

                {user?.isCSR && renderNavLink(PATH.USERS, 'Users')}

                {FEATURE.ORDERS && renderNavLink(PATH.ORDERS, 'Orders')}

                {renderNavLink(PATH.GET_QUOTE, 'Get a Quote')}

                {renderNavLink(PATH.SCHEDULE_PICKUP, 'Schedule a Pickup')}
              </Nav>
            )}
            <StyledNavbarNav className="ml-auto">
              {renderBillPayButton()}

              <TrackingNumber />

              <StyledAvatar onClick={toggleDropdown}>
                <StyledNoAvatar>?</StyledNoAvatar>
              </StyledAvatar>
            </StyledNavbarNav>
          </Navbar.Collapse>
        </StyledNavbar>

        {navDropdownVisible && (
          <ContextMenu onHide={toggleDropdown}>
            <NavigationDropdown user={user} onToggle={toggleDropdown} />
          </ContextMenu>
        )}
      </>
    );
  }

  return render();
}

export default Navigation;

import React from 'react';

function DocumentGeneralDescriptionTooltip() {
  return (
    <span>
      Brief description of shipment contents. For
      <br /> example: business documents or women's
      <br /> clothing
    </span>
  );
}

export default DocumentGeneralDescriptionTooltip;

import React from 'react';
import styled from 'styled-components/macro';

import {colors, secondaryFont} from 'styles/shared';

const StyledReviewText = styled.div`
  ${secondaryFont};
  color: ${colors.darkBlue_main};
`;

function ReviewText({children}) {
  return <StyledReviewText>{children}</StyledReviewText>;
}

export default ReviewText;

import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';

const StyledContainer = styled.span`
  position: relative;
`;

interface Props extends BaseProps {
  onHide: () => void;
}

function ContextMenu({onHide, children}: Props) {
  let modalRef = useRef<any>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, false);
    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, false);
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, []);

  function handleOutsideClick(e) {
    if (!modalRef) return;

    const domNode = ReactDOM.findDOMNode(modalRef.current);

    if (domNode && (domNode === e.target || !domNode.contains(e.target))) {
      onHide();
    }
  }

  function handleEscKey(e) {
    if (e.keyCode === 27) {
      onHide();
    }
  }

  return <StyledContainer ref={node => (modalRef.current = node)}>{children}</StyledContainer>;
}

export default ContextMenu;

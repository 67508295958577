import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';

import AddPaymentMethodModal from './components/AddPaymentMethodModal';
import AddNewPayment from './components/AddNewPayment';
import PaymentItem from './components/PaymentItem';

const StyledContainer = styled(Container)`
  padding: 3.6rem 2.6rem 1rem;
`;

interface Props {
  paymentId: string;
  paymentMethods: PaymentMethod[];
  savePaymentMethodAction: (e) => void;
  selectShipmentPaymentMethod: (id: string) => void;
  deletePaymentMethod: (id: string) => void;
}

function PaymentInfo({
  paymentId,
  paymentMethods,
  savePaymentMethodAction,
  selectShipmentPaymentMethod,
  deletePaymentMethod
}: Props) {
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);

  useEffect(() => {
    if (!paymentModalVisible) return;

    togglePaymentModal();
  }, [paymentId]);

  function togglePaymentModal() {
    setPaymentModalVisible(!paymentModalVisible);
  }

  function render() {
    return (
      <StyledContainer>
        <FormHeader>Payment Info</FormHeader>

        {paymentMethods &&
          paymentMethods.map(paymentMethod => {
            const id = paymentMethod.id;
            const isUsed = id === paymentId;

            return (
              <PaymentItem
                key={paymentMethod.id}
                used={isUsed}
                creditCard={paymentMethod?.card}
                achAccount={paymentMethod?.achAccount}
                selectShipmentPaymentMethod={() => selectShipmentPaymentMethod(id)}
                deletePaymentMethod={() => deletePaymentMethod(id)}
              />
            );
          })}

        <AddNewPayment open={togglePaymentModal} />

        {paymentModalVisible && (
          <AddPaymentMethodModal
            visible={paymentModalVisible}
            close={togglePaymentModal}
            savePaymentMethodAction={savePaymentMethodAction}
          />
        )}
      </StyledContainer>
    );
  }

  return render();
}

export default PaymentInfo;

import React, {useState, useEffect, useRef} from 'react';
import {isEmpty} from 'lodash';
import { useReactToPrint } from 'react-to-print';

import CARRIER from 'constants/carriers';

import rateService from 'services/rateService';

import CarrierCheckboxes from './components/CarrierCheckboxes';
import CarrierEmptyList from './components/empty_list/CarrierEmptyList';
import RateItem from './components/RateItem';
import ExpressService from './components/ExpressService';
import { Col } from 'components/bootstrap';

import * as styled from './DomesticRates.styled';

interface Props {
  rates: Rate[];
  isStandaloneQuote: boolean;
  carrierProduct?: string;
  rateIdWithLowerPrice: string;
  isBtnDisabled?: boolean;
  onChange?: OnChangeHandler;
  continueShipping?: (rate: Rate) => void;
}

function DomesticRates({
  rates,
  isStandaloneQuote,
  carrierProduct,
  rateIdWithLowerPrice,
  isBtnDisabled,
  onChange,
  continueShipping
}: Props) {
  const componentRef = useRef<any>();

  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([CARRIER.UPS, CARRIER.FED_EX]);
  const [ratesLookup, setRatesLookup] = useState<Rate[]>();

  useEffect(() => {
    const lookup = rateService.getDomesticRatesLookup(rates, selectedCarriers);
    setRatesLookup(lookup);
  }, [rates, selectedCarriers]);

  function toggleCarrierAction(field: string) {
    let index = selectedCarriers.findIndex(carrier => carrier === field);

    if (index === -1) {
      setSelectedCarriers(prevArray => [...prevArray, field]);
    } else {
      setSelectedCarriers(selectedCarriers.filter(carrier => carrier !== field));
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  function render() {
    if (!ratesLookup) return null;

    return (
      <>
        <CarrierCheckboxes rates={rates} selectedCarriers={selectedCarriers} onToggle={toggleCarrierAction} />
        {!isStandaloneQuote && <styled.divider />}

        {isStandaloneQuote &&
          <>
                <styled.button type="primary" autoWidth margin={`0 0 3rem`} onClick={handlePrint}>
                  Print All Quotes
                </styled.button>
          </>
        }

        <Col xl={15} ref={componentRef}>

          {!isEmpty(ratesLookup) ? (
            ratesLookup.map(rate => {
              const rateId = rate.id;
              const isLowerPrice = rateIdWithLowerPrice === rateId;

              if (isStandaloneQuote) {
                return (
                  <RateItem
                    key={rateId}
                    rate={rate}
                    isDomestic={true}
                    continueShipping={continueShipping}
                    isLowerPrice={isLowerPrice}
                    isFastestDelivery={false}
                  />
                );
              }

              return (
                <ExpressService
                  key={rateId}
                  service={rate}
                  carrierProduct={carrierProduct}
                  isBtnDisabled={isBtnDisabled}
                  isDomestic={true}
                  isLowerPrice={isLowerPrice}
                  onChange={onChange}
                />
              );
            })
          ) : (
            <CarrierEmptyList isStandaloneQuote={isStandaloneQuote} />
          )}
          </Col>
      </>
    );
  }

  return render();
}

export default DomesticRates;

import React from 'react';
import styled from 'styled-components/macro';

import {colors, mainFont} from 'styles/shared';

const StyledTabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

const StyledTabItem = styled.div`
  height: 7.2rem;
  color: ${props => (props.active ? colors.darkBlue_main : colors.darkBlue_light)};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: ${props => (props.active ? `0.3rem solid ${colors.orange_main}` : 'none')};
  ${mainFont};
`;

interface Props {
  tabs: BasicOption[];
  tabSelected: string;
  onChange: (value) => void;
}

function TabComponent({tabs, tabSelected, onChange}: Props) {
  return (
    <StyledTabContainer>
      {tabs.map(tab => {
        const value = tab.value;

        let isActive = value === tabSelected;

        return (
          <StyledTabItem key={value} active={isActive} onClick={() => onChange(value)}>
            {tab.label}
          </StyledTabItem>
        );
      })}
    </StyledTabContainer>
  );
}

export default TabComponent;

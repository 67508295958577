import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" viewBox="0 0 44 44">
      <circle cx="22" cy="22" r="22" fill={colors.grey_light}></circle>
      <path
        fill={colors.orange_dark}
        d="M30.642 29.274l-3.343-2.627c-.685-.547-1.672-.437-2.247.22-.603.683-.987 1.148-1.124 1.258-1.014 1.012-3.124-.465-5.371-2.709-2.247-2.243-3.7-4.322-2.686-5.335.11-.109.576-.52 1.261-1.121.658-.575.767-1.56.22-2.244l-2.632-3.338a1.667 1.667 0 00-1.918-.492c-.904.383-2.52 1.724-2.96 2.161-2.41 2.435.603 7.962 5.865 13.242 5.261 5.28 10.824 8.29 13.236 5.855.438-.438 1.781-2.052 2.165-2.955.301-.684.11-1.45-.466-1.915zM22.421 17.537c-.712-.164-1.398.383-1.343 1.122.028.465.384.848.822.985a3.305 3.305 0 012.44 2.435c.109.465.492.793.986.82.74.055 1.288-.629 1.123-1.34-.192-.93-.685-1.833-1.425-2.572a5.381 5.381 0 00-2.603-1.45z"></path>
      <path
        fill={colors.orange_dark}
        d="M29.656 23.228c.657.055 1.206-.52 1.15-1.176a9.695 9.695 0 00-2.794-6.02 9.724 9.724 0 00-6.03-2.79c-.657-.055-1.232.492-1.178 1.15.028.519.439.957.987 1.011a7.358 7.358 0 014.686 2.19 7.608 7.608 0 012.192 4.677c0 .52.439.93.987.958z"></path>
      <path
        fill={colors.orange_dark}
        d="M30.97 13.05a13.98 13.98 0 00-9.4-4.049c-.63-.027-1.15.52-1.095 1.15.027.546.493.984 1.041 1.011 2.877.11 5.727 1.259 7.892 3.447a11.607 11.607 0 013.426 7.88c.027.547.438 1.012 1.014 1.04a1.064 1.064 0 001.15-1.095c-.082-3.42-1.452-6.785-4.028-9.384z"></path>
    </svg>
  );
}

export default Icon;

import React from 'react';
import styled from 'styled-components/macro';
import {Col} from 'components/bootstrap';

import formatHelper from 'helpers/formatHelper';
import draftExtras from 'domain/entities/draftExtras';

import MEASUREMENT_SYSTEM from 'constants/measurementSystems';
import UNIT from 'constants/units';

import SummaryItem from '../../../../SummaryItem';

import {secondaryFont, colors} from 'styles/shared';

const StyledCommodityCard = styled.div`
  margin-bottom: 2rem;
  border-radius: 0.4rem;
  padding: 2rem;
  background: ${colors.grey_light};
  flex: 1;
`;

const StyledTitle = styled.div`
  ${secondaryFont};
  font-weight: 900;
`;

interface Props {
  item: ProductItem;
  itemNumber: number;
  currency: string;
  measurementSystem: string;
}

function CommodityItem({item, itemNumber, currency, measurementSystem}: Props) {
  let subTotal = 0;
  if (item.itemValue && item.quantity) {
    subTotal = item.itemValue * item.quantity;
  }
  let subTotalDisplay = formatHelper.displayPrice(subTotal, currency);
  let massUnit = measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL ? UNIT.MASS_POUND : UNIT.MASS_KG;

  const itemWeight = formatHelper.displayLineItemWeight(item.weight, massUnit);
  const itemTotalWeight = formatHelper.displayLineItemWeight(draftExtras.calculateLineItemsTotalWeight([item]), massUnit);

  return (
    <Col lg={6}>
      <StyledCommodityCard>
        <StyledTitle>ITEM {itemNumber}</StyledTitle>
        <SummaryItem label="Item description" value={item.itemDescription} />
        <SummaryItem label="Schedule B" value={item.commodityCode} />
        <SummaryItem label="Quantity" value={item.quantity} />
        <SummaryItem label="Item Value" value={formatHelper.displayPrice(item.itemValue, currency)} />
        <SummaryItem label="Sub total" value={subTotalDisplay} />
        <SummaryItem label="Item Weight" value={itemWeight} />
        <SummaryItem label="Item Total Weight" value={itemTotalWeight} />
        <SummaryItem label="Where was item made" value={item.itemMade} />
      </StyledCommodityCard>
    </Col>
  );
}

export default CommodityItem;

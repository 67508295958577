import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';
import userActions from 'actions/userActions';

import PATH from 'constants/path';

import config from 'config';
import formatHelper from 'helpers/formatHelper';
import uiHelper from 'helpers/uiHelper';

import authService from 'services/authService';

import FormHeader from 'components/common/FormHeader';
import Button from 'components/common/Button';
import InputError from 'components/common/InputComponents/InputError';
import ActionLink from 'components/common/ActionLink';
import Contacts from '../../sign_in/components/Contacts';

import * as Styled from 'styles/modal';
import * as styled from './PasswordProtectedModal.styled';
import {buttonLeftRightMargin} from 'styles/shared';

interface Props {
  visible: boolean;
  close: () => void;
}

function PasswordProtectedModal({visible, close}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: AppState) => state.user.current);

  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState<string | JSX.Element>('');

  const redirectUrl = authService.getRedirectUrl();

  async function signIn(e) {
    if (e) e.preventDefault();

    const userData = {
      email: user?.billingInformation?.osClientId || user?.email,
      password
    };

    const response: any = await dispatch(userActions.fullAccessSignIn(userData));

    const isSuccessResponse = (object: any): object is PasswordAuthResponse => {
      return object && 'token' in object;
    };

    if (isSuccessResponse(response)) {
      await dispatch(
        userActions.updateTokensAndGetUser({
          token: response.token,
          refreshToken: response.refresh_token,
          updateRefreshToken: rememberMe
        })
      );

      close();

      if (redirectUrl) history.push(redirectUrl);
    } else {
      setError(
        <div>
          Password you have entered does not match the one in our database. Please try again or use{' '}
          <ActionLink title="Forgot Password?" isInheritFont={true} onClick={forgotPasswordAction} />
        </div>
      );
    }
  }

  function forgotPasswordAction() {
    close();
    dispatch(commonActions.toggleResetPasswordModal(true));
  }

  function callAction() {
    window.open(`tel:${config.supportPhone.value}`);
  }

  async function sendMePassword(e) {
    if (e) e.preventDefault();

    const response: any = await dispatch(
      userActions.resetPassword(user?.billingInformation?.osClientId || user?.email)
    );
    const result = response as ResetPasswordResponse;

    if (result?.success) {
      const successMessage = result?.message || 'An email has been sent to reset your password.';
      uiHelper.showNotice(successMessage);
      close();
      return;
    }

    dispatch(
      commonActions.infoAction({
        type: 'error',
        text: result?.message || 'Something went wrong. Please reload the page and try again.',
        rollbarContext: 'Reset password email unable to be sent - API error response',
        close: () => close()
      })
    );
  }

  function getDataSpecificForPath() {
    let header = '';
    let featureTitle = '';
    let actionButtonTitle = '';

    switch (redirectUrl) {
      case PATH.PREPARE_SHIPMENT:
        header = 'To prepare a Shipment please enter password!';
        featureTitle = 'preparing a Shipment';
        actionButtonTitle = 'Prepare Shipment';
        break;

      case PATH.SCHEDULE_PICKUP:
        header = 'To schedule a Pickup please enter password!';
        featureTitle = 'scheduling a Pickup';
        actionButtonTitle = 'Schedule Pickup';
        break;

      case PATH.VIEW_HISTORY:
      case PATH.HOME:
        header = 'To view your history please enter password!';
        featureTitle = 'viewing history';
        actionButtonTitle = 'View History';
        break;

      default:
        break;
    }

    return {header, featureTitle, actionButtonTitle};
  }

  function collectPasswordSection(featureTitle, actionButtonTitle) {
    return (
      <>
        <styled.text>
          To ensure your information is secure, {featureTitle} is password protected. Please enter your password to
          access. If needed, you can use{' '}
          <ActionLink title="Forgot Password?" isInheritFont={true} onClick={forgotPasswordAction} /> to reset your
          password.<span>*</span>
        </styled.text>

        <styled.textInput
          name="password"
          type="password"
          label="Password"
          value={password}
          onChange={(field, value) => setPassword(value)}
          placeholder="********"
        />
        {error && <InputError>{error}</InputError>}

        <styled.checkboxInput
          name="rememberMe"
          label="Remember me"
          value={rememberMe}
          onToggle={(field, value) => setRememberMe(value)}
        />

        <styled.buttonsContainer justify="center">
          <Button type="secondary" autoWidth margin={buttonLeftRightMargin} onClick={close}>
            Cancel
          </Button>

          <Button autoWidth margin={buttonLeftRightMargin} disabled={!password} onClick={signIn}>
            {actionButtonTitle}
          </Button>
        </styled.buttonsContainer>
      </>
    );
  }

  function sendPasswordSection() {
    const email = formatHelper.displayUserEmail(user?.email);
    const displayEmail = email ? `(to ${email})` : '';

    return (
      <>
        <styled.text>
          To ensure your information is secure, this feature is password protected<span>*</span>. To finish setting up
          your online access, we will send you a one-time email {displayEmail}.
          <br />
          <br />
          However, you can continue prepping your shipment without it!
          <br />
          <br />
          If you do not receive email in couple of minutes, please contact our support and they will fix this for you!
        </styled.text>

        <Contacts />

        <styled.buttonsContainer justify="center">
          <Button autoWidth margin={buttonLeftRightMargin} onClick={close}>
            Close
          </Button>
          <Button autoWidth margin={buttonLeftRightMargin} onClick={sendMePassword}>
            Send me a Password
          </Button>
        </styled.buttonsContainer>
      </>
    );
  }

  function render() {
    const {header, featureTitle, actionButtonTitle} = getDataSpecificForPath();

    return (
      <Styled.CustomModal show={visible} backdrop="static" padding="2.6rem 2.6rem 2.4rem" onHide={close}>
        <FormHeader>{header}</FormHeader>

        {user?.passwordSet ? collectPasswordSection(featureTitle, actionButtonTitle) : sendPasswordSection()}

        <styled.divider />

        <styled.hint>
          <span>*</span> If you need any assistance signing in, please contact Customer Service at{' '}
          <ActionLink title="972-383-9901" onClick={callAction} />
        </styled.hint>
      </Styled.CustomModal>
    );
  }

  return render();
}

export default PasswordProtectedModal;

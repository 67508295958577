import React from 'react';
import styled from 'styled-components/macro';

import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import {opacity, colors} from 'styles/shared';

const IMAGE_SIZE = 116;

const StyledContainer = styled(Flex)`
  background-color: ${colors.white};
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};
  border-radius: 0.6rem;
  margin: ${props => (props.withMargin ? '2rem 0' : 0)};
  padding: 0 1.6rem 0 2rem;
`;

interface Props {
  withMargin: boolean;
  openModal: () => void;
}

function CommodityPanel({withMargin, openModal}: Props) {
  return (
    <StyledContainer withMargin={withMargin} justify="space-between" align="center">
      <img alt="Commodity Manager" src="/images/commodity-manager.png" height={IMAGE_SIZE} width={IMAGE_SIZE} />

      <Button autoWidth onClick={openModal}>
        My Items List
      </Button>
    </StyledContainer>
  );
}

export default CommodityPanel;

import React from 'react';
import styled from 'styled-components/macro';

import validationHelper from 'helpers/validationHelper';

import Flex from 'components/common/Flex';
import AlertComponent from '../AlertComponent';
import FormLabel from '../FormLabel';
import QuestionMark from '../Marks/QuestionMark';
import RadioButton from './RadioButton';
import RadioLabel from './RadioLabel';

import {colors, mainFont, secondaryFont} from 'styles/shared';

const StyledRadioContainer = styled.div`
  margin-bottom: 4.6rem;
`;

const StyledLabelContainer = styled(Flex)`
  margin-bottom: 1.4rem;
`;

const StyledDescription = styled.div`
  color: ${colors.darkBlue_main};
  max-width: 27rem;
  margin-bottom: 1rem;
  ${mainFont};
`;

const StyledOptionContainer = styled(Flex)`
  margin-bottom: 1.4rem;
`;

const StyledOptionLink = styled.span`
  margin-left: 1rem;
  color: ${colors.orange_main};
  cursor: pointer;
  ${secondaryFont};

  &:hover {
    text-decoration: underline;
  }
`;

interface Props extends BaseProps {
  id: string;
  value?: string | boolean;
  name: string;
  label: string;
  extraLabel?: string;
  description: string;
  options: any[];
  onChange: OnChangeHandler;
  required: boolean;
  tooltip: string | JSX.Element;
  error?: string | ValidationError[];
}

RadioInput.defaultProps = {
  required: false,
  description: '',
  tooltip: '',
  options: [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'}
  ],
  id: '',
  className: ''
};

function RadioInput({
  name,
  label,
  options,
  onChange,
  value,
  required,
  tooltip,
  description,
  extraLabel,
  id,
  error,
  className
}: Props) {
  const inputError: string = validationHelper.getInputError(error);

  return (
    <>
      <StyledRadioContainer id={id} className={className}>
        <StyledLabelContainer justify="space-between">
          <div>
            <FormLabel required={required} extraLabel={extraLabel}>
              {label}
            </FormLabel>

            {description && <StyledDescription>{description}</StyledDescription>}
          </div>

          {tooltip && <QuestionMark id={`${name}-tooltip`} tooltip={tooltip} />}
        </StyledLabelContainer>

        {options.map(option => {
          const optionValue = option.value;
          const linkText = option.linkText;

          const id = `${name}-${optionValue}`;

          const isChecked = optionValue === value;

          return (
            <StyledOptionContainer key={optionValue} align="center">
              <RadioButton id={id} name={name} isChecked={isChecked} value={optionValue} onChange={onChange} />

              <div>
                <RadioLabel id={id} label={option.label} />

                {linkText && <StyledOptionLink onClick={option.linkUrl}>{linkText}</StyledOptionLink>}
              </div>
            </StyledOptionContainer>
          );
        })}
      </StyledRadioContainer>

      {inputError && <AlertComponent header="Warning!" text={inputError} />}
    </>
  );
}

export default RadioInput;

import React from 'react';
import styled from 'styled-components/macro';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';

import FormHeader from 'components/common/FormHeader';
import ActionLink from 'components/common/ActionLink';
import Flex from 'components/common/Flex';
import Button from 'components/common/Button';
import NewContacts from './NewContacts';

import * as Styled from 'styles/modal';
import {colors, mediaQueries, authFont} from 'styles/shared';

const StyledTitle = styled(FormHeader)`
  margin-bottom: 2.4rem;
`;

const StyledText = styled.div`
  ${authFont};
  font-weight: 500;
  color: ${colors.darkBlue_main};
  margin-bottom: 1.6rem;
`;

const StyledButtons = styled(Flex)`
  margin-top: 3.6rem;

  > button {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media ${mediaQueries.mobileMax} {
    > button {
      width: auto;
      padding: 0 1.6rem;
    }
  }
`;

interface Props extends BaseProps {
  visible: boolean;
  close: () => void;
}

function AuthTroublePopup({visible, close}: Props) {
  const dispatch = useDispatch();

  function forgotPasswordAction() {
    dispatch(commonActions.toggleResetPasswordModal(true));
    close();
  }

  function render() {
    return (
      <Styled.CustomModal show={visible} backdrop="static" width="52.4rem" onHide={close}>
        <StyledTitle>Having trouble signing in?</StyledTitle>

        <StyledText>
          We see you seem to be having some trouble signing in. <br />
          Try the <ActionLink title="Forgot Password?" onClick={forgotPasswordAction} /> feature, or we can help.
        </StyledText>

        <NewContacts close={close} />

        <StyledButtons justify="center">
          <Button autoWidth onClick={close}>
            Close
          </Button>
        </StyledButtons>
      </Styled.CustomModal>
    );
  }

  return render();
}

export default AuthTroublePopup;

const ERROR = {
  SIGN_IN_FAILED:
    'Sign In information is invalid. Please try again and contact Customer Support if you still have problems.',
  SIGN_UP_FAILED:
    'Sign Up information is invalid. Please try again and contact Customer Support if you still have problems.',
  DANGEROUS_GOODS:
    'Shipping Dangerous Goods is not yet supported in this tool; contact customer service to let us create your label for you.',
  COMMODITY_CODE: 'Correct format is "XXXX.XX.XXXX"',
  DRAFT_NOT_FOUND: 'Draft not found',
  RATE_ERROR:
    'Sorry, there was a problem getting rates for your account. Please make sure your information is correct and contact Customer Service if you need help.',
  RATE_ERROR_HEADER: 'Could not get rates',
  NO_DELIVERY_SERVICES_AVAILABLE:
    "We weren't able to get any delivery options for this shipment. This may be because there are no rates for your origin/destination or because the carrier's website is down. Please contact Customer Service to complete your shipment.",
  CANCEL_PICKUP_ERROR: 'There was a problem deleting the pickup. Please try later or contact Customer Service.'
};

export default ERROR;

import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {get} from 'lodash';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';
import draftActions from 'actions/draftActions';

import pickupService from 'services/pickupService';

import COMMON from 'constants/common';

import Container from 'components/common/Container';
import TextAreaInput from 'components/common/TextAreaInput';
import Slider from 'components/common/Scheduler/Slider';
import RadioInput from 'components/common/RadioInput/RadioInput';
import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import PickupRowDisplay from './PickupRowDisplay';

import {secondaryFont, buttonRightMargin, buttonLeftRightMargin} from 'styles/shared';
import CARRIER from '../../../../../constants/carriers';
import NumberInput from '../../../../common/NumberInput/NumberInput';

const StyledPickupCapability = styled.div`
  ${secondaryFont};
`;

const StyledActionButtons = styled(Flex)`
  margin-bottom: 1.3rem;
`;

interface Props {
  pickupDelivery: PickupDelivery;
  existingPickup?: Pickup;
  addExistingPickup: (pickup: Optional<Pickup>) => void;
  validatePickupAction: () => void;
  updatePickupData: OnChangeHandler;
  carrier: string;
}

function PickupDetails({pickupDelivery, existingPickup, addExistingPickup, validatePickupAction, updatePickupData, carrier}: Props) {
  const dispatch = useDispatch();

  const errors = useSelector((state: AppState) => state.draft.pickup.errors);
  const pickupToSave = useSelector((state: AppState) => state.draft.pickup.dataToSave);
  const pickupCapability = useSelector((state: AppState) => state.draft.pickup.pickupCapability);

  function changePickupDataTime(field, value: number[]) {
    if (pickupToSave?.startTime !== value[0] || pickupToSave?.endTime !== value[1]) {
      updatePickupData(COMMON.PICKUP_TIME, value);
    }
  }

  function cancelEditPickup() {
    dispatch(draftActions.setPickupDataToSave(undefined));
  }

  function renderButtons(readOnlyMode) {
    let editMode = pickupDelivery?.pickup || pickupDelivery?.pickupId;

    let renderCancelButton = () => {
      return (
        <Button type="secondary" margin={buttonLeftRightMargin} onClick={cancelEditPickup}>
          Cancel
        </Button>
      );
    };

    if (readOnlyMode) {
      return (
        <StyledActionButtons>
          <Button
            type="secondary"
            autoWidth
            margin={buttonRightMargin}
            onClick={() => addExistingPickup(existingPickup)}>
            Add this shipment to Pickup
          </Button>

          {editMode && renderCancelButton()}
        </StyledActionButtons>
      );
    }

    let pickupButtonDisabled = !pickupToSave?.location;

    return (
      <StyledActionButtons>
        <Button disabled={pickupButtonDisabled} margin={buttonRightMargin} onClick={validatePickupAction}>
          Confirm Pickup
        </Button>

        {editMode && renderCancelButton()}
      </StyledActionButtons>
    );
  }

  function renderPickupCapability() {
    if (!pickupCapability) return null;

    return (
      <Col>
        <StyledPickupCapability>
          Book By: <strong>{pickupCapability.bookByTime}</strong>
        </StyledPickupCapability>
        <StyledPickupCapability>
          Earliest Pickup Window Time: <strong>{pickupCapability.pickupWindowEarliestTime}</strong>
        </StyledPickupCapability>
        <StyledPickupCapability>
          Latest Pickup Window Time: <strong>{pickupCapability.pickupWindowLatestTime}</strong>
        </StyledPickupCapability>
      </Col>
    );
  }

  function render() {
    let readOnlyMode = existingPickup ? true : false;

    let pickupData = existingPickup ?? pickupToSave;

    if (!pickupData) {
      return null;
    }

    return (
      <Container>
        <Slider
          name="time"
          label="Pickup Time:"
          value={[pickupData.startTime, pickupData.endTime]}
          readOnlyMode={readOnlyMode}
          carrier={carrier}
          onChange={changePickupDataTime}
          pickupCapability={pickupCapability}
        />

        {readOnlyMode ? (
          <>
            <PickupRowDisplay label="Location of pickup" value={pickupData.location} />
            { CARRIER.UPS === carrier && (<PickupRowDisplay label="Number of packages" value={pickupData.numOfPackages} />)}
            <PickupRowDisplay label="Note for driver" value={pickupData.notes || '/'} required={false} />
          </>
        ) : (
          <>
            <Row>
              <Col>
                <RadioInput
                  name="location"
                  label="Location of pickup"
                  required={true}
                  value={pickupData.location}
                  options={pickupService.getPickupLocationOptions()}
                  onChange={updatePickupData}
                  error={get(errors, 'pickupLocation', '')}
                />
              </Col>

              {renderPickupCapability()}
            </Row>

            { CARRIER.UPS === carrier && (
                <NumberInput
                  name="numOfPackages"
                  label="Number of packages"
                  withSignButtons={false}
                  autoHeight
                  required={true}
                  value={pickupData.numOfPackages}
                  onChange={updatePickupData}
                  error={get(errors, 'numOfPackages', 0)}
                />
            )}

            <TextAreaInput
              name="notes"
              label="Write down note for driver"
              placeholder="Short description"
              value={pickupData.notes}
              onChange={updatePickupData}
              error={get(errors, 'notes', '')}
            />
          </>
        )}

        {renderButtons(readOnlyMode)}
      </Container>
    );
  }

  return render();
}

export default PickupDetails;

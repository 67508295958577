import {round} from 'lodash';

export default {
  createFromData
};

function createFromData(data, createdInId?): Rate {
  let rate = {
    ...data,
    createdInId
  };

  rate = addExtraRateMethods(rate);

  return rate;
}

//helper methods

function addExtraRateMethods(entityToExtend) {
  let rate: Rate = {
    ...entityToExtend,
    get displayOptimalRate() {
      if (!rate?.optimalRate?.amount) return 0;

      const result = round(rate.optimalRate.amount, 2);
      return result;
    },
    get displayRetailRate() {
      if (!rate?.retailRate?.amount) return 0;

      const result = round(rate.retailRate.amount, 2);
      return result;
    },
    get savingsPercent() {
      if (!rate?.savings) return 0;

      const result = Math.round(rate.savings * 100);
      return result;
    },
    get isSavingsVisible() {
      return rate?.savingsPercent >= 20;
    }
  };

  return rate;
}

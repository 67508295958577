import PACKAGING_TYPES from "./packagingTypes";

// Currently only used in dropdown packaging selection for shipping documents tab
// and review finish in prepare shipping

const DOCUMENT_PACKAGING_TYPE_OPTIONS: BasicOption[] = [
  {value: PACKAGING_TYPES.DHL_EXPRESS_ENVELOPE,
    label: 'DHL Express Envelope - Documents under 0.5 lbs get BEST PRICE'},
  {value: PACKAGING_TYPES.DOCUMENT, label: 'Other Packaging'}
];

export default DOCUMENT_PACKAGING_TYPE_OPTIONS;

import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';

import {colors, mainFont} from 'styles/shared';

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 3.2rem;
  padding: 0 2.6rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
`;

const StyledItem = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  color: ${props => (props.active ? colors.white : colors.orange_main)};
  background: ${props => (props.active ? colors.orange_main : colors.white)};
  cursor: ${props => (props.active ? 'initial' : 'pointer')};
  ${mainFont};
  font-weight: bold;
  border: 0.1rem solid ${colors.orange_main};
`;

interface Props {
  value: string;
  onChange: (value: string) => void;
}

function AlphabetSearch({value, onChange}: Props) {
  const [alphabet, setAlphabet] = useState<string[]>([]);

  useEffect(() => {
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    const alphabetInitial = alpha.map(x => String.fromCharCode(x));

    setAlphabet(alphabetInitial);
  }, []);

  return (
    <StyledContainer>
      <StyledItem active={!value} onClick={() => onChange('')}>
        All
      </StyledItem>

      {alphabet.map(letter => {
        return (
          <StyledItem key={letter} active={letter === value} onClick={() => onChange(letter)}>
            {letter}
          </StyledItem>
        );
      })}

      <StyledItem active={value === 'digit'} onClick={() => onChange('digit')}>
        0...9
      </StyledItem>
      <StyledItem active={value === 'symbol'} onClick={() => onChange('symbol')}>
        ẞ..._
      </StyledItem>
    </StyledContainer>
  );
}

export default AlphabetSearch;

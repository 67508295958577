import React from 'react';
import styled from 'styled-components';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import MESSAGE from 'constants/literals/messages';

import uiHelper from 'helpers/uiHelper';

import Button from 'components/common/Button';
import SummaryContainer from './SummaryContainer';
import SummaryItem from '../../SummaryItem';

const StyledContainer = styled(SummaryContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;
`;

interface Props {
  trackingNumber: string;
}

function TrackingNumberSummary({trackingNumber}: Props) {
  return (
    <StyledContainer>
      <SummaryItem label="Tracking Nr. (AWB)" value={trackingNumber} isTextHighlighted={true}></SummaryItem>

      <CopyToClipboard text={trackingNumber} onCopy={() => uiHelper.showNotice(MESSAGE.COPIED_TO_CLIPBOARD)}>
        <Button type="tertiary">Copy Number</Button>
      </CopyToClipboard>
    </StyledContainer>
  );
}

export default TrackingNumberSummary;

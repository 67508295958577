import * as actions from 'action_types/commonActionTypes';

import MESSAGE from 'constants/literals/messages';

import {ConfirmInfo, InfoAction, GlobalMenuAction, TutorialInfo} from 'reducers/commonReducer';

import helper from './actionHelper';
import apis from 'domain/apis';

export default {
  asyncActionStart,
  asyncActionEnd,
  toggleGlobalOverlay,
  confirmAction,
  confirmActionCancel,
  infoAction,
  infoActionCancel,
  globalMenu,
  globalMenuClose,
  toggleSaveDraftModal,
  tutorialInfo,
  tutorialInfoClose,
  toggleHouseAccountUsage,
  sendToSupport,
  togglePasswordProtectedModal,
  toggleResetPasswordModal,
  setIsQuickAccessAuth,
  toggleChangePasswordModal,
  toggleIntercomChat,
  toggleGuestAuthModal,
  toggleGuestSignUpModal
};

function asyncActionStart(showOverlay: boolean) {
  return helper.getAction(actions.ASYNC_ACTION_START, {showOverlay});
}

function asyncActionEnd() {
  return helper.getAction(actions.ASYNC_ACTION_END);
}

function toggleGlobalOverlay() {
  return helper.getAction(actions.TOGGLE_GLOBAL_OVERLAY);
}

function confirmAction(confirmInfo: ConfirmInfo) {
  return helper.getAction(actions.CONFIRM_ACTION, {confirmInfo});
}

function confirmActionCancel() {
  return helper.getAction(actions.CONFIRM_ACTION_CANCEL);
}

function infoAction(info: InfoAction) {
  return helper.getAction(actions.INFO_ACTION, {info});
}

function infoActionCancel() {
  return helper.getAction(actions.INFO_ACTION_CANCEL);
}

function globalMenu(menuActions: GlobalMenuAction[]) {
  return helper.getAction(actions.GLOBAL_MENU, {menuActions});
}

function globalMenuClose() {
  return helper.getAction(actions.GLOBAL_MENU_CLOSE);
}

function toggleSaveDraftModal(visible: boolean) {
  return helper.getAction(actions.TOGGLE_SAVE_DRAFT_MODAL, {visible});
}

function tutorialInfo(tutorialInfo: TutorialInfo) {
  return helper.getAction(actions.TUTORIAL_INFO, {tutorialInfo});
}

function tutorialInfoClose() {
  return helper.getAction(actions.TUTORIAL_INFO_CLOSE);
}

function toggleHouseAccountUsage(isUsed: boolean) {
  return helper.getAction(actions.TOGGLE_HOUSE_ACCOUNT_USAGE, {isUsed});
}

function sendToSupport(data: SupportRequestDto, showMessage: boolean) {
  return helper.dispatchAsyncAction(async dispatch => {
    let response = await apis.sendToSupport(data);

    if (showMessage && response?.id) {
      dispatch(helper.getAction(actions.APP_MESSAGE, {message: MESSAGE.SEND_TO_SUPPORT}));
    }

    return response?.id ? true : false;
  }, false);
}

function togglePasswordProtectedModal(visible: boolean, redirectUrl?: string) {
  return helper.getAction(actions.TOGGLE_PASSWORD_PROTECTED_DATA_MODAL, {visible, redirectUrl});
}

function toggleResetPasswordModal(visible: boolean) {
  return helper.getAction(actions.TOGGLE_RESET_PASSWORD_MODAL, {visible});
}

function setIsQuickAccessAuth(isQuickAccessAuth: boolean) {
  return helper.getAction(actions.SET_IS_QUICK_ACCESS_AUTH, {isQuickAccessAuth});
}

function toggleChangePasswordModal(visible: boolean) {
  return helper.getAction(actions.TOGGLE_CHANGE_PASSWORD_MODAL, {visible});
}

function toggleIntercomChat() {
  return helper.getAction(actions.TOGGLE_INTERCOM_CHAT);
}

function toggleGuestAuthModal(visible: boolean, redirectUrl?: string) {
  return helper.getAction(actions.TOGGLE_GUEST_AUTH_MODAL, {visible, redirectUrl});
}

function toggleGuestSignUpModal(visible: boolean, redirectUrl?: string) {
  return helper.getAction(actions.TOGGLE_GUEST_SIGN_UP_MODAL, {visible, redirectUrl});
}

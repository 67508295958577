import React from 'react';
import styled, {css} from 'styled-components/macro';

import {colors, mainFont} from 'styles/shared';

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
`;

const StyledTabItem = styled.div`
  padding: 2.8rem 1.2rem;
  cursor: pointer;
  ${mainFont};
  color: ${colors.darkBlue_light};
  flex: 0 0 auto;

  ${props =>
    props.active &&
    css`
      border-bottom: 0.3rem solid ${colors.orange_main};
      color: ${colors.darkBlue_main};
    `};
`;

interface Props {
  tabs: BasicOption[];
  activeTab: string;
  onChange: (tab: string) => void;
}

function TabComponentMobile({tabs, activeTab, onChange}: Props) {
  return (
    <StyledContainer>
      {tabs.map((tab: BasicOption) => {
        const value = tab.value;
        const isActive: boolean = value === activeTab;

        return (
          <StyledTabItem key={value} active={isActive} onClick={() => onChange(value)}>
            {tab.label}
          </StyledTabItem>
        );
      })}
    </StyledContainer>
  );
}

export default TabComponentMobile;

import React from 'react';

import FormHeader from 'components/common/FormHeader';
import Button from 'components/common/Button';

import * as styled from './FreightShippingModule.styled';

interface Props {
  askForFreightQuote: () => void;
  close: () => void;
}

function FreightShippingModule({askForFreightQuote, close}: Props) {
  return (
    <styled.container>
      <styled.content>
        <FormHeader>Freight Shipping</FormHeader>
        <styled.description>
          Express is the fastest service that we provide. To obtain a quote for a slower service via{' '}
          <strong>Air Freight</strong> or <strong>Ocean Freight</strong>, please contact us directly!
        </styled.description>
        <Button autoWidth onClick={askForFreightQuote}>
          Ask for a Freight Quote
        </Button>
      </styled.content>

      <img src="/images/standalone/freight-shipping.png" alt="Freight Shipping" />

      <styled.closeButton onClick={close} />
    </styled.container>
  );
}

export default FreightShippingModule;

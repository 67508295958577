import React, {useState} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {AppState} from 'reducers';

import Flex from 'components/common/Flex';
import ActionLink from 'components/common/ActionLink';

import TermsOfTradeModal from './TermsOfTradeModal';

import {mainFont} from 'styles/shared';

const StyledDescriptionText = styled.span`
  ${mainFont};
  margin-left: 0.5rem;
`;

function TermsOfTradeSection() {
  const termsOfTradeOptions = useSelector((state: AppState) => state.shipmentOptions.termsOfTradeOptions);

  const [termsOfTradeModalVisible, setTermsOfTradeModalVisible] = useState(false);

  function toggleTermsOfTradeModal() {
    setTermsOfTradeModalVisible(!termsOfTradeModalVisible);
  }

  return (
    <>
      <Flex>
        <ActionLink title="See details" onClick={toggleTermsOfTradeModal} />
        <StyledDescriptionText>about different Customs Terms of Trade</StyledDescriptionText>
      </Flex>

      {termsOfTradeModalVisible && (
        <TermsOfTradeModal
          visible={termsOfTradeModalVisible}
          close={toggleTermsOfTradeModal}
          termsOfTradeOptions={termsOfTradeOptions}
        />
      )}
    </>
  );
}

export default TermsOfTradeSection;

import ITEM_QUANTITY_VALUES_TO_REMOVE from "constants/itemQuantityUnits/itemQuantityValuesToRemove";

export default {
  removeUnusedQuantityUnits
}

function removeUnusedQuantityUnits(items: ProductItem[] | LineItem[]) {
  items.forEach((item, index, items) => {
    if (ITEM_QUANTITY_VALUES_TO_REMOVE.includes(item.units)) {
      items[index].units = '';
    }
  });

  return items;
}

import SLIDER from 'constants/slider';

import formatHelper from 'helpers/formatHelper';

export default {
  createFromData,
  getDefault,
  getPickupTime
};

function createFromData(data): PickupData {
  let entity = {
    ...data
  };

  entity = addExtraMethods(entity);

  return entity;
}

function getDefault(): PickupData {
  let pickupDataDefault: PickupData = {
    date: new Date(),
    startTime: formatHelper.getDefaultMinSliderTime(),
    endTime: SLIDER.INITIAL_END_TIME,
    location: '',
    numOfPackages: 0,
    notes: ''
  };

  let pickupData = createFromData(pickupDataDefault);

  return pickupData;
}

function getPickupTime(val: number) {
  let time = '';

  let hours = Math.floor(val / 60);

  let minutes = val % 60;

  if (hours < 10) {
    time += `0${hours}:`;
  } else {
    time += `${hours}:`;
  }

  if (minutes < 10) {
    time += `0${minutes}:00`;
  } else {
    time += `${minutes}:00`;
  }

  return time;
}

//helper methods

function addExtraMethods(entityToExtend: PickupData): PickupData {
  let entity: PickupData = {
    ...entityToExtend,
    get displayPickupWindow() {
      let startTimeDisplay = formatHelper.displayTimeInAmPmFormat(entity.startTime);
      let endTimeDisplay = formatHelper.displayTimeInAmPmFormat(entity.endTime);

      return `${startTimeDisplay} - ${endTimeDisplay}`;
    },
    get sliderTime() {
      return [entity.startTime, entity.endTime];
    }
  };

  return entity;
}

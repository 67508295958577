import React from 'react';

import DRAFT_STEP from 'constants/draftSteps';
import PICKUP_OPTION from 'constants/pickupOptions';
import SECTION from 'constants/sections';

import dateHelper from 'helpers/dateHelper';
import config from 'config';

import ReviewRow from './ReviewRow';
import ReviewText from './ReviewText';
import FEATURE from 'constants/features';
interface Props {
  data: PickupDelivery;
  confirmationNumber?: string;
  navigate: (step: string, section: string) => void;
}

function ReviewPickup({data, confirmationNumber, navigate}: Props) {
  function renderPickupDetails() {
    return (
      <>
        {data?.pickup?.location && <ReviewText>{data?.pickup?.location}</ReviewText>}

        {data?.pickup?.notes && <ReviewText>{data?.pickup?.notes}</ReviewText>}

        {confirmationNumber && <ReviewText>Pickup Confirmation No.: {confirmationNumber}</ReviewText>}
      </>
    );
  }

  function renderPickupOptions() {
    const pickupOption = data?.custom?.pickupOption;

    let text = '';

    if (!FEATURE.PICKUP_OPTIONS) {
      text = 'No pickup scheduled';
    } else if (pickupOption === PICKUP_OPTION.PERSONAL_DROPPING) {
      text = 'Personally dropping off in nearby Store';
    } else if (pickupOption === PICKUP_OPTION.DAILY_ROUTINE) {
      text = 'Daily pickup routine';
    } else if (pickupOption === PICKUP_OPTION.NOT_READY_SCHEDULE) {
      text = 'Not ready to schedule';
    }

    return <ReviewText>{text}</ReviewText>;
  }

  function render() {
    let shipmentDate = data.pickup ? data.pickup.date : data.shipmentDate;
    let displayPickupTime = dateHelper.displayDate(shipmentDate, config.format.dayMonth);

    if (data.pickup) {
      displayPickupTime += `, ${data.pickup.displayPickupWindow}`;
    }

    return (
      <ReviewRow
        title="Pickup"
        border={false}
        onEditSection={() => navigate(DRAFT_STEP.ADDITIONAL_SERVICES, SECTION.DRAFT.PICKUP)}>
        <ReviewText>{displayPickupTime}</ReviewText>

        {data.isPickupRequired ? renderPickupDetails() : renderPickupOptions()}
      </ReviewRow>
    );
  }

  return render();
}

export default ReviewPickup;

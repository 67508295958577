import config from 'config';
import httpHelper from 'helpers/httpHelper';

import draftApis from './draft';
import shipmentApis from './shipment';
import pickupApis from './pickup';
import orderApis from './order';
import optionApis from './option';
import quoteApis from './quote';
import addressApis from './address';
import lineItemApis from './lineItem';
import userApis from './user';
import authApis from './auth';
import paymentApis from './payment';
import prepAndPickupApis from './prepAndPickup';

export default {
  draft: draftApis,
  shipment: shipmentApis,
  pickup: pickupApis,
  order: orderApis,
  quote: quoteApis,
  address: addressApis,
  option: optionApis,
  lineItem: lineItemApis,
  user: userApis,
  auth: authApis,
  payment: paymentApis,
  prepAndPickup: prepAndPickupApis,
  geCommodityCodeDetails,
  getZipCodes,
  validateAddress,
  captureImage,
  sendToSupport
};

function geCommodityCodeDetails(code): Promise<[string, string] | AcceptableErrorCodeResponse> {
  return httpHelper.get(`/commodity_code_options/${code}`, {}, {acceptableErrorCodes: [404]});
}

function getZipCodes(countryCode, search) {
  let options = {
    countryCode,
    search
  };

  return httpHelper.get('/data/postal-codes', options, {acceptableErrorCodes: [404]});
}

function validateAddress(address: Address) {
  if (!config.useStubs) return {isValidAddress: true};

  let options = {
    address
  };

  return httpHelper.post('/data/validate-address', options);
}

function captureImage(image, imageId, draftId, createdById): Promise<ScreenshotResponse> {
  let body = {
    image,
    imageId,
    draftId,
    createdById
  };

  return httpHelper.post('/capture/screenshot', body, {acceptableErrorCodes: [404]});
}

function sendToSupport(data: SupportRequestDto) {
  const body: SupportRequest = {
    shipmentId: data.draftId || null,
    pickupId: data.pickupId || null,
    quoteId: data.rateId || null,
    lastRequestId: data.requestId || null,
    prepResultId: null,
    context: data.context || null,
    message: data.message,
    locationDescription: data.locationDescription,
    screenshot: data.screenshotIdRequest || null
  };

  return httpHelper.post('/support_requests', body);
}

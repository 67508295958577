import styled from 'styled-components/macro';

import CloseButton from 'components/common/CloseButton';
import FormHeader from 'components/common/FormHeader';

export const header = styled(FormHeader)`
  margin-bottom: 3rem;
  text-align: center;
`;

export const closeButton = styled(CloseButton)`
  position: absolute;
  right: 2rem;
  top: 2rem;
`;

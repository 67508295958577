import React from 'react';
import styled from 'styled-components/macro';
import {Row, Col} from 'components/bootstrap';
import {useDispatch} from 'react-redux';

import lineItemsActions from 'actions/lineItemsActions';

import Button from 'components/common/Button';
import SearchAutosuggest from './components/SearchAutosuggest';

import {buttonLeftMargin, buttonLeftRightMargin} from 'styles/shared';

const StyledContainer = styled.div`
  padding: 0 2.6rem;
`;

const StyledRow = styled(Row)`
  align-items: flex-end;
`;

const StyledButtonsRow = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;
`;

interface Props {
  searchStr: string;
  anyItems: boolean;
  anyCheckedItems: boolean;
  editMode: boolean;
  toggleEditMode: () => void;
  onChange: (field: string, value: string) => void;
  onAdd: () => void;
  onDelete: () => void;
}

function ActionPanel({
  searchStr,
  anyItems,
  anyCheckedItems,
  editMode,
  toggleEditMode,
  onChange,
  onAdd,
  onDelete
}: Props) {
  const dispatch = useDispatch();

  function onAutoFill(lineItem) {
    dispatch(lineItemsActions.selectLineItem(lineItem));
  }

  function renderDeleteButton() {
    if (!editMode) return null;

    return (
      <Button disabled={!anyCheckedItems} margin={buttonLeftRightMargin} onClick={onDelete}>
        Delete Selected
      </Button>
    );
  }

  function renderEditListButton() {
    let text = editMode ? 'Finish Editing' : 'Edit List';

    return (
      <Button type="secondary" autoWidth disabled={!anyItems} margin={buttonLeftRightMargin} onClick={toggleEditMode}>
        {text}
      </Button>
    );
  }

  function render() {
    return (
      <StyledContainer>
        <StyledRow>
          <Col lg={5}>
            <SearchAutosuggest
              name="searchStr"
              label="Search Item"
              placeholder="Type Schedule B, or Item description"
              value={searchStr}
              onChange={onChange}
              onAutoFill={onAutoFill}
            />
          </Col>

          <StyledButtonsRow lg={7}>
            {renderDeleteButton()}

            {renderEditListButton()}

            <Button type="secondary" autoWidth margin={buttonLeftMargin} onClick={onAdd}>
              New Item
            </Button>
          </StyledButtonsRow>
        </StyledRow>
      </StyledContainer>
    );
  }

  return render();
}

export default ActionPanel;

import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {startsWith} from 'lodash';
import PhoneInput from 'react-phone-input-2';
import $ from 'jquery';

import INCOMPATIBLE_COUNTRY_CODE_MAPPING from 'constants/countryCodes/incompatibleCountryCodeMapping';
import INCOMPATIBLE_DIAL_CODE_MAPPING from 'constants/incompatibleDialCodeMapping';

import validationHelper from 'helpers/validationHelper';
import phoneHelper from 'helpers/phoneHelper';

import FormLabel from './FormLabel';
import InputError from 'components/common/InputComponents/InputError';

import 'react-phone-input-2/lib/plain.css'; //style • high-res • material • bootstrap • semantic-ui • plain

import {colors} from 'styles/shared';
import inputStyles from 'styles/customization/input';

const StyledInputContainer = styled.div`
  ${inputStyles.common.inputContainer};

  .flag-dropdown {
    border: 0.1rem solid ${props => (props.error ? colors.red_main : colors.darkBlue_light)};
    border-radius: 0.6rem;
  }

  .flag-focus {
    box-shadow: none;
    ${inputStyles.common.inputFocus};
  }

  .form-control {
    border: 0.1rem solid ${props => (props.error ? colors.red_main : colors.darkBlue_light)};
    ${inputStyles.phoneNumberInput.inputField};
  }

  .validation-error {
    margin-top: 0.8rem;
  }
`;

interface Props extends BaseProps {
  name: string;
  label: string;
  onChange: OnChangeHandler;
  placeholder: string;
  value: string;
  required: boolean;
  countryCode: string;
  error?: string | ValidationError[];
}

PhoneNumberInput.defaultProps = {
  required: false,
  placeholder: '+00 0000 0000',
  className: ''
};

function PhoneNumberInput({name, label, onChange, placeholder, value, error, required, countryCode, className}: Props) {

  const [validationError, setValidationError] = useState('');
  const [dialCode, setDialCode] = useState('');

  if (INCOMPATIBLE_COUNTRY_CODE_MAPPING.hasOwnProperty(countryCode)) {
    countryCode = INCOMPATIBLE_COUNTRY_CODE_MAPPING[countryCode];
  }

  // Manage dial code locally since that is autopopulated
  useEffect(() => {
    if (countryCode) {
      let dialCode: string;

      if (INCOMPATIBLE_DIAL_CODE_MAPPING.hasOwnProperty(countryCode)) {
        dialCode = INCOMPATIBLE_DIAL_CODE_MAPPING[countryCode];
      } else {
        dialCode = phoneHelper.getDialCodeFromCountryCode(countryCode);
      }

      setDialCode(`+${dialCode}`);
    }
  }, [countryCode]);

  let inputOnChange = (phone) => {
    if (phone && !startsWith(phone, '+')) phone = `+${phone}`;

    onChange(name, phone);
  };

  let inputOnBlur = () => {
    const isValid: boolean = validationHelper.isValidPhoneNumber(value);
    setValidationError(!isValid ? 'Invalid phone number' : '');
  };

  let inputError: string = validationHelper.getInputError(error);
  if (!inputError && validationError) inputError = validationError;

  //add class to overide styles for flag dropdown
  $('.form-control')
    .focus(() => {
      $('.flag-dropdown').addClass('flag-focus');
    })
    .blur(() => {
      $('.flag-dropdown').removeClass('flag-focus');
    });

  let phoneNumber = !value ? dialCode : value;

  let inputProps = {name, required};

  return (
    <StyledInputContainer error={inputError} className={className}>
      {label && <FormLabel required={required}>{label}</FormLabel>}

      <PhoneInput
        placeholder={placeholder}
        value={phoneNumber}
        enableLongNumbers={true}
        enableTerritories={true}
        autoFormat={true}
        onChange={inputOnChange}
        onBlur={inputOnBlur}
        inputProps={inputProps}
      />

      {inputError && <InputError>{inputError}</InputError>}
    </StyledInputContainer>
  );
}

export default PhoneNumberInput;

import React from 'react';

import {colors} from 'styles/shared';

function Icon(props) {
  const color = props.color || colors.darkBlue_light;

  return (
    <svg width="15" height="26" fill="none" viewBox="0 0 15 26" {...props}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M13 0L1.686 11.314.272 12.728l1.414 1.414L13 25.456l1.414-1.414L3.101 12.728 14.414 1.414 13 0z"
        clipRule="evenodd"></path>
    </svg>
  );
}

export default Icon;

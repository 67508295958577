import {css} from 'styled-components/macro';

import {colors, boxShadow, mainFont, navBarHeight, scrollStyles, secondaryFont} from 'styles/shared';

const modalBody = css`
  max-width: 42rem;
  padding-right: 2rem !important;
  ${scrollStyles};
`;

const prohibitedModalBody = css`
  ${modalBody};
  ${secondaryFont};
`;

const globalMenuModal = css`
  .modal-content {
    padding: 2rem 3rem;
    background: ${colors.white};
    box-shadow: ${boxShadow};
    color: ${colors.darkBlue_main};
    ${mainFont};
    margin-top: calc(${navBarHeight} + 1rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .modal-backdrop {
    background-color: transparent;
  }
`;

const infoModal = css`
  .modal-dialog {
    width: 36rem;
  }
  .modal-content {
    padding: 1.6rem 1.6rem 2.6rem;
    background: ${colors.darkBlue_main};
    border-radius: 0.6rem;
    color: ${colors.white};
    margin-top: calc(${navBarHeight} + 1rem);
    ${secondaryFont};
  }
  .modal-backdrop {
    background-color: transparent;
  }
`;

const errorModal = css`
  .modal-content {
    background: ${colors.red_light};
  }
`;

export default {
  common: {
    modalBody
  },
  globalMenu: globalMenuModal,
  prohibitedItems: {
    modalBody: prohibitedModalBody
  },
  infoPopup: infoModal,
  errorPopup: errorModal
};

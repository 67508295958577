import React, {useState, useEffect} from 'react';
import {get} from 'lodash';
import styled from 'styled-components/macro';
import {useSelector} from 'react-redux';

import {AppState} from 'reducers';

import customHooks from 'helpers/customHooks';

import CUSTOMS_TERMS_OF_TRADE_DEFAULTS from 'constants/payment/customsTermsOfTradeDefaults';
import WHO_PAYS_TOOLTIP_COPY from 'constants/payment/whoPaysTooltipCopy';

import TextInput from 'components/common/TextInput';
import SelectInput from 'components/common/SelectInput/SelectInput';
import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import FormLabel from 'components/common/FormLabel';
import DutiesAndTaxesTooltip from 'components/tooltips/DutiesAndTaxesTooltip';

import TermsOfTradeSection from './components/TermsOfTradeSection';

import extras from 'components/prepare_shipment/extras';
import WHO_PAYS from 'constants/payment/whoPays';

const StyledSelectInput = styled(SelectInput)`
  margin-bottom: 0;
`;

const StyledText = styled(FormLabel)`
  margin-top: 1.5rem;
`;

interface Props {
  draft: Draft;
  billingInfo?: BillingInformation;
  errors: object;
  onChange: (field: string, value: any) => void;
}

function DutiesAndTaxesPayLater({draft, billingInfo, errors, onChange}: Props) {
  const termsOfTradeOptions = useSelector((state: AppState) => state.shipmentOptions.termsOfTradeOptions);
  const isHouseAccountUsed = useSelector((state: AppState) => state.common.isHouseAccountUsed);
  const whoPaysOptions = useSelector((state: AppState) => state.shipmentOptions.whoPaysOptions);
  const carrier = useSelector((state: AppState) => state.draft.rate.current?.carrier);

  const [whoPaysOptionsList, setWhoPaysOptionsList] = useState<BasicOption[]>([]);

  const firstRender = customHooks.useFirstRender();

  //update pays options depends on export/import workflow
  useEffect(() => {
    const whoPaysList = extras.getWhoPaysOptionsList(
      billingInfo,
      whoPaysOptions,
      draft.isExport,
      draft.isImport,
      isHouseAccountUsed
    );
    setWhoPaysOptionsList(whoPaysList);
  }, [whoPaysOptions, draft.shipmentFrom.country, draft.shipmentTo.country, isHouseAccountUsed]);

  // if user selects "My Optimal Account" or "Alternate Account Number" for 'Who is paying for the Duties/Taxes?',
  // then DDP - Delivered Duty Paid will auto-populate into the Customs Terms of Trade box
  // if the user selects "Receiver", then DAP - Delivered at Place will auto-populate
  useEffect(() => {
    // Prevents changing of chosen options from saved drafts, duplicate shipments, or changing pages
    if (firstRender) return;

    let customsTermsOfTrade: string | undefined = CUSTOMS_TERMS_OF_TRADE_DEFAULTS[draft.payment.dutiesAndTaxesPayment];

    if (customsTermsOfTrade && draft.payment.customsTermsOfTrade !== customsTermsOfTrade) {
      onChange('customsTermsOfTrade', customsTermsOfTrade);
    }
  }, [draft.payment.dutiesAndTaxesPayment]);

  function ShipmentTooltip() {
    if ((draft.payment.freightPayment === WHO_PAYS.RECEIVER || draft.payment.freightPayment === WHO_PAYS.THIRD_PARTY) && carrier) {
      return (
        <span>
          {WHO_PAYS_TOOLTIP_COPY[draft.payment.freightPayment][carrier]}
        </span>
      );
    }

    return null;
  }

  function renderDutiablePayment() {
    return (
      <>
        <SelectInput
          name="dutiesAndTaxesPayment"
          label="Who is paying for the Duties/Taxes?"
          value={draft.payment.dutiesAndTaxesPayment}
          tooltip={DutiesAndTaxesTooltip()}
          options={whoPaysOptionsList}
          onChange={onChange}
          error={get(errors, 'payment.dutiesAndTaxesPayment', '')}
        />

        {draft.isDutyAccountNumberRequired && (
          <TextInput
            name="dutyAccountNumber"
            label="Third party account number"
            placeholder="000000000"
            value={draft.payment.dutyAccountNumber}
            onChange={onChange}
            error={get(errors, 'payment.dutyAccountNumber', '')}
          />
        )}

        <StyledSelectInput
          name="customsTermsOfTrade"
          label="Select Customs Terms of Trade"
          value={draft.payment.customsTermsOfTrade}
          options={termsOfTradeOptions}
          onChange={onChange}
          error={get(errors, 'payment.customsTermsOfTrade', '')}
        />

        <TermsOfTradeSection />
      </>
    );
  }

  function render() {
    const shipmentTooltip = ShipmentTooltip();

    return (
      <Container>
        <FormHeader>Payment</FormHeader>

        {draft.isDutiable && (<StyledText>
          <strong>Based on your selection of who is paying for the duties/taxes, we will choose the Customs Terms of Trade option
          that we believe is the best fit for your shipment.</strong> <br/><br/> <strong>If you would like to select a different
          Terms of Trade option, you may also choose from the list.</strong>
        </StyledText>)}

        {shipmentTooltip ?
              <SelectInput
                name="freightPayment"
                label="Who is paying for the shipment?"
                value={draft.payment.freightPayment}
                tooltip={shipmentTooltip}
                options={whoPaysOptionsList}
                onChange={onChange}
                error={get(errors, 'payment.freightPayment', '')}
              />
              :
              <SelectInput
                name="freightPayment"
                label="Who is paying for the shipment?"
                value={draft.payment.freightPayment}
                options={whoPaysOptionsList}
                onChange={onChange}
                error={get(errors, 'payment.freightPayment', '')}
              />
        }

        {draft.isShipperAccountNumberRequired && (
          <TextInput
            name="shipperAccountNumber"
            label="Third party account number"
            placeholder="000000000"
            required={true}
            value={draft.payment.shipperAccountNumber}
            onChange={onChange}
            error={get(errors, 'payment.shipperAccountNumber', '')}
          />
        )}

        {draft.isAssociatedZipCodeRequired && (
          <TextInput
            name="billingAccountNumberPostalCode"
            label="Associated zip code"
            placeholder="Zip Code"
            required={true}
            value={draft.payment.billingAccountNumberPostalCode}
            onChange={onChange}
            error={get(errors, 'payment.billingAccountNumberPostalCode', '')}
          />
        )}

        {draft.isDutiable && renderDutiablePayment()}
      </Container>
    );
  }

  return render();
}

export default DutiesAndTaxesPayLater;

import {gql} from '@apollo/client';
import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';

import config from 'config';
import httpHelper from 'helpers/httpHelper';
import uiHelper from 'helpers/uiHelper';

import userMapper from 'services/mappers/userMapper';
import authService from 'services/authService';

export default {
  getUsers,
  getUserById,
  refreshUser,
  getBillingInformation,
  updateBillingInformation
};

async function getUsers(search) {
  if (config.useStubs) {
    let users = await httpHelper.get('/users', {search});

    return users.map(user => userMapper.mapUser(user));
  }

  let GET_USERS = gql`
    query GetPosts($search: String) {
      users(search: $search) {
        totalCount
        edges {
          node {
            id
            name
            companyName
            email
            roles
            billingInformation {
              dhlImportNumber
              dhlExportNumber
              osClientId
            }
            setupForImports
            setupForExports
            active
            suspended
            whitelisted
          }
        }
      }
    }
  `;

  let queryResult = await executeQuery(GET_USERS, {search});

  if (!queryResult) return [];

  let result = queryResult.data.users?.edges.map(user => userMapper.mapUser(user.node));

  return result;
}

async function getUserById(id) {
  return await httpHelper.get(`/users/${id}`, {});
}

async function refreshUser(emailOrId, isActive?, isSuspended?, isWhitelisted?): Promise<SyncUserResponse> {
  return httpHelper.post('/sync_user_actions', {
    emailOrId: emailOrId,
    active: (isActive === undefined || isActive === null) ? null : isActive,
    suspended: (isSuspended === undefined || isSuspended === null) ? null : isSuspended,
    whitelisted: (isWhitelisted === undefined || isWhitelisted === null) ? null : isWhitelisted
  });
}

async function getBillingInformation(): Promise<BillingInformation | undefined> {
  const options = {
    page: 1
  };

  const result: BillingInformation[] = await httpHelper.get('/billing_informations', options);

  if (result && result?.length) return result[0];

  return undefined;
}

async function updateBillingInformation(
  billingContactInformation: BillingContactInformation,
  billingId: string
): Promise<BillingInformation> {
  const body: {billingContact: BillingContact} = {
    billingContact: {
      name: billingContactInformation.contactName,
      phone: {
        number: billingContactInformation.phoneNumber,
        extension: billingContactInformation.phoneNumberExtension
      },
      email: billingContactInformation.email
    }
  };

  return await httpHelper.put(`/billing_informations/${billingId}`, body, {});
}

// helper methods

const httpLink = createHttpLink({
  uri: `${config.baseUrl}/graphql`
});

const authLink = setContext((_, {headers}) => {
  // get the authentication token from local storage if it exists
  const token = authService.getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

async function executeQuery(query, variables) {
  try {
    let result = await client.query({
      query,
      variables
    });
    return result;
  } catch (err) {
    uiHelper.logError(err);
    return null;
  }
}

const SECTION = {
  DRAFT: {
    DETAILS: 'shipment-details',
    DOMESTIC_DETAILS: 'shipment-domestic-details',
    SERVICES: 'services',
    PICKUP: 'shipment-pickup'
  },
  STATUS: {
    PICKUPS: 'scheduled-pickups',
    QUOTES: 'standalone-quotes'
  }
};

export default SECTION;

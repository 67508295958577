const ITEM_QUANTITY_VALUES_TO_REMOVE = [
  'CONE',
  'RILL',
  'ROLL',
  'TU',
  '2M',
  '2NO',
  '3M',
  '3KG'
];

export default ITEM_QUANTITY_VALUES_TO_REMOVE;

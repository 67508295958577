import React from 'react';

function ProductGeneralDescriptionTooltip() {
  return (
    <span>
      General description of what <br /> commodity(s) are being shipped. <br /> Such as mobile phone, women’s <br />{' '}
      blouses, books, auto-parts etc.
    </span>
  );
}

export default ProductGeneralDescriptionTooltip;

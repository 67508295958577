import {LOAD_CURRENT_USER, LOAD_USERS, LOAD_BILLING_INFO} from 'action_types/userActionTypes';
import {APP_ERROR} from 'action_types/commonActionTypes';

import COMMON from 'constants/common';
import ERRORS from 'constants/literals/errors';

import helper from './actionHelper';
import apis from 'domain/apis';

import authService from 'services/authService';
import heapAnalyticsService from 'services/heapAnalyticsService';

export default {
  getCurrentUser,
  loadUsers,
  fullAccessSignIn,
  quickAccessSignIn,
  instantSignUp,
  signUpAction,
  signOut,
  loadBillingInformation,
  updateBillingContactInformation,
  refreshUserData,
  signInGuest,
  resetPassword,
  changePassword,
  updateTokensAndGetUser
};

function getCurrentUser(id = COMMON.CURRENT_USER_ID) {
  return helper.dispatchAsyncAction(async dispatch => {
    let user = await authService.getCurrentUser(id);

    heapAnalyticsService.identify(user);

    dispatch(helper.getAction(LOAD_CURRENT_USER, {user}));
  });
}

function loadUsers(search = '') {
  return helper.dispatchAsyncAction(async dispatch => {
    const users = await apis.user.getUsers(search);

    dispatch(helper.getAction(LOAD_USERS, {users}));

    return users?.length > 0;
  });
}

function fullAccessSignIn(user) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.auth.fullAccessSignIn(user);
    return response;
  });
}

function quickAccessSignIn(quickAccessField: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.auth.quickAccessSignIn(quickAccessField);
    return response;
  });
}

function instantSignUp(userData: SignUptDto) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.auth.instantSignUp(userData);

    if (response?.token) {
      dispatch(updateTokensAndGetUser({token: response.token}));
    } else {
      dispatch(helper.getAction(APP_ERROR, {error: ERRORS.SIGN_UP_FAILED}));
    }
  });
}

function signUpAction(body) {
  return helper.dispatchAsyncAction(async () => {
    const response = await apis.auth.signUp(body);
    return response?.success;
  });
}

function signOut() {
  authService.saveToken(null);
  authService.saveRefreshToken(null);
  authService.saveQuickAccessToken(null);
  authService.setAuthContext(null);
  authService.saveRedirectUrl(null);

  return helper.getAction(LOAD_CURRENT_USER, {user: undefined});
}

function loadBillingInformation() {
  return helper.dispatchAsyncAction(async dispatch => {
    const billingInfo = await apis.user.getBillingInformation();

    dispatch(helper.getAction(LOAD_BILLING_INFO, {billingInfo}));
  });
}

function updateBillingContactInformation(billingContactInformation: BillingContactInformation, billingId: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    const billingInfo = await apis.user.updateBillingInformation(billingContactInformation, billingId);
    return billingInfo?.billingContact ? true : false;
  });
}

function refreshUserData(emailOrId, isActive?, isSuspended?, isWhitelisted?) {
  return helper.dispatchAsyncAction(async () => {
    const response: SyncUserResponse = await apis.user.refreshUser(emailOrId, isActive, isSuspended, isWhitelisted);

    if (isActive === true || isActive === false) {
      return response?.success
    }

    return response?.success && response?.synced;
  });
}

function signInGuest() {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.auth.guestSignIn();

    if (response?.token) {
      dispatch(updateTokensAndGetUser({token: response.token}));
    } else {
      dispatch(helper.getAction(APP_ERROR, {error: ERRORS.SIGN_IN_FAILED}));
    }

    return response?.token;
  });
}

function resetPassword(emailOrId) {
  return helper.dispatchAsyncAction(async () => {
    const response: ResetPasswordResponse = await apis.auth.resetPassword(emailOrId);
    return response;
  });
}

function changePassword(password: string) {
  return helper.dispatchAsyncAction(async () => {
    const response: ResetPasswordResponse = await apis.auth.changePassword(password);
    return response;
  });
}

function updateTokensAndGetUser(data: UpdateTokensDto, refreshAuth? : boolean) {
  return helper.dispatchAsyncAction(async dispatch => {
    if (refreshAuth) authService.setAuthContext(null);

    authService.saveToken(data.token || null);
    authService.saveQuickAccessToken(data.quickAccessToken || null);

    if (data.updateRefreshToken && data.refreshToken) {
      authService.saveRefreshToken(data.refreshToken);
    }

    await dispatch(getCurrentUser());
  });
}

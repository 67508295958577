import React, {useState} from 'react';
import styled from 'styled-components/macro';

import FormHeader from 'components/common/FormHeader';
import Button from 'components/common/Button';
import TextInput from 'components/common/TextInput';
import TextAreaInput from 'components/common/TextAreaInput';
import Flex from 'components/common/Flex';

import * as Styled from 'styles/modal';
import {mainFont, buttonLeftMargin, buttonLeftRightMargin} from 'styles/shared';

const StyledText = styled.div`
  ${mainFont};
  margin-top: 1rem;
`;

const StyledInput = styled(TextInput)`
  margin-bottom: 0;
`;

const StyledTextArea = styled(TextAreaInput)`
  margin-top: 0;
`;

interface Props {
  visible: boolean;
  close: () => void;
  send: (data: string) => void;
}

function ReportQuoteModal({visible, close, send}: Props) {
  const [reportData, setReportData] = useState({quote: '', message: ''});

  function onChange(field, value) {
    setReportData({...reportData, [field]: value});
  }

  function onSend() {
    send(JSON.stringify(reportData));
  }

  return (
    <Styled.CustomModal show={visible} backdrop="static" onHide={close}>
      <FormHeader>Report Problem</FormHeader>

      <StyledText>
        If you have encountered any problem (eg. Incorrect Rate) send us short description and we will deal with it as
        soon as possible.
      </StyledText>

      <StyledInput
        name="quote"
        value={reportData.quote}
        label="Correct quote"
        placeholder="Correct quote"
        onChange={onChange}
      />

      <StyledTextArea
        name="message"
        value={reportData.message}
        label="Problem description"
        placeholder="Short description of the problem"
        onChange={onChange}
      />

      <Flex justify="flex-end">
        <Button type="secondary" autoWidth margin={buttonLeftRightMargin} onClick={close}>
          Cancel
        </Button>
        <Button autoWidth margin={buttonLeftMargin} onClick={onSend}>
          Send Report
        </Button>
      </Flex>
    </Styled.CustomModal>
  );
}

export default ReportQuoteModal;

import React from 'react';

function LicenseTooltip() {
  return (
    <span>
      <strong>LICENSE TYPE/SYMBOL:</strong> Depending on what you
      <br /> are exporting and where you are shipping, your product
      <br />
      or products may require an export license from the
      <br /> <strong>U.S. Department of Commerce, State Department</strong>, or <br />
      other agency. If so, the license number should be <br /> included along with the value of the goods being shipped
      <br />
      under the license. Most exports do not require a license
      <br /> and are listed as <strong>NLR</strong>.
    </span>
  );
}

export default LicenseTooltip;

import React from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';

import ActionLink from 'components/common/ActionLink';
import FormHeader from 'components/common/FormHeader';

import {colors} from 'styles/shared';

const StyledRowContainer = styled.div`
  border-bottom: ${props => (props.border ? `0.1rem dashed ${colors.darkBlue_light}` : 'none')};
  padding-bottom: 2.8rem;
  margin-bottom: 3rem;
`;

const StyledBody = styled(Row)`
  margin-top: 1rem;
`;

const StyledEditLink = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

interface Props extends BaseProps {
  title: string;
  border?: boolean;
  onEditSection: () => void;
}

ReviewRow.defaultProps = {
  border: true
};

function ReviewRow({title, border, children, onEditSection}: Props) {
  return (
    <StyledRowContainer border={border}>
      <FormHeader>{title}</FormHeader>

      <StyledBody>
        <Col lg={8}>{children}</Col>
        <StyledEditLink lg={4}>
          <ActionLink title="Edit Section" onClick={onEditSection} />
        </StyledEditLink>
      </StyledBody>
    </StyledRowContainer>
  );
}

export default ReviewRow;

import React, {useState, useEffect, useRef} from 'react';
import {Row, Col} from 'components/bootstrap';
import styled, {css} from 'styled-components/macro';
import {get, isEqual, upperFirst, debounce} from 'lodash';

import TIMER from 'constants/timers';

import unitHelper from 'helpers/unitHelper';

import rateService from 'services/rateService';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import InputWithUnits from 'components/common/InputWithUnits';
import NumberInput from 'components/common/NumberInput/NumberInput';
import FormLabel from 'components/common/FormLabel';
import Flex from 'components/common/Flex';
import ActionLink from 'components/common/ActionLink';
import SystemUnitsToggler from 'components/common/SystemUnitsToggler';
import ItemBox from './ItemBox';
import CheckBoxInput from 'components/common/CheckboxInput';
import QuestionMark from 'components/common/Marks/QuestionMark';
import ExclusivelyDocumentsTooltip from 'components/tooltips/ExclusivelyDocumentsTooltip';
import ExpressEnvelopeTooltip from 'components/tooltips/ExpressEnvelopeTooltip';

import {mainBorder, mediaQueries, secondaryFont, colors} from 'styles/shared';
import InsuranceSection from './InsuranceSection';
import UNIT from 'constants/units';

const StyledForm = styled.div`
  padding: 2.6rem 2.6rem 0;
  border-bottom: ${mainBorder};
`;

const StyledFormHeader = styled(FormHeader)`
  margin-bottom: 2.6rem;
`;

const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -2rem;
`;

const StyledWeightMobile = styled(Row)`
  display: none;

  @media ${mediaQueries.mobileMax} {
    display: block;
  }
`;

const StyledNumberInput = styled(NumberInput)`
  margin-bottom: 0;
`;

const StyledSizesRow = styled(Row)`
  @media ${mediaQueries.mobileMax} {
    display: none;
  }
`;

const StyledBoxesContainer = styled.div`
  padding: 1.5rem 2.6rem 0;

  @media ${mediaQueries.mobileMax} {
    display: ${props => (props.visible ? 'inherit' : 'none')};
    padding: 2rem 1.6rem 0;
  }
`;

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;

  @media ${mediaQueries.mobileMax} {
    margin-bottom: 2.2rem;
  }
`;

const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 1.6rem;

  @media ${mediaQueries.mobileMax} {
    display: none;
  }
`;

const StyledTabs = styled.div`
  display: none;

  @media ${mediaQueries.mobileMax} {
    display: flex;
  }
`;

const StyledCustomTab = styled(Row)`
  display: none;

  @media ${mediaQueries.mobileMax} {
    display: initial;
  }
`;

const StyledTabItem = styled.div`
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${secondaryFont};
  width: 40%;
  background: ${props => (props.active ? colors.white : colors.grey_light)};
  border-bottom: ${props => (props.active ? `0.4rem solid ${colors.orange_main}` : 'none')};
`;

const StyledCommonTabItem = styled(StyledTabItem)`
  width: 60%;
`;

const StyledActionLink = styled(ActionLink)`
  margin-left: 2rem;

  @media ${mediaQueries.mobileMax} {
    display: none;
  }
`;

const StyledSystemUnits = styled(SystemUnitsToggler)`
  margin: 2rem 0 0;
`;

const StyledInputWithUnits = styled(InputWithUnits)`
  ${props =>
    props.isFreightWarning &&
    css`
      & input {
        border-color: ${colors.darkBlue_light};
      }
      & div {
        border-color: ${colors.darkBlue_light};
      }
      .validation-error {
        color: ${colors.darkBlue_main};
        margin-bottom: 2rem;
      }
    `}
`;

const StyledCheckboxes = styled(Flex)`
  align-items: flex-start;
  margin-top: -0.4rem;

  @media ${mediaQueries.desktopMax} {
    margin-top: 0;
  }

  @media ${mediaQueries.mobileMax} {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 3.7rem 1rem;
  }
`;

const StyledSeparator = styled.div`
  width: 4.5rem;

  @media ${mediaQueries.mobileMax} {
    display: none;
  }
`;

const CUSTOM_SIZE_TAB = 'custom';
const COMMON_SIZE_TAB = 'common';

interface Props {
  index: number;
  isLastIndex: boolean;
  item: StandaloneItem;
  errors: object;
  boxes: Box[];
  domestic: boolean;
  isImport: boolean;
  measurementSystem: string;
  freightQuoteWarning: string;
  onChange: (index: number, field: string, value: any) => void;
  onRemove: (index: number) => void;
  onBoxSelect: (index: number, box: Box) => void;
  onUnitChange: (field: string, value: string) => void;
  standaloneQuote: StandaloneQuote;
}

function StandaloneItem({
  index,
  isLastIndex,
  item,
  errors,
  boxes,
  domestic,
  isImport,
  measurementSystem,
  freightQuoteWarning,
  onChange,
  onRemove,
  onBoxSelect,
  onUnitChange,
  standaloneQuote
}: Props) {
  const [activeBox, setActiveBox] = useState<Box | undefined>();
  const [activeTab, setActiveTab] = useState(CUSTOM_SIZE_TAB);
  const [boxesVisible, setBoxesVisible] = useState(false);
  const [expressEnvelopeCheckboxVisible, setExpressEnvelopeCheckboxVisible] = useState(false);
  const [dimensionsVisible, setDimensionsVisible] = useState(true);

  const measurementSystemOptions: BasicOption[] = unitHelper.getMeasurementSystemOptions();

  // Package Dimensions not visible if isDocument is selected.
  useEffect(() => {
    if (domestic || !standaloneQuote.isDocument) {
      setDimensionsVisible(true);
    } else {
      setDimensionsVisible(false);
    }
  }, [domestic, standaloneQuote.isDocument]);

  // UseExpressEnvelopeCheckbox is only visible if isDocument is selected and not an import.
  useEffect(() => {
    if (standaloneQuote.isDocument && !isImport) {
      setExpressEnvelopeCheckboxVisible(true);
    } else {
      setExpressEnvelopeCheckboxVisible(false);
    }
  }, [standaloneQuote.isDocument, isImport]);

  const displaySurcharge = useRef(
    debounce(() => rateService.displaySurcharge('package'), TIMER.SURCHARGE_DISPLAY_DELAY)
  ).current;

  function onItemChange(field, value) {
    onChange(index, field, value);
    displaySurcharge();
  }

  function selectBox(box: Box) {
    setActiveBox(box);
    onBoxSelect(index, box);
  }

  function toggleBoxesVisibility() {
    setBoxesVisible(!boxesVisible);
  }

  function selectCommonSizesTab() {
    setBoxesVisible(true);
    setActiveTab(COMMON_SIZE_TAB);
  }

  function onMeasurementSystemChange(field, value) {
    onUnitChange(field, value);
    rateService.displaySurcharge('measurement_system');
  }

  function onToggle(field, value) {
    onUnitChange(field, value);
  }

  function renderSizeInput(field, isWeightUnit = false) {
    const label = upperFirst(field);
    const error = get(errors, `items[${index}].${field}`);

    let warning = '';
    if (field === 'weight' && isLastIndex && freightQuoteWarning) {
      warning = freightQuoteWarning;
    }

    return (
      <StyledInputWithUnits
        name={field}
        label={label}
        value={item[field]}
        isFreightWarning={warning ? true : false}
        measurementSystem={measurementSystem}
        isWeightUnit={isWeightUnit}
        required={true}
        onChange={onItemChange}
        error={error || warning}
      />
    );
  }

  function renderTabs() {
    return (
      <StyledTabs>
        <StyledTabItem active={activeTab === CUSTOM_SIZE_TAB} onClick={() => setActiveTab(CUSTOM_SIZE_TAB)}>
          Custom Sizes
        </StyledTabItem>
        <StyledCommonTabItem active={activeTab === COMMON_SIZE_TAB} onClick={selectCommonSizesTab}>
          Common Shipping Sizes
        </StyledCommonTabItem>
      </StyledTabs>
    );
  }

  function renderSystemUnits() {
    if (domestic) return null;

    return (
      <>
        <Col lg={3}>
          <StyledSystemUnits
            name="measurementSystem"
            label="Measurement system"
            title="Select System"
            value={measurementSystem}
            options={measurementSystemOptions}
            onChange={onMeasurementSystemChange}
          />
        </Col>
      </>
    );
  }

  function render() {
    const firstItem = index === 0;

    const itemNumber = index + 1;

    const togglerTitle = boxesVisible ? 'Hide Boxes' : 'Show Boxes';

    const justify = firstItem ? "flex-start" : "space-between";

    return (
      <Container withoutPadding>
        <StyledForm>
          <Flex justify={justify}>
            <StyledFormHeader>Package #{itemNumber}</StyledFormHeader>
            {(firstItem && !domestic) && (
              <>
                <StyledSeparator />
                <StyledCheckboxes align="center">

                  <Flex align="center">
                    <CheckBoxInput
                      name="isDocument"
                      label="Shipment contains exclusively Documents"
                      value={standaloneQuote.isDocument}
                      onToggle={onToggle}
                    />

                    <QuestionMark
                      id="exclusively-documents-tooltip"
                      tooltip={ExclusivelyDocumentsTooltip()}
                      placement="top"
                    />
                  </Flex>

                  <StyledSeparator />

                  <Flex align="center">
                    {expressEnvelopeCheckboxVisible && (
                      <>
                        <CheckBoxInput
                          name="usesExpressEnvelope"
                          label="I’m using a DHL Letter Express Envelope"
                          value={standaloneQuote.usesExpressEnvelope}
                          onToggle={onToggle}
                        />

                        <QuestionMark
                        id="express-envelope-tooltip"
                        tooltip={ExpressEnvelopeTooltip()}
                        placement="top" />
                  </>
                    )}
                  </Flex>

                </StyledCheckboxes>
              </>
            )}

            {index > 0 && <ActionLink title="Delete Package" onClick={() => onRemove(index)} />}
          </Flex>

          <StyledWeightMobile>
            <Col>{renderSizeInput('weight', true)}</Col>
          </StyledWeightMobile>

          <Row>
            <Col lg={6}>
              <StyledNumberInput
                name="quantity"
                label="Number of packages"
                required={true}
                value={item.quantity}
                onChange={onItemChange}
                error={get(errors, `items[${index}].quantity`)}
              />
            </Col>

            {renderSystemUnits()}
          </Row>

          <StyledSizesRow>
            <Col lg={3}>{renderSizeInput('weight', true)}</Col>
            <Col lg={2}>
              <StyledImageContainer>
                <img alt="Empty Box" src="/images/standalone/empty-box.png" />
              </StyledImageContainer>
            </Col>
            {dimensionsVisible && (<Col lg={7}>
              <Row>
                <Col lg={4}>{renderSizeInput('length')}</Col>
                <Col lg={4}>{renderSizeInput('width')}</Col>
                <Col lg={4}>{renderSizeInput('height')}</Col>
              </Row>
            </Col>
            )}
          </StyledSizesRow>
        </StyledForm>

        {dimensionsVisible && renderTabs()}

        {(dimensionsVisible && activeTab === CUSTOM_SIZE_TAB) && (
          <StyledCustomTab>
            <Col>{renderSizeInput('length')}</Col>
            <Col>{renderSizeInput('width')}</Col>
            <Col>{renderSizeInput('height')}</Col>
          </StyledCustomTab>
        )}

        {dimensionsVisible && (<StyledBoxesContainer visible={activeTab === COMMON_SIZE_TAB}>
          <Flex>
            <StyledFormLabel>Not sure about size? Select standard boxes!</StyledFormLabel>

            <StyledActionLink title={togglerTitle} onClick={toggleBoxesVisibility} />
          </Flex>

          {boxesVisible && (
            <StyledFlex justify="space-between">
              {boxes.map(box => {
                const active = isEqual(box, activeBox);

                return (
                  <ItemBox
                    key={box.name}
                    box={box}
                    measurementSystem={measurementSystem}
                    active={active}
                    onSelect={selectBox}
                  />
                );
              })}
            </StyledFlex>
          )}
        </StyledBoxesContainer>
        )}
        {domestic && <InsuranceSection
          insuranceValue={item.documentDeclaredValue || 0}
          currency={UNIT.CURRENCY_USD}
          onChange={(field, value) => onItemChange('documentDeclaredValue', value)}
          error={get(errors, `items[${index}].documentDeclaredValue`)}
          isDomestic={true}
        />}
      </Container>
    );
  }

  return render();
}

export default StandaloneItem;

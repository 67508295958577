import {LOAD_ORDERS} from 'action_types/orderActionTypes';

import initialState from './initialState';
import helper from './reducerHelper';

export interface OrderState {
  list: any[];
}

const orderReducer = (state: OrderState = initialState.order, action) => {
  return helper.handleActions<OrderState>(state, action, {
    [LOAD_ORDERS](state, payload) {
      state.list = payload.orders;
    }
  });
};

export default orderReducer;

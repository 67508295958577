import React from 'react';

function AnyBatteriesTooltip() {
  return (
    <span>
      Learn How to Safely Pack and <br /> Ship Batteries. Click on Tell me <br /> more.
    </span>
  );
}

export default AnyBatteriesTooltip;

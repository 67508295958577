import React from 'react';

function InvoiceNumberTooltip() {
  return (
    <span>
      This is NOT a required field and will most commonly <br />
      be left blank. If the shipper or receiver uses{' '}
      <strong>
        Internal <br /> Invoice Numbers
      </strong>
      , those could be listed here.
    </span>
  );
}

export default InvoiceNumberTooltip;

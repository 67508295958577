import React from 'react';
import styled from 'styled-components/macro';

import uiHelper from 'helpers/uiHelper';

import AppIcon from 'components/common/AppIcon/AppIcon';

import {colors, mainFont, secondaryFont, mainBorder} from 'styles/shared';

const StyledStoreContainer = styled.div`
  margin: 1rem 0;
  border-bottom: ${mainBorder};
`;

const StyledTitle = styled.div`
  font-weight: bold;
  color: ${colors.darkBlue_main};
  ${secondaryFont};
`;

const StyledBody = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.6rem;
`;

const StyledSubtitle = styled.div`
  color: ${colors.darkBlue_main};
  ${secondaryFont};
`;

const StyledWorkingHoursContainer = styled.div`
  margin: 0.6rem 0 0.9rem;
  display: flex;
`;

const StyledOpenedLabel = styled(StyledTitle)`
  margin-right: 1rem;
`;

const StyledClosedLabel = styled(StyledTitle)`
  color: ${colors.red_main};
  margin-right: 1rem;
`;

const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledIconContainer = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: ${colors.orange_main};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledDirections = styled.div`
  color: ${colors.orange_main};
  ${mainFont};
`;

interface Props {
  store: any;
}

function DropOffComponent({store}: Props) {
  const workingHours = store.workingHours;

  return (
    <StyledStoreContainer>
      <StyledTitle>{store.name}</StyledTitle>

      <StyledBody>
        <div>
          <StyledSubtitle>{store.address},</StyledSubtitle>
          <StyledSubtitle>{store.country}</StyledSubtitle>

          <StyledWorkingHoursContainer>
            {workingHours.isOpened ? (
              <>
                <StyledOpenedLabel>Open</StyledOpenedLabel>
                <StyledSubtitle>Closes {workingHours.closingTime}</StyledSubtitle>
              </>
            ) : (
              <>
                <StyledClosedLabel>Closed</StyledClosedLabel>
                <StyledSubtitle>Opens {workingHours.closingTime}</StyledSubtitle>
              </>
            )}
          </StyledWorkingHoursContainer>
        </div>

        <StyledRightContainer>
          <StyledIconContainer onClick={uiHelper.notImplemented}>
            <AppIcon name="direction" />
          </StyledIconContainer>

          <StyledDirections>Directions</StyledDirections>
        </StyledRightContainer>
      </StyledBody>
    </StyledStoreContainer>
  );
}

export default DropOffComponent;

import React from 'react';

function ExclusivelyDocumentsTooltip() {
  return (
    <span>
      Documents include legal, financial or business paperwork. <br />
      Items with monetary value are not considered a document <br />
      shipment. Documents over 5 lbs are considered a product <br />
      shipment. This information influences the delivery time <br />
      and/or price of quotes.
    </span>
  );
}

export default ExclusivelyDocumentsTooltip;

import React, {useState, useRef} from 'react';
import {debounce} from 'lodash';
import Autosuggest from 'react-autosuggest';
import InputAdornment from '@material-ui/core/InputAdornment';

import utils from 'helpers/utils';

import FormLabel from 'components/common/FormLabel';

import {MaterialTextField} from 'styles/materialUI';
import * as Styled from 'styles/autosuggest';

interface Props {
  name: string;
  label: string;
  onChange: OnChangeHandler;
  onAutoFill: (suggestion) => void;
  value?: string;
  placeholder: string;
}

SearchAutosuggest.defaultProps = {
  placeholder: ''
};

function SearchAutosuggest({name, label, placeholder, onChange, onAutoFill, value}: Props) {
  const [suggestions, setSuggestions] = useState<any[]>([]);

  const lastRequestId = useRef(null);

  const onFetchSuggestions = useRef(debounce(value => onSuggestionsFetchRequested(value), 1000)).current;

  async function onSuggestionsFetchRequested(value) {
    if (value.length < 2) return;

    let requestId = utils.getRandomUid();
    lastRequestId.current = requestId;

    if (lastRequestId.current !== requestId) return;
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onInputChange(event, {newValue}) {
    onChange(name, newValue);
  }

  function getSuggestionValue(suggestion) {
    return suggestion.companyName;
  }

  function onSuggestionSelected(event, {suggestion}) {
    onAutoFill(suggestion);
  }

  function renderInputComponent(inputProps) {
    const text = inputProps?.value ? 'Clear' : 'Search';

    const clearSearchValue = () => {
      if (!inputProps?.value) return;
      onChange(name, '');
    };

    return (
      <MaterialTextField
        {...inputProps}
        label={placeholder}
        variant="filled"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={clearSearchValue}>
              {text}
            </InputAdornment>
          )
        }}
      />
    );
  }

  function renderSuggestion(suggestion) {
    return (
      <div>
        <span>{suggestion.companyName}, </span>
        <span>{suggestion.contactName}</span>
      </div>
    );
  }

  function render() {
    const inputProps = {value, onChange: onInputChange, autoComplete: 'nope'};

    return (
      <Styled.AutosuggestContainer>
        <FormLabel>{label}</FormLabel>

        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={({value}) => onFetchSuggestions(value)}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={autosuggestProps => renderInputComponent(autosuggestProps)}
        />
      </Styled.AutosuggestContainer>
    );
  }

  return render();
}

export default SearchAutosuggest;

import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  return (
    <svg width="19" height="14" fill="none" viewBox="0 0 19 14">
      <path
        fill={colors.orange_main}
        fillRule="evenodd"
        d="M10.546 1.4l1.41-1.4 5.634 5.6L19 7l-1.409 1.4-5.636 5.6-1.41-1.4 4.64-4.61H0V6.01h15.185L10.546 1.4z"
        clipRule="evenodd"></path>
    </svg>
  );
}

export default Icon;

import React, {useState} from 'react';
import styled from 'styled-components/macro';

import Button from 'components/common/Button';
import ActionsPopupDropdown from 'components/common/ActionsPopup/components/ActionsPopupDropdown';
import ContextMenu from 'components/common/ContextMenu';

const StyledActionBox = styled.div`
  > span > span {
    top: 0rem;
    left: -12.5rem;
  }
`;

interface Props extends BaseProps {
  actions: DropdownAction[];
  activeItem: User | Shipment | Draft | Pickup | Quote;
}

function ButtonActionsPopup({actions, activeItem, className}: Props) {
  const [isActionsPopupOpened, setIsActionsPopupOpened] = useState(false);

  function toggleActionsPopup(e) {
    setIsActionsPopupOpened(!isActionsPopupOpened);

    return e && e.preventDefault();
  }

  function hideActionsPopup() {
    setIsActionsPopupOpened(false);
  }

  return (
    <StyledActionBox className={className} onClick={toggleActionsPopup}>
      <Button autoWidth>Actions</Button>

      <ContextMenu onHide={hideActionsPopup}>
        {isActionsPopupOpened && <ActionsPopupDropdown actions={actions} activeItem={activeItem} />}
      </ContextMenu>
    </StyledActionBox>
  );
}

export default ButtonActionsPopup;

import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components/macro';

import commonActions from 'actions/commonActions';

import uiHelper from 'helpers/uiHelper';

import FormHeader from 'components/common/FormHeader';
import Button from 'components/common/Button';
import SavePickupModal from 'components/common/Scheduler/SavePickupModal';
import Flex from 'components/common/Flex';
import SummaryItem from '../SummaryItem';
import DatePanel from './components/DatePanel';
import StatusPanel from './components/StatusPanel';
import ContactUs from 'components/auth/components/contact_us/ContactUs';

import {buttonSideMargin} from 'styles/shared';
import CARRIER from '../../../../constants/carriers';

const StyledContainer = styled.div`
  margin-bottom: ${props => props.canSchedulePickup ? '0': '4rem'};
`;

interface Props {
  shipment: Shipment;
  pickup?: Pickup;
  date: Date;
  service?: string;
  carrier?: string;
  isImport?: boolean;
  cancelPickupAction: () => void;
  editPickupAction: (pickup: PickupData, pickupId: string) => void;
  addToPickupAction: (pickup: PickupData) => void;
}

function PickupsSummary({shipment, pickup, date, service, carrier, isImport, cancelPickupAction, editPickupAction, addToPickupAction}: Props) {
  const dispatch = useDispatch();

  const [pickupModalVisible, setPickupModalVisible] = useState(false);

  // Editing pickups is currently nonfunctional in backend for all carriers
  function editPickup() {
    dispatch(commonActions.infoAction(uiHelper.getCannotReschedulePickupPopupParameters(carrier)));
    return;
    // togglePickupModal();
  }

  function togglePickupModal() {
    setPickupModalVisible(!pickupModalVisible);
  }

  async function onSavePickup(pickupToSave: PickupData) {
    togglePickupModal();

    if (pickup?.id) {
      await editPickupAction(pickupToSave, pickup.id);
    } else {
      await addToPickupAction(pickupToSave);
    }
  }

  function renderPickupDetails() {
    return (
      <>
        <SummaryItem label="Pickup time" value={pickup?.displayPickupWindow}></SummaryItem>
        <SummaryItem label="Location of pickup" value={pickup?.location}></SummaryItem>
        { CARRIER.UPS === pickup?.carrier && (<SummaryItem label="Number of packages" value={pickup?.numOfPackages}></SummaryItem>)}
        <SummaryItem label="Note for Driver" value={pickup?.notes}></SummaryItem>
        <SummaryItem label="Service" value={service}></SummaryItem>
        <SummaryItem
          label="Pickup Confirmation Nr."
          value={pickup?.confirmationNumber}
          isTextHighlighted={true}></SummaryItem>

        <Flex>
          <Button autoWidth margin={`1.5rem ${buttonSideMargin} 5rem`} onClick={editPickup}>
            Edit Pickup
          </Button>
          <Button autoWidth margin={`1.5rem ${buttonSideMargin} 5rem`} onClick={cancelPickupAction}>
            Cancel Pickup
          </Button>
        </Flex>
      </>
    );
  }

  function render() {
    const canSchedulePickup = !isImport;

    return (
      <StyledContainer canSchedulePickup={canSchedulePickup}>
        <FormHeader>2. Dropoff your package(s) or manage a pickup</FormHeader>

        <DatePanel date={date} />

        <StatusPanel isPickupExists={pickup ? true : false} />

        {pickup ? (
          renderPickupDetails()
        ) : canSchedulePickup ? (
          <Button autoWidth margin={`1.5rem ${buttonSideMargin} 5rem`} onClick={togglePickupModal}>
            Add Pickup
          </Button>
        ) : (
          <ContactUs
            close={()=> {}}
            message={'Please contact our customer service if you would like to schedule a pickup.'}
            color={'grey'}
          />
        )}

        <SavePickupModal
          title={pickup ? 'Edit Existing Pickup' : 'Add Pickup'}
          visible={pickupModalVisible}
          pickupId={pickup?.id}
          pickup={pickup}
          carrier={carrier}
          shipment={shipment}
          isDateReadOnly={pickup ? true : false}
          confirmedDates={[]}
          close={togglePickupModal}
          onSave={onSavePickup}
        />
      </StyledContainer>
    );
  }

  return render();
}

export default PickupsSummary;

import React from 'react';
import styled, {css} from 'styled-components/macro';

import validationHelper from 'helpers/validationHelper';

import FormLabelContainer from 'components/common/InputComponents/FormLabelContainer';
import InputFooter from 'components/common/InputComponents/InputFooter';
import Button from 'components/common/Button';

import * as Styled from 'styles/materialUI';
import inputStyles from 'styles/customization/input';
import {colors, minInputContainerHeight, defaultInputHeight} from 'styles/shared';

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => (props.small ? '0.6rem 0' : '2rem 0')};
  min-height: ${props => (props.small ? '' : minInputContainerHeight)};
  position: relative;
`;

const StyledLabelContainer = styled(FormLabelContainer)`
  justify-content: ${props => (props.inline ? 'initial' : 'space-between')};
`;

const StyledInput = styled.input`
  height: ${defaultInputHeight};
  ${inputStyles.common.inputField};

  ${props =>
    props.error &&
    css`
      border-color: ${colors.red_main};
    `}

  ${props =>
    props.isWarning &&
    css`
      border-color: ${colors.orange_main};
    `}
`;

const StyledActionButton = styled(Button)`
  position: absolute;
  right: 0.1rem;
  top: 3.1rem;
`;

interface Props extends BaseProps {
  id?: string;
  name: string;
  label?: string;
  onChange: OnChangeHandler;
  onBlur?: () => void;
  placeholder?: string;
  value?: string;
  error?: string | ValidationError[];
  type: string;
  disabled?: boolean;
  required?: boolean;
  size: 'small' | 'big';
  tooltip: string | JSX.Element;
  actionLink?: ActionLink;
  isWarning: boolean;
  actionButton?: ActionLink;
}

TextInput.defaultProps = {
  type: 'text',
  required: false,
  size: 'big',
  tooltip: '',
  actionLink: {
    title: '',
    action: null
  },
  className: '',
  isWarning: false,
  actionButton: {
    title: '',
    action: null
  }
};

function TextInput({
  id,
  name,
  label,
  onChange,
  onBlur,
  placeholder,
  value,
  error,
  type,
  disabled,
  required,
  size,
  tooltip,
  actionLink,
  className,
  isWarning,
  actionButton
}: Props) {
  let inputOnChange = e => {
    onChange(e.target.name, e.target.value);
  };

  let inputOnBlur = e => {
    if (value && value !== value.trim()) {
      onChange(name, value.trim());
    }
    if (onBlur) onBlur();
  };

  let isSmallSize = size === 'small';

  const displayActionButton = actionButton?.title ? true : false;

  const inputError: string = validationHelper.getInputError(error);

  return (
    <StyledInputContainer small={isSmallSize} className={className}>
      {label ? (
        <>
          <StyledLabelContainer
            inline={displayActionButton}
            label={label}
            name={name}
            required={required}
            disabled={disabled}
            tooltip={tooltip}
          />

          <StyledInput
            id={id}
            type={type}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            value={value ? value : ''}
            onChange={inputOnChange}
            onBlur={inputOnBlur}
            error={inputError}
            isWarning={isWarning}
            autoComplete="nope"
          />

          {displayActionButton && (
            <StyledActionButton width={16} onClick={actionButton?.action}>
              {actionButton?.title}
            </StyledActionButton>
          )}
        </>
      ) : (
        <Styled.MaterialTextField
          id={id}
          name={name}
          type={type}
          label={placeholder}
          value={value ? value : ''}
          onChange={inputOnChange}
          onBlur={inputOnBlur}
          error={inputError ? true : false}
          autoComplete="nope"
          variant="filled"
          className={isWarning ? 'isWarning' : null}
        />
      )}

      <InputFooter inputError={inputError} actionLink={actionLink} />
    </StyledInputContainer>
  );
}

export default TextInput;

import React from 'react';
import styled from 'styled-components/macro';

import Flex from 'components/common/Flex';

import {colors, secondaryFont, mainFont, mainBorder, mediaQueries} from 'styles/shared';

const StyledContainer = styled.div`
  height: 7rem;
  padding: 2rem 2.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: ${props => (props.bordered ? mainBorder : 'none')};

  @media ${mediaQueries.mobileMax} {
    padding-left: 1.6rem;
    padding-right: 0;
  }
`;

const StyledLabel = styled.div`
  color: ${colors.darkBlue_main};
  ${secondaryFont};
`;

const StyledButton = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: ${props => (props.active ? null : `0.1rem solid ${colors.orange_main}`)};
  ${mainFont};
  color: ${props => (props.active ? colors.white : colors.orange_main)};
  background-color: ${props => (props.active ? colors.orange_main : colors.white)};
`;

const StyledPlaceholder = styled.div`
  width: 13rem;

  @media ${mediaQueries.mobileMax} {
    display: none;
  }
`;

interface Props {
  label: string;
  pagination: Pagination;
  bordered: boolean;
  onChange: (page: number) => void;
}

SimplePagination.defaultProps = {
  bordered: false
};

function SimplePagination({label, pagination, bordered, onChange}: Props) {
  const numberOfPages = Math.ceil(pagination.totalCount / pagination.pageSize);

  return (
    <StyledContainer bordered={bordered}>
      <StyledLabel>
        {label}: {pagination.totalCount}
      </StyledLabel>

      <Flex>
        {[...Array(numberOfPages)].map((x, i) => {
          let pageNumber = i + 1;
          let active = pageNumber === pagination.activePage;

          return (
            <StyledButton key={i} active={active} onClick={() => onChange(pageNumber)}>
              {pageNumber}
            </StyledButton>
          );
        })}
      </Flex>

      <StyledPlaceholder />
    </StyledContainer>
  );
}

export default SimplePagination;

import React from 'react';
import styled, {css} from 'styled-components/macro';

import validationHelper from 'helpers/validationHelper';

import FormLabelContainer from 'components/common/InputComponents/FormLabelContainer';
import Flex from 'components/common/Flex';
import InputError from 'components/common/InputComponents/InputError';

import inputStyles from 'styles/customization/input';
import {colors} from 'styles/shared';

const StyledContainer = styled(Flex)`
  margin: 2rem 0;
`;

const StyledTextArea = styled.textarea`
  ${inputStyles.common.inputField};

  ${props =>
    props.error &&
    css`
      border-color: ${colors.red_main};
    `}

  &:disabled {
    background-color: initial;
    color: ${colors.grey_main};
  }
`;

interface Props extends BaseProps {
  name: string;
  label?: string;
  onChange: OnChangeHandler;
  placeholder?: string;
  value?: string;
  error?: string | ValidationError[];
  required: boolean;
  rows?: number;
  disabled: boolean;
  tooltip: string | JSX.Element;
}

TextAreaInput.defaultProps = {
  required: false,
  rows: 6,
  tooltip: '',
  disabled: false
};

function TextAreaInput({
  name,
  label,
  onChange,
  placeholder,
  value,
  error,
  rows,
  required,
  tooltip,
  disabled,
  className
}: Props) {
  const inputOnChange = e => {
    onChange(e.target.name, e.target.value);
  };

  const inputError: string = validationHelper.getInputError(error);

  return (
    <StyledContainer direction="column" className={className}>
      {label && (
        <FormLabelContainer label={label} name={name} required={required} disabled={disabled} tooltip={tooltip} />
      )}

      <StyledTextArea
        name={name}
        rows={rows}
        placeholder={placeholder}
        disabled={disabled}
        value={value ? value : ''}
        onChange={inputOnChange}
        autoComplete="nope"
      />

      {inputError && !disabled && <InputError>{inputError}</InputError>}
    </StyledContainer>
  );
}

export default TextAreaInput;

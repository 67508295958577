import React, {useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Switch, Route as RouteDom} from 'react-router-dom';
import {get, isEmpty} from 'lodash';
import styled from 'styled-components/macro';
import $ from 'jquery';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';

import TIMER from 'constants/timers';

import utils from 'helpers/utils';
import {ALLOWED_URLS, ALLOWED_INACTIVE_USER_URLS, DISALLOWED_SUSPENDED_USER_URLS} from 'helpers/navigationHelper';

import AppPage from 'components/common/AppPage';
import Confirm from 'components/common/Confirm';
import InfoPopup from 'components/common/InfoPopup';
import AppIcon from 'components/common/AppIcon';
import GlobalMenu from 'components/common/GlobalMenu';
import Tutorial from 'components/common/Tutorial';
import ErrorBoundary from 'components/error_handlers/ErrorBoundary';
import PasswordProtectedModal from 'components/auth/components/password_protected_modal/PasswordProtectedModal';
import ResetPasswordModal from 'components/auth/components/ResetPasswordModal';
import ChangePasswordModal from 'components/auth/components/ChangePasswordModal';

import GuestAuthModal from 'components/auth/guest/auth_modal/GuestAuthModal';
import GuestSignUpModal from 'components/auth/guest/GuestSignUpModal';

import {colors, opacity} from 'styles/shared';
import 'styles/App.scss';

const StyledUiBlock = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: ${opacity(0.3)};
  filter: alpha(opacity=30);
  z-index: 999;
`;

const StyledGlobalOverlayContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface Props {
  routes: Route[];
  children?: any;
}

function App(props: Props) {
  let dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user.current as User);
  const isQuickAccessAuth = useSelector((state: AppState) => state.common.isQuickAccessAuth);

  const asyncAction = useSelector((state: AppState) => state.common.asyncAction);
  const confirmAction = useSelector((state: AppState) => state.common.confirmAction);
  const infoAction = useSelector((state: AppState) => state.common.infoAction);
  const globalMenuActions = useSelector((state: AppState) => state.common.globalMenuActions);
  const tutorialInfo = useSelector((state: AppState) => state.common.tutorialInfo);
  const showOverlay = useSelector((state: AppState) => state.common.showOverlay);
  const passwordProtectedDataModalVisible = useSelector(
    (state: AppState) => state.common.passwordProtectedDataModalVisible
  );
  const resetPasswordModalVisible = useSelector((state: AppState) => state.common.resetPasswordModalVisible);
  const changePasswordModalVisible = useSelector((state: AppState) => state.common.changePasswordModalVisible);
  const guestAuthModalVisible = useSelector((state: AppState) => state.common.guestAuthModalVisible);
  const guestSignUpModalVisible = useSelector((state: AppState) => state.common.guestSignUpModalVisible);

  const autoCloseTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (autoCloseTimer?.current) clearInterval(autoCloseTimer.current);
    };
  }, []);

  useEffect(() => {
    if (!infoAction || infoAction?.action || infoAction?.close) return;

    hideModalBackdrop();

    autoCloseTimer.current = utils.setTimeout(cancelInfoAction, TIMER.INFO_POPUP_AUTOCLOSE);
  }, [infoAction]);

  useEffect(() => {
    if (!globalMenuActions) return;

    hideModalBackdrop();
  }, [globalMenuActions]);

  const cancelInfoAction = () => {
    dispatch(commonActions.infoActionCancel());
  };

  const closeGlobalMenu = () => {
    dispatch(commonActions.globalMenuClose());
  };

  const cancelConfirmAction = () => {
    dispatch(commonActions.confirmActionCancel());
  };

  const cancelTutorialInfo = () => {
    dispatch(commonActions.tutorialInfoClose());
  };

  const closeAuthModal = () => {
    dispatch(commonActions.togglePasswordProtectedModal(false));
  };

  const closeResetPasswordModal = () => {
    dispatch(commonActions.toggleResetPasswordModal(false));
  };

  const closeChangePasswordModal = () => {
    dispatch(commonActions.toggleChangePasswordModal(false));
  };

  const closeGuestAuthModal = () => {
    dispatch(commonActions.toggleGuestAuthModal(false));
  };

  const closeGuestSignUpModal = () => {
    dispatch(commonActions.toggleGuestSignUpModal(false));
  };

  const hideModalBackdrop = () => {
    $('.modal-backdrop').css('opacity', 0);
  };

  let renderOverlay = showOverlay || get(asyncAction, 'showOverlay', false);

  const renderRoute = (route: Route, index: number) => {
    const {pageProps, component: Component} = route;

    const wrapInAppPage = !isEmpty(pageProps);

    let render = props => <Component {...props} />;

    if (wrapInAppPage) {
      render = props => (
        <AppPage pageProps={pageProps}>
          <Component {...props} />
        </AppPage>
      );
    }

    //display only navigation component for forbidden pages (guest user or quick access auth)
    if (
      (
        (user?.isGuest || isQuickAccessAuth)
          && !pageProps?.public
          && !ALLOWED_URLS.includes(route.path)
        ) || (
          (user && !user.active)
          && !ALLOWED_INACTIVE_USER_URLS.includes(route.path)
        ) || (
          (user && (user.suspended && !user.whitelisted))
          && DISALLOWED_SUSPENDED_USER_URLS.includes(route.path)
      )
    ) {
      render = props => <AppPage pageProps={pageProps} />;
    }

    return <RouteDom key={index} exact={route.exact} path={route.path} render={render} />;
  };

  function render() {
    return (
      <ErrorBoundary>
        {renderOverlay && (
          <StyledUiBlock>
            <StyledGlobalOverlayContent>
              <AppIcon name="loading" size="10x" color={colors.darkBlue_lighter} spin />
            </StyledGlobalOverlayContent>
          </StyledUiBlock>
        )}

        {confirmAction && (
          <Confirm
            title={confirmAction.title}
            text={confirmAction.text}
            visible={true}
            action={async () => {
              cancelConfirmAction();
              await confirmAction.action();
            }}
            close={async () => {
              cancelConfirmAction();
              if (confirmAction.close) await confirmAction.close();
            }}
          />
        )}

        {infoAction && (
          <InfoPopup
            text={infoAction.text}
            type={infoAction.type}
            actionTitle={infoAction.actionTitle}
            closeTitle={infoAction.closeTitle}
            logToRollbar={infoAction.logToRollbar}
            rollbarContext={infoAction.rollbarContext}
            action={
              infoAction.action
                ? async () => {
                    cancelInfoAction();
                    if (infoAction.action) await infoAction.action();
                  }
                : undefined
            }
            visible={true}
            close={
              infoAction.close
                ? async () => {
                    cancelInfoAction();
                    if (infoAction.close) await infoAction.close();
                  }
                : undefined
            }
          />
        )}

        {globalMenuActions && <GlobalMenu visible={true} actions={globalMenuActions} close={closeGlobalMenu} />}

        {tutorialInfo && (
          <Tutorial tutorial={tutorialInfo.tutorial} steps={tutorialInfo.steps} close={cancelTutorialInfo} />
        )}

        {passwordProtectedDataModalVisible && (
          <PasswordProtectedModal visible={passwordProtectedDataModalVisible} close={closeAuthModal} />
        )}

        {resetPasswordModalVisible && (
          <ResetPasswordModal visible={resetPasswordModalVisible} close={closeResetPasswordModal} />
        )}

        {changePasswordModalVisible && (
          <ChangePasswordModal visible={changePasswordModalVisible} close={closeChangePasswordModal} />
        )}

        {guestAuthModalVisible && <GuestAuthModal visible={guestAuthModalVisible} close={closeGuestAuthModal} />}

        {guestSignUpModalVisible && (
          <GuestSignUpModal visible={guestSignUpModalVisible} close={closeGuestSignUpModal} />
        )}

        <Switch>{props.routes.map((route, index: number) => renderRoute(route, index))}</Switch>

        {props.children}
      </ErrorBoundary>
    );
  }

  return render();
}

export default App;

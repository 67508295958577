import React from 'react';
import styled from 'styled-components/macro';

import stateStorageService from 'services/stateStorageService';

import Flex from 'components/common/Flex';
import DropdownButton from 'components/common/Dropdown/DropdownButton';

const StyledContainer = styled(Flex)`
  margin-bottom: 3rem;
`;

const CREATED_BY_ME = 'created_by_me';
const CREATED_BY_CSRs = 'created_by_all';

function CSRToggler() {
  const createdByOptions: BasicOption[] = [
    {value: CREATED_BY_CSRs, label: 'Created By CSRs'},
    {value: CREATED_BY_ME, label: 'Created By Me'}
  ];

  function onChange(value) {
    const createdByMe = value === CREATED_BY_ME;

    stateStorageService.toggleCreatedByCSR(createdByMe);

    window.location.reload();
  }

  function render() {
    const isCreatedByCSR = stateStorageService.isCreatedByCSR();

    const value = isCreatedByCSR ? CREATED_BY_ME : CREATED_BY_CSRs;

    return (
      <StyledContainer justify="flex-end">
        <DropdownButton value={value} options={createdByOptions} onChange={onChange} />
      </StyledContainer>
    );
  }

  return render();
}

export default CSRToggler;

import {LOAD_QUOTES, CHANGE_QUOTES_PAGE, CHECK_RATE, CLEAR_QUOTE_STATE} from 'action_types/quoteActionTypes';

import helper from './actionHelper';
import apis from 'domain/apis';

export default {
  loadQuotes,
  updateQuotesPageNumber,
  checkRate,
  clearQuoteState,
  submitForFreightQuote,
  getActiveQuoteForShipment
};

function loadQuotes(activePage: number, pageSize: number) {
  return helper.dispatchAsyncAction(async dispatch => {
    const result = await apis.quote.getQuotes(activePage, pageSize);

    dispatch(helper.getAction(LOAD_QUOTES, {...result}));
  });
}

function getActiveQuoteForShipment(shipmentId: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    return await apis.quote.getActiveQuoteForShipment(shipmentId);
  });
}

function updateQuotesPageNumber(activePage: number) {
  return helper.getAction(CHANGE_QUOTES_PAGE, {activePage});
}

function checkRate(data: StandaloneQuote, isDomestic: boolean) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.quote.checkRate(data, isDomestic);

    dispatch(helper.getAction(CHECK_RATE, {rates: response.rates, errorMessage: response.errorMessage}));
  });
}

function clearQuoteState() {
  return helper.getAction(CLEAR_QUOTE_STATE);
}

function submitForFreightQuote(standaloneQuote: StandaloneQuote, contactData: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.quote.submitForFreightQuoteAction(standaloneQuote, contactData);
    return response?.success;
  });
}

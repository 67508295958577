import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';

import {AppState} from 'reducers';
import lineItemsActions from 'actions/lineItemsActions';
import commonActions from 'actions/commonActions';

import entities from 'domain/entities';

import uiHelper from 'helpers/uiHelper';
import validationHelper from 'helpers/validationHelper';

import MEASUREMENT_SYSTEM from 'constants/measurementSystems';

import FormHeader from 'components/common/FormHeader';
import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';
import ActionPanel from './components/action_panel/ActionPanel';
import LineItemsList from './components/line_items_list/LineItemsList';
import SaveLineItem from './components/SaveLineItem';

import * as Styled from 'styles/modal';

const StyledHeader = styled(Flex)`
  padding: 2.6rem 2.6rem 0;
`;

interface Props {
  visible: boolean;
  currency: string;
  close: () => void;
  onLoad: (lineItem: LineItem) => void;
}

function CommodityManager({visible, currency, close, onLoad}: Props) {
  const dispatch = useDispatch();

  const measurementSystem = useSelector((state: AppState) => state.draft.current.data?.measurementSystem) ?? MEASUREMENT_SYSTEM.IMPERIAL;

  const lineItems = useSelector((state: AppState) => state.lineItem.list);
  const activePage = useSelector((state: AppState) => state.lineItem.pagination.activePage);
  const pageSize = useSelector((state: AppState) => state.lineItem.pagination.pageSize);

  const [displayLineItems, setDisplayLineItems] = useState<LineItem[]>([]);
  const [searchStr, setSearchStr] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState(false);

  const [lineItem, setLineItem] = useState<LineItem | null>(null);
  const [errors, setErrors] = useState({});
  const [lineItemParamsChanged, setLineItemParamsChanged] = useState<number | undefined>(undefined);
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    loadItems(true);

    //reset active page number on modal close
    return () => {
      dispatch(lineItemsActions.updateLineItemPageNumber(1));
    };
  }, []);

  useEffect(() => {
    if (!readyToRender) return;

    if (!checkedItems.length && checkedAll) {
      setCheckedAll(false);
      setEditMode(false);
    }

    if (checkedItems.length === displayLineItems.length && !checkedAll) setCheckedAll(true);

    if (checkedItems.length !== displayLineItems.length && checkedAll) setCheckedAll(false);
  }, [checkedItems]);

  useEffect(() => {
    if (isEmpty(lineItems)) return setDisplayLineItems([]);

    const firstPageIndex = (activePage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;

    setDisplayLineItems(lineItems.slice(firstPageIndex, lastPageIndex));
  }, [lineItems, activePage]);

  useEffect(() => {
    if (isEmpty(errors) || !lineItem) return;

    const validationResult: ValidationResult = validationHelper.validateCommodityItem(lineItem);

    setErrors(validationResult.errors);
  }, [lineItemParamsChanged]);

  async function loadItems(firstLoad = false) {
    await dispatch(lineItemsActions.loadLineItems());

    if (firstLoad) setReadyToRender(true);
  }

  function toggleEditMode() {
    setEditMode(!editMode);
  }

  function onSearchChange(field, value) {
    setSearchStr(value);

    if (value) return;

    loadItems();
  }

  function addNewItem() {
    const defaultItem = entities.draft.getDefaultInvoiceItem();

    let lineItemInitial = {
      ...defaultItem,
      id: '',
      name: ''
    };

    setLineItem(lineItemInitial);
  }

  function onItemChange(field, value) {
    if (!lineItem) return;

    setLineItem({...lineItem, [field]: value});

    setLineItemParamsChanged(new Date().getTime());
  }

  async function saveLineItem() {
    if (!lineItem) return;

    const validationResult: ValidationResult = validationHelper.validateCommodityItem(lineItem);

    if (!validationResult.valid) {
      setErrors(validationResult.errors);
      return;
    }

    const response: any = await dispatch(lineItemsActions.createLineItem(lineItem, currency, measurementSystem));
    const isSuccess = response as boolean;

    if (isSuccess) {
      setLineItem(null);
      await loadItems();
      uiHelper.showNotice('You have successfully saved your item!');
    }
  }

  async function deleteItems() {
    dispatch(
      commonActions.confirmAction({
        title: 'Delete Selected',
        action: async () => {
          try {
            for (let checkedId of checkedItems) {
              await dispatch(lineItemsActions.removeLineItem(checkedId));
            }

            setCheckedItems([]);
          } catch (e) {
            uiHelper.logError(e);
          }
        }
      })
    );
  }

  function toggleCheckbox(checkedId) {
    let index = checkedItems.findIndex(id => id === checkedId);

    if (index === -1) {
      setCheckedItems(prevArray => [...prevArray, checkedId]);
    } else {
      setCheckedItems(checkedItems.filter(id => id !== checkedId));
    }
  }

  function selectAllToggle() {
    if (checkedAll) {
      setCheckedItems([]);
      setCheckedAll(false);
    } else {
      let checked = displayLineItems.map(item => item.id);
      setCheckedItems(checked);

      setCheckedAll(true);
    }
  }

  function closeSaveModal() {
    setLineItem(null);
  }

  function render() {
    if (!readyToRender) return null;

    let createLineItemModalVisible = lineItem ? true : false;

    return (
      <Styled.CustomModal
        show={visible}
        backdrop="static"
        width="112.2rem"
        height="116.6rem"
        padding="0"
        onHide={close}>
        <StyledHeader justify="space-between">
          <FormHeader>My Items List</FormHeader>
          <CloseButton onClick={close} />
        </StyledHeader>

        <ActionPanel
          searchStr={searchStr}
          anyItems={lineItems?.length > 0}
          anyCheckedItems={checkedItems?.length > 0}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          onChange={onSearchChange}
          onAdd={addNewItem}
          onDelete={deleteItems}
        />

        <LineItemsList
          items={displayLineItems}
          checkedItems={checkedItems}
          editMode={editMode}
          toggleCheckbox={toggleCheckbox}
          checkedAll={checkedAll}
          selectAllToggle={selectAllToggle}
          onSelect={onLoad}
        />

        {createLineItemModalVisible && lineItem && (
          <SaveLineItem
            visible={createLineItemModalVisible}
            lineItem={lineItem}
            currency={currency}
            measurementSystem={measurementSystem}
            close={closeSaveModal}
            onChange={onItemChange}
            onSave={saveLineItem}
            errors={errors}
          />
        )}
      </Styled.CustomModal>
    );
  }

  return render();
}

export default CommodityManager;

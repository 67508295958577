import React from 'react';
import styled from 'styled-components';

import {opacity, colors, secondaryFont} from 'styles/shared';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${colors.white};
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};
  border-radius: 0.8rem;
  padding: 1.6rem;
  margin: 2rem 0;
`;

const StyledButton = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  background: ${colors.orange_main};
  box-shadow: 0 0.4rem 2rem ${opacity(0.15)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-size: 3.2rem;
  line-height: 3.2rem;
  margin-right: 1.6rem;
`;

const StyledText = styled.span`
  ${secondaryFont};
`;

interface Props {
  open: () => void;
}

function AddNewPayment({open}: Props) {
  return (
    <StyledContainer onClick={open}>
      <StyledButton>+</StyledButton>
      <StyledText>Add new payment method</StyledText>
    </StyledContainer>
  );
}

export default AddNewPayment;

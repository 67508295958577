import React from 'react';

function TaxIdTooltip() {
  return (
    <span>
      <strong>TAX ID</strong> - Taxpayer Identification Number <br /> and Employer Identification Number (EIN): <br /> A
      US Based taxpayer identification number <br /> which is a nine or eleven digit number <br /> assigned to a
      business or organization by <br /> the IRS. Individuals are assigned a Social <br /> Security Number.
    </span>
  );
}

export default TaxIdTooltip;

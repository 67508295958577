import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {get, isEmpty} from 'lodash';

import FEATURE from 'constants/features';
import PAYMENT_METHOD from 'constants/paymentMethods';
import PAYMENT_TIME from 'constants/paymentTime';

import {AppState} from 'reducers';
import draftActions from 'actions/draftActions';
import paymentActions from 'actions/paymentActions';
import commonActions from 'actions/commonActions';

import validationHelper from 'helpers/validationHelper';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';

import DutiesAndTaxesPayLater from './components/duties_taxes_payment/DutiesAndTaxesPayLater';
import DutiesAndTaxesPayNow from './components/duties_taxes_payment/DutiesAndTaxesPayNow';
import PaymentInfo from './components/payment_info/PaymentInfo';
import BillingAddressSection from './components/BillingAddressSection';
import ACHPayment from './components/ACHPayment';
import CreditCardInfo from './components/CreditCardInfo';
import TermsAndConditionsSection from './components/TermsAndConditionsSection';
import BillingContactInformationSection from './components/BillingContactInformationSection';
import PaymentMethodSelection from './components/PaymentMethodSelection';
import PaymentTimeSection from './components/PaymentTimeSection';
import PaymentButton from './components/PaymentButton';

function Payment() {
  const dispatch = useDispatch();

  const draft = useSelector((state: AppState) => state.draft.current.data as Draft);
  const errors = useSelector((state: AppState) => state.draft.current.errors);
  const billingInfo = useSelector((state: AppState) => state.user.billingInfo);
  const countryOptions = useSelector((state: AppState) => state.shipmentOptions.countryOptions);

  const paymentMethods = useSelector((state: AppState) => state.payment.paymentMethods);
  const billingAddress = useSelector((state: AppState) => state.payment.billingAddress);
  const creditCard = useSelector((state: AppState) => state.payment.creditCard);
  const achAccount = useSelector((state: AppState) => state.payment.achAccount);
  const paymentMethod = useSelector((state: AppState) => state.payment.selectedPaymentMethod);
  const paymentTime = useSelector((state: AppState) => state.payment.paymentTime);
  const paymentId = useSelector((state: AppState) => state.payment.paymentId);
  const paymentErrors = useSelector((state: AppState) => state.payment.errors);
  const paymentParamsChanged = useSelector((state: AppState) => state.payment.paymentParamsChanged);

  useEffect(() => {
    if (isEmpty(paymentErrors)) return;

    const validationResult: ValidationResult = validationHelper.validatePaymentMethod(
      getSavePaymentMethodDto(),
      countryOptions
    );

    dispatch(paymentActions.setPaymentErrors(validationResult.errors));
  }, [paymentParamsChanged]);

  async function savePaymentMethod(e) {
    if (e) e.preventDefault();

    const data: SavePaymentMethodDto = getSavePaymentMethodDto();

    const validationResult: ValidationResult = validationHelper.validatePaymentMethod(data, countryOptions);

    if (!validationResult.valid) {
      dispatch(paymentActions.setPaymentErrors(validationResult.errors));
      return;
    } else {
      dispatch(paymentActions.setPaymentErrors({}));
    }

    const response: any = await dispatch(paymentActions.savePaymentMethod(data));
    const isSuccess = response as boolean;

    if (isSuccess) await dispatch(paymentActions.loadPaymentMethods());
  }

  function getSavePaymentMethodDto(): SavePaymentMethodDto {
    return {
      creditCard: paymentMethod === PAYMENT_METHOD.CREDIT_CARD ? creditCard : null,
      achAccount: paymentMethod === PAYMENT_METHOD.ACH ? achAccount : null,
      billingAddress
    };
  }

  function changePaymentMethod(value) {
    dispatch(paymentActions.changePaymentMethod(value));
  }

  function changePaymentTime(value) {
    dispatch(paymentActions.changePaymentTime(value));
  }

  function selectShipmentPaymentMethod(id: string) {
    dispatch(paymentActions.usePaymentMethod(id));
  }

  async function deletePaymentMethod(id: string) {
    dispatch(
      commonActions.confirmAction({
        title: 'Delete Selected',
        action: async () => {
          const response: any = await dispatch(paymentActions.removePaymentMethod(id, paymentId));
          const isSuccess = response as boolean;

          if (isSuccess) await dispatch(paymentActions.loadPaymentMethods());
        }
      })
    );
  }

  function onChange(field, value) {
    dispatch(draftActions.modifyDraft('payment', field, value));
  }

  function renderDutiesAndTaxesPayment() {
    // Payments turned off or international and domestic pay later
    if (!FEATURE.PAYMENT_AUTH || paymentTime === PAYMENT_TIME.PAY_LATER) {
      return <DutiesAndTaxesPayLater billingInfo={billingInfo} draft={draft} errors={errors} onChange={onChange} />;
    // International products pay now
    } else if (draft.isDutiable) {
      return <DutiesAndTaxesPayNow />;
    }
    // International documents and all domestic pay now
    return <></>;
  }

  function renderPaymentInfo() {
    return (
      <PaymentInfo
        paymentMethods={paymentMethods}
        paymentId={paymentId}
        savePaymentMethodAction={savePaymentMethod}
        selectShipmentPaymentMethod={selectShipmentPaymentMethod}
        deletePaymentMethod={deletePaymentMethod}
      />
    );
  }

  function renderCommonPaymentSections() {
    return (
      <>
        {!billingInfo?.billingContact && <BillingContactInformationSection iri={billingInfo?.['@id']} />}
        {renderDutiesAndTaxesPayment()}

        <TermsAndConditionsSection
          termsAndConditionsAgreement={draft.payment.termsAndConditionsAgreement}
          onChange={onChange}
          error={get(errors, 'payment.termsAndConditionsAgreement')}
        />
      </>
    );
  }

  function userNoBillingInfo() {
    return (
      <>
        <Container>
          <FormHeader>Payment Info</FormHeader>
          <PaymentMethodSelection
            paymentMethod={paymentMethod}
            onChange={(field, value) => changePaymentMethod(value)}
          />
          {paymentMethod === PAYMENT_METHOD.CREDIT_CARD && <CreditCardInfo />}
          {paymentMethod === PAYMENT_METHOD.ACH && <ACHPayment />}
          <BillingAddressSection />
          <PaymentButton
            paymentMethod={paymentMethod}
            type="secondary"
            margin="3.4rem 0 1.4rem"
            onSaveAction={savePaymentMethod}
          />
        </Container>

        {renderCommonPaymentSections()}
      </>
    );
  }

  function userWithBillingInfo() {
    return (
      <>
        {renderPaymentInfo()}
        {renderCommonPaymentSections()}
      </>
    );
  }

  function userCanPayLater() {
    return (
      <>
        <PaymentTimeSection paymentTime={paymentTime} onChange={(field, value) => changePaymentTime(value)} />

        {paymentTime === PAYMENT_TIME.PAY_NOW && renderPaymentInfo()}

        {renderCommonPaymentSections()}
      </>
    );
  }

  function render() {
    if (!FEATURE.PAYMENT_AUTH) return renderDutiesAndTaxesPayment();

    const canPayLater = billingInfo?.canPayLater;
    const anyPaymentMethods = paymentMethods?.length > 0;

    //Payment Use-Case: User / Account auth NO info on file
    if (!canPayLater && !anyPaymentMethods) return userNoBillingInfo();

    //Payment Use-Case: User with info on life
    if (!canPayLater && anyPaymentMethods) return userWithBillingInfo();

    //Payment Use-Case: user with pay later option
    if (canPayLater) return userCanPayLater();

    return null;
  }

  return render();
}

export default Payment;

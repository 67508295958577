import React from 'react';

import styled from 'styled-components/macro';

import Checkbox from './Checkbox';

import {secondaryFont} from 'styles/shared';

const CONTAINER_SIZE = '4rem';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${CONTAINER_SIZE};
  height: ${CONTAINER_SIZE};
  min-width: ${CONTAINER_SIZE};
  min-height: ${CONTAINER_SIZE};
`;

const StyledLabel = styled.span`
  ${secondaryFont};
  margin-right: 0.7rem;
  cursor: pointer;
  user-select: none;
`;

interface Props extends BaseProps {
  name: string;
  label?: string | JSX.Element;
  value?: boolean;
  preSelected?: boolean;
  onToggle: OnToggleHandler;
}

CheckBoxInput.defaultProps = {
  value: false,
  label: '',
  preSelected: false
};

function CheckBoxInput({name, label, onToggle, value, preSelected, className}: Props) {
  const onChange = () => {
    onToggle(name, !value);
  };

  return (
    <StyledContainer htmlFor={name} className={className} onClick={onChange}>
      <StyledCheckBoxContainer>
        <Checkbox name={name} onToggle={onChange} value={value} preSelected={preSelected} />
      </StyledCheckBoxContainer>

      {label && <StyledLabel>{label}</StyledLabel>}
    </StyledContainer>
  );
}

export default CheckBoxInput;

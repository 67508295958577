import {lowerCase, startCase, sortBy, pull, isEmpty} from 'lodash';

import * as ACTION_TYPES from 'action_types/shipmentOptionsActionTypes';

import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import INVOICE_TYPE from 'constants/invoiceTypes';

import helper from './reducerHelper';
import uiHelper from 'helpers/uiHelper';
import initialState from './initialState';

export interface ShipmenOptionState {
  ftrExemptions: BasicOption[];
  countryOptions: CountryOption[];
  countryList: BasicOption[];
  signatureServiceOptions: BasicOption[];
  whoPaysOptions: string[];
  termsOfTradeOptions: TermsOfTradeOption[];
  currencyOptions: BasicOption[];
  carrierServices: CarrierService[];
  itemQuantityOptions: BasicOption[];
  invoiceTypeOptions: BasicOption[];
  proformaInvoiceTypeOptions: BasicOption[];
  eeiFilingTypeOptions: string[];
  exportTypeOptions: BasicOption[];
}

interface TermsOfTradeOption extends BasicOption {
  definition: string;
}

interface OptionsPayload {
  ftrOptions: BasicOption[];
  countryOptions: CountryOption[];
  signatureServices: BasicOption[];
  whoPaysOptions: string[];
  tradeOptions: TermsOfTradeOption[];
  currencyList: BasicOption[];
  carrierServices: CarrierService[];
  itemQuantityOptions: BasicOption[];
  invoiceTypeOptions: string[];
  eeiFilingTypeOptions: string[];
  exportOptions: BasicOption[];
}

const shipmentOptionsReducer = (state: ShipmenOptionState = initialState.shipmentOptions, action) => {
  return helper.handleActions<ShipmenOptionState>(state, action, {
    [ACTION_TYPES.LOAD_OPTIONS_DATA](state, payload: OptionsPayload) {
      state.ftrExemptions = payload.ftrOptions;

      const countryOptions = payload.countryOptions;
      state.countryOptions = countryOptions;
      state.countryList = getCountryList(countryOptions);

      state.signatureServiceOptions = payload.signatureServices;

      state.whoPaysOptions = payload.whoPaysOptions;

      state.termsOfTradeOptions = payload.tradeOptions;

      state.currencyOptions = payload.currencyList;

      state.carrierServices = payload.carrierServices;

      state.itemQuantityOptions = payload.itemQuantityOptions;

      state.eeiFilingTypeOptions = payload.eeiFilingTypeOptions;

      state.exportTypeOptions = payload.exportOptions;

      loadInvoiceTypeOptions(state, payload);
    }
  });
};

function loadInvoiceTypeOptions(state, payload: OptionsPayload) {
  try {
    const invoiceTypeOptions = payload.invoiceTypeOptions;

    let basicInvoiceTypes = invoiceTypeOptions.filter(
      item => !item.includes(INVOICE_TYPE.PROFORMA) && item !== INVOICE_TYPE.EXTERNAL
    );
    basicInvoiceTypes.splice(1, 0, INVOICE_TYPE.PROFORMA);

    let proformaInvoiceTypes = invoiceTypeOptions.filter(item => item.includes(INVOICE_TYPE.PROFORMA));

    state.invoiceTypeOptions = basicInvoiceTypes.map(item => {
      let label = item === INVOICE_TYPE.PROFORMA ? 'Pro Forma' : item;
      return {value: item, label};
    });

    state.proformaInvoiceTypeOptions = proformaInvoiceTypes.map(item => {
      let label = '';

      if (item === INVOICE_TYPE.PROFORMA_GIFT) label = 'Gift';
      if (item === INVOICE_TYPE.PROFORMA_SAMPLE) label = 'Sample';
      if (item === INVOICE_TYPE.PROFORMA_RETURN) label = 'Return/Repair';

      return {value: item, label};
    });
  } catch (err) {
    uiHelper.logError(err);
  }
}

function getCountryList(countryOptions) {
  let countryList: BasicOption[] = [];

  if (isEmpty(countryOptions)) return countryList;

  countryOptions.forEach(country => {
    let label = lowerCase(country.name);
    label = startCase(label);

    const option = {value: country.code, label: label};
    countryList.push(option);
  });

  countryList = sortBy(countryList, option => option.label);

  const usaOption = countryList.find(option => option.value === COUNTRY_CODE.USA);

  if (usaOption) {
    pull(countryList, usaOption);
    countryList.unshift(usaOption);
  }

  return countryList;
}

export default shipmentOptionsReducer;

import httpHelper from 'helpers/httpHelper';

export default {
  getDrafts,
  getDraft,
  createDraft,
  updateDraft,
  deleteDraft
};

async function getDrafts(activePage: number, pageSize: number): Promise<{drafts: DraftResponse[]; totalItems: number}> {
  const options = {
    page: activePage,
    itemsPerPage: pageSize,
    'exists[completedOn]': false
  };

  const response: {items: DraftResponse[]; totalItems: number} = await httpHelper.get('/shipment_drafts', options, {
    noCache: true,
    totalItems: true
  });

  return {
    drafts: response.items,
    totalItems: response.totalItems
  };
}

function getDraft(id: string): Promise<DraftResponse | AcceptableErrorCodeResponse> {
  const options = {page: 1};

  return httpHelper.get(`/shipment_drafts/${id}`, options, {acceptableErrorCodes: [404]});
}

function createDraft(draft: Draft): Promise<DraftResponse> {
  const body = {
    payload: JSON.stringify(draft),
    completedOn: null,
    updatedOn: new Date(),
    shipmentId: draft.id
  };

  return httpHelper.post('/shipment_drafts', body);
}

function updateDraft(draft: Draft): Promise<DraftResponse> {
  const body = {
    payload: JSON.stringify(draft),
    completedOn: null,
    updatedOn: new Date(),
    shipmentId: draft.id
  };

  return httpHelper.put(`/shipment_drafts/${draft.id}`, body);
}

function deleteDraft(id): Promise<SuccessfullResponse> {
  return httpHelper.delete(`/shipment_drafts/${id}`, {});
}

const COUNTRY_CODE = {
  USA: 'US',
  PUERTO_RICO: 'PR',
  RUSSIA: 'RU',
  CHINA: 'CN',
  VENEZUELA: 'VE',
  HONG_KONG: 'HK',
  AMERICAN_SAMOA: 'AS',
  VIRGIN_ISLANDS_US: 'VI',
  GUAM: 'GU',
  MARIANA_ISLANDS: 'MP',
  MARSHALL_ISLANDS: 'MH',
  MICRONESIA: 'FM',
  PALAU: 'PW',
  CURACAO: 'XC',
  KOSOVO: 'KV',
  NEVIS: 'XN',
  TAIWAN: 'TW',
  ST_BARTHELEMY: 'XY',
  ST_MAARTEN: 'XM',
  ANGUILLA: 'AI',
  ANTIGUA: 'AG',
  BAHAMAS: 'BS',
  BARBADOS: 'BB',
  BERMUDA: 'BM',
  BONAIRE: 'XB',
  CANARY_ISLANDS: 'IC',
  CAYMAN_ISLANDS: 'KY',
  DOMINICA: 'DM',
  GRENADA: 'GD',
  JAMAICA: 'JM',
  MONTSERRAT: 'MS',
  NETHERLAND_ANTILLES: 'AN',
  SOMALILAND: 'XS',
  ST_EUSTATIUS: 'XE',
  ST_KITTS: 'KN',
  ST_LUCIA: 'LC',
  ST_VINCENT: 'VC',
  TRINIDAD_AND_TOBAGO: 'TT',
  TURKS_AND_CAICOS: 'TC',
  VIRGIN_ISLANDS_BRITISH: 'VG',
  ARGENTINA: 'AR',
  SOMALIA: 'SO',
  CARIBBEAN_NETHERLANDS: 'BQ'
};

export default COUNTRY_CODE;

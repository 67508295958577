import React, {useState, Fragment} from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';
import {isEqual} from 'lodash';

import FEATURE from 'constants/features';

import dateHelper from 'helpers/dateHelper';
import formatHelper from 'helpers/formatHelper';

import Container from 'components/common/Container';
import AppIcon from 'components/common/AppIcon';
import IssueDetails from './components/IssueDetails';
import ListHeader from '../ListHeader';

import * as Styled from 'styles/table';
import {colors} from 'styles/shared';

const StyledStatusText = styled.span`
  color: ${colors.red_main};
  font-weight: 900;
`;

const StyledDetailsLink = styled.span`
  color: ${colors.red_main};
  cursor: pointer;
`;

type ShipmentStatusesNeedAttentionProps = {
  shipmentStatuses: Shipment[];
};

function ShipmentStatusesNeedAttention({shipmentStatuses}: ShipmentStatusesNeedAttentionProps) {
  const [selectedShipment, setSelectedShipment] = useState(null);

  function seeDetails(item) {
    if (isEqual(item, selectedShipment)) return setSelectedShipment(null);

    setSelectedShipment(item);
  }

  return (
    <Container withoutPadding={true}>
      <Row>
        <Col sm={12}>
          <ListHeader>Shipments That Need Attention</ListHeader>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Styled.Table>
            <thead>
              <Styled.TRowHead>
                {FEATURE.PAID_COLUMN_VISIBLE && <Styled.THeadCell smallIndent>Paid</Styled.THeadCell>}

                <Styled.THeadCell>Orig.</Styled.THeadCell>
                <Styled.THeadCell>Dest.</Styled.THeadCell>
                <Styled.THeadCell>Shipper</Styled.THeadCell>
                <Styled.THeadCell>Receiver</Styled.THeadCell>
                <Styled.THeadCell>Ship. date</Styled.THeadCell>
                <Styled.THeadCell>Service</Styled.THeadCell>
                <Styled.THeadCell>Track. Nr.</Styled.THeadCell>
                <Styled.THeadCell>Status</Styled.THeadCell>
                <Styled.THeadCell />
              </Styled.TRowHead>
            </thead>

            <tbody>
              {shipmentStatuses.map(item => {
                const id = item.id;

                let isIssueSelected = isEqual(item, selectedShipment);

                let linkText = isIssueSelected ? 'Colapse' : 'See details';

                let shipmentDate = dateHelper.displayShortDate(item.targetShipDate);

                return (
                  <Fragment key={id}>
                    <Styled.TRowBody attention noBorder={isIssueSelected}>
                      {FEATURE.PAID_COLUMN_VISIBLE && (
                        <Styled.TBodyCell smallIndent>
                          <AppIcon name="unpaid" />
                        </Styled.TBodyCell>
                      )}

                      <Styled.TBodyCell>{formatHelper.displayValue(item?.shipmentFrom?.country)}</Styled.TBodyCell>
                      <Styled.TBodyCell>{formatHelper.displayValue(item?.shipmentTo?.country)}</Styled.TBodyCell>
                      <Styled.TBodyCell>{formatHelper.displayValue(item?.shipmentFrom?.contactName)}</Styled.TBodyCell>
                      <Styled.TBodyCell>{formatHelper.displayValue(item?.shipmentTo?.contactName)}</Styled.TBodyCell>
                      <Styled.TBodyCell>{shipmentDate}</Styled.TBodyCell>
                      <Styled.TBodyCell>{formatHelper.displayValue(item?.carrier?.name)}</Styled.TBodyCell>
                      <Styled.TBodyCell>{formatHelper.displayValue(item?.trackingNumber)}</Styled.TBodyCell>

                      <Styled.TBodyCell bold>
                        <StyledStatusText>
                          {formatHelper.displayValue(item?.primaryStatusFlagLabel?.toUpperCase())}
                        </StyledStatusText>
                      </Styled.TBodyCell>

                      <Styled.TBodyCell>
                        <StyledDetailsLink onClick={() => seeDetails(item)}>{linkText}</StyledDetailsLink>
                      </Styled.TBodyCell>
                    </Styled.TRowBody>

                    {isIssueSelected && (
                      <tr>
                        <td colSpan={12}>
                          <IssueDetails issue={item} />
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </Styled.Table>
        </Col>
      </Row>
    </Container>
  );
}

export default ShipmentStatusesNeedAttention;

import * as actionTypes from 'action_types/addressActionTypes';

import helper from './actionHelper';
import apis from 'domain/apis';

export default {
  loadAddresses,
  createAddress,
  updateAddress,
  removeAddress,
  importAddresses,
  updateAddressesPageNumber,
  selectAddress,
  searchAddress
};

function loadAddresses(search: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    const result = await apis.address.getAddresses(search);

    dispatch(helper.getAction(actionTypes.LOAD_ADDRESSES, {...result}));

    return result?.addresses;
  }, false);
}

function createAddress(address: Sending) {
  return helper.dispatchAsyncAction(async () => {
    const response: SavedAddress = await apis.address.createAddress(address);
    return response;
  });
}

function updateAddress(address: Sending, id: string) {
  return helper.dispatchAsyncAction(async () => {
    const response: SavedAddress = await apis.address.updateAddress(address, id);
    return response?.id ? true : false;
  });
}

function removeAddress(id: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.address.deleteAddress(id);

    if (response?.success) {
      dispatch(helper.getAction(actionTypes.REMOVE_ADDRESS, {id}));
    }
  });
}

function importAddresses(formData, user) {
  return helper.dispatchAsyncAction(async dispatch => {
    return await apis.address.importAddresses(formData, user);
  });
}

function updateAddressesPageNumber(activePage: number) {
  return helper.getAction(actionTypes.CHANGE_ADDRESSES_PAGE, {activePage});
}

function selectAddress(address: SavedAddress) {
  return helper.getAction(actionTypes.SELECT_ADDRESS_FROM_SEARCH, {address});
}

function searchAddress(search) {
  return helper.dispatchAsyncAction(async () => {
    const result = await apis.address.getAddresses(search);
    return result.addresses;
  });
}

import React from 'react';

function FedExTooltip() {
  return (
    <span>
      Please select at least a 3 hour window for the driver. 
    </span>
  );
}

export default FedExTooltip;

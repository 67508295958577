import React, {Fragment} from 'react';
import styled from 'styled-components/macro';
import {isEmpty} from 'lodash';

import SHIPMENT_STATUS from 'constants/shipmentStatuses';
import FEATURE from 'constants/features';

import dateHelper from 'helpers/dateHelper';
import formatHelper from 'helpers/formatHelper';

import CheckBoxInput from 'components/common/CheckboxInput';
import AppIcon, {IconName} from 'components/common/AppIcon/AppIcon';
import ActionsPopup from 'components/common/ActionsPopup';

import * as Styled from 'styles/table';

import {colors} from 'styles/shared';

const StyledStatusText = styled.span`
  color: ${props => (props.status === SHIPMENT_STATUS.DELIVERED ? `${colors.green_main}` : `${colors.orange_main}`)};
`;

interface Props {
  shipments: Shipment[];
  checkedItems: string[];
  onCheck: (field: string) => void;
  actions?: DropdownAction[];
}

ShipmentsTable.defaultProps = {
  actions: []
};

function ShipmentsTable({shipments, checkedItems, onCheck, actions}: Props) {
  function renderActionPopup(shipment) {
    if (!actions) return null;

    let actionsToRender = actions.filter(action => {
      if (isEmpty(action?.hideForStatuses)) return true;

      return !action?.hideForStatuses?.includes(shipment?.primaryStatusFlag);
    });

    return (
      <Styled.TBodyCell actions>
        <ActionsPopup actions={actionsToRender} activeItem={shipment} />
      </Styled.TBodyCell>
    );
  }

  function renderTableBody(anyActions, checkboxesVisible) {
    return (
      <tbody>
        {shipments.map((shipment: Shipment) => {
          const id = shipment.id;

          const icon: IconName = false ? 'paid' : 'unpaid';

          let isChecked = checkedItems.find(i => i === id) ? true : false;

          let shipmentDate = dateHelper.displayShortDate(shipment.targetShipDate);

          let delivered = shipment?.primaryStatusFlag === SHIPMENT_STATUS.DELIVERED;

          return (
            <Fragment key={id}>
              <Styled.TRowBody success={delivered} checked={isChecked}>
                {checkboxesVisible && (
                  <Styled.TBodyCell>
                    <CheckBoxInput name={id} value={isChecked} onToggle={onCheck} />
                  </Styled.TBodyCell>
                )}

                {FEATURE.PAID_COLUMN_VISIBLE && (
                  <Styled.TBodyCell>
                    <AppIcon name={icon} />
                  </Styled.TBodyCell>
                )}

                <Styled.TBodyCell>{formatHelper.displayValue(shipment?.shipmentFrom?.country)}</Styled.TBodyCell>
                <Styled.TBodyCell>{formatHelper.displayValue(shipment?.shipmentTo?.country)}</Styled.TBodyCell>
                <Styled.TBodyCell>{formatHelper.displayValue(shipment?.shipmentFrom?.contactName)}</Styled.TBodyCell>
                <Styled.TBodyCell>{formatHelper.displayValue(shipment?.shipmentTo.contactName)}</Styled.TBodyCell>
                <Styled.TBodyCell>{shipmentDate}</Styled.TBodyCell>
                <Styled.TBodyCell>{formatHelper.displayValue(shipment?.carrier?.name)}</Styled.TBodyCell>
                <Styled.TBodyCell bold>{formatHelper.displayValue(shipment?.trackingNumber)}</Styled.TBodyCell>
                {FEATURE.UNUSED_HISTORY_COLUMNS_VISIBLE && (
                  <Styled.TBodyCell bold>
                    <StyledStatusText status={shipment?.primaryStatusFlag}>
                      {formatHelper.displayValue(shipment?.primaryStatusFlagLabel?.toUpperCase())}
                    </StyledStatusText>
                  </Styled.TBodyCell>
                )}

                {anyActions && renderActionPopup(shipment)}
              </Styled.TRowBody>
            </Fragment>
          );
        })}
      </tbody>
    );
  }

  function render() {
    const anyActions = !isEmpty(actions);
    let checkboxesVisible = FEATURE.UNUSED_HISTORY_COLUMNS_VISIBLE;
    // for pickups
    if (!anyActions) checkboxesVisible = true;

    return (
      <Styled.Table>
        <thead>
          <Styled.TRowHead topBorder>
            {checkboxesVisible && <Styled.THeadCell />}
            {FEATURE.PAID_COLUMN_VISIBLE && <Styled.THeadCell>Paid</Styled.THeadCell>}
            <Styled.THeadCell>Orig.</Styled.THeadCell>
            <Styled.THeadCell>Dest.</Styled.THeadCell>
            <Styled.THeadCell>Contact</Styled.THeadCell>
            <Styled.THeadCell>Receiver</Styled.THeadCell>
            <Styled.THeadCell>Ship date</Styled.THeadCell>
            <Styled.THeadCell>Service</Styled.THeadCell>
            <Styled.THeadCell>Track. Nr.</Styled.THeadCell>
            {FEATURE.UNUSED_HISTORY_COLUMNS_VISIBLE && <Styled.THeadCell>Status</Styled.THeadCell>}
            {anyActions && <Styled.THeadCell />}
          </Styled.TRowHead>
        </thead>

        {renderTableBody(anyActions, checkboxesVisible)}
      </Styled.Table>
    );
  }

  return render();
}

export default ShipmentsTable;

import React, {useState, useRef} from 'react';
import {debounce} from 'lodash';
import Autosuggest from 'react-autosuggest';
import {useDispatch} from 'react-redux';

import addressActions from 'actions/addressActions';

import validationHelper from 'helpers/validationHelper';
import utils from 'helpers/utils';

import InputError from 'components/common/InputComponents/InputError';
import FormLabel from 'components/common/FormLabel';

import {MaterialTextField} from 'styles/materialUI';
import * as Styled from 'styles/autosuggest';

const ADDRESS_BOOK_TAB = 'address-book';
const HISTORY_TAB = 'history';

let TABS: BasicOption[] = [
  {value: ADDRESS_BOOK_TAB, label: 'Address Book'},
  {value: HISTORY_TAB, label: 'History'}
];

//TODO enable TABS support
TABS = [];

interface Props {
  name: string;
  label: string;
  required: boolean;
  onChange: OnChangeHandler;
  onAutoFill: (suggestion: Sending) => void;
  value?: string;
  placeholder: string;
  error?: string | ValidationError[];
}

AddressAutosuggest.defaultProps = {
  placeholder: ''
};

function AddressAutosuggest({name, label, required, placeholder, onChange, onAutoFill, value, error}: Props) {
  const dispatch = useDispatch();

  const [suggestions, setSuggestions] = useState<Sending[]>([]);
  const [activeTab, setActiveTab] = useState(ADDRESS_BOOK_TAB);

  const lastRequestId = useRef(null);

  const onFetchSuggestions = useRef(debounce(value => onSuggestionsFetchRequested(value), 1000)).current;

  async function onSuggestionsFetchRequested(value) {
    if (value.length < 2) return;

    const requestId = utils.getRandomUid();
    lastRequestId.current = requestId;

    const response: any = await dispatch(addressActions.loadAddresses(value));
    const addresses = response as SavedAddress[];

    if (lastRequestId.current !== requestId) return;

    setSuggestions(addresses || []);
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onInputChange(event, {newValue}) {
    onChange(name, newValue);
  }

  function getSuggestionValue(suggestion) {
    return suggestion.companyName;
  }

  function onSuggestionSelected(event, {suggestion}) {
    onAutoFill(suggestion);
  }

  function renderInputComponent(inputProps, inputError) {
    return <MaterialTextField {...inputProps} label={placeholder} error={inputError ? true : false} variant="filled" />;
  }

  function onTabSelect(tab) {
    if (tab === activeTab) return;
    setActiveTab(tab);
  }

  function renderSuggestionsContainer({containerProps, children}) {
    return (
      <div {...containerProps}>
        <Styled.AutosuggestTabsPanel>
          {!!TABS.length &&
            TABS.map(tab => {
              const value = tab.value;

              const isActive = value === activeTab;

              return (
                <Styled.AutosuggestTab key={value} active={isActive} onClick={() => onTabSelect(value)}>
                  {tab.label}
                </Styled.AutosuggestTab>
              );
            })}
        </Styled.AutosuggestTabsPanel>
        {children}
      </div>
    );
  }

  function renderSuggestion(suggestion: Sending) {
    const address = suggestion.address;

    return (
      <div>
        <span>{suggestion.companyName}, </span>
        <span>{address.addressLine1}, </span>
        {address.city && <span>{address.city}, </span>}
        {address.division && <span>{address.division}, </span>}
        {address.postalCode && <span>{address.postalCode}</span>}
      </div>
    );
  }

  function render() {
    const inputError: string = validationHelper.getInputError(error);

    const inputProps = {value, onChange: onInputChange, autoComplete: 'nope'};

    return (
      <Styled.AutosuggestContainer withTabs={true} error={error} addressInput={true}>
        <FormLabel required={required}>{label}</FormLabel>

        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={({value}) => onFetchSuggestions(value)}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestionsContainer={renderSuggestionsContainer}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={autosuggestProps => renderInputComponent(autosuggestProps, inputError)}
        />

        {inputError && <InputError>{inputError}</InputError>}
      </Styled.AutosuggestContainer>
    );
  }

  return render();
}

export default AddressAutosuggest;

import React from 'react';
import styled from 'styled-components/macro';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';

import config from 'config';
import mailHelper from 'helpers/mailHelper';

import ActionLink from 'components/common/ActionLink';

import {colors} from 'styles/shared';

const StyledContacts = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  color: ${colors.darkBlue_main};
  margin-top: 1.6rem;
`;

const StyledContactRow = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 1.6rem;
  line-height: 3.2rem;
  letter-spacing: 0.03rem;
`;

function Contacts() {
  const dispatch = useDispatch();

  function callAction() {
    window.open(`tel:${config.supportPhone.value}`);
  }

  function sendEmail() {
    const supportLink = mailHelper.getHelpEmailLink();

    window.location.href = supportLink;
  }

  function startChat() {
    dispatch(commonActions.toggleIntercomChat());
  }

  return (
    <StyledContacts>
      <StyledContactRow>Contact support:</StyledContactRow>
      <StyledContactRow>
        Chat: &nbsp; <ActionLink title="Start chat" onClick={startChat} />
      </StyledContactRow>
      <StyledContactRow>
        Phone: &nbsp; <ActionLink title={config.supportPhone.label} onClick={callAction} />
      </StyledContactRow>
      <StyledContactRow>
        Email: &nbsp; <ActionLink title={config.supportUrl} onClick={sendEmail} />
      </StyledContactRow>
    </StyledContacts>
  );
}

export default Contacts;

import {last} from 'lodash';
import USER_ROLE from 'constants/userRoles';

export default {
  mapUser
};

function mapUser(userData) {
  let id = last(userData.id.split('/'));

  let user: User = {
    ...userData,
    id,
    isCSR: userData?.roles.includes(USER_ROLE.CSR),
    isClient: userData?.roles.includes(USER_ROLE.CLIENT),
    isAdmin: userData?.roles.includes(USER_ROLE.ADMIN),
    isSuperAdmin: userData?.roles.includes(USER_ROLE.SUPER_ADMIN),
    isGuest: userData?.roles.includes(USER_ROLE.GUEST)
  };

  return user;
}

import styled, {css} from 'styled-components';

import Container from 'components/common/Container';
import Button from 'components/common/Button';

import {mediaQueries, mainFont, navBarHeight, opacity, maxUsersContainerWidth} from 'styles/shared';

const CONTENT_LEFT_PADDING = '1.75rem';

export const container = styled(Container)`
  position: sticky;
  top: calc(${navBarHeight} - 0.1rem);
  z-index: 900;
  padding: 0 1rem 0 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: end;

  @media ${mediaQueries.mobileMax} {
    position: initial;
    padding: 0 1.7rem;
    flex-direction: column;
    align-items: center;
  }

  ${props =>
    props.isSticky &&
    css`
      height: 7rem;
      width: 100vw;
      margin-left: calc(-50vw + 50%);
      padding-left: calc(((100vw - ${maxUsersContainerWidth}) / 2) + ${CONTENT_LEFT_PADDING});
      padding-right: calc((100vw - ${maxUsersContainerWidth}) / 2);
      justify-content: space-between;
      align-items: center;
      border: none;
      border-radius: 0;
      box-shadow: 0 2.4rem 2.4rem -2.4rem ${opacity(0.25)};
    `}
`;

export const content = styled.div`
  padding: ${CONTENT_LEFT_PADDING} 0;
  max-width: 56.9rem;
  margin-right: 2.4rem;

  @media ${mediaQueries.mobileMax} {
    margin-right: 0;
  }
`;

export const description = styled.div`
  margin-top: 0.4rem;
  ${mainFont};
`;

export const button = styled(Button)`
  margin-bottom: 2rem;

  ${props =>
    props.isSticky &&
    css`
      margin-bottom: 0;
    `}
`;

export const imageContainer = styled.div`
  align-self: center;

  ${props =>
    props.isSticky &&
    css`
      img {
        height: 7rem;
        margin-right: 6.6rem;
      }
    `}
`;

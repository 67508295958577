import React from 'react';
import styled from 'styled-components';

import PAYMENT_TIME from 'constants/paymentTime';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import SelectInput from 'components/common/SelectInput/SelectInput';

const StyledContainer = styled(Container)`
  padding-bottom: 0;
`;

interface Props {
  paymentTime: string;
  onChange: OnChangeHandler;
}

function PaymentTimeSection({paymentTime, onChange}: Props) {
  const paymentTimeOptions: BasicOption[] = [
    {value: PAYMENT_TIME.PAY_NOW, label: 'Pay Now'},
    {value: PAYMENT_TIME.PAY_LATER, label: 'Pay Later'}
  ];

  return (
    <StyledContainer>
      <FormHeader>When would you like to pay?</FormHeader>

      <SelectInput
        name="paymentTime"
        label="Select whether you want to pay now or later"
        value={paymentTime}
        options={paymentTimeOptions}
        onChange={onChange}
      />
    </StyledContainer>
  );
}

export default PaymentTimeSection;

import React from 'react';
import styled, {css} from 'styled-components/macro';

import AppIcon from './AppIcon';

import {colors} from 'styles/shared';

const StyledHiddenInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const StyledCheckContainer = styled.div`
  left: 0.3rem;
  position: absolute;
  top: 0.1rem;

  ${props =>
    props.preSelected &&
    css`
      top: 1.2rem;
      left: 0.5rem;
    `}
`;

const StyledCheckBox = styled.span`
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 0.4rem;
  border: 0.1rem solid ${colors.darkBlue_light};
  position: relative;
  display: inline-block;
  outline: none;
  appearance: none;
  cursor: pointer;
`;

const StyledPreSelected = styled.div`
  width: 1.4rem;
  height: 0.2rem;
  background: ${colors.orange_main};
`;

interface Props extends BaseProps {
  name: string;
  label?: string;
  value?: boolean;
  preSelected?: boolean;
  onToggle: OnToggleHandler;
}

Checkbox.defaultProps = {
  value: false,
  label: '',
  preSelected: false
};

function Checkbox({name, onToggle, value, preSelected, className}: Props) {
  const onChange = () => {
    onToggle(name, !value);
  };

  return (
    <>
      <StyledHiddenInput type="checkbox" id={name} name={name} checked={value} onChange={onChange} />

      <StyledCheckBox className={className}>
        <StyledCheckContainer preSelected={preSelected}>
          {value && <AppIcon name="check" />}
          {preSelected && <StyledPreSelected />}
        </StyledCheckContainer>
      </StyledCheckBox>
    </>
  );
}

export default Checkbox;

import COMMON from 'constants/common';

import formatHelper from 'helpers/formatHelper';

export default {
  displayPickupWindow,
  displayCarrierServiceName
};

function displayPickupWindow(pickup: Pickup, isPastTab: boolean) {
  if (!isPastTab) return pickup.displayPickupWindow;

  const displayDate = formatHelper.displayPastDate(pickup.date);

  return `${displayDate}, ${pickup.displayPickupWindow}`;
}

function displayCarrierServiceName(carrierProduct: string, carrierServices: CarrierService[]) {
  const serviceName = COMMON.EMPTY_FIELD;

  const service = carrierServices.find(item => item.productCode === carrierProduct);

  if (!service) return serviceName;

  return service.name;
}

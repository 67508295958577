import React from 'react';

function ExpressEnvelopeTooltip() {
  return (
    <span>
      When shipping documents 0.5 lbs or less, this qualifies <br />
      for our lowest express document shipping rate!
    </span>
  );
}

export default ExpressEnvelopeTooltip;

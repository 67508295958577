import React, {useState} from 'react';
import styled from 'styled-components/macro';

import Button from 'components/common/Button';

import {buttonSideMargin} from 'styles/shared';

const StyledOverlay = styled.div`
  z-index: 2000;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
`;

const StyledImage = styled.img`
  margin-top: 5rem;
`;

const StyledButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
`;

interface Props {
  tutorial: string;
  steps: number;
  close: () => void;
}

function Tutorial({tutorial, steps, close}: Props) {
  const [step, setStep] = useState(1);

  function getImagePath() {
    const mainPath = '/images/tutorials/';

    let path = `${mainPath}${tutorial}_${step}.png`;

    return path;
  }

  function goToNextStep() {
    if (isLastStep()) close();

    setStep(step + 1);
  }

  function isLastStep() {
    return step === steps;
  }

  function renderButtons() {
    let nextButton = isLastStep() ? 'Finish' : 'Next Step';

    return (
      <StyledButtonsRow>
        <Button type="secondary" autoWidth margin={`2rem ${buttonSideMargin} 0`} onClick={close}>
          Close Tutorial
        </Button>

        <Button autoWidth margin={`2rem ${buttonSideMargin} 0`} onClick={goToNextStep}>
          {nextButton}
        </Button>
      </StyledButtonsRow>
    );
  }

  function render() {
    let src = getImagePath();

    return (
      <StyledOverlay>
        <StyledImage alt="Tutorial" src={src} />

        {renderButtons()}
      </StyledOverlay>
    );
  }

  return render();
}

export default Tutorial;

import React from 'react';

function DomesticDeclaredValueTooltip() {
  return (
    <span>
      The value entered here represents UPS maximum <br />
      liability for a package that is lost or damaged. <br />
      UPS automatically covers $100. If you choose <br />
      higher coverage, additional charges will apply.
    </span>
  );
}

export default DomesticDeclaredValueTooltip;

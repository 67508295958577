import PACKAGING_TYPE from 'constants/packagingTypes/packagingTypes';

import httpHelper from 'helpers/httpHelper';
import unitHelper from 'helpers/unitHelper';

import entities from 'domain/entities';
import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import UNIT from 'constants/units';

export default {
  getQuotes,
  checkRate,
  submitForFreightQuoteAction,
  getActiveQuoteForShipment
};

async function getQuotes(activePage: number, pageSize: number): Promise<{quotes: Quote[]; totalItems: number}> {
  const options = {
    page: activePage,
    itemsPerPage: pageSize,
    'exists[shipmentId]': false
  };

  const result: {items: Quote[]; totalItems: number} = await httpHelper.get('/quotes', options, {totalItems: true});

  return {
    quotes: result.items.map((quote: Quote) => {
      quote.rates = quote.rates.map(rate => {
        return entities.rate.createFromData(rate);
      });

      return quote;
    }),
    totalItems: result.totalItems
  };
}

async function getActiveQuoteForShipment(shipmentId: string): Promise<Quote> {
  const options = {
    shipmentId: shipmentId,
    active: true
  };

  const result: {activeQuotes: Quote[]} = await httpHelper.get('/quotes', options);

  const quote = result[0] as Quote;
  quote.rates = quote.rates.map(rate => {
    return entities.rate.createFromData(rate);
  });

  return quote;
}

async function checkRate(quote: StandaloneQuote, isDomestic: boolean): Promise<{rates: Rate[]; errorMessage: string}> {
  const sendingFrom = quote.sendingFrom;
  const sendingTo = quote.sendingTo;
  const insuredValue = isDomestic ? undefined
  : {
    amount: quote.insuranceValue,
    currency: quote.currency
  };

  const body: RateShipmentRequest = {
    shipmentId: null,
    accountNumber: '', //omit to have it autopopulated by client data
    originCountryCode: sendingFrom.country,
    originZip: sendingFrom.postalCode,
    destinationCountryCode: sendingTo.country,
    destinationCity: sendingTo.city,
    destinationZip: sendingTo.postalCode,
    targetShipDate: quote.shipDate,
    pieces: mapPieceRequest(quote.items, quote.measurementSystem, quote.isDocument, quote.usesExpressEnvelope, isDomestic),
    insuredValue: insuredValue,
    currency: quote.currency,
    isResidential: sendingTo.isResidential,
    hasEeiFiledByCarrier: false,
    hasDutiesAndTaxesPaidByCarrierAccount: false,
    markInactive: true
  };

  const response: RateShipmentResponse = await httpHelper.post('/rate_shipment_actions', body, {
    useHouseAccount: true,
    acceptableErrorCodes: [400]
  });

  let rates: Rate[] = [];

  if (response?.rates) {
    response.rates.forEach(rate => {
      rates.push(entities.rate.createFromData(rate));
    });
  }

  let errorMessage = '';
  if (response?.success === false && response?.error) {
    errorMessage = response.error;
  } else if (response['hydra:description']) {
    errorMessage = response['hydra:description'];
  }

  return {rates, errorMessage};
}

async function submitForFreightQuoteAction(quote: StandaloneQuote, contactData: string): Promise<SuccessfullResponse> {
  const isDomestic = quote.sendingFrom.country === COUNTRY_CODE.USA && quote.sendingTo.country === COUNTRY_CODE.USA;
  const sendingFrom = quote.sendingFrom;
  const sendingTo = quote.sendingTo;
  const insuredValue = isDomestic ? undefined
    : {
      amount: quote.insuranceValue,
      currency: quote.currency
    };

  const body: FreightQuoteDto = {
    quoteData: {
      originCountryCode: sendingFrom.country,
      originZip: sendingFrom.postalCode,
      destinationCountryCode: sendingTo.country,
      destinationCity: sendingTo.city,
      destinationZip: sendingTo.postalCode,
      targetShipDate: quote.shipDate,
      weight: null,
      weightUnit: null,
      pieces: mapPieceRequest(quote.items, quote.measurementSystem, quote.isDocument, quote.usesExpressEnvelope, isDomestic),
      isResidential: sendingFrom.isResidential,
      insuredValue: insuredValue
    },
    contactData
  };

  return await httpHelper.post('/submit_for_freight_quote_actions', body);
}

//helper methods

function mapPieceRequest(items: StandaloneItem[], measurementSystem, isDocument: boolean, usesExpressEnvelope: boolean, isDomestic: boolean): PieceRequest[] {
  const lengthUnit: string = unitHelper.getUnitFromMeasurementSystem(measurementSystem);
  const weightUnit: string = unitHelper.getUnitFromMeasurementSystem(measurementSystem, true);

  return items.map((packageItem: StandaloneItem) => {
    const packagingType = getPackagingType(isDocument, usesExpressEnvelope);
    const documentDeclaredValue = (isDomestic && packageItem.documentDeclaredValue) ? {
      amount: packageItem.documentDeclaredValue,
      currency: UNIT.CURRENCY_USD
    }
    : undefined;

    return {
      weight: {
        unit: weightUnit,
        value: packageItem.weight
      },
      length: {
        unit: lengthUnit,
        value: packageItem.length
      },
      width: {
        unit: lengthUnit,
        value: packageItem.width
      },
      depth: {
        unit: lengthUnit,
        value: packageItem.height
      },
      documentDeclaredValue: documentDeclaredValue,
      quantity: packageItem.quantity,
      packagingType,
      description: '',
      isDocument
    };
  });
}

function getPackagingType(isDocument: boolean, usesExpressEnvelope: boolean) {
  if (!isDocument) {
    return PACKAGING_TYPE.CUSTOMER_PROVIDED;
  } else if (usesExpressEnvelope) {
    return PACKAGING_TYPE.DHL_EXPRESS_ENVELOPE;
  }
  return PACKAGING_TYPE.DOCUMENT;
}

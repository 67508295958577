import React, {useEffect, useState} from 'react';

import {isEmpty} from 'lodash';
import Helmet from 'react-helmet';
import styled, {css, createGlobalStyle} from 'styled-components/macro';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';
import userActions from 'actions/userActions';
import shipmentOptionsActions from 'actions/shipmentOptionsActions';

import PAGE_ID from 'constants/pageIds';
import SEARCH_PARAMETER from 'constants/searchParameters';
import FEATURE from 'constants/features';

import navigationHelper from 'helpers/navigationHelper';
import uiHelper from 'helpers/uiHelper';

import stateStorageService from 'services/stateStorageService';
import authService from 'services/authService';
import supportService from 'services/supportService';
import intercomService from 'services/intercomService';
import userInfoService from 'services/userInfoService';

import AppIcon from 'components/common/AppIcon';
import LogoHeader from 'components/LogoHeader';
import Flex from 'components/common/Flex';
import Navigation from './components/navigation/Navigation';
import HelpWidget from './components/HelpWidget';
import SupportModal from './components/SupportModal';
import TemporaryLoginModal from './components/TemporaryLoginModal';

import {colors, leftPanelWidth, navBarHeight, mediaQueries, helpWidgetHeight} from 'styles/shared';

const CONTAINER_PADDING = '3rem';

const StyledChildContainer = styled.div`
  margin-left: ${props => (props.leftPanel ? leftPanelWidth : 0)};
  padding: ${props => (props.withPadding ? CONTAINER_PADDING : 0)};
  padding-top: ${props => (props.withPadding ? `calc(${navBarHeight} + ${CONTAINER_PADDING})` : navBarHeight)};
  display: flex;
  justify-content: center;

  ${props =>
    props.withoutPadding &&
    css`
      padding: ${CONTAINER_PADDING} 0 0;
    `}

  @media ${mediaQueries.mobileMax} {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: ${helpWidgetHeight};
    display: block;
  }
`;

const GlobalPublicStyle = createGlobalStyle`
  body {
    background: ${props => (props.whiteBackground ? colors.white : colors.grey_lighter)};
  }
`;

const StyledInspectionLink = styled.div`
  position: fixed;
  left: 10rem;
  bottom: 3rem;
  z-index: 1000;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
`;

const StyledLink = styled.a`
  color: ${colors.grey_main};
  margin-right: 2rem;
`;

const StyledSupportLink = styled.div`
  cursor: pointer;
`;

//global style to override body background
const GlobalStyle = createGlobalStyle`
  body {
    background: ${props => (props.whiteBackground ? colors.white : colors.grey_lighter)};
  }


  ${props =>
    props.isAdmin &&
    css`
      #atlwdg-trigger {
        display: initial;
      }
    `}
`;

interface Props extends BaseProps {
  pageProps?: PageProps;
}

function AppPage({pageProps, children}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {search, pathname} = useLocation();

  const user = useSelector((state: AppState) => state.user.current as User);
  const draft = useSelector((state: AppState) => state.draft.current.data);
  const rate = useSelector((state: AppState) => state.draft.rate.current);
  const isExistingDraft = useSelector((state: AppState) => state.draft.current.isExistingDraft);
  const currentStep = useSelector((state: AppState) => state.draft.current?.step);
  const countryOptions = useSelector((state: AppState) => state.shipmentOptions.countryOptions);
  const isQuickAccessAuth = useSelector((state: AppState) => state.common.isQuickAccessAuth);
  const intercomChatVisible = useSelector((state: AppState) => state.common.intercomChatVisible);

  const [supportModalVisible, setSupportModalVisible] = useState(false);
  const [temporaryLoginModalVisible, setTemporaryLoginModalVisible] = useState(false);

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (intercomChatVisible) startChat();
  }, [intercomChatVisible]);

  async function initData() {
    window.scroll({top: 0, left: 0});

    //get search parameters from URL (load auth token, get CSR params, reassign parameters)
    await processSearchParameters();

    const token = authService.getToken();

    if (!isAuthenticated() || token) {
      await dispatch(userActions.getCurrentUser());
    }
  }

  useEffect(() => {
    if (!user) return;

    if (authService.getTemporaryToken()) {
      setTemporaryLoginModalVisible(true);
    }

    const isQuickAccessToken = authService.getQuickAccessToken() ? true : false;

    if (isQuickAccessToken !== isQuickAccessAuth) {
      dispatch(commonActions.setIsQuickAccessAuth(isQuickAccessToken));
    }

    loadOptions();
  }, [user]);

  async function loadOptions() {
    if (isEmpty(countryOptions)) await dispatch(shipmentOptionsActions.loadOptionsData());
  }

  async function processSearchParameters() {
    const searchParams: any = new URLSearchParams(search);

    const LOCAL_SEARCH_PARAMETERS: string[] = [
      SEARCH_PARAMETER.LOCAL.NEW_SHIPMENT_OVERVIEW,
      SEARCH_PARAMETER.LOCAL.SECTION,
      SEARCH_PARAMETER.LOCAL.DOMESTIC
    ];

    let searchUrl = '';
    let csrToken = '';
    let profileId = '';

    for (let pair of searchParams.entries()) {
      const key = pair[0];
      const value = pair[1];

      if (key === SEARCH_PARAMETER.GLOBAL.HANDOFF) await autoLoginWithToken(value);
      if (key === SEARCH_PARAMETER.GLOBAL.LOGIN_AS) csrToken = value;
      if (key === SEARCH_PARAMETER.GLOBAL.SWITCH_TO) profileId = value;

      if (key === SEARCH_PARAMETER.GLOBAL.MODE) {
        if (value === SEARCH_PARAMETER.VALUE.RESET_PASSWORD) {
          dispatch(commonActions.toggleChangePasswordModal(true));
          // do not show TemporaryLoginModal if mode=reset-password
          authService.saveTemporaryToken(null);
        }
      }

      if (key === SEARCH_PARAMETER.GLOBAL.RECOVERY_CODE) authService.saveRecoveryCode(value);

      if (key === SEARCH_PARAMETER.GLOBAL.RESET_PASSWORD_REASON) {
        authService.saveResetPasswordError(value);
      }

      if (!key.includes(SEARCH_PARAMETER.GLOBAL.UTM) && !LOCAL_SEARCH_PARAMETERS.includes(key)) continue;

      const symbol = searchUrl ? '&' : '?';
      searchUrl += `${symbol}${key}=${value}`;
    }

    // load user profile like CSR user
    if (csrToken && profileId) {
      authService.saveToken(csrToken);
      await authService.signIntoProfile(profileId, null);
    }

    let url = pathname;
    if (searchUrl) url += searchUrl;

    history.push(url);
  }

  async function autoLoginWithToken(temporaryToken) {
    authService.saveToken(temporaryToken);
    authService.saveQuickAccessToken(null);
    authService.saveRefreshToken(null);
    authService.setAuthContext(null);
    //store token to show TemporaryLoginModal; clear on modal close
    authService.saveTemporaryToken(temporaryToken);
  }

  function isAuthenticated() {
    if (pageProps?.public) return true;

    return !user ? false : true;
  }

  function getTitle() {
    return pageProps?.title ? `Optimal Ship - ${pageProps?.title}` : 'Optimal Ship';
  }

  function isShipmentCreatePage() {
    return pageProps?.pageId === PAGE_ID.PREPARE_SHIPMENT_ID || pageProps?.pageId === PAGE_ID.DRAFT_ID;
  }

  function isOrdersPage() {
    return pageProps?.pageId === PAGE_ID.ORDERS_ID;
  }

  function isUsersPage() {
    return pageProps?.pageId === PAGE_ID.USERS_ID;
  }

  function toggleSupportModal() {
    setSupportModalVisible(!supportModalVisible);
  }

  function closeTemporaryLoginModal() {
    authService.saveTemporaryToken(null);
    setTemporaryLoginModalVisible(false);
  }

  async function sendSupportInfo(info) {
    supportService.logToRollbar('Support Requested', info, user);

    let data = await supportService.getSupportRequestDto(draft?.id, rate?.id, null, info);

    let isSuccess: any = await dispatch(commonActions.sendToSupport(data, true));

    if (!isSuccess) return;

    uiHelper.logMessage(info);
    toggleSupportModal();
  }

  function getSupportParameters() {
    let parameters: SupportParameters = {
      user,
      currentStep,
      isShipmentCreatePage: isShipmentCreatePage(),
      pageId: pageProps?.pageId,
      shipmentId: draft?.id,
      isExistingDraft,
      rate
    };

    return parameters;
  }

  async function startChat() {
    const supportParameters = getSupportParameters();

    const info: SupportInfo = await supportService.getSupportInfo(supportParameters);

    intercomService.setIntercomInfo(info);

    let Intercom = (window as any).Intercom;
    if (!Intercom) return;
    Intercom('showMessages');

    dispatch(commonActions.toggleIntercomChat());
  }

  function render() {
    if (!isAuthenticated()) return null;

    const title = getTitle();

    const publicMode = pageProps?.public;

    let withPadding = true;
    if (isOrdersPage() || isUsersPage()) withPadding = false;

    const requestId = stateStorageService.getRequestId();
    const inspectionLinkVisible = requestId ? true : false;

    const supportParameters = getSupportParameters();

    const isMobile: boolean = userInfoService.isMobile();
    let whiteBackground = pageProps?.whiteBackground;
    if (isMobile && pageProps?.whiteBackgroundMobile) whiteBackground = true;

    return (
      <>
        <Helmet title={title} />

        {publicMode && pageProps?.showLogo && <LogoHeader publicMode={publicMode} />}

        {!publicMode && <Navigation hideNavLinks={pageProps?.hideNavLinks} />}

        {publicMode ? (
          <>
            <Flex justify="center">{children}</Flex>
            {!pageProps?.showLogo && <GlobalPublicStyle whiteBackground={whiteBackground} />}
          </>
        ) : (
          <>
            <StyledChildContainer leftPanel={pageProps?.leftPanel} withPadding={withPadding} withoutPadding={!user}>
              {children}
            </StyledChildContainer>

            <GlobalStyle whiteBackground={whiteBackground} isAdmin={user.isCSR || user.isAdmin} />
          </>
        )}

        <HelpWidget />

        {FEATURE.INSPECTION_URL_VISIBLE && inspectionLinkVisible && (
          <StyledInspectionLink>
            <StyledLink target="_blank" rel="noopener noreferrer" href={navigationHelper.getInspectUrl(requestId)}>
              Inspect
            </StyledLink>

            <StyledSupportLink onClick={toggleSupportModal}>
              <AppIcon name="share" color={colors.grey_main} />
            </StyledSupportLink>
          </StyledInspectionLink>
        )}

        {supportModalVisible && (
          <SupportModal
            visible={supportModalVisible}
            supportParameters={supportParameters}
            close={toggleSupportModal}
            onSend={sendSupportInfo}
          />
        )}

        {temporaryLoginModalVisible && (
          <TemporaryLoginModal visible={temporaryLoginModalVisible} close={closeTemporaryLoginModal} />
        )}
      </>
    );
  }

  return render();
}

export default AppPage;

import React from 'react';

import InputError from 'components/common/InputComponents/InputError';
import ActionLink from 'components/common/ActionLink';
import Flex from 'components/common/Flex';

interface Props extends BaseProps {
  inputError?: string;
  actionLink?: ActionLink;
}

function InputFooter({inputError, actionLink}: Props) {
  const justify = inputError ? 'space-between' : 'flex-end';

  return (
    <Flex justify={justify}>
      {inputError && <InputError>{inputError}</InputError>}
      {actionLink?.title && <ActionLink title={actionLink.title} onClick={actionLink.action} />}
    </Flex>
  );
}

export default InputFooter;

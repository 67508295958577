import COMMON from 'constants/common';
import CARRIER from 'constants/carriers';

import DHLTooltip from 'components/tooltips/DHLTooltip';
import UPSTooltip from 'components/tooltips/UPSTooltip';
import FedExTooltip from 'components/tooltips/FedExTooltip';

export default {
  getPickupTimeTooltip
};

function getPickupTimeTooltip(carrier: string): string | JSX.Element {
  let pickupTimeTooltip: string | JSX.Element = COMMON.MISSING_TEXT_TODO;

  const pickupTimeMap = {
    [CARRIER.DHL]: DHLTooltip(),
    [CARRIER.UPS]: UPSTooltip(),
    [CARRIER.FED_EX]: FedExTooltip()
  };

  if (pickupTimeMap.hasOwnProperty(carrier)) {
    pickupTimeTooltip = pickupTimeMap[carrier];
  }

  return pickupTimeTooltip;
}

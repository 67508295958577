import React from 'react';
import styled from 'styled-components/macro';

import {usePagination, DOTS} from './usePagination';

import AppIcon from 'components/common/AppIcon';
import Flex from 'components/common/Flex';

import {colors, mainFont, mainBorder} from 'styles/shared';

const BUTTON_SIZE = '3rem';

const StyledContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 7rem;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: ${mainBorder};
`;

const StyledButton = styled.div`
  width: ${BUTTON_SIZE};
  height: ${BUTTON_SIZE};
  border-radius: 50%;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.active ? 'initial' : 'pointer')};
  border: ${props => (props.active ? null : `0.1rem solid ${colors.orange_main}`)};
  ${mainFont};
  color: ${props => (props.active ? colors.white : colors.orange_main)};
  background-color: ${props => (props.active ? colors.orange_main : colors.white)};
  user-select: none;
`;

const StyledArrowButton = styled.div`
  width: ${BUTTON_SIZE};
  height: ${BUTTON_SIZE};
  background: ${colors.orange_main};
  border-radius: 0.3rem;
  margin: 0 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
`;

const StyledDots = styled.div`
  ${mainFont};
  color: ${colors.orange_main};
  margin: 0 0.9rem;
  user-select: none;
`;

interface Props extends BaseProps {
  pagination: Pagination;
  onChange: (page: number) => void;
}

function Pagination({pagination, onChange, className}: Props) {
  const {activePage, totalCount, pageSize} = pagination;

  const paginationRange: any = usePagination({
    currentPage: activePage,
    totalCount: totalCount,
    siblingCount: 0,
    pageSize
  });

  function onNext() {
    let lastPage = paginationRange[paginationRange.length - 1];

    if (lastPage === activePage) return;

    onChange(activePage + 1);
  }

  function onPrevious() {
    if (activePage === 1) return;

    onChange(activePage - 1);
  }

  function render() {
    if (activePage === 0 || paginationRange.length < 2) {
      return (
        <StyledContainer>
          <Flex>
            <StyledButton active={true}>{activePage}</StyledButton>
          </Flex>
        </StyledContainer>
      );
    }

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
      <StyledContainer className={className}>
        <StyledArrowButton disabled={activePage === 1} onClick={onPrevious}>
          <AppIcon name="left-arrow" height={15} width={8} color={colors.white} />
        </StyledArrowButton>

        {paginationRange.map((pageNumber, i) => {
          if (pageNumber === DOTS) return <StyledDots key={i}>{DOTS}</StyledDots>;

          const active = pageNumber === activePage;

          return (
            <StyledButton key={i} active={active} onClick={() => onChange(pageNumber)}>
              {pageNumber}
            </StyledButton>
          );
        })}

        <StyledArrowButton disabled={activePage === lastPage} onClick={onNext}>
          <AppIcon name="right-arrow" height={15} width={8} color={colors.white} />
        </StyledArrowButton>
      </StyledContainer>
    );
  }

  return render();
}

export default Pagination;

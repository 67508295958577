import React from 'react';

import styled from 'styled-components/macro';

import LogoHeader from 'components/LogoHeader';

import {errorInfoTitle} from 'styles/shared';

const StyledError = styled.div`
  margin-top: 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTitle = styled.div`
  ${errorInfoTitle};
  width: 53.8rem;
  text-align: center;
`;

interface Props {
  errorText: string;
  showLogo?: boolean;
  extraSection?: any;
}

function ErrorHandler({errorText, showLogo, extraSection}: Props) {
  return (
    <>
      {showLogo && <LogoHeader />}

      <StyledError>
        <StyledTitle>{errorText}</StyledTitle>

        {extraSection}

        <img alt="Error Handler" src="/images/error-handler.png" />
      </StyledError>
    </>
  );
}

export default ErrorHandler;

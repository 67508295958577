import React from 'react';
import styled from 'styled-components/macro';

import {colors, mainBorder, mainContainerPadding} from 'styles/shared';

const StyledContainer = styled.div`
  background: ${colors.white};
  border: ${mainBorder};
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: ${props => (props.withoutPadding ? 0 : mainContainerPadding)};
  margin-bottom: 3rem;
`;

interface Props extends BaseProps {
  withoutPadding?: boolean;
  id: string;
}

Container.defaultProps = {
  id: '',
  withoutPadding: false
};

function Container({children, className, id, withoutPadding}: Props) {
  return (
    <StyledContainer id={id} className={className} withoutPadding={withoutPadding}>
      {children}
    </StyledContainer>
  );
}

export default Container;

import React from 'react';
import styled from 'styled-components/macro';

import mailHelper from 'helpers/mailHelper';

import Button from 'components/common/Button';

import {mainFont} from 'styles/shared';

const StyledText = styled.div`
  margin-bottom: 2rem;
  ${mainFont}
`;

function PickupForImport() {
  function onClick() {
    const supportLink = mailHelper.getHelpEmailLink();

    window.location.href = supportLink;
  }

  return (
    <>
      <StyledText>If you want to schedule a Pickup outside of the USA, please contact our Customer Support.</StyledText>

      <Button autoWidth onClick={onClick}>
        Customer Support
      </Button>
    </>
  );
}

export default PickupForImport;

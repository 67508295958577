import React from 'react';
import styled from 'styled-components/macro';

import Flex from 'components/common/Flex';
import Pagination from 'components/common/Pagination';
import SimplePagination from 'components/common/Pagination/SimplePagination';

import {colors, secondaryFont} from 'styles/shared';

const StyledContainer = styled(Flex)`
  height: 7rem;
  padding: 2rem 0 2rem 1.6rem;
`;

const StyledLabel = styled.div`
  color: ${colors.darkBlue_main};
  ${secondaryFont};
`;

const StyledPagination = styled(Pagination)`
  position: initial;
  border-top: none;

  & div {
    margin: 0 0.5rem;
  }
`;

interface Props {
  label: string;
  pagination: Pagination;
  onChange: (page: number) => void;
}

function PaginationMobile({label, pagination, onChange}: Props) {
  const numberOfPages: number = Math.ceil(pagination.totalCount / pagination.pageSize);

  if (numberOfPages <= 4) {
    return <SimplePagination label={label} pagination={pagination} onChange={onChange} />;
  }

  return (
    <StyledContainer justify="space-between" align="center">
      <StyledLabel>
        {label}: {pagination.totalCount}
      </StyledLabel>

      <StyledPagination pagination={pagination} onChange={onChange} />
    </StyledContainer>
  );
}

export default PaginationMobile;

import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components/macro';
import {useHistory} from 'react-router-dom';

import draftActions from 'actions/draftActions';

import PATH from 'constants/path';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import TextAreaInput from 'components/common/TextAreaInput';
import Button from 'components/common/Button';

const StyledPageContainer = styled.div`
  width: 80rem;
`;

function DevPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [payload, setPayload] = useState('');

  function onChange(field, value) {
    setPayload(value);
  }

  async function onSubmit() {
    let draft = JSON.parse(payload);

    if (typeof draft === 'string') {
      draft = JSON.parse(draft);
    }

    const draftData = {
      id: draft?.id,
      payload: JSON.stringify(draft)
    };

    dispatch(draftActions.editExistingDraft(draftData));

    history.push(PATH.PREPARE_SHIPMENT);
  }

  let btnDisabled = !payload ? true : false;

  return (
    <StyledPageContainer>
      <Container>
        <FormHeader>Upload Draft</FormHeader>

        <TextAreaInput name="draft" rows={20} value={payload} onChange={onChange} />

        <Button disabled={btnDisabled} onClick={onSubmit}>
          Open Draft
        </Button>
      </Container>
    </StyledPageContainer>
  );
}

export default DevPage;

import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';

import uiHelper from 'helpers/uiHelper';

import Button from 'components/common/Button';
import SurveyModal from './SurveyModal';

import {opacity, secondaryFont, largeFont} from 'styles/shared';

const StyledContainer = styled.div`
  height: 38rem;
  background: url(/images/survey/survey-image.png) bottom right no-repeat;
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};
  border-radius: 0.6rem;
  margin-bottom: 3rem;
  position: relative;
  padding-left: 2.8rem;
`;

const StyledDescription = styled.div`
  position: absolute;
  width: 32rem;
  height: 13.8rem;
  top: 3.3rem;
  left: 4.1rem;
  z-index: 2;
`;

const StyledTitle = styled.div`
  ${largeFont};
  font-weight: 700;
  margin-bottom: 0.6rem;
`;

const StyledText = styled.div`
  ${secondaryFont};
`;

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 2rem;
  z-index: 2;
`;

interface Props {
  hideSurveyLink: () => void;
}

function SurveyLink({hideSurveyLink}: Props) {
  const [surveyModalVisible, setSurveyModalVisible] = useState(false);

  useEffect(() => {
    window.document.addEventListener('surveySubmitted', surveySubmitted, false);

    return () => window.removeEventListener('surveySubmitted', surveySubmitted);
  }, []);

  function surveySubmitted(e) {
    if (e?.detail?.successful) {
      toggleSurveyModal();
      hideSurveyLink();
      uiHelper.showNotice('Your response has been recorded.');
    }
  }

  function toggleSurveyModal() {
    setSurveyModalVisible(!surveyModalVisible);
  }

  return (
    <StyledContainer>
      <StyledDescription>
        <StyledTitle>How did it go?</StyledTitle>
        <StyledText>
          We appreciate your feedback. We are constantly striving to improve our tools for our clients. Please let us
          know what you liked about your experience or what we could improve upon.
        </StyledText>
      </StyledDescription>

      <StyledButton autoWidth onClick={toggleSurveyModal}>
        Ok, Show Me The Survey!
      </StyledButton>

      {surveyModalVisible && <SurveyModal visible={surveyModalVisible} close={toggleSurveyModal} />}
    </StyledContainer>
  );
}

export default SurveyLink;

import React from 'react';
import styled from 'styled-components/macro';

import {colors, errorInfoTitle, largeFont} from 'styles/shared';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-top: 6rem;
`;

const StyledText = styled.div`
  color: ${colors.darkBlue_main};
  max-width: 52rem;
`;

const StyledTitle = styled.div`
  ${errorInfoTitle};
  margin-bottom: 2rem;
`;

const StyledMessage = styled.div`
  ${largeFont};
`;

function EmptyList() {
  return (
    <StyledContainer>
      <StyledText>
        <StyledTitle>Looks like you don’t have any contacts in your Address Book</StyledTitle>
        <StyledMessage>
          Import your existing contacts via CSV file or
          <br /> create them manually
        </StyledMessage>
      </StyledText>

      <img alt="Address Book" src="/images/address-book.png" />
    </StyledContainer>
  );
}

export default EmptyList;

import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {isEmpty} from 'lodash';
import {Row, Col} from 'components/bootstrap';

import config from 'config';

import Button from 'components/common/Button';
import FormHeader from 'components/common/FormHeader';
import Flex from 'components/common/Flex';
import DocumentItem from './components/DocumentItem';
import PrintModal from './components/PrintModal';

import {mainFont, buttonLeftRightMargin, colors} from 'styles/shared';
import AppIcon from '../../../common/AppIcon';
import DocumentErrorModal, {DOCUMENT_ERROR_MODAL_MESSAGES} from './components/DocumentErrorModal';
import {useGetDocuments} from '../../../../hooks/queries/documents/useGetDocuments';
import {useIsFetching, UseQueryResult} from '@tanstack/react-query';
import {QUERY_KEY_DICTIONARY} from '../../../../hooks/queries/queryKeyDictionary';

const StyledMessage = styled.div`
  padding: 5rem 0;
  ${mainFont};
  font-size: 2rem;
`;

const StyledButtonRow = styled(Flex)`
  margin-top: 3rem;
  margin-bottom: 5rem;
`;

export interface DocumentToPrint {
  name: string;
  src: string;
}

interface Props {
  shipmentId: string;
  documents: Document[];
}

const DOCUMENT_ACTIONS = {
  PRINT: 'print',
  DOWNLOAD: 'download'
}

function getDocUrl(file) {
    const dataUrltoBlob = file => {
      const arr = file.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: mime});
    };

    const blob = dataUrltoBlob(file);

    return window.URL.createObjectURL(blob);
}

function mapDocumentsForPrinting(documents: any[]): DocumentToPrint[]  {
  let documentsToPrint: DocumentToPrint[] = [];
  documents.forEach(document => {
    const docUrl = getDocUrl(document?.file);
    if (docUrl) {
      documentsToPrint.push({ name: document?.type, src: docUrl })
    }
  })
  return documentsToPrint
}

function downloadDocuments(documents: any[], allSelected?: boolean, shipmentId?: string) {
  if (allSelected && shipmentId) {
    const downloadLink = `${config.baseUrl}/file/download-all/${shipmentId}`;
    window.open(downloadLink, '_blank');
  } else {
    documents.forEach(currentDocument => {
      if (currentDocument?.file) {
        const downloadLink = document.createElement('a');

        downloadLink.href = currentDocument?.file;
        downloadLink.download = currentDocument?.type;
        downloadLink.click();
      }
    })
  }
}

export default function UpdatedDocumentsSummary({documents, shipmentId}: Props) {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>(documents.length ? documents.map(document => document.id) : []);
  const [action, setAction] = useState<string>('')
  const [documentsForPrintModal, setDocumentsForPrintModal] = useState<DocumentToPrint[]>([]);
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)

  const getDocumentsResult = useGetDocuments(selectedDocumentIds, action)
  const isFetchingDocuments = !!useIsFetching({ queryKey: [QUERY_KEY_DICTIONARY.GET_DOCUMENT_QUERY_KEY] })

  const successfulDocumentQueries =
    getDocumentsResult.filter(queryResult => queryResult.isSuccess)
  const errorDocumentQueries =
    getDocumentsResult.filter(queryResult => queryResult.isError)

  useEffect(() => {
    if (!!(!isFetchingDocuments && action)) {
      if (errorDocumentQueries.length > 0) {
        if (errorDocumentQueries.some((errorDocumentQuery: UseQueryResult<any, any>) => 418 === errorDocumentQuery.error?.response?.status)) {
          setErrorMessage(DOCUMENT_ERROR_MODAL_MESSAGES.UPS_DOCUMENT_NOT_READY)
        } else {
          setErrorMessage(DOCUMENT_ERROR_MODAL_MESSAGES.ONE_OR_MORE_DOCUMENT_ERRORS)
        }
        setShowErrorModal(true)
      }

      if (successfulDocumentQueries.length > 0) {
        try {
          const documentData = successfulDocumentQueries.map((documentQuery: UseQueryResult<any, any>) => documentQuery.data)

          if (DOCUMENT_ACTIONS.PRINT === action) {
            setDocumentsForPrintModal(mapDocumentsForPrinting(documentData));
            setShowPrintModal(true)
          } else if (DOCUMENT_ACTIONS.DOWNLOAD === action) {
            downloadDocuments(documentData,
              selectedDocumentIds.length === documents.length,
              shipmentId)
          }
        } catch (error) {
          if (errorDocumentQueries.length === 0) {
            setErrorMessage(DOCUMENT_ERROR_MODAL_MESSAGES.UNKNOWN_ERROR_PLEASE_TRY_AGAIN)
          }
          setShowErrorModal(true)
        }
        setSelectedDocumentIds([])
      }
      setAction('')
    }
  }, [isFetchingDocuments, action, errorDocumentQueries.length, successfulDocumentQueries.length])

  function onSelect(checkedId: string) {
    const index = selectedDocumentIds.findIndex(id => id === checkedId);

    if (index === -1) {
      setSelectedDocumentIds(prevArray => [...prevArray, checkedId]);
    } else {
      setSelectedDocumentIds(selectedDocumentIds.filter(id => id !== checkedId));
    }
  }

  return (
    <div>
      <FormHeader>1. Print your documents</FormHeader>

      {isEmpty(documents) ? (
        <StyledMessage>There are no documents.</StyledMessage>
      ) : (
        <>
          <Row>
            {documents.map(document => {
              return (
                <Col sm={4} key={document.id}>
                  <DocumentItem id={document.id}
                                title={document.type}
                                selectAction={onSelect}
                                isSelected={!!selectedDocumentIds.find(i => i === document.id)}/>
                </Col>
              );
            })}
          </Row>

          <StyledButtonRow>
            <Button
              autoWidth
              disabled={selectedDocumentIds.length === 0 || isFetchingDocuments}
              margin={buttonLeftRightMargin}
              onClick={() => {
                getDocumentsResult.forEach(query => query.refetch())
                setAction(DOCUMENT_ACTIONS.PRINT)
              }}>
              Print
            </Button>
            <Button
              autoWidth
              disabled={selectedDocumentIds.length === 0 || isFetchingDocuments}
              margin={buttonLeftRightMargin}
              onClick={() => {
                getDocumentsResult.forEach(query => query.refetch())
                setAction(DOCUMENT_ACTIONS.DOWNLOAD)
              }}>
              Download
            </Button>
            { isFetchingDocuments && (<AppIcon name="loading" size="5x" color={colors.darkBlue_lighter} spin />)}
          </StyledButtonRow>

          {documentsForPrintModal.length !== 0 && (
            <PrintModal
              visible={showPrintModal}
              close={() => setShowPrintModal(false)}
              documents={documentsForPrintModal}
            />
          )}

          {showErrorModal &&
            (<DocumentErrorModal
              visible={showErrorModal}
              message={errorMessage}
              onCancel={() => {
                setShowErrorModal(false)
              }}
            />)
          }
        </>
      )}
    </div>
  );
}
import React from 'react';

function EccnTooltip() {
  return (
    <span>
      <strong>ECCN: Export Control Classification Number</strong> is a <br />
      five character alpha-numeric designation used on the <br /> <strong>Commerce Control List (CCL)</strong> to
      identify dual-use <br />
      items for export control purposes. It categorizes items <br /> based on the nature of the product, i.e. type of
      <br />
      commodity, software, or technology. An <strong>ECCN</strong> is <br />
      different from <strong>HS, HTSUS</strong> or <strong>Schedule B numbers</strong>. <br /> The <strong>ECCN</strong>
      number is used to determine whether or not <br />
      your goods require an <strong>Export License</strong>. Keep in mind <br /> that most consumer goods and low-level
      technology are
      <br /> designated <strong>EAR99</strong>.
    </span>
  );
}

export default EccnTooltip;

import React from 'react';
import {Modal} from 'components/bootstrap';
import styled from 'styled-components/macro';

import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import {mainFont, buttonLeftRightMargin} from 'styles/shared';
import * as Styled from 'styles/modal';
import ActionLink from '../../../../common/ActionLink';
import config from '../../../../../config';
import mailHelper from '../../../../../helpers/mailHelper';
import commonActions from '../../../../../actions/commonActions';
import {useDispatch} from 'react-redux';

const StyledContent = styled.div`
  text-align: center;
`;

const StyledModalTitle = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
  ${mainFont};
`;

const StyledModalFooter = styled(Flex)`
  margin-top: 1rem;
`;

const StyledLinkRow = styled.div`
  ${mainFont};
  display: flex;
  margin-top: 1rem;
`;

interface Props {
  visible: boolean;
  message?: string;
  onCancel: () => void;
}


export const DOCUMENT_ERROR_MODAL_MESSAGES = {
  UPS_DOCUMENT_NOT_READY: 'UPS has not yet generated your document. Please try again shortly.',
  ONE_OR_MORE_DOCUMENT_ERRORS: 'There was a problem getting one or more of your documents. Please try again or contact our customer service:',
  UNKNOWN_ERROR_PLEASE_TRY_AGAIN: 'Something went wrong. Please try again or contact our customer service:'
}

export default function DocumentErrorModal({visible, message, onCancel}: Props) {
  const dispatch = useDispatch();

  function callAction() {
    window.open(`tel:${config.supportPhone.value}`);
  }

  function sendEmail() {
    window.location.href = mailHelper.getHelpEmailLink();
    onCancel();
  }

  function startChat() {
    dispatch(commonActions.toggleIntercomChat());
    onCancel();
  }

  return (
    <Styled.CustomModal show={visible} backdrop="static" width="45.8rem" padding="4rem 4rem 2rem" onHide={onCancel}>
      <Modal.Body>
        <StyledContent>
          <StyledModalTitle>Document Problem</StyledModalTitle>
          <img alt="Pickup Error" src={'/images/no-drafts.png'} width="100" height="100" />

          <h3>{message}</h3>

        { DOCUMENT_ERROR_MODAL_MESSAGES.UPS_DOCUMENT_NOT_READY !== message &&
          (
            <>
              <StyledLinkRow>
                Phone: &nbsp; <ActionLink title={config.supportPhone.label} onClick={callAction} />
              </StyledLinkRow>
              <StyledLinkRow>
                Email: &nbsp; <ActionLink title={config.supportUrl} onClick={sendEmail} />
              </StyledLinkRow>
              <StyledLinkRow>
                Chat: &nbsp; <ActionLink title="Start chat" onClick={startChat} />
              </StyledLinkRow>
            </>
          )}
        </StyledContent>
        <StyledModalFooter justify="center">
          <Button type="secondary" autoWidth margin={buttonLeftRightMargin} onClick={onCancel}>
            Close
          </Button>
        </StyledModalFooter>
      </Modal.Body>
    </Styled.CustomModal>
  );
}

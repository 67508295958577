import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {AppState} from 'reducers';
import userActions from 'actions/userActions';

import PATH from 'constants/path';

import validationHelper from 'helpers/validationHelper';
import navigationHelper from 'helpers/navigationHelper';

import authService from 'services/authService';
import userInfoService from 'services/userInfoService';

import TextInput from 'components/common/TextInput';
import Button from 'components/common/Button';
import Flex from 'components/common/Flex';
import CheckBoxInput from 'components/common/CheckboxInput';
import Divider from 'components/common/Divider';
import FullAccessModule from 'components/auth/components/full_access_module/FullAccessModule';

import BrowserHelp from './components/BrowserHelp';
import CommunicationPopup from './components/CommunicationPopup';
import SetPasswordModal from './components/SetPasswordModal';
import NoPasswordSetModal from './components/NoPasswordSetModal';
import AuthTroublePopup from './components/AuthTroublePopup';

import {buttonLeftRightMargin} from 'styles/shared';

import * as styled from './SignInPage.styled';

type pageState = 'default' | 'truncated';

function SignInPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: AppState) => state.user.current);

  const [quickQuoteAccessField, setQuickQuoteAccessField] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [errors, setErrors] = useState({email: '', password: '', quickQuoteAccessField: ''});

  const [pageState, setPageState] = useState<pageState>('default');
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  // eslint-disable-next-line
  const [actionHandler, setActionHandler] = useState<{call: () => void}>();

  // extra auth components
  const [setPasswordModalVisible, setSetPasswordModalVisible] = useState(false);
  const [noPasswordSetModalVisible, setNoPasswordSetModalVisible] = useState(false);

  // render appropriate mode depends on redirect URL
  useEffect(() => {
    const redirectUrl = authService.getRedirectUrl();
    if (!redirectUrl) return;

    if (redirectUrl === PATH.SCHEDULE_PICKUP || redirectUrl === PATH.PREPARE_SHIPMENT) {
      setPageState('truncated');
    }
  }, []);

  useEffect(() => {
    if (!user) return;
    redirectOnSuccess();
  }, [user]);

  function redirectOnSuccess() {
    if (!user) return;

    const landingPath = navigationHelper.getLandingUrl(user.isCSR, user.isGuest, quickQuoteAccessField);

    history.push(landingPath);
  }

  function closeHandler(redirect = false) {
    setErrorCode(undefined);

    if (redirect) redirectOnSuccess();
  }

  function closePasswordModalHandler() {
    setSetPasswordModalVisible(false);
    redirectOnSuccess();
  }

  function signInFromIsValid(userData) {
    let newErrors = {
      email: '',
      password: '',
      quickQuoteAccessField: ''
    };

    if (!userData.email) {
      newErrors.email = 'Email or import/export number field is required.';
    } else if (userData.email.length < 6) {
      newErrors.email = 'This value is too short. It should have 6 characters or more.';
    }

    if (!userData.password) {
      newErrors.password = 'Password field is required.';
    }

    setErrors(newErrors);

    return validationHelper.isEmptyErrorObject(newErrors);
  }

  function quickQuoteAccessFieldIsValid() {
    let newErrors = {
      email: '',
      password: '',
      quickQuoteAccessField: ''
    };

    if (!quickQuoteAccessField) {
      newErrors.quickQuoteAccessField = 'Email or import/export number field is required.';
    } else if (quickQuoteAccessField.length < 6) {
      newErrors.quickQuoteAccessField = 'This value is too short. It should have 6 characters or more.';
    }

    setErrors(newErrors);

    return validationHelper.isEmptyErrorObject(newErrors);
  }

  async function quickQuoteSignIn(e) {
    if (e) e.preventDefault();

    if (!quickQuoteAccessFieldIsValid()) return;

    await signOut();

    const response: any = await dispatch(userActions.quickAccessSignIn(quickQuoteAccessField));

    const isSuccessResponse = (object: any): object is QuickAccessResponse => {
      return object && 'token' in object;
    };

    if (isSuccessResponse(response)) {
      await dispatch(
        userActions.updateTokensAndGetUser({
          token: response.token,
          quickAccessToken: response.token,
          refreshToken: response.refresh_token?.refreshToken,
          updateRefreshToken: rememberMe
        })
      );
    } else {
      const errorResponse = response as AcceptableErrorCodeResponse;
      setErrorCode(errorResponse?.errorCode);
      setUIError(errorResponse?.errorCode, true);
    }
  }

  function setUIError(code: ErrorCode, isQuickAccess = false) {
    let error = '';

    switch (code) {
      case 'FlaggedForFraud':
      case 'BadDebtor':
      case 'NonOSImportExportNumber':
      case 'InvalidNumber':
      case 'FailedSignInAttempts':
        error = 'There seems to be an issue with your account number.';
        break;
      case 'InvalidFormat':
        error = 'Invalid account number format';
        break;
      case 'EmailNotFound':
        error = 'There seems to be an issue with your email.';
        break;
      case 'PasswordIncorrect':
        error = 'The password entered does not match the email address on file.';
        break;

      default:
        break;
    }

    if (isQuickAccess) {
      setErrors({email: '', password: '', quickQuoteAccessField: error});
    } else {
      setErrors({email: error, password: '', quickQuoteAccessField: ''});
    }
  }

  async function fullAccessSignIn(userData, rememberMeFlag) {
    if (!signInFromIsValid(userData)) return;

    await signOut();

    const response: any = await dispatch(userActions.fullAccessSignIn(userData));

    const isSuccessResponse = (object: any): object is PasswordAuthResponse => {
      return object && 'token' in object;
    };

    if (isSuccessResponse(response)) {
      await dispatch(
        userActions.updateTokensAndGetUser({
          token: response.token,
          refreshToken: response.refresh_token,
          updateRefreshToken: rememberMeFlag
        })
      );
    } else {
      const errorResponse = response as AcceptableErrorCodeResponse;

      if (errorResponse?.errorCode === 'NoPasswordSet') {
        let error = '';
        if (validationHelper.isValidEmail(userData.email)) {
          error = 'This Email does not match password you have entered.';
        } else {
          error = 'This Import/Export No. does not match password you have entered.';
        }
        setErrors({email: error, password: '', quickQuoteAccessField: ''});
        setNoPasswordSetModalVisible(true);
        return;
      }

      if (errorResponse?.errorCode === 'PasswordIncorrect') {
        setUIError(errorResponse.errorCode);
        return;
      }

      setErrorCode(errorResponse?.errorCode);
      setUIError(errorResponse?.errorCode, true);
    }
  }

  //sign out before signing in
  async function signOut() {
    let token = authService.getToken();
    if (token) await dispatch(userActions.signOut());
  }

  function signUp() {
    return history.push(PATH.SIGN_UP);
  }

  async function proceedAsGuest(e) {
    if (e) e.preventDefault();

    await signOut();

    await dispatch(userActions.signInGuest());
  }

  function renderQuickQuote() {
    return (
      <>
        <TextInput
          name="quickQuoteAccessField"
          label="Already a client? Get a quick quote."
          value={quickQuoteAccessField}
          placeholder="Enter your Import/Export No. or Client ID"
          onChange={(field, value) => setQuickQuoteAccessField(value)}
          error={errors.quickQuoteAccessField}
        />

        <Flex>
          <CheckBoxInput
            name="rememberMe"
            label="Remember me"
            value={rememberMe}
            onToggle={(field, value) => setRememberMe(value)}
          />

          <Button autoWidth disabled={!quickQuoteAccessField} margin={'0 0 0 3.2rem'} onClick={quickQuoteSignIn}>
            Get Quote
          </Button>
        </Flex>
      </>
    );
  }

  function render() {
    const isChromeBrowser = userInfoService.isChromeBrowser();

    let communicationPopupVisible = false;
    let authTroublePopupVisible = false;

    if (errorCode) {
      switch (errorCode) {
        case 'FlaggedForFraud':
        case 'BadDebtor':
          if (quickQuoteAccessField) {
            authTroublePopupVisible = true;
          } else {
            communicationPopupVisible = true;
          }
          break;
        case 'OldExportNumber':
        case 'OldImportNumber':
        case 'FailedSignInAttempts':
        case 'EmailNotFound':
          authTroublePopupVisible = true;
          break;
        case 'NoPasswordSet':
        case 'NonOSImportExportNumber':
        case 'InvalidNumber':
        case 'InvalidFormat':
          communicationPopupVisible = true;
          break;
        default:
          authTroublePopupVisible = true;
          break;
      }
    }

    return (
      <>
        <styled.wrapper>
          <styled.container>
            <styled.title>Let’s Get Started!</styled.title>

            <FullAccessModule errors={errors} signInAction={fullAccessSignIn} />

            {pageState === 'default' && (
              <>
                <styled.divider />
                {renderQuickQuote()}
              </>
            )}

            <Divider message="Not an OptimalShip Client?" />

            <styled.buttonsContainer justify="center">
              {pageState === 'default' && (
                <Button autoWidth margin={buttonLeftRightMargin} onClick={proceedAsGuest}>
                  Get a Guest Quote
                </Button>
              )}

              <Button autoWidth margin={buttonLeftRightMargin} onClick={signUp}>
                Sign Up
              </Button>
            </styled.buttonsContainer>
          </styled.container>
        </styled.wrapper>

        {!isChromeBrowser && <BrowserHelp />}

        {communicationPopupVisible && errorCode && (
          <CommunicationPopup
            visible={communicationPopupVisible}
            errorCode={errorCode}
            close={closeHandler}
            action={actionHandler}
          />
        )}

        {authTroublePopupVisible && <AuthTroublePopup visible={authTroublePopupVisible} close={closeHandler} />}

        {setPasswordModalVisible && (
          <SetPasswordModal visible={setPasswordModalVisible} close={closePasswordModalHandler} />
        )}

        {noPasswordSetModalVisible && (
          <NoPasswordSetModal visible={noPasswordSetModalVisible} close={() => setNoPasswordSetModalVisible(false)} />
        )}
      </>
    );
  }

  return render();
}

export default SignInPage;

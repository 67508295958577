import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {isEmpty} from 'lodash';
import styled from 'styled-components/macro';
import {useLocation} from 'react-router-dom';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';
import shipmentActions from 'actions/shipmentActions';
import pickupActions from 'actions/pickupActions';
import draftActions from 'actions/draftActions';
import quoteActions from 'actions/quoteActions';

import SEARCH_PARAMETER from 'constants/searchParameters';
import FEATURE from 'constants/features';

import authService from 'services/authService';
import userInfoService from 'services/userInfoService';

import ScheduledPickups from './components/pickups/ScheduledPickups';
import ShipmentStatuses from './components/statuses/ShipmentStatuses';
import ShipmentStatusesNeedAttention from './components/shipment_issues/ShipmentStatusesNeedAttention';
import Quotes from './components/quote/Quotes';
import Drafts from './components/drafts/Drafts';
import CSRToggler from './components/CSRToggler';
import ViewHistoryMobile from './mobile/ViewHistoryMobile';

import {maxContainerWidth, mediaQueries} from 'styles/shared';

const StyledPageContainer = styled.div`
  width: ${maxContainerWidth};

  @media ${mediaQueries.mobileMax} {
    width: auto;
    overflow-x: visible;
  }
`;

function ViewHistoryPage() {
  const dispatch = useDispatch();
  const {search} = useLocation();

  const pickups = useSelector((state: AppState) => state.pickup.list);
  const pickupsActivePage = useSelector((state: AppState) => state.pickup.pagination.activePage);
  const pickupsPageSize = useSelector((state: AppState) => state.pickup.pagination.pageSize);

  const quotes = useSelector((state: AppState) => state.quote.list);
  const quotesActivePage = useSelector((state: AppState) => state.quote.pagination.activePage);
  const quotesPageSize = useSelector((state: AppState) => state.quote.pagination.pageSize);

  const shipments = useSelector((state: AppState) => state.shipment.list);
  const shipmentsActivePage = useSelector((state: AppState) => state.shipment.pagination.activePage);
  const shipmentsPageSize = useSelector((state: AppState) => state.shipment.pagination.pageSize);

  const drafts = useSelector((state: AppState) => state.draft.list);
  const draftsActivePage = useSelector((state: AppState) => state.draft.pagination.activePage);
  const draftsPageSize = useSelector((state: AppState) => state.draft.pagination.pageSize);

  const [shipmentStatuses, setShipmentStatuses] = useState([] as Shipment[]);
  const [statusesNeedAttention, setStatusesNeedAttention] = useState([] as Shipment[]);
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    async function initData() {
      //set default page number for all lists (shipments, drafts, etc.)
      dispatch(shipmentActions.updateShipmentsPageNumber(1));
      dispatch(draftActions.updateDraftsPageNumber(1));
      dispatch(quoteActions.updateQuotesPageNumber(1));

      if (authService.getQuickAccessToken()) return;

      let loadDataHandlers: any = [reloadPickups(), reloadShipments(), reloadDrafts(), reloadQuotes()];

      dispatch(commonActions.toggleGlobalOverlay());

      await Promise.all(loadDataHandlers).finally(() => {
        dispatch(commonActions.toggleGlobalOverlay());
      });
    }

    initData();

    setReadyToRender(true);
  }, []);

  //scroll to specific section
  useEffect(() => {
    if (!readyToRender) return;

    const query = new URLSearchParams(search);

    const section = query.get(SEARCH_PARAMETER.LOCAL.SECTION);

    if (!section) return;

    let element = document.getElementById(section);

    if (element) element.scrollIntoView();
  }, [readyToRender]);

  useEffect(() => {
    if (!FEATURE.SHIPMENTS_NEED_ATTENTION_VISIBLE) {
      setShipmentStatuses(shipments);
      return;
    }

    let initialShipmentStatuses = shipments.filter((shipment: Shipment) => !shipment.hasError);
    let initialShipmentNeedAttention = shipments.filter((shipment: Shipment) => shipment.hasError);

    setShipmentStatuses(initialShipmentStatuses);
    setStatusesNeedAttention(initialShipmentNeedAttention);
  }, [shipments]);

  useEffect(() => {
    if (!readyToRender) return;

    reloadShipments();
  }, [shipmentsActivePage]);

  useEffect(() => {
    if (!readyToRender) return;

    reloadDrafts();
  }, [draftsActivePage]);

  useEffect(() => {
    if (!readyToRender) return;

    reloadQuotes();
  }, [quotesActivePage]);

  useEffect(() => {
    if (!readyToRender) return;

    reloadPickups();
  }, [pickupsActivePage]);

  async function reloadPickups() {
    await dispatch(pickupActions.loadPickups(pickupsActivePage, pickupsPageSize));
  }

  async function reloadShipments() {
    await dispatch(shipmentActions.loadShipments(shipmentsActivePage, shipmentsPageSize));
  }

  async function reloadDrafts() {
    await dispatch(draftActions.loadDrafts(draftsActivePage, draftsPageSize));
  }

  async function reloadQuotes() {
    await dispatch(quoteActions.loadQuotes(quotesActivePage, quotesPageSize));
  }

  function render() {
    const authContext: AuthContext = authService.getAuthContext();
    const isMobile: boolean = userInfoService.isMobile();

    if (isMobile) {
      return (
        <ViewHistoryMobile
          statusesNeedAttention={statusesNeedAttention}
          pickups={pickups}
          shipmentStatuses={shipmentStatuses}
          reloadShipments={reloadShipments}
          reloadPickups={reloadPickups}
          quotes={quotes}
          drafts={drafts}
          onDraftListChange={reloadDrafts}
        />
      );
    }

    return (
      <StyledPageContainer>
        {authContext?.isCsrPersonalAccount && <CSRToggler />}

        {!isEmpty(statusesNeedAttention) && <ShipmentStatusesNeedAttention shipmentStatuses={statusesNeedAttention} />}

        <ScheduledPickups pickups={pickups} />

        <ShipmentStatuses
          shipmentStatuses={shipmentStatuses}
          pickups={pickups}
          reloadShipments={reloadShipments}
          reloadPickups={reloadPickups}
        />

        <Quotes quotes={quotes} />

        <Drafts drafts={drafts} onDraftListChange={reloadDrafts} />
      </StyledPageContainer>
    );
  }

  return render();
}

export default ViewHistoryPage;

import React from 'react';

import config from 'config';

function ScheduleBTooltip() {
  let commodityCodeUrl = config.commodityCodeUrl;

  return (
    <span>
      <strong>Schedule B / Harmonized Code / HTS Code / HS Code</strong> - <br />
      Is the unique product identification number that is used by <br /> customs to determine what rate the duties and
      taxes <br />
      should be charged at. This website can assist in determining <br />
      the correct code to use for your product. <br />
      <a href={commodityCodeUrl} target="_blank" rel="noopener noreferrer">
        {commodityCodeUrl}
      </a>
    </span>
  );
}

export default ScheduleBTooltip;

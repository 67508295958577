import helper from './reducerHelper';

import {LOAD_PICKUPS, CHANGE_PICKUPS_PAGE} from 'action_types/pickupsActonTypes';
import initialState from './initialState';

export interface PickupState {
  list: Pickup[];
  pagination: Pagination;
}

const pickupReducer = (state: PickupState = initialState.pickup, action) => {
  return helper.handleActions<PickupState>(state, action, {
    [LOAD_PICKUPS](state, payload: {pickups: Pickup[]; totalItems: number}) {
      state.list = payload.pickups;
      state.pagination.totalCount = payload.totalItems;
    },
    [CHANGE_PICKUPS_PAGE](state, payload: {activePage: number}) {
      state.pagination.activePage = payload.activePage;
    }
  });
};

export default pickupReducer;

import {Modal} from 'components/bootstrap';
import styled, {css} from 'styled-components/macro';

import {boxShadow, mediaQueries} from 'styles/shared';

const DEFAULT_MODAL_WIDTH = '54.6rem';
const DEFAULT_MODAL_PADDING = '2.6rem 2.6rem 2rem';

export const CustomModal = styled(Modal)`
  text-align: center;
  padding: 0 !important;

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: ${props => (props.width ? props.width : DEFAULT_MODAL_WIDTH)};
    max-width: none !important;

    ${props =>
      props.height &&
      css`
        height: ${props.height} !important;
      `}

    @media ${mediaQueries.mobileMax} {
      width: auto;
    }

    .modal-content {
      min-height: 19rem;
      padding: ${props => (props.padding ? props.padding : DEFAULT_MODAL_PADDING)};
      box-shadow: ${boxShadow};
      border-radius: 0.6rem;

      ${props =>
        props.height &&
        css`
          height: ${props.height};
        `}

      .modal-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ${props =>
          props.height &&
          css`
            overflow-y: scroll;
          `}
      }
    }
  }

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.3rem;
  }
`;

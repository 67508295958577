const PATH = {
  HOME: '/',
  GET_QUOTE: '/get-quote',
  SCHEDULE_PICKUP: '/schedule-pickup',
  PREPARE_SHIPMENT: '/prepare-shipment',
  VIEW_HISTORY: '/view-history',
  ORDERS: '/orders',
  USERS: '/users',
  USER_SETTINGS: '/user-settings',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  GUEST_LANDING: '/guest-landing',
  RESET_PASSWORD: '/reset-password'
};

export default PATH;

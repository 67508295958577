import CARRIER from './carriers';

const ACCOUNT_NUMBER_FORMAT = {
  [CARRIER.DHL]: {
    LENGTH: 9,
    CONTAINS_ONLY_NUMBERS: true
  },
  [CARRIER.UPS]: {
    LENGTH: 6,
    CONTAINS_ONLY_NUMBERS: false
  },
  [CARRIER.FED_EX]: {
    LENGTH: 9,
    CONTAINS_ONLY_NUMBERS: true
  }
};

export default ACCOUNT_NUMBER_FORMAT;

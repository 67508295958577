import {css} from 'styled-components/macro';

import {minInputContainerHeight, colors, mainFont, opacity, defaultInputHeight} from 'styles/shared';

const INPUT_BORDER_RADIUS = '0.6rem';

//common
const inputContainer = css`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  min-height: ${minInputContainerHeight};
`;

const inputContainerWithoutMargin = css`
  ${inputContainer};
  margin: 0;
`;

const inputFocus = css`
  outline: none;
  border-color: ${opacity(0.5, colors.orange_main)};
`;

const inputPlaceholder = css`
  ::placeholder {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: ${colors.darkBlue_light};
  }
`;

const inputField = css`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: ${INPUT_BORDER_RADIUS};
  padding: 1.2rem 0.8rem;
  margin-bottom: 0.8rem;
  color: ${colors.darkBlue_main};
  border: 0.1rem solid ${colors.darkBlue_light};
  ${mainFont};
  ${inputPlaceholder};

  &:focus {
    ${inputFocus};
  }
`;

const inputFieldWithUnits = css`
  height: ${defaultInputHeight};
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: ${INPUT_BORDER_RADIUS};
  border-bottom-left-radius: ${INPUT_BORDER_RADIUS};
  border-right: none;
  padding: 1.2rem 0.8rem;
  display: flex;
  align-items: center;
  color: ${colors.darkBlue_main};
  margin-bottom: 0.8rem;
  ${mainFont};
  ${inputPlaceholder};

  &:focus {
    ${inputFocus};
  }

  &:disabled {
    background-color: initial;
    color: ${colors.grey_main};
  }
`;

const inputDropdown = css`
  height: ${defaultInputHeight};
  width: 15.8rem;
  box-sizing: border-box;
  border-top-right-radius: ${INPUT_BORDER_RADIUS};
  border-bottom-right-radius: ${INPUT_BORDER_RADIUS};
  padding: 1.2rem 0.8rem;
  display: flex;
  align-items: center;
  color: ${colors.orange_main};
  margin-bottom: 0.8rem;
  border-left: none;
  appearance: none;
  direction: rtl;
  ${mainFont};
  font-weight: bold;
  color: ${colors.darkBlue_main};
  background-color: ${colors.white};

  &:focus {
    ${inputFocus};
  }
`;

//tracking number input
const trackingNumberInputField = css`
  ${inputField};
  height: 4.6rem;
  margin-bottom: 0;
`;

//select input
const selectInputField = css`
  height: ${defaultInputHeight};
  width: 100%;
  margin-right: 0.8rem;
  appearance: none;
  background: url(images/arrow-down.svg) no-repeat right transparent;
  background-position: 98% 50%;
  ${inputField};
`;

//search input
const searchInputField = css`
  height: ${defaultInputHeight};
  background: url(/images/search-icon.svg) no-repeat scroll;
  background-position: 98% 50%;
  ${inputField};
`;

//number input
const numberInputField = css`
  height: ${defaultInputHeight};
  width: 100%;
  margin-right: 0.8rem;
  ${inputField};
`;

//phone number input
const phoneNumberInputField = css`
  width: 100%;
  height: ${defaultInputHeight};
  box-sizing: border-box;
  border-radius: ${INPUT_BORDER_RADIUS};
  display: flex;
  align-items: center;
  color: ${colors.darkBlue_main};
  ${mainFont};
  ${inputPlaceholder};

  &:focus {
    box-shadow: none;
    ${inputFocus};
  }
`;

export default {
  common: {
    inputContainer,
    inputField,
    inputFieldWithUnits,
    inputDropdown,
    inputFocus
  },
  numberInput: {
    inputField: numberInputField
  },
  selectInput: {
    inputField: selectInputField
  },
  searchInput: {
    inputField: searchInputField
  },
  trackingNumber: {
    inputField: trackingNumberInputField
  },
  currencyInput: {
    inputContainer: inputContainerWithoutMargin
  },
  inputWithUnits: {
    inputContainer: inputContainerWithoutMargin
  },
  phoneNumberInput: {
    inputField: phoneNumberInputField
  }
};

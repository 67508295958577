import apis from "domain/apis";
import helper from './actionHelper';

import { APP_ERROR } from "action_types/commonActionTypes";

export default {
  completePrepAndPickup
};

function completePrepAndPickup(
  prepareShipmentDto: PrepareShipmentDto,
  confirmPickupDto: ConfirmPickupDto | null,
  addShipmentToPickupDto: AddShipmentToPickupDto | null) {
    return helper.dispatchAsyncAction(async dispatch => {
      const response = await apis.prepAndPickup.completePrepAndPickup(
        prepareShipmentDto,
        confirmPickupDto,
        addShipmentToPickupDto
      );

      if (response?.successful) return response.shipmentId;

      let errorMessage = getErrorFromResponse(response, 'Error on Shipment creation');

      dispatch(helper.getAction(APP_ERROR, {error: errorMessage}));

      return null;
    });
}

function getErrorFromResponse(response: CompletePrepAndPickupResponse, errorMessage) {
  let errors = response?.errors;

  if (errors && errors?.length > 0) {
    errorMessage = '';

    for (let i = 0; i <= errors.length - 1; i++) {
      errorMessage += errors[i];

      if (i !== errors.length - 1) {
        errorMessage += '</br>';
      }
    }
  }

  return errorMessage;
}

import {combineReducers} from 'redux';

import common, {CommonState} from './commonReducer';
import pickup, {PickupState} from './pickupReducer';
import shipment, {ShipmentState} from './shipmentReducer';
import shipmentOptions, {ShipmenOptionState} from './shipmentOptionsReducer';
import user, {UserState} from './userReducers';
import order, {OrderState} from './orderReducer';
import draft, {DraftState} from './draftReducer';
import quote, {QuoteState} from './quoteReducer';
import address, {AddressState} from './addressReducer';
import lineItem, {LineItemState} from './lineItemReducer';
import payment, {PaymentState} from './paymentReducer';

const rootReducer = combineReducers({
  common,
  pickup,
  shipment,
  shipmentOptions,
  user,
  order,
  draft,
  quote,
  address,
  lineItem,
  payment
});

export interface AppState {
  common: CommonState;
  pickup: PickupState;
  shipment: ShipmentState;
  shipmentOptions: ShipmenOptionState;
  user: UserState;
  order: OrderState;
  draft: DraftState;
  quote: QuoteState;
  address: AddressState;
  lineItem: LineItemState;
  payment: PaymentState;
}

export default rootReducer;

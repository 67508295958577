import React, {useState, useEffect, useRef} from 'react';
import {findDOMNode} from 'react-dom';
import styled, {css} from 'styled-components/macro';

import AppIcon from 'components/common/AppIcon';

import {colors, mainFont, boxShadow} from 'styles/shared';

const StyledWindow = styled.span`
  background-color: ${colors.white};
  left: -20.5rem;
  top: 2rem;
  position: absolute;
  width: 24.1rem;
  z-index: 1;
  box-shadow: ${boxShadow};
  border-radius: 0.6rem;

  ${props =>
    props.isDropUp &&
    css`
      top: -18rem;
    `}
`;

const StyledListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const StyledListItem = styled.li`
  align-items: baseline;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  list-style-type: none;
  margin-left: -4rem;

  &:hover {
    background-color: ${colors.grey_light};
  }
  &:first-child {
    margin-top: 1rem;
  }
  &:last-child {
    margin-bottom: 1rem;
  }
`;

const StyledText = styled.div`
  ${mainFont}
  color: ${colors.darkBlue_main};
  padding: 1.3rem 0 1.3rem 1.6rem;
`;

const StyledIconBox = styled.div`
  height: 44px;
  margin-right: ${props => (props.withoutMargin ? 0 : '1rem')};
  min-width: 44px;
  min-height: 44px;
  text-align: right;
  width: 44px;
`;

interface Props extends BaseProps {
  actions: DropdownAction[];
  activeItem: User | Shipment | Draft | Pickup | Quote;
}

function ActionsPopupDropdown({actions, activeItem, className}: Props) {
  let popupRef = useRef<any>(null);

  const [isDropUp, setIsDropUp] = useState(false);

  useEffect(() => {
    determineDropUp();
  }, []);

  function determineDropUp() {
    const node = findDOMNode(popupRef.current) as Element;

    if (!node) return;

    const windowHeight = window.innerHeight;
    const instOffsetWithMenu = node.getBoundingClientRect().bottom;

    setIsDropUp(instOffsetWithMenu >= windowHeight);
  }

  return (
    <StyledWindow ref={node => (popupRef.current = node)} isDropUp={isDropUp} className={className}>
      <StyledListContainer>
        {actions.map(item => {
          const label = item.label;

          return (
            <StyledListItem key={label} onClick={() => item.action(activeItem)}>
              <StyledText>{label}</StyledText>
              {item.icon && (
                <StyledIconBox withoutMargin={item.withoutMargin}>
                  <AppIcon name={item.icon} />
                </StyledIconBox>
              )}
            </StyledListItem>
          );
        })}
      </StyledListContainer>
    </StyledWindow>
  );
}

export default ActionsPopupDropdown;

import * as actions from 'action_types/paymentActionTypes';

import helper from './actionHelper';
import apis from 'domain/apis';

export default {
  loadPaymentMethods,
  savePaymentMethod,
  removePaymentMethod,
  modifyCreditCard,
  modifyAchAccount,
  modifyBillingAddress,
  selectBillingAddress,
  changePaymentMethod,
  changePaymentTime,
  usePaymentMethod,
  setPaymentErrors,
  clearPaymentState,
  modifyBillingContactInformation,
  clearCreditCardAndAchAccount
};

function loadPaymentMethods() {
  return helper.dispatchAsyncAction(async dispatch => {
    const paymentMethods = await apis.payment.getSavedPaymentMethods();

    dispatch(helper.getAction(actions.LOAD_PAYMENT_METHODS, {paymentMethods}));
  });
}

function savePaymentMethod(data: SavePaymentMethodDto) {
  return helper.dispatchAsyncAction(async dispatch => {
    const paymentMethod = await apis.payment.savePaymentMethod(data);

    if (paymentMethod.id) {
      dispatch(helper.getAction(actions.UPDATE_PAYMENT_ID, {paymentId: paymentMethod.id}));
      return true;
    }

    return false;
  });
}

function removePaymentMethod(id: string, paymentId: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.payment.removePaymentMethod(id);

    if (response?.success && id === paymentId) {
      dispatch(helper.getAction(actions.UPDATE_PAYMENT_ID, {paymentId: ''}));
    }

    return response?.success ? true : false;
  });
}

function modifyCreditCard(field: string, value: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(helper.getAction(actions.MODIFY_CREDIT_CARD, {field, value}));
  });
}

function modifyAchAccount(field: string, value: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(helper.getAction(actions.MODIFY_ACH_ACCOUNT, {field, value}));
  });
}

function modifyBillingAddress(field: string, value: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(helper.getAction(actions.MODIFY_BILLING_ADDRESS, {field, value}));
  });
}

function selectBillingAddress(address: Address) {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(helper.getAction(actions.SELECT_BILLING_ADDRESS, {address}));
  });
}

function changePaymentMethod(paymentMethod: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(helper.getAction(actions.CHANGE_PAYMENT_METHOD, {paymentMethod}));
  });
}

function changePaymentTime(paymentTime: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(helper.getAction(actions.CHANGE_PAYMENT_TIME, {paymentTime}));
  });
}

function usePaymentMethod(paymentId: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(helper.getAction(actions.UPDATE_PAYMENT_ID, {paymentId}));
  });
}

function setPaymentErrors(errors: object) {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(helper.getAction(actions.SET_PAYMENT_ERRORS, {errors}));
  });
}

function clearPaymentState() {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(helper.getAction(actions.CLEAR_PAYMENT_STATE));
  });
}

function modifyBillingContactInformation(field: string, value: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(helper.getAction(actions.MODIFY_BILLING_CONTACT_INFORMATION, {field, value}));
  });
}

function clearCreditCardAndAchAccount() {
  return helper.dispatchAsyncAction(async dispatch => {
    dispatch(helper.getAction(actions.CLEAR_CREDIT_CARD_AND_ACH_ACCOUNT));
  });
}

import React, {useState, useEffect} from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';
import {useSelector, useDispatch} from 'react-redux';
import {cloneDeep, get, isEmpty} from 'lodash';
import $ from 'jquery';
import {useLocation} from 'react-router-dom';

import {AppState} from 'reducers';
import quoteActions from 'actions/quoteActions';
import commonActions from 'actions/commonActions';

import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import COMMON from 'constants/common';
import UNIT from 'constants/units';
import MEASUREMENT_SYSTEM from 'constants/measurementSystems';
import SEARCH_PARAMETER from 'constants/searchParameters';

import validationHelper from 'helpers/validationHelper';
import uiHelper from 'helpers/uiHelper';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import SelectInput from 'components/common/SelectInput/SelectInput';
import TextInput from 'components/common/TextInput';
import DatePicker from 'components/common/DatePicker';
import TogglerWithLabels from 'components/common/TogglerWithLabels';
import Flex from 'components/common/Flex';
import FormLabel from 'components/common/FormLabel';
import Button from 'components/common/Button';
import Disclaimer from 'components/common/Disclaimer';

import SignMeUpModule from './components/sign_me_up_module/SignMeUpModule';
import StandaloneItem from './components/StandaloneItem';
import QuoteSection from './components/QuoteSection';
import InsuranceSection from './components/InsuranceSection';
import DhlProvider from './components/DhlProvider';
import FreightQuoteModal from './components/FreightQuoteModal';
import FreightShippingModule from './components/freight_shipping_module/FreightShippingModule';

import extras from './extras';

import {mainFont, maxUsersContainerWidth, mediaQueries, colors} from 'styles/shared';

const StyledPageContainer = styled.div`
  width: ${maxUsersContainerWidth};

  @media ${mediaQueries.mobileMax} {
    width: 100%;
  }
`;

const StyledSendingContainer = styled(Container)`
  padding: 2.6rem 2.6rem 1.3rem;

  @media ${mediaQueries.mobileMax} {
    padding: 2.6rem 1.7rem 3.4rem;
  }
`;

const StyledFormHeader = styled(FormHeader)`
  margin-bottom: 0.6rem;
`;

const StyledDatePicker = styled(DatePicker)`
  margin: 0;

  @media ${mediaQueries.mobileMax} {
    margin-bottom: 2rem;
  }
`;

const StyledTotalRow = styled(Flex)`
  margin: 4rem 0;

  @media ${mediaQueries.mobileMax} {
    padding: 0 1.7rem;
  }
`;

const StyledTotalHeader = styled(FormHeader)`
  @media ${mediaQueries.mobileMax} {
    flex: 1;
  }
`;

const StyledAddButton = styled(Button)`
  @media ${mediaQueries.mobileMax} {
    padding: 1.5rem;
  }
`;

const StyledCountryInput = styled(SelectInput)`
  @media ${mediaQueries.mobileMax} {
    margin-bottom: 0;
  }
`;

const StyledTextInput = styled(TextInput)`
  @media ${mediaQueries.mobileMax} {
    margin-bottom: 0;
  }
`;

const StyledDomesticInfo = styled.div`
  ${mainFont};
  margin-bottom: 2rem;

  @media ${mediaQueries.mobileMax} {
    margin: 0 1.6rem 1.6rem;
  }
`;

const StyledAsterisk = styled.span`
  color: ${colors.orange_main};
`;

const StyledDisclaimer = styled(Flex)`
  @media ${mediaQueries.mobileMax} {
    justify-content: center;
  }
`;

function GetQuotePage() {
  const dispatch = useDispatch();
  const {search} = useLocation();

  const user = useSelector((state: AppState) => state.user.current);
  const countryList = useSelector((state: AppState) => state.shipmentOptions.countryList);
  const rates = useSelector((state: AppState) => state.quote.currentRates);
  const rateError = useSelector((state: AppState) => state.quote.error);

  const [standaloneQuote, setStandaloneQuote] = useState<StandaloneQuote>(extras.getInitialStandaloneQuote());
  const [domestic, setDomestic] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [totalWeight, setTotalWeight] = useState(0);
  const [disclaimerModalVisible, setDisclaimerModalVisible] = useState(false);
  const [freightQuoteWarning, setFreightQuoteWarning] = useState<string>('');
  const [freightQuoteModalVisible, setFreightQuoteModalVisible] = useState(false);
  const [freightShippingModuleVisible, setFreightShippingModuleVisible] = useState(true);

  const [quoteParamsChanged, setQuoteParamsChanged] = useState<number | undefined>(undefined);
  const [errorsScroll, setErrorsScroll] = useState(false);
  const [errors, setErrors] = useState({});

  const standartBoxes: Box[] = extras.getStandartBoxes();

  useEffect(() => {
    initData();

    return () => {
      //clear quote state on page leave
      dispatch(quoteActions.clearQuoteState());
    };
  }, []);

  function initData() {
    const query = new URLSearchParams(search);

    const isDomestic = query.get(SEARCH_PARAMETER.LOCAL.DOMESTIC);

    if (!isDomestic) return;

    setStandaloneQuote({
      ...standaloneQuote,
      sendingFrom: {...standaloneQuote.sendingFrom, country: COUNTRY_CODE.USA},
      sendingTo: {...standaloneQuote.sendingTo, country: COUNTRY_CODE.USA}
    });
  }

  //TODO calculate total weight only when item weight had changed
  useEffect(() => {
    const totalWeightValue = extras.calculatePackagesTotalWeight(standaloneQuote.items);
    setTotalWeight(totalWeightValue);
  });

  //get freight warning (check if package exceeds total weight or size limit)
  useEffect(() => {
    if (!totalWeight) return;

    const freightWarning: string = extras.getFreightWarning(standaloneQuote, totalWeight);

    if (freightWarning !== freightQuoteWarning) {
      setFreightQuoteWarning(freightWarning);
    }
  }, [totalWeight, standaloneQuote.items]);

  //show warning if user check Dangerous Goods
  useEffect(() => {
    if (!standaloneQuote.hasDangerousGoods) return;

    dispatch(
      commonActions.infoAction({
        type: 'info',
        text: 'Are you shipping Dangerous Goods or Alcohol? Please contact our customer service team at 972-383-9901.',
        close: () => null
      })
    );
  }, [standaloneQuote.hasDangerousGoods]);

  //scroll to the first issue that is not locally validated
  useEffect(() => {
    if (!errorsScroll) return;

    setErrorsScroll(false);

    // Ignore mobile elements if in desktop mode and vice versa
    const errors = $('.validation-error').filter(function() {
      return this.clientHeight !== 0;
    });

    let scrollTop = errors?.offset()?.top;

    if (scrollTop) {
      scrollTop = scrollTop - 250;
      $('html, body').animate({scrollTop}, 1000);
    }
  }, [errorsScroll]);

  useEffect(() => {
    if (!isEmpty(rates)) dispatch(quoteActions.clearQuoteState());

    if (isEmpty(errors)) return;

    const validationResult: ValidationResult = validationHelper.validateStandaloneQuote(standaloneQuote, domestic);

    setErrors(validationResult.errors);
  }, [quoteParamsChanged]);

  useEffect(() => {
    if (!rateError) return;

    dispatch(
      commonActions.infoAction({
        type: 'error',
        text: rateError,
        rollbarContext: 'Get Quote rate - API error response',
        close: () => null
      })
    );
  }, [rateError]);

  useEffect(() => {
    setDomestic(extras.isDomesticStandaloneQuote(standaloneQuote));
  }, [standaloneQuote.sendingFrom.country, standaloneQuote.sendingTo.country]);

  useEffect(() =>{
    setIsImport(extras.isImportStandaloneQuote(standaloneQuote));
  }, [standaloneQuote.sendingFrom.country]);

  useEffect(() => {
    if (!domestic) return;

    if (
      standaloneQuote.currency !== UNIT.CURRENCY_USD ||
      standaloneQuote.measurementSystem !== MEASUREMENT_SYSTEM.IMPERIAL
    ) {
      setStandaloneQuote({
        ...standaloneQuote,
        currency: UNIT.CURRENCY_USD,
        measurementSystem: MEASUREMENT_SYSTEM.IMPERIAL
      });
    }
  }, [domestic]);

  // Prevents isDocument from being true if changes to domestic shipment
  // Prevent usesExpressEnvelope from being true if it changes to a domestic or import shipment or
  // if isDocument is false.
  useEffect(() => {
    const standaloneCopy = standaloneQuote;
    standaloneCopy.usesExpressEnvelope = false;

    if (domestic) {
      standaloneCopy.isDocument = false;
    }
    setStandaloneQuote(standaloneCopy);
  }, [domestic, standaloneQuote.isDocument, isImport]);

  // Remove extra packages if isDocument checkbox is selected (leaves first)
  useEffect(() => {
    if (standaloneQuote.isDocument && standaloneQuote.items.length > 1) {
      let newItems = [standaloneQuote.items[0]];
      onChange(null, 'items', newItems);
    }
  }, [standaloneQuote.isDocument]);

  // Prevents hasDangerousGoods from being true if changes to domestic or documents shipment
  useEffect(() => {
    if ((domestic || standaloneQuote.isDocument) && standaloneQuote.hasDangerousGoods) {
      onChange(null, 'hasDangerousGoods', false);
    }
  }, [domestic, standaloneQuote.isDocument]);

  function onChange(innerObjectName, field, value) {
    if (innerObjectName) {
      let updated = {...standaloneQuote[innerObjectName], [field]: value};

      setStandaloneQuote({...standaloneQuote, [innerObjectName]: updated});
    } else {
      setStandaloneQuote({...standaloneQuote, [field]: value});
    }

    setQuoteParamsChanged(new Date().getTime());
  }

  function onCountryChange(innerObjectName, field, value) {
    const origin = innerObjectName === 'sendingFrom' ? value : standaloneQuote.sendingFrom.country;
    const destination = innerObjectName === 'sendingTo' ? value : standaloneQuote.sendingTo.country;

    // Check for error and display message. Do not save invalid choice.
    const validationError = validationHelper.validateCountriesForShipment(origin, destination);
    if (validationError) {
      dispatch(commonActions.infoAction(uiHelper.getValidationErrorPopupParameters(validationError)));
      if (!validationError.isWarning) return;
    }

    onChange(innerObjectName, field, value);
  }

  function updateItem(i, field, value) {
    let newItems = [...standaloneQuote.items];

    let item = {...standaloneQuote.items[i]};
    item[field] = value;

    newItems[i] = item;

    onChange(null, 'items', newItems);
  }

  function selectBox(index: number, box: Box) {
    let newItems = [...standaloneQuote.items];

    const measurementSystem = standaloneQuote.measurementSystem;

    let item = {...standaloneQuote.items[index]};
    item.length = box.size[measurementSystem].length;
    item.width = box.size[measurementSystem].width;
    item.height = box.size[measurementSystem].height;

    newItems[index] = item;

    onChange(null, 'items', newItems);
  }

  function addItem() {
    let newItems = [...standaloneQuote.items];

    const defaultItem = cloneDeep(extras.getDefaultStandaloneItem());

    newItems.push(defaultItem);

    onChange(null, 'items', newItems);
  }

  function removeItem(index) {
    let newItems = [...standaloneQuote.items];

    newItems.splice(index, 1);

    onChange(null, 'items', newItems);
  }

  async function getQuote() {
    if (freightQuoteWarning) {
      toggleFreightQuoteModal();
      return;
    }

    const validationResult: ValidationResult = validationHelper.validateStandaloneQuote(standaloneQuote, domestic);

    if (!validationResult.valid) {
      setErrors(validationResult.errors);
      setErrorsScroll(true);
      return;
    }

    await dispatch(quoteActions.checkRate(standaloneQuote, domestic));
  }

  function toggleDisclaimerModal() {
    setDisclaimerModalVisible(!disclaimerModalVisible);
  }

  function toggleFreightQuoteModal() {
    setFreightQuoteModalVisible(!freightQuoteModalVisible);
  }

  function renderSendingSection(domestic) {
    const postalCodeLabel = domestic ? 'Zip' : 'Postal Code';

    return (
      <StyledSendingContainer>
        <StyledFormHeader>Sending from:</StyledFormHeader>

        <Row>
          <Col lg={5}>
            <StyledCountryInput
              name="country"
              label="Country or Region"
              required={true}
              options={countryList}
              value={standaloneQuote.sendingFrom.country}
              onChange={(field, value) => onCountryChange('sendingFrom', field, value)}
              error={get(errors, 'sendingFrom.country', '')}
            />
          </Col>
          <Col lg={1} />
          <Col lg={6}>
            <Row>
              <Col lg={6}>
                <StyledTextInput
                  name="city"
                  label="City"
                  placeholder="Type City"
                  value={standaloneQuote.sendingFrom.city}
                  onChange={(field, value) => onChange('sendingFrom', field, value)}
                  error={get(errors, 'sendingFrom.city', '')}
                />
              </Col>
              <Col lg={6}>
                <TextInput
                  name="postalCode"
                  label={postalCodeLabel}
                  placeholder={postalCodeLabel}
                  required={domestic}
                  value={standaloneQuote.sendingFrom.postalCode}
                  onChange={(field, value) => onChange('sendingFrom', field, value)}
                  error={get(errors, 'sendingFrom.postalCode', '')}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <StyledFormHeader>Sending to:</StyledFormHeader>

        <Row>
          <Col lg={5}>
            <StyledCountryInput
              name="country"
              label="Country or Region"
              required={true}
              options={countryList}
              value={standaloneQuote.sendingTo.country}
              onChange={(field, value) => onCountryChange('sendingTo', field, value)}
              error={get(errors, 'sendingTo.country', '')}
            />
          </Col>
          <Col lg={1} />
          <Col lg={6}>
            <Row>
              <Col lg={6}>
                <StyledTextInput
                  name="city"
                  label="City"
                  placeholder="eg. Dallas"
                  value={standaloneQuote.sendingTo.city}
                  onChange={(field, value) => onChange('sendingTo', field, value)}
                  error={get(errors, 'sendingTo.city', '')}
                />
              </Col>
              <Col lg={6}>
                <TextInput
                  name="postalCode"
                  label={postalCodeLabel}
                  placeholder={postalCodeLabel}
                  required={domestic}
                  value={standaloneQuote.sendingTo.postalCode}
                  onChange={(field, value) => onChange('sendingTo', field, value)}
                  error={get(errors, 'sendingTo.postalCode', '')}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={5}>
            <StyledDatePicker
              name="shipDate"
              label="Select shipment date"
              required={true}
              dateFormat="m/d/Y"
              value={standaloneQuote.shipDate}
              onChange={(field, value) => onChange(null, field, value)}
            />
          </Col>
          <Col lg={1}></Col>
          <Col lg={6}>
            <Flex justify="space-between" align="flex-start">
              <FormLabel required={true}>Is it Residential?</FormLabel>
              <TogglerWithLabels
                name="isResidential"
                value={standaloneQuote.sendingTo.isResidential}
                namePrefix="standalone-residential"
                onToggle={(field, value) => onChange('sendingTo', field, value)}
              />
            </Flex>
          </Col>
        </Row>
      </StyledSendingContainer>
    );
  }

  function render() {
    const weightUnitLabel = extras.getWeightUnitLabel(standaloneQuote.measurementSystem);
    const displayTotalWeight = totalWeight > 0 ? `${totalWeight} ${weightUnitLabel}` : COMMON.EMPTY_FIELD;

    const addPackagesDisabled = standaloneQuote.isDocument;

    return (
      <StyledPageContainer>
        {user?.isGuest && <SignMeUpModule />}

        {renderSendingSection(domestic)}

        {standaloneQuote.items.map((item, index) => {
          const isLastIndex = standaloneQuote.items.length - 1 === index;

          return (
            <StandaloneItem
              key={index}
              index={index}
              isLastIndex={isLastIndex}
              item={item}
              domestic={domestic}
              isImport={isImport}
              measurementSystem={standaloneQuote.measurementSystem}
              freightQuoteWarning={freightQuoteWarning}
              errors={errors}
              boxes={standartBoxes}
              onChange={updateItem}
              onRemove={removeItem}
              onBoxSelect={selectBox}
              onUnitChange={(field, value) => onChange(null, field, value)}
              standaloneQuote={standaloneQuote}
            />
          );
        })}

        <StyledTotalRow justify="space-between" align="center">
          <StyledTotalHeader>Total Shipment Weight: {displayTotalWeight}</StyledTotalHeader>
          <StyledAddButton disabled={addPackagesDisabled} type="secondary" autoWidth onClick={addItem}>
            Add New Package
          </StyledAddButton>
        </StyledTotalRow>

        {!domestic && <InsuranceSection
          insuranceValue={standaloneQuote.insuranceValue}
          currency={standaloneQuote.currency}
          onChange={(field, value) => onChange(null, field, value)}
          error={get(errors, 'insuranceValue', '')}
          isDomestic={false}
        />}

        {domestic && !isEmpty(rates) && (
          <StyledDomesticInfo>
            The discounted quotes below are for shipping charges. The estimates are based on the shipment information
            provided. Pickup, insurance, and other service fees may not be included in this quote.
            <StyledAsterisk>*</StyledAsterisk>
          </StyledDomesticInfo>
        )}

        <QuoteSection
          standaloneQuote={standaloneQuote}
          isDomestic={domestic}
          onChange={onChange}
          getQuoteAction={getQuote}
        />

        <StyledDisclaimer>
          <Button type="tertiary" margin={`0 0 3rem`} onClick={toggleDisclaimerModal}>
            *Quote Disclaimer
          </Button>
        </StyledDisclaimer>

        {freightShippingModuleVisible && (
          <FreightShippingModule
            askForFreightQuote={toggleFreightQuoteModal}
            close={() => setFreightShippingModuleVisible(false)}
          />
        )}

        {!domestic && <DhlProvider />}

        {disclaimerModalVisible && (
          <Disclaimer visible={disclaimerModalVisible} close={toggleDisclaimerModal} isDomestic={domestic} />
        )}

        {freightQuoteModalVisible && (
          <FreightQuoteModal
            visible={freightQuoteModalVisible}
            standaloneQuote={standaloneQuote}
            isGuest={user?.isGuest}
            close={toggleFreightQuoteModal}
          />
        )}

      </StyledPageContainer>
    );
  }

  return render();
}

export default GetQuotePage;

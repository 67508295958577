import React from 'react';
import styled from 'styled-components/macro';
import {Row, Col} from 'components/bootstrap';

import COMMON from 'constants/common';
import UNIT from 'constants/units';

import FormLabel from 'components/common/FormLabel';
import TextInput from 'components/common/TextInput';
import Toggler from 'components/common/Toggler';
import DatePicker from 'components/common/DatePicker';
import CurrencyInput from 'components/common/CurrencyInput';

const StyledAutogenerateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

interface Props {
  order: OrderInformation;
  onChange: (field: string, value: any) => void;
}

function OrderSection({order, onChange}: Props) {
  return (
    <>
      <FormLabel>Order Summary Information</FormLabel>

      <TextInput name="id" label="Order #" required placeholder="Order #" value={order.id} onChange={onChange} />

      <StyledAutogenerateContainer>
        <FormLabel>Autogenerate Order</FormLabel>
        <Toggler name="autogenerate" value={order.autogenerate} onToggle={onChange} />
      </StyledAutogenerateContainer>

      <Row>
        <Col lg={6}>
          <DatePicker
            name="oderDate"
            label="Order Date"
            placeholder="DD / MM / YYYYY"
            value={order.oderDate}
            onChange={onChange}
          />
        </Col>
        <Col lg={6}>
          <DatePicker
            name="paidDate"
            label="Paid Date"
            placeholder="DD / MM / YYYYY"
            value={order.paidDate}
            onChange={onChange}
          />
        </Col>
      </Row>

      <CurrencyInput
        name="shippingPaid"
        label="Shipping Paid"
        value={order.shippingPaid}
        currency={UNIT.CURRENCY_USD}
        required={true}
        tooltip={COMMON.MISSING_TEXT_TODO}
        placeholder="00,00"
        onChange={onChange}
      />

      <CurrencyInput
        name="taxPaid"
        label="Tax Paid"
        value={order.taxPaid}
        currency={UNIT.CURRENCY_USD}
        required={true}
        tooltip={COMMON.MISSING_TEXT_TODO}
        placeholder="00,00"
        onChange={onChange}
      />

      <CurrencyInput
        name="totalPaid"
        label="Total Paid"
        value={order.totalPaid}
        currency={UNIT.CURRENCY_USD}
        required={true}
        tooltip={COMMON.MISSING_TEXT_TODO}
        placeholder="00,00"
        onChange={onChange}
      />
    </>
  );
}

export default OrderSection;

import * as actionTypes from 'action_types/lineItemActionTypes';

import helper from './actionHelper';
import apis from 'domain/apis';

export default {
  createLineItem,
  loadLineItems,
  removeLineItem,
  selectLineItem,
  updateLineItemPageNumber
};

function createLineItem(item: LineItem, currency: string, measurementSystem: string) {
  return helper.dispatchAsyncAction(async () => {
    const response = await apis.lineItem.createLineItem(item, currency, measurementSystem);
    return response?.id ? true : false;
  });
}

function loadLineItems() {
  return helper.dispatchAsyncAction(async dispatch => {
    const result = await apis.lineItem.getLineItems();

    dispatch(helper.getAction(actionTypes.LOAD_LINE_ITEMS, {...result}));

    return result?.lineItems;
  }, false);
}

function removeLineItem(id: string) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.lineItem.deleteLineItem(id);

    if (response?.success) dispatch(helper.getAction(actionTypes.REMOVE_LINE_ITEM, {id}));
  });
}

function selectLineItem(lineItem: LineItem) {
  return helper.getAction(actionTypes.SELECT_LINE_ITEM_FROM_SEARCH, {lineItem});
}

function updateLineItemPageNumber(activePage: number) {
  return helper.getAction(actionTypes.CHANGE_LINE_ITEM_PAGE, {activePage});
}

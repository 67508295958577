import React from 'react';
import styled from 'styled-components/macro';

import FormLabel from 'components/common/FormLabel';
import Button from 'components/common/Button';

import {colors, secondaryFont, mainFont} from 'styles/shared';

const StyledContainer = styled.div`
  background: ${colors.grey_concrete};
  border-radius: 0.6rem;
  height: 5.4rem;
  padding: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
`;

const StyledTitle = styled.div`
  ${secondaryFont};
`;

const StyledButton = styled(Button)`
  height: 3.8rem;
  width: auto;
  min-width: ${props => (props.big ? '9.4rem' : '6.8rem')};
  max-width: 9.4rem;
  padding: 0.3rem;
`;

const StyledSelect = styled.select`
  z-index: 100;
  height: 4.6rem;
  width: 100%;
  cursor: pointer;
  border: none;
  color: ${colors.white};
  background-color: transparent;
  ${mainFont};
  appearance: none;
  background: url(images/arrow-down-small.png) no-repeat right transparent;
  background-position: 98% 50%;
  padding: 0.5rem;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }

  option {
    color: ${colors.black};
    ${mainFont};
    direction: ltr;
  }
`;

interface Props extends BaseProps {
  name: string;
  label: string;
  title: string;
  value: string;
  options: BasicOption[];
  onChange: OnChangeHandler;
}

SystemUnitsToggler.defaultProps = {
  className: ''
};

function SystemUnitsToggler({name, label, title, value, options, onChange, className}: Props) {
  function onFieldChange(e) {
    let val = e.target.value;
    onChange(name, val);
  }

  return (
    <div className={className}>
      <FormLabel>{label}</FormLabel>

      <StyledContainer>
        <StyledTitle>{title}:</StyledTitle>

        <StyledButton big={value.length > 6}>
          <StyledSelect value={value} onChange={onFieldChange}>
            {options.map((opt: BasicOption) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </StyledSelect>
        </StyledButton>
      </StyledContainer>
    </div>
  );
}

export default SystemUnitsToggler;

import styled from 'styled-components/macro';
import {Col} from 'components/bootstrap';

import {colors, mainFont} from 'styles/shared';

export const innerContainer = styled.div`
  padding: 3.7rem 3rem 3rem 6rem;
`;

export const amountContainer = styled.div`
  font-size: 4rem;
  line-height: 5.5rem;
  letter-spacing: 0.03rem;
  color: ${colors.orange_main};
`;

export const total = styled(amountContainer)`
  color: ${colors.darkBlue_main};
`;

export const imageContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

export const footer = styled.div`
  margin-top: 1.2rem;
`;

export const text = styled.div`
  ${mainFont};
  margin-bottom: 2.4rem;

  > span {
    color: ${colors.orange_main};
  }
`;

export const separator = styled.div`
  height: 2.4rem;
  width: 0.1rem;
  background-color: ${colors.darkBlue_light};
  margin: 0 2.4rem;
`;

export const asterisk = styled.span`
  color: ${colors.orange_main};
`;

import React from 'react';
import styled from 'styled-components/macro';
import {useHistory} from 'react-router-dom';

import formatHelper from 'helpers/formatHelper';
import navigationHelper from 'helpers/navigationHelper';

import authService from 'services/authService';

import Button from 'components/common/Button';
import FormHeader from 'components/common/FormHeader';
import Flex from 'components/common/Flex';
import ActionsPopup from 'components/common/ActionsPopup';

import {colors, mainFont, opacity} from 'styles/shared';

const StyledCard = styled(Flex)`
  width: 35.4rem;
  min-height: 34.6rem;
  margin: 1.5rem;
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};
  border-radius: 0.4rem;
  padding: 1.4rem 1.4rem 3rem;
  color: ${colors.darkBlue_main};
  background-color: ${props => (!props.active && colors.red_light) ||
          (props.suspended && colors.yellow_light)};
`;

const StyledActionsPopup = styled(ActionsPopup)`
  height: auto;
  padding: 0;
  margin-bottom: 0.4rem;

  & div:last-child {
    margin: 0;
  }
`;

const StyledInfoContainer = styled.div`
  margin-left: 1rem;
`;

const StyledTitle = styled(FormHeader)`
  margin-bottom: 0.8rem;
`;

const StyledText = styled.div`
  ${mainFont};
  margin-bottom: 0.8rem;

  & span:first-child {
    font-weight: bold;
  }
`;

const StyledStatusText = styled.div`
  ${mainFont};
  margin-bottom: 0.8rem;
  border-radius: 0.4rem;
  border-style: dashed;
  padding: 1.0rem;
  border-color: ${colors.darkBlue_main};

  & span:first-child {
    font-weight: bold;
  }
`;

interface Props {
  user: User;
}

interface Props {
  user: User;
  actions: DropdownAction[];
  onRefresh: (user: User) => void;
}

function UserCard({user, actions, onRefresh}: Props) {
  const history = useHistory();

  async function signIntoProfile() {
    await authService.signIntoProfile(null, user);

    history.push(navigationHelper.getViewHistoryPageLink());
  }

  function renderRow(label: string, value: string) {
    return (
      <StyledText>
        <span>{label}: </span>
        <span>{value}</span>
      </StyledText>
    );
  }

  function renderStatus(value: string) {
    return (
      <StyledStatusText>
        <span>{value}</span>
      </StyledStatusText>
    );
  }

  function render() {
    const id = user.id;

    let title = formatHelper.truncateTextToLength(user.companyName, 43);

    let clientId = user.billingInformation.osClientId;
    if (!clientId) clientId = formatHelper.truncateTextToLength(id, 8, 'start');

    const billingInformation = user.billingInformation;

    return (
      <StyledCard key={id} direction="column" justify="space-between" active={user.active} suspended={user.suspended}>
        <div>
          <Flex justify="space-between">
            <div>
              {!user.active && renderStatus('Inactivated')}
              {user.suspended && renderStatus('Suspended')}
              {user.whitelisted && renderStatus('Whitelisted')}
            </div>
            <StyledActionsPopup actions={actions} activeItem={user} labelVisible={false} />
          </Flex>

          <StyledInfoContainer>
            <StyledTitle>{title}</StyledTitle>

            {renderRow('Contact', user?.name)}
            {renderRow('Client ID', clientId)}
            {renderRow('Export Nr.', formatHelper.displayValue(billingInformation.dhlExportNumber))}
            {renderRow('Import Nr.', formatHelper.displayValue(billingInformation.dhlImportNumber))}
            {renderRow('Email', user?.email)}
          </StyledInfoContainer>
        </div>

        <Flex justify="space-between">
          <Button autoWidth onClick={signIntoProfile}>
            Log Into Profile
          </Button>

          <Button autoWidth onClick={() => onRefresh(user)}>
            Sync Client
          </Button>
        </Flex>
      </StyledCard>
    );
  }

  return render();
}

export default UserCard;

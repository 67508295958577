import update from 'immutability-helper';
import {set as setField, cloneDeep, differenceWith, isEqual} from 'lodash';

import * as actions from 'action_types/draftActionTypes';
import initialState, {CURRENT_DRAFT, DRAFT_PICKUP, DRAFT_RATE} from './initialState';
import helper from './reducerHelper';

import WHO_PAYS from 'constants/payment/whoPays';
import COMMON from 'constants/common';
import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import CARRIER from 'constants/carriers';

import entities from 'domain/entities';
import draftExtras from 'domain/entities/draftExtras';

import stateStorageService from 'services/stateStorageService';
import heapAnalyticsService from 'services/heapAnalyticsService';
import rateService from 'services/rateService';

export interface DraftState {
  list: DraftItem[];
  pagination: Pagination;
  current: CurrentDraft;
  pickup: PickupItem;
  rate: RateItem;
}

export interface DraftItem {
  id: string;
  payload: string;
}

interface CurrentDraft {
  data?: Draft;
  errors: object;
  steps: Step[];
  step: string;
  stepWasSubmitted: boolean;
  originAddressError: boolean;
  destinationAddressError: boolean;
  lastModifiedAddressSection: string | null;
  isDataUploaded: boolean;
  isExistingDraft: boolean;
  rateParamsChanged?: number;
  carrierProductChanged?: number;
  shouldReviewPickup?: boolean;
}

interface PickupItem {
  list: Pickup[];
  dataToSave?: PickupData;
  pickupCapability?: PickupCapability;
  errors: object;
  pickupWasSubmitted: boolean;
}

interface RateItem {
  list: Rate[];
  current?: Rate;
  error?: string;
}

const draftReducer = (state: DraftState = initialState.draft, action) => {
  return helper.handleActions<DraftState>(state, action, {
    [actions.LOAD_DRAFTS](state, payload: {drafts: DraftItem[]; totalItems: number}) {
      state.list = payload.drafts;
      state.pagination.totalCount = payload.totalItems;
    },
    [actions.CHANGE_DRAFTS_PAGE](state, payload: {activePage: number}) {
      state.pagination.activePage = payload.activePage;
    },
    [actions.SET_DRAFT](state, payload: {draftObject: Draft}) {
      state.current.data = payload.draftObject;
      state.current.rateParamsChanged = new Date().getTime();
    },
    [actions.DRAFT_GO_TO_PREVIOUS_STEP](state) {
      const steps = state.current.steps;
      const currentStep = state.current.step;

      let currentIndex = steps.findIndex(item => item.step === currentStep);

      let previousStep = steps[currentIndex - 1].step;

      changeStep(state, previousStep);
    },
    [actions.DRAFT_GO_TO_SECTION](state, payload: {step: string}) {
      changeStep(state, payload.step);
    },
    [actions.DRAFT_GO_TO_STEP](state, payload: {step: string}) {
      changeStep(state, payload.step);
    },
    [actions.DRAFT_GO_TO_NEXT_VALID_STEP](state) {
      const steps = state.current.steps;
      const currentStep = state.current.step;
      const isDataUploaded = state.current.isDataUploaded;

      if (state.current.shouldReviewPickup) {
        const pickupStepIndex = steps.findIndex(item => item.step === COMMON.PICKUP_STEP);
        const currentIndex = steps.findIndex(item => item.step === currentStep);

        state.current.steps = update(steps, {
          [currentIndex]: {valid: {$set: true}}
        });

        state.current.steps = update(steps, {
          [pickupStepIndex]: {valid: {$set: false}}
        });

        let nextStepIndex = pickupStepIndex;

        if (pickupStepIndex > currentIndex) {
          for (let i = currentIndex + 1; i < pickupStepIndex; i++) {
            if (!state.current.steps[i].valid) {
              nextStepIndex = i;
              return;
            }
          }
        }

        const nextStepObj: Step = steps[nextStepIndex];

        heapAnalyticsService.track('Shipment Prep Revise Pickup After Address Change');

        const nextStep = nextStepObj.step;
        changeStep(state, nextStep);

        return;
      }

      const currentIndex = steps.findIndex(item => item.step === currentStep);

      let index = currentIndex;
      while (steps[index + 1]?.valid) index++;
      if (index < steps.length - 1) index++;

      const stepObj: Step = steps[index];

      // track draft step with heap analytics only for new drafts
      if (!isDataUploaded) {
        heapAnalyticsService.trackDraftStep('Shipment Prep: step', stepObj.stepNumber);
      }

      const nextStep = stepObj.step;
      changeStep(state, nextStep);

      let stepIndex = steps.findIndex((item: any) => item.step === currentStep);

      if (stepIndex !== -1) {
        for (let i = 0; i <= stepIndex; i++) {
          state.current.steps = update(steps, {
            [stepIndex]: {valid: {$set: true}}
          });
        }
      }
    },
    [actions.MODIFY_DRAFT](state, payload: {innerObjectName: string | null; field: string; value: any}) {
      const innerObjectName = payload.innerObjectName;
      const field = payload.field;
      const value = payload.value;

      let newShipment = cloneDeep(state.current.data) as Draft;

      let path = field.split('.');

      if (innerObjectName) {
        setField(newShipment[innerObjectName], path, value);
      } else if (field) {
        setField(newShipment, path, value);
      }

      let newDraft = entities.draft.createFromData(newShipment);

      let isRateParameter = checkIsRateParameter(state.current.data as Draft, newDraft, field, state.rate.current);

      let hasCarrierProductChanged = !isEqual(state.current.data?.pickupDelivery.carrierProduct,
        newDraft.pickupDelivery.carrierProduct);

      if (state.current.steps[state.current.steps.findIndex(item => item.step === COMMON.PICKUP_STEP)].valid) {
        state.current.shouldReviewPickup = checkShouldReviewPickup(state.current.data as Draft, newDraft);
      }

      if (isRateParameter) {
        state.current.rateParamsChanged = new Date().getTime();
      } else if (hasCarrierProductChanged) {
        state.current.carrierProductChanged = new Date().getTime();
      }

      state.current.data = newDraft;

      storeUnsavedDraft(state);

      if (state.current.isDataUploaded) state.current.isDataUploaded = false;

      state.current.lastModifiedAddressSection = field === 'address' ? innerObjectName : null;
    },
    [actions.SET_DRAFT_ADDRESS_ERRORS](state, payload: {previousAddressSection: string}) {
      const previousAddressSection = payload.previousAddressSection;

      if (previousAddressSection === 'shipmentFrom') state.current.originAddressError = true;
      if (previousAddressSection === 'shipmentTo') state.current.destinationAddressError = true;
    },
    [actions.CLEAN_DRAFT](state){
      function trimAll(o) {
        for (const [k, v] of Object.entries(o)) {
          if (Object(v) === v) trimAll(v);
          else if (typeof v === 'string') o[k] = v.trim();
        }
        return o;
      }

      if (state.current.data) {
        let cleanedDraft = cloneDeep(state.current.data) as Draft;
        // trim all string fields
        cleanedDraft = trimAll(cleanedDraft);
        // convert all ECCN letters to uppercase
        if (cleanedDraft.shipmentDetails.products.items) {
          cleanedDraft.shipmentDetails.products.items.forEach(item => {
            if (item.eccnValue) item.eccnValue = item.eccnValue.toUpperCase();
          });
        }
        state.current.data = cleanedDraft;
      }
    },
    [actions.DRAFT_VALIDATE](state, payload: {errors: object}) {
      state.current.errors = payload.errors;
      state.current.stepWasSubmitted = true;
    },
    [actions.LOAD_DRAFT_PICKUPS](state, payload: {pickups: Pickup[]}) {
      state.pickup.list = payload.pickups;
      state.current.shouldReviewPickup = false;
    },
    [actions.PICKUP_VALIDATE](state, payload: {errors: object}) {
      state.pickup.errors = payload.errors;
      state.pickup.pickupWasSubmitted = true;
    },
    [actions.CHECK_PICKUP_CAPABILITY](state, payload: {data: PickupCapability}) {
      state.pickup.pickupCapability = payload.data;
    },
    [actions.LOAD_RATES](state, payload: {rateInfo: Rate[]; errorMessage: string}) {
      const rateInfo = payload.rateInfo;
      const carrierProduct = state.current.data?.pickupDelivery.carrierProduct;

      state.rate.list = rateInfo;
      state.rate.error = payload.errorMessage;

      state.rate.current = getSelectedRate(rateInfo, carrierProduct);

      // Ensures same carrier product between selected rate and current draft
      if (state.current.data !== undefined && state.rate.current?.carrierProduct &&
          state.rate.current?.carrierProduct !== carrierProduct) {
        state.current.data.pickupDelivery.carrierProduct = state.rate.current.carrierProduct;
      }
    },
    [actions.LOAD_RATE](state) {
      const rateInfo = state.rate.list;
      const carrierProduct = state.current.data?.pickupDelivery.carrierProduct;

      state.rate.current = getSelectedRate(rateInfo, carrierProduct);
      state.current.shouldReviewPickup = true;
    },
    [actions.CLEAR_DRAFT_STATE](state) {
      clearState(state);
    },
    [actions.SET_PICKUP_DATA_TO_SAVE](state, payload: {pickupData?: PickupData}) {
      state.pickup.dataToSave = payload.pickupData;
    },
    [actions.DRAFT_CREATE_NEW](state, payload: {draft: Draft; pickupData: PickupData}) {
      state.current.data = payload.draft;
      state.pickup.dataToSave = payload.pickupData;
    },
    [actions.DRAFT_CREATE_FROM_ORDER](state) {
      state.current.isDataUploaded = true;
      state.current.isExistingDraft = false;
    },
    [actions.DRAFT_CREATE_FROM_SHIPMENT](state) {
      state.current.isDataUploaded = true;
      state.current.isExistingDraft = false;
    },
    [actions.DRAFT_CREATE_FROM_QUOTE](state) {
      state.current.isDataUploaded = true;
      state.current.isExistingDraft = false;
    },
    [actions.DRAFT_EDIT_EXISTING](state) {
      state.current.isDataUploaded = true;
      state.current.isExistingDraft = true;
    },
    [actions.DRAFT_UPLOAD_FROM_LOCAL_STORAGE](state) {
      state.current.isDataUploaded = true;
      state.current.isExistingDraft = false;
    },
    [actions.DRAFT_CANCEL](state) {
      clearState(state);
    }
  });
};

function getSelectedRate(rateInfo: Rate[], carrierProduct: string | undefined) {
  let selectedRate: Rate | undefined = undefined;
      if (rateInfo) {
        selectedRate = rateInfo.find(
          r => r.carrierService.productCode === carrierProduct
        );

        if (!selectedRate) selectedRate = rateInfo?.[0];
      }

  return selectedRate;
}

function clearState(state) {
  state.current = {...CURRENT_DRAFT};
  state.pickup = {...DRAFT_PICKUP};
  state.rate = {...DRAFT_RATE};
}

function storeUnsavedDraft(state) {
  let draftToSave = {
    ...state.current.data
  };

  stateStorageService.setUnsavedDraft(draftToSave);
}

function changeStep(state, step) {
  if (state.current.step === step) return;
  state.current.step = step;
  state.current.stepWasSubmitted = false;
  state.pickup.pickupWasSubmitted = false;
}

function checkShouldReviewPickup(previousDraft: Draft, currentDraft: Draft): boolean {
  if (previousDraft.shipmentFrom.country !== currentDraft.shipmentFrom.country) return true;
  if (previousDraft.shipmentFrom.address.addressLine1 !== currentDraft.shipmentFrom.address.addressLine1
      && currentDraft.shipmentFrom.country === COUNTRY_CODE.USA
      && currentDraft.pickupDelivery.isPickupRequired !== false) {
    return true;
  }
  return false;
}

function checkIsRateParameter(previousDraft: Draft, currentDraft: Draft, field, currentRate: Rate | undefined) {
  if (field === 'products') {
    const previousPackages = previousDraft.shipmentDetails.products.packages;
    const currentPackages = currentDraft.shipmentDetails.products.packages;

    if (previousPackages.length !== currentPackages.length) return true;

    const difference = differenceWith(previousPackages, currentPackages, isEqual);

    if (difference?.length > 0) return true;

    if (
      previousDraft.shipmentDetails.products.isItnNumberExists !==
        currentDraft.shipmentDetails.products.isItnNumberExists &&
      (previousDraft.shipmentDetails.products.isItnNumberExists === false ||
        currentDraft.shipmentDetails.products.isItnNumberExists === false)
    ) {
      return true;
    }
  }

  const previousDocuments = previousDraft.shipmentDetails.documents;
  const currentDocuments = currentDraft.shipmentDetails.documents;

  if (previousDraft.shipmentFrom.country !== currentDraft.shipmentFrom.country) return true;
  if (previousDraft.shipmentFrom.address.postalCode !== currentDraft.shipmentFrom.address.postalCode) return true;

  if (previousDraft.shipmentTo.country !== currentDraft.shipmentTo.country) return true;
  if (previousDraft.shipmentTo.address.city !== currentDraft.shipmentTo.address.city) return true;
  if (previousDraft.shipmentTo.address.postalCode !== currentDraft.shipmentTo.address.postalCode) return true;
  if (previousDraft.shipmentTo.isResidential !== currentDraft.shipmentTo.isResidential) {
    if (currentDraft.isDomestic) {
      rateService.displaySurcharge('domestic_receivers_residential');
    } else {
      rateService.displaySurcharge('receivers_residential', currentDraft.shipmentTo.isResidential);
    }
    return true;
  }
  // Currently has no effect on the rate, but this may change in the future
  if (previousDraft.shipmentFrom.isResidential !== currentDraft.shipmentFrom.isResidential) return true;

  if (!isEqual(previousDraft.pickupDelivery.shipmentDate, currentDraft.pickupDelivery.shipmentDate)) return true;
  if (!isEqual(previousDraft.pickupDelivery.pickup?.date, currentDraft.pickupDelivery.pickup?.date)) {
    if (currentDraft.pickupDelivery.isPickupRequired === false
      && currentRate?.carrier === CARRIER.UPS
      && draftExtras.hasNewPickupRequest(previousDraft)) {
      rateService.displaySurcharge('pickup');
    }

    return true;
  }

  if (previousDraft.currency !== currentDraft.currency) return true;
  if (previousDraft.measurementSystem !== currentDraft.measurementSystem) {
    rateService.displaySurcharge('measurement_system');
    return true;
  }

  if (previousDocuments.numberOfPackages !== currentDocuments.numberOfPackages) return true;
  if (previousDocuments.typeOfPackaging !== currentDocuments.typeOfPackaging) return true;
  if (previousDocuments.declaredShipmentValue !== currentDocuments.declaredShipmentValue) return true;
  if (previousDocuments.documentWeight !== currentDocuments.documentWeight) return true;
  if (previousDocuments.isDocumentWeightAboveHalfPound !== currentDocuments.isDocumentWeightAboveHalfPound) return true;

  //additional services
  const previousAdditionalServices = previousDraft.additionalServices;
  const currentAdditionalServices = currentDraft.additionalServices;

  if (previousAdditionalServices.insuranceValue !== currentAdditionalServices.insuranceValue) {
    if (!currentAdditionalServices.insuranceValue) rateService.displaySurcharge('insurance');
    return true;
  }

  if (
    currentDraft.isDocumentsShipment &&
    previousAdditionalServices.doYouNeedInsurance !== currentAdditionalServices.doYouNeedInsurance
  ) {
    rateService.displaySurcharge('insurance');
    return true;
  }

  if (
    previousAdditionalServices.isNeutralDeliveryServiceRequired !==
    currentAdditionalServices.isNeutralDeliveryServiceRequired
  ) {
    rateService.displaySurcharge('nds', currentAdditionalServices.isNeutralDeliveryServiceRequired);
    return true;
  }

  if (previousAdditionalServices.signature !== currentAdditionalServices.signature) {
    rateService.displaySurcharge('signature');
    return true;
  }

  //payment
  const previousPayment = previousDraft.payment;
  const currentPayment = currentDraft.payment;

  if (
    previousPayment.dutiesAndTaxesPayment !== currentPayment.dutiesAndTaxesPayment &&
    (previousPayment.dutiesAndTaxesPayment === WHO_PAYS.MY_ACCOUNT ||
      currentPayment.dutiesAndTaxesPayment === WHO_PAYS.MY_ACCOUNT)
  ) {
    const paidWithMyPaymentAccount = currentPayment.dutiesAndTaxesPayment === WHO_PAYS.MY_ACCOUNT;
    rateService.displaySurcharge('billing_duties', paidWithMyPaymentAccount);
    return true;
  }

  return false;
}

export default draftReducer;

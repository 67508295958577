import React from 'react';

import Flex from 'components/common/Flex';
import FormLabel from 'components/common/FormLabel';
import QuestionMark from 'components/common/Marks/QuestionMark';

interface Props extends BaseProps {
  label: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  tooltip: string | JSX.Element;
}

function FormLabelContainer({label, name, required, disabled, tooltip, className}: Props) {
  const tooltipId = `${name}-tooltip`;

  return (
    <Flex justify="space-between" className={className}>
      <FormLabel required={required} disabled={disabled}>
        {label}
      </FormLabel>

      {tooltip && <QuestionMark id={tooltipId} tooltip={tooltip} />}
    </Flex>
  );
}

export default FormLabelContainer;

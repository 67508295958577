import React from 'react';
import {useSelector} from 'react-redux';

import {AppState} from 'reducers';

import COMMON from 'constants/common';

import SummarySection from './SummarySection';
import SummaryItem from '../../SummaryItem';

interface Props {
  payment: Payment;
  isDomestic: boolean;
  insuranceValue: string;
}

function PaymentSummary({payment, isDomestic, insuranceValue}: Props) {
  const termsOfTradeOptions = useSelector((state: AppState) => state.shipmentOptions.termsOfTradeOptions);

  let termsOfTradeDisplay = COMMON.EMPTY_FIELD;

  if (payment.customsTermsOfTrade) {
    termsOfTradeDisplay = payment.customsTermsOfTrade;

    let option = termsOfTradeOptions.find((item: TermsOfTradeOption) => item.value === payment.customsTermsOfTrade);

    if (option) termsOfTradeDisplay = option.label;
  }

  return (
    <SummarySection header="Payment">
      <SummaryItem label="Transportation Charges Paid by" value={payment.freightPayment} />

      {!isDomestic && (
        <>
          <SummaryItem label="Duties and Taxes paid by" value={payment.dutiesAndTaxesPayment} />
          <SummaryItem label="Custom Terms of Trade" value={termsOfTradeDisplay} />
          <SummaryItem label="Shipment Value Protection" value={insuranceValue} />
        </>
      )}
    </SummarySection>
  );
}

export default PaymentSummary;

import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  let mainColor = colors.darkBlue_main;

  return (
    <svg width="44" height="45" fill="none" viewBox="0 0 44 45">
      <path
        stroke={mainColor}
        d="M10 32.213H6a2 2 0 01-2-2v-14a2 2 0 012-2h32a2 2 0 012 2v14a2 2 0 01-2 2h-4M9.5 10.713a1.5 1.5 0 011.5-1.5h22a1.5 1.5 0 011.5 1.5v3.5h-25v-3.5z"></path>
      <path stroke={mainColor} d="M9.5 24.713h25v10.5a1.5 1.5 0 01-1.5 1.5H11a1.5 1.5 0 01-1.5-1.5v-10.5z"></path>
      <path stroke={mainColor} d="M32.5 16.713H36.5V18.713H32.5z"></path>
      <path fill={mainColor} d="M12 27.213H32V28.213H12z"></path>
      <path fill={mainColor} d="M12 30.213H32V31.213H12z"></path>
      <path fill={mainColor} d="M12 33.213H24V34.213H12z"></path>
    </svg>
  );
}

export default Icon;

export default {
  mapLineItemFromLineItemResponse
};

function mapLineItemFromLineItemResponse(response: LineItemResponse) {
  const license = response.license;

  let lineItem: LineItem = {
    id: response.id,
    name: response.nickname,
    commodityCode: response.commodityCode,
    itemDescription: response.description,
    quantity: response.quantity.amount,
    units: response.quantity.unit,
    itemValue: response.value.amount ? Number(response.value.amount) : 0,
    itemMade: response.countryOfOrigin.code,
    eccnValue: response.eccn,
    exportLicenseNumber: license?.number,
    exportLicenseExpiryDate: license?.expiry,
    isLicenseSymbolRequired: license?.symbol ? true : false,
    licenseSymbol: license?.symbol,
    isLicenseDetailsRequired: false,
    isEmpty: false,
    isEccnRequired: false,
    weight: response.weight?.value ?? 0
  };

  return lineItem;
}

import COUNTRY_CODE from "./countryCodes";

const US_TERRITORIES = [
  COUNTRY_CODE.AMERICAN_SAMOA,
  COUNTRY_CODE.GUAM,
  COUNTRY_CODE.MARIANA_ISLANDS,
  COUNTRY_CODE.MARSHALL_ISLANDS,
  COUNTRY_CODE.MICRONESIA,
  COUNTRY_CODE.PALAU,
  COUNTRY_CODE.PUERTO_RICO,
  COUNTRY_CODE.VIRGIN_ISLANDS_US
];

export default US_TERRITORIES;

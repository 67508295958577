import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components/macro';
import {isEmpty, minBy} from 'lodash';

import {AppState} from 'reducers';
import draftActions from 'actions/draftActions';
import commonActions from 'actions/commonActions';

import uiHelper from 'helpers/uiHelper';

import PATH from 'constants/path';

import entities from 'domain/entities';

import rateServie from 'services/rateService';
import stateStorageService from 'services/stateStorageService';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import Flex from 'components/common/Flex';
import Button from 'components/common/Button';
import CheckBoxInput from 'components/common/CheckboxInput';
import QuestionMark from 'components/common/Marks/QuestionMark';
import AppIcon from 'components/common/AppIcon';
import DangerousGoodsTooltip from 'components/tooltips/DangerousGoodsTooltip';
import DomesticDangerousGoodsTooltip from 'components/tooltips/DomesticDangerousGoodsTooltip';

import DomesticRates from 'components/common/DomesticRates';
import RateItem from 'components/common/DomesticRates/components/RateItem';

import {secondaryFont, mediaQueries} from 'styles/shared';

const StyledInitialSection = styled.div`
  padding: 2.6rem 2.6rem 4rem;
`;

const StyledRow = styled(Flex)`
  margin-top: 1.3rem;

  @media ${mediaQueries.mobileMax} {
    flex-direction: column;
  }
`;

const StyledCheckboxes = styled(Flex)`
  @media ${mediaQueries.mobileMax} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3.7rem;
  }
`;

const StyledDomesticDangerousText = styled.div`
  margin: 0 11.4rem 0 0.8rem;
  ${secondaryFont};

  @media ${mediaQueries.mobileMax} {
    padding: 1rem 0;

    & br {
      display: none;
    }
  }
`;

interface Props {
  standaloneQuote: StandaloneQuote;
  isDomestic: boolean;
  onChange: (innerObjectName: string | null, field: string, value: boolean) => void;
  getQuoteAction: () => void;
}

function QuoteSection({standaloneQuote, isDomestic, onChange, getQuoteAction}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const rates = useSelector((state: AppState) => state.quote.currentRates);
  const user = useSelector((state: AppState) => state.user.current as User);
  const isQuickAccessAuth = useSelector((state: AppState) => state.common.isQuickAccessAuth);

  const [lowerPriceRateId, setLowerPriceRateId] = useState<string>('');
  const [fastestDeliveryRateId, setFastestDeliveryRateId] = useState<string>('');

  useEffect(() => {
    const lowerPriceId = rateServie.getRateIdWithLowerPrice(rates);
    const fastestDelivery = minBy(rates, rate => rate.estimatedDelivery);

    setLowerPriceRateId(lowerPriceId || '');
    setFastestDeliveryRateId(fastestDelivery?.id || '');
  }, [rates]);

  function onToggle(field, value) {
    onChange(null, field, value);
  }

  async function continueShipping(rate: Rate) {
    // Allows for selected rate to carry over into prepare-shipment page
    setRateOnStandaloneQuote(rate);

    if (user.isGuest || !user.active || (user.suspended && !user.whitelisted)) {
      const draft = entities.draft.mapFromStandaloneQuote(standaloneQuote);

      stateStorageService.setUnsavedDraft(draft);

      if (user.isGuest) {
        dispatch(commonActions.toggleGuestAuthModal(true, PATH.PREPARE_SHIPMENT));

        return;
      }

      if (!user.active) {
        dispatch(commonActions.infoAction(uiHelper.getInactiveUserNotice()));
      } else if (user.suspended) {
        dispatch(commonActions.infoAction(uiHelper.getSuspendedUserNotice()));
      }

      return;
    }

    if (isQuickAccessAuth) {
      const draft = entities.draft.mapFromStandaloneQuote(standaloneQuote);

      stateStorageService.setUnsavedDraft(draft);

      dispatch(commonActions.togglePasswordProtectedModal(true, PATH.PREPARE_SHIPMENT));

      return;
    }

    await createDraftFromQuote();
  }

  async function createDraftFromQuote() {
    const draft = entities.draft.mapFromStandaloneQuote(standaloneQuote);

    await dispatch(draftActions.createDraftFromQuote(draft));

    history.push(PATH.PREPARE_SHIPMENT);
  }

  function setRateOnStandaloneQuote(rate: Rate) {
    standaloneQuote.carrierProduct = rate.carrierProduct;
  }

  function renderInitialSection() {
    const isDocument = standaloneQuote.isDocument;
    const dangerousGoodsTooltip: JSX.Element = isDomestic ? DomesticDangerousGoodsTooltip() : DangerousGoodsTooltip();

    return (
      <Container withoutPadding>
        <StyledInitialSection>
          <FormHeader>Quote</FormHeader>

          <StyledRow justify="space-between" align="center">
            <StyledCheckboxes align="center">
              <Flex align="center">
                {(isDocument) ? (
                  <>
                    <AppIcon name="bulb" />

                    <StyledDomesticDangerousText>
                      Shipping <strong>Dangerous Goods</strong> & <br /> <strong>Alcohol</strong> as a document is
                      prohibited!
                    </StyledDomesticDangerousText>
                  </>
                ) : (
                  <>
                    <CheckBoxInput
                      name="hasDangerousGoods"
                      label="Shipment contains Dangerous Goods"
                      value={standaloneQuote.hasDangerousGoods}
                      onToggle={onToggle}
                    />

                    <QuestionMark id="dangerous-goods-tooltip" tooltip={dangerousGoodsTooltip} placement="top" />
                  </>
                )}
              </Flex>
            </StyledCheckboxes>

            <Button onClick={getQuoteAction}>Get A Quote</Button>
          </StyledRow>
        </StyledInitialSection>
      </Container>
    );
  }

  function renderQuoteSection() {
    if (isDomestic)
      return (
        <DomesticRates
          rates={rates}
          rateIdWithLowerPrice={lowerPriceRateId}
          continueShipping={continueShipping}
          isStandaloneQuote
        />
      );

    return (
      <>
        {rates.map(rate => {
          const id = rate.id;

          const isLowerPrice = lowerPriceRateId === id;
          const isFastestDelivery = fastestDeliveryRateId === id;

          return (
            <RateItem
              key={id}
              rate={rate}
              isDomestic={isDomestic}
              continueShipping={continueShipping}
              isLowerPrice={isLowerPrice}
              isFastestDelivery={isFastestDelivery}
            />
          );
        })}
      </>
    );
  }

  function render() {
    if (isEmpty(rates)) return renderInitialSection();

    return renderQuoteSection();
  }

  return render();
}

export default QuoteSection;

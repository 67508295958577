import {cloneDeep} from 'lodash';

import entities from 'domain/entities';

const TYPE = {
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  STRING: 'STRING',
  JSON: 'JSON'
};

const KEYS = {
  UNSAVED_DRAFT: 'unsaved_draft',
  REQUEST_ID: 'request_id',
  SAVED_USER_SEARCH_STRING: 'saved_user_search_string', // Used on page refresh in UsersListPage
  HOUSE_ACCOUNT: 'house_account',
  CREATED_BY_CSR: 'created_by_csr'
};

let cache = {};

export default {
  getUnsavedDraft,
  setUnsavedDraft,
  getRequestId,
  setRequestId,
  getSavedUserSearchString,
  setSavedUserSearchString,
  clearSavedUserSearchString,
  clearUnsavedDraft,
  getOptions,
  setOptions,
  tutorialWasDisplayed,
  setTutorialWasDisplayed,
  getHouseAccountIsUsed,
  setHouseAccountIsUsed,
  isCreatedByCSR,
  toggleCreatedByCSR
};

function getUnsavedDraft() {
  let unsavedDraft = localStorage.getItem(KEYS.UNSAVED_DRAFT);

  if (unsavedDraft) return entities.draft.parse(unsavedDraft);

  return null;
}

function setUnsavedDraft(obj) {
  localStorage.setItem(KEYS.UNSAVED_DRAFT, JSON.stringify(obj));
}

function getRequestId() {
  return localStorage.getItem(KEYS.REQUEST_ID);
}

function setRequestId(requestId) {
  return localStorage.setItem(KEYS.REQUEST_ID, requestId);
}

function getSavedUserSearchString(): string | null {
  return localStorage.getItem(KEYS.SAVED_USER_SEARCH_STRING);
}

function setSavedUserSearchString(searchString: string) {
  return localStorage.setItem(KEYS.SAVED_USER_SEARCH_STRING, searchString);
}

function clearSavedUserSearchString() {
  localStorage.removeItem(KEYS.SAVED_USER_SEARCH_STRING);
}

function clearUnsavedDraft() {
  localStorage.removeItem(KEYS.UNSAVED_DRAFT);
}

function getOptions(key) {
  return getValue(key, TYPE.JSON);
}

function setOptions(key, options) {
  setValue(key, options, 20 * 60 * 1000); // 20 minutes
}

let getTutorialStorageKey = tutorial => {
  return `tutorial_${tutorial}_displayed`;
};

function tutorialWasDisplayed(tutorial) {
  let value = storageGet(getTutorialStorageKey(tutorial));

  return value ? true : false;
}

function setTutorialWasDisplayed(tutorial) {
  storageSet(getTutorialStorageKey(tutorial), true);
}

function getHouseAccountIsUsed(csrId, userId) {
  let key = getHouseAccountKey(csrId, userId);
  return localStorage.getItem(key);
}

function setHouseAccountIsUsed(csrId, userId, isUsed) {
  let key = getHouseAccountKey(csrId, userId);

  if (isUsed) {
    localStorage.setItem(key, 'true');
  } else {
    localStorage.removeItem(key);
  }
}

function isCreatedByCSR() {
  const createdByMe = localStorage.getItem(KEYS.CREATED_BY_CSR);

  if (createdByMe) return true;

  return false;
}

function toggleCreatedByCSR(createdByMe) {
  if (createdByMe) {
    localStorage.setItem(KEYS.CREATED_BY_CSR, createdByMe);
  } else {
    localStorage.removeItem(KEYS.CREATED_BY_CSR);
  }
}

//helper mehods

function getValue(key, type, defaultValue = false) {
  if (cache[key]) {
    let cacheValue = cache[key];

    if (type === TYPE.JSON) {
      return cloneDeep(cacheValue);
    }

    return cacheValue;
  }

  let value = type === TYPE.JSON ? storageGetJson(key) : storageGet(key);

  if (!defaultValue) {
    switch (type) {
      case TYPE.BOOLEAN:
        defaultValue = false;
        break;
      default:
    }
  }

  if (!value) return defaultValue;

  switch (type) {
    case TYPE.BOOLEAN:
      value = value === 'true' ? true : false;
      break;

    case TYPE.NUMBER:
      value = Number(value);
      break;

    case TYPE.STRING:
    case TYPE.JSON:
      break;

    default:
      throw new Error(`Unsupported type ${type}`);
  }

  cache[key] = value;

  return value;
}

function setValue(key, value, expires?) {
  if (!expires) expires = 30 * 24 * 60 * 60 * 1000;

  storageSet(key, value, expires);

  cache = {};
}

//for compatibility with Cookies library
function storageGetJson(key) {
  return storageGet(key);
}

function storageGet(key) {
  let storeItem = localStorage.getItem(key);

  if (!storeItem) return null;

  let item = JSON.parse(storeItem);

  if (item.expires && Date.now() > item.expires) return null;

  return item.value;
}

function storageSet(key: string, value: any, expires?: number) {
  let storeValue = JSON.stringify({
    value,
    expires: expires ? Date.now() + expires : null
  });
  localStorage.setItem(key, storeValue);
}

function getHouseAccountKey(csrId, userId) {
  return `${KEYS.HOUSE_ACCOUNT}_${csrId}_${userId}`;
}

import React from 'react';
import styled from 'styled-components/macro';

import uiHelper from 'helpers/uiHelper';

import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import {colors, secondaryFont, buttonLeftMargin, buttonLeftRightMargin} from 'styles/shared';

const StyledIssueBody = styled.div`
  height: 15.4rem;
  background-color: ${colors.red_light};
  color: ${colors.darkBlue_main};
  display: flex;
  padding: 1.8rem 2.6rem;
`;

const StyledFirstColumn = styled.div`
  padding-right: 1.5rem;
  border-right: 0.1rem solid ${colors.red_main};
  max-width: 18.5rem;
  margin-right: 1.6rem;
`;

const StyledSecondColumn = styled.div`
  margin-bottom: 1rem;
  width: 83rem;
`;

const StyledTitle = styled.div`
  ${secondaryFont};
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const StyledText = styled.div`
  ${secondaryFont};
  column-count: 2;
  column-gap: 3rem;
  column-width: 40rem;
`;

const StyledIssueFooter = styled(Flex)`
  padding: 2rem;
  background-color: ${colors.red_lighter};
  border-bottom: 0.1rem solid ${colors.red_main};
`;

interface Props {
  issue: any;
}

function IssueDetails({issue}: Props) {
  //Fix-API: get issue details from issue or from API call?
  const issueTitle = 'Your shipment has been held up at customs!';

  const issueText =
    '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."';

  return (
    <>
      <StyledIssueBody>
        <StyledFirstColumn>
          <StyledTitle>Issue:</StyledTitle>
          <StyledTitle>{issueTitle}</StyledTitle>
        </StyledFirstColumn>

        <StyledSecondColumn>
          <StyledTitle>DHL Message</StyledTitle>
          <StyledText>{issueText}</StyledText>
        </StyledSecondColumn>
      </StyledIssueBody>

      <StyledIssueFooter justify="flex-end">
        <Button type="secondary" theme="error" margin={buttonLeftRightMargin} onClick={uiHelper.notImplemented}>
          Tracking history
        </Button>

        <Button theme="error" margin={buttonLeftMargin} onClick={uiHelper.notImplemented}>
          Customer support
        </Button>
      </StyledIssueFooter>
    </>
  );
}

export default IssueDetails;

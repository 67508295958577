import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="37" fill="none" viewBox="0 0 35 37">
      <circle cx="18.92" cy="14.728" r="8.5" stroke={colors.darkBlue_main} transform="rotate(45 18.92 14.728)"></circle>
      <path stroke={colors.darkBlue_main} strokeLinecap="round" d="M23.163 18.97a6 6 0 000-8.485"></path>
      <path
        stroke={colors.darkBlue_main}
        d="M11.85 19.678l2.12 2.121-8.131 8.132a1.5 1.5 0 01-2.122-2.122l8.132-8.131z"></path>
      <path
        stroke={colors.darkBlue_main}
        strokeLinecap="round"
        d="M12 6V3a2 2 0 012-2h18a2 2 0 012 2v28a2 2 0 01-2 2H14a2 2 0 01-2-2v-5"></path>
      <rect width="7" height="1" x="25" y="5" fill={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="3" height="1" x="29" y="9" fill={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="3" height="1" x="29" y="17" fill={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="3" height="1" x="29" y="13" fill={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="5" height="1" x="27" y="21" fill={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="9" height="1" x="15.001" y="25" fill={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="9" height="1" x="15.001" y="29" fill={colors.darkBlue_main} rx="0.5"></rect>
      <rect width="5" height="4" x="26.5" y="25.5" stroke={colors.darkBlue_main} rx="0.5"></rect>
    </svg>
  );
}

export default Icon;

import React from 'react';
import styled, {css} from 'styled-components/macro';

import validationHelper from 'helpers/validationHelper';

import Flex from 'components/common/Flex';
import FormLabel from './FormLabel';
import InputError from 'components/common/InputComponents/InputError';
import QuestionMark from './Marks/QuestionMark';
import ActionLink from './ActionLink';

import {colors} from 'styles/shared';
import inputStyles from 'styles/customization/input';

const StyledLabelContainer = styled(Flex)`
  margin-bottom: 0.8rem;
`;

const StyledInputContainer = styled.div`
  ${inputStyles.common.inputContainer};
`;

const StyledInput = styled.input`
  ${inputStyles.searchInput.inputField};

  ${props =>
    props.error &&
    css`
      border-color: ${colors.red_main};
    `}
`;

interface Props extends BaseProps {
  name: string;
  label: string;
  onChange: OnChangeHandler;
  placeholder: string;
  tooltip: string | JSX.Element;
  value: string;
  error?: string | ValidationError[];
  type: string;
  required: boolean;
  actionLink?: ActionLink;
  onKeyDown?: () => void;
}

SearchInput.defaultProps = {
  required: false,
  tooltip: '',
  actionLink: {
    title: '',
    action: null
  },
  className: ''
};

function SearchInput({
  name,
  label,
  onChange,
  placeholder,
  value,
  error,
  required,
  tooltip,
  actionLink,
  className,
  onKeyDown
}: Props) {
  const inputOnChange = e => {
    onChange(e.target.name, e.target.value);
  };

  const inputError: string = validationHelper.getInputError(error);

  return (
    <StyledInputContainer className={className}>
      {label && (
        <StyledLabelContainer justify="space-between">
          <Flex align="center">
            <FormLabel required={required}>{label}</FormLabel>
            {tooltip && <QuestionMark id={`${name}-tooltip`} tooltip={tooltip} />}
          </Flex>

          {actionLink && actionLink.title && <ActionLink title={actionLink.title} onClick={actionLink.action} />}
        </StyledLabelContainer>
      )}

      <StyledInput
        type="text"
        name={name}
        placeholder={placeholder}
        value={value ? value : ''}
        onChange={inputOnChange}
        onKeyDown={onKeyDown}
        error={inputError}
        autoComplete="nope"
      />

      {inputError && <InputError>{inputError}</InputError>}
    </StyledInputContainer>
  );
}

export default SearchInput;

import React from 'react';

function ExportTypeTooltip() {
  return (
    <span>
      If the commodity you are shipping is staying in the <br />
      destination country; it is considered <strong>Permanent</strong>. <br />
      If the commodity you are shipping will only temporarily <br />
      be in the destination country, before being returned to <br />
      origin or sent to another country, it is considered <br /> <strong>Temporary</strong>.
    </span>
  );
}

export default ExportTypeTooltip;

const SLIDER = {
  DOMESTIC_MIN_TIME: 8 * 60, // 08:00 AM
  DHL_MIN_TIME: 10 * 60, // 10:00 AM
  MAX_TIME: 19 * 60, // 07:00 PM
  INITIAL_END_TIME: 14 * 60, // 02:00 PM
  STEP: 15, //15 min
  TICK_INTERVAL: 60, //60 min
  DHL_MIN_INTERVAL: 90, //90 min
  UPS_MIN_INTERVAL: 180, //180 min
  FED_EX_MIN_INTERVAL: 180, //180 min
  AFTERNOON_TIME: 12 * 60, // 12:00 PM
  MIDNIGHT_TIME: 24 * 60 // 12:00 AM
};

export default SLIDER;

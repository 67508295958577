import toastr from 'toastr';
import classnames from 'classnames';

import {InfoAction} from 'reducers/commonReducer';

import CARRIER from 'constants/carriers';

// Time in milliseconds that the toast should be displayed. Set timeOut and extendedTimeOut to 0 to make it sticky.
const DEFAULT_TIMEOUT = 5000;
const ERROR_TIMEOUT = 15000;

const DEFAULT_POSITION = 'toast-bottom-left';
const INFO_POSITION = 'toast-top-center';

export default {
  showNotice,
  showError,
  notImplemented,
  logMessage,
  logError,
  logDataObject,
  getValidationErrorPopupParameters,
  getCannotReschedulePickupPopupParameters,
  getInactiveUserNotice,
  getSuspendedUserNotice
};

function showNotice(message: string, autoWidth = false) {
  // Position not honored if existing toasts are displayed (https://github.com/CodeSeven/toastr/issues/245)
  toastr.remove();

  toastr.options.positionClass = INFO_POSITION;
  toastr.options.preventDuplicates = true;
  toastr.options.timeOut = DEFAULT_TIMEOUT;
  toastr.options.escapeHtml = false;

  const iconClass = classnames({
    'toast-custom': true,
    'toast-auto-width': autoWidth
  });

  toastr.success(message, '', {iconClass});
}

let maxErrorNumber = 3;
let newErrorsAllowedInterval = 7000;
let errorTimes: number[] = [];

function showError(error: string) {
  let now = Date.now();

  errorTimes = errorTimes.filter(time => now - time < newErrorsAllowedInterval);

  if (errorTimes.length < maxErrorNumber) {
    errorTimes.push(now);
    toastr.options.positionClass = DEFAULT_POSITION;
    toastr.options.timeOut = ERROR_TIMEOUT;
    toastr.error(error);
  }
}

function notImplemented() {
  toastr.options.positionClass = DEFAULT_POSITION;
  toastr.options.timeOut = DEFAULT_TIMEOUT;
  toastr.warning('Coming Soon!', '', {});
}

function logMessage(message) {
  // eslint-disable-next-line
  console.log(message);
}

function logDataObject(data) {
  // eslint-disable-next-line
  console.log(JSON.stringify(data, null, 2));
}

function logError(message) {
  // eslint-disable-next-line
  console.error(message);
}

function getValidationErrorPopupParameters(validationError: ValidationError): InfoAction {
  return {
    text: validationError.message,
    type: validationError.isWarning ? 'info' : 'warning', // This is correct
    logToRollbar: true,
    close: () => {}
  }
}

function getCannotReschedulePickupPopupParameters(carrier?: string): InfoAction {
  let text = 'Please contact our customer service if you would like to reschedule your pickup.';

  if (carrier === CARRIER.UPS) {
    text = 'UPS requires you to cancel your pickup and create a new one rather than modify an existing one.';
  }

  return {
    type: 'info',
    text: text,
    logToRollbar: true,
    rollbarContext: 'Pickup Reschedule',
    close: () => null
  }
}

function getInactiveUserNotice(): InfoAction {
  let text = 'Your account has been inactivated due to non-payment. Please contact our customer service for help reactivating your account.';

  return {
    type: 'info',
    text: text,
    logToRollbar: true,
    rollbarContext: 'Inactive user notice',
    close: () => null
  }
}

function getSuspendedUserNotice(): InfoAction {
  let text = 'You have prepared the maximum allowed shipments for the day. A payment will be required on already prepared shipments, for additional shipment allowance. Please contact customer service for any questions regarding shipping limits, and to obtain help lifting this hold.';

  return {
    type: 'info',
    text: text,
    logToRollbar: true,
    rollbarContext: 'Suspended user notice',
    close: () => null
  }
}

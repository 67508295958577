import styled from 'styled-components/macro';

import TextInput from 'components/common/TextInput';
import Flex from 'components/common/Flex';
import FormHeader from 'components/common/FormHeader';
import PhoneNumberInput from 'components/common/PhoneNumberInput';

import {pageSubtitle} from 'styles/shared';

export const textInput = styled(TextInput)`
  margin-bottom: 0;
`;

export const phoneNumberInput = styled(PhoneNumberInput)`
  margin-bottom: 0;
`;

export const formContainer = styled.div`
  width: 49.4rem;
  margin-top: 3rem;
`;

export const title = styled(FormHeader)`
  margin-bottom: 3.2rem;
  text-align: center;
`;

export const subtitle = styled.div`
  ${pageSubtitle};
  max-width: auto;
  margin-bottom: 3rem;
`;

export const buttonRow = styled(Flex)`
  margin-top: 2rem;
`;

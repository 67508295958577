import React from 'react';
import styled from 'styled-components/macro';

import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import {errorInfoTitle, largeFont} from 'styles/shared';

const StyledContainer = styled(Flex)`
  max-width: 54.6rem;
  margin-top: 9.5rem;
  text-align: center;
`;

const StyledTitle = styled.div`
  ${errorInfoTitle};
  margin-bottom: 1rem;
`;

const StyledText = styled.div`
  ${largeFont};
  margin-bottom: 5rem;
`;

interface Props {
  title: string;
  text: string;
  buttonText: string;
  action: () => void;
}

function NoResults({title, text, buttonText, action}: Props) {
  return (
    <StyledContainer direction="column" align="center">
      <StyledTitle>{title}</StyledTitle>
      <StyledText>{text}</StyledText>
      <Button autoWidth onClick={action}>
        {buttonText}
      </Button>
    </StyledContainer>
  );
}

export default NoResults;

import styled, {css} from 'styled-components/macro';

import {colors, secondaryFont, mainBorder, mediaQueries} from 'styles/shared';

export const Table = styled.table`
  width: 100%;
`;

export const TRowHead = styled.tr`
  border-bottom: ${mainBorder};
  height: 4rem;
  text-align: left;
  ${secondaryFont}
  user-select: none;

  ${props =>
    props.topBorder &&
    css`
      border-top: ${mainBorder};
    `}

  @media ${mediaQueries.mobileMax} {
    display: none;
  }
`;

export const TRowBody = styled.tr`
  border-bottom: 0.1rem solid ${props => (props.attention ? colors.red_main : colors.darkBlue_lighter)};
  height: 8rem;
  text-align: left;
  ${secondaryFont}

  ${props =>
    props.attention &&
    css`
      background-color: ${colors.red_lighter};
    `};

  ${props =>
    props.success &&
    css`
      background-color: ${colors.green_lighter};
    `}

  ${props =>
    props.noBorder &&
    css`
      border-bottom: none;
    `};

  ${props =>
    props.checked &&
    css`
      background-color: ${props => (props.success ? colors.green_light : colors.grey_light)}};
    `}

  ${props =>
    props.newest &&
    css`
      background-color: ${colors.grey_light};
      border-bottom: 0.1rem solid ${colors.orange_main};
    `}

    ${props =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
`;

export const THeadCell = styled.th`
  color: ${colors.darkBlue_light};
  font-weight: normal;

  &:first-child {
    padding-left: ${props => (props.smallIndent ? '1.6rem' : '3rem')};
  }
`;

export const TBodyCell = styled.td`
  color: ${colors.darkBlue_main};

  &:first-child {
    padding-left: ${props => (props.smallIndent ? '0.8rem' : '3rem')};
  }

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `};

  ${props =>
    props.actions &&
    css`
      width: 1%;
    `};

  ${props =>
    props.maxWidth &&
    css`
      width: 15%;
    `};

  & p {
    margin-top: 1rem;
  }
`;

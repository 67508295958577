import React, {useState, Fragment} from 'react';
import {Row, Col} from 'components/bootstrap';
import {useSelector, useDispatch} from 'react-redux';

import {AppState} from 'reducers';
import quoteActions from 'actions/quoteActions';

import SECTION from 'constants/sections';
import FEATURE from 'constants/features';

import uiHelper from 'helpers/uiHelper';
import dateHelper from 'helpers/dateHelper';
import formatHelper from 'helpers/formatHelper';

import userInfoService from 'services/userInfoService';

import Container from 'components/common/Container';
import CheckBoxInput from 'components/common/CheckboxInput';
import Pagination from 'components/common/Pagination/SimplePagination';
import ActionsPopup from 'components/common/ActionsPopup';
import EmptyList from '../EmptyList';
import QuoteModal from './components/QuoteModal';
import ListHeader from '../ListHeader';

import ListContainerMobile from 'components/view_history/mobile/components/ListContainerMobile';
import QuotesListMobile from 'components/view_history/mobile/components/quotes/QuotesListMobile';
import PaginationMobile from 'components/view_history/mobile/components/PaginationMobile';

import * as Styled from 'styles/table';

interface Props {
  quotes: Quote[];
}

function Quotes({quotes}: Props) {
  const dispatch = useDispatch();

  const pagination = useSelector((state: AppState) => state.quote.pagination);

  const [checkedQuotes, setCheckedQuotes] = useState<string[]>([]);
  const [quoteToSee, setQuoteToSee] = useState<Quote | undefined>();

  const actions: DropdownAction[] = [
    {label: 'See Quote', icon: 'quote', action: openQuoteModal},
    {label: 'Delete Quote', icon: 'delete-shipment', action: uiHelper.notImplemented}
  ];

  function onCheckboxClick(checkedId) {
    let index = checkedQuotes.findIndex(id => id === checkedId);

    if (index === -1) {
      setCheckedQuotes(prevArray => [...prevArray, checkedId]);
    } else {
      setCheckedQuotes(checkedQuotes.filter(id => id !== checkedId));
    }
  }

  function openQuoteModal(quote) {
    setQuoteToSee(quote);
  }

  function closeQuoteModal() {
    setQuoteToSee(undefined);
  }

  function onPageChange(page) {
    dispatch(quoteActions.updateQuotesPageNumber(page));
  }

  function renderQuotes() {
    return (
      <Styled.Table>
        <thead>
          <Styled.TRowHead topBorder>
            {FEATURE.UNUSED_HISTORY_COLUMNS_VISIBLE && <Styled.THeadCell />}
            <Styled.THeadCell>Origin</Styled.THeadCell>
            <Styled.THeadCell>Destination</Styled.THeadCell>
            <Styled.THeadCell>Date created</Styled.THeadCell>
            <Styled.THeadCell />
          </Styled.TRowHead>
        </thead>

        <tbody>
          {quotes.map(quote => {
            const id = quote.id;

            let isChecked = checkedQuotes.find(i => i === id) ? true : false;

            return (
              <Fragment key={id}>
                <Styled.TRowBody checked={isChecked}>
                  {FEATURE.UNUSED_HISTORY_COLUMNS_VISIBLE && (
                    <Styled.TBodyCell>
                      <CheckBoxInput name={id} value={isChecked} onToggle={onCheckboxClick} />
                    </Styled.TBodyCell>
                  )}
                  <Styled.TBodyCell maxWidth>
                    {formatHelper.displayValue(quote.requestOriginCountryCode)}
                  </Styled.TBodyCell>
                  <Styled.TBodyCell maxWidth>
                    {formatHelper.displayValue(quote.requestDestinationCountryCode)}
                  </Styled.TBodyCell>
                  <Styled.TBodyCell>{dateHelper.displayShortDate(quote.createdOn)}</Styled.TBodyCell>
                  <Styled.TBodyCell actions>
                    <ActionsPopup actions={actions} activeItem={quote} />
                  </Styled.TBodyCell>
                </Styled.TRowBody>
              </Fragment>
            );
          })}
        </tbody>
      </Styled.Table>
    );
  }

  function renderEmptyList() {
    return (
      <EmptyList
        image="no-quotes.png"
        title="Looks like there are no Quotes!"
        subtitle="Select “Get a Quote” on the main menu above to see your discounted shipping rate."
      />
    );
  }

  function render() {
    const noQuotes = !quotes || quotes.length === 0;

    const quoteModalVisible = quoteToSee ? true : false;

    const isMobile = userInfoService.isMobile();

    if (isMobile) {
      return (
        <ListContainerMobile>
          <QuotesListMobile quotes={quotes} actions={actions} />

          {noQuotes && renderEmptyList()}

          <PaginationMobile label="Total Quotes" pagination={pagination} onChange={onPageChange} />

          {quoteToSee && <QuoteModal visible={quoteModalVisible} quote={quoteToSee} close={closeQuoteModal} />}
        </ListContainerMobile>
      );
    }

    return (
      <Container withoutPadding={true} id={SECTION.STATUS.QUOTES}>
        <Row>
          <Col sm={12}>
            <ListHeader>Quotes</ListHeader>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {renderQuotes()}
            {noQuotes && renderEmptyList()}
          </Col>
        </Row>

        <Pagination label="Total Quotes" pagination={pagination} bordered={noQuotes} onChange={onPageChange} />

        {quoteToSee && <QuoteModal visible={quoteModalVisible} quote={quoteToSee} close={closeQuoteModal} />}
      </Container>
    );
  }

  return render();
}

export default Quotes;

import React from 'react';
import styled from 'styled-components/macro';
import {Row, Col} from 'components/bootstrap';

import FormLabel from 'components/common/FormLabel';
import TextInput from 'components/common/TextInput';
import SelectInput from 'components/common/SelectInput/SelectInput';

const StyledAddressInput = styled(TextInput)`
  min-height: auto;
  margin: 0;
`;

const StyledCityInput = styled(TextInput)`
  margin-bottom: 0;
`;

const StyledSelectInput = styled(SelectInput)`
  margin-top: 0;
`;
interface Props {
  recipient: Recipient;
  countryList: Array<any>;
  onChange: any;
}

function RecipientSection({recipient, countryList, onChange}: Props) {
  function onAddressChange(field, value) {
    onChange('address', {...recipient.address, [field]: value});
  }

  return (
    <>
      <FormLabel>Recipient Information</FormLabel>

      <TextInput
        name="name"
        label="Recipient Name"
        required
        placeholder="Write recipient name"
        value={recipient.name}
        onChange={onChange}
      />

      <TextInput
        name="companyName"
        label="Recipient Company Name"
        required
        placeholder="Recipient Company Name"
        value={recipient.companyName}
        onChange={onChange}
      />

      <StyledAddressInput
        name="addressLine1"
        label="Recipient Address"
        required
        placeholder="Address Line 1"
        value={recipient.address.addressLine1}
        onChange={onAddressChange}
      />

      <StyledAddressInput
        name="addressLine2"
        placeholder="Address Line 2"
        value={recipient.address.addressLine2}
        onChange={onAddressChange}
      />

      <StyledAddressInput
        name="addressLine3"
        placeholder="Address Line 3"
        value={recipient.address.addressLine3}
        onChange={onAddressChange}
      />

      <StyledCityInput
        name="city"
        label="Recipient City"
        required
        placeholder="Recipient City"
        value={recipient.city}
        onChange={onChange}
      />

      <Row>
        <Col lg={6}>
          <TextInput
            name="state"
            label="State"
            required
            placeholder="State"
            value={recipient.state}
            onChange={onChange}
          />
        </Col>
        <Col lg={6}>
          <TextInput
            name="zipCode"
            label="Zip Code"
            required
            placeholder="Zip Code"
            value={recipient.zipCode}
            onChange={onChange}
          />
        </Col>
      </Row>

      <StyledSelectInput
        name="country"
        label="Country"
        required={true}
        options={countryList}
        value={recipient.country}
        onChange={onChange}
      />
    </>
  );
}

export default RecipientSection;

import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';
import userActions from 'actions/userActions';
import commonActions from 'actions/commonActions';

import FEATURE from 'constants/features';

import config from 'config';
import uiHelper from 'helpers/uiHelper';
import navigationHelper from 'helpers/navigationHelper';

import stateStorageService from 'services/stateStorageService';

import Flex from 'components/common/Flex';
import UsersSearchPanel from './components/UsersSearchPanel';
import UserCard from './components/UserCard';
import NoResults from './components/NoResults';
import PasswordRecoveryModal from './components/PasswordRecoveryModal';
import ChangePasswordModal from './components/ChangePasswordModal';

import extras from './extras';

import {maxUsersContainerWidth} from 'styles/shared';

const StyledContainer = styled.div`
  width: 100%;
`;

const StyledUsersList = styled(Flex)`
  width: calc(${maxUsersContainerWidth} + 3rem);
  flex-wrap: wrap;
`;

function UsersListPage() {
  const dispatch = useDispatch();

  const savedUserSearchString = stateStorageService.getSavedUserSearchString() ?? '';
  const emptySavedUserSearchString = 'emptySavedSearchString';

  const users = useSelector((state: AppState) => state.user.list);

  const [searchStr, setSearchStr] = useState(savedUserSearchString === emptySavedUserSearchString ? '' : savedUserSearchString);
  const [noSearchResults, setNoSearchResults] = useState(false);
  const [userNotImported, setUserNotImported] = useState(false);
  const [userToRecovery, setUserToRecovery] = useState<User>();
  const [userToChangePassword, setUserToChangePassword] = useState<User>();

  function getActions(user: User) : DropdownAction[] {
    const actions = [
      {
        label: 'Send Recovery Email',
        action: passwordRecoveryAction
      },
      {
        label: 'View Log',
        action: viewLogAction,
        hidden: !user?.isSuperAdmin
      },
      {
        label: 'Change Password',
        action: changePasswordAction,
        hidden: !FEATURE.CHANGE_USER_PASSWORD_BY_CSR
      },
      {
        label: user?.active ? 'Inactivate Client' : 'Activate Client',
        action: (u: User) => toggleUserActiveStatus(u)
      },
      {
        label: user?.suspended ? 'Unsuspend Prep' : 'Suspend Prep',
        action: (u: User) => toggleUserSuspendedStatus(u)
      },
      {
        label: user?.whitelisted ? 'Remove from Whitelist' : 'Whitelist (Prep Limits)',
        action: (u: User) => toggleUserWhitelistedStatus(u)
      }
    ];

    return actions.filter(action => !action.hidden);
  }

  useEffect(() => {
    if (!savedUserSearchString) {
      getUsers();
      return;
    }

    stateStorageService.clearSavedUserSearchString();
    searchUsers();
    uiHelper.showNotice(`You have successfully updated the client! If you don't see the client below after waiting 10 seconds for it to load, try updating your search term.`);
  }, []);

  async function getUsers() {
    return await dispatch(userActions.loadUsers(String(searchStr).trim()));
  }

  async function searchUsers() {
    const anyUsers = await getUsers();

    if (userNotImported) setUserNotImported(false);

    if (searchStr && !anyUsers) {
      setNoSearchResults(true);
    } else {
      setNoSearchResults(false);
    }
  }

  function onSearchChange(field, value) {
    setSearchStr(value);
  }

  async function importUserAccount() {
    if (!searchStr) return;

    await refreshUserAndUpdateUI('import', searchStr);
  }

  function goToBillPay() {
    window.open(config.portalPayment, '_blank');
  }

  async function syncUserAccount(user: User) {
    const emailOrId = user.billingInformation.osClientId || user.email;

    await refreshUserAndUpdateUI('sync', emailOrId);
  }

  async function toggleUserActiveStatus(user: User) {
    const id = user.id;

    await refreshUserAndUpdateUI('toggleUserActiveStatus', id, !user.active, user.suspended, user.whitelisted);
  }

  async function toggleUserSuspendedStatus(user: User) {
    const id = user.id;

    await refreshUserAndUpdateUI('toggleUserSuspendedStatus', id, user.active, !user.suspended, user.whitelisted);
  }

  async function toggleUserWhitelistedStatus(user: User) {
    const id = user.id;

    await refreshUserAndUpdateUI('toggleUserWhitelistedStatus', id, user.active, user.suspended, !user.whitelisted);
  }

  async function refreshUserAndUpdateUI(
    type: 'import' | 'sync' | 'toggleUserActiveStatus' | 'toggleUserSuspendedStatus'| 'toggleUserWhitelistedStatus',
    emailOrId: string,
    isActive?: boolean,
    isSuspended?: boolean,
    isWhitelisted?: boolean
  ) {
    const response: any = await dispatch(userActions.refreshUserData(
      String(emailOrId).trim(),
      isActive,
      isSuspended,
      isWhitelisted
    ));
    const isSuccess = response as boolean;

    if (isSuccess) {
      stateStorageService.setSavedUserSearchString(searchStr || emptySavedUserSearchString);
      window.location.reload();
    } else if (type === 'sync') {
      uiHelper.showNotice('Something went wrong with the sync. Please try again!');
    } else if (type === 'toggleUserActiveStatus') {
      uiHelper.showNotice(`Could not ${isActive ? 'activate' : 'inactivate'} user. Please try again!`)
    } else if (type === 'toggleUserSuspendedStatus') {
      uiHelper.showNotice(`Could not ${isSuspended ? 'suspend' : 'unsuspend'} user. Please try again!`)
    } else if (type === 'toggleUserWhitelistedStatus') {
      uiHelper.showNotice(`Could not ${isWhitelisted ? 'whitelist user.' : 'remove user from the whitelist.'} Please try again!`)
    } else {
      setNoSearchResults(false);
      setUserNotImported(true);
    }
  }

  function passwordRecoveryAction(user: User) {
    setUserToRecovery(user);
  }

  function closeRecoveryModal() {
    setUserToRecovery(undefined);
  }

  async function sendRecoveryEmail() {
    if (!userToRecovery) return;

    const response: any = await dispatch(
      userActions.resetPassword(userToRecovery.email || userToRecovery?.billingInformation?.osClientId)
    );

    setUserToRecovery(undefined);

    if (response?.success) {
      dispatch(
        commonActions.infoAction({
          text:
            'Email sent! Sometimes the client’s email provider rejects our emails. You can message #ospt in Slack to have a developer check if the server delivered the email to their inbox.',
          type: 'warning',
          close: () => {}
        })
      );
      return;
    }

    const errorMessage = response?.message
      ? response.message
      : 'Something went wrong. Please reload the page and try again.';

    dispatch(
      commonActions.infoAction({
        type: 'error',
        text: errorMessage,
        close: () => null
      })
    );
  }

  function viewLogAction(user: User) {
    const url = navigationHelper.getUserInspectUrl(user?.id);
    window.open(url, '_blank');
  }

  function changePasswordAction(user: User) {
    setUserToChangePassword(user);
  }

  function closeChangePasswordModal() {
    setUserToChangePassword(undefined);
  }

  async function changePassword(password: string) {
    const response: any = await dispatch(userActions.changePassword(password));

    const receiverInfo = extras.getReceiverInfo(userToChangePassword);

    closeChangePasswordModal();

    if (response?.success) {
      uiHelper.showNotice(`You have successfully changed password for ${receiverInfo}`);
      return;
    }

    dispatch(
      commonActions.infoAction({
        type: 'error',
        text: response?.message || 'Could not reset password. Please try to reset user password again.',
        close: () => null
      })
    );
  }

  function render() {
    const isPasswordRecoveryModalVisible = userToRecovery ? true : false;
    const isChangePasswordModalVisible = userToChangePassword ? true : false;

    return (
      <StyledContainer>
        <UsersSearchPanel searchStr={searchStr} onChange={onSearchChange} onSearch={searchUsers} />

        <Flex justify="center">
          {users.length > 0 && (
            <StyledUsersList>
              {users.map((user: User) => {
                return <UserCard key={user.id} user={user} actions={getActions(user)} onRefresh={syncUserAccount} />;
              })}
            </StyledUsersList>
          )}

          {noSearchResults && (
            <NoResults
              title="Looks like this user is not yet imported!"
              text="Please search for either the client’s CID or their email address. If you still can’t find them, then try importing the user’s account from BillPay. You must have either the client’s CID or email from BillPay entered as the search term or the import will not work. You can also import multiple accounts at the same time if you enter in multiple CIDs or email addresses, each separated by a single space."
              buttonText="Import User Account"
              action={importUserAccount}
            />
          )}

          {userNotImported && (
            <NoResults
              title="Unfortunately, this user does not exist in BillPay."
              text="You can go to BillPay and create a new user profile there. Then search for their CID or email address here to import the account."
              buttonText="Go to BillPay"
              action={goToBillPay}
            />
          )}
        </Flex>

        {isPasswordRecoveryModalVisible && userToRecovery && (
          <PasswordRecoveryModal
            visible={isPasswordRecoveryModalVisible}
            user={userToRecovery}
            close={closeRecoveryModal}
            onSend={sendRecoveryEmail}
          />
        )}

        {isChangePasswordModalVisible && userToChangePassword && (
          <ChangePasswordModal
            visible={isChangePasswordModalVisible}
            user={userToChangePassword}
            close={closeChangePasswordModal}
            onChangePassword={changePassword}
          />
        )}
      </StyledContainer>
    );
  }

  return render();
}

export default UsersListPage;

import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';

import mailHelper from 'helpers/mailHelper';
import authService from 'services/authService';
import config from 'config';

import AppIcon from 'components/common/AppIcon';
import HoverIcon from 'components/common/AppIcon/HoverIcon';

import {colors, secondaryFont, boxShadow, mediaQueries, helpWidgetHeight} from 'styles/shared';
import commonActions from 'actions/commonActions';

const BORDER_RADIUS = '1.8rem';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.white};
  box-shadow: ${boxShadow};
  border-top-left-radius: ${BORDER_RADIUS};
  border-top-right-radius: ${BORDER_RADIUS};
  height: ${helpWidgetHeight};
  width: 40rem;
  min-height: ${helpWidgetHeight};
  min-width: 40rem;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1030;

  @media ${mediaQueries.mobileMax} {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    min-width: auto;
  }
`;

const StyledText = styled.div`
  ${secondaryFont};
  flex-grow: 4;
  margin: 2.6rem;
`;

const StyledIconBox = styled.div`
  display: flex;
  align-items: center;
  height: 4.4rem;
  width: 4.4rem;
  min-height: 4.4rem;
  min-width: 4.4rem;
  margin: 1.2rem 1.5rem;
  cursor: pointer;
`;

const StyledCallingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.orange_main};
  border-radius: 7rem;
  width: 21.3rem;
  height: 4.4rem;
  min-width: 21.3rem;
  min-height: 4.4rem;
  margin: 1.2rem;
  padding: 1.6rem;
  cursor: pointer;
`;

const StyledCallingText = styled.div`
  ${secondaryFont};
  color: ${colors.white};
`;

function HelpWidget() {
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user.current);

  const [isCalling, setIsCalling] = useState(false);

  useEffect(() => {
    if (config.isDevLocal) return;

    let Intercom = (window as any).Intercom;

    if (!Intercom) return;

    const isQuickAccessToken = authService.getQuickAccessToken() ? true : false;

    let intercomSettings = {
      app_id: config.intercom.appId,
      custom_launcher_selector: '#intercom-launcher',
      hide_default_launcher: true
    };

    if (user && !isQuickAccessToken && !user?.isGuest) {
      const userIntercomSettings = {
        user_id: user?.id,
        email: user?.email
      };

      intercomSettings = Object.assign(intercomSettings, userIntercomSettings);
    }

    Intercom('boot', intercomSettings);

    Intercom('onUnreadCountChange', unreadCount => {
      Intercom('hide');
    });
  }, [user]);

  function toggleIsCalling() {
    setIsCalling(!isCalling);
  }

  function startChat() {
    dispatch(commonActions.toggleIntercomChat());
  }

  const supportLink = mailHelper.getHelpEmailLink();

  return (
    <StyledContainer>
      <StyledText>Need help?</StyledText>
      {isCalling ? (
        <StyledCallingBox onClick={toggleIsCalling}>
          <StyledCallingText>Call: {config.supportPhone.label}</StyledCallingText>
          <AppIcon name="close" color={colors.white} />
        </StyledCallingBox>
      ) : (
        <>
          <StyledIconBox onClick={toggleIsCalling}>
            <HoverIcon name="phone" nameHover="phone-hover" namePressed="phone-pressed" />
          </StyledIconBox>

          <StyledIconBox>
            <a href={supportLink} target="_blank" rel="noopener noreferrer">
              <HoverIcon name="mail" nameHover="mail-hover" namePressed="mail-pressed" />
            </a>
          </StyledIconBox>

          <StyledIconBox id="intercom-launcher" onClick={startChat}>
            <HoverIcon name="chat" nameHover="chat-hover" namePressed="chat-pressed" />
          </StyledIconBox>
        </>
      )}
    </StyledContainer>
  );
}

export default HelpWidget;

import CARRIER from './carriers';

const PICKUP_FORMAT = {
  [CARRIER.DHL]: {
    NOTES: {
      MAX_LENGTH: 80
    },
    COMPANY_NAME: {
      MAX_LENGTH: 60
    },
    CONTACT_NAME: {
      MAX_LENGTH: 35
    },
    ADDRESS_LINE: {
      MAX_LENGTH: 45
    }
  },
  [CARRIER.UPS]: {
    NOTES: {
      MAX_LENGTH: 57
    },
    COMPANY_NAME: {
      MAX_LENGTH: 27
    },
    CONTACT_NAME: {
      MAX_LENGTH: 22
    },
    ADDRESS_LINE: {
      MAX_LENGTH: 35,
      TOTAL_MAX_LENGTH: 73
    }
  },
  [CARRIER.FED_EX]: {
    NOTES: {
      MAX_LENGTH: 57
    },
    COMPANY_NAME: {
      MAX_LENGTH: 27
    },
    CONTACT_NAME: {
      MAX_LENGTH: 22
    },
    ADDRESS_LINE: {
      MAX_LENGTH: 35,
      TOTAL_MAX_LENGTH: 73
    }
  }
};

export default PICKUP_FORMAT;

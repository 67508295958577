import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import styled, {css} from 'styled-components/macro';

import commonActions from 'actions/commonActions';

import authService from 'services/authService';

import FormHeader from 'components/common/FormHeader';
import Button, {ButtonTheme} from 'components/common/Button';
import Flex from 'components/common/Flex';

import * as Styled from 'styles/modal';
import {secondaryFont, buttonSideMargin, buttonLeftRightMargin} from 'styles/shared';

const StyledHeader = styled.div`
  text-align: center;
`;

const StyledText = styled.div`
  ${secondaryFont};
  margin: 1rem 0 3rem;
  text-align: center;

  span {
    font-weight: bold;
  }
`;

const StyledFooter = styled.div`
  display: flex;

  ${props =>
    props.centered &&
    css`
      flex-direction: column;
      align-items: center;
    `}
`;

const StyledConfirmedSection = styled(Flex)`
  margin-top: 3rem;
`;

interface Props {
  visible: boolean;
  isExport: boolean;
  close: () => void;
}

function HouseAccountWarningModal({visible, isExport, close}: Props) {
  const dispatch = useDispatch();

  const [isConfirmed, setIsConfirmed] = useState(false);

  function useHouseAccount() {
    authService.toggleHouseAccount(true);
    dispatch(commonActions.toggleHouseAccountUsage(true));
    close();
  }

  function render() {
    const label = isExport ? 'Export number' : 'Import number';

    const buttonTheme: ButtonTheme = isConfirmed ? 'success' : 'main';

    return (
      <Styled.CustomModal show={visible} backdrop="static" width="68.2rem" padding="4rem 4rem 3rem" onHide={close}>
        <StyledHeader>
          <FormHeader>Warning!</FormHeader>
        </StyledHeader>

        <StyledText>
          This user has no <span>{label}</span> on file, or their status is something other than <span>Open</span>. You
          should use the <span>Use House Account</span> number to rate, schedule pickups, and prep this Shipment.
        </StyledText>

        <StyledFooter centered={isConfirmed}>
          {!isConfirmed && (
            <Button type="secondary" autoWidth margin={`0 ${buttonSideMargin} 0 6rem`} onClick={close}>
              Cancel
            </Button>
          )}

          <Button theme={buttonTheme} autoWidth margin={buttonLeftRightMargin} onClick={() => setIsConfirmed(true)}>
            Use House Account
          </Button>

          {isConfirmed && (
            <StyledConfirmedSection direction="column" align="center">
              <StyledText>
                You have successfully selected <span>House Account</span>. You can now close this window & continue with
                the preparation of the Shipment.
              </StyledText>

              <Button autoWidth onClick={useHouseAccount}>
                Close & Continue
              </Button>
            </StyledConfirmedSection>
          )}
        </StyledFooter>
      </Styled.CustomModal>
    );
  }

  return render();
}

export default HouseAccountWarningModal;

import React, {useRef} from 'react';
import styled from 'styled-components/macro';
import {Row, Col} from 'components/bootstrap';
import {useDispatch} from 'react-redux';

import addressActions from 'actions/addressActions';

import heapAnalyticsService from 'services/heapAnalyticsService';

import Button from 'components/common/Button';
import SearchAutosuggest from './components/SearchAutosuggest';
import QuestionMark from 'components/common/Marks/QuestionMark';

import {buttonLeftMargin, buttonLeftRightMargin} from 'styles/shared';

const StyledContainer = styled.div`
  padding: 0 2.6rem;
`;

const StyledRow = styled(Row)`
  align-items: flex-end;
`;

const StyledButtonsRow = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;
`;

interface Props {
  searchStr: string;
  anyContacts: boolean;
  editMode: boolean;
  isLoading: boolean;
  toggleEditMode: () => void;
  deleteButtonVisible: boolean;
  onChange: (field: string, value: string) => void;
  onAddContact: () => void;
  importContacts: (e) => void;
  onDelete: () => void;
}

function SearchPanel({
  searchStr,
  anyContacts,
  editMode,
  isLoading,
  toggleEditMode,
  deleteButtonVisible,
  onChange,
  onAddContact,
  importContacts,
  onDelete
}: Props) {
  const dispatch = useDispatch();

  const inputFile = useRef<HTMLInputElement>(null);

  function openUploadDialog() {
    if (inputFile?.current) {
      heapAnalyticsService.track('Clicking to Import Contact');
      inputFile.current.click();
    }
  }

  function onAutoFill(address) {
    dispatch(addressActions.selectAddress(address));
  }

  function renderEditListButton() {
    let text = editMode ? 'Finish Editing' : 'Edit List';

    return (
      <Button autoWidth disabled={!anyContacts} margin={buttonLeftRightMargin} onClick={toggleEditMode}>
        {text}
      </Button>
    );
  }

  function render() {
    const importTooltipUrl = 'https://drive.google.com/file/d/10aTdP5HeHqoXdq60lyCHaimY20A2mQZJ/view?usp=sharing';

    const itemTooltip = (
      <span>
        Upload your exported DHL+ contacts or use&nbsp;
        <a href={importTooltipUrl} target="_blank" rel="noopener noreferrer">
          our contact list template
        </a>
        .<br /> If you have any trouble, please email your file to customer service for help.
      </span>
    );

    return (
      <StyledContainer>
        <StyledRow>
          <Col lg={5}>
            <SearchAutosuggest
              name="searchStr"
              label="Search Contact"
              placeholder="Type Company or Contact name"
              value={searchStr}
              onChange={onChange}
              onAutoFill={onAutoFill}
            />
          </Col>

          <StyledButtonsRow lg={7}>
            {renderEditListButton()}

            <Button autoWidth margin={buttonLeftRightMargin} disabled={isLoading} onClick={onAddContact}>
              Create Contact
            </Button>

            <input type="file" id="file" ref={inputFile} hidden accept=".xlsx,.csv" onChange={importContacts} />

            <Button autoWidth margin={buttonLeftMargin} disabled={isLoading} onClick={openUploadDialog}>
              Import Contact
            </Button>
            <QuestionMark id="item-tooltip" tooltip={itemTooltip} />
          </StyledButtonsRow>
        </StyledRow>

        {deleteButtonVisible && (
          <Button margin="2rem 0" onClick={onDelete}>
            Delete Selected
          </Button>
        )}
      </StyledContainer>
    );
  }

  return render();
}

export default SearchPanel;

import Cookies from 'js-cookie';

import COMMON from 'constants/common';

import userMapper from 'services/mappers/userMapper';
import stateStorageService from 'services/stateStorageService';
import apis from 'domain/apis';

const KEYS = {
  TOKEN: 'jwt_token',
  AUTH_CONTEXT: 'auth_context',
  REFRESH_TOKEN: 'refresh_token',
  QUICK_ACCESS_TOKEN: 'quick_access_token',
  TEMPORARY_TOKEN: 'temporary_token',
  REDIRECT_URL: 'redirect_url',
  RECOVERY_CODE: 'recovery_code',
  RESET_PASSWORD_ERROR: 'reset_password_error'
};

export default {
  getToken,
  saveToken,
  getRefreshToken,
  saveRefreshToken,
  getQuickAccessToken,
  saveQuickAccessToken,
  getTemporaryToken,
  saveTemporaryToken,
  getResetPasswordError,
  saveResetPasswordError,
  getRedirectUrl,
  saveRedirectUrl,
  getRecoveryCode,
  saveRecoveryCode,
  getCurrentUser,
  getAuthContext,
  setAuthContext,
  isHouseAccountUsed,
  toggleHouseAccount,
  signIntoProfile,
  logCsrAsDraftOwner
};

function getToken() {
  let token = Cookies.get(KEYS.TOKEN);
  return token;
}

function saveToken(jwt) {
  if (!jwt) return Cookies.remove(KEYS.TOKEN);
  Cookies.set(KEYS.TOKEN, jwt);
}

function getRefreshToken() {
  let token = Cookies.get(KEYS.REFRESH_TOKEN);
  return token;
}

function saveRefreshToken(jwt: string | null) {
  if (!jwt) return Cookies.remove(KEYS.REFRESH_TOKEN);
  Cookies.set(KEYS.REFRESH_TOKEN, jwt);
}

function getQuickAccessToken() {
  let token = Cookies.get(KEYS.QUICK_ACCESS_TOKEN);
  return token;
}

function saveQuickAccessToken(jwt) {
  if (!jwt) return Cookies.remove(KEYS.QUICK_ACCESS_TOKEN);
  Cookies.set(KEYS.QUICK_ACCESS_TOKEN, jwt);
}

function getTemporaryToken() {
  let token = Cookies.get(KEYS.TEMPORARY_TOKEN);
  return token;
}

function saveTemporaryToken(jwt) {
  if (!jwt) return Cookies.remove(KEYS.TEMPORARY_TOKEN);
  Cookies.set(KEYS.TEMPORARY_TOKEN, jwt);
}

function getResetPasswordError() {
  let resetPasswordError = Cookies.get(KEYS.RESET_PASSWORD_ERROR);
  return resetPasswordError;
}

function saveResetPasswordError(message) {
  if (!message) return Cookies.remove(KEYS.RESET_PASSWORD_ERROR);
  Cookies.set(KEYS.RESET_PASSWORD_ERROR, message);
}

function getRedirectUrl() {
  const url = Cookies.get(KEYS.REDIRECT_URL);
  return url;
}

function saveRedirectUrl(url) {
  if (!url) return Cookies.remove(KEYS.REDIRECT_URL);
  Cookies.set(KEYS.REDIRECT_URL, url, {expires: 1 / 144}); //expires in 10 minutes
}

function getRecoveryCode() {
  const result = Cookies.get(KEYS.RECOVERY_CODE);
  return result;
}

function saveRecoveryCode(code) {
  if (!code) return Cookies.remove(KEYS.RECOVERY_CODE);
  Cookies.set(KEYS.RECOVERY_CODE, code);
}

async function getCurrentUser(id) {
  let user: User = await apis.user.getUserById(id);

  let mappedUser = userMapper.mapUser(user);

  if (user && !getAuthContext()) {
    const userId = user.id;

    let authContext: AuthContextData = {
      creatorId: userId,
      isCreatorCsrUser: mappedUser?.isCSR,
      ownerId: userId,
      ownerName: user?.name,
      ownerCompanyName: user?.companyName,
      ownerSetupForExports: user?.setupForExports,
      ownerSetupForImports: user?.setupForImports
    };

    setAuthContext(authContext);
  }

  return mappedUser;
}

function getAuthContext() {
  let authContext = Cookies.get(KEYS.AUTH_CONTEXT);

  if (authContext) {
    let authContextParsed: any = JSON.parse(authContext);

    return {
      ...authContextParsed,
      get isCsrPersonalAccount() {
        return authContextParsed?.isCreatorCsrUser && authContextParsed?.creatorId === authContextParsed?.ownerId;
      },
      get isCsrUserAccount() {
        return authContextParsed?.isCreatorCsrUser && authContextParsed?.creatorId !== authContextParsed?.ownerId;
      }
    };
  }

  return null;
}

function setAuthContext(authContext) {
  if (!authContext) return Cookies.remove(KEYS.AUTH_CONTEXT);
  return Cookies.set(KEYS.AUTH_CONTEXT, JSON.stringify(authContext));
}

function isHouseAccountUsed() {
  let authContext: AuthContext = getAuthContext();

  let isUsed = stateStorageService.getHouseAccountIsUsed(authContext?.creatorId, authContext?.ownerId);

  return isUsed ? true : false;
}

function toggleHouseAccount(isUsed) {
  let authContext: AuthContext = getAuthContext();

  stateStorageService.setHouseAccountIsUsed(authContext?.creatorId, authContext?.ownerId, isUsed);
}

async function signIntoProfile(profileId, userData) {
  let profile: any = null;

  if (userData) {
    profile = userData;
  } else {
    profile = await apis.user.getUserById(profileId);
  }

  if (!profile) return;

  let authContext: AuthContext = getAuthContext();

  if (authContext) {
    authContext.ownerId = profile.id;
    authContext.ownerName = profile?.name;
    authContext.ownerCompanyName = profile?.companyName;
    authContext.ownerSetupForExports = profile?.setupForExports;
    authContext.ownerSetupForImports = profile?.setupForImports;

    setAuthContext(authContext);
  } else {
    let user: User = await apis.user.getUserById(COMMON.CURRENT_USER_ID);

    let mappedUser = userMapper.mapUser(user);

    let authContextData: AuthContextData = {
      creatorId: user.id,
      isCreatorCsrUser: mappedUser?.isCSR,
      ownerId: profile.id,
      ownerName: profile?.name,
      ownerCompanyName: profile?.companyName,
      ownerSetupForExports: profile?.setupForExports,
      ownerSetupForImports: profile?.setupForImports
    };

    setAuthContext(authContextData);
  }
}

async function logCsrAsDraftOwner(ownedById) {
  let authContext: AuthContext = getAuthContext();

  if (!authContext.isCsrPersonalAccount) return;

  await signIntoProfile(ownedById, null);
}

import React from 'react';
import styled from 'styled-components/macro';
import {isEmpty} from 'lodash';

import Flex from 'components/common/Flex';
import ButtonActionsPopup from 'components/common/ActionsPopup/ButtonActionsPopup';
import ItemContainerMobile from '../ItemContainerMobile';
import ShipmentRowMobile from '../ShipmentRowMobile';

const StyledButtonRow = styled(Flex)`
  padding: 1rem 0.6rem 2rem;
`;

interface Props {
  shipments: Shipment[];
  actions: DropdownAction[];
}

function ShipmentStatusListMobile({shipments, actions}: Props) {
  return (
    <>
      {shipments.map((shipment: Shipment) => {
        let actionsToRender = actions.filter(action => {
          if (isEmpty(action?.hideForStatuses)) return true;

          return !action?.hideForStatuses?.includes(shipment?.primaryStatusFlag);
        });

        return (
          <ItemContainerMobile key={shipment.id}>
            <ShipmentRowMobile shipment={shipment} />

            <StyledButtonRow justify="flex-end">
              <ButtonActionsPopup actions={actionsToRender} activeItem={shipment} />
            </StyledButtonRow>
          </ItemContainerMobile>
        );
      })}
    </>
  );
}

export default ShipmentStatusListMobile;

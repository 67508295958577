import React, {useState, useEffect, useRef} from 'react';
import styled, {css} from 'styled-components/macro';

import TIMER from 'constants/timers';

import utils from 'helpers/utils';

import {colors, mainFont, opacity} from 'styles/shared';

const StyledButtonContainer = styled.button`
  width: ${props => props.width};
  height: 4.6rem;
  box-sizing: border-box;
  border-radius: 0.6rem;
  border: ${props => (props.type === 'secondary' ? `0.1rem solid ${props.color}` : 'none')};
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${props => (props.type === 'primary' ? colors.white : props.color)};
  background: ${props => (props.type === 'primary' ? props.color : 'transparent')};
  ${mainFont};
  padding: ${props => (props.width === 'auto' ? '0 3rem' : null)};
  margin: ${props => (props.margin ? props.margin : 0)};

  ${props =>
    props.theme === 'success' &&
    css`
      cursor: initial !important;
    `}

  ${props =>
    props.type === 'primary' &&
    !props.disabled &&
    css`
      box-shadow: 0 0.4rem 2rem ${opacity(0.15)};
    `}

  &:focus {
    outline: none;
  }
`;

export type ButtonType = 'primary' | 'secondary' | 'tertiary';
export type ButtonTheme = 'main' | 'success' | 'error';

interface Props extends BaseProps {
  type?: ButtonType;
  theme?: ButtonTheme;
  width?: number;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  autoWidth?: boolean;
  margin?: string;
}

Button.defaultProps = {
  type: 'primary',
  theme: 'main',
  className: '',
  disabled: false,
  autoWidth: false,
  margin: ''
};

function Button({children, type, theme, width, onClick, className, disabled, autoWidth, margin}: Props) {
  const [isHover, setIsHover] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const isPressedTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (isPressedTimer?.current) clearInterval(isPressedTimer.current);
    };
  }, []);

  function getThemeColor() {
    if (disabled) return colors.grey_main;

    if (theme === 'success') return colors.green_main;

    if (theme === 'error') return colors.red_main;

    if (isPressed) return colors.orange_dark;

    if (isHover) return colors.orange_light;

    return colors.orange_main;
  }

  function getButtonWidth(autoWidth) {
    if (autoWidth) return 'auto';

    if (width) return `${width}rem`;

    let widthNumber = 0;

    switch (type) {
      case 'primary':
        widthNumber = 17.3;
        break;
      case 'secondary':
        widthNumber = 19.4;
        break;
      case 'tertiary':
        widthNumber = 15.2;
        break;
      default:
        break;
    }

    return `${widthNumber}rem`;
  }

  function onMouseEnter() {
    setIsHover(true);
  }

  function onMouseLeave() {
    setIsHover(false);
  }

  function clickButton() {
    if (!onClick) return;

    onClick();

    setIsPressed(true);

    isPressedTimer.current = utils.setTimeout(() => setIsPressed(false), TIMER.PRESSED_INTERVAL);
  }

  function render() {
    let color = getThemeColor();

    const buttonWidth = getButtonWidth(autoWidth);

    return (
      <StyledButtonContainer
        type={type}
        color={color}
        theme={theme}
        width={buttonWidth}
        margin={margin}
        disabled={disabled}
        className={className}
        onClick={clickButton}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        {children}
      </StyledButtonContainer>
    );
  }

  return render();
}

export default Button;

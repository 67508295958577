import React, {useState, useRef} from 'react';
import {debounce} from 'lodash';
import Autosuggest from 'react-autosuggest';

import TIMER from 'constants/timers';

import validationHelper from 'helpers/validationHelper';
import utils from 'helpers/utils';

import apis from 'domain/apis';

import InputError from 'components/common/InputComponents/InputError';

import {MaterialTextField} from 'styles/materialUI';
import * as Styled from 'styles/autosuggest';

interface Props {
  name: string;
  onChange: OnChangeHandler;
  onAutoFill: (suggestion: string) => void;
  value?: string;
  countrySelected: string;
  placeholder: string;
  error?: string | ValidationError[];
}

AutosuggestInput.defaultProps = {
  placeholder: '',
  value: ''
};

function AutosuggestInput({name, placeholder, onChange, onAutoFill, value, countrySelected, error}: Props) {
  const [suggestions, setSuggestions] = useState([]);

  const lastRequestId = useRef(null);

  const onFetchSuggestions = useRef(debounce(value => onSuggestionsFetchRequested(value), TIMER.DEBOUNCE_TIME)).current;

  async function onSuggestionsFetchRequested(value) {
    if (value.length < 2) return;

    let requestId = utils.getRandomUid();
    lastRequestId.current = requestId;

    let response = await apis.getZipCodes(countrySelected, value);

    if (lastRequestId.current !== requestId) return;

    setSuggestions(response || []);
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onInputChange(event, {newValue}) {
    onChange(name, newValue);
  }

  function getSuggestionValue(suggestion) {
    return suggestion.postalCode;
  }

  function onSuggestionSelected(event, {suggestion}) {
    onAutoFill(suggestion);
  }

  function renderInputComponent(inputProps, inputError) {
    return <MaterialTextField {...inputProps} label={placeholder} error={inputError ? true : false} variant="filled" />;
  }

  function render() {
    const inputError = validationHelper.getInputError(error);

    const inputProps = {value, onChange: onInputChange, autoComplete: 'nope'};

    const renderSuggestion = suggestion => (
      <div>
        {suggestion.postalCode} - {suggestion.city}
      </div>
    );

    return (
      <Styled.AutosuggestContainer small={true} error={error}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={({value}) => onFetchSuggestions(value)}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={autosuggestProps => renderInputComponent(autosuggestProps, inputError)}
        />

        {inputError && <InputError>{inputError}</InputError>}
      </Styled.AutosuggestContainer>
    );
  }

  return render();
}

export default AutosuggestInput;

import React from 'react';
import {DropdownButton as DropDownButton, Dropdown} from 'components/bootstrap';
import styled, {css} from 'styled-components/macro';

import {colors, opacity, mainFont} from 'styles/shared';

const StyledDropdownButton = styled(DropDownButton)`
  > button {
    height: 4.8rem;
    padding-left: 1rem;
    padding-right: 1.6rem;
    background-color: ${colors.white};
    box-shadow: 0 0.2rem 0.6rem ${opacity(0.14)};
    border-radius: 0.6rem;
    border: none;
    color: ${colors.orange_main};
    ${mainFont};

    &.dropdown-toggle {
      background-color: ${colors.white}!important;
      border: none;
      color: ${colors.orange_main}!important;
    }
    &:hover {
      background-color: ${colors.grey_light}!important;
      border: none;
      color: ${colors.orange_main};
    }
    &:active,
    &:focus {
      background-color: ${colors.white}!important;
      box-shadow: 0 0 0.4rem ${opacity(0.16)}!important;
      color: ${colors.orange_main}!important;
    }
    &:after {
      vertical-align: baseline !important;
      margin-left: 1.6rem !important;

      ${props =>
        props.drop === 'up'
          ? css`
              border-right: 0.8rem solid transparent !important;
              border-bottom: 0.8rem solid !important;
              border-left: 0.8rem solid transparent !important;
            `
          : css`
              border-top: 0.8rem solid;
              border-right: 0.8rem solid transparent;
              border-left: 0.8rem solid transparent;
            `}
    }
  }

  > div {
    width: 100%;
  }

  .dropdown-item {
    ${mainFont};
    padding: 1.3rem 0 1.3rem 1.3rem;

    &:active {
      background-color: ${colors.grey_light};
      color: ${colors.black};
    }
  }
`;

interface Props extends BaseProps {
  value?: string;
  options: BasicOption[];
  direction: 'up' | 'down';
  onChange: (value) => void;
}

DropdownButton.defaultProps = {
  direction: 'down'
};

function DropdownButton({value, options, direction, onChange, className}: Props) {
  const selectedOption = options.find(option => option.value === value);
  const title = selectedOption?.label || options[0].label;

  return (
    <StyledDropdownButton title={title} drop={direction} className={className}>
      {options.map(option => {
        return (
          <Dropdown.Item key={option.value} eventKey={option.value} onSelect={eventKey => onChange(eventKey)}>
            {option.label}
          </Dropdown.Item>
        );
      })}
    </StyledDropdownButton>
  );
}

export default DropdownButton;

import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {debounce} from 'lodash';

import commonActions from 'actions/commonActions';

import FormHeader from 'components/common/FormHeader';

import * as styled from './SignMeUpModule.styled';

function SignMeUpModule() {
  const dispatch = useDispatch();

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', debounce(isStickyEvent, 50));

    return () => {
      window.removeEventListener('scroll', debounce(isStickyEvent, 50));
    };
  }, []);

  function isStickyEvent() {
    // Sticky height is 7rem but non-sticky height depends on text
    setIsSticky((isSticky) => {
      return window.scrollY > (isSticky ? 52 : 100);
    });
  }

  async function signUp() {
    await dispatch(commonActions.toggleGuestAuthModal(true));
  }

  return (
    <styled.container isSticky={isSticky}>
      <styled.content>
        <FormHeader>Sign up to get even better rates!</FormHeader>
        {!isSticky && (
          <styled.description>
            You are currently viewing our <strong>Guest Rates</strong>. Please <strong>sign in</strong> or{' '}
            <strong>sign up</strong> to <br /> get even better discounts!{' '}
            <strong>Our clients typically save 40% off retail prices</strong>.
          </styled.description>
        )}
      </styled.content>

      <styled.button autoWidth isSticky={isSticky} onClick={signUp}>
        Awesome, Sign Me In/Up
      </styled.button>

      <styled.imageContainer isSticky={isSticky}>
        <img src="/images/standalone/money.png" alt="Get better rates" />
      </styled.imageContainer>
    </styled.container>
  );
}

export default SignMeUpModule;

import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import {isEmpty} from 'lodash';
import {useDispatch} from 'react-redux';
import {Row, Col} from 'components/bootstrap';

import shipmentActions from 'actions/shipmentActions';

import config from 'config';
import uiHelper from 'helpers/uiHelper';

import Button from 'components/common/Button';
import FormHeader from 'components/common/FormHeader';
import Flex from 'components/common/Flex';
import DocumentItem from './components/DocumentItem';
import PrintModal from './components/PrintModal';

import {mainFont, buttonLeftRightMargin} from 'styles/shared';

const StyledMessage = styled.div`
  padding: 5rem 0;
  ${mainFont};
  font-size: 2rem;
`;

const StyledButtonRow = styled(Flex)`
  margin-top: 3rem;
  margin-bottom: 5rem;
`;

export interface DocumentToPrint {
  name: string;
  src: string;
}

interface Props {
  shipmentId: string;
  documents: Document[];
}

function DocumentsSummary({documents, shipmentId}: Props) {
  const dispatch = useDispatch();

  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [documentsToPrint, setDocumentsToPrint] = useState<DocumentToPrint[]>([]);

  useEffect(() => {
    let checkedDocuments: string[] = [];

    for (let document of documents) {
      checkedDocuments.push(document.id);
    }

    setSelectedDocuments(checkedDocuments);
  }, []);

  function onSelect(checkedId) {
    const index = selectedDocuments.findIndex(id => id === checkedId);

    if (index === -1) {
      setSelectedDocuments(prevArray => [...prevArray, checkedId]);
    } else {
      setSelectedDocuments(selectedDocuments.filter(id => id !== checkedId));
    }
  }

  async function downloadDocuments() {
    if (isEmpty(selectedDocuments)) return;

    const allSelected = selectedDocuments.length === documents.length;

    if (allSelected) {
      const downloadLink = `${config.baseUrl}/file/download-all/${shipmentId}`;
      window.open(downloadLink, '_blank');
    } else {
      for (const id of selectedDocuments) {
        const response: any = await dispatch(shipmentActions.loadShipmentDocument(id));
        const doc = response as ShipmentDocument;

        if (doc?.file) {
          const downloadLink = document.createElement('a');

          downloadLink.href = doc?.file;
          downloadLink.download = doc.type;
          downloadLink.click();
        }
      }
    }

    setSelectedDocuments([]);
  }

  function getDocUrl(file) {
    try {
      const dataUrltoBlob = file => {
        const arr = file.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type: mime});
      };

      const blob = dataUrltoBlob(file);

      const docUrl = window.URL.createObjectURL(blob);
      return docUrl;
    } catch (e) {
      uiHelper.logError(e);
      return null;
    }
  }

  async function printDocuments() {
    if (isEmpty(selectedDocuments)) return;

    let documents: DocumentToPrint[] = [];

    for (let id of selectedDocuments) {
      const response: any = await dispatch(shipmentActions.loadShipmentDocument(id));
      const doc = response as ShipmentDocument;

      if (doc?.file) {
        const docUrl = getDocUrl(doc.file);
        if (!docUrl) continue;
        documents.push({name: doc.type, src: docUrl});
      }
    }

    setDocumentsToPrint(documents);
    setSelectedDocuments([]);
  }

  function render() {
    const anySelectedDocuments = !isEmpty(selectedDocuments);

    const anyPrintDocuments = !isEmpty(documentsToPrint);

    return (
      <div>
        <FormHeader>1. Print your documents</FormHeader>

        {isEmpty(documents) ? (
          <StyledMessage>There are no documents.</StyledMessage>
        ) : (
          <>
            <Row>
              {documents.map(document => {
                const id = document.id;

                let isSelected = selectedDocuments.find(i => i === id) ? true : false;

                return (
                  <Col sm={4} key={id}>
                    <DocumentItem id={id} title={document.type} selectAction={onSelect} isSelected={isSelected} />
                  </Col>
                );
              })}
            </Row>

            <StyledButtonRow>
              <Button
                autoWidth
                disabled={!anySelectedDocuments}
                margin={buttonLeftRightMargin}
                onClick={printDocuments}>
                Print
              </Button>
              <Button
                autoWidth
                disabled={!anySelectedDocuments}
                margin={buttonLeftRightMargin}
                onClick={downloadDocuments}>
                Download
              </Button>
            </StyledButtonRow>

            {anyPrintDocuments && (
              <PrintModal
                visible={anyPrintDocuments}
                close={() => setDocumentsToPrint([])}
                documents={documentsToPrint}
              />
            )}
          </>
        )}
      </div>
    );
  }

  return render();
}

export default DocumentsSummary;

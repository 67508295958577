import React from 'react';
import Select from 'react-select';
import styled from 'styled-components/macro';

import validationHelper from 'helpers/validationHelper';

import FormLabelContainer from 'components/common/InputComponents/FormLabelContainer';
import InputError from 'components/common/InputComponents/InputError';

import {colors, defaultInputHeight, boxShadow, mainFont} from 'styles/shared';
import inputStyles from 'styles/customization/input';

const StyledInputContainer = styled.div`
  ${inputStyles.common.inputContainer};
`;

const StyledSelect = styled(Select)`
  ${inputStyles.selectInput.inputField};
  position: relative;

  .react-select-input__control {
    width: 100%;
  }
  .react-select-input__indicator {
    display: none;
  }
  .react-select-input__value-container {
    display: flex;
    align-items: center;
  }
  .react-select-input__placeholder {
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: ${colors.darkBlue_light};
  }
  .react-select-input__menu {
    position: absolute;
    top: ${defaultInputHeight};
    left: 0;
    filter: drop-shadow(${boxShadow});
    border-radius: 0.6rem;
    padding: 0.6rem 0;
    background: ${colors.white};
    width: 100%;
    z-index: 20;
  }
  .react-select-input__option {
    height: 6.2rem;
    padding: 2rem 1.6rem;
    ${mainFont};
    color: ${colors.darkBlue_light};
    cursor: pointer;
    position: relative;

    &:first-child:before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 0.1rem;
      width: 95%;
      border-bottom: 0.1rem solid ${colors.orange_main};
    }
    &:hover {
      background-color: ${colors.grey_light};
      color: ${colors.darkBlue_main};
    }
    > span {
      font-weight: bold;
    }
  }
  .react-select-input__option--is-selected {
    color: ${colors.darkBlue_main};
  }
`;

interface Props extends BaseProps {
  id: string;
  name: string;
  value?: string;
  label?: string;
  onChange: OnChangeHandler;
  options: any;
  error?: string | ValidationError[];
  required?: boolean;
  tooltip: string | JSX.Element;
  placeholder?: string;
}

ReactSelectInput.defaultProps = {
  required: false,
  tooltip: '',
  className: '',
  placeholder: '',
  id: ''
};

function ReactSelectInput({
  name,
  label,
  value,
  onChange,
  options,
  error,
  required,
  tooltip,
  className,
  id,
  placeholder
}: Props) {
  const inputOnChange = option => {
    onChange(name, option.value);
    return option;
  };

  const inputError: string = validationHelper.getInputError(error);

  //clear all styles to default
  const styleProxy = new Proxy(
    {},
    {
      get: (target, propKey) => () => {}
    }
  );

  //menuIsOpen={true} for debugging purpose

  return (
    <StyledInputContainer className={className} id={id}>
      {label && <FormLabelContainer label={label} name={name} required={required} tooltip={tooltip} />}

      <StyledSelect
        id={name}
        name={name}
        defaultValue={options.find(option => option.value === value)}
        classNamePrefix="react-select-input"
        options={options}
        styles={styleProxy}
        placeholder={placeholder}
        onChange={inputOnChange}
      />

      {inputError && <InputError>{inputError}</InputError>}
    </StyledInputContainer>
  );
}

export default ReactSelectInput;

import React, {useState} from 'react';
import styled from 'styled-components/macro';

import {Row, Col} from 'components/bootstrap';

import ActionLink from 'components/common/ActionLink';
import FormHeader from 'components/common/FormHeader';
import SummaryContainer from './SummaryContainer';

const StyledSummaryContainer = styled(SummaryContainer)`
  position: relative;
  min-height: 7.8rem;
`;

const StyledActionLink = styled(ActionLink)`
  position: absolute;
  top: 2.8rem;
  right: 1.6rem;
`;

interface Props extends BaseProps {
  header: string;
  additionalHeader?: string;
}

SummaryContainer.defaultProps = {
  className: ''
};

function SummarySection({children, className, header, additionalHeader}: Props) {
  const [collapsed, setCollapsed] = useState(false);

  function onToggle() {
    setCollapsed(!collapsed);
  }

  function render() {
    let toggleLinkTitle = collapsed ? 'Show' : 'Hide';

    let headerComponent = <FormHeader>{`${header}:`}</FormHeader>;

    if (additionalHeader) {
      headerComponent = (
        <Row>
          <Col lg={6}>
            <FormHeader>{`${header}:`}</FormHeader>
          </Col>
          <Col lg={6}>
            <FormHeader>{`${additionalHeader}:`}</FormHeader>
          </Col>
        </Row>
      );
    }

    return (
      <StyledSummaryContainer className={className}>
        <div>
          {headerComponent}
          <StyledActionLink title={toggleLinkTitle} onClick={onToggle}></StyledActionLink>
        </div>
        {!collapsed && children}
      </StyledSummaryContainer>
    );
  }

  return render();
}

export default SummarySection;

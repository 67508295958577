import React from 'react';
import styled, {css} from 'styled-components/macro';

import UNIT from 'constants/units';
import MEASUREMENT_SYSTEM from 'constants/measurementSystems';

import Flex from 'components/common/Flex';

import {opacity, mainFont, secondaryFont, mediaQueries} from 'styles/shared';

const StyledBox = styled.div`
  width: 23.2rem;
  height: 10rem;
  border-radius: 0.6rem;
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};
  margin-bottom: 3rem;
  display: flex;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      cursor: initial;
      background: #ffdcd2;
    `}

  @media ${mediaQueries.mobileMax} {
    flex-direction: column;
    align-items: center;
    width: 16.8rem;
    height: auto;
    margin-bottom: 0.6rem;
  }
`;

const StyledEmptyBox = styled.div`
  width: 23.2rem;
  height: 10rem;
  display: hidden;

  @media ${mediaQueries.mobileMax} {
    display: none;
  }
`;

const StyledContent = styled(Flex)`
  margin-left: 0.8rem;

  @media ${mediaQueries.mobileMax} {
    margin: 0 0 0.8rem;
    text-align: center;
  }
`;

const StyledTitle = styled.div`
  ${mainFont};
  font-weight: bold;
  margin-bottom: 0.3rem;
`;

const StyledText = styled.div`
  ${secondaryFont};
`;

interface Props {
  box: Box;
  measurementSystem: string;
  active: boolean;
  onSelect: (box: Box) => void;
}

function ItemBox({box, measurementSystem, active, onSelect}: Props) {
  if (box?.hidden) return <StyledEmptyBox />;

  const unit = measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL ? UNIT.INCH_LABEL : UNIT.LENGTH_CM;

  const size = box.size[measurementSystem];

  return (
    <StyledBox active={active} onClick={() => onSelect(box)}>
      <img alt="Envelope" src={`/images/standalone/${box.image}`} />

      <StyledContent direction="column" justify="center">
        <StyledTitle>{box.name}</StyledTitle>
        <StyledText>
          <span>
            {size.length}x{size.width}
          </span>
          {size.height > 0 && <span>x{size.height}</span>}
          <span> {unit}</span>
        </StyledText>
      </StyledContent>
    </StyledBox>
  );
}

export default ItemBox;

import httpHelper from 'helpers/httpHelper';
import mapper from './mapping';
import pickup from './pickup';

export default {
  completePrepAndPickup
}

function completePrepAndPickup(
  prepData: PrepareShipmentDto,
  newPickupData: ConfirmPickupDto | null,
  addShipmentToPickupData: AddShipmentToPickupDto | null
  ): Promise<CompletePrepAndPickupResponse> {
    const options = {useHouseAccount: true};

    const shipmentRequest: ShipmentRequest = httpHelper.addRequiredParameters(
      mapper.shipment.mapShipmentRequestFromDraft(
        prepData.id,
        prepData.draft,
        prepData.eeiFilingTypeOptions,
        prepData.paymentId,
        prepData.paymentAmount,
        prepData.paymentTime
      ), options
    );

    const pickupRequest: ConfirmPickupRequest | null = httpHelper.addRequiredParameters(
      pickup.getConfirmPickupRequest(newPickupData),
      options
    );

    const addShipmentToPickupRequest: AddShipmentToPickupRequest | null = httpHelper.addRequiredParameters(
      pickup.getAddShipmentToPickupRequest(addShipmentToPickupData),
      options
    );

    const prepAndPickupRequest: PrepAndPickupRequest = {
      prep: shipmentRequest,
      newPickup: pickupRequest,
      existingPickup: addShipmentToPickupRequest
    };
  return httpHelper.post('/complete_prep_and_pickup_actions', prepAndPickupRequest, options);
}

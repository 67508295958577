import config from 'config';

export default {
  getSchedulePickupLink,
  getHelpEmailLink,
  getQuoteSupportLink
};

function getSchedulePickupLink() {
  return getMailToLink('Schedule a Pickup', '');
}

function getHelpEmailLink() {
  return getMailToLink('Request for Support', '');
}

function getQuoteSupportLink() {
  return getMailToLink('Request for Support', '', config.supportQuoteUrl);
}

//helper methods

function getMailToLink(subject, body, url = config.supportUrl) {
  let link = `mailto:${url}`;

  if (subject) link += `?subject=${subject}`;
  if (body) link += `&body=${body}`;

  return link;
}

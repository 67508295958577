import React from 'react';

function ProductDeclaredValueTooltip() {
  return (
    <span>
      Total value of the items being sent. <br />
      This does not include taxes, <br />
      shipping costs, or any other fees.
    </span>
  );
}

export default ProductDeclaredValueTooltip;

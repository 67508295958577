import React from 'react';

function EORITooltip() {
  return (
    <span>
      <strong>EORI</strong> - Economic Operators Registration <br /> and Identification Number: A unique <br />{' '}
      identification number used throughout the <br /> European Union, it is assigned by Customs <br /> to a company or
      persons for import and <br /> export. An EORI number is not the same <br /> as a VAT number.
    </span>
  );
}

export default EORITooltip;

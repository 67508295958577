import React from 'react';

import styled from 'styled-components/macro';

import dateHelper from 'helpers/dateHelper';
import formatHelper from 'helpers/formatHelper';
import tooltipHelper from 'helpers/tooltipHelper';

import FormLabel from 'components/common/FormLabel';
import Button from 'components/common/Button';
import QuestionMark from 'components/common/Marks/QuestionMark';
import Flex from 'components/common/Flex';
import PickupRowDisplay from './PickupRowDisplay';

import {colors, mainFont} from 'styles/shared';
import CARRIER from '../../../../../constants/carriers';

const StyledLabelRow = styled(Flex)`
  margin-bottom: 2rem;
`;

const StyledTimeLabel = styled.span`
  font-weight: bold;
  color: ${colors.orange_main};
  ${mainFont}
`;

const StyledButtons = styled(Flex)`
  margin-bottom: 4rem;
`;

const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 2rem;
  font-family: 'Nunito Sans Bold';
`;

interface Props {
  pickupData?: PickupData;
  carrier?: string;
  onEdit: () => void;
}

function PickupReadOnly({pickupData, carrier, onEdit}: Props) {
  function render() {
    if (!pickupData) return null;

    const confirmationNumber = (pickupData as Pickup).confirmationNumber;

    const isExistingPickup = confirmationNumber ? true : false;

    const editTitle = isExistingPickup ? 'Use another pickup' : 'Edit pickup';

    const pickupTimeTooltip = carrier ? tooltipHelper.getPickupTimeTooltip(carrier) : '';

    return (
      <>
        {isExistingPickup && <StyledFormLabel>Using Existing Pickup:</StyledFormLabel>}

        <PickupRowDisplay label="Pickup date" value={dateHelper.displayDate(pickupData.date)} />

        <FormLabel required>Pickup Time</FormLabel>

        <StyledLabelRow justify="space-between">
          <StyledTimeLabel>{pickupData.displayPickupWindow}</StyledTimeLabel>

          {pickupTimeTooltip && (
            <QuestionMark id="pickup-readonly-tooltip" tooltip={pickupTimeTooltip} />
          )}
        </StyledLabelRow>

        <PickupRowDisplay label="Location of pickup" value={formatHelper.displayValue(pickupData.location)} />

        { CARRIER.UPS === carrier && (<PickupRowDisplay label="Number of packages" value={formatHelper.displayValue(pickupData.numOfPackages)} />)}

        <PickupRowDisplay
          label="Note for driver"
          value={formatHelper.displayValue(pickupData.notes)}
          required={false}
        />

        {confirmationNumber && (
          <PickupRowDisplay label="Pickup Confirmation Nr." value={confirmationNumber} required={false} />
        )}

        <StyledButtons>
          <Button type="secondary" onClick={onEdit}>
            {editTitle}
          </Button>
        </StyledButtons>
      </>
    );
  }

  return render();
}

export default PickupReadOnly;

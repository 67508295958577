import React from 'react';

import PICKUP_TAB from 'constants/pickupTabs';

import formatHelper from 'helpers/formatHelper';

import ActionsPopup from 'components/common/ActionsPopup';
import NextSevenDaysPickups from './components/NextSevenDaysPickups';
import AddressCell from './components/AddressCell';

import extras from 'components/view_history/extras';

import * as Styled from 'styles/table';

interface Props {
  activePickups: Pickup[];
  activeTab: string;
  pickupActions: DropdownAction[];
}

function PickupsList({activePickups, activeTab, pickupActions}: Props) {
  const isFutureTab = activeTab === PICKUP_TAB.FUTURE;
  const isPastTab = activeTab === PICKUP_TAB.PAST;

  function render() {
    if (isFutureTab) {
      return <NextSevenDaysPickups activePickups={activePickups} pickupActions={pickupActions} isPastTab={isPastTab} />;
    }

    return (
      <tbody>
        {activePickups.map(pickup => {
          const displayPickupWindow = extras.displayPickupWindow(pickup, isPastTab);
          const carrierReferenceNumber = formatHelper.displayValue(pickup.confirmationNumber);

          return (
            <Styled.TRowBody key={pickup.id}>
              <AddressCell pickup={pickup} />
              <Styled.TBodyCell>{pickup.contactName}</Styled.TBodyCell>
              <Styled.TBodyCell>{displayPickupWindow}</Styled.TBodyCell>
              <Styled.TBodyCell>{carrierReferenceNumber}</Styled.TBodyCell>

              {!isPastTab && (
                <Styled.TBodyCell actions>
                  <ActionsPopup actions={pickupActions} activeItem={pickup} />
                </Styled.TBodyCell>
              )}
            </Styled.TRowBody>
          );
        })}
      </tbody>
    );
  }

  return render();
}

export default PickupsList;

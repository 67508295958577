import update from 'immutability-helper';

import helper from './reducerHelper';
import uiHelper from 'helpers/uiHelper';

import authService from 'services/authService';

import * as actions from 'action_types/commonActionTypes';
import initialState from './initialState';

import {IconName} from 'components/common/AppIcon/AppIcon';

export interface CommonState {
  asyncAction?: {showOverlay: boolean};
  showOverlay: boolean;
  confirmAction?: ConfirmInfo;
  infoAction?: InfoAction;
  globalMenuActions?: GlobalMenuAction[];
  tutorialInfo?: TutorialInfo;
  saveDraftModalVisible: boolean;
  isHouseAccountUsed: boolean;
  passwordProtectedDataModalVisible: boolean;
  resetPasswordModalVisible: boolean;
  isQuickAccessAuth: boolean;
  changePasswordModalVisible: boolean;
  intercomChatVisible: boolean;
  guestAuthModalVisible: boolean;
  guestSignUpModalVisible: boolean;
  errors: string[];
  messages: string[];
}

export interface ConfirmInfo {
  title: string;
  text?: string;
  action: () => void;
  close?: () => void;
}

export interface InfoAction {
  text: string | JSX.Element;
  type: 'info' | 'warning' | 'issue' | 'error' | 'critical';
  actionTitle?: string;
  closeTitle?: string;
  logToRollbar?: boolean;
  rollbarContext?: string;
  action?: () => void;
  close?: () => void;
}

export interface GlobalMenuAction {
  title: string;
  icon: IconName;
  action: () => void;
}

export interface TutorialInfo {
  tutorial: string;
  steps: number;
}

const commonReducer = (state: CommonState = initialState.common, action) => {
  return helper.handleActions<CommonState>(state, action, {
    [actions.ASYNC_ACTION_START](state, payload: {showOverlay: boolean}) {
      state.asyncAction = {showOverlay: payload.showOverlay};
    },
    [actions.ASYNC_ACTION_END](state) {
      state.asyncAction = undefined;
    },
    [actions.TOGGLE_GLOBAL_OVERLAY](state) {
      state.showOverlay = !state.showOverlay;
    },
    [actions.CONFIRM_ACTION](state, payload: {confirmInfo: ConfirmInfo}) {
      state.confirmAction = payload.confirmInfo;
    },
    [actions.CONFIRM_ACTION_CANCEL](state) {
      state.confirmAction = undefined;
    },
    [actions.INFO_ACTION](state, payload: {info: InfoAction}) {
      state.infoAction = payload.info;
    },
    [actions.INFO_ACTION_CANCEL](state) {
      state.infoAction = undefined;
    },
    [actions.GLOBAL_MENU](state, payload: {menuActions: GlobalMenuAction[]}) {
      state.globalMenuActions = payload.menuActions;
    },
    [actions.GLOBAL_MENU_CLOSE](state) {
      state.globalMenuActions = undefined;
    },
    [actions.TOGGLE_SAVE_DRAFT_MODAL](state, payload: {visible: boolean}) {
      state.saveDraftModalVisible = payload.visible;
    },
    [actions.TUTORIAL_INFO](state, payload: {tutorialInfo: TutorialInfo}) {
      state.tutorialInfo = payload.tutorialInfo;
    },
    [actions.TUTORIAL_INFO_CLOSE](state) {
      state.tutorialInfo = undefined;
    },
    [actions.TOGGLE_HOUSE_ACCOUNT_USAGE](state, payload: {isUsed: boolean}) {
      state.isHouseAccountUsed = payload.isUsed;
    },
    [actions.APP_ERROR](state, payload: {error: string}) {
      const error = payload.error;

      state.infoAction = {type: 'error', text: error, close: () => null};

      state.errors = update(state.errors, {$push: [error]});
    },
    [actions.APP_MESSAGE](state, payload: {message: string}) {
      const message = payload.message;

      uiHelper.showNotice(message);

      state.messages = update(state.messages, {$push: [message]});
    },
    [actions.TOGGLE_PASSWORD_PROTECTED_DATA_MODAL](state, payload: {visible: boolean; redirectUrl?: string}) {
      if (payload.redirectUrl) authService.saveRedirectUrl(payload.redirectUrl);
      state.passwordProtectedDataModalVisible = payload.visible;
    },
    [actions.TOGGLE_RESET_PASSWORD_MODAL](state, payload: {visible: boolean}) {
      state.resetPasswordModalVisible = payload.visible;
    },
    [actions.SET_IS_QUICK_ACCESS_AUTH](state, payload: {isQuickAccessAuth: boolean}) {
      state.isQuickAccessAuth = payload.isQuickAccessAuth;
    },
    [actions.TOGGLE_CHANGE_PASSWORD_MODAL](state, payload: {visible: boolean}) {
      state.changePasswordModalVisible = payload.visible;
    },
    [actions.TOGGLE_INTERCOM_CHAT](state) {
      state.intercomChatVisible = !state.intercomChatVisible;
    },
    [actions.TOGGLE_GUEST_AUTH_MODAL](state, payload: {visible: boolean; redirectUrl?: string}) {
      if (payload.redirectUrl) authService.saveRedirectUrl(payload.redirectUrl);
      state.guestAuthModalVisible = payload.visible;
    },
    [actions.TOGGLE_GUEST_SIGN_UP_MODAL](state, payload: {visible: boolean; redirectUrl?: string}) {
      if (payload.redirectUrl) authService.saveRedirectUrl(payload.redirectUrl);
      state.guestSignUpModalVisible = payload.visible;
    }
  });
};

export default commonReducer;

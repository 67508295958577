import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  const color = colors.orange_main;

  return (
    <svg width="22" height="22" fill="none" viewBox="0 0 22 22">
      <mask id="path-1-inside-1" fill={colors.white}>
        <path fillRule="evenodd" d="M15 13.745a7 7 0 10-8 0V17a4 4 0 008 0v-3.255z" clipRule="evenodd"></path>
      </mask>
      <path
        fill={color}
        d="M15 13.745l-.572-.82-.428.299v.521h1zm-8 0h1v-.521l-.428-.299-.572.82zM17 8a5.992 5.992 0 01-2.572 4.925l1.144 1.64A7.992 7.992 0 0019 8h-2zm-6-6a6 6 0 016 6h2a8 8 0 00-8-8v2zM5 8a6 6 0 016-6V0a8 8 0 00-8 8h2zm2.572 4.925A5.992 5.992 0 015 8H3a7.992 7.992 0 003.428 6.566l1.144-1.64zM8 17v-3.255H6V17h2zm3 3a3 3 0 01-3-3H6a5 5 0 005 5v-2zm3-3a3 3 0 01-3 3v2a5 5 0 005-5h-2zm0-3.255V17h2v-3.255h-2z"
        mask="url(#path-1-inside-1)"></path>
      <rect width="8" height="1" x="7" y="14" fill={color} rx="0.5"></rect>
      <rect width="8" height="1" x="7" y="16" fill={color} rx="0.5"></rect>
      <rect width="6" height="1" x="8" y="18" fill={color} rx="0.5"></rect>
      <path stroke={color} strokeLinecap="round" d="M11 14v-3M15 8a4 4 0 00-4-4"></path>
      <circle cx="15" cy="10" r="0.5" fill={color}></circle>
    </svg>
  );
}

export default Icon;

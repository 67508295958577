import {
  format,
  parseISO,
  isToday,
  isTomorrow,
  addDays as fnAddDays,
  subDays as fnSubDays,
  isWithinInterval,
  getMonth,
  getYear,
  isPast,
  isSameDay,
  isSaturday,
  isSunday,
  isMonday,
  isYesterday
} from 'date-fns';

import {isDate} from 'lodash';

import COMMON from '../constants/common';

import config from '../config';

export default {
  parseDate,
  displayDate,
  displayShortDate,
  todayDate,
  tomorrowDate,
  yesterdayDate,
  inNextSevenDays,
  inNextFiveDays,
  getNextMonday,
  getMonthFromDate,
  getYearFromDate,
  isPastDate,
  isEqualDates,
  isSaturdayDay,
  isSundayDay,
  addDays,
  subDays
};

function parseDate(dateStr: string) {
  if (isDate(dateStr)) return dateStr;

  let [year, month, day] = dateStr.split('-');

  let date = new Date(Number(year), Number(month) - 1, Number(day));

  return date;
}

function displayDate(date, pattern = config.format.dayDate) {
  if (!date) return COMMON.EMPTY_FIELD;

  try {
    if (isDate(date)) return format(date, pattern);

    return format(parseDateString(date), pattern);
  } catch (err) {
    return COMMON.EMPTY_FIELD;
  }
}

function displayShortDate(date) {
  if (!date) return COMMON.EMPTY_FIELD;
  return displayDate(date, config.format.shortDate);
}

function todayDate(dateStr) {
  let date = parseDateString(dateStr);

  return isToday(date);
}

function tomorrowDate(dateStr) {
  let date = parseDateString(dateStr);

  return isTomorrow(date);
}

function yesterdayDate(dateStr) {
  let date = parseDateString(dateStr);

  return isYesterday(date);
}

function getNextMonday(dateStr) {
  let date = parseDateString(dateStr);

  while(!isMonday(date)) {
    date = (addDays(date, 1));
  }

  return date;
}

function inNextSevenDays(dateStr) {
  let date = parseDateString(dateStr);

  let today = new Date();

  let startDay = addDays(today, 1);
  let endDay = addDays(today, 7);

  return isWithinInterval(date, {start: startDay, end: endDay});
}

function inNextFiveDays(dateStr) {
  let date = parseDateString(dateStr);

  let today = new Date();

  let endDay = addDays(today, 5);

  return isWithinInterval(date, {start: today, end: endDay});
}

function getMonthFromDate(dateStr) {
  let date = parseDateString(dateStr);

  return getMonth(date);
}

function getYearFromDate(dateStr) {
  let date = parseDateString(dateStr);

  return getYear(date);
}

function isPastDate(dateStr) {
  let date = parseDateString(dateStr);

  return isPast(date);
}

function isEqualDates(firstDate, secondDate) {
  return isSameDay(parseDateString(firstDate), parseDateString(secondDate));
}

function isSaturdayDay(date) {
  return isSaturday(date);
}

function isSundayDay(date) {
  return isSunday(date);
}

function addDays(date, num) {
  return fnAddDays(date, num);
}

function subDays(date, num) {
  return fnSubDays(date, num);
}

//helper methods

function parseDateString(date) {
  let num: any = 1;

  return isDate(date) ? date : parseISO(date, num);
}

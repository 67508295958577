import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Row, Col} from 'components/bootstrap';
import {isEmpty} from 'lodash';
import {useHistory} from 'react-router-dom';

import {AppState} from 'reducers';
import {GlobalMenuAction} from 'reducers/commonReducer';
import commonActions from 'actions/commonActions';
import shipmentActions from 'actions/shipmentActions';
import draftActions from 'actions/draftActions';

import SHIPMENT_STATUS from 'constants/shipmentStatuses';
import PATH from 'constants/path';

import uiHelper from 'helpers/uiHelper';

import authService from 'services/authService';
import userInfoService from 'services/userInfoService';

import entities from 'domain/entities';

import Container from 'components/common/Container';
import Pagination from 'components/common/Pagination/SimplePagination';
import EmptyList from '../EmptyList';
import ShipmentsTable from '../ShipmentsTable';
import CancelShipmentModal from './components/CancelShipmentModal';
import ListHeader from '../ListHeader';

import ShipmentStatusListMobile from 'components/view_history/mobile/components/shipment_statuses/ShipmentStatusListMobile';
import ListContainerMobile from 'components/view_history/mobile/components/ListContainerMobile';
import PaginationMobile from 'components/view_history/mobile/components/PaginationMobile';

type ShipmentStatusesProps = {
  shipmentStatuses: Shipment[];
  pickups: Pickup[];
  reloadShipments: () => void;
  reloadPickups: () => void;
};

function ShipmentStatuses({shipmentStatuses, pickups, reloadShipments, reloadPickups}: ShipmentStatusesProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: AppState) => state.user.current);
  const pagination = useSelector((state: AppState) => state.shipment.pagination);

  const [checkedShipments, setCheckedShipments] = useState<string[]>([]);
  const [shipmentToCancel, setShipmentToCancel] = useState<Shipment | undefined>();

  const allStatuses = Object.keys(SHIPMENT_STATUS).map(key => SHIPMENT_STATUS[key]);

  const statusActions: DropdownAction[] = [
    {
      label: 'Shipment Overview',
      icon: 'view',
      action: seeSummaryAction,
      //visible for all except DELIVERED
      hideForStatuses: allStatuses.filter(status => status === SHIPMENT_STATUS.DELIVERED)
    },
    {
      label: 'Reprint Documents',
      icon: 'print',
      action: seeSummaryAction,
      withoutMargin: true,
      //visible for all except DELIVERED
      hideForStatuses: allStatuses.filter(status => status === SHIPMENT_STATUS.DELIVERED)
    },
    {
      label: 'View Shipment',
      icon: 'view',
      action: seeSummaryAction,
      //visible only for DELIVERED
      hideForStatuses: allStatuses.filter(status => status !== SHIPMENT_STATUS.DELIVERED)
    },
    {
      label: 'Shipment Tracking',
      icon: 'tracking',
      action: uiHelper.notImplemented,
      //visible only for IN_TRANSIT
      hideForStatuses: allStatuses.filter(status => status !== SHIPMENT_STATUS.IN_TRANSIT)
    },
    {label: 'Archive Shipment', icon: 'archive', action: archiveShipmentAction},
    {
      label: 'Edit Shipment',
      icon: 'edit-shipment',
      action: editShipmentAction,
      //visible for all except DELIVERED
      hideForStatuses: allStatuses.filter(status => status === SHIPMENT_STATUS.DELIVERED)
    },
    {label: 'Duplicate Shipment', icon: 'duplicate', action: duplicateShipmentAction},
    {
      label: 'Delete Shipment',
      icon: 'delete-shipment',
      action: deleteShipmentAction,
      //visible only for DELIVERED
      hideForStatuses: allStatuses.filter(status => status !== SHIPMENT_STATUS.DELIVERED)
    },
    {
      label: 'Cancel Shipment',
      icon: 'cancel-shipment',
      action: cancelShipmentAction,
      //visible for AWAITING_PICKUP and AWAITING_DROPOFF
      hideForStatuses: allStatuses.filter(
        status => status !== SHIPMENT_STATUS.AWAITING_PICKUP && status !== SHIPMENT_STATUS.NONE
      )
    }
  ];

  useEffect(() => {
    const actions: GlobalMenuAction[] = [
      {title: 'Archive Shipments', icon: 'archive', action: uiHelper.notImplemented},
      {title: 'Delete Shipments', icon: 'delete-shipment', action: uiHelper.notImplemented}
    ];

    if (checkedShipments.length > 1) {
      dispatch(commonActions.globalMenu(actions));
    } else {
      dispatch(commonActions.globalMenuClose());
    }
  }, [checkedShipments]);

  function seeSummaryAction(item: Shipment) {
    if (user && !user.active) {
      dispatch(commonActions.infoAction(uiHelper.getInactiveUserNotice()));
      return;
    }
    
    history.push(`/shipment-summary/${item.id}`);
  }

  function editShipmentAction(item: Shipment) {
    dispatch(
      commonActions.infoAction({
        text: 'Please use duplicate shipment and use the same pickup.',
        type: 'warning',
        close: () => {}
      })
    );
  }

  function archiveShipmentAction(item: Shipment) {
    dispatch(
      commonActions.confirmAction({
        title: 'Are you sure you want to Archive Shipment?',
        text: 'Shipment will be stored in Archived Shipments.',
        action: async () => {
          uiHelper.notImplemented();
        }
      })
    );
  }

  function duplicateShipmentAction(item: Shipment) {
    if (user && !user.active) {
      dispatch(commonActions.infoAction(uiHelper.getInactiveUserNotice()));
      return;
    } else if (user && (user.suspended && !user.whitelisted)) {
      dispatch(commonActions.infoAction(uiHelper.getSuspendedUserNotice()));
      return;
    }

    dispatch(
      commonActions.confirmAction({
        title: 'Are you sure you want to Duplicate Shipment?',
        text: 'You can later edit shipment and create new pickup.',
        action: async () => {
          let draft = entities.draft.mapFromShipment(item, pickups);

          dispatch(draftActions.createDraftFromShipment(draft));

          await authService.logCsrAsDraftOwner(item?.ownedById);

          history.push(PATH.PREPARE_SHIPMENT);
        }
      })
    );
  }

  function deleteShipmentAction(item: Shipment) {
    dispatch(
      commonActions.confirmAction({
        title: 'Are you sure you want to Delete Shipment?',
        text: 'Shipment will be deleted.',
        action: async () => {
          uiHelper.notImplemented();
        }
      })
    );
  }

  function cancelShipmentAction(item?: Shipment) {
    if (!item) return;

    let shipmentHasAssociatedPickup = false;

    for (let pickup of pickups) {
      const pickupShipment = pickup.shipments.find(shipmentId => shipmentId === item.id);

      if (pickupShipment) {
        shipmentHasAssociatedPickup = true;
        break;
      }
    }

    const message = shipmentHasAssociatedPickup
      ? 'This Shipment has an associated Pickup.'
      : 'This Shipment has no associated Pickup.';

    dispatch(
      commonActions.confirmAction({
        title: 'Are you sure you want to cancel selected Shipment/s? ',
        text: message,
        action: async () => {
          if (shipmentHasAssociatedPickup) {
            setShipmentToCancel(item);
          } else {
            await cancelShipmentWithoutPickup(item.id);
          }
        }
      })
    );
  }

  async function cancelShipmentWithoutPickup(shipmentId?: string) {
    if (!shipmentId) return;

    if (shipmentToCancel) setShipmentToCancel(undefined);

    const isSuccess: any = await dispatch(shipmentActions.cancelShipment(shipmentId, false));

    reloadShipments();

    if (isSuccess) {
      uiHelper.showNotice('You have successfully canceled Shipment');
    }
  }

  async function cancelShipmentAndPickup(shipmentId?: string) {
    if (!shipmentId) return;

    setShipmentToCancel(undefined);

    const isSuccess: any = await dispatch(shipmentActions.cancelShipment(shipmentId, true));

    reloadShipments();

    if (isSuccess) {
      uiHelper.showNotice('You have successfully canceled Shipment and associated Pickup.');
      reloadPickups();
    }
  }

  async function goBack() {
    setShipmentToCancel(undefined);
    await cancelShipmentAction(shipmentToCancel);
  }

  function onCheckboxClick(checkedId) {
    const index = checkedShipments.findIndex(id => id === checkedId);

    if (index === -1) {
      setCheckedShipments(prevArray => [...prevArray, checkedId]);
    } else {
      setCheckedShipments(checkedShipments.filter(id => id !== checkedId));
    }
  }

  function onPageChange(page) {
    dispatch(shipmentActions.updateShipmentsPageNumber(page));
  }

  function renderEmptyList() {
    return (
      <EmptyList
        image="no-shipments.png"
        title="Looks like there are no Scheduled Shipments!"
        subtitle="Click “Prepare a Shipment” on the main menu above and start shipping today."
        isIconFirst={false}
      />
    );
  }

  function render() {
    const isNoShipmentStatuses = isEmpty(shipmentStatuses);

    const cancelShipmentModalVisible = shipmentToCancel ? true : false;

    const isMobile = userInfoService.isMobile();

    if (isMobile) {
      return (
        <ListContainerMobile>
          <ShipmentStatusListMobile shipments={shipmentStatuses} actions={statusActions} />

          {isNoShipmentStatuses && renderEmptyList()}

          <PaginationMobile label="Total Shipments" pagination={pagination} onChange={onPageChange} />
        </ListContainerMobile>
      );
    }

    return (
      <Container withoutPadding={true}>
        <Row>
          <Col sm={12}>
            <ListHeader>Shipment Status</ListHeader>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <ShipmentsTable
              shipments={shipmentStatuses}
              checkedItems={checkedShipments}
              onCheck={onCheckboxClick}
              actions={statusActions}
            />

            {isNoShipmentStatuses && renderEmptyList()}
          </Col>
        </Row>

        <Pagination
          label="Total Shipments"
          pagination={pagination}
          bordered={isNoShipmentStatuses}
          onChange={onPageChange}
        />

        {cancelShipmentModalVisible && (
          <CancelShipmentModal
            visible={cancelShipmentModalVisible}
            close={() => setShipmentToCancel(undefined)}
            cancelOnlyShipment={() => cancelShipmentWithoutPickup(shipmentToCancel?.id)}
            cancelShipmentAndPickup={() => cancelShipmentAndPickup(shipmentToCancel?.id)}
            goBack={goBack}
          />
        )}
      </Container>
    );
  }

  return render();
}

export default ShipmentStatuses;

import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';

import uiHelper from 'helpers/uiHelper';

import FormHeader from 'components/common/FormHeader';
import SelectInput from 'components/common/SelectInput/SelectInput';
import FormError from 'components/common/FormError';
import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';
import SearchAutosuggest from './components/SearchAutosuggest';
import CommodityPanel from './components/CommodityPanel';

import * as Styled from 'styles/modal';

const StyledHeader = styled(Flex)`
  margin-bottom: 1.9rem;
`;

const StyledSelectInput = styled(SelectInput)`
  min-height: auto;
`;

interface Props {
  visible: boolean;
  close: () => void;
  onApply: () => void;
}

function CommoditySearchModal({visible, close, onApply}: Props) {
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [subCategoryTwo, setSubCategoryTwo] = useState('');
  const [commodityItem, setCommodityItem] = useState<any>();
  // eslint-disable-next-line
  const [searchError, setSearchError] = useState('');

  // eslint-disable-next-line
  const [categoryOptions, setCategoryOptions] = useState<BasicOption[]>([{value: 'pet', label: 'Pet Care'}]);
  // eslint-disable-next-line
  const [subCategoryOptions, setSubCategoryOptions] = useState<BasicOption[]>([
    {value: 'pet_food', label: 'Pet Food And Health Supplies'}
  ]);
  // eslint-disable-next-line
  const [subCategoryTwoOptions, setSubCategoryTwoOptions] = useState<BasicOption[]>([
    {value: 'vitamins', label: 'Vitamins - Calcium'}
  ]);

  useEffect(() => {
    if (!subCategoryTwo) return;

    setCommodityItem({code: '2309.90.8500', description: 'Preparations of a kind used in animal feeding; Other'});
  }, [subCategoryTwo]);

  function onAutoFill() {
    uiHelper.notImplemented();
  }

  function render() {
    return (
      <Styled.CustomModal show={visible} backdrop="static" padding="2.6rem 2.6rem 4rem" onHide={close}>
        <StyledHeader justify="space-between">
          <FormHeader>Schedule B Search</FormHeader>
          <CloseButton onClick={close} />
        </StyledHeader>

        <SearchAutosuggest
          name="description"
          label="Item Description"
          placeholder="Short description of the Item"
          value={description}
          onChange={(field, value) => setDescription(value)}
          onAutoFill={onAutoFill}
        />

        <StyledSelectInput
          name="category"
          label="Category"
          value={category}
          options={categoryOptions}
          onChange={(field, value) => setCategory(value)}
        />

        {category && (
          <StyledSelectInput
            name="subcategory"
            label="Subcategory"
            value={subCategory}
            options={subCategoryOptions}
            onChange={(field, value) => setSubCategory(value)}
          />
        )}

        {subCategory && (
          <StyledSelectInput
            name="subCategoryTwo"
            label="Subcategory 2"
            value={subCategoryTwo}
            options={subCategoryTwoOptions}
            onChange={(field, value) => setSubCategoryTwo(value)}
          />
        )}

        {commodityItem && <CommodityPanel item={commodityItem} onApply={onApply} />}

        {searchError && <FormError message={<div>{searchError}</div>} />}
      </Styled.CustomModal>
    );
  }

  return render();
}

export default CommoditySearchModal;

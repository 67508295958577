import React from 'react';
import styled, {css} from 'styled-components/macro';

import PICKUP_TAB from 'constants/pickupTabs';

import PickupButton from './components/PickupButton';
import ListHeader from 'components/view_history/components/ListHeader';

import {colors, secondaryFont, degree, mediaQueries, mainBorder} from 'styles/shared';

const StyledPickupHeaderContainer = styled.div`
  position: relative;
  @media ${mediaQueries.mobileMax} {
    border-bottom: ${mainBorder};
  }
`;

const StyledHeader = styled(ListHeader)`
  padding: 2.6rem 0 2rem 2.6rem;
  margin: 0;
`;

const StyledScrollingWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  @media ${mediaQueries.mobileMax} {
    margin-top: 1.6rem;
  }
`;

const StyledTabButton = styled.div`
  padding: 1rem 1.5rem;
  cursor: pointer;
  ${secondaryFont};
  flex: 0 0 auto;

  ${props =>
    props.active &&
    css`
      border-bottom: 0.2rem solid ${colors.orange_main};
    `};
`;

const StyledPickupsNumber = styled.span`
  ${degree};
`;

interface Props {
  pickups: object;
  activeTab: string;
  onTabChange: (tab: string) => void;
}

function PickupHeader({pickups, activeTab, onTabChange}: Props) {
  function renderTabButton(key: string, label: string) {
    const isActive = activeTab === key;
    const pickupsNumber = pickups[key]?.length || null;

    return (
      <StyledTabButton active={isActive} onClick={() => onTabChange(key)}>
        {label}
        {pickupsNumber && <StyledPickupsNumber>{pickupsNumber}</StyledPickupsNumber>}
      </StyledTabButton>
    );
  }

  function render() {
    return (
      <StyledPickupHeaderContainer>
        <StyledHeader>Scheduled Pickups</StyledHeader>

        <StyledScrollingWrapper>
          {renderTabButton(PICKUP_TAB.TODAY, 'Today')}
          {renderTabButton(PICKUP_TAB.TOMORROW, 'Tomorrow')}
          {renderTabButton(PICKUP_TAB.FUTURE, 'In Next Seven Days')}
          {renderTabButton(PICKUP_TAB.PAST, 'Past Pickups')}
        </StyledScrollingWrapper>

        <PickupButton />
      </StyledPickupHeaderContainer>
    );
  }

  return render();
}

export default PickupHeader;

import * as actionTypes from 'action_types/lineItemActionTypes';
import lineItemHelper from 'helpers/lineItemHelper';

import initialState from './initialState';
import helper from './reducerHelper';

export interface LineItemState {
  list: LineItem[];
  pagination: Pagination;
}

const lineItemReducer = (state: LineItemState = initialState.lineItem, action) => {
  return helper.handleActions<LineItemState>(state, action, {
    [actionTypes.LOAD_LINE_ITEMS](state, payload: {lineItems: LineItem[]; totalItems: number}) {
      payload.lineItems = lineItemHelper.removeUnusedQuantityUnits(payload.lineItems) as LineItem[];

      state.list = payload.lineItems;
      state.pagination.totalCount = payload.totalItems;
    },
    [actionTypes.REMOVE_LINE_ITEM](state, payload: {id: string}) {
      state.list = [...state.list.filter(item => item.id !== payload.id)];
      state.pagination.totalCount = state.pagination.totalCount - 1;
    },
    [actionTypes.CHANGE_LINE_ITEM_PAGE](state, payload: {activePage: number}) {
      state.pagination.activePage = payload.activePage;
    },
    [actionTypes.SELECT_LINE_ITEM_FROM_SEARCH](state, payload: {lineItem: LineItem}) {
      state.list = [payload.lineItem];
      state.pagination.totalCount = 1;
      state.pagination.activePage = 1;
    }
  });
};

export default lineItemReducer;

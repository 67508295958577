import {isArray} from 'lodash';

import httpHelper from 'helpers/httpHelper';

import entities from 'domain/entities';

export default {
  getOrders,
  createPreparedRequestForOrder
};

function getOrders() {
  let options = {
    page: 1
  };

  return httpHelper.get('/orders', options);
}

async function createPreparedRequestForOrder(orderId): Promise<Draft> {
  let body = {
    orderId
  };

  let response = await httpHelper.post('/create_prep_request_for_order_actions', body);

  return mapResponse(response);
}

//helper methods

function mapResponse(response) {
  if (response.pieces && !isArray(response.pieces)) {
    response.pieces = response.pieces['hydra:member'];
  }

  if (response?.international?.exportLineItems && !isArray(response.international.exportLineItems)) {
    response.international.exportLineItems = response.international.exportLineItems['hydra:member'];
  }

  let result = entities.draft.mapFromPreparedOrderRequest(response);
  return result;
}

const WHO_PAYS = {
  MY_ACCOUNT: 'My Optimal Account',
  RECEIVER: 'Receiver',
  THIRD_PARTY: 'Third Party',
  HOUSE_ACCOUNT: 'House Account',
  DAP: 'DAP',
  DDP: 'DDP'
};

export default WHO_PAYS;

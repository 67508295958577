import React from 'react';
import styled from 'styled-components/macro';

import dateHelper from 'helpers/dateHelper';

import {colors} from 'styles/shared';

const StyledDatePanel = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.grey_concrete};
  height: 5.8rem;
  margin-top: 2rem;
  margin-top: 3rem;
  font-size: 2rem;
  padding-left: 2rem;
  border-radius: 0.6rem;

  strong {
    margin-right: 1rem;
  }
`;

interface Props {
  date: Date;
}

function DatePanel({date}: Props) {
  if (!date) return null;

  return (
    <StyledDatePanel>
      <strong>Shipment Date: </strong> {dateHelper.displayShortDate(date)}
    </StyledDatePanel>
  );
}

export default DatePanel;

import React from 'react';

function PickupTooltip() {
  return (
    <span>
      All delivery dates are estimates only <br /> and in no way a guarantee of actual <br /> delivery date by the
      carrier.
    </span>
  );
}

export default PickupTooltip;

export default {
  setIntercomInfo
};

function setIntercomInfo(info: SupportInfo) {
  const contextData = {
    rateId: info.rateId,
    currentStep: info.currentStep,
    shipmentId: info.shipmentId,
    pageId: info.pageId,
    baseUrl: info.baseUrl,
    createShipmentFlow: info.createShipmentFlow
  };

  const newSettings = {
    ...(window as any).intercomSettings,
    loggedInUser: JSON.stringify(info.loggedInUser),
    workingAsUser: JSON.stringify(info.workingAsUser),
    inspectionUrl: info.inspectionUrl,
    clientId: info.loggedInUser?.id,
    supportUrl: info.supportUrl,
    currentUrl: info.currentUrl,
    draftLink: info.draftLink,
    screenshotId: info.screenshotId,
    lastShippingRate: info.shippingRate,
    lastTotalRate: info.totalRate,
    context: JSON.stringify(contextData)
  };

  (window as any).intercomSettings = newSettings;
}

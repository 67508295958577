import React from 'react';
import styled, {css} from 'styled-components/macro';

import PICKUP_TAB from 'constants/pickupTabs';

import formatHelper from 'helpers/formatHelper';

import Flex from 'components/common/Flex';
import ButtonActionsPopup from 'components/common/ActionsPopup/ButtonActionsPopup';
import ListItemMobile, {StyledLabel, StyledValue} from '../ListItemMobile';
import ItemContainerMobile from '../ItemContainerMobile';

import extras from 'components/view_history/extras';

const StyledButtonRow = styled(Flex)`
  padding: 1rem 0.6rem 2rem;

  ${props =>
    props.isPastTab &&
    css`
      padding: 0;
    `}
`;

interface Props {
  activePickups: Pickup[];
  activeTab: string;
  pickupActions: DropdownAction[];
}

function PickupsListMobile({activePickups, activeTab, pickupActions}: Props) {
  const isPastTab = activeTab === PICKUP_TAB.PAST;

  return (
    <>
      {activePickups.map((pickup: Pickup) => {
        const formattedAddress = formatHelper.displayValue(pickup.formattedAddress);
        const displayPickupWindow = extras.displayPickupWindow(pickup, isPastTab);

        return (
          <ItemContainerMobile key={pickup.id}>
            <StyledLabel>Pickup Address</StyledLabel>
            <StyledValue dangerouslySetInnerHTML={{__html: formattedAddress}} />

            <ListItemMobile label="Contact" value={pickup.contactName} />
            <ListItemMobile label="Pickup Window" value={displayPickupWindow} />
            <ListItemMobile label="Pickup Confirmation Nr." value={pickup.confirmationNumber} bold />

            <StyledButtonRow justify="flex-end" isPastTab={isPastTab}>
              {!isPastTab && <ButtonActionsPopup actions={pickupActions} activeItem={pickup} />}
            </StyledButtonRow>
          </ItemContainerMobile>
        );
      })}
    </>
  );
}

export default PickupsListMobile;

import React from 'react';
import styled from 'styled-components/macro';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';

import FormHeader from 'components/common/FormHeader';
import ActionLink from 'components/common/ActionLink';
import Flex from 'components/common/Flex';
import Button, {ButtonType} from 'components/common/Button';
import NewContacts from './NewContacts';

import * as Styled from 'styles/modal';
import {colors, buttonLeftRightMargin, mediaQueries, authFont} from 'styles/shared';

const StyledTitle = styled(FormHeader)`
  margin-bottom: 2.4rem;
`;

const StyledText = styled.div`
  ${authFont};
  font-weight: 500;
  color: ${colors.darkBlue_main};
`;

const StyledButtons = styled(Flex)`
  margin-top: 3.6rem;

  > button {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media ${mediaQueries.mobileMax} {
    > button {
      width: auto;
      padding: 0 1.6rem;
    }
  }
`;

interface DisplayFields {
  title: string;
  text: string | JSX.Element;
  contactsVisible: boolean;
  closeTitle: string;
  closeButtonType: ButtonType;
  actionTitle?: string;
}

interface Props extends BaseProps {
  visible: boolean;
  errorCode: ErrorCode;
  close: (redirect?: boolean) => void;
  action?: {call: () => void};
}

function CommunicationPopup({visible, errorCode, close, action}: Props) {
  const dispatch = useDispatch();

  function getDisplayFields(): DisplayFields {
    let title = '';
    let text: string | JSX.Element = '';
    let contactsVisible = false;
    let closeTitle = '';
    let actionTitle = '';
    let closeButtonType: ButtonType = 'primary';

    switch (errorCode) {
      case 'FlaggedForFraud':
        title = 'We are having trouble signing you in!';
        text =
          'There seems to be an issue that requires customer service assistance. Please contact us, so we can help!';
        contactsVisible = true;
        closeTitle = 'Close';
        break;

      case 'BadDebtor':
        title = 'Outstanding balance past due.';
        text = 'You have an outstanding balance that is past due. Please contact us, so we can get you shipping again.';
        contactsVisible = true;
        closeTitle = 'Close';
        break;

      case 'NoPasswordSet':
        title = 'Sign in successful!';
        text =
          'Did you know that setting a password allows you access to tools such as shipping history, address book, and commodity manager?';
        closeTitle = 'Not Now';
        actionTitle = 'Set Password';
        break;

      case 'OldImportNumber':
        title = 'Updated Export Number';
        text = 'Please note that your Export number has been updated. Your new number is 000000000.';
        closeTitle = 'Continue';
        break;

      case 'NonOSImportExportNumber':
        title = 'Export/Import number not recognized';
        text =
          'The number entered does not appear to be an OptimalShip export or import number. Please check the number entered, and contact us if the error persists.';
        closeTitle = 'Close';
        break;

      case 'InvalidNumber':
        title = 'Invalid Account number!';
        text = (
          <div>
            This export or import number is not valid for shipping. <br /> Please contact customer service for help. You
            may <br /> continue, however you will not be able to create <br /> documents or schedule a pickup.
          </div>
        );
        contactsVisible = true;
        closeTitle = 'Close';
        actionTitle = 'Continue';
        closeButtonType = 'secondary';
        break;

      case 'InvalidFormat':
        title = 'Unrecognized Export/Import number format';
        text = `The export/import number entered is in an unrecognized format. It should be a 9 digit number beginning with an 8 or a 9.`;
        closeTitle = 'Close';
        break;

      case 'FailedSignInAttempts':
        title = 'Having trouble signing in?';
        text = (
          <div>
            We see you seem to be having some trouble signing in. We're here to help you Monday through Friday 7am-7pm,
            or you can use the <ActionLink title="Forgot Password?" onClick={forgotPasswordAction} /> feature.
          </div>
        );
        contactsVisible = true;
        closeTitle = 'Cancel';
        break;

      case 'EmailNotFound':
        title = 'Email not found';
        text =
          'This email could not be found in our system. Try a different email, or contact customer service if this error persists.';
        closeTitle = 'Close';
        break;

      case 'InvalidCredentials':
        title = 'Invalid Sign-in Information';
        text = (
          <div>
            We see you seem to be having some trouble signing in. We're here to help you Monday through Friday 7am-7pm,
            or you can use the <ActionLink title="Forgot Password?" onClick={forgotPasswordAction} /> feature.
          </div>
        );
        contactsVisible = true;
        closeTitle = 'Cancel';
        break;

      default:
        break;
    }

    return {
      title,
      text,
      contactsVisible,
      closeTitle,
      actionTitle,
      closeButtonType
    };
  }

  function forgotPasswordAction() {
    dispatch(commonActions.toggleResetPasswordModal(true));
    close();
  }

  function handleAction() {
    if (action) action.call();
    close();
  }

  function handleClose() {
    if (errorCode === 'NoPasswordSet' || errorCode === 'OldImportNumber') {
      close(true);
    }

    close();
  }

  function render() {
    const fields = getDisplayFields();

    return (
      <Styled.CustomModal show={visible} backdrop="static" width="52.4rem" onHide={handleClose}>
        <StyledTitle>{fields.title}</StyledTitle>

        <StyledText>{fields.text}</StyledText>

        {fields.contactsVisible && <NewContacts close={handleClose} />}

        <StyledButtons justify="center">
          <Button type={fields.closeButtonType} autoWidth margin={buttonLeftRightMargin} onClick={handleClose}>
            {fields.closeTitle}
          </Button>

          {fields.actionTitle && (
            <Button autoWidth margin={buttonLeftRightMargin} onClick={handleAction}>
              {fields.actionTitle}
            </Button>
          )}
        </StyledButtons>
      </Styled.CustomModal>
    );
  }

  return render();
}

export default CommunicationPopup;

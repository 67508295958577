import COUNTRY_CODE from "./countryCodes/countryCodes";
import INCOMPATIBLE_COUNTRY_CODE_MAPPING from "./countryCodes/incompatibleCountryCodeMapping";

// For use when dial codes are not the same between google-libphonenumber and react phone input.

const INCOMPATIBLE_DIAL_CODE_MAPPING = {
  [COUNTRY_CODE.AMERICAN_SAMOA]: '1684',
  [COUNTRY_CODE.ANGUILLA]: '1264',
  [COUNTRY_CODE.ANTIGUA]: '1268',
  [COUNTRY_CODE.BAHAMAS]: '1242',
  [COUNTRY_CODE.BARBADOS]: '1246',
  [COUNTRY_CODE.BERMUDA]: '1441',
  [COUNTRY_CODE.CANARY_ISLANDS]: '34',
  [COUNTRY_CODE.CAYMAN_ISLANDS]: '1345',
  [COUNTRY_CODE.MARIANA_ISLANDS]: '1670',
  [COUNTRY_CODE.DOMINICA]: '1767',
  [COUNTRY_CODE.GRENADA]: '1473',
  [COUNTRY_CODE.GUAM]: '1671',
  [COUNTRY_CODE.JAMAICA]: '1876',
  [COUNTRY_CODE.MONTSERRAT]: '1664',
  [COUNTRY_CODE.NETHERLAND_ANTILLES]: '599',
  [COUNTRY_CODE.ST_KITTS]: '1869',
  [COUNTRY_CODE.ST_LUCIA]: '1758',
  [INCOMPATIBLE_COUNTRY_CODE_MAPPING[COUNTRY_CODE.ST_MAARTEN]]: '1721',
  [COUNTRY_CODE.ST_VINCENT]: '1784',
  [COUNTRY_CODE.TRINIDAD_AND_TOBAGO]: '1868',
  [COUNTRY_CODE.TURKS_AND_CAICOS]: '1649',
  [COUNTRY_CODE.VIRGIN_ISLANDS_BRITISH]: '1284',
  [COUNTRY_CODE.VIRGIN_ISLANDS_US]: '1340'
};

export default INCOMPATIBLE_DIAL_CODE_MAPPING;

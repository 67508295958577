import React from 'react';
import {Row, Col} from 'components/bootstrap';

import formatHelper from 'helpers/formatHelper';

import SummarySection from './SummarySection';
import SummaryItem from '../../SummaryItem';

interface Props {
  origin: Sending;
  destination: Sending;
  isDomestic: boolean;
}

function SendingSummary({origin, isDomestic, destination}: Props) {
  function renderContact(email, phoneNumber, extension) {
    if (phoneNumber && extension) phoneNumber += ` ext.${extension}`;

    let value = (
      <>
        {email && <div>{email}</div>}
        {phoneNumber && <div>{phoneNumber}</div>}
      </>
    );

    return <SummaryItem label="Contact" value={value} />;
  }

  function renderColumn(isShipper) {
    let shipperInfo = destination;
    let label = 'Receiver';

    if (isShipper) {
      shipperInfo = origin;
      label = 'Shipper';
    }

    let displayExtraValue = val => {
      return val || 'No';
    };

    return (
      <Col lg={6}>
        <SummaryItem label={label} value={shipperInfo.contactName} />

        {renderContact(shipperInfo.email, shipperInfo.phoneNumber, shipperInfo.phoneNumberExtension)}

        <SummaryItem label="Residential" value={formatHelper.displayBooleanValue(shipperInfo?.isResidential)} />
        {!isDomestic && <SummaryItem label="Shippers VAT" value={displayExtraValue(shipperInfo?.VATNumber)} />}
        <SummaryItem label="Company" value={shipperInfo?.companyName} />
        <SummaryItem label="Address" value={shipperInfo.formattedAddress} htmlValue />

        {!isDomestic && (
          <>
            <SummaryItem label="Tax ID" value={displayExtraValue(shipperInfo?.taxId)} />
            <SummaryItem label="EORI" value={displayExtraValue(shipperInfo?.EORINumber)} />
          </>
        )}
      </Col>
    );
  }

  return (
    <SummarySection header="From" additionalHeader="To">
      <Row>
        {renderColumn(true)}
        {renderColumn(false)}
      </Row>
    </SummarySection>
  );
}

export default SendingSummary;

import React from 'react';
import styled from 'styled-components/macro';

import Flex from 'components/common/Flex';

import {colors, errorInfoTitle, largeFont, mediaQueries} from 'styles/shared';

const StyledContainer = styled(Flex)`
  @media ${mediaQueries.mobileMax} {
    flex-direction: column;
    margin-bottom: 3rem;
  }
`;

const StyledIcon = styled.div`
  margin-right: ${props => (props.isIconFirst ? '5rem' : 0)};
  margin-left: ${props => (props.isIconFirst ? 0 : '5rem')};

  @media ${mediaQueries.mobileMax} {
    margin: 0;
  }
`;

const StyledText = styled.div`
  color: ${colors.darkBlue_main};
  max-width: 37rem;

  @media ${mediaQueries.mobileMax} {
    max-width: 30.5rem;
    text-align: center;
  }
`;

const StyledTitle = styled.div`
  ${errorInfoTitle};
  margin-bottom: 0.5rem;
`;

const StyledMessage = styled.div`
  ${largeFont};
`;

interface Props {
  image: string;
  title: string;
  subtitle: string;
  isIconFirst?: boolean;
}

EmptyList.defaultProps = {
  isIconFirst: true
};

function EmptyList({image, title, subtitle, isIconFirst}: Props) {
  let styledIcon = (
    <StyledIcon isIconFirst={isIconFirst}>
      <img alt={title} src={`/images/${image}`} />
    </StyledIcon>
  );

  return (
    <StyledContainer justify="center" align="center">
      {isIconFirst && styledIcon}

      <StyledText>
        <StyledTitle>{title}</StyledTitle>
        <StyledMessage>{subtitle}</StyledMessage>
      </StyledText>

      {!isIconFirst && styledIcon}
    </StyledContainer>
  );
}

export default EmptyList;

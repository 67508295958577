import React from 'react';
import styled from 'styled-components/macro';
import {useHistory} from 'react-router-dom';

import PATH from 'constants/path';

import config from 'config';

import Button from 'components/common/Button';
import Flex from 'components/common/Flex';
import FormHeader from 'components/common/FormHeader';

import * as Styled from 'styles/modal';

import {secondaryFont, buttonLeftRightMargin, buttonLeftMargin} from 'styles/shared';

const StyledText = styled.div`
  ${secondaryFont};
  margin: 1rem 0 3rem;
  width: 60.2rem;
  text-align: center;
`;

interface Props {
  visible: boolean;
  close: () => void;
}

function TemporaryLoginModal({visible, close}: Props) {
  const history = useHistory();

  function continueShipping() {
    close();
    history.push(PATH.PREPARE_SHIPMENT);
  }

  function changePassword() {
    window.open(config.portalChangePassword);
  }

  return (
    <Styled.CustomModal show={visible} backdrop="static" width="68.2rem" padding="4rem 2.6rem 3rem" onHide={close}>
      <Flex justify="center">
        <FormHeader>Attention!</FormHeader>
      </Flex>

      <StyledText>
        You are currently Signed-in with a <strong>temporary password.</strong> We highly recommend that you go to{' '}
        <strong>BillPay</strong> and create your own password for your own safety. However, you can prepare your
        shipment now and change your password later.
      </StyledText>

      <Flex justify="space-between">
        <Button type="secondary" autoWidth onClick={close}>
          Cancel
        </Button>

        <Flex>
          <Button autoWidth margin={buttonLeftRightMargin} onClick={continueShipping}>
            Continue Shipping
          </Button>

          <Button autoWidth margin={buttonLeftMargin} onClick={changePassword}>
            Change Password
          </Button>
        </Flex>
      </Flex>
    </Styled.CustomModal>
  );
}

export default TemporaryLoginModal;

import shipment from './shipment';
import pickup from './pickup';
import address from './address';
import lineItem from './lineItem';

export default {
  shipment,
  pickup,
  address,
  lineItem
};

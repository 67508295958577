import React, {useEffect, useState} from 'react';
import {get} from 'lodash';
import styled from 'styled-components/macro';
import {useSelector, useDispatch} from 'react-redux';

import {AppState} from 'reducers';
import draftActions from 'actions/draftActions';
import commonActions from 'actions/commonActions';

import COMMON from 'constants/common';
import DOCUMENT_PACKAGING_TYPE_OPTIONS from 'constants/packagingTypes/packagingTypeOptions';
import { SHIPMENT_DETAILS_INITIAL } from 'domain/entities/shipmentDataEntity';

import validationHelper from 'helpers/validationHelper';
import customHooks from 'helpers/customHooks';

import RadioInput from 'components/common/RadioInput/RadioInput';
import NumberInput from 'components/common/NumberInput/NumberInput';
import SelectInput from 'components/common/SelectInput/SelectInput';
import CurrencyInput from 'components/common/CurrencyInput';
import TextAreaInput from 'components/common/TextAreaInput';
import InputWithUnits from 'components/common/InputWithUnits';
import RadioWithTextInput from 'components/common/RadioWithTextInput';
import Devider from './Devider';
import WeightTooltip from 'components/tooltips/WeightTooltip';
import TypeOfPackagingTooltip from 'components/tooltips/TypeOfPackagingTooltip';
import JustDocumentTooltip from 'components/tooltips/JustDocumentTooltip';
import DocumentGeneralDescriptionTooltip from 'components/tooltips/DocumentGeneralDescriptionTooltip';
import DocumentDeclaredValueTooltip from 'components/tooltips/DocumentDeclaredValueTooltip';
import SystemUnits from './SystemUnits';

const StyledDocumentWeight = styled(InputWithUnits)`
  min-height: initial;
`;

const StyledDeclaredValue = styled(CurrencyInput)`
  margin-bottom: 2rem;
`;

interface Props {
  documents: Documents;
  currency: string;
  measurementSystem: string;
  onChange: OnChangeHandler;
  onInsuranceChange: OnChangeHandler;
}

function ShippingDocumentsTab({documents, currency, measurementSystem, onChange, onInsuranceChange}: Props) {
  const dispatch = useDispatch();
  const firstRender = customHooks.useFirstRender();
  const initialPackagingType = SHIPMENT_DETAILS_INITIAL.documents.typeOfPackaging;
  const defaultPackagingType = DOCUMENT_PACKAGING_TYPE_OPTIONS[1].value;

  const draft = useSelector((state: AppState) => state.draft.current.data as Draft);
  const errors = useSelector((state: AppState) => state.draft.current.errors);

  const [warningMessage, setWarningMessage] = useState<JSX.Element | undefined>(undefined);

  // Handles case of unused packaging type from a saved draft
  useEffect(() => {
    // Don't change value if is inital blank value from a new draft
    if (documents.typeOfPackaging === initialPackagingType) return;

    const packagingOption = DOCUMENT_PACKAGING_TYPE_OPTIONS.find(item => item.value === documents.typeOfPackaging);

    // Saves custom packaging if packaging type not found.
    if(!packagingOption) {
      documents.typeOfPackaging = defaultPackagingType;
    }
  }, []);

  useEffect(() =>{
    if (firstRender && !draft.isImport) return;

    let packagingType = initialPackagingType;
    if (draft.isImport) packagingType = defaultPackagingType;
    onChange('typeOfPackaging', packagingType);
  }, [draft.isImport]);

  useEffect(() => {
    if (documents.isItJustDocument === false) {
      setWarningMessage(
        <span>
          If your shipment contains more than just <strong>Document(s)</strong>, you should ship it as a{' '}
          <strong>Product</strong>!
        </span>
      );
    }
  }, [documents.isItJustDocument]);

  useEffect(() => {
    if (isDocumentWeightExceeded()) {
      setWarningMessage(
        <span>
          Shipments that weigh more than <strong>5.0 lbs</strong> should be shipped as a <strong>Product</strong>!
        </span>
      );
    }
  }, [documents.documentWeight]);

  useEffect(() => {
    if (!warningMessage) return;

    dispatch(
      commonActions.infoAction({
        text: warningMessage,
        type: 'issue',
        logToRollbar: true,
        rollbarContext: 'Prepare Shipment shipping documents - validation error',
        actionTitle: 'Ship As Product',
        action: () => {
          dispatch(draftActions.modifyDraft(null, 'isDocumentsShipment', false));
        },
        close: () => {
          setWarningMessage(undefined);
        }
      })
    );
  }, [warningMessage]);

  function isDocumentWeightExceeded() {
    return !validationHelper.checkWeightIsUnderLimit(documents.documentWeight, COMMON.DOCUMENT, measurementSystem);
  }

  const documentWeightOptions = [
    {
      value: false,
      label: 'It is 0.5 lbs or less (0.5 lbs is aprox. 40 sheets of paper)'
    },
    {
      value: true,
      label: 'It is above 0.5 lbs',
      alwaysVisible: true,
      element: (
        <StyledDocumentWeight
          name="documentWeight"
          value={documents.documentWeight}
          placeholder="Type in exact weight"
          measurementSystem={measurementSystem}
          isWeightUnit={true}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.documents.documentWeight', '')}
          isWarning={documents.isDocumentWeightAboveHalfPound && !documents.documentWeight}
        />
      )
    }
  ];

  const insuranceOptions: RadioInputOption[] = [
        {
          value: true,
          label: 'Yes (Costs $5.00 for $500 Fixed Coverage)'
        },
        {value: false, label: 'No'}
  ];


  function render() {
    let disabledMode = documents.isItJustDocument === false || isDocumentWeightExceeded();

    return (
      <div>
        <SystemUnits currency={currency} measurementSystem={measurementSystem} />

        <RadioWithTextInput
          name="isDocumentWeightAboveHalfPound"
          label="Which describes the weight of your document shipment?"
          required={true}
          options={documentWeightOptions}
          tooltip={WeightTooltip()}
          value={documents.isDocumentWeightAboveHalfPound}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.documents.isDocumentWeightAboveHalfPound', '')}
        />

        <br />

        <RadioInput
          name="isItJustDocument"
          label="Is it just Document(s)?"
          value={documents.isItJustDocument}
          tooltip={JustDocumentTooltip()}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.documents.isItJustDocument', '')}
        />

        <Devider />

        <NumberInput
          name="numberOfPackages"
          label="Number of Packages"
          value={documents.numberOfPackages}
          required={true}
          disabled={disabledMode}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.documents.numberOfPackages', '')}
        />

        {!draft.isImport && <SelectInput
          name="typeOfPackaging"
          label="Type of Packaging"
          value={documents.typeOfPackaging}
          options={DOCUMENT_PACKAGING_TYPE_OPTIONS}
          required={true}
          disabled={disabledMode}
          tooltip={TypeOfPackagingTooltip()}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.documents.typeOfPackaging', '')}
        />}

        <TextAreaInput
          name="generalDescription"
          label="General description of the Shipment"
          value={documents.generalDescription}
          placeholder="Short description"
          required={true}
          disabled={disabledMode}
          tooltip={DocumentGeneralDescriptionTooltip()}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.documents.generalDescription', '')}
        />

        <StyledDeclaredValue
          name="declaredShipmentValue"
          label="Declared value of the Shipment"
          value={documents.declaredShipmentValue}
          currency={currency}
          disabled={disabledMode}
          tooltip={DocumentDeclaredValueTooltip()}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.documents.declaredShipmentValue', '')}
        />

      <RadioWithTextInput
        name="doYouNeedInsurance"
        label="Would you like to insure this Shipment?"
        options={insuranceOptions}
        value={draft.additionalServices.doYouNeedInsurance}
        onChange={onInsuranceChange}
      />
      </div>
    );
  }

  return render();
}

export default ShippingDocumentsTab;

import draftEntity from './draftEntity';
import pickupEntity from './pickupEntity';
import rateEntity from './rateEntity';
import shipmentDataEntity from './shipmentDataEntity';

export default {
  draft: draftEntity,
  pickup: pickupEntity,
  rate: rateEntity,
  shipmentData: shipmentDataEntity
};

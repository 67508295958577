import UNIT from 'constants/units';
import MEASUREMENT_SYSTEM from 'constants/measurementSystems';
import CARRIER from 'constants/carriers';
import COMMON from 'constants/common';

export default {
  getUnitFromMeasurementSystem,
  defineMeasurementSystem,
  getMeasurementSystemOptions,
  getStandaloneWeightLimitMessage,
  getStandaloneHeightLimitMessage,
  convertWeightBetweenUnits,
  convertDimensionBetweenUnits
};

function getUnitFromMeasurementSystem(measurementSystem, isWeightUnit = false): string {
  let unit = '';

  if (measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL) {
    unit = isWeightUnit ? UNIT.MASS_POUND : UNIT.LENGTH_INCH;
  } else {
    unit = isWeightUnit ? UNIT.MASS_KG : UNIT.LENGTH_CM;
  }

  return unit;
}

function defineMeasurementSystem(isDocument, firstPackage, documentWeightUnit): string {
  let measurementSystem = '';

  if (isDocument) {
    measurementSystem =
      documentWeightUnit === UNIT.MASS_POUND ? MEASUREMENT_SYSTEM.IMPERIAL : MEASUREMENT_SYSTEM.METRIC;
    return measurementSystem;
  }

  if (
    firstPackage.length.unit === UNIT.LENGTH_INCH ||
    firstPackage.width.unit === UNIT.LENGTH_INCH ||
    firstPackage.weight.unit === UNIT.MASS_POUND
  ) {
    measurementSystem = MEASUREMENT_SYSTEM.IMPERIAL;
  } else {
    measurementSystem = MEASUREMENT_SYSTEM.METRIC;
  }

  return measurementSystem;
}

function getMeasurementSystemOptions(): BasicOption[] {
  return [
    {value: MEASUREMENT_SYSTEM.IMPERIAL, label: 'Imperial'},
    {value: MEASUREMENT_SYSTEM.METRIC, label: 'Metric'}
  ];
}

function getStandaloneWeightLimitMessage(measurementSystem, isDomestic, isDocument): string {
  const packageLimits = require('data/packageLimits.json');
  const unit = measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL ? UNIT.MASS_POUND : UNIT.MASS_KG;
  const unitLabel = measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL ? UNIT.POUND_LABEL : UNIT.KG_LABEL;
  const service = isDocument ? COMMON.DOCUMENT : isDomestic ? COMMON.DOMESTIC : CARRIER.DHL;
  const value = packageLimits[service][unit];

  return `How heavy is your item? Please enter a weight up to ${value} ${unitLabel}.`;
}

function getStandaloneHeightLimitMessage(measurementSystem, isDomestic): string {
  const packageLimits = require('data/packageLimits.json');
  const unit = measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL ? UNIT.LENGTH_INCH : UNIT.LENGTH_CM;
  const unitLabel = measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL ? UNIT.INCH_LABEL : UNIT.LENGTH_CM;
  const service = isDomestic ? COMMON.DOMESTIC : CARRIER.DHL;
  let value = packageLimits[service][unit];

  if (typeof value !== 'number') {
    value = packageLimits[service][unit]['height'];
  }

  return `Please enter a height up to ${value} ${unitLabel} to proceed.`;
}

function convertWeightBetweenUnits(weight, unit): number {
  const coefficient = 2.205;

  if (unit === UNIT.MASS_KG) {
    return Math.round(weight * coefficient * 100) / 100;
  }

  if (unit === UNIT.MASS_POUND) {
    return Math.round((weight / coefficient) * 100) / 100;
  }

  return weight;
}

function convertDimensionBetweenUnits(dimension, unit): number {
  const coefficient = 2.54;

  if (unit === UNIT.LENGTH_CM) {
    return Math.round((dimension / coefficient) * 100) / 100;
  }

  if (unit === UNIT.LENGTH_INCH) {
    return Math.round(dimension * coefficient * 100) / 100;
  }

  return dimension;
}

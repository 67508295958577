import React from 'react';
import styled, {css} from 'styled-components/macro';

import validationHelper from 'helpers/validationHelper';

import FormLabelContainer from 'components/common/InputComponents/FormLabelContainer';
import InputError from 'components/common/InputComponents/InputError';

import {colors} from 'styles/shared';
import inputStyles from 'styles/customization/input';

const StyledInputContainer = styled.div`
  ${inputStyles.common.inputContainer};
`;

const StyledSelect = styled.select`
  ${inputStyles.selectInput.inputField};

  ${props =>
    props.error &&
    css`
      border-color: ${colors.red_main};
    `}

  ${props =>
    props.disabled &&
    css`
      background: initial;
    `}
`;

interface Props extends BaseProps {
  id: string;
  name: string;
  value?: string;
  label?: string;
  onChange: OnChangeHandler;
  options: BasicOption[];
  error?: string | ValidationError[];
  required?: boolean;
  disabled?: boolean;
  tooltip: string | JSX.Element;
}

SelectInput.defaultProps = {
  required: false,
  tooltip: '',
  className: '',
  id: '',
  disabled: false
};

function SelectInput({
  name,
  label,
  value,
  onChange,
  options,
  error,
  required,
  tooltip,
  className,
  id,
  disabled
}: Props) {
  const inputOnChange = e => {
    onChange(name, e.target.value);
  };

  const inputError: string = validationHelper.getInputError(error);

  return (
    <StyledInputContainer className={className} id={id}>
      {label && (
        <FormLabelContainer label={label} name={name} required={required} disabled={disabled} tooltip={tooltip} />
      )}

      <StyledSelect
        id={name}
        data-testid={id}
        name={name}
        value={value}
        onChange={inputOnChange}
        disabled={disabled}
        error={inputError}>
        {!value && <option value="select">Select</option>}

        {options.map(opt => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </StyledSelect>

      {inputError && !disabled && <InputError>{inputError}</InputError>}
    </StyledInputContainer>
  );
}

export default SelectInput;

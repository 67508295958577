import React from 'react';

import {Row, Col} from 'components/bootstrap';
import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';
import draftActions from 'actions/draftActions';

import validationHelper from 'helpers/validationHelper';
import uiHelper from 'helpers/uiHelper';

import Container from 'components/common/Container';
import SelectInput from 'components/common/SelectInput/SelectInput';
import FormHeader from 'components/common/FormHeader';

const StyledInput = styled(SelectInput)`
  margin-bottom: 0;
`;

interface Props {
  originCountry: string;
  destinationCountry: string;
}

function CountriesSelect({originCountry, destinationCountry}: Props) {
  const dispatch = useDispatch();

  const countryList = useSelector((state: AppState) => state.shipmentOptions.countryList);
  const errors = useSelector((state: AppState) => state.draft.current.errors);

  const originInnerObjectName = 'shipmentFrom';
  const destinationInnerObjectName = 'shipmentTo';
  const countryField = 'country';

  function onChange(innerObjectName, field, value) {
    const origin = innerObjectName === originInnerObjectName ? value : originCountry;
    const destination = innerObjectName === destinationInnerObjectName ? value : destinationCountry;

    // Check for error and display message. Do not save invalid choice.
    const validationError = validationHelper.validateCountriesForShipment(origin, destination);
    if (validationError) {
      dispatch(commonActions.infoAction(uiHelper.getValidationErrorPopupParameters(validationError)));
      if (!validationError.isWarning) return;
    }

    dispatch(draftActions.modifyDraft(innerObjectName, field, value));
  }

  return (
    <Container>
      <Row>
        <Col lg={6}>
          <FormHeader>Country of Origin:</FormHeader>
        </Col>
        <Col lg={6}>
          <FormHeader>Destination Country:</FormHeader>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <StyledInput
            id="origin-country"
            name={countryField}
            label="Select Country"
            required={true}
            options={countryList}
            value={originCountry}
            onChange={(field, value) => onChange(originInnerObjectName, field, value)}
            error={get(errors, `${originInnerObjectName}.${countryField}`, '')}
          />
        </Col>
        <Col lg={6}>
          <StyledInput
            id="destination-country"
            name={countryField}
            label="Select Country"
            required={true}
            options={countryList}
            value={destinationCountry}
            onChange={(field, value) => onChange(destinationInnerObjectName, field, value)}
            error={get(errors, `${destinationInnerObjectName}.${countryField}`, '')}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default CountriesSelect;

import html2canvas from 'html2canvas';
import Rollbar from 'rollbar';

import SEARCH_PARAMETER from 'constants/searchParameters';

import navigationHelper from 'helpers/navigationHelper';
import config from 'config';
import utils from 'helpers/utils';
import uiHelper from 'helpers/uiHelper';

import stateStorageService from 'services/stateStorageService';
import authService from 'services/authService';
import userInfoService from 'services/userInfoService';

import apis from 'domain/apis';

export default {
  getSupportInfo,
  getSupportRequestDto,
  logToRollbar,
  getMetadata
};

async function getSupportInfo(params: SupportParameters): Promise<SupportInfo> {
  const {user, isShipmentCreatePage, pageId, shipmentId, isExistingDraft, rate, currentStep} = params;

  const authContext: AuthContext = authService.getAuthContext();

  const workingAsUser: any = {};

  if (authContext?.isCsrUserAccount) {
    workingAsUser.id = authContext.ownerId;
    workingAsUser.name = authContext.ownerName;
  }

  const requestId = stateStorageService.getRequestId();

  const inspectionUrl: string = navigationHelper.getInspectUrl(requestId);

  const screenshotId: string = utils.getRandomUid();

  await makeScreenshot(screenshotId, user?.id, shipmentId);

  const currentUrl = window.location.href;

  const info: SupportInfo = {
    loggedInUser: user ? {id: user.id, name: user.name} : undefined,
    workingAsUser,
    baseUrl: config.baseUrl,
    currentUrl,
    inspectionUrl,
    pageId,
    screenshotId
  };

  //add support url for CSR user only
  if (workingAsUser.id) {
    const token = authService.getToken();

    info.supportUrl = `${currentUrl}?${SEARCH_PARAMETER.GLOBAL.LOGIN_AS}=${token}&${SEARCH_PARAMETER.GLOBAL.SWITCH_TO}=${workingAsUser.id}`;
  }

  const unsavedDraft = stateStorageService.getUnsavedDraft();

  if (isShipmentCreatePage && unsavedDraft) {
    const draftId = await autoSaveDraft(unsavedDraft, isExistingDraft);

    if (draftId) window.history.replaceState(null, 'Edit Draft', `/draft/${draftId}`);

    info.shipmentId = shipmentId;
    info.rateId = rate?.id;
    info.shippingRate = rate?.displayOptimalRate;
    info.totalRate = rate?.displayRetailRate;
    info.currentStep = currentStep;
    info.draftLink = draftId ? window.location.href : '';

    info.createShipmentFlow = {
      originContactName: unsavedDraft?.shipmentFrom?.contactName,
      destinationContactName: unsavedDraft?.shipmentTo?.contactName
    };
  }

  return info;
}

async function getSupportRequestDto(draftId, rateId, currentStep, info = '') {
  const requestId = stateStorageService.getRequestId() || undefined;

  let locationDescription = '';
  let message = '';

  if (currentStep) locationDescription = `create shipment > step "${currentStep}"`;

  if (info) {
    let infoData = JSON.parse(info);

    locationDescription = `${infoData?.pageId}`;
    if (infoData?.currentStep) locationDescription += ` > step ${infoData?.currentStep}`;

    message = infoData?.userNotes;
  }

  const response = await makeScreenshot(utils.getRandomUid(), '', draftId);

  const data: SupportRequestDto = {
    draftId,
    rateId,
    requestId,
    locationDescription,
    message,
    context: info,
    screenshotIdRequest: response?.['@_requestId'] || null
  };

  return data;
}

let rollbar = new Rollbar({
  accessToken: config.rollbar.accessToken,
  enabled: config.rollbar.enabled,
  payload: config.rollbar.payload
});

function logToRollbar(message, metaData, user, isError = false, isCritical = false) {
  if (user) {
    const person = {
      id: user.id,
      username: user.name,
      email: user.email
    };

    rollbar.configure({
      payload: {person}
    });
  }

  if (isError) {
    rollbar.error(message, metaData);
  } else if (isCritical) {
    rollbar.critical(message, metaData);
  } else {
    rollbar.info(message, metaData);
  }
}

//helper methods
async function autoSaveDraft(unsavedDraft, isExistingDraft) {
  let response: DraftResponse | null = null;

  if (isExistingDraft) {
    response = await apis.draft.updateDraft(unsavedDraft);
  } else {
    response = await apis.draft.createDraft(unsavedDraft);
  }

  return response?.id;
}

async function makeScreenshot(screenshotId, userId, shipmentId): Promise<ScreenshotResponse | undefined> {
  try {
    const screenshotTarget = document.body;

    let canvas = await html2canvas(screenshotTarget);

    let dataUrl = canvas.toDataURL('image/png');

    let saveLocally = false;

    if (saveLocally) {
      let href = dataUrl.replace('image/png', 'image/octet-stream');
      window.location.href = href; // it will save locally
    }

    const imageData = dataUrl.split(';base64,')[1];

    return await apis.captureImage(imageData, screenshotId, shipmentId, userId);
  } catch (err) {
    uiHelper.logError('Cannot take screenshot');
  }
}

function getMetadata(
  user,
  shipmentId: string | undefined,
  isExistingDraft: boolean | undefined,
  rateId: string | undefined,
  currentStep: string | undefined,
  lastRequestId: string | null,
  errorMessage?: string) {
  return {
    user: user,
    shipmentId: shipmentId,
    unsavedDraft: stateStorageService.getUnsavedDraft(),
    isExistingDraft: isExistingDraft,
    rateId: rateId,
    currentStep: currentStep,
    authContext: authService.getAuthContext(),
    lastRequestId: lastRequestId,
    inspectionUrl: navigationHelper.getInspectUrl(lastRequestId),
    browser: userInfoService.getBrowser(),
    errorMessage: errorMessage
  };
}

import {cloneDeep} from 'lodash';

import UNIT from 'constants/units';
import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import MEASUREMENT_SYSTEM from 'constants/measurementSystems';
import CARRIER from 'constants/carriers';

import validationHelper from 'helpers/validationHelper';

export default {
  getStandartBoxes,
  getDefaultStandaloneItem,
  getInitialStandaloneQuote,
  isDomesticStandaloneQuote,
  isImportStandaloneQuote,
  calculatePackagesTotalWeight,
  getWeightUnitLabel,
  getFreightWarning
};

function getStandartBoxes(): Box[] {
  const standartBoxes: Box[] = [
    {
      name: 'A4 Envelope',
      image: 'envelope.png',
      size: {
        imperial: {length: 12, width: 9, height: 1},
        metric: {length: 30, width: 23, height: 2.5}
      }
    },
    {
      name: 'One or two books',
      image: 'books.png',
      size: {
        imperial: {length: 9, width: 5, height: 1},
        metric: {length: 23, width: 13, height: 2.5}
      }
    },
    {
      name: 'Board Game',
      image: 'board-game.png',
      size: {
        imperial: {length: 15, width: 11, height: 3},
        metric: {length: 38, width: 28, height: 8}
      }
    },
    {
      name: 'Small kitchen appliance',
      image: 'kitchen.png',
      size: {
        imperial: {length: 18, width: 15, height: 13},
        metric: {length: 46, width: 38, height: 33}
      }
    },
    {
      name: 'Moving Box',
      image: 'moving-box.png',
      size: {
        imperial: {length: 30, width: 18, height: 18},
        metric: {length: 76, width: 46, height: 46}
      }
    },
    {
      name: 'US-1 Pallet',
      image: 'pallet.png',
      size: {
        imperial: {length: 48, width: 40, height: 0},
        metric: {length: 122, width: 102, height: 0}
      }
    },
    {
      name: 'hidden-1',
      image: '',
      size: {
        imperial: {length: 0, width: 0, height: 0},
        metric: {length: 0, width: 0, height: 0}
      },
      hidden: true
    },
    {
      name: 'hidden-2',
      image: '',
      size: {
        imperial: {length: 0, width: 0, height: 0},
        metric: {length: 0, width: 0, height: 0}
      },
      hidden: true
    }
  ];

  return standartBoxes;
}

function getDefaultStandaloneItem(): StandaloneItem {
  const defaultStandaloneItem: StandaloneItem = {
    quantity: 0,
    weight: 0,
    length: 0,
    width: 0,
    height: 0
  };

  return defaultStandaloneItem;
}

function getInitialStandaloneQuote(): StandaloneQuote {
  const defaultItem = getDefaultStandaloneItem();

  const standaloneQuote: StandaloneQuote = {
    sendingFrom: {
      country: '',
      city: '',
      postalCode: '',
      isResidential: false
    },
    sendingTo: {
      country: '',
      city: '',
      postalCode: '',
      isResidential: false
    },
    shipDate: new Date(),
    items: [cloneDeep(defaultItem)],
    hasDangerousGoods: false,
    isDocument: false,
    usesExpressEnvelope: false,
    insuranceValue: 0,
    currency: UNIT.CURRENCY_USD,
    measurementSystem: MEASUREMENT_SYSTEM.IMPERIAL,
    carrierProduct: ''
  };

  return standaloneQuote;
}

function isDomesticStandaloneQuote(standaloneQuote: StandaloneQuote): boolean {
  const countryFrom = standaloneQuote.sendingFrom.country;
  const countryTo = standaloneQuote.sendingTo.country;

  return countryFrom === COUNTRY_CODE.USA && countryTo === COUNTRY_CODE.USA;
}

function isImportStandaloneQuote(standaloneQuote: StandaloneQuote): boolean {
  const countryFrom = standaloneQuote.sendingFrom.country;

  return countryFrom !== COUNTRY_CODE.USA;
}

function calculatePackagesTotalWeight(packages: StandaloneItem[]): number {
  let totalWeight = 0;

  packages.forEach(item => {
    totalWeight += item.quantity * item.weight;
  });

  return totalWeight;
}

function getWeightUnitLabel(measurementSystem: string): string {
  return measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL ? UNIT.POUND_LABEL : UNIT.KG_LABEL;
}

function getFreightWarning(standaloneQuote: StandaloneQuote, totalWeight: number): string {
  const isDomestic = isDomesticStandaloneQuote(standaloneQuote);
  const carrier = isDomestic ? CARRIER.UPS : CARRIER.DHL;

  const measurementSystem = standaloneQuote.measurementSystem;
  const weightUnitLabel = getWeightUnitLabel(measurementSystem);

  const totalWeightLimit = getTotalWeightExceedingLimits(totalWeight, carrier, measurementSystem);
  const pieceWeightLimit = getPieceWeightExceedingLimits(standaloneQuote.items, carrier, measurementSystem);

  if (totalWeightLimit) {
    return `Your total weight has surpassed ${totalWeightLimit} ${weightUnitLabel}. It qualifies as a Freight Quote.`;
  } else if (pieceWeightLimit) {
    return `Your piece weight has surpassed ${pieceWeightLimit} ${weightUnitLabel}. It qualifies as a Freight Quote.`;
  }

  const packageExceedsLimit: boolean = validationHelper.checkPackageExceedsSizeLimit(
    standaloneQuote.items,
    carrier,
    isDomestic,
    measurementSystem
  );

  return packageExceedsLimit ? 'Your package exceeds the maximum size constraints' : '';
}

function getTotalWeightExceedingLimits(
  totalWeight: number,
  carrier: string,
  measurementSystem: string
): number | null {
  const totalWeightIsUnderLimit: boolean = validationHelper.totalWeightIsUnderLimit(
    totalWeight,
    carrier,
    measurementSystem
  );

  if (!totalWeightIsUnderLimit) {
    const totalWeightLimit: number = validationHelper.getWeightLimit(carrier, measurementSystem, true);
    return totalWeightLimit;
  }

  return null;
}

function getPieceWeightExceedingLimits(
  items: StandaloneItem[],
  carrier: string,
  measurementSystem: string
): number | null {
  let weightLimit: number | null = null;

  items.forEach(item => {
    if (!validationHelper.checkWeightIsUnderLimit(item.weight, carrier, measurementSystem)) {
      weightLimit = validationHelper.getWeightLimit(carrier, measurementSystem);
    }
  });

  return weightLimit;
}

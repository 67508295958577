import React from 'react';
import styled from 'styled-components/macro';

import {colors, mainFont, opacity} from 'styles/shared';

const StyledContainer = styled.div`
  background: ${colors.red_light};
  border: 0.1rem solid ${colors.red_main};
  border-radius: 0.6rem;
  color: ${colors.red_main};
  ${mainFont};
  padding: 1rem 1rem 2rem 2rem;

  & div {
    margin-top: 1rem;
  }
`;

const StyledSign = styled.div`
  float: right;
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  background: ${colors.white};
  box-shadow: 0 0 1.6rem ${opacity(0.26, colors.red_main)};
  font-weight: 900;
  font-size: 4.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.03rem;
  color: ${colors.red_main};
  margin-top: 0 !important;
`;

interface Props extends BaseProps {
  message?: JSX.Element;
}

function FormError({message, className}: Props) {
  return (
    <StyledContainer className={className}>
      <StyledSign>!</StyledSign>
      {message}
    </StyledContainer>
  );
}

export default FormError;

import React from 'react';
import styled from 'styled-components/macro';
import {lighten} from 'polished';

import {colors, mainFont, mainBorder, opacity} from 'styles/shared';

const WIDTH = '9.6rem';

const StyledContainer = styled.label`
  position: relative;
  float: right;
  width: ${WIDTH};
  margin: 1.5rem 0;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .toggle-slider {
    background-color: ${colors.orange_lighter};
    border-color: ${colors.orange_lighter};
  }

  &:checked + .toggle-slider:before {
    background-color: ${colors.orange_main};
    border-color: ${colors.orange_main};
    transform: translateX(5.5rem);
  }

  &:focus + .toggle-slider {
    border-color: ${lighten(0.1, colors.orange_main)};
  }

  &:focus + .toggle-slider:before {
    border-color: ${lighten(0.1, colors.orange_main)};
    background-color: ${lighten(0.2, colors.orange_main)};
    color: ${colors.black};
  }
`;

const StyledSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: -1rem;
  width: ${WIDTH};
  height: 4rem;
  border-radius: 2.7rem;
  border: ${mainBorder};
  background-color: ${colors.darkBlue_lighter};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 0.2rem 0.1rem ${opacity(0.1)};
  display: flex;
  align-items: center;
  justify-content: ${props => (props.togglerLabel === NO ? 'flex-end' : 'flex-start')};
  padding: 0.9rem 1.3rem;
  color: ${colors.darkBlue_light};
  ${mainFont};

  &:before {
    position: absolute;
    content: ${props => `"${props.togglerLabel}"`};
    height: 5.2rem;
    width: 5.2rem;
    right: 5rem;
    bottom: -0.8rem;
    border: ${mainBorder};
    border-radius: 50%;
    background-color: ${colors.white};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: 0.1rem 0.1rem 0.3rem ${opacity(0.3)};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => (props.togglerLabel === YES ? colors.white : colors.darkBlue_main)};
  }

  &:after {
    position: absolute;
    content: '';
    height: 2.6rem;
    width: 5rem;
    left: -0.5rem;
    bottom: -0.9rem;
  }
`;

const YES = 'Yes';
const NO = 'No';

interface Props {
  name: string;
  value: boolean;
  namePrefix: string;
  onToggle: OnToggleHandler;
}

function TogglerWithLabels({name, value, namePrefix, onToggle}: Props) {
  let onChange = () => {
    onToggle(name, !value);
  };

  let uniqId = namePrefix ? `${namePrefix}-${name}` : name;

  let sliderLabel = value ? NO : YES;

  let togglerLabel = value ? YES : NO;

  return (
    <StyledContainer htmlFor={uniqId}>
      <StyledInput type="checkbox" id={uniqId} checked={value} onChange={onChange} />
      <StyledSlider className="toggle-slider" togglerLabel={togglerLabel}>
        {sliderLabel}
      </StyledSlider>
    </StyledContainer>
  );
}

export default TogglerWithLabels;

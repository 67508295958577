import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" fill="none" viewBox="0 0 30 28">
      <path
        stroke={colors.darkBlue_main}
        d="M26 8.647V5.06a1 1 0 00-1-1H9.577a1 1 0 01-.656-.246L5.97 1.246A1 1 0 005.313 1H2a1 1 0 00-1 1v21.941S1 27 3.734 27c2.735 0 2.735-3.059 2.735-3.059"></path>
      <path
        stroke={colors.darkBlue_main}
        d="M4 27h22a3 3 0 003-3V10a1 1 0 00-1-1h-4.385M6.5 24V10a1 1 0 011-1h9.962"></path>
      <path stroke={colors.darkBlue_main} strokeLinecap="round" d="M3.5 10v14"></path>
      <path
        stroke={colors.darkBlue_main}
        d="M20.189 15.823l-2.689 2.14V7a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v10.963l-2.689-2.14-.311-.248-.311.248z"></path>
    </svg>
  );
}

export default Icon;

import React, {useState} from 'react';
import styled, {css} from 'styled-components/macro';
import {Row, Col} from 'components/bootstrap';

import FEATURE from 'constants/features';
import COUNTRY_CODE from 'constants/countryCodes/countryCodes';

import uiHelper from 'helpers/uiHelper';
import formatHelper from 'helpers/formatHelper';

import rateService from 'services/rateService';

import TotalSum from 'components/common/TotalSum';
import FormHeader from 'components/common/FormHeader';
import Button from 'components/common/Button';
import Flex from 'components/common/Flex';
import CloseButton from 'components/common/CloseButton';
import IncompleteRate from 'components/common/DomesticRates/components/IncompleteRate';

import * as StyledModal from 'styles/modal';

import {secondaryFont, opacity, mediaQueries} from 'styles/shared';

const MODAL_HEIGHT = FEATURE.QUOTE_MODAL_SHIPMENT_DETAILS ? '89.3rem' : '81.3rem';
const MODAL_FOOTER_HEIGHT = '54.4rem';
const MODAL_HEADER_HEIGHT = '6.8rem';
const MARGIN_TOP = '2rem';
const MARGIN_BOTTOM = '3.5rem';

const StyledQuoteModal = styled(StyledModal.CustomModal)`
  @media ${mediaQueries.mobileMax} {
    .modal-content {
      height: auto !important;
    }
  }
`;

const StyledModalHeader = styled.div`
  height: ${MODAL_HEADER_HEIGHT};
  padding: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${props =>
    props.withBorder &&
    css`
      box-shadow: 0 0.4rem 2rem ${opacity(0.12)};
    `}
`;

const StyledBody = styled.div`
  padding: 0 2rem;
  max-height: calc(${MODAL_HEIGHT} - ${MODAL_FOOTER_HEIGHT} - ${MODAL_HEADER_HEIGHT});
  overflow-y: auto;
  ${secondaryFont};

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 2rem;
`;

const StyledBodyTitle = styled(FormHeader)`
  margin-bottom: 1rem;
`;

const StyledLabel = styled.div`
  font-weight: bold;
`;

const StyledDivider = styled.div`
  height: 1rem;
`;

const StyledFooter = styled.div`
  position: absolute;
  bottom: 0;
  padding: 3rem 2.6rem 2rem 2.6rem;
  width: 100%;
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};

  @media ${mediaQueries.mobileMax} {
    position: initial;
    padding: 2rem;
  }
`;

const StyledTotal = styled(TotalSum)`
  padding: 0;
`;

const StyledTitle = styled(FormHeader)`
  margin-top: ${MARGIN_TOP};
`;

const StyledText = styled.div`
  margin: 1rem 0 ${MARGIN_BOTTOM};
  ${secondaryFont};
`;

const StyledContainer = styled.div`
  padding-top: ${MARGIN_TOP};
  padding-bottom: ${MARGIN_BOTTOM};
`;

interface Props {
  visible: boolean;
  quote: Quote;
  close: () => void;
}

function QuoteModal({visible, quote, close}: Props) {
  const rate = quote.rates[0];
  const isIncompleteRate = rate ? rateService.isIncompleteRate(rate) : false;

  const [scrollActive, setScrollActive] = useState(false);

  function trackingScroll() {
    let element = document.getElementById('quote-modal-body');

    if (!element) return;

    if (element.scrollTop === 0) {
      if (scrollActive) setScrollActive(false);
    } else {
      if (!scrollActive) setScrollActive(true);
    }
  }

  function renderQuoteDetails() {
    return (
      <>
        <StyledRow>
          <Col xs={6} lg={6}>
            <StyledBodyTitle>From:</StyledBodyTitle>

            <StyledLabel>Country:</StyledLabel>
            <div>{formatHelper.displayValue(quote.requestOriginCountryCode)}</div>
            <StyledDivider />

            <StyledLabel>Zip:</StyledLabel>
            <div>{formatHelper.displayValue(quote.requestOriginZip)}</div>
            <StyledDivider />
          </Col>

          <Col xs={6} lg={6}>
            <StyledBodyTitle>To:</StyledBodyTitle>

            <StyledLabel>Country:</StyledLabel>
            <div>{formatHelper.displayValue(quote.requestDestinationCountryCode)}</div>
            <StyledDivider />

            <StyledLabel>Zip:</StyledLabel>
            <div>{formatHelper.displayValue(quote.requestDestinationZip)}</div>
            <StyledDivider />

            <StyledLabel>Residential:</StyledLabel>
            <div>{formatHelper.displayBooleanValue(quote.requestIsResidential)}</div>
          </Col>
        </StyledRow>

        {FEATURE.QUOTE_MODAL_SHIPMENT_DETAILS && (
          <>
            <Row>
              <Col>
                <StyledBodyTitle>Shipment Details:</StyledBodyTitle>
              </Col>
            </Row>

            <StyledRow>
              <Col xs={6} lg={6}>
                <StyledLabel>Package specs:</StyledLabel>
                <div>-</div>
                <StyledDivider />

                <StyledLabel>Overall weight of the Shipment:</StyledLabel>
                <div>-</div>
                <StyledDivider />

                <StyledLabel>General description of the Content:</StyledLabel>
                <div>-</div>
                <StyledDivider />

                <StyledLabel>Type of Packaging:</StyledLabel>
                <div>-</div>
                <StyledDivider />

                <StyledLabel>Shipment insurance:</StyledLabel>
                <div>-</div>
                <StyledDivider />

                <StyledLabel>Neutral delivery Service:</StyledLabel>
                <div>-</div>
                <StyledDivider />

                <StyledLabel>Duties & Taxes:</StyledLabel>
                <div>-</div>
              </Col>

              <Col xs={6} lg={6}>
                <StyledLabel>Shipment Date:</StyledLabel>
                <div>-</div>
                <StyledDivider />

                <StyledLabel>Pickup time:</StyledLabel>
                <div>-</div>
                <StyledDivider />

                <StyledLabel>Location of pickup:</StyledLabel>
                <div>-</div>
                <StyledDivider />

                <StyledLabel>Note for Driver:</StyledLabel>
                <div>-</div>
                <StyledDivider />

                <StyledLabel>Service:</StyledLabel>
                <div>-</div>
              </Col>
            </StyledRow>
          </>
        )}
      </>
    );
  }

  function render() {
    const isDomesticQuote =
      quote.requestDestinationCountryCode === quote.requestOriginCountryCode &&
      quote.requestDestinationCountryCode === COUNTRY_CODE.USA;

    return (
      <StyledQuoteModal show={visible} height={MODAL_HEIGHT} padding="0" onHide={close}>
        <StyledModalHeader withBorder={scrollActive}>
          <CloseButton onClick={close} />
        </StyledModalHeader>

        <StyledBody id="quote-modal-body" onScroll={trackingScroll}>
          {renderQuoteDetails()}
        </StyledBody>

        <StyledFooter>
          <StyledTotal rate={rate} isDomestic={isDomesticQuote} isIncompleteRate={isIncompleteRate} />

          {isIncompleteRate ? (
            <StyledContainer>
              <IncompleteRate />
            </StyledContainer>
          ) : (
            <>
              <StyledTitle>Do you like the quote?</StyledTitle>

              <StyledText>
                This quote is showing you expenses if you ship with OptimalShip. <br /> And if you want - you can do
                just that! Even better - you can use the information you have already entered here to create a shipment
                and even schedule a pickup for it directly in OptimalShip.
              </StyledText>
            </>
          )}

          <Flex justify="center">
            <Button type="secondary" onClick={uiHelper.notImplemented}>
              Create Shipping Label
            </Button>
          </Flex>
        </StyledFooter>
      </StyledQuoteModal>
    );
  }

  return render();
}

export default QuoteModal;

import config from 'config';

type EventType =
  | 'Shipment Prep Started'
  | 'Prepare Shipment Attempted'
  | 'Shipment Prep Completed'
  | 'Clicking to open Address Book'
  | 'Shipment Prep: step'
  | 'Shipment Prep Revise Pickup After Address Change'
  | 'Clicking to Import Contact';

export default {
  identify,
  track,
  trackDraftStep
};

function identify(user: User) {
  if (config.isDevLocal) return;

  const id = user.id;
  const name = user.name;
  const clientId = user?.billingInformation?.osClientId;

  const identifier = clientId ? `${name}#${clientId}` : `${name}#${id}`;

  const data = {
    id,
    identifier,
    email: user.email,
    name,
    clientId,
    os: getOS(),
    isClient: user.isClient,
    isGuest: user.isGuest,
    isCsr: user.isCSR,
    isAdmin: user.isAdmin,
    isSuperAdmin: user.isSuperAdmin
  };

  const heap = (window as any).heap;

  if (!heap) return;

  if (!user.isGuest) heap.identify(identifier);
  heap.addUserProperties(data);
}

function track(type: EventType, data?: any) {
  const heap = (window as any).heap;

  if (!heap) return;

  if (data) {
    heap.track(type, JSON.stringify(data));
  } else {
    heap.track(type);
  }
}

function trackDraftStep(type: EventType, stepNumber: number) {
  const heap = (window as any).heap;

  if (!heap) return;

  const eventName = `${type} ${stepNumber}`;
  heap.track(eventName);
}

//helper methods

function getOS() {
  const userAgent = window?.navigator?.userAgent;
  const platform = window?.navigator?.platform;

  const macosPlatforms: string[] = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms: string[] = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms: string[] = ['iPhone', 'iPad', 'iPod'];

  let os = '';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

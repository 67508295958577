import styled from 'styled-components/macro';

import {colors, mainBorder, mediaQueries} from 'styles/shared';

export const container = styled.div`
  width: 54.6rem;
  margin: 2rem 0;
  background: ${colors.white};
  border: ${mainBorder};
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: 2.4rem 2.6rem 2rem;
  position: relative;

  @media ${mediaQueries.mobileMax} {
    width: auto;
  }
`;

import React from 'react';

import PAYMENT_METHOD from 'constants/paymentMethods';

import Button, {ButtonType} from 'components/common/Button';
import Flex from 'components/common/Flex';

interface Props extends BaseProps {
  paymentMethod: string;
  type: ButtonType;
  margin: string;
  onSaveAction: (e) => void;
}

PaymentButton.defaultProps = {
  type: 'primary',
  margin: '2rem 0 0.4rem'
};

function PaymentButton({type, margin, paymentMethod, onSaveAction}: Props) {
  const title = paymentMethod === PAYMENT_METHOD.CREDIT_CARD ? 'Save Card' : 'Save ACH';

  return (
    <Flex justify="center">
      <Button type={type} autoWidth margin={margin} onClick={onSaveAction}>
        {title}
      </Button>
    </Flex>
  );
}

export default PaymentButton;

import React from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components/macro';

import ActionLink from 'components/common/ActionLink';

import commonActions from 'actions/commonActions';
import { colors, mainFont } from 'styles/shared';

const StyledIncompleteRate = styled.div`
  padding: 1rem;
  margin-left: 1rem;
  background: ${colors.red_light};
  border-radius: 0.8rem;
  ${mainFont};
  font-weight: 400;
`;

function IncompleteRate() {
  const dispatch = useDispatch();

  function infoAction() {
    dispatch(
      commonActions.infoAction({
        type: 'info',
        text: 'There seems to be an issue with the Quote you have requested. Our team is looking into it. We will get back to you shortly.',
        closeTitle: 'Cancel',
        close: () => {}
      })
    );
  }

  return (
    <StyledIncompleteRate>Something went wrong, but we are working to fix it. You can contact{' '}
    <ActionLink title="Customer Service" isInheritFont onClick={infoAction} /> to get your Quote immediately!</StyledIncompleteRate>
  );
}

export default IncompleteRate;

import React from 'react';
import {DropdownButton as DropDownButton, Dropdown} from 'components/bootstrap';
import styled, {css} from 'styled-components/macro';

import {colors, mainFont, boxShadow} from 'styles/shared';

const StyledDropdownButton = styled(DropDownButton)`
  > button {
    height: 4.8rem;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border: none;
    color: ${colors.orange_main};
    ${mainFont};

    &.dropdown-toggle {
      background-color: ${colors.white}!important;
      border: none;
      color: ${colors.orange_main}!important;
    }
    &:hover {
      background-color: transparent !important;
      border: none;
      color: ${colors.orange_main};
    }
    &:active,
    &:focus {
      background-color: transparent !important;
      color: ${colors.orange_main}!important;
      box-shadow: none !important;
    }
    &:after {
      vertical-align: middle;
      margin-left: 1rem !important;

      ${props =>
        props.drop === 'up'
          ? css`
              border-right: 1.4rem solid transparent !important;
              border-bottom: 1.4rem solid !important;
              border-left: 1.4rem solid transparent !important;
            `
          : css`
              border-top: 1.4rem solid;
              border-right: 1.4rem solid transparent;
              border-left: 1.4rem solid transparent;
            `}
    }
  }

  .dropdown-menu {
    box-shadow: ${boxShadow};
    border-radius: 0.6rem;
    padding: 0.8rem 0;
  }

  .dropdown-item {
    ${mainFont};
    padding: 0.8rem 1.6rem 0.8rem;
    color: ${colors.darkBlue_light};

    & sup {
      color: ${colors.orange_main};
      font-weight: bold;
    }

    &.active {
      color: ${colors.darkBlue_main};
      background-color: transparent;
    }
  }
`;

interface Props extends BaseProps {
  value?: string;
  options: DropdownOption[];
  direction: 'up' | 'down';
  onChange: (value) => void;
}

DropdownAction.defaultProps = {
  direction: 'down'
};

function DropdownAction({value, options, direction, onChange, className}: Props) {
  const selectedOption = options.find(option => option.value === value);
  const title = selectedOption?.label || options[0].label;

  return (
    <StyledDropdownButton title={title} drop={direction} className={className}>
      {options.map(option => {
        const active = option.value === value;

        return (
          <Dropdown.Item
            key={option.value}
            eventKey={option.value}
            active={active}
            onSelect={eventKey => onChange(eventKey)}>
            {option.label}
            {option.counter && <sup>{option.counter}</sup>}
          </Dropdown.Item>
        );
      })}
    </StyledDropdownButton>
  );
}

export default DropdownAction;

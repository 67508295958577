import React, {Fragment} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled, {css} from 'styled-components/macro';

import {AppState} from 'reducers';
import addressActions from 'actions/addressActions';

import CheckBoxInput from 'components/common/CheckboxInput';
import Pagination from 'components/common/Pagination';
import AppIcon from 'components/common/AppIcon';
import Flex from 'components/common/Flex';
import EmptyList from './EmptyList';

import * as Styled from 'styles/table';
import {colors} from 'styles/shared';

const StyledTableContainer = styled.div`
  ${props =>
    props.anyItems &&
    css`
      height: 74rem;
      overflow-y: auto;
    `}
`;

const LoadingContainer = styled(Flex)`
  margin-top: 20rem;
`;

interface Props {
  items: SavedAddress[];
  editMode: boolean;
  isLoading: boolean;
  checkedItems: string[];
  toggleCheckbox: (field: string) => void;
  onSelect: (address: SavedAddress) => void;
  checkedAll: boolean;
  selectAllToggle: () => void;
}

function AddressList({
  items,
  editMode,
  isLoading,
  checkedItems,
  toggleCheckbox,
  onSelect,
  checkedAll,
  selectAllToggle
}: Props) {
  const dispatch = useDispatch();

  const pagination = useSelector((state: AppState) => state.address.pagination);

  function onSelectAddress(address) {
    if (editMode) return;

    onSelect(address);
  }

  function onPageChange(page) {
    dispatch(addressActions.updateAddressesPageNumber(page));
  }

  function render() {
    const anyItems = items?.length ? true : false;

    const preSelected = items.length !== checkedItems.length && checkedItems.length > 0;

    return (
      <>
        <StyledTableContainer anyItems={anyItems}>
          <Styled.Table>
            <thead>
              <Styled.TRowHead topBorder>
                {editMode && (
                  <Styled.THeadCell>
                    <CheckBoxInput
                      name="checkedAll"
                      value={checkedAll}
                      preSelected={preSelected}
                      onToggle={selectAllToggle}
                    />
                  </Styled.THeadCell>
                )}
                <Styled.THeadCell>Company Name</Styled.THeadCell>
                <Styled.THeadCell>Address</Styled.THeadCell>
                <Styled.THeadCell>Country</Styled.THeadCell>
                <Styled.THeadCell>Contact</Styled.THeadCell>
              </Styled.TRowHead>
            </thead>

            <tbody>
              {items.map(item => {
                const id = item.id;

                let isChecked = checkedItems.find(i => i === id) ? true : false;

                let contactDisplay = item.contactName;
                if (item.phoneNumber) contactDisplay += `, ${item.phoneNumber}`;

                return (
                  <Fragment key={id}>
                    <Styled.TRowBody checked={isChecked} clickable={!editMode} onClick={() => onSelectAddress(item)}>
                      {editMode && (
                        <Styled.TBodyCell>
                          <CheckBoxInput name={id} value={isChecked} onToggle={toggleCheckbox} />
                        </Styled.TBodyCell>
                      )}
                      <Styled.TBodyCell bold>{item.companyName}</Styled.TBodyCell>
                      <Styled.TBodyCell dangerouslySetInnerHTML={{__html: item.formattedAddress}} />
                      <Styled.TBodyCell>{item.country}</Styled.TBodyCell>
                      <Styled.TBodyCell>{contactDisplay}</Styled.TBodyCell>
                    </Styled.TRowBody>
                  </Fragment>
                );
              })}
            </tbody>
          </Styled.Table>
        </StyledTableContainer>

        {!anyItems && !isLoading && <EmptyList />}
        {isLoading && (
          <LoadingContainer justify="center">
            <AppIcon name="loading" size="10x" color={colors.darkBlue_lighter} spin />
          </LoadingContainer>
        )}

        <Pagination pagination={pagination} onChange={onPageChange} />
      </>
    );
  }

  return render();
}

export default AddressList;

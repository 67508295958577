import React from 'react';

function WeightTooltip() {
  return (
    <span>
      Documents weighing up to 0.5 Lbs will be <br /> shiped as <strong>DHL Express Document</strong>. <br /> Documents
      weighing 0.6 Lbs - 5 Lbs <br />
      wil be shiped as <strong>Non-Express Document</strong>. <br /> Documents weighing over 5 Lbs must be <br />{' '}
      shipped as a <strong>Product!</strong>
    </span>
  );
}

export default WeightTooltip;

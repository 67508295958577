import React from 'react';
import styled, {css} from 'styled-components';

import {mainFont, colors} from 'styles/shared';

const StyledBadge = styled.div`
  background: url(/images/standalone/domestic-tooltip.png) no-repeat scroll;
  font-family: 'Nunito Sans Italic';
  font-weight: 900;
  color: ${colors.white};
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0.5rem;

  ${props =>
    props.size === 'small' &&
    css`
      background-size: 100%;
      height: 7rem;
      width: 7rem;
      font-size: 1.1rem;
      line-height: 1.5rem;
      letter-spacing: 0.03rem;
    `};

  ${props =>
    props.size === 'large' &&
    css`
      height: 10rem;
      width: 10rem;
      ${mainFont};
    `};
`;

interface Props extends BaseProps {
  size: 'small' | 'large';
}

function Badge({size, children, className}: Props) {
  return (
    <StyledBadge size={size} className={className}>
      {children}
    </StyledBadge>
  );
}

export default Badge;

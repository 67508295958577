import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';
import userActions from 'actions/userActions';

import MESSAGE from 'constants/literals/messages';

import validationHelper from 'helpers/validationHelper';
import uiHelper from 'helpers/uiHelper';

import authService from 'services/authService';

import FormHeader from 'components/common/FormHeader';
import TextInput from 'components/common/TextInput';
import Flex from 'components/common/Flex';
import Button from 'components/common/Button';
import AppIcon from 'components/common/AppIcon';

import * as Styled from 'styles/modal';
import {buttonLeftMargin, buttonLeftRightMargin, middleFont} from 'styles/shared';

const StyledFormHeader = styled(FormHeader)`
  text-align: center;
`;

const StyledIconContainer = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 2.3rem;
  text-align: center;
`;

const StyledTitle = styled.div`
  ${middleFont};
  margin-bottom: 2.5rem;
  font-family: 'Nunito Sans Italic';
  text-align: center;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 0;
`;

const StyledButtonsRow = styled(Flex)`
  margin-top: 3.6rem;
`;

interface Props {
  visible: boolean;
  close: () => void;
}

function ChangePasswordModal({visible, close}: Props) {
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({password: '', confirmPassword: ''});

  function formIsValid() {
    let newErrors = {
      password: '',
      confirmPassword: ''
    };

    if (!password) {
      newErrors.password = 'Password field is required.';
    } else if (password.length < 8) {
      newErrors.password = 'This value is too short. It should have 8 characters or more.';
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = 'Confirm password field is required.';
    }

    if (password && confirmPassword && password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match.';
    }

    setErrors(newErrors);

    return validationHelper.isEmptyErrorObject(newErrors);
  }

  async function createNewPassword(e) {
    if (e) e.preventDefault();

    if (!formIsValid()) return;

    const response: any = await dispatch(userActions.changePassword(password));
    const result = response as ResetPasswordResponse;

    if (result?.success) {
      closeAction();
      uiHelper.showNotice(MESSAGE.CHANGE_PASSWORD);
      return;
    }

    dispatch(
      commonActions.infoAction({
        type: 'error',
        text: result?.message || 'Could not reset password. Please try to reset your password again.',
        close: () => null
      })
    );
  }

  function closeAction() {
    //clear recovery code
    authService.saveRecoveryCode(null);
    close();
  }

  function render() {
    const disabled = !password && !confirmPassword;

    return (
      <Styled.CustomModal show={visible} backdrop="static" padding="2.6rem 2.6rem 4rem" onHide={closeAction}>
        <StyledFormHeader>Need a new password?</StyledFormHeader>

        <StyledIconContainer>
          <AppIcon name="bulb" />
        </StyledIconContainer>

        <StyledTitle>Please type in your new password!</StyledTitle>

        <StyledTextInput
          name="password"
          label="New Password"
          type="password"
          value={password}
          placeholder="New Password"
          onChange={(field, value) => setPassword(value)}
          error={errors.password}
        />

        <StyledTextInput
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          value={confirmPassword}
          onChange={(field, value) => setConfirmPassword(value)}
          placeholder="Confirm Password"
          error={errors.confirmPassword}
        />

        <StyledButtonsRow justify="flex-end">
          <Button type="secondary" autoWidth margin={buttonLeftRightMargin} onClick={closeAction}>
            Cancel
          </Button>
          <Button autoWidth margin={buttonLeftMargin} disabled={disabled} onClick={createNewPassword}>
            Save new password
          </Button>
        </StyledButtonsRow>
      </Styled.CustomModal>
    );
  }

  return render();
}

export default ChangePasswordModal;

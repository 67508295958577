import React, {useState} from 'react';
import {Row, Col} from 'components/bootstrap';
import {usePaymentInputs} from 'react-payment-inputs';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {get, isEmpty} from 'lodash';

import {AppState} from 'reducers';
import paymentActions from 'actions/paymentActions';

import FormLabel from 'components/common/FormLabel';
import Flex from 'components/common/Flex';
import InputError from 'components/common/InputComponents/InputError';
import TextInput from 'components/common/TextInput';

import {colors} from 'styles/shared';
import inputStyles from 'styles/customization/input';

const StyledContainer = styled.div`
  ${inputStyles.common.inputContainer};
`;

const StyledInput = styled.input`
  ${inputStyles.common.inputField};
  border: 0.1rem solid ${props => (props.error ? colors.red_main : colors.darkBlue_light)};
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 0;
`;

function CreditCardInfo() {
  const dispatch = useDispatch();

  const {meta, getCardNumberProps, getExpiryDateProps, getCVCProps} = usePaymentInputs();

  const creditCard = useSelector((state: AppState) => state.payment.creditCard);
  const paymentErrors = useSelector((state: AppState) => state.payment.errors);

  const [cardholder, setCardholder] = useState('');

  function onChange(field: string, value: string) {
    dispatch(paymentActions.modifyCreditCard(field, value));
  }

  function handleChangeCardNumber(e) {
    if (e) e.preventDefault();
    onChange('cardNumber', e.target.value);
  }

  function handleChangeExpiryDate(e) {
    if (e) e.preventDefault();
    onChange('expirationDate', e.target.value);
  }

  function handleChangeCVC(e) {
    if (e) e.preventDefault();
    onChange('cardCode', e.target.value);
  }

  function handleCardHolderName() {
    const [firstName, lastName] = cardholder.split(' ');
    onChange('cardholderFirstName', firstName);
    if (lastName !== undefined) onChange('cardholderLastName', lastName);
  }

  function render() {
    const errors = meta?.erroredInputs;
    const touchedInputs = meta?.touchedInputs;
    const anyPaymentErrors = !isEmpty(paymentErrors);

    const cardError = errors?.cardNumber;
    const cardErrorVisible = cardError && (anyPaymentErrors || touchedInputs?.cardNumber);

    const cvcError = errors?.cvc;
    const cvcErrorVisible = cvcError && (anyPaymentErrors || touchedInputs?.cvc);

    const expiryDateError = errors?.expiryDate;
    const expiryDateErrorVisible = expiryDateError && (anyPaymentErrors || touchedInputs?.expiryDate);

    return (
      <>
        <Row>
          <Col>
            <StyledContainer>
              <Flex justify="space-between" align="baseline">
                <FormLabel required>Card Number</FormLabel>

                <div>
                  <img src="./images/credit_cards/master-card.png" alt="Master Card" />
                  <img src="./images/credit_cards/pay-pal.png" alt="PayPal" />
                  <img src="./images/credit_cards/visa.png" alt="Visa" />
                  <img src="./images/credit_cards/jcb.png" alt="JCB" />
                  <img src="./images/credit_cards/american-express.png" alt="American Express" />
                  <img src="./images/credit_cards/discover.png" alt="Discover" />
                  <img src="./images/credit_cards/apple-pay.png" alt="Apple Pay" />
                </div>
              </Flex>
              <StyledInput
                {...getCardNumberProps({onChange: handleChangeCardNumber})}
                value={creditCard?.cardNumber}
                placeholder="0000 0000 0000 0000"
                error={cardErrorVisible && cardError}
              />

              {cardErrorVisible && <InputError>{cardError}</InputError>}
            </StyledContainer>
          </Col>
        </Row>

        <Row>
          <Col>
            <StyledTextInput
              name="cardholder"
              label="Cardholder Name"
              required
              placeholder="John Doe"
              value={cardholder}
              onChange={(field, value) => setCardholder(value)}
              onBlur={handleCardHolderName}
              error={get(paymentErrors, 'creditCard.cardholderFirstName')}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <StyledContainer>
              <FormLabel required>Expiration</FormLabel>
              <StyledInput
                {...getExpiryDateProps({onChange: handleChangeExpiryDate})}
                value={creditCard?.expirationDate}
                error={expiryDateErrorVisible && expiryDateError}
              />
              {expiryDateErrorVisible && <InputError>{expiryDateError}</InputError>}
            </StyledContainer>
          </Col>
          <Col>
            <StyledContainer>
              <FormLabel required>Security Code</FormLabel>
              <StyledInput
                {...getCVCProps({onChange: handleChangeCVC})}
                type="password"
                value={creditCard?.cardCode}
                error={cvcErrorVisible && cvcError}
              />
              {cvcErrorVisible && <InputError>{cvcError}</InputError>}
            </StyledContainer>
          </Col>
        </Row>
      </>
    );
  }

  return render();
}

export default CreditCardInfo;

import PAGE_ID from 'constants/pageIds';
import PATH from 'constants/path';

import NotFountPage from './components/error_handlers/NotFoundPage';
import SurveyForm from './components/shipment_summary/components/survery/SurveyForm';

import SignInPage from './components/auth/sign_in/SignInPage';
import SignUpPage from './components/auth/sign_up/SignUpPage';
import ResetPasswordPage from './components/auth/ResetPasswordPage';
import GuestLandingPage from './components/auth/guest/GuestLandingPage';

import PrepareShipmentPage from './components/prepare_shipment/PrepareShipmentPage';
import ViewHistoryPage from './components/view_history/ViewHistoryPage';
import ShipmentSummaryPage from './components/shipment_summary/ShipmentSummaryPage';
import OrdersPage from './components/orders/OrdersPage';
import UserSettingsPage from './components/settings/UserSettingsPage';
import UsersListPage from './components/admin_csr/UsersListPage';
import EditDraftPage from './components/draft/EditDraftPage';
import SchedulePickupPage from './components/schedule_pickup/SchedulePickupPage';
import GetQuotePage from './components/get_quote/GetQuotePage';

//dev pages
import UISpeckPage from './components/UISpecPage';
import DevPage from './components/DevPage';

export const routes: Route[] = [
  {
    path: PATH.HOME,
    exact: true,
    component: ViewHistoryPage,
    pageProps: {
      pageId: PAGE_ID.VIEW_HISTORY_ID,
      title: 'View History'
    }
  },
  {
    path: PATH.VIEW_HISTORY,
    exact: true,
    component: ViewHistoryPage,
    pageProps: {
      pageId: PAGE_ID.VIEW_HISTORY_ID,
      title: 'View History',
      whiteBackgroundMobile: true
    }
  },
  {
    path: PATH.PREPARE_SHIPMENT,
    component: PrepareShipmentPage,
    pageProps: {
      pageId: PAGE_ID.PREPARE_SHIPMENT_ID,
      title: 'Prepare a Shipment',
      leftPanel: true
    }
  },
  {
    path: '/draft/:id',
    component: EditDraftPage,
    pageProps: {
      pageId: PAGE_ID.DRAFT_ID,
      title: 'Edit Draft',
      leftPanel: true
    }
  },
  {
    path: '/shipment-summary/:id',
    exact: true,
    component: ShipmentSummaryPage,
    pageProps: {
      pageId: 'shipment-summary',
      title: 'Shipment Summary',
      whiteBackground: true
    }
  },
  {
    path: PATH.ORDERS,
    component: OrdersPage,
    pageProps: {
      pageId: PAGE_ID.ORDERS_ID,
      title: 'Orders',
      leftPanel: true
    }
  },
  {
    path: PATH.SCHEDULE_PICKUP,
    component: SchedulePickupPage,
    pageProps: {
      pageId: 'schedule-pickup',
      title: 'Schedule a Pickup'
    }
  },
  {
    path: PATH.GET_QUOTE,
    component: GetQuotePage,
    pageProps: {
      pageId: 'get-quote',
      title: 'Get a Quote'
    }
  },
  {
    path: '/survey-form',
    component: SurveyForm
  },
  {
    path: PATH.USER_SETTINGS,
    component: UserSettingsPage,
    pageProps: {
      pageId: 'user-settings',
      title: 'User Settings',
      hideNavLinks: true,
      whiteBackground: true
    }
  },
  {
    path: PATH.USERS,
    component: UsersListPage,
    pageProps: {
      pageId: PAGE_ID.USERS_ID,
      title: 'Users',
      whiteBackground: true
    }
  },
  {
    path: '/ui-spec',
    component: UISpeckPage,
    pageProps: {
      pageId: 'ui-spec',
      title: 'UI Spec',
      public: true,
      showLogo: true
    }
  },
  {
    path: '/dev',
    component: DevPage,
    pageProps: {
      pageId: 'dev',
      title: 'Dev'
    }
  },
  {
    path: PATH.SIGN_IN,
    component: SignInPage,
    pageProps: {
      pageId: PAGE_ID.SIGN_IN_ID,
      title: 'Sign In',
      public: true
    }
  },
  {
    path: PATH.SIGN_UP,
    component: SignUpPage,
    pageProps: {
      pageId: 'sign-up',
      title: 'Sign Up',
      public: true
    }
  },
  {
    path: PATH.RESET_PASSWORD,
    component: ResetPasswordPage,
    pageProps: {
      pageId: 'reset-password',
      title: 'Reset Password',
      public: true,
      showLogo: true
    }
  },
  {
    path: PATH.GUEST_LANDING,
    component: GuestLandingPage,
    pageProps: {
      pageId: 'guest-landing',
      title: 'Landing',
      whiteBackground: true,
      public: true
    }
  },
  {
    path: '/*',
    component: NotFountPage,
    pageProps: {
      pageId: 'not_found',
      title: 'Page not found',
      public: true,
      showLogo: true
    }
  }
];

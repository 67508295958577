import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import {Range} from 'rc-slider';
import {isEmpty} from 'lodash';

import SLIDER from 'constants/slider';
import WARNING from 'constants/literals/warnings';
import CARRIER from 'constants/carriers';

import formatHelper from 'helpers/formatHelper';
import tooltipHelper from 'helpers/tooltipHelper';

import QuestionMark from 'components/common/Marks/QuestionMark';
import Flex from 'components/common/Flex';

import {colors, mainFont, secondaryFont, opacity} from 'styles/shared';

const SLIDER_HEIGHT = '0.6rem';
const HANDLE_SIDE = '4.8rem';

const StyledSliderContainer = styled.div`
  margin-bottom: ${props => (props.isLittleMarging ? '3rem' : '7rem')};
`;

const StyledLabel = styled.div`
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: 0.03rem;
  color: ${colors.darkBlue_main};
  margin-bottom: 1rem;
`;

const StyledTimeLabel = styled.span`
  font-weight: bold;
  color: ${colors.orange_main};
  ${mainFont}
`;

const StyledRangeContainer = styled.div`
  padding: 0 2rem;
  margin: 4rem 0 2rem;
`;

const StyledRange = styled(Range)`
  .rc-slider-rail {
    height: ${SLIDER_HEIGHT};
    background: ${colors.darkBlue_lighter};
    border-radius: 2rem;
  }
  .rc-slider-track {
    height: ${SLIDER_HEIGHT};
    background: ${colors.orange_main};
  }
  .rc-slider-dot {
    width: 0.1rem;
    height: 1rem;
    background: ${colors.darkBlue_light};
    border-radius: 0.4rem;
    bottom: -0.6rem;
    margin-left: 0;
    border: none;
  }
  .rc-slider-dot-active {
    height: 0.4rem;
  }
  .rc-slider-mark-text {
    color: ${colors.darkBlue_light};
    margin-top: 2.3rem;
    ${secondaryFont};
  }
  .rc-slider-mark-text:first-child {
    left: 1.5rem !important;
  }
  .rc-slider-mark-text:nth-child(2) {
    width: max-content;
    left: 99% !important;
  }
  .rc-slider-handle {
    width: ${HANDLE_SIDE};
    height: ${HANDLE_SIDE};
    background: ${colors.orange_main};
    box-shadow: 0 0.1rem 0.4rem ${opacity(0.25)};
    border-radius: 0.6rem;
    border: none;
    outline: none;
    margin-top: -2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      font-weight: bold;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.03rem;
      color: ${colors.white};
    }
  }
  .rc-slider-handle-1 {
    &::after {
      content: '${props => props.firstHandleValue}';
    }
  }
  .rc-slider-handle-2 {
    &::after {
      content: '${props => props.secondHandleValue}';
    }
  }
`;

const StyledWarning = styled.div`
  ${mainFont};
`;

interface Props {
  name: string;
  label?: string;
  value: any[];
  onChange: OnChangeHandler;
  readOnlyMode?: boolean;
  carrier: string;
  pickupCapability?: PickupCapability | undefined;
}

Slider.defaultProps = {
  readOnlyMode: false
};

function Slider({name, label, value, onChange, readOnlyMode, carrier, pickupCapability}: Props) {
  const [defaultMinTime, setDefaultMinTime] = useState(formatHelper.getDefaultMinSliderTime(carrier));
  const [minimumInterval, setMinimumInterval] = useState(formatHelper.getMinimumInterval(carrier, pickupCapability?.minimumPickupWindow));
  const [defaultValue, setDefaultValue] = useState(getDefaultValue());

  useEffect(() => {
    setDefaultMinTime(formatHelper.getDefaultMinSliderTime(carrier));
    setMinimumInterval(formatHelper.getMinimumInterval(carrier, pickupCapability?.minimumPickupWindow));
  }, [carrier, pickupCapability]);

  // For handling changes between default ranges of DHL and Domestic: resets minimum start of range
  // For handling changes between default ranges of UPS and DHL: resets actual values if they fall outside range
  useEffect(() => {
    const min = value[0];
    const max = value[1];

    const capabilitiesMin = formatHelper.convertTimeStringIntoNumber(pickupCapability?.pickupWindowEarliestTime, defaultMinTime);
    const capabilitiesMax = formatHelper.convertTimeStringIntoNumber(pickupCapability?.pickupWindowLatestTime ? pickupCapability.pickupWindowLatestTime : pickupCapability?.cutOffTime, SLIDER.MAX_TIME);

    if (min < capabilitiesMin
      || min > (capabilitiesMax - minimumInterval)
      || max < (capabilitiesMin + minimumInterval)
      || max > capabilitiesMax
      || (max - min) < minimumInterval
      || min + minimumInterval < SLIDER.INITIAL_END_TIME) {
        const d = getDefaultValue();

        setDefaultValue(d);
        onSliderChange(d);
      }
  }, [pickupCapability, carrier]);

  function getDefaultValue() {
    const min = formatHelper.convertTimeStringIntoNumber(pickupCapability?.pickupWindowEarliestTime, defaultMinTime);

    if (min + minimumInterval <= SLIDER.INITIAL_END_TIME && (!carrier || carrier === CARRIER.DHL)) {
      return [min, SLIDER.INITIAL_END_TIME];
    }
    const carrierMinInterval = carrier === CARRIER.UPS ? SLIDER.UPS_MIN_INTERVAL
      : CARRIER.FED_EX ? SLIDER.FED_EX_MIN_INTERVAL : SLIDER.DHL_MIN_INTERVAL;

    const defaultMinInterval = minimumInterval > carrierMinInterval ? minimumInterval : carrierMinInterval;

    return [min, min + defaultMinInterval];
  }

  function onSliderChange(val) {
    let startPickupTime = val[0];
    let endPickupTime = val[1];

    if (endPickupTime < SLIDER.INITIAL_END_TIME && (!carrier || carrier === CARRIER.DHL)) return;

    if (carrier === CARRIER.FED_EX && pickupCapability?.cutOffTime && startPickupTime < pickupCapability?.cutOffTime) {
      return;
    }

    onChange(name, val);
  }

  function setMarks(min, max) {
    let initialMarks = {
      [min]: formatHelper.displayTimeInAmPmFormat(min, true),
      [max]: formatHelper.displayTimeInAmPmFormat(max, true)
    };

    let tickInterval = SLIDER.TICK_INTERVAL;
    const diffBtwnMinAndStartOfHour = min % tickInterval;
    let tickTime = min + tickInterval - diffBtwnMinAndStartOfHour;

    while (tickTime < max) {
      initialMarks[tickTime] = '';
      tickTime += tickInterval;
    }

   return initialMarks;
  }

  function render() {
    const min = formatHelper.convertTimeStringIntoNumber(pickupCapability?.pickupWindowEarliestTime, defaultMinTime);
    const max = formatHelper.convertTimeStringIntoNumber(pickupCapability?.pickupWindowLatestTime, SLIDER.MAX_TIME);

    value = isEmpty(value) ? defaultValue : value;

    const marks = setMarks(min, max);

    const displayTime = [formatHelper.displayTimeInAmPmFormat(value[0], true), formatHelper.displayTimeInAmPmFormat(value[1], true)];
    const timeLabel = `${displayTime[0]} - ${displayTime[1]}`;

    const firstHandleValue = displayTime[0].split(' ')[0];
    const secondHandleValue = displayTime[1].split(' ')[0];

    const pickupTimeTooltip = carrier ? tooltipHelper.getPickupTimeTooltip(carrier) : '';

    return (
      <StyledSliderContainer isLittleMarging={readOnlyMode}>
        {label && (
          <Flex justify="space-between">
            <StyledLabel>
              {label} <StyledTimeLabel> {timeLabel}</StyledTimeLabel>
            </StyledLabel>

            {pickupTimeTooltip && (
              <QuestionMark id={`${name}-tooltip`} tooltip={pickupTimeTooltip} />
            )}
          </Flex>
        )}

        {readOnlyMode ? (
          <StyledWarning>{WARNING.SCHEDULED_PICKUP_TIME(carrier)}</StyledWarning>
        ) : (
          <StyledRangeContainer>
            <StyledRange
              min={min}
              max={max}
              step={SLIDER.STEP}
              defaultValue={defaultValue}
              value={value}
              marks={marks}
              allowCross={false}
              pushable={minimumInterval}
              displayTime={displayTime}
              firstHandleValue={firstHandleValue}
              secondHandleValue={secondHandleValue}
              onChange={onSliderChange}
            />
          </StyledRangeContainer>
        )}
      </StyledSliderContainer>
    );
  }

  return render();
}

export default Slider;

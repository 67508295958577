import React, {useState} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'components/bootstrap';
import {get} from 'lodash';
import {useSelector, useDispatch} from 'react-redux';

import {AppState} from 'reducers';
import paymentActions from 'actions/paymentActions';
import userActions from 'actions/userActions';

import COUNTRY_CODE from 'constants/countryCodes/countryCodes';

import validationHelper from 'helpers/validationHelper';
import uiHelper from 'helpers/uiHelper';
import formatHelper from 'helpers/formatHelper';

import Container from 'components/common/Container';
import Flex from 'components/common/Flex';
import QuestionMark from 'components/common/Marks/QuestionMark';
import FormHeader from 'components/common/FormHeader';
import BillingContactInformationTooltip from 'components/tooltips/BillingContactInformationTooltip';
import Button from 'components/common/Button';
import TextInput from 'components/common/TextInput';
import PhoneNumberInput from 'components/common/PhoneNumberInput';

const StyledContainer = styled(Container)`
  position: relative;
`;

const StyledHeader = styled(Flex)`
  margin-bottom: 2.2rem;
`;

const StyledPhoneNumberInput = styled(PhoneNumberInput)`
  margin: 4rem 0 0;
`;

const StyledPhoneExtension = styled(TextInput)`
  margin: 4rem 0 0;
`;

const StyledButtonContainer = styled(Flex)`
  margin-bottom: 1.4rem;
`;

interface Props {
  iri: string;
}

function BillingContactInformationSection({iri}: Props) {
  const dispatch = useDispatch();

  const billingContactInformation = useSelector((state: AppState) => state.payment.billingContactInformation);

  const [errors, setErrors] = useState({});

  function onChange(field, value) {
    dispatch(paymentActions.modifyBillingContactInformation(field, value));
  }

  async function saveBillingContactInformation(e) {
    if (e) e.preventDefault();

    const validationResult: ValidationResult = validationHelper.validateBillingContactInformation(
      billingContactInformation
    );

    if (!validationResult.valid) {
      setErrors(validationResult.errors);
      return;
    }

    const billingId = formatHelper.getIdFromIri(iri);

    const response: any = await dispatch(
      userActions.updateBillingContactInformation(billingContactInformation, billingId)
    );
    const isSuccess = response as boolean;

    if (isSuccess) {
      await dispatch(userActions.loadBillingInformation());
      uiHelper.showNotice('You have successfully updated your billing contact information!');
    }
  }

  function isActiveButton() {
    return billingContactInformation.contactName &&
      billingContactInformation.phoneNumber &&
      billingContactInformation.email
      ? true
      : false;
  }

  function render() {
    const isActive = isActiveButton();

    return (
      <StyledContainer>
        <StyledHeader align="flex-end">
          <FormHeader>Billing Contact Information</FormHeader>

          <QuestionMark id="billing-contact-information" tooltip={BillingContactInformationTooltip()} />
        </StyledHeader>

        <Row>
          <Col lg={7}>
            <StyledPhoneNumberInput
              name="phoneNumber"
              label="Telephone Number"
              required={true}
              countryCode={COUNTRY_CODE.USA}
              value={billingContactInformation.phoneNumber}
              onChange={onChange}
              error={get(errors, 'phoneNumber')}
            />
          </Col>
          <Col lg={5}>
            <StyledPhoneExtension
              name="phoneNumberExtension"
              label="Extension"
              placeholder="000"
              value={billingContactInformation.phoneNumberExtension}
              onChange={onChange}
              error={get(errors, 'phoneNumberExtension')}
            />
          </Col>
        </Row>

        <TextInput
          name="email"
          type="email"
          label="E-mail"
          required={true}
          placeholder="john.doe@gmail.com"
          value={billingContactInformation.email}
          onChange={onChange}
          error={get(errors, 'email')}
        />

        <TextInput
          name="contactName"
          label="Contact name"
          placeholder="John Doe"
          required={true}
          value={billingContactInformation.contactName}
          onChange={onChange}
          error={get(errors, 'contactName')}
        />

        <StyledButtonContainer justify="center">
          <Button autoWidth type="secondary" disabled={!isActive} onClick={saveBillingContactInformation}>
            Save Mailing Address
          </Button>
        </StyledButtonContainer>
      </StyledContainer>
    );
  }

  return render();
}

export default BillingContactInformationSection;

import React from 'react';

import {colors} from 'styles/shared';

function Icon(props) {
  const color = props.color || colors.darkBlue_light;

  return (
    <svg width="15" height="26" fill="none" viewBox="0 0 15 26" {...props}>
      <path
        fill={color}
        fillRule="evenodd"
        d="M2 25.455l11.314-11.314 1.414-1.414-1.414-1.414L2-.001.586 1.413l11.313 11.314L.586 24.041 2 25.455z"
        clipRule="evenodd"></path>
    </svg>
  );
}

export default Icon;

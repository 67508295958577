import React from 'react';

function OtherRemarksTooltip() {
  return (
    <span>
      This is NOT a required field, and will most commonly <br />
      be left blank. If you have any remarks that would be <br />
      important for customs to have for clearance you can <br />
      enter them here. This would include things like: <br />
      broker information, dangerous goods statements, and <br />
      other miscellaneous statements that can assist <br />
      customs in clearing your shipment.
    </span>
  );
}

export default OtherRemarksTooltip;

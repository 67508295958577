import CARRIER from 'constants/carriers';

import httpHelper from 'helpers/httpHelper';

import stateStorageService from 'services/stateStorageService';

export default {
  getFtrExemptions,
  getCountryOptions,
  getAdditionalServiceOptions,
  getWhoPaysOptions,
  getTermsOfTradeOptions,
  getCurrencyOptions,
  getCarrierServices,
  getItemQuantityOptions,
  getInvoiceTypeOptions,
  getEeiFilingTypeOptions,
  getExportTypeOptions
};

const KEYS = {
  COUNTRIES: 'countries_options',
  FTR_EXEMPTION: 'frt_exemption_options',
  PACKAGING_TYPES: 'packaging_types_options',
  ADDITIONAL_SERVICES: 'additional_services_options',
  WHO_PAYS: 'who_pays_options',
  TERMS_OF_TRADE: 'terms_of_trade_options',
  CURRENCIES: 'currency_options',
  CARRIER_SERVICES: 'carrier_services',
  ITEM_QUANTITY: 'item_quantity_options',
  INVOICE_TYPES: 'invoice_types_options',
  EEI_FILING_TYPES: 'eei_filing_types_options',
  EXPORT_TYPES: 'export_type_options'
};

async function getOptions(key, url, parameters?) {
  let options = stateStorageService.getOptions(key);

  if (!options) {
    const query = {page: 1, ...parameters};

    options = await httpHelper.get(url, query);

    stateStorageService.setOptions(key, options);
  }

  return options;
}

function getFtrExemptions() {
  return getOptions(KEYS.FTR_EXEMPTION, '/ftr_exemption_options');
}

function getCountryOptions() {
  return getOptions(KEYS.COUNTRIES, '/country_options');
}

function getAdditionalServiceOptions() {
  return getOptions(KEYS.ADDITIONAL_SERVICES, '/additional_service_options', {carrier: CARRIER.UPS});
}

function getWhoPaysOptions() {
  return getOptions(KEYS.WHO_PAYS, '/who_pays_options');
}

function getTermsOfTradeOptions() {
  return getOptions(KEYS.TERMS_OF_TRADE, '/terms_of_trade_options');
}

function getCurrencyOptions() {
  return getOptions(KEYS.CURRENCIES, '/currency_options');
}

function getCarrierServices() {
  return getOptions(KEYS.CARRIER_SERVICES, '/carrier_services');
}

function getItemQuantityOptions() {
  return getOptions(KEYS.ITEM_QUANTITY, '/item_quantity_options');
}

function getInvoiceTypeOptions() {
  return getOptions(KEYS.INVOICE_TYPES, '/invoice_type_options');
}

function getEeiFilingTypeOptions() {
  return getOptions(KEYS.EEI_FILING_TYPES, '/eei_filing_type_options');
}

function getExportTypeOptions() {
  return getOptions(KEYS.EXPORT_TYPES, '/export_type_options');
}

import COUNTRY_CODE from "./countryCodes";

// For use when country codes are not the same between OS APIs
// and external packages.

const INCOMPATIBLE_COUNTRY_CODE_MAPPING = {
  [COUNTRY_CODE.BONAIRE]: COUNTRY_CODE.CARIBBEAN_NETHERLANDS,
  [COUNTRY_CODE.CURACAO]: 'CW',
  [COUNTRY_CODE.KOSOVO]: 'XK',
  [COUNTRY_CODE.NEVIS]: COUNTRY_CODE.ST_KITTS,
  [COUNTRY_CODE.SOMALILAND]: COUNTRY_CODE.SOMALIA,
  [COUNTRY_CODE.ST_BARTHELEMY]: 'BL',
  [COUNTRY_CODE.ST_EUSTATIUS]: COUNTRY_CODE.CARIBBEAN_NETHERLANDS,
  [COUNTRY_CODE.ST_MAARTEN]: 'SX'
};

export default INCOMPATIBLE_COUNTRY_CODE_MAPPING;

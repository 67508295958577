import React from 'react';

function EEITooltip() {
  return (
    <span>
      <strong>EEI</strong> is required if the value of export <br /> from United States is{' '}
      <strong>over $2,500 USD</strong>. <br />
      It is also required for all shipments leaving <br /> the US and being shipped to <strong>
        China, Brazil
      </strong>{' '}
      <br />
      and <strong>Venezuela</strong> regardless of value.
    </span>
  );
}

export default EEITooltip;

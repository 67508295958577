import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';
import draftActions from 'actions/draftActions';

import Container from 'components/common/Container';
import SelectInput from 'components/common/SelectInput/SelectInput';
import FormHeader from 'components/common/FormHeader';

const StyledAdditionalHeader = styled(FormHeader)`
  margin-bottom: 3rem;
`;

function DomesticAdditionalServices() {
  const dispatch = useDispatch();

  const draft = useSelector((state: AppState) => state.draft.current.data as Draft);
  const signatureServiceOptions = useSelector((state: AppState) => state.shipmentOptions.signatureServiceOptions);

  function onChange(field, value) {
    dispatch(draftActions.modifyDraft('additionalServices', field, value));
  }

  return (
    <Container>
      <StyledAdditionalHeader>Additional Services</StyledAdditionalHeader>

      <SelectInput
        name="signature"
        label="Select signature"
        value={draft.additionalServices.signature}
        tooltip="Additional Fees may apply"
        options={signatureServiceOptions}
        onChange={onChange}
      />
    </Container>
  );
}

export default DomesticAdditionalServices;

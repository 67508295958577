const TIMER = {
  INFO_POPUP_AUTOCLOSE: 5000,
  VALIDATION_SCHEDULE_DELAY: 1000,
  PRESSED_INTERVAL: 500,
  CALENDAR_TOOLTIP: 8000,
  HELP_TUTORIAL_DELAY: 1500,
  DEBOUNCE_TIME: 500,
  RATE_ACTION_DELAY: 1000,
  CALENDAR_TOOLTIP_DELAY: 300,
  SURCHARGE_DISPLAY_DELAY: 2000
};

export default TIMER;

import MEASUREMENT_SYSTEM from 'constants/measurementSystems';
import UNIT from 'constants/units';

import httpHelper from 'helpers/httpHelper';
import utils from 'helpers/utils';

import mapper from './mapping';

export default {
  getLineItems,
  createLineItem,
  deleteLineItem
};

async function getLineItems(search = ''): Promise<{lineItems: LineItem[]; totalItems: number}> {
  let allLineItems: LineItemResponse[] = [];
  let totalItems = 0;
  let page = 1;

  do {
    const options = {
      nickname: search,
      page
    };

    const result: LineItemsResponse = await httpHelper.get('/saved_line_items', options, {
      acceptableErrorCodes: [404],
      totalItems: true
    });

    allLineItems = allLineItems.concat(result.items);
    totalItems = result.totalItems;
    page++;
  } while (allLineItems.length < totalItems);

  return {
    lineItems: allLineItems.map(item => mapper.lineItem.mapLineItemFromLineItemResponse(item)),
    totalItems
  };
}

async function createLineItem(data: LineItem, currency: string, measurementSystem: string): Promise<LineItem> {
  const body: LineItemDto = {
    id: utils.getRandomUid(),
    nickname: data.name,
    description: data.itemDescription,
    countryOfOrigin: {
      code: data.itemMade
    },
    commodityCode: {
      code: data.commodityCode
    },
    eccn: data.eccnValue,
    value: {
      amount: data.itemValue,
      currency: {
        code: currency
      }
    },
    license: {
      number: data.exportLicenseNumber,
      expiry: data.exportLicenseExpiryDate || new Date(),
      symbol: data.licenseSymbol
    },
    quantity: {
      amount: data.quantity,
      unit: data.units
    },
    weight: {
      value: data.weight ?? 0,
      unit: measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL ? UNIT.MASS_POUND : UNIT.MASS_KG
    }
  };

  return await httpHelper.post('/saved_line_items', body, {});
}

async function deleteLineItem(id: string): Promise<SuccessfullResponse> {
  return await httpHelper.delete(`/saved_line_items/${id}`, {});
}

import React from 'react';

function BillingContactInformationTooltip() {
  return (
    <span>
      If your mailing address is different than <br />
      your billing address, please update it <br />
      here for our records
    </span>
  );
}

export default BillingContactInformationTooltip;

import React from 'react';
import styled from 'styled-components/macro';

import {secondaryFont} from 'styles/shared';

const StyledList = styled.ul`
  padding-inline-start: 3rem;
  ${secondaryFont};

  > li {
    margin-top: 1.7rem;
  }
`;

function InternationalDisclaimer() {
  return (
    <StyledList>
      <li>
        Quotes are based on the weights and dimensions provided by the client. Final shipping charges are based on DHL's
        weight and measurement of packages shipped.
      </li>
      <li>Customs and duties charges are invoiced separately and paid by the receiver, unless otherwise requested.</li>
      <li>
        Optimal Logistics does not charge a pickup fee for DHL. There is also no extra charge for delivery unless a
        delivery is to a residential address ($6.15). Or if a delivery is to an extremely rural location ($0.49 per lb.,
        min. $49.00). This fee may not be reflected in your quote. Please check the DHL site to see if the shipment is
        to a remote area.
      </li>
      <li>
        International Express dimensional weight formula: (Length x Width x Height) / 139.00. The heavier of actual
        weight or dimensional weight is used to calculate shipping charges.
      </li>
      <li>
        If a shipment includes a piece that is 48 inches or more in length (120 CM) or 150 or more LBS (70 KG), an
        excess size-overweight piece charge of $100.00 is applied per excess size-overweight piece. A charge of $310.00
        is applied if a shipment is palletized and non-stackable.
      </li>
    </StyledList>
  );
}

export default InternationalDisclaimer;

import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  const color = colors.darkBlue_main;

  return (
    <svg width="38" height="35" fill="none" viewBox="0 0 38 35">
      <path
        stroke={color}
        strokeLinecap="round"
        d="M22 8.333l-2.6 1.214a1 1 0 01-.807.018l-8.21-3.406a1 1 0 00-.766 0l-8 3.318a1 1 0 00-.617.924v22.102a1 1 0 001.383.923l7.234-3a1 1 0 01.766 0l8.234 3.415a1 1 0 00.766 0l8.234-3.415a1 1 0 01.766 0l7.234 3A1 1 0 0037 32.503V10.37a1 1 0 00-.577-.906L34 8.334"></path>
      <path stroke={color} d="M10 6v24M19 10v24"></path>
      <path stroke={color} strokeLinecap="round" d="M28 28v2"></path>
      <path stroke={color} d="M22.4 16l5.6 3.5 5.6-3.5M28 19.5v6.3"></path>
      <path
        stroke={color}
        d="M27.755 13.21a.5.5 0 01.49 0l2.557 1.437 2.522 1.496a.5.5 0 01.245.424l.035 2.933-.035 2.933a.5.5 0 01-.245.424l-2.522 1.496-2.557 1.436a.5.5 0 01-.49 0l-2.557-1.436-2.522-1.496-.255.43.255-.43a.5.5 0 01-.245-.424l-.035-2.933.035-2.933a.5.5 0 01.245-.424l2.522-1.496 2.557-1.436zM32.5 5c0 1.636-1.054 3.266-2.24 4.533a13.67 13.67 0 01-1.642 1.492 7.315 7.315 0 01-.618.428 7.315 7.315 0 01-.619-.429 13.67 13.67 0 01-1.64-1.49C24.553 8.265 23.5 6.635 23.5 5a4.5 4.5 0 119 0z"></path>
      <circle cx="27.889" cy="5.056" r="2.389" stroke={color}></circle>
    </svg>
  );
}

export default Icon;

import {cloneDeep} from 'lodash';

import INVOICE_TYPE from 'constants/invoiceTypes';
import WHO_PAYS from 'constants/payment/whoPays';
import UNIT from 'constants/units';
import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import COMMON from 'constants/common';
import MEASUREMENT_SYSTEM from 'constants/measurementSystems';
import US_TERRITORIES from 'constants/countryCodes/usTerritories';

import utils from 'helpers/utils';

import extras from './draftExtras';

export default {
  createNew,
  createFromData
};

const ADDRESS = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  postalCode: '',
  division: '',
  suburb: ''
};

export const SENDING_INITIAL = {
  companyName: '',
  contactName: '',
  country: '',
  phoneNumber: '',
  phoneNumberExtension: '',
  email: '',
  address: ADDRESS,
  formattedAddress: '',
  isResidential: false,
  isVATIncluded: false,
  isTaxIdIncluded: false,
  isEORINumberIncluded: false,
  VATNumber: '',
  taxId: '',
  EORINumber: ''
};

export const SHIPMENT_DETAILS_INITIAL = {
  documents: {
    isDocumentWeightAboveHalfPound: undefined,
    documentWeight: 0,
    isItJustDocument: undefined,
    numberOfPackages: 1,
    typeOfPackaging: '',
    declaredShipmentValue: 0,
    generalDescription: '',
    isShipmentContainsDangerousGoods: false
  },
  products: {
    packages: [extras.getDefaultPackage()],
    isAnyBatteries: undefined,
    declaredShipmentValue: 0,
    generalDescription: '',
    isShipmentContainsDangerousGoods: false,
    electronicExportFiling: '',
    foreignTradeRegulations: COMMON.FOREIGN_TRADE_REGULATION_DEFAULT,
    isItnNumberExists: undefined,
    ITNNumber: '',
    EINNumber: '',
    invoiceType: '',
    purposeOfShipment: '',
    exportType: '',
    isShipmentReferenceNumberExists: undefined,
    shipmentReferenceNumber: '',
    isInvoiceNumberExists: undefined,
    invoiceNumber: '',
    isReceiverReferenceExists: undefined,
    receiverReference: '',
    isAnyPackageMarks: undefined,
    packageMarks: '',
    isAnyOtherRemarks: undefined,
    otherRemarks: '',
    thirdPartyPayorInfo: undefined,
    items: [extras.getDefaultInvoiceItem()],
    freightCost: 0,
    insuranceCost: 0,
    miscCost: 0
  }
};

export const ADDITIONAL_SERVICES_INITIAL = {
  doYouNeedInsurance: undefined,
  insuranceValue: 0,
  isNeutralDeliveryServiceRequired: undefined,
  isCertificateOfOriginRequired: undefined,
  signature: '',
  isReturnLabel: undefined
};

export const PAYMENT_INITIAL: Payment = {
  freightPayment: '',
  shipperAccountNumber: '',
  dutiesAndTaxesPayment: '',
  dutyAccountNumber: '',
  customsTermsOfTrade: '',
  billingAccountNumberPostalCode: '',
  termsAndConditionsAgreement: false
};

function createNew() {
  let draftData: ShipmentDataObject = {
    id: utils.getRandomUid(),
    shipmentFrom: {...SENDING_INITIAL},
    shipmentTo: {...SENDING_INITIAL},
    shipmentDetails: cloneDeep(SHIPMENT_DETAILS_INITIAL),
    additionalServices: {...ADDITIONAL_SERVICES_INITIAL},
    payment: {...PAYMENT_INITIAL},
    isDocumentsShipment: false,
    currency: UNIT.CURRENCY_USD,
    measurementSystem: MEASUREMENT_SYSTEM.IMPERIAL
  };

  let draft = createFromData(draftData);

  return draft;
}

function createFromData(data): ShipmentData {
  let instanceObject: ShipmentDataObject = {
    ...data
  };

  instanceObject.shipmentDetails.products.packages = instanceObject.shipmentDetails.products.packages.map(packageItem =>
    extras.createPackage(packageItem)
  );

  instanceObject.shipmentDetails.products.items = instanceObject.shipmentDetails.products.items.map(item =>
    extras.createInvoiceItem(item, instanceObject.shipmentTo.country)
  );

  let instance = addExtraMethods(instanceObject);

  return instance;
}

function addExtraMethods(instanceObject: ShipmentDataObject): ShipmentData {
  let shipmentData = {...instanceObject} as ShipmentData;

  shipmentData = {
    ...shipmentData,
    get isExport() {
      const countryFrom = shipmentData.shipmentFrom.country;
      const countryTo = shipmentData.shipmentTo.country;

      if (!countryFrom || !countryTo) return false;

      let exportFromUSA = countryFrom === COUNTRY_CODE.USA && countryTo !== COUNTRY_CODE.USA;
      return exportFromUSA;
    },
    get isImport() {
      const countryFrom = shipmentData.shipmentFrom.country;
      const countryTo = shipmentData.shipmentTo.country;

      if (!countryFrom || !countryTo) return false;

      return countryFrom !== COUNTRY_CODE.USA;
    },
    get isDomestic() {
      const countryFrom = shipmentData.shipmentFrom.country;
      const countryTo = shipmentData.shipmentTo.country;

      return countryFrom === COUNTRY_CODE.USA && countryTo === COUNTRY_CODE.USA;
    },
    get isUSTerritories() {
      const countryFrom = shipmentData.shipmentFrom.country;
      const countryTo = shipmentData.shipmentTo.country;

      return US_TERRITORIES.includes(countryFrom) || US_TERRITORIES.includes(countryTo);
    },
    get isShipperAccountNumberRequired() {
      if (shipmentData.payment.freightPayment === WHO_PAYS.THIRD_PARTY) return true;

      if (shipmentData.payment.freightPayment === WHO_PAYS.RECEIVER) return true;

      return false;
    },
    get isAssociatedZipCodeRequired() {
      if (!shipmentData.isDomestic) return false;

      const freightPayment = shipmentData.payment.freightPayment;

      if (freightPayment === WHO_PAYS.RECEIVER || freightPayment === WHO_PAYS.THIRD_PARTY) return true;

      return false;
    },
    get isDutyAccountNumberRequired() {
      if (shipmentData.payment.dutiesAndTaxesPayment === WHO_PAYS.THIRD_PARTY) return true;
      return false;
    },
    get isInvoiceItemsRequired() {
      return this.isDutiable;
    },
    get isLicenseRequired() {
      return shipmentData.shipmentDetails.products.isItnNumberExists === false;
    },
    get isProformaInvoiceType() {
      return shipmentData.shipmentDetails.products.invoiceType === INVOICE_TYPE.PROFORMA;
    },
    get invoiceTotalSum() {
      let totalSum: number = 0;

      shipmentData.shipmentDetails.products.items.forEach(item => {
        totalSum += item.quantity * Number(item.itemValue);
      });

      return Math.round(totalSum * 100) / 100;
    },
    get declaredTotalValue() {
      if (!shipmentData.isDomestic) return 0;

      const result = shipmentData.shipmentDetails.products.packages.reduce(
        (sum, {declaredValue}: {declaredValue: number}, index, array) => Number(sum) + ((Number(declaredValue)) * Number(array[index].numberOfPackages)),
        0
      );

      return result;
    },
    get isDutiable() {
      return !shipmentData.isDomestic && !shipmentData.isDocumentsShipment;
    },
    get description() {
      return shipmentData.isDocumentsShipment
        ? shipmentData.shipmentDetails.documents.generalDescription
        : shipmentData.shipmentDetails.products.generalDescription;
    },
    get hasDangerousGoods() {
      return shipmentData.isDocumentsShipment
        ? false
        : shipmentData?.shipmentDetails?.products?.isShipmentContainsDangerousGoods;
    },
    get hasBatteries() {
      return !shipmentData.isDocumentsShipment && shipmentData?.shipmentDetails?.products?.isAnyBatteries
        ? true
        : false;
    },
    get shipmentReferenceNumber() {
      if (shipmentData.isDocumentsShipment) return '';
      return shipmentData?.shipmentDetails?.products?.shipmentReferenceNumber;
    }
  };

  return shipmentData;
}

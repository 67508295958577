import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" fill="none" viewBox="0 0 18 14">
      <path
        stroke={colors.orange_main}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 8.059L5.667 13 17 1"></path>
    </svg>
  );
}

export default Icon;

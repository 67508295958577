import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';

import navigationHelper from 'helpers/navigationHelper';

import BackButton from './components/BackButton';
import SignUpForm from 'components/auth/components/sign_up_form/SignUpForm';

import * as styled from './SignUpPage.styled';

function SignUpPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: AppState) => state.user.current);

  useEffect(() => {
    if (!user) return;
    redirectOnSuccess();
  }, [user]);

  // for instant sign-up only
  function redirectOnSuccess() {
    if (!user) return;

    const landingPath = navigationHelper.getLandingUrl(user.isCSR);

    history.push(landingPath);

    dispatch(
      commonActions.infoAction({
        text: (
          <div>
            Welcome to OptimalShip, {user.name}!
            <br />
            You now have access to the very best international & domestic shipping rates as well as a top notch Customer
            Service team who is here to assist you with your every shipping need.
          </div>
        ),
        type: 'warning',
        closeTitle: 'Great!',
        close: () => {}
      })
    );
  }

  function navigateToPreviousPage() {
    history.go(-2);
  }

  return (
    <styled.container>
      <BackButton onClick={navigateToPreviousPage} />

      <SignUpForm isGuest={user?.isGuest ? true : false} />
    </styled.container>
  );
}

export default SignUpPage;

import React from 'react';

function PackageMarksTooltip() {
  return (
    <span>
      This is NOT a required field and will most commonly <br />
      be left blank. Short descriptors can be listed here <br />
      of no more than <strong>35 characters</strong>. This would include <br />
      descriptors like:{' '}
      <strong>
        Sample, Not For Resale, <br />
        Warranty Return
      </strong>
      , etc.
    </span>
  );
}

export default PackageMarksTooltip;

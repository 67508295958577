import React from 'react';
import styled from 'styled-components/macro';

import AppIcon from 'components/common/AppIcon';

const StyledBackButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 2.4rem;
  left: 1rem;
`;

interface Props extends BaseProps {
  onClick: () => void;
}

function BackButton({onClick, className}: Props) {
  return (
    <StyledBackButton className={className} onClick={onClick}>
      <AppIcon name="back" />
    </StyledBackButton>
  );
}

export default BackButton;

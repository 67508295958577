import libphonenumber from 'google-libphonenumber';

import validationHelper from './validationHelper';

export default {
  getValidNumberWithDialCode,
  getDialCodeFromCountryCode,
  getCountryCodeFromValidNumber
}

function getValidNumberWithDialCode(phoneNumber: string, countryCode: string, dialCode?: string): string | undefined {
  if (dialCode) {
    phoneNumber = `+${dialCode}${phoneNumber}`;
  }

  if (validationHelper.isValidPhoneNumber(phoneNumber, countryCode)) {
    const phoneInstance = getPhoneInstance(getPhoneUtil(), phoneNumber, countryCode);

    dialCode = phoneInstance.getCountryCode();

    return `+${dialCode}${phoneInstance.getNationalNumber()}`;
  }
}

function getCountryCodeFromValidNumber(phoneNumber: string): string | undefined {
  if (validationHelper.isValidPhoneNumber(phoneNumber, '')) {
    return getPhoneUtil().getRegionCodeForNumber(getPhoneInstance(getPhoneUtil(), phoneNumber, ''));
  }
}

function getDialCodeFromCountryCode(countryCode: string): string {
  return getPhoneUtil().getCountryCodeForRegion(countryCode);
}

function getPhoneUtil() {
  return libphonenumber.PhoneNumberUtil.getInstance();
}

function getPhoneInstance(phoneUtil, phoneNumber: string, countryCode: string) {
  return phoneUtil.parse(phoneNumber, countryCode);
}

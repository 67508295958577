import COMMON from './common';

const SHIPMENT_FORMAT = {
  [COMMON.INTERNAIONAL]: {
    COMPANY_NAME: {
      MAX_LENGTH: 60
    },
    CONTACT_NAME: {
      MAX_LENGTH: 35
    },
    ADDRESS_LINE: {
      MAX_LENGTH: 45
    }
  },
  [COMMON.DOMESTIC]: {
    COMPANY_NAME: {
      MAX_LENGTH: 27
    },
    CONTACT_NAME: {
      MAX_LENGTH: 22
    },
    ADDRESS_LINE: {
      MAX_LENGTH: 35,
      TOTAL_MAX_LENGTH: 73
    }
  }
};

export default SHIPMENT_FORMAT;

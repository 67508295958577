import React from 'react';

function JustDocumentTooltip() {
  return (
    <span>
      If you are shipping more <br /> than just documents, <br />
      you will need to ship it <br /> as a <strong>product.</strong>
    </span>
  );
}

export default JustDocumentTooltip;

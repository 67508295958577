import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components/macro';
import {useSelector} from 'react-redux';
import {isEmpty, lowerCase, startCase} from 'lodash';
import {Row, Col} from 'components/bootstrap';

import {AppState} from 'reducers';

import AppIcon from 'components/common/AppIcon';
import SummaryContainer from './SummaryContainer';

import {colors} from 'styles/shared';

const MEDIUM = 'medium';
const SMALL = 'small';

const StyledContainer = styled(SummaryContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 11.6rem;
  max-width: 25.8rem;
  padding: 2rem 0.8rem;
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
`;

const StyledCountry = styled.div`
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3.3rem;
  letter-spacing: 0.03rem;
  color: ${colors.black};
  text-align: center;

  ${props =>
    props.size === MEDIUM &&
    css`
      font-size: 1.5rem;
      line-height: 2rem;
    `}

  ${props =>
    props.size === SMALL &&
    css`
      font-size: 1.2rem;
      line-height: 1.6rem;
    `}
`;

interface Props {
  countryFrom: string;
  countryTo: string;
}

function CountrySummary({countryFrom, countryTo}: Props) {
  const countryOptions = useSelector((state: AppState) => state.shipmentOptions.countryOptions);

  const [countryLookup, setCountryLookup] = useState({});

  useEffect(() => {
    if (isEmpty(countryOptions)) return;

    let lookupInitial: any = {};

    countryOptions.forEach(country => {
      let name = lowerCase(country.name);
      name = startCase(name);

      lookupInitial[country.code] = name;
    });

    setCountryLookup(lookupInitial);
  }, [countryOptions]);

  function getCountryFontSize(country) {
    const length = country.length;

    if (length < 7) return '';

    if (length < 22) return MEDIUM;

    return SMALL;
  }

  function render() {
    if (isEmpty(countryLookup)) return null;

    let countryFromDisplay = countryLookup[countryFrom];
    let countryToDisplay = countryLookup[countryTo];

    return (
      <StyledContainer>
        <StyledRow>
          <Col xs={5}>
            <StyledCountry size={getCountryFontSize(countryFromDisplay)}>{countryFromDisplay}</StyledCountry>
          </Col>
          <Col xs={2}>
            <AppIcon name="summary-arrow" />
          </Col>
          <Col xs={5}>
            <StyledCountry size={getCountryFontSize(countryToDisplay)}>{countryToDisplay}</StyledCountry>
          </Col>
        </StyledRow>
      </StyledContainer>
    );
  }

  return render();
}

export default CountrySummary;

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';

import authService from 'services/authService';

import SignUpForm from 'components/auth/components/sign_up_form/SignUpForm';

import * as Styled from 'styles/modal';

interface Props {
  visible: boolean;
  close: () => void;
}

function GuestSignUpModal({visible, close}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: AppState) => state.user.current as User);

  useEffect(() => {
    if (user?.isGuest) return;

    redirectOnSuccess();
  }, [user]);

  function redirectOnSuccess() {
    const redirectUrl = authService.getRedirectUrl();

    close();

    if (redirectUrl) history.push(redirectUrl);

    dispatch(
      commonActions.infoAction({
        text: (
          <div>
            Welcome to OptimalShip, {user.name}!
            <br />
            <br />
            Sign up promo message explaining whole OSPT suite and general client benefits!
          </div>
        ),
        type: 'warning',
        closeTitle: 'Great!',
        close: () => {}
      })
    );
  }

  return (
    <Styled.CustomModal show={visible} padding="2.4rem 2.6rem 2rem" onHide={close}>
      <SignUpForm isGuest={user?.isGuest ? true : false} close={close} />
    </Styled.CustomModal>
  );
}

export default GuestSignUpModal;

import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {get} from 'lodash';

import draftActions from 'actions/draftActions';
import {AppState} from 'reducers';

import SECTION from 'constants/sections';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import TextInput from 'components/common/TextInput';
import ReferenceNumberTooltip from 'components/tooltips/ReferenceNumberTooltip';
import Packages from '../packages';
import DangerousGoodsToggler from 'components/common/DangerousGoodsToggler';

function DomesticShipmentDetails() {
  const dispatch = useDispatch();

  const draft = useSelector((state: AppState) => state.draft.current.data as Draft);
  const rate = useSelector((state: AppState) => state.draft.rate.current);
  const errors = useSelector((state: AppState) => state.draft.current.errors);

  function onChange(field, value) {
    dispatch(draftActions.modifyDraft('shipmentDetails', field, value));
  }

  function onProductsChange(field, value) {
    onChange('products', {...draft.shipmentDetails.products, [field]: value});
  }

  function onReferenceNumberChange(field, value) {
    onChange('products', {...draft.shipmentDetails.products, isShipmentReferenceNumberExists: true, [field]: value});
  }

  function render() {
    const products = draft.shipmentDetails.products;

    return (
      <Container id={SECTION.DRAFT.DOMESTIC_DETAILS}>
        <FormHeader>Shipment Details</FormHeader>

        <Packages
          packages={products.packages}
          carrier={rate?.carrier}
          currency={draft.currency}
          isDomestic={draft.isDomestic}
          measurementSystem={draft.measurementSystem}
          errorPath="shipmentDetails.products.packages"
          errors={errors}
          onChange={onProductsChange}
        />

        <TextInput
          name="shipmentReferenceNumber"
          label="Reference Number"
          placeholder="Type in reference number"
          value={products.shipmentReferenceNumber}
          tooltip={ReferenceNumberTooltip()}
          onChange={onReferenceNumberChange}
          error={get(errors, 'shipmentDetails.products.shipmentReferenceNumber', '')}
          isWarning={products.isShipmentReferenceNumberExists && !products.shipmentReferenceNumber}
        />

        <DangerousGoodsToggler
          value={products.isShipmentContainsDangerousGoods}
          errors={errors}
          onChange={onProductsChange}
        />
      </Container>
    );
  }

  return render();
}

export default DomesticShipmentDetails;

const UNIT = {
  CURRENCY_USD: 'USD',
  LENGTH_INCH: 'in',
  MASS_POUND: 'lb',
  LENGTH_CM: 'cm',
  MASS_KG: 'kg',
  INCH_LABEL: 'inch',
  POUND_LABEL: 'lbs',
  KG_LABEL: 'kgs'
};

export default UNIT;

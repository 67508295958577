import React from 'react';
import styled from 'styled-components';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import FormLabel from 'components/common/FormLabel';
import TermsOfTradeSection from './components/TermsOfTradeSection';

import {secondaryFont} from 'styles/shared';

const StyledDutiesAndTaxesContainer = styled.div`
  margin: 2.2rem 0 4rem;
`;

const StyledCustomsTermsOfTradeContainer = styled.div`
  margin-bottom: 3rem;
`;

const StyledText = styled.div`
  ${secondaryFont};
`;

function DutiesAndTaxesPayNow() {
  return (
    <Container>
      <FormHeader>Duties/Taxes Payment</FormHeader>

      <StyledDutiesAndTaxesContainer>
        <FormLabel>Duties and Taxes</FormLabel>
        <StyledText>Paid by Receiver</StyledText>
      </StyledDutiesAndTaxesContainer>

      <StyledCustomsTermsOfTradeContainer>
        <FormLabel>Customs Terms of Trade</FormLabel>
        <StyledText>DAP - Delivered At Place</StyledText>
      </StyledCustomsTermsOfTradeContainer>

      <TermsOfTradeSection />
    </Container>
  );
}

export default DutiesAndTaxesPayNow;

import COUNTRY_CODE from 'constants/countryCodes/countryCodes';

import httpHelper from 'helpers/httpHelper';
import formatHelper from 'helpers/formatHelper';

export default {
  getSavedPaymentMethods,
  savePaymentMethod,
  removePaymentMethod
};

async function getSavedPaymentMethods(): Promise<PaymentMethod[]> {
  const options = {
    page: 1
  };

  const result: PaymentMethod[] = await httpHelper.get('/saved_payment_methods', options);

  return result;
}

async function savePaymentMethod(data: SavePaymentMethodDto): Promise<PaymentMethod> {
  const body: SavePaymentMethodRequest = {
    address: mapAddressToBillingAddress(data.billingAddress),
    card: mapCreditCard(data.creditCard),
    achAccount: data.achAccount
  };

  const result: PaymentMethod = await httpHelper.post('/saved_payment_methods', body);

  return result;
}

async function removePaymentMethod(id: string): Promise<SuccessfullResponse> {
  return await httpHelper.delete(`/saved_payment_methods/${id}`, {});
}

//helper methods
function mapAddressToBillingAddress(address: Address): BillingAddress {
  const result: BillingAddress = {
    address: address.addressLine1,
    city: address.city || '',
    division: address.division || address.suburb,
    postalCode: address.postalCode || '',
    country: COUNTRY_CODE.USA,
    addressLine2: address.addressLine2,
    addressLine3: address.addressLine3
  };

  return result;
}

function mapCreditCard(creditCard: CreditCard | null): CreditCard | null {
  if (!creditCard) return null;

  const result: CreditCard = {
    ...creditCard,
    cardNumber: formatHelper.removeSpacesFromString(creditCard.cardNumber),
    expirationDate: formatHelper.removeSpacesFromString(creditCard.expirationDate)
  };

  return result;
}

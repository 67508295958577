import React from 'react';
import {useDispatch} from 'react-redux';
import styled, {css} from 'styled-components/macro';

import draftActions from 'actions/draftActions';

import DRAFT_STEP from 'constants/draftSteps';

import {colors} from 'styles/shared';

const circleSide = 5;

const StyledStepperContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.blue_main};
`;

const StyledStepCircle = styled.div`
  width: ${circleSide}rem;
  height: ${circleSide}rem;
  border-radius: 50%;
  border: ${props => (props.active || props.completed ? 'none' : `0.2rem solid ${colors.blue_main}`)};
  background: ${props =>
    props.active || props.completed ? (props.active ? colors.orange_main : colors.green_main) : 'none'};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3.3rem;
  letter-spacing: 0.04rem;
  margin-right: 1rem;

  ${props =>
    props.completed &&
    !props.active &&
    css`
      cursor: pointer;
    `}
`;

const StyledStepTitle = styled.div`
  font-size: 1.4rem;
  line-height: 1.9rem;
  letter-spacing: 0.04rem;
`;

const StyledLine = styled.div`
  border-left: 0.2rem dashed ${colors.blue_main};
  height: 5rem;
  margin-left: ${circleSide / 2}rem;
`;

interface Props {
  item: Step;
  currentStepNumber: number;
}

function StepperComponent({item, currentStepNumber}: Props) {
  const dispatch = useDispatch();

  function onChange(stepItem: Step) {
    if (!stepItem.valid) return;

    dispatch(draftActions.goToStep(item.step, item.stepNumber));
  }

  function render() {
    const itemStepNumber = item.stepNumber;

    let lastStep = item.step === DRAFT_STEP.REVIEW_FINISH;

    let isCurrentStep = itemStepNumber === currentStepNumber;

    return (
      <>
        <StyledStepperContainer>
          <StyledStepCircle active={isCurrentStep} completed={item.valid} onClick={() => onChange(item)}>
            {itemStepNumber}
          </StyledStepCircle>

          <StyledStepTitle>{item.title}</StyledStepTitle>
        </StyledStepperContainer>

        {!lastStep && <StyledLine />}
      </>
    );
  }

  return render();
}

export default StepperComponent;

import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';
import userActions from 'actions/userActions';

import MESSAGE from 'constants/literals/messages';

import uiHelper from 'helpers/uiHelper';
import validationHelper from 'helpers/validationHelper';

import FormHeader from 'components/common/FormHeader';
import Flex from 'components/common/Flex';
import Button from 'components/common/Button';
import TextInput from 'components/common/TextInput';
import CheckBoxInput from 'components/common/CheckboxInput';
import Contacts from './Contacts';

import * as Styled from 'styles/modal';
import {colors, buttonLeftRightMargin, mediaQueries, authFont} from 'styles/shared';

const StyledTitle = styled(FormHeader)`
  margin-bottom: 2.4rem;
`;

const StyledText = styled.div`
  ${authFont};
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  color: ${colors.darkBlue_main};

  & div {
    display: inline;
  }
`;

const StyledButtons = styled(Flex)`
  margin-top: 3.6rem;

  > button {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media ${mediaQueries.mobileMax} {
    > button {
      width: auto;
      padding: 0 1.6rem;
    }
  }
`;

const StyledLimitedAccessButton = styled(Button)`
  white-space: nowrap;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 0;
`;

interface Props extends BaseProps {
  visible: boolean;
  close: () => void;
}

function NoPasswordSetModal({visible, close}: Props) {
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [errors, setErrors] = useState({password: '', confirmPassword: ''});

  function formIsValid() {
    let newErrors = {
      password: '',
      confirmPassword: ''
    };

    if (!password) {
      newErrors.password = 'Password field is required.';
    } else if (password.length < 8) {
      newErrors.password = 'This value is too short. It should have 8 characters or more.';
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = 'Confirm password field is required.';
    }

    if (password && confirmPassword && password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match.';
    }

    setErrors(newErrors);

    return validationHelper.isEmptyErrorObject(newErrors);
  }

  async function createNewPassword(e) {
    if (e) e.preventDefault();

    if (!formIsValid()) return;

    const response: any = await dispatch(userActions.changePassword(password));
    const result = response as ResetPasswordResponse;

    if (result?.success) {
      close();
      uiHelper.showNotice(MESSAGE.SET_PASSWORD);
      return;
    }

    dispatch(
      commonActions.infoAction({
        type: 'error',
        text: result?.message || 'Could not reset password. Please try to reset your password again.',
        close: () => close()
      })
    );
  }

  function handleAction() {
    close();
  }

  function render() {
    return (
      <Styled.CustomModal show={visible} backdrop="static" width="48rem" padding="2.6rem 2.4rem 2rem" onHide={close}>
        <StyledTitle>Password not set yet.</StyledTitle>

        <StyledText>
          It seems you never had a password set on your account. Without a password, you can access Quotes and Shipment
          Preparation only! To access Shipment History and other confidential info, please set a new password or contact
          customer support. Safety first!
        </StyledText>

        <StyledTextInput
          name="password"
          label="Password"
          type="password"
          value={password}
          placeholder="********"
          onChange={(field, value) => setPassword(value)}
          error={errors.password}
        />

        <StyledTextInput
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          value={confirmPassword}
          onChange={(field, value) => setConfirmPassword(value)}
          placeholder="********"
          error={errors.confirmPassword}
        />

        <CheckBoxInput
          name="rememberMe"
          label="Remember me"
          value={rememberMe}
          onToggle={(field, value) => setRememberMe(value)}
        />

        <Flex justify="center">
          <Button width={28.8} margin="2.7rem 0 1.1rem" onClick={createNewPassword}>
            Set Password & Continue
          </Button>
        </Flex>

        <Contacts />

        <StyledButtons justify="center">
          <Button autoWidth type="secondary" margin={buttonLeftRightMargin} onClick={close}>
            Cancel
          </Button>

          <StyledLimitedAccessButton autoWidth margin={buttonLeftRightMargin} onClick={handleAction}>
            Continue W/ Limited Access
          </StyledLimitedAccessButton>
        </StyledButtons>
      </Styled.CustomModal>
    );
  }

  return render();
}

export default NoPasswordSetModal;

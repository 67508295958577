import React, {useState, useEffect, useRef} from 'react';

import TIMER from 'constants/timers';

import utils from 'helpers/utils';

import AppIcon, {IconName} from './AppIcon';

interface Props extends BaseProps {
  name: IconName;
  nameHover: IconName;
  namePressed: IconName;
}

function HoverIcon({name, nameHover, namePressed}: Props) {
  const [isHover, setIsHover] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const isPressedTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (isPressedTimer?.current) clearInterval(isPressedTimer.current);
    };
  }, []);

  function onMouseEnter() {
    setIsHover(true);
  }

  function onMouseLeave() {
    setIsHover(false);
  }

  function onClick() {
    setIsPressed(true);

    isPressedTimer.current = utils.setTimeout(() => setIsPressed(false), TIMER.PRESSED_INTERVAL);
  }

  function render() {
    const defaultState = !isHover && !isPressed;
    const hoverState = isHover && !isPressed;

    return (
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
        {defaultState && <AppIcon name={name} />}

        {hoverState && <AppIcon name={nameHover} />}

        {isPressed && <AppIcon name={namePressed} />}
      </div>
    );
  }

  return render();
}

export default HoverIcon;

import React, {useState, useEffect, Fragment} from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {isEmpty} from 'lodash';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';
import draftActions from 'actions/draftActions';

import PAGE_ID from 'constants/pageIds';
import MESSAGE from 'constants/literals/messages';
import FEATURE from 'constants/features';

import dateHelper from 'helpers/dateHelper';
import formatHelper from 'helpers/formatHelper';
import uiHelper from 'helpers/uiHelper';

import entities from 'domain/entities';
import supportService from 'services/supportService';
import userInfoService from 'services/userInfoService';

import Container from 'components/common/Container';
import CheckBoxInput from 'components/common/CheckboxInput';
import Pagination from 'components/common/Pagination/SimplePagination';
import ActionsPopup from 'components/common/ActionsPopup';
import SendDraftsModal from './components/SendDraftsModal';
import EmptyList from '../EmptyList';
import ListHeader from '../ListHeader';

import ListContainerMobile from 'components/view_history/mobile/components/ListContainerMobile';
import DraftsListMobile from 'components/view_history/mobile/components/drafts/DraftsListMobile';
import PaginationMobile from 'components/view_history/mobile/components/PaginationMobile';

import extras from 'components/view_history/extras';

import * as Styled from 'styles/table';
import {colors} from 'styles/shared';

const StyledStatusText = styled.div`
  color: ${colors.orange_main};
`;

interface Props {
  drafts: any[];
  onDraftListChange: () => void;
}

function Drafts({drafts, onDraftListChange}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: AppState) => state.user.current);
  const carrierServices = useSelector((state: AppState) => state.shipmentOptions.carrierServices);
  const pagination = useSelector((state: AppState) => state.draft.pagination);

  const [checkedDrafts, setCheckedDrafts] = useState<string[]>([]);
  const [draftIdSendToSupport, setDraftIdSendToSupport] = useState('');
  const [newlyCreatedDraftId, setNewlyCreatedDraftId] = useState('');

  const actions: DropdownAction[] = [
    {label: 'Edit Draft', icon: 'edit-shipment', action: onEditDraft},
    {label: 'Delete Draft', icon: 'delete-shipment', action: onDraftDelete},
    {label: 'Send Draft to Support', icon: 'duplicate', action: onSendToSupport}
  ];

  //find last updated draft by comparing 'updatedOn' field
  useEffect(() => {
    if (isEmpty(drafts)) return;

    let latestDraft = drafts.reduce((a, b) => {
      return new Date(a.updatedOn) > new Date(b.updatedOn) ? a : b;
    });

    if (latestDraft) setNewlyCreatedDraftId(latestDraft.id);
  }, [drafts]);

  async function onEditDraft(draft) {
    if (user && !user.active) {
      dispatch(commonActions.infoAction(uiHelper.getInactiveUserNotice()));
      return;
    } else if (user && (user.suspended && !user.whitelisted)) {
      dispatch(commonActions.infoAction(uiHelper.getSuspendedUserNotice()));
      return;
    }

    history.push(`/draft/${draft.id}`);
  }

  function onCheckboxClick(checkedId) {
    let index = checkedDrafts.findIndex(id => id === checkedId);

    if (index === -1) {
      setCheckedDrafts(prevArray => [...prevArray, checkedId]);
    } else {
      setCheckedDrafts(checkedDrafts.filter(id => id !== checkedId));
    }
  }

  function closeSendDraftModal() {
    setDraftIdSendToSupport('');
  }

  function onSendToSupport(draft) {
    setDraftIdSendToSupport(draft.id);
  }

  async function sendToSupportAction(message: string) {
    const info = {
      pageId: PAGE_ID.VIEW_HISTORY_ID,
      user,
      userNotes: message
    };

    const data = await supportService.getSupportRequestDto(draftIdSendToSupport, '', '', JSON.stringify(info));

    const isSuccess: any = await dispatch(commonActions.sendToSupport(data, false));

    if (!isSuccess) return;

    closeSendDraftModal();
    uiHelper.showNotice(MESSAGE.SAVE_AND_SEND_DRAFT);
  }

  function onDraftDelete(draft) {
    dispatch(
      commonActions.confirmAction({
        title: 'Delete draft',
        action: async () => {
          const response: any = await dispatch(draftActions.removeDraft(draft.id));
          const isSuccess = response as boolean;

          if (!isSuccess) return;

          onDraftListChange();
        }
      })
    );
  }

  function onPageChange(page) {
    dispatch(draftActions.updateDraftsPageNumber(page));
  }

  function renderDrafts() {
    return (
      <Styled.Table>
        <thead>
          <Styled.TRowHead topBorder>
            {FEATURE.UNUSED_HISTORY_COLUMNS_VISIBLE && <Styled.THeadCell />}
            <Styled.THeadCell>Draft name</Styled.THeadCell>
            <Styled.THeadCell>Orig.</Styled.THeadCell>
            <Styled.THeadCell>Dest.</Styled.THeadCell>
            <Styled.THeadCell>Shipper</Styled.THeadCell>
            <Styled.THeadCell>Receiver</Styled.THeadCell>
            <Styled.THeadCell>Ship date</Styled.THeadCell>
            <Styled.THeadCell>Service</Styled.THeadCell>
            <Styled.THeadCell>Draft ID</Styled.THeadCell>
            <Styled.THeadCell />
          </Styled.TRowHead>
        </thead>

        <tbody>
          {drafts.map(draft => {
            const id = draft.id;

            const draftObject = entities.draft.parse(draft.payload);
            if (!draftObject) return null;

            const shipmentFrom: Sending = draftObject.shipmentFrom;
            const shipmentTo: Sending = draftObject.shipmentTo;
            const pickupDelivery: PickupDelivery = draftObject.pickupDelivery;

            const displayServiceName = extras.displayCarrierServiceName(pickupDelivery.carrierProduct, carrierServices);

            const date = pickupDelivery.shipmentDate;
            let displayDate = dateHelper.displayShortDate(date);

            let isChecked = checkedDrafts.find(i => i === id) ? true : false;

            return (
              <Fragment key={id}>
                <Styled.TRowBody checked={isChecked} newest={newlyCreatedDraftId === id}>
                  {FEATURE.UNUSED_HISTORY_COLUMNS_VISIBLE && (
                    <Styled.TBodyCell>
                      <CheckBoxInput name={id} value={isChecked} onToggle={onCheckboxClick} />
                    </Styled.TBodyCell>
                  )}

                  <Styled.TBodyCell bold maxWidth>
                    {formatHelper.displayValue(draftObject.name)}
                  </Styled.TBodyCell>
                  <Styled.TBodyCell>{formatHelper.displayValue(shipmentFrom.country)}</Styled.TBodyCell>
                  <Styled.TBodyCell>{formatHelper.displayValue(shipmentTo.country)}</Styled.TBodyCell>
                  <Styled.TBodyCell>{formatHelper.displayValue(shipmentFrom.contactName)}</Styled.TBodyCell>
                  <Styled.TBodyCell>{formatHelper.displayValue(shipmentTo.contactName)}</Styled.TBodyCell>
                  <Styled.TBodyCell>{displayDate}</Styled.TBodyCell>
                  <Styled.TBodyCell>{displayServiceName}</Styled.TBodyCell>
                  <Styled.TBodyCell bold>
                    <StyledStatusText>{id}</StyledStatusText>
                  </Styled.TBodyCell>
                  <Styled.TBodyCell actions>
                    <ActionsPopup actions={actions} activeItem={draft} />
                  </Styled.TBodyCell>
                </Styled.TRowBody>
              </Fragment>
            );
          })}
        </tbody>
      </Styled.Table>
    );
  }

  function renderEmptyList() {
    return (
      <EmptyList
        image="no-drafts.png"
        title="Looks like there are no unfinished shipments!"
        subtitle="Any unfinished shipment that was saved as a draft will appear here. Don’t worry, you can always finish it later."
        isIconFirst={false}
      />
    );
  }

  function render() {
    const noDrafts = !drafts || drafts.length === 0;

    const sendDraftsModalVisible = draftIdSendToSupport ? true : false;

    const isMobile = userInfoService.isMobile();

    if (isMobile) {
      return (
        <ListContainerMobile>
          <DraftsListMobile drafts={drafts} actions={actions} carrierServices={carrierServices} />

          {noDrafts && renderEmptyList()}

          <PaginationMobile label="Total Drafts" pagination={pagination} onChange={onPageChange} />

          {sendDraftsModalVisible && (
            <SendDraftsModal
              visible={sendDraftsModalVisible}
              close={closeSendDraftModal}
              onSend={sendToSupportAction}
            />
          )}
        </ListContainerMobile>
      );
    }

    return (
      <Container withoutPadding={true}>
        <Row>
          <Col sm={12}>
            <ListHeader>Drafts</ListHeader>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {renderDrafts()}
            {noDrafts && renderEmptyList()}
          </Col>
        </Row>

        <Pagination label="Total Drafts" pagination={pagination} bordered={noDrafts} onChange={onPageChange} />

        {sendDraftsModalVisible && (
          <SendDraftsModal visible={sendDraftsModalVisible} close={closeSendDraftModal} onSend={sendToSupportAction} />
        )}
      </Container>
    );
  }

  return render();
}

export default Drafts;

import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  const mainColor = colors.darkBlue_main;

  return (
    <svg width="27" height="24" fill="none" viewBox="0 0 27 24">
      <path
        stroke={mainColor}
        d="M0.705 0H12.115V5.205H0.705z"
        transform="matrix(.7051 -.7091 .7051 .7091 11.167 13.195)"></path>
      <path
        stroke={mainColor}
        d="M19.684 4.629l1.883-1.894a1.49 1.49 0 012.116 0L25.237 4.3a1.51 1.51 0 010 2.127L23.354 8.32l-3.67-3.691zM8.207 19.861l2.447-6.15 1.033-1.04 3.67 3.69-1.033 1.04-6.117 2.46zM10.23 14.895l2.917 2.933"></path>
      <path
        stroke={mainColor}
        strokeLinecap="round"
        d="M1 4.042h15.468M1 7.153h12.375M1 10.265h10.312M1 13.376h7.218M1 16.487h5.156M1 19.599h5.156M1 22.709h15.468"></path>
    </svg>
  );
}

export default Icon;

const SEARCH_PARAMETER = {
  LOCAL: {
    NEW_SHIPMENT_OVERVIEW: 'new',
    SECTION: 'section',
    DOMESTIC: 'domestic'
  },
  GLOBAL: {
    HANDOFF: 'handoff',
    LOGIN_AS: 'login-as',
    SWITCH_TO: 'switch-to',
    UTM: 'utm_',
    MODE: 'mode',
    RECOVERY_CODE: 'recoveryCode',
    RESET_PASSWORD_REASON: 'reason'
  },
  VALUE: {
    RESET_PASSWORD: 'reset-password'
  }
};

export default SEARCH_PARAMETER;

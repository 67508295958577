import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AppState} from 'reducers';
import draftActions from 'actions/draftActions';

import SECTION from 'constants/sections';

import Container from 'components/common/Container';
import TabComponent from 'components/common/TabComponent';
import FormHeader from 'components/common/FormHeader';
import ShippingDocumentsTab from './components/ShippingDocumentsTab';
import ShippingProductsTab from './components/shipping_products/ShippingProductsTab';

const SHIPPING_DOCUMENTS_TAB = 'shipping_documents';
const SHIPPING_PRODUCTS_TAB = 'shipping_products';

const tabs: BasicOption[] = [
  {value: SHIPPING_DOCUMENTS_TAB, label: 'I am Shipping Documents'},
  {value: SHIPPING_PRODUCTS_TAB, label: 'I am Shipping Products'}
];

function ShipmentDetails() {
  const dispatch = useDispatch();

  const draft = useSelector((state: AppState) => state.draft.current.data as Draft);

  function onChange(field, value) {
    dispatch(draftActions.modifyDraft('shipmentDetails', field, value));
  }

  function onDocumentsChange(field, value) {
    onChange('documents', {...draft.shipmentDetails.documents, [field]: value});
  }

  function onProductsChange(field, value) {
    onChange('products', {...draft.shipmentDetails.products, [field]: value});
  }

  function onInsuranceChange(field, value) {
    dispatch(draftActions.modifyDraft('additionalServices', field, value));
  }

  function handleTabSelect(tab) {
    dispatch(draftActions.modifyDraft(null, 'isDocumentsShipment', tab === SHIPPING_DOCUMENTS_TAB));
  }

  function render() {
    let tabSelected = draft.isDocumentsShipment ? SHIPPING_DOCUMENTS_TAB : SHIPPING_PRODUCTS_TAB;

    return (
      <Container id={SECTION.DRAFT.DETAILS}>
        <FormHeader>Shipment Details</FormHeader>

        <TabComponent tabs={tabs} tabSelected={tabSelected} onChange={handleTabSelect} />

        {draft.isDocumentsShipment ? (
          <ShippingDocumentsTab
            documents={draft.shipmentDetails.documents}
            currency={draft.currency}
            measurementSystem={draft.measurementSystem}
            onChange={onDocumentsChange}
            onInsuranceChange={onInsuranceChange}
          />
        ) : (
          <ShippingProductsTab draft={draft} onChange={onProductsChange} onInsuranceChange={onInsuranceChange} />
        )}
      </Container>
    );
  }

  return render();
}

export default ShipmentDetails;

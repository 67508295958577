import React from 'react';
import styled from 'styled-components/macro';

import {colors, secondaryFont} from 'styles/shared';

const StyledErrorContainer = styled.div`
  color: ${colors.red_main};
  ${secondaryFont};
`;

function InputError({children}: BaseProps) {
  return <StyledErrorContainer className="validation-error">{children}</StyledErrorContainer>;
}

export default InputError;

import React from 'react';
import styled from 'styled-components';

import {colors, middleFont} from 'styles/shared';

const StyledListHeader = styled.div`
  ${middleFont};
  font-weight: bold;
  color: ${colors.black};
  margin: 2.6rem;
`;

function ListHeader({children, className}: BaseProps) {
  return <StyledListHeader className={className}>{children}</StyledListHeader>;
}

export default ListHeader;

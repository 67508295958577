import React from 'react';
import styled from 'styled-components/macro';

import formatHelper from 'helpers/formatHelper';

import MESSAGE from 'constants/literals/messages';

import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';
import FormHeader from 'components/common/FormHeader';

import * as StyledModal from 'styles/modal';
import {colors, headerFont} from 'styles/shared';

const StyledHeader = styled.div`
  padding: 2.4rem;
`;

const StyledCloseContainer = styled(Flex)`
  margin-bottom: 1.7rem;
`;

const StyledFormHeader = styled(FormHeader)`
  font-weight: normal;
  margin: 0 0 0.8rem 0.8rem;
`;

const StyledBody = styled.div`
  padding: 0 1.6rem 3rem;
`;

const StyledRow = styled(Flex)`
  padding: 0 0.8rem;
  line-height: 3.2rem;
  letter-spacing: 0.03rem;
  background-color: ${props => {
    if (props.isTotalRow) {
      return colors.darkBlue_main;
    } else {
      return props.isHighlighted ? colors.grey_concrete : colors.white;
    }
  }};
  color: ${props => props.isTotalRow ? colors.white : colors.black}
`;

const StyledLabel = styled.div`
  font-weight: bold;
  font-size: 1.6rem;
`;

const StyledValue = styled.div`
  font-size: 1.4rem;
`;

const StyledTotalText = styled.div`
  ${headerFont};
  font-weight: normal;
`;

interface Props {
  visible: boolean;
  rate?: Rate;
  displayShippingCharge: string;
  displaySavings: string;
  displayTotalCharge: string;
  close: () => void;
}

function TotalSumDetailsModal({visible, rate, displayShippingCharge, displaySavings, displayTotalCharge, close}: Props) {
  function renderItem(fee: Fee, index: number) {
    const isHighlighted = index % 2 !== 0;
    const displayValue = formatHelper.displayDollarAmount(fee.amount.amount);

    return (
      <StyledRow key={fee.id} justify="space-between" isHighlighted={isHighlighted}>
        <StyledLabel>{fee.description}</StyledLabel>
        <StyledValue>{displayValue}</StyledValue>
      </StyledRow>
    );
  }

  function renderTotalCharge() {
    return (
      <StyledRow key={'TotalRow'} justify="space-between" isTotalRow={true}>
        <StyledTotalText>{MESSAGE.TOTAL_CHARGE}:</StyledTotalText>
        <StyledTotalText>{displayTotalCharge}</StyledTotalText>
      </StyledRow>
    );
  }

  function render() {
    const fees = rate?.fees || [];

    return (
      <StyledModal.CustomModal show={visible} padding="0" onHide={close}>
        <StyledHeader>
          <StyledCloseContainer justify="flex-end">
            <CloseButton onClick={close} />
          </StyledCloseContainer>

          <Flex>
            <FormHeader>{MESSAGE.SHIPPING_CHARGE}:</FormHeader>
            <StyledFormHeader>{displayShippingCharge}</StyledFormHeader>
          </Flex>

          <Flex>
            <FormHeader>{MESSAGE.SAVINGS}:</FormHeader>
            <StyledFormHeader>{displaySavings}</StyledFormHeader>
          </Flex>
        </StyledHeader>

        <StyledBody>
          {fees.map((fee: Fee, index: number) => {
            return renderItem(fee, index);
          })}
          {renderTotalCharge()}
        </StyledBody>
      </StyledModal.CustomModal>
    );
  }

  return render();
}

export default TotalSumDetailsModal;

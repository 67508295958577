import styled from 'styled-components';

import Container from 'components/common/Container';
import CloseButton from 'components/common/CloseButton';

import {mainFont, mediaQueries} from 'styles/shared';

export const container = styled(Container)`
  position: relative;
  padding: 0 1rem 0 2.6rem;
  display: flex;
  flex-direction: row;

  @media ${mediaQueries.mobileMax} {
    padding: 1.7rem;
    flex-direction: column;
  }
`;

export const closeButton = styled(CloseButton)`
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
`;

export const content = styled.div`
  padding: 1rem 0 2rem;
  max-width: 56.9rem;

  @media ${mediaQueries.mobileMax} {
    display: flex;
    flex-direction: column;
  }
`;

export const description = styled.div`
  margin: 0.8rem 0 2.8rem;
  ${mainFont};
`;

import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  const mainColor = colors.darkBlue_main;

  return (
    <svg width="44" height="45" fill="none" viewBox="0 0 44 45">
      <rect width="24.081" height="22.514" x="10.392" y="12.566" stroke={mainColor} rx="1.5"></rect>
      <rect
        width="0.784"
        height="25.081"
        x="34.973"
        y="15.985"
        fill={mainColor}
        rx="0.392"
        transform="rotate(90 34.973 15.985)"></rect>
      <rect width="1.568" height="4.703" x="16.162" y="9.715" fill={mainColor} rx="0.784"></rect>
      <rect width="1.568" height="4.703" x="27.135" y="9.715" fill={mainColor} rx="0.784"></rect>
      <path
        fill={mainColor}
        d="M16.5 26.715a.5.5 0 101 0h-1zm9.5-5.5a.5.5 0 100-1v1zm-8.5 5.5v-1h-1v1h1zm4.5-5.5h4v-1h-4v1zm-4.5 4.5a4.5 4.5 0 014.5-4.5v-1a5.5 5.5 0 00-5.5 5.5h1zM29.5 23.715a.5.5 0 00-1 0h1zm-9.5 5.5a.5.5 0 000 1v-1zm8.5-5.5v1h1v-1h-1zm-4.5 5.5h-4v1h4v-1zm4.5-4.5a4.5 4.5 0 01-4.5 4.5v1a5.5 5.5 0 005.5-5.5h-1z"></path>
      <path
        stroke={mainColor}
        strokeLinecap="round"
        d="M24.121 18.715l2.122 2.12-2.122 2.122M21.121 31.957L19 29.836l2.121-2.121"></path>
    </svg>
  );
}

export default Icon;

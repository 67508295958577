import React, {useRef, useState} from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';
import {useReactToPrint} from 'react-to-print';

import COMMON from 'constants/common';
import FEATURE from 'constants/features';
import MESSAGE from 'constants/literals/messages';

import uiHelper from 'helpers/uiHelper';
import dateHelper from 'helpers/dateHelper';
import config from 'config';
import formatHelper from 'helpers/formatHelper';

import rateService from 'services/rateService';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import Flex from 'components/common/Flex';
import Button from 'components/common/Button';
import QuestionMark from 'components/common/Marks/QuestionMark';
import PickupTooltip from 'components/tooltips/PickupTooltip';
import ActionsPopup from 'components/common/ActionsPopup';
import Badge from 'components/common/Badge';
import TotalSumDetailsModal from 'components/common/TotalSum/components/TotalSumDetailsModal';

import IncompleteRate from './IncompleteRate';

import {colors, secondaryFont, mediaQueries, mainFont, middleFont} from 'styles/shared';

const StyledContainer = styled(Container)`
  padding: 2rem 2.4rem 2.4rem 1.6rem;

  @media ${mediaQueries.mobileMax} {
    padding: 1.6rem 1.6rem 0;
  }
`;

const StyledTitle = styled(FormHeader)`
  text-transform: capitalize;
`;

const StyledDeliveryDate = styled.div`
  ${mainFont};
  font-weight: bold;

  > span {
    font-weight: normal;

    @media ${mediaQueries.mobileMax} {
      display: block;
    }
  }
`;

const StyledQuote = styled.div`
  background: ${colors.grey_light};
  border-radius: 0.6rem;
  margin-top: 0.8rem;
  padding: 1.9rem 1.6rem 1.8rem;
`;

const StyledFormHeader = styled(FormHeader)`
  font-weight: normal;
`;

const StyledAsterisk = styled.span`
  color: ${colors.orange_main};
`;

const StyledDelimiter = styled.div`
  border: 0.1rem dashed ${colors.darkBlue_light};
  margin: 0.8rem 0;
`;

const StyledRowTitle = styled.div`
  ${middleFont};
`;

const StyledRowValue = styled(StyledRowTitle)`
  font-weight: bold;
`;

const StyledList = styled.ul`
  padding-inline-start: 3rem;
  ${secondaryFont};
  margin-bottom: 1.6rem;

  @media ${mediaQueries.mobileMax} {
    margin-top: 2rem;
    width: 20rem;
  }
`;

const StyledDomesticDescription = styled.div`
  @media ${mediaQueries.mobileMax} {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1.2rem;
  }
`;

const StyledButtonsContainer = styled(Flex)`
  height: 100%;
  padding-top: 1.6rem;

  @media ${mediaQueries.mobileMax} {
    align-items: center;
  }
`;

const StyledSecondaryButtonsContainer = styled(Flex)`
  @media ${mediaQueries.mobileMax} {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;

  @media ${mediaQueries.mobileMax} {
    max-width: 23.5rem;
    margin-bottom: 2rem;
  }
`;

const StyledActionsPopup = styled(ActionsPopup)`
  display: none;

  @media ${mediaQueries.mobileMax} {
    display: initial;

    > div {
      margin: 0;
    }
    > span > span {
      width: 12.7rem;
      left: -9.2rem;
      top: 3.7rem;
    }
  }
`;

interface Props {
  rate: Rate;
  isDomestic: boolean;
  isLowerPrice: boolean;
  isFastestDelivery: boolean;
  continueShipping?: (rate: Rate) => void;
}

function RateItem({rate, isDomestic, isLowerPrice, isFastestDelivery, continueShipping}: Props) {
  const componentRef = useRef<any>();

  const [detailsVisible, setDetailsVisible] = useState(false);

  const isIncomplete = rateService.isIncompleteRate(rate);
  const beforeDiscount: number = isIncomplete ? 0 : rateService.calculateRateBeforeDiscount(rate);
  const savingsAmount = isIncomplete ? 0 : rateService.calculateSavingsAmount(rate, beforeDiscount);
  const beforeDiscountTotal : number = isIncomplete ? 0 : rateService.addFeesToCalculatedRate(rate, beforeDiscount);
  const isBeforeDiscountTotalVisible : boolean = rate.displayRetailRate < beforeDiscountTotal;

  function toggleDetails() {
    setDetailsVisible(!detailsVisible);
  }

  const actions: DropdownAction[] = [
    {
      label: 'Print Quote',
      action: () => handlePrint
    }
  ];
  if (FEATURE.SHARE_QUOTE_VISIBLE) {
    actions.push({
      label: 'Share Quote',
      action: uiHelper.notImplemented
    });
  }
  if (!isIncomplete) {
    actions.push({
      label: 'See Details',
      action: toggleDetails
    });
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  function getPageMargins() {
    return `@page { margin: 30px !important; }`;
  }

  function renderQuoteRow(title, value: string, withDelimiter = true, totalRow = false) {
    return (
      <>
        <Flex justify="space-between">
          {totalRow ? (
            <>
              <StyledFormHeader>{title}</StyledFormHeader>
              <FormHeader>{value}
                <StyledAsterisk>*</StyledAsterisk>
              </FormHeader>
            </>
          ) : (
            <>
              <StyledRowTitle>{title}</StyledRowTitle>
              <StyledRowValue>{value}</StyledRowValue>
            </>
          )}
        </Flex>
        {withDelimiter && <StyledDelimiter />}
      </>
    );
  }

  function renderHeader() {
    const header = isDomestic ? rate?.carrierService?.name : 'DHL Express';

    const dateFormat = isDomestic ? config.format.estimatedDeliveryDateDomestic : config.format.estimatedDeliveryDate;
    const deliveryDate = dateHelper.displayDate(rate.estimatedDelivery, dateFormat);

    return (
      <Flex justify="space-between">
        <div>
          <StyledTitle>{header}&#174;</StyledTitle>
          <StyledDeliveryDate>
            Estimated Delivery Date: <span>{deliveryDate}</span>
          </StyledDeliveryDate>
        </div>

        <StyledActionsPopup actions={actions} activeItem={rate} labelVisible={false} />
      </Flex>
    );
  }

  function renderRateDescriptionList() {
    if (!isDomestic) {
      return (
        <StyledList>
          <li>On Demand CSRs</li>
          <li>Shipment Tracking</li>
          <li>NO Pickup Fees</li>
        </StyledList>
      );
    }

    let tooltipText = '';
    if (isFastestDelivery) tooltipText = 'Fastest Delivery!';
    if (isLowerPrice) tooltipText = 'Lowest Price!';

    return (
      <StyledDomesticDescription>
        <StyledList>
          <li>Discounted Rate</li>
          <li>On Demand Customer Service Representatives</li>
          <li>Shipment Tracking</li>
        </StyledList>

        {tooltipText && <Badge size="large">{tooltipText}</Badge>}
      </StyledDomesticDescription>
    );
  }

  function render() {
    const displayShippingCharge = formatHelper.displayDollarAmount(rate.displayOptimalRate);
    const displayTotalCharge = formatHelper.displayDollarAmount(rate.displayRetailRate);
    const displaySavings = savingsAmount ? `$${savingsAmount.toFixed(2)} | ${rate.savingsPercent}%` : COMMON.EMPTY_RATE;
    const displayBeforeDiscountTotal = formatHelper.displayDollarAmount(beforeDiscountTotal);

    const pickupTitle = (
      <Flex>
        Latest Pickup: <QuestionMark id="pickup-tooltip" tooltip={PickupTooltip()} />
      </Flex>
    );

    return (
      <StyledContainer>
        <Row>
          <Col lg={9} ref={componentRef}>
            <style>{getPageMargins()}</style>

            {renderHeader()}

            <Row>
              <Col lg={8}>
                <StyledQuote>
                  <Flex justify="space-between">
                    <StyledFormHeader>{MESSAGE.SHIPPING_CHARGE}:</StyledFormHeader>
                    <FormHeader>
                      {isIncomplete && <IncompleteRate />}
                      {!isIncomplete &&
                        <>
                          {displayShippingCharge}
                          <StyledAsterisk>*</StyledAsterisk>
                        </>
                      }
                    </FormHeader>
                  </Flex>

                  <StyledDelimiter />

                  {renderQuoteRow(`${MESSAGE.TOTAL_CHARGE}:`, displayTotalCharge, true, true)}
                  {rate.isSavingsVisible && renderQuoteRow(`${MESSAGE.SAVINGS}:`, displaySavings)}
                  {isBeforeDiscountTotalVisible && renderQuoteRow('Total without discount:', displayBeforeDiscountTotal)}
                  {renderQuoteRow(pickupTitle, formatHelper.displayValue(rate.latestPickupTime))}
                  {renderQuoteRow('Schedule by:', formatHelper.displayValue(rate.latestPickupScheduleByTime), false)}
                </StyledQuote>
              </Col>
              <Col lg={4}>{renderRateDescriptionList()}</Col>
            </Row>
          </Col>

          <Col lg={3}>
            <StyledButtonsContainer direction="column" justify="space-between">
              <StyledSecondaryButtonsContainer direction="column">
                <StyledButton type="secondary" autoWidth margin={`0 0 3rem`} onClick={handlePrint}>
                  Print Quote
                </StyledButton>
                {FEATURE.SHARE_QUOTE_VISIBLE && (
                  <StyledButton type="secondary" autoWidth margin={`0 0 3rem`} onClick={uiHelper.notImplemented}>
                    Share Quote
                  </StyledButton>
                )}
                {!isIncomplete && (<StyledButton type="secondary" autoWidth margin={`0 0 3rem`} onClick={toggleDetails}>
                <StyledAsterisk>*</StyledAsterisk>See Details
                </StyledButton>
                )}
              </StyledSecondaryButtonsContainer>

              <StyledButton
                autoWidth
                onClick={() => {
                  if (continueShipping) continueShipping(rate);
                }}>
                Continue to Shipping
              </StyledButton>
            </StyledButtonsContainer>
          </Col>
        </Row>
        {detailsVisible && (
          <TotalSumDetailsModal
            visible={detailsVisible}
            rate={rate}
            displayShippingCharge={displayShippingCharge}
            displaySavings={displaySavings}
            displayTotalCharge={displayTotalCharge}
            close={toggleDetails}
          />
        )}
      </StyledContainer>
    );
  }

  return render();
}

export default RateItem;

import React from 'react';
import styled from 'styled-components/macro';
import {Modal} from 'components/bootstrap';

import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';

import * as Styled from 'styles/modal';
import modalStyles from 'styles/customization/modal';
import {secondaryFont} from 'styles/shared';

const StyledHeader = styled(Flex)`
  margin-bottom: 2rem;
`;

const StyledCloseButton = styled(CloseButton)`
  margin-right: 2rem;
`;

const StyledBody = styled(Modal.Body)`
  ${modalStyles.common.modalBody};
`;

const StyledItem = styled.div`
  margin-bottom: 1.5rem;
  ${secondaryFont};
`;

const StyledTitle = styled.div`
  font-weight: bold;
`;

interface Props {
  visible: boolean;
  termsOfTradeOptions: TermsOfTradeOption[];
  close: () => void;
}

function TermsOfTradeModal({visible, termsOfTradeOptions, close}: Props) {
  return (
    <Styled.CustomModal
      show={visible}
      width="45.2rem"
      height="40rem"
      padding="2.6rem 0.5rem 2.6rem 2.6rem"
      onHide={close}>
      <StyledHeader justify="flex-end">
        <StyledCloseButton onClick={close} />
      </StyledHeader>

      <StyledBody>
        {termsOfTradeOptions.map(item => {
          const code = item.value;

          return (
            <StyledItem key={code}>
              <StyledTitle>{code}</StyledTitle>
              {item.definition}
            </StyledItem>
          );
        })}
      </StyledBody>
    </Styled.CustomModal>
  );
}

export default TermsOfTradeModal;

import React from 'react';
import styled from 'styled-components/macro';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import PATH from 'constants/path';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';

import navigationHelper, {ALLOWED_URLS} from 'helpers/navigationHelper';
import {goToBillPay} from '../Navigation';

import Flex from 'components/common/Flex';
import Button from 'components/common/Button';

import {colors, opacity, boxShadow, secondaryFont} from 'styles/shared';

const ARROW_SIZE = '0.8rem';
const ARROW_BORDER = `0.1rem solid ${opacity(0.08)}`;

const StyledContainer = styled.div`
  width: 36rem;
  background: ${colors.white};
  box-shadow: ${boxShadow};
  border-radius: 0.8rem;
  position: fixed;
  right: 1rem;
  top: 8rem;
  z-index: 1200;
  padding: ${props => (props.singIn ? '2rem 0 1rem' : '3rem')};

  &::before {
    content: '';
    display: block;
    width: ${ARROW_SIZE};
    height: ${ARROW_SIZE};
    position: absolute;
    top: calc(-${ARROW_SIZE} / 2);
    right: 2rem;
    transform: rotate(45deg);
    background: transparent;
    z-index: 1200;
    border-top: ${ARROW_BORDER};
    border-left: ${ARROW_BORDER};
    background-color: ${colors.white};
  }
`;

const StyledLink = styled.div`
  cursor: pointer;
  color: ${colors.black};
  ${secondaryFont};
  text-align: center;
  padding: 2rem 0;
`;

interface Props {
  user: User;
  onToggle: () => void;
}

function MobilePopup({user, onToggle}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const isQuickAccessAuth = useSelector((state: AppState) => state.common.isQuickAccessAuth);

  function openLink(url) {
    if (isQuickAccessAuth && !ALLOWED_URLS.includes(url)) {
      dispatch(commonActions.togglePasswordProtectedModal(true, url));
      onToggle();
      return;
    }

    history.push(url);
    onToggle();
  }

  function handleClick() {
    goToBillPay();
    onToggle();
  }

  function guestSignIn(e) {
    if (e) e.preventDefault();
    dispatch(commonActions.toggleGuestAuthModal(true));
  }

  function guestSignUp(e) {
    if (e) e.preventDefault();
    dispatch(commonActions.toggleGuestSignUpModal(true));
  }

  function renderGuestPopup() {
    return (
      <Flex justify="space-around">
        <Button type="secondary" autoWidth onClick={guestSignIn}>
          Sign In
        </Button>
        <Button type="secondary" autoWidth onClick={guestSignUp}>
          Sign Up
        </Button>
      </Flex>
    );
  }

  function renderSignedInPopup() {
    const viewHistoryLink = navigationHelper.getViewHistoryPageLink();

    return (
      <>
        <StyledLink onClick={() => openLink(viewHistoryLink)}>View History</StyledLink>
        <StyledLink onClick={() => openLink(PATH.GET_QUOTE)}>Get a Quote</StyledLink>
        <StyledLink onClick={handleClick}>Go to Bill Pay</StyledLink>
      </>
    );
  }

  function render() {
    return (
      <StyledContainer signIn={user ? true : false}>
        {user?.isGuest ? renderGuestPopup() : renderSignedInPopup()}
      </StyledContainer>
    );
  }

  return render();
}

export default MobilePopup;

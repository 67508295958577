import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  let mainColor = colors.orange_main;

  return (
    <svg width="44" height="45" fill="none" viewBox="0 0 44 45">
      <rect width="20" height="28" x="11.5" y="8.713" stroke={mainColor} rx="1.5"></rect>
      <path stroke={mainColor} strokeLinecap="round" d="M21.5 14.213v17"></path>
      <path
        stroke={mainColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M28.577 24.278L21.5 31.343l-7.077-7.065"></path>
    </svg>
  );
}

export default Icon;

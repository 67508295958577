import React from 'react';
import styled from 'styled-components/macro';

import uiHelper from 'helpers/uiHelper';

import TextInput from 'components/common/TextInput';
import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import {colors, mainFont, secondaryFont, mainBorder} from 'styles/shared';

const StyledRow = styled.div`
  border-bottom: ${mainBorder};
  margin-bottom: 3rem;
  padding: 2rem 2.6rem 4rem;
  color: ${colors.darkBlue_main};
`;

const StyledLabel = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
  ${mainFont};
`;

const StyledValue = styled.div`
  ${secondaryFont};
`;

const StyledLink = styled.div`
  color: ${colors.orange_main};
  cursor: pointer;
  ${mainFont};
`;

const StyledInput = styled(TextInput)`
  margin: 0;
  min-height: 9rem;
`;

const StyledPasswords = styled(TextInput)`
  margin: 0;
`;

const StyledMessage = styled.div`
  ${secondaryFont};
  text-align: center;
  margin-top: 3rem;
`;

interface Props {
  label: string;
  field: string;
  value: any;
  editField: string;
  onToggleEditMode: (mode?: string) => void;
  onChange: OnChangeHandler;
}

function SettingsRow({label, field, value, editField, onToggleEditMode, onChange}: Props) {
  const READ_ONLY = true;

  let isEditMode = editField === field;

  function onActionClick() {
    let mode = isEditMode ? undefined : field;
    onToggleEditMode(mode);
  }

  function renderEditSection() {
    if (field === 'password') return renderEditPasswordSection();

    let isExport = field === 'exportNumber';

    let type = field === 'email' ? 'email' : 'text';

    return (
      <>
        <StyledInput type={type} name={field} value={value} onChange={onChange} />

        <Flex justify="center">
          <Button autoWidth onClick={uiHelper.notImplemented}>
            Save changes
          </Button>
        </Flex>

        {isExport && (
          <StyledMessage>
            If you do not have Import Number yet please contact our Customer Support to get help!
          </StyledMessage>
        )}
      </>
    );
  }

  function renderEditPasswordSection() {
    return (
      <>
        <StyledInput type="password" name={field} value={value} onChange={onChange} />

        <StyledPasswords type="password" name="newPassword" label="New Password" value="" onChange={onChange} />

        <StyledPasswords type="password" name="confirmPassword" label="Confirm Password" value="" onChange={onChange} />

        <Flex justify="center">
          <Button autoWidth disabled={true} onClick={uiHelper.notImplemented}>
            Save changes
          </Button>
        </Flex>
      </>
    );
  }

  function render() {
    let linkName = isEditMode ? 'Close' : 'Edit';

    return (
      <StyledRow>
        <Flex justify="space-between">
          <StyledLabel>{label}</StyledLabel>

          {!READ_ONLY && <StyledLink onClick={onActionClick}>{linkName}</StyledLink>}
        </Flex>

        {isEditMode ? renderEditSection() : <StyledValue>{value}</StyledValue>}
      </StyledRow>
    );
  }

  return render();
}

export default SettingsRow;

import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';

import {AppState} from 'reducers';
import addressActions from 'actions/addressActions';
import commonActions from 'actions/commonActions';

import MESSAGE from 'constants/literals/messages';

import uiHelper from 'helpers/uiHelper';
import validationHelper from 'helpers/validationHelper';

import downloadService from 'services/downloadService';

import {SENDING_INITIAL} from 'domain/entities/shipmentDataEntity';

import FormHeader from 'components/common/FormHeader';
import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';

import AlphabetSearch from './components/AlphabetSearch';
import ActionPanel from './components/action_panel/ActionPanel';
import AddressList from './components/address_list/AddressList';
import SaveContact from './components/SaveContact';

import * as Styled from 'styles/modal';

const StyledHeader = styled(Flex)`
  padding: 2.6rem;
`;

interface Search {
  letter: string;
  searchStr: string;
}

interface Props {
  visible: boolean;
  close: () => void;
  onLoad: (address: SavedAddress) => void;
}

function AddressBook({visible, close, onLoad}: Props) {
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user.current);
  const addresses = useSelector((state: AppState) => state.address.list);
  const activePage = useSelector((state: AppState) => state.address.pagination.activePage);
  const pageSize = useSelector((state: AppState) => state.address.pagination.pageSize);
  const countryOptions = useSelector((state: AppState) => state.shipmentOptions.countryOptions);

  const [displayAddresses, setDisplayAddresses] = useState<SavedAddress[]>([]);
  const [search, setSearch] = useState<Search>({letter: '', searchStr: ''});
  const [contact, setContact] = useState<Sending | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [checkedAddresses, setCheckedAddresses] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState({});
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    loadAddresses(true);

    //reset active page number on modal close
    return () => {
      dispatch(addressActions.updateAddressesPageNumber(1));
    };
  }, []);

  useEffect(() => {
    if (!readyToRender) return;

    loadAddresses();
  }, [search.letter]);

  //clear checked addresses on toggle edit mode
  useEffect(() => {
    if (editMode) return;

    setCheckedAddresses([]);
  }, [editMode]);

  useEffect(() => {
    if (!readyToRender) return;

    if (!checkedAddresses.length && checkedAll) {
      setCheckedAll(false);
      setEditMode(false);
    }

    if (checkedAddresses.length === displayAddresses.length && !checkedAll) setCheckedAll(true);

    if (checkedAddresses.length !== displayAddresses.length && checkedAll) setCheckedAll(false);
  }, [checkedAddresses]);

  useEffect(() => {
    if (isEmpty(addresses)) return setDisplayAddresses([]);

    const firstPageIndex = (activePage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;

    setDisplayAddresses(addresses.slice(firstPageIndex, lastPageIndex));
  }, [addresses, activePage]);

  async function loadAddresses(firstLoad = false) {
    await dispatch(addressActions.loadAddresses(search.letter));

    if (firstLoad) setReadyToRender(true);
  }

  function onSearchChange(field, value) {
    setSearch({...search, [field]: value});

    if (value) return;

    loadAddresses();
  }

  function onLetterChange(value) {
    onSearchChange('letter', value);
  }

  function onClose() {
    if (contact) {
      setContact(null);
      setErrors({});
    } else {
      close();
    }
  }

  function addContact() {
    setContact({...SENDING_INITIAL});
  }

  function toggleEditMode() {
    setEditMode(!editMode);
  }

  function toggleCheckbox(checkedId) {
    let index = checkedAddresses.findIndex(id => id === checkedId);

    if (index === -1) {
      setCheckedAddresses(prevArray => [...prevArray, checkedId]);
    } else {
      setCheckedAddresses(checkedAddresses.filter(id => id !== checkedId));
    }
  }

  function selectAllToggle() {
    if (checkedAll) {
      setCheckedAddresses([]);
      setCheckedAll(false);
    } else {
      const checked = displayAddresses.map(item => item.id);
      setCheckedAddresses(checked);

      setCheckedAll(true);
    }
  }

  function updateContact(field, value) {
    if (!contact) return;

    setContact({...contact, [field]: value});
  }

  async function saveContact() {
    if (!contact) return;

    const validationResult: ValidationResult = validationHelper.validateContact(contact, countryOptions);

    if (!validationResult.valid) {
      setErrors(validationResult.errors);
      return;
    }

    const response: any = await dispatch(addressActions.createAddress(contact));
    const address = response as SavedAddress;

    if (address?.id) {
      await loadAddresses();
      setContact(null);
      uiHelper.showNotice(MESSAGE.CREATE_ADDRESS);
    }
  }

  async function deleteContacts() {
    dispatch(
      commonActions.confirmAction({
        title: 'Delete Selected',
        action: async () => {
          try {
            for (let checkedId of checkedAddresses) {
              await dispatch(addressActions.removeAddress(checkedId));
            }

            setCheckedAddresses([]);
          } catch (e) {
            uiHelper.logError(e);
          }
        }
      })
    );
  }

  async function importContacts(e) {
    try {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      let file = e.target.files[0];

      if (!file) return;

      setIsLoading(true);

      let formData = new FormData();
      formData.append('addresses', file);

      const response: any = await dispatch(addressActions.importAddresses(formData, user));
      const notValidAddresses = response as AddressResponse[];

      await loadAddresses();

      if (isEmpty(notValidAddresses)) {
        uiHelper.showNotice('You have successfully imported contacts!');
      } else {
        dispatch(
          commonActions.infoAction({
            text:
              'You have imported contacts, however one or more contact seems to have an issue. Please download spreadsheet of failed contacts, fix them and import them again.',
            type: 'issue',
            actionTitle: 'Download',
            action: () => {
              downloadService.downloadFailedAddresses(notValidAddresses);
            },
            close: () => {}
          })
        );
      }
    } catch (e) {
      uiHelper.logError(e);
    } finally {
      setIsLoading(false);
    }
  }

  function renderPresentationMode() {
    const deleteButtonVisible = editMode && !isEmpty(checkedAddresses);

    return (
      <>
        <AlphabetSearch value={search.letter} onChange={onLetterChange} />

        <ActionPanel
          searchStr={search.searchStr}
          anyContacts={addresses?.length > 0}
          editMode={editMode}
          isLoading={isLoading}
          toggleEditMode={toggleEditMode}
          deleteButtonVisible={deleteButtonVisible}
          onChange={onSearchChange}
          onAddContact={addContact}
          importContacts={importContacts}
          onDelete={deleteContacts}
        />

        <AddressList
          items={displayAddresses}
          checkedItems={checkedAddresses}
          isLoading={isLoading}
          editMode={editMode}
          toggleCheckbox={toggleCheckbox}
          onSelect={onLoad}
          checkedAll={checkedAll}
          selectAllToggle={selectAllToggle}
        />
      </>
    );
  }

  function render() {
    if (!readyToRender) return null;

    let isCreationMode = contact ? true : false;

    return (
      <Styled.CustomModal show={visible} backdrop="static" width="118rem" height="117rem" padding="0" onHide={close}>
        <StyledHeader justify="space-between">
          <FormHeader>Address book</FormHeader>

          <CloseButton onClick={onClose} />
        </StyledHeader>

        {isCreationMode && contact ? (
          <SaveContact
            contact={contact}
            errors={errors}
            close={onClose}
            onChange={updateContact}
            onSave={saveContact}
          />
        ) : (
          renderPresentationMode()
        )}
      </Styled.CustomModal>
    );
  }

  return render();
}

export default AddressBook;

import SLIDER from 'constants/slider';

import entities from 'domain/entities';

import formatHelper from 'helpers/formatHelper';
import dateHelper from 'helpers/dateHelper';

export default {
  mapPickupFromPickupResponse
};

function mapPickupFromPickupResponse(pickupResponse: PickupResponse): Pickup {
  let pickupDate = dateHelper.parseDate(pickupResponse.requestedReadyAtDate);
  let minTime = formatHelper.getDefaultMinSliderTime(pickupResponse.carrier);

  let pickupDataObject: PickupData = {
    date: pickupDate,
    location: pickupResponse.locatedAt,
    startTime: formatHelper.convertTimeStringIntoNumber(pickupResponse?.requestedReadyAtTime, minTime),
    endTime: formatHelper.convertTimeStringIntoNumber(pickupResponse?.requestedClosesAtTime, SLIDER.INITIAL_END_TIME),
    numOfPackages: pickupResponse.numOfPackages,
    notes: pickupResponse.notes
  };

  let pickupData = entities.pickup.createFromData(pickupDataObject);

  let pickup: Pickup = {
    ...pickupData,
    id: pickupResponse.id,
    confirmationNumber: pickupResponse.carrierReferenceNumber,
    contactName: pickupResponse?.contact?.name,
    formattedAddress: pickupResponse?.address?.formattedAddress,
    addressLine1: pickupResponse?.address?.addressLine1,
    shipments: pickupResponse.shipments.map(shipment => shipment.id),
    carrier: pickupResponse.carrier
  };

  return pickup;
}

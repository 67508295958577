import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  const mainColor = colors.darkBlue_main;

  return (
    <svg width="24" height="29" fill="none" viewBox="0 0 24 29">
      <path
        stroke={mainColor}
        d="M4.407 27.259L2.552 8.709h18.896l-1.855 18.55a.5.5 0 01-.498.45H4.905a.5.5 0 01-.498-.45z"></path>
      <path stroke={mainColor} strokeLinecap="round" d="M8 11.209v14M12 11.209v14M16 11.209v14"></path>
      <rect width="23" height="4" x="0.5" y="4.709" stroke={mainColor} rx="0.5"></rect>
      <rect width="11" height="4" x="6.5" y="0.709" stroke={mainColor} rx="0.5"></rect>
      <rect width="7" height="2" x="8.5" y="2.709" stroke={mainColor} rx="0.5"></rect>
    </svg>
  );
}

export default Icon;

export default {
  mapAddressFromAddressResponse
};

function mapAddressFromAddressResponse(response: AddressResponse) {
  const addressResponse = response.address;
  const contactResponse = response.contact;

  let address: SavedAddress = {
    id: response.id,
    companyName: response.companyName,
    contactName: response.contactName,
    country: addressResponse.countryCode,
    phoneNumber: contactResponse?.phone?.number || '',
    phoneNumberExtension: contactResponse?.phone?.extension || '',
    email: contactResponse?.email,
    address: {
      addressLine1: addressResponse?.addressLine1 || '',
      addressLine2: addressResponse?.addressLine2 || '',
      addressLine3: addressResponse?.addressLine3 || '',
      city: addressResponse?.locality || '',
      postalCode: addressResponse?.postalCode || '',
      division: addressResponse?.administrativeArea || '',
      suburb: addressResponse?.dependentLocality || ''
    },
    formattedAddress: addressResponse.formattedAddress,
    isResidential: response.residential ? true : false,
    isVATIncluded: response.vatNumber ? true : false,
    isTaxIdIncluded: response.taxId ? true : false,
    isEORINumberIncluded: response.eoriNumber ? true : false,
    VATNumber: response.vatNumber || '',
    taxId: response.taxId || '',
    EORINumber: response.eoriNumber || ''
  };

  return address;
}

import React, {useState} from 'react';
import styled from 'styled-components/macro';

import formatHelper from 'helpers/formatHelper';

import * as Styled from 'styles/table';

const StyledAddressCell = styled(Styled.TBodyCell)`
  padding-left: 6.2rem !important;
  cursor: pointer;
  background: ${props =>
    props.collapsed
      ? 'url(../images/arrow-up.svg) no-repeat right transparent'
      : 'url(../images/arrow-down.svg) no-repeat right transparent'};
  background-position: 3rem center;
`;

interface Props {
  pickup: Pickup;
}

function AddressCell({pickup}: Props) {
  const [collapsedAddressesWithPickupId, setCollapsedAddressesWithPickupId] = useState<string[]>([]);

  function toggleAddressCell(pickupId: string) {
    const index = collapsedAddressesWithPickupId.findIndex(id => id === pickupId);

    if (index === -1) {
      setCollapsedAddressesWithPickupId(prevArray => [...prevArray, pickupId]);
    } else {
      setCollapsedAddressesWithPickupId(collapsedAddressesWithPickupId.filter(id => id !== pickupId));
    }
  }

  function render() {
    const pickupId = pickup.id;
    const collapsed = collapsedAddressesWithPickupId.find(id => id === pickupId) ? true : false;
    const formattedAddress = formatHelper.displayValue(pickup.formattedAddress);

    const addressToDisplay = collapsed ? formattedAddress : pickup.addressLine1;

    return (
      <StyledAddressCell
        collapsed={collapsed}
        dangerouslySetInnerHTML={{__html: addressToDisplay}}
        onClick={() => toggleAddressCell(pickupId)}
      />
    );
  }

  return render();
}

export default AddressCell;

import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {useSelector} from 'react-redux';
import {isBoolean} from 'lodash';

import {AppState} from 'reducers';

import SettingsRow from './components/SettingsRow';
import AddressSection from './components/AddressSection';
import ActionLink from 'components/common/ActionLink';

const ADDRESS_DEFAULT = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  postalCode: '',
  addressNickname: '',
  isDefaultAddress: false
};

const StyledContainer = styled.div`
  width: 54.6rem;
`;

const StyledAddButton = styled(ActionLink)`
  margin: 1.2rem 0 3rem;
  float: right;
`;

function UserSettingsPage() {
  const READ_ONLY = true;

  const user = useSelector((state: AppState) => state.user.current);

  const [localUser, setLocalUser] = useState({...(user as User)});
  const [editField, setEditField] = useState('');
  const [addresses, setAddresses] = useState([{...ADDRESS_DEFAULT, isDefaultAddress: true}]);

  function toggleEditMode(field) {
    setEditField(field);
  }

  function onUserChange(field, value) {
    setLocalUser({...localUser, [field]: value});
  }

  function onBillingInfoChange(field, value) {}

  function addAddressItem() {
    let newAddresses = [...addresses];

    let newAddress = {...ADDRESS_DEFAULT};
    newAddresses.push(newAddress);

    setAddresses(newAddresses);
  }

  function updateAddressItem(i, field, value) {
    let newAddresses = [...addresses];

    if (isBoolean(value)) {
      newAddresses.forEach(address => {
        address.isDefaultAddress = false;
      });
    }

    let address = {...addresses[i]};
    address[field] = value;

    newAddresses[i] = address;

    setAddresses(newAddresses);
  }

  function removeAddressItem(index) {
    let newAddresses = [...addresses.slice(0, index), ...addresses.slice(index + 1)];

    setAddresses(newAddresses);
  }

  function render() {
    //let passwordDisplay = formatHelper.truncateTextToLength(localUser.password, 30, 'middle');

    const billingInformation = user?.billingInformation;

    return (
      <StyledContainer>
        <SettingsRow
          label="Name"
          field="name"
          editField={editField}
          value={localUser.name}
          onToggleEditMode={toggleEditMode}
          onChange={onUserChange}
        />

        <SettingsRow
          label="Company"
          field="companyName"
          editField={editField}
          value={localUser.companyName}
          onToggleEditMode={toggleEditMode}
          onChange={onUserChange}
        />

        <SettingsRow
          label="E-mail"
          field="email"
          editField={editField}
          value={localUser.email}
          onToggleEditMode={toggleEditMode}
          onChange={onUserChange}
        />

        {/* //Hidden-Feature: enable if required */}
        {/* {!READ_ONLY && (
          <SettingsRow
            label="Current Password"
            field="password"
            editField={editField}
            value={passwordDisplay}
            onToggleEditMode={toggleEditMode}
            onChange={onUserChange}
          />
        )} */}

        <SettingsRow
          label="Import Number"
          field="importNumber"
          editField={editField}
          value={billingInformation?.dhlImportNumber}
          onToggleEditMode={toggleEditMode}
          onChange={onBillingInfoChange}
        />

        <SettingsRow
          label="Export Number"
          field="exportNumber"
          editField={editField}
          value={billingInformation?.dhlExportNumber}
          onToggleEditMode={toggleEditMode}
          onChange={onBillingInfoChange}
        />

        {!READ_ONLY && (
          <>
            {addresses.map((address, index) => {
              return (
                <AddressSection
                  key={index}
                  index={index}
                  address={address}
                  onChange={updateAddressItem}
                  onRemove={removeAddressItem}
                />
              );
            })}

            <StyledAddButton title="Add Additional Address" onClick={addAddressItem} />
          </>
        )}
      </StyledContainer>
    );
  }

  return render();
}

export default UserSettingsPage;

import React from 'react';

function LineItemDescriptionTooltip() {
  return (
    <span>
      Specific description of each unique item <br /> in the shipment. <br />
      <i>
        Example: Original artwork: Handblown <br /> glass dolphin figurine
      </i>
    </span>
  );
}

export default LineItemDescriptionTooltip;

import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  let mainColor = colors.darkBlue_light;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="66" height="88" fill="none" viewBox="0 0 66 88">
      <rect
        width="56"
        height="78"
        x="1"
        y="9"
        fill={colors.grey_light}
        stroke={mainColor}
        strokeWidth="2"
        rx="3"></rect>
      <rect
        width="56"
        height="78"
        x="9"
        y="1"
        fill={colors.grey_light}
        stroke={mainColor}
        strokeWidth="2"
        rx="3"></rect>
      <rect width="42.182" height="2.667" x="15.909" y="10.665" fill={mainColor} rx="1.333"></rect>
      <rect width="42.182" height="2.667" x="15.909" y="21.334" fill={mainColor} rx="1.333"></rect>
      <rect width="42.182" height="2.667" x="15.909" y="32.001" fill={mainColor} rx="1.333"></rect>
      <rect width="42.182" height="2.667" x="15.909" y="42.667" fill={mainColor} rx="1.333"></rect>
      <rect width="19.773" height="2.667" x="15.909" y="54.667" fill={mainColor} rx="1.333"></rect>
      <mask id="path-8-inside-1" fill={colors.white}>
        <rect width="17.136" height="14.667" x="40.954" y="54.667" rx="1"></rect>
      </mask>
      <rect
        width="17.136"
        height="14.667"
        x="40.954"
        y="54.667"
        stroke={mainColor}
        strokeWidth="4"
        mask="url(#path-8-inside-1)"
        rx="1"></rect>
      <rect width="19.773" height="2.667" x="15.909" y="66.665" fill={mainColor} rx="1.333"></rect>
    </svg>
  );
}

export default Icon;

import React from 'react';
import styled, {css} from 'styled-components/macro';

import validationHelper from 'helpers/validationHelper';

import AlertComponent from './AlertComponent';
import FormLabel from './FormLabel';
import QuestionMark from './Marks/QuestionMark';

import {colors, mainFont, secondaryFont} from 'styles/shared';

const StyledRadioContainer = styled.div`
  margin-bottom: 4.6rem;
`;

const StyledLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.4rem;
`;

const StyledDescription = styled.div`
  color: ${colors.darkBlue_main};
  max-width: 27rem;
  margin-bottom: 1rem;
  ${mainFont};
`;

const StyledOptionRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.4rem;
`;

const StyledOptionContainer = styled.div`
  display: flex;
  align-items: center;
  ${props =>
    props.pullRight
      ? css`
          flex: 1;
        `
      : css`
          margin-right: 0.5rem;
        `};

  div:first-child {
    margin: 0;
  }
`;

const StyledRadioInputStyled = styled.input`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 0.1rem solid ${colors.darkBlue_light};
  margin-right: 0.7rem;
  position: relative;
  display: inline-block;
  outline: none;
  appearance: none;
  cursor: pointer;

  &:after {
    content: '${props => '\\00A0'}';
    position: absolute;
    top: 0.7rem;
    left: 0.7rem;
    width: 1.4rem;
    height: 1.4rem;
    background-color: ${props => (props.checked ? colors.orange_main : '')};
    border-radius: 50%;
  }
`;

const StyledOptionLabel = styled.label`
  color: ${colors.darkBlue_main};
  cursor: pointer;
  ${secondaryFont};
`;

const StyledElementContainer = styled.div`
  flex: 2;
`;

interface Props extends BaseProps {
  name: string;
  value?: boolean | string;
  label: string;
  description: string;
  options: RadioInputOption[];
  onChange: OnChangeHandler;
  required: boolean;
  tooltip: string | JSX.Element;
  error?: string | ValidationError[];
}

RadioWithTextInput.defaultProps = {
  required: false,
  description: '',
  tooltip: '',
  options: [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'}
  ],
  className: ''
};

function RadioWithTextInput({
  name,
  label,
  options,
  onChange,
  value,
  required,
  tooltip,
  description,
  error,
  className
}: Props) {
  const inputError: string = validationHelper.getInputError(error);

  return (
    <>
      <StyledRadioContainer className={className}>
        <StyledLabelContainer>
          <div>
            <FormLabel required={required}>{label}</FormLabel>

            {description && <StyledDescription>{description}</StyledDescription>}
          </div>

          {tooltip && <QuestionMark id={`${name}-tooltip`} tooltip={tooltip} />}
        </StyledLabelContainer>

        {options.map(option => {
          const optionValue = option.value;

          const id = `${name}-${optionValue}`;

          const isChecked = optionValue === value;

          const element = option.element;

          const elementPullRight = option?.elementPullRight;

          const elementVisible = (element && isChecked) || (element && option.alwaysVisible);

          return (
            <StyledOptionRow key={optionValue}>
              <StyledOptionContainer pullRight={elementPullRight}>
                <StyledRadioInputStyled
                  type="radio"
                  id={id}
                  name={name}
                  checked={isChecked}
                  value={optionValue}
                  onChange={() => onChange(name, optionValue)}
                />

                <StyledOptionLabel htmlFor={id}>{option.label}</StyledOptionLabel>
              </StyledOptionContainer>

              {elementVisible && <StyledElementContainer>{element}</StyledElementContainer>}
            </StyledOptionRow>
          );
        })}
      </StyledRadioContainer>

      {inputError && <AlertComponent header="Warning!" text={inputError} />}
    </>
  );
}

export default RadioWithTextInput;

const PAGE_ID = {
  USERS_ID: 'users',
  PREPARE_SHIPMENT_ID: 'prepare-shipment',
  ORDERS_ID: 'orders',
  SIGN_IN_ID: 'sign-in',
  DRAFT_ID: 'draft-edit',
  VIEW_HISTORY_ID: 'view-history'
};

export default PAGE_ID;

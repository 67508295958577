import React from 'react';

function AutoPayTooltip() {
  return (
    <span>
      Only one card can be enrolled in Auto Pay at a time. <br />
      By checking this box, you agree that your card will <br />
      be charged once a week on your selected day for <br />
      any open balances. If you have no open balances, <br />
      you will not be charged any fees.
    </span>
  );
}

export default AutoPayTooltip;

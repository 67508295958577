import React, {useState} from 'react';
import styled from 'styled-components/macro';

import FormHeader from 'components/common/FormHeader';
import TextAreaInput from 'components/common/TextAreaInput';
import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import {colors, mainFont, buttonLeftMargin, buttonLeftRightMargin} from 'styles/shared';
import * as Styled from 'styles/modal';

const StyledText = styled.div`
  color: ${colors.darkBlue_main};
  ${mainFont};
  margin-top: 1rem;
`;

interface Props {
  visible: boolean;
  close: () => void;
  onSend: (message: string) => void;
}

function SendDraftsModal({visible, close, onSend}: Props) {
  const [message, setMessage] = useState('');

  return (
    <Styled.CustomModal show={visible} backdrop="static" onHide={close}>
      <FormHeader>Send Drafts to Support</FormHeader>

      <StyledText>
        You will send selected Drafts to OptimalShip Support. <br />
        You can write short message to help Support understand your needs!
      </StyledText>

      <TextAreaInput
        name="message"
        label="Short message"
        placeholder="These are the drafts that I have mentioned on the phone earlier this day."
        value={message}
        onChange={(field, value) => setMessage(value)}
      />

      <Flex justify="flex-end">
        <Button type="secondary" autoWidth margin={buttonLeftRightMargin} onClick={close}>
          Cancel
        </Button>
        <Button autoWidth margin={buttonLeftMargin} onClick={() => onSend(message)}>
          Send
        </Button>
      </Flex>
    </Styled.CustomModal>
  );
}

export default SendDraftsModal;

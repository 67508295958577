import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" viewBox="0 0 44 44">
      <path
        fill={colors.orange_main}
        fillRule="evenodd"
        d="M11 12a2 2 0 00-2 2v16a2 2 0 002 2h22a2 2 0 002-2V14a2 2 0 00-2-2H11zm1.707 3.293a1 1 0 00-1.414 1.414L16.586 22l-5.293 5.293a1 1 0 001.414 1.414L18 23.414l4 4 4-4 5.293 5.293a1 1 0 001.414-1.414L27.414 22l5.293-5.293a1 1 0 00-1.414-1.414l-6 5.999-.001.002L22 24.586l-3.292-3.292v-.001l-.002-.001-5.999-6z"
        clipRule="evenodd"></path>
    </svg>
  );
}

export default Icon;

import React from 'react';

function DangerousGoodsTooltip() {
  return (
    <span>
      Shipments containing dangerous goods (e.g. toxic substances or lithium batteries) <br />
      are regulated by the authorities according to the respective mode of transport <br />
      and therefore need special handling. For an accurate quote for shipments over <br />
      <strong>88lbs</strong>, this specification is required. If the shipment is less <br />
      than 88lbs, please review the terms and conditions during the booking process.
    </span>
  );
}

export default DangerousGoodsTooltip;

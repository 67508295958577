import React from 'react';
import styled from 'styled-components/macro';

import {colors, errorInfoTitle} from 'styles/shared';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-top: 9.5rem;
`;

const StyledTitle = styled.div`
  color: ${colors.darkBlue_main};
  max-width: 53.8rem;
  ${errorInfoTitle};
  margin-bottom: 6rem;
`;

function EmptyList() {
  return (
    <StyledContainer>
      <StyledTitle>Looks like you need to put some Items in your Items List!</StyledTitle>

      <img alt="Commodity Manager" src="/images/commodity-manager.png" />
    </StyledContainer>
  );
}

export default EmptyList;

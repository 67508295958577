import React from 'react';
import styled from 'styled-components/macro';

import {colors} from 'styles/shared';

const StyledContainer = styled.div`
  margin: 1rem 0;
  padding: 1rem 1rem 0;
  background: ${colors.grey_light};
  border-radius: 0.4rem;
`;

function ItemContainerMobile(props) {
  return <StyledContainer>{props.children}</StyledContainer>;
}

export default ItemContainerMobile;

import React from 'react';

function LineItemMadeTooltip() {
  return (
    <span>
      Select the country where the item was made, <br /> this may be different from where it was sold.
      <br />
      <i>
        Example: the glass dolphin figurine was <br /> made in Mexico but sold in California, you <br /> would select
        Mexico.
      </i>
    </span>
  );
}

export default LineItemMadeTooltip;

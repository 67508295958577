import React, {useState} from 'react';
import styled from 'styled-components/macro';

import navigationHelper from 'helpers/navigationHelper';

import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import {mediaQueries} from 'styles/shared';
import inputStyles from 'styles/customization/input';

const StyledContainer = styled(Flex)`
  width: 30.9rem;
  position: relative;

  @media ${mediaQueries.desktopMax} {
    width: 18.8rem;
  }
`;

const StyledInputLarge = styled.input`
  ${inputStyles.trackingNumber.inputField};

  @media ${mediaQueries.desktopMax} {
    display: none;
  }
`;

const StyledInputSmall = styled.input`
  ${inputStyles.trackingNumber.inputField};
  padding-right: 0;

  @media ${mediaQueries.desktopMin} {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 0;

  @media ${mediaQueries.desktopMax} {
    width: 7rem;
    right: -0.7rem;
  }
`;

function TrackingNumber() {
  const [trackingNumber, setTrackingNumber] = useState('');

  function trackingHandler() {
    if (!trackingNumber) return;

    const trackingUrl = navigationHelper.getDHLTrackingUrl(trackingNumber);
    window.open(trackingUrl, '_blank');
  }

  return (
    <StyledContainer>
      <StyledInputLarge
        name="trackingNumber"
        placeholder="Enter Any Tracking Number"
        value={trackingNumber}
        onChange={e => setTrackingNumber(e.target.value)}
      />

      <StyledInputSmall
        name="trackingNumber"
        placeholder="Enter Tracking #"
        value={trackingNumber}
        onChange={e => setTrackingNumber(e.target.value)}
      />

      <StyledButton width={11.6} onClick={trackingHandler}>
        Track
      </StyledButton>
    </StyledContainer>
  );
}

export default TrackingNumber;

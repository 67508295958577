import React, {Component} from 'react';
import styled from 'styled-components/macro';
import Rollbar from 'rollbar';
import {connect} from 'react-redux';

import {AppState} from 'reducers';

import uiHelper from 'helpers/uiHelper';
import config from 'config';

import authService from 'services/authService';
import stateStorageService from 'services/stateStorageService';

import ErrorHandler from './ErrorHandler';
import Button from 'components/common/Button';

const StyledExtraSectionContainer = styled.div`
  margin-top: 4.5rem;
`;

class ErrorBoundary extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      rollbar: new Rollbar({
        accessToken: config.rollbar.accessToken,
        enabled: config.rollbar.enabled,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: config.rollbar.payload
      })
    };

    this.getRollbar = this.getRollbar.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({hasError: true});

    uiHelper.logError(error);
    uiHelper.logError(info);
    uiHelper.logMessage(this.getUserInfo());

    const unsavedDraft = stateStorageService.getUnsavedDraft();
    if (unsavedDraft) uiHelper.logDataObject(unsavedDraft);

    this.getRollbar().error(error);
    this.getRollbar().info(`User ${this.props.user?.email} received the crash screen and had to reload`);
  }

  getUserInfo() {
    const user: User = this.props.user;

    if (!user) return null;

    return {
      userId: user.id,
      email: user.email,
      isCsr: user.isCSR,
      isHouseAccountUsed: this.props.isHouseAccountUsed,
      authContext: authService.getAuthContext(),
      lastRequestId: stateStorageService.getRequestId()
    };
  }

  getRollbar(): Rollbar {
    const user: User = this.props.user;

    if (user) {
      const person = {
        id: user.id,
        username: user.name,
        email: user.email
      };
      
      this.state.rollbar.configure({
        payload: {person}
      });
    }

    return this.state.rollbar;
  }

  render() {
    let pageReload = () => {
      window.location.reload();
    };

    if (this.state.hasError) {
      let extraSection = (
        <StyledExtraSectionContainer>
          <Button onClick={pageReload} autoWidth>
            Reload Page
          </Button>
        </StyledExtraSectionContainer>
      );

      return (
        <ErrorHandler
          showLogo={true}
          extraSection={extraSection}
          errorText="Something went wrong. Please reload the page and try again."
        />
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = function (state: AppState) {
  return {
    user: state.user.current,
    isHouseAccountUsed: state.common.isHouseAccountUsed
  };
};

export default connect(mapStateToProps)(ErrorBoundary);

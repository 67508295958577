import React from 'react';
import styled from 'styled-components/macro';

import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';
import FormLabel from 'components/common/FormLabel';

import InternationalDisclaimer from './components/InternationalDisclaimer';
import DomesticDisclaimer from './components/DomesticDisclaimer';

import * as Styled from 'styles/modal';
import {colors} from 'styles/shared';

const StyledAsterisk = styled.span`
  color: ${colors.orange_main};
`;

interface Props {
  visible: boolean;
  isDomestic: boolean;
  close: () => void;
}

function Disclaimer({visible, isDomestic, close}: Props) {
  return (
    <Styled.CustomModal show={visible} backdrop="static" width="57.8rem" padding="4rem 4rem 2rem" onHide={close}>
      <Flex justify="space-between">
        <FormLabel>
          <StyledAsterisk>*</StyledAsterisk>Shipping Quote Disclaimer
        </FormLabel>
        <CloseButton onClick={close} />
      </Flex>

      {isDomestic ? <DomesticDisclaimer /> : <InternationalDisclaimer />}
    </Styled.CustomModal>
  );
}

export default Disclaimer;

import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Row, Col} from 'components/bootstrap';
import {isEmpty} from 'lodash';
import {useSelector} from 'react-redux';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';
import pickupActions from 'actions/pickupActions';

import PICKUP_TAB from 'constants/pickupTabs';
import MESSAGE from 'constants/literals/messages';
import SECTION from 'constants/sections';

import uiHelper from 'helpers/uiHelper';
import dateHelper from 'helpers/dateHelper';

import pickupService from 'services/pickupService';
import userInfoService from 'services/userInfoService';

import Container from 'components/common/Container';
import Pagination from 'components/common/Pagination/SimplePagination';
import PickupHeader from './components/header/PickupHeader';
import NoPickups from './components/NoPickups';
import EmptyList from '../EmptyList';
import SavePickupModal from 'components/common/Scheduler/SavePickupModal';
import EditShipmentsModal from './components/EditShipmentsModal';
import PickupsList from './components/pickups_list/PickupsList';

import PickupHeaderMobile from 'components/view_history/mobile/components/scheduled_pickups/PickupHeaderMobile';
import PickupsListMobile from 'components/view_history/mobile/components/scheduled_pickups/PickupsListMobile';
import ListContainerMobile from 'components/view_history/mobile/components/ListContainerMobile';
import PaginationMobile from 'components/view_history/mobile/components/PaginationMobile';

import * as Styled from 'styles/table';

interface Props {
  pickups: Pickup[];
}

function ScheduledPickups({pickups}: Props) {
  const dispatch = useDispatch();

  const pagination = useSelector((state: AppState) => state.pickup.pagination);
  const shipments = useSelector((state: AppState) => state.shipment.list);

  const [activeTab, setActiveTab] = useState(PICKUP_TAB.TODAY);
  const [pickupsDisplay, setPickupsDisplay] = useState({});
  const [pickupToReschedule, setPickupToReschedule] = useState<Pickup | null>(null);
  const [confirmedDates, setConfirmedDates] = useState([]);
  const [shipmentsToEdit, setShipmentsToEdit] = useState([]);

  const actions: DropdownAction[] = [
    {label: 'Cancel Pickup', icon: 'cancel-pickup', action: cancelPickup, withoutMargin: true},
    {label: 'Reschedule Pickup', icon: 'reschedule-pickup', action: toggleReschedulePickupModal, withoutMargin: true},
    {label: 'Edit Shipments', icon: 'edit-shipment', action: onEditShipments}
  ];

  useEffect(() => {
    let displayPickups: any = {
      [PICKUP_TAB.TODAY]: [],
      [PICKUP_TAB.TOMORROW]: [],
      [PICKUP_TAB.FUTURE]: [],
      [PICKUP_TAB.PAST]: []
    };

    pickups.forEach((pickup: Pickup) => {
      const date = pickup.date;

      if (dateHelper.todayDate(date)) {
        displayPickups[PICKUP_TAB.TODAY].push(pickup);
      } else if (dateHelper.tomorrowDate(date)) {
        displayPickups[PICKUP_TAB.TOMORROW].push(pickup);
      } else if (dateHelper.inNextSevenDays(date)) {
        displayPickups[PICKUP_TAB.FUTURE].push(pickup);
      } else if (dateHelper.isPastDate(date)) {
        displayPickups[PICKUP_TAB.PAST].push(pickup);
      }
    });

    let initialConfirmedDates: any = pickupService.getConfirmedDates(pickups);

    setConfirmedDates(initialConfirmedDates);
    setPickupsDisplay(displayPickups);
  }, [pickups]);

  function cancelPickup(pickup) {
    dispatch(
      commonActions.confirmAction({
        title: MESSAGE.CANCEL_PICKUP_TITLE,
        text: MESSAGE.CANCEL_PICKUP_TEXT,
        action: async () => {
          await dispatch(pickupActions.cancelPickupAction(pickup.id));
        }
      })
    );
  }

  function onTabChange(tab) {
    setActiveTab(tab);
  }

  // Editing pickups is currently nonfunctional for all carriers in backend
  function toggleReschedulePickupModal(pickup: Pickup | null = null) {
    if (pickup) {
      dispatch(commonActions.infoAction(
          uiHelper.getCannotReschedulePickupPopupParameters(pickup.carrier)
      ));
      return;
    }
    setPickupToReschedule(pickup);
  }

  async function reschedulePickup(pickupToEdit: PickupData) {
    toggleReschedulePickupModal();

    await dispatch(pickupActions.editPickup(pickupToEdit, pickupToReschedule?.id));
  }

  function onEditShipments(pickup: Pickup) {
    let shipments: any = pickup.shipments;
    setShipmentsToEdit(shipments);
  }

  function onCloseEditShipmentsModal() {
    setShipmentsToEdit([]);
  }

  async function removeShipmentsFromPickup(shipmentIds: string[]) {
    dispatch(
      commonActions.confirmAction({
        title: 'Are you sure you want to remove Shipments from the Pickup?',
        text: 'You can find Shipments in Draft section and create new Pickup for them.',
        action: async () => {
          uiHelper.notImplemented();

          //Fix-API: call APIs endpoint
          let response: any = {successful: false};

          if (response?.successful) {
            uiHelper.showNotice(MESSAGE.REMOVE_SHIPMENTS_FROM_PICKUP);
          }

          onCloseEditShipmentsModal();
        }
      })
    );
  }

  function onPageChange(page) {
    dispatch(pickupActions.updatePickupsPageNumber(page));
  }

  function renderPickupsTable(activePickups, isActivePickupsPresent) {
    const isPastTab = activeTab === PICKUP_TAB.PAST;

    return (
      <Styled.Table>
        <thead>
          <Styled.TRowHead topBorder>
            <Styled.THeadCell>Pickup Address</Styled.THeadCell>
            <Styled.THeadCell>Contact</Styled.THeadCell>
            <Styled.THeadCell>Pickup Window</Styled.THeadCell>
            <Styled.THeadCell>Pickup Conf. Nr.</Styled.THeadCell>
            {!isPastTab && <Styled.THeadCell />}
          </Styled.TRowHead>
        </thead>

        {isActivePickupsPresent && (
          <PickupsList activePickups={activePickups} activeTab={activeTab} pickupActions={actions} />
        )}
      </Styled.Table>
    );
  }

  function renderPickupsConditions(isNoPickups, isActivePickupsPresent) {
    if (!isNoPickups && !isActivePickupsPresent) return <NoPickups activeTab={activeTab} />;

    if (isNoPickups) {
      return (
        <EmptyList
          image="no-pickups.png"
          title="Looks like there are no Scheduled Pickups!"
          subtitle="Click “Prepare a Shipment” on the main menu above and start shipping today. If you already have your shipping labels and only need a pickup scheduled, you can click on “Schedule a Pickup” on the main menu above."
        />
      );
    }
  }

  function renderModals() {
    const reschedulePickupModalVisible = pickupToReschedule ? true : false;
    const editShipmentsModalVisible = isEmpty(shipmentsToEdit) ? false : true;
    const getPickupShipments = (shipmentIDs: string[]): Shipment[] => {
      return shipments.filter((shipment: Shipment) => {
        return shipmentIDs.find(shipmentId => shipmentId === shipment.id);
      });
    };

    return (
      <>
        {pickupToReschedule && (
          <SavePickupModal
            title="Reschedule Pickup"
            visible={reschedulePickupModalVisible}
            pickupId={pickupToReschedule?.id}
            pickup={pickupToReschedule}
            carrier={pickupToReschedule.carrier}
            confirmedDates={confirmedDates}
            close={toggleReschedulePickupModal}
            onSave={reschedulePickup}
            shipment={getPickupShipments(pickupToReschedule.shipments)[0]}
          />
        )}

        {editShipmentsModalVisible && (
          <EditShipmentsModal
            visible={editShipmentsModalVisible}
            pickupShipments={getPickupShipments(shipmentsToEdit)}
            close={onCloseEditShipmentsModal}
            onSave={removeShipmentsFromPickup}
          />
        )}
      </>
    );
  }

  function render() {
    if (isEmpty(pickupsDisplay)) return null;

    const activePickups = pickupsDisplay[activeTab];
    const isActivePickupsPresent = activePickups.length > 0;
    const isNoPickups = isEmpty(pickups);

    const isMobile = userInfoService.isMobile();

    if (isMobile) {
      return (
        <ListContainerMobile>
          <PickupHeaderMobile pickups={pickupsDisplay} activeTab={activeTab} onChange={onTabChange} />
          {renderPickupsConditions(isNoPickups, isActivePickupsPresent)}
          {isActivePickupsPresent && (
            <PickupsListMobile activePickups={activePickups} activeTab={activeTab} pickupActions={actions} />
          )}

          <PaginationMobile label="Total Pickups" pagination={pagination} onChange={onPageChange} />
          {renderModals()}
        </ListContainerMobile>
      );
    }

    return (
      <Container withoutPadding={true} id={SECTION.STATUS.PICKUPS}>
        <Row>
          <Col sm={12}>
            <PickupHeader pickups={pickupsDisplay} activeTab={activeTab} onTabChange={onTabChange} />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {renderPickupsTable(activePickups, isActivePickupsPresent)}
            {renderPickupsConditions(isNoPickups, isActivePickupsPresent)}
          </Col>
        </Row>

        <Pagination
          label="Total Pickups"
          pagination={pagination}
          bordered={isNoPickups || !isActivePickupsPresent}
          onChange={onPageChange}
        />

        {renderModals()}
      </Container>
    );
  }

  return render();
}

export default ScheduledPickups;

import {sortBy, pull} from 'lodash';

import * as actions from 'action_types/shipmentOptionsActionTypes';

import EXPORT_TYPE from 'constants/exportTypes';
import ITEM_QUANTITY_LABEL_MAPPING from 'constants/itemQuantityUnits/itemQuantityLabelMapping';
import ITEM_QUANTITY_VALUES_TO_REMOVE from 'constants/itemQuantityUnits/itemQuantityValuesToRemove';

import helper from './actionHelper';
import apis from 'domain/apis';

export default {
  loadOptionsData
};

function loadOptionsData() {
  return helper.dispatchAsyncAction(async dispatch => {
    let [
      ftrOptions,
      countryOptions,
      signatureServices,
      whoPaysOptions,
      tradeOptions,
      currencyList,
      carrierServices,
      itemQuantityOptions,
      invoiceTypeOptions,
      eeiFilingTypeOptions,
      exportOptions
    ] = await Promise.all([
      loadFtrExemptions(),
      loadCountryOptions(),
      loadAdditionalServiceOptions(),
      loadWhoPaysOptions(),
      loadTermsOfTradeOptions(),
      loadCurrencyOptions(),
      loadCarrierServices(),
      loadItemQuantityOptions(),
      loadInvoiceTypeOptions(),
      loadEeiFilingTypeOptions(),
      loadExportTypeOptions()
    ]);

    let payload = {
      ftrOptions,
      countryOptions,
      signatureServices,
      whoPaysOptions,
      tradeOptions,
      currencyList,
      carrierServices,
      itemQuantityOptions,
      invoiceTypeOptions,
      eeiFilingTypeOptions,
      exportOptions
    };

    dispatch(helper.getAction(actions.LOAD_OPTIONS_DATA, payload));
  });
}

async function loadFtrExemptions(): Promise<BasicOption[]> {
  const ftrExemptions = await apis.option.getFtrExemptions();

  const ftrOptions = ftrExemptions.map(item => {
    return {value: item.code, label: item.code};
  });

  return ftrOptions;
}

async function loadCountryOptions(): Promise<CountryOption[]> {
  const countryOptions = await apis.option.getCountryOptions();

  return countryOptions;
}

async function loadAdditionalServiceOptions(): Promise<BasicOption[]> {
  const additionalServiceOptions = await apis.option.getAdditionalServiceOptions();

  const signatureServices = additionalServiceOptions.map(service => {
    return {value: service.id, label: service.description};
  });

  return signatureServices;
}

async function loadWhoPaysOptions(): Promise<string[]> {
  const whoPaysOptions = await apis.option.getWhoPaysOptions();

  return whoPaysOptions;
}

async function loadTermsOfTradeOptions(): Promise<TermsOfTradeOption[]> {
  const termsOfTradeOptions = await apis.option.getTermsOfTradeOptions();

  let tradeOptions = termsOfTradeOptions.map(item => {
    const code = item.code;

    return {value: code, label: `${code} - ${item.description}`, definition: item.definition};
  });

  tradeOptions = sortBy(tradeOptions, option => option.value);

  return tradeOptions;
}

async function loadCurrencyOptions(): Promise<BasicOption[]> {
  const currencyOptions = await apis.option.getCurrencyOptions();

  let currencyList = currencyOptions.map(item => {
    return {value: item, label: item};
  });

  currencyList = sortBy(currencyList, option => option.value);

  return currencyList;
}

async function loadCarrierServices(): Promise<CarrierService[]> {
  const carrierServices = await apis.option.getCarrierServices();

  return carrierServices;
}

async function loadItemQuantityOptions(): Promise<BasicOption[]> {
  const quantityOptions = await apis.option.getItemQuantityOptions();

  const quantityOptionsToDisplay = quantityOptions;

  ITEM_QUANTITY_VALUES_TO_REMOVE.forEach((value) => {
    pull(quantityOptionsToDisplay, value);
  });

  const itemQuantityOptions = quantityOptionsToDisplay.map((item: string) => {
    let label: string = item;

    if (ITEM_QUANTITY_LABEL_MAPPING.hasOwnProperty(item)) {
      label = ITEM_QUANTITY_LABEL_MAPPING[item];
    }
    return {value: item, label: label};
  });

  return itemQuantityOptions;
}

async function loadInvoiceTypeOptions(): Promise<string[]> {
  const invoiceTypeOptions = await apis.option.getInvoiceTypeOptions();

  return invoiceTypeOptions;
}

async function loadEeiFilingTypeOptions(): Promise<any[]> {
  const eeiFilingTypeOptions = await apis.option.getEeiFilingTypeOptions();

  return eeiFilingTypeOptions;
}

async function loadExportTypeOptions(): Promise<BasicOption[]> {
  const exportTypeOptions = await apis.option.getExportTypeOptions();

  const exportOptions = exportTypeOptions.map(item => {
    let label = item;

    if (item === EXPORT_TYPE.PERMANENT) label = `${label} - it won’t be shipped back`;
    if (item === EXPORT_TYPE.TEMPORARY) label = `${label} - it will be shipped back`;

    return {value: item, label};
  });

  return exportOptions;
}

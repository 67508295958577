import React from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';

import dateHelper from 'helpers/dateHelper';

import entities from 'domain/entities';

import Flex from 'components/common/Flex';
import ButtonActionsPopup from 'components/common/ActionsPopup/ButtonActionsPopup';
import ItemContainerMobile from '../ItemContainerMobile';
import ListItemMobile from '../ListItemMobile';

import extras from 'components/view_history/extras';

const StyledButtonContainer = styled(Flex)`
  padding: 1rem 0.6rem 2rem 0;
`;

interface Props {
  drafts: any[];
  actions: DropdownAction[];
  carrierServices: CarrierService[];
}

function DraftsListMobile({drafts, actions, carrierServices}: Props) {
  return (
    <>
      {drafts.map(draft => {
        const draftObject = entities.draft.parse(draft.payload);
        if (!draftObject) return null;

        const id = draft.id;
        const shipmentFrom: Sending = draftObject.shipmentFrom;
        const shipmentTo: Sending = draftObject.shipmentTo;
        const pickupDelivery: PickupDelivery = draftObject.pickupDelivery;

        const displayServiceName = extras.displayCarrierServiceName(pickupDelivery.carrierProduct, carrierServices);
        const displayDate = dateHelper.displayShortDate(pickupDelivery.shipmentDate);

        return (
          <ItemContainerMobile key={id}>
            <Row>
              <Col>
                <ListItemMobile label="Origin" value={shipmentFrom.country} />
                <ListItemMobile label="Contact" value={shipmentFrom.contactName} />
                <ListItemMobile label="Ship Date" value={displayDate} />
                <ListItemMobile label="Draft Name" value={draftObject.name} bold />
              </Col>
              <Col>
                <ListItemMobile label="Destination" value={shipmentTo.country} />
                <ListItemMobile label="Receiver" value={shipmentTo.contactName} />
                <ListItemMobile label="Service" value={displayServiceName} />
                <ListItemMobile label="Draft ID" value={id} bold info />
                <StyledButtonContainer justify="flex-end">
                  <ButtonActionsPopup actions={actions} activeItem={draft} />
                </StyledButtonContainer>
              </Col>
            </Row>
          </ItemContainerMobile>
        );
      })}
    </>
  );
}

export default DraftsListMobile;

import React from 'react';
import {Row, Col} from 'components/bootstrap';
import {useSelector, useDispatch} from 'react-redux';

import {AppState} from 'reducers';
import draftActions from 'actions/draftActions';

import unitHelper from 'helpers/unitHelper';

import SystemUnitsToggler from 'components/common/SystemUnitsToggler';

interface Props {
  currency: string;
  measurementSystem: string;
}

function SystemUnits({currency, measurementSystem}: Props) {
  const dispatch = useDispatch();

  const currencyOptions = useSelector((state: AppState) => state.shipmentOptions.currencyOptions);

  const measurementSystemOptions: BasicOption[] = unitHelper.getMeasurementSystemOptions();

  function onUnitChange(field, value) {
    dispatch(draftActions.modifyDraft(null, field, value));
  }

  return (
    <Row>
      <Col lg={6}>
        <SystemUnitsToggler
          name="currency"
          label="Currency"
          title="Select Currency"
          value={currency}
          options={currencyOptions}
          onChange={onUnitChange}
        />
      </Col>
      <Col lg={6}>
        <SystemUnitsToggler
          name="measurementSystem"
          label="Measurement system"
          title="Select System"
          value={measurementSystem}
          options={measurementSystemOptions}
          onChange={onUnitChange}
        />
      </Col>
    </Row>
  );
}

export default SystemUnits;

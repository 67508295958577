import React from 'react';

function DomesticDangerousGoodsTooltip() {
  return (
    <span>
      If shipping Dangerous Goods or Alcohol, <br />
      please contact customer service at 972-383-9901.
    </span>
  );
}

export default DomesticDangerousGoodsTooltip;

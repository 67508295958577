import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { AppState } from 'reducers';

import dateHelper from 'helpers/dateHelper';
import formatHelper from 'helpers/formatHelper';
import rateService from 'services/rateService';
import config from 'config';

import COMMON from 'constants/common';
import MESSAGE from 'constants/literals/messages';

import Button from 'components/common/Button';
import FormHeader from 'components/common/FormHeader';
import Flex from 'components/common/Flex';
import Badge from 'components/common/Badge';
import ActionLink from 'components/common/ActionLink';
import TotalSumDetailsModal from 'components/common/TotalSum/components/TotalSumDetailsModal';

import {colors, opacity, mainFont, secondaryFont} from 'styles/shared';

const StyledExpressContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};
  margin: 2rem 0;
  padding: 2rem;
  position: relative;
`;

const StyledAmount = styled(FormHeader)`
  color: ${colors.orange_main};
  margin: 1rem 0 1rem;
`;

const StyledTotal = styled(StyledAmount)`
  color: ${colors.darkBlue_main};
`;

const StyledLabel = styled.div`
  ${mainFont};
  font-weight: bold;
`;

const StyledSubtitle = styled(StyledLabel)`
  font-weight: initial;
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  right: 2.6rem;
  top: 1.4rem;
`;

const StyledAsterisk = styled.span`
  color: ${colors.orange_main};
`;

const StyledDetails = styled.div`
  margin-top: 0.5rem;
  ${secondaryFont}
`;

interface Props {
  service: Rate;
  carrierProduct?: string;
  isBtnDisabled?: boolean;
  isDomestic: boolean;
  isLowerPrice: boolean;
  onChange?: OnChangeHandler;
}

function ExpressService({service, carrierProduct, isBtnDisabled, isDomestic, isLowerPrice, onChange}: Props) {
  const draft = useSelector((state: AppState) => state.draft.current.data);

  const [detailsVisible, setDetailsVisible] = useState(false);

  const carrier = service?.carrierProduct;

  const header = isDomestic ? service?.carrierService?.name : 'DHL Express';

  const dateFormat = isDomestic ? config.format.estimatedDeliveryDateDomestic : config.format.estimatedDeliveryDate;
  const deliveryDate = dateHelper.displayDate(service.estimatedDelivery, dateFormat);

  const isIncomplete = rateService.isIncompleteRate(service);
  const isUSTerritories = draft?.isUSTerritories;
  const displayDetails = !isIncomplete && !isUSTerritories;

  const amountDisplay = formatHelper.displayDollarAmount(service.displayOptimalRate);
  const totalDisplay = formatHelper.displayDollarAmount(service.displayRetailRate);
  const savingsValue =
      displayDetails ? (service.savings ? service.savingsPercent : COMMON.EMPTY_RATE) : '0';
  const savingsDisplay = `${savingsValue}%`;

  const isActiveBtn = carrierProduct === carrier;

  const buttonTheme = isActiveBtn ? 'success' : 'main';
  const buttonText = isActiveBtn ? 'Selected' : 'Select';

  function toggleDetails() {
    setDetailsVisible(!detailsVisible);
  }

  function selectService() {
    if (onChange) onChange('carrierProduct', carrier);
  }

  return (
    <StyledExpressContainer>
      <FormHeader>{header}&#174;</FormHeader>

      <StyledAmount>{amountDisplay}</StyledAmount>

      {displayDetails &&
        <>
          <StyledLabel>{MESSAGE.TOTAL_CHARGE}:</StyledLabel>
          <StyledTotal>{totalDisplay}<StyledAsterisk>*</StyledAsterisk></StyledTotal>
        </>
      }

      <Flex justify="space-between">
        <div>
          <StyledLabel>Estimated Delivery Date:</StyledLabel>
          <StyledSubtitle>{deliveryDate}</StyledSubtitle>
        </div>

        <Button theme={buttonTheme} disabled={isBtnDisabled} width={10.6} onClick={selectService}>
          {buttonText}
        </Button>
      </Flex>

      {isLowerPrice && <StyledBadge size="small">Lowest Price!</StyledBadge>}

      {displayDetails && <StyledDetails>
        <StyledAsterisk>*</StyledAsterisk>
        {displayDetails && <ActionLink title="See Details" onClick={toggleDetails} />}
      </StyledDetails>}

      {detailsVisible && (
          <TotalSumDetailsModal
            visible={detailsVisible}
            rate={service}
            displayShippingCharge={amountDisplay}
            displaySavings={savingsDisplay}
            displayTotalCharge={totalDisplay}
            close={toggleDetails}
          />
      )}
    </StyledExpressContainer>
  );
}

export default ExpressService;

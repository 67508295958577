import React from 'react';
import styled from 'styled-components/macro';
import {Modal, Row, Col, Container} from 'components/bootstrap';
import {useSelector} from 'react-redux';

import {AppState} from 'reducers';

import UNITS from 'constants/units';

import uiHelper from 'helpers/uiHelper';

import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';
import FormHeader from 'components/common/FormHeader';
import Button from 'components/common/Button';
import RecipientSection from './components/RecipientSection';
import OrderSection from './components/OrderSection';
import OrderLineItems from './components/OrderLineItems';

import {leftPanelWidth, navBarHeight, ordersNavBarHeight, mainBorder, buttonLeftRightMargin} from 'styles/shared';

const MODAL_GAP = '2rem';

const StyledModal = styled(Modal)`
  text-align: center;
  padding: 0 !important;
  left: calc(${leftPanelWidth} / 2 + ${MODAL_GAP} / 2);
  padding-top: calc(${navBarHeight} + ${ordersNavBarHeight} + ${MODAL_GAP}) !important;

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 109.2rem;
    max-width: 109.2rem;

    .modal-content {
      .modal-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -1.5rem;
  }
`;

const StyledHeader = styled(Flex)`
  padding: 2.6rem 2.3rem 2rem 2.6rem;
`;

const StyledForm = styled.div`
  padding: 0 1.1rem;
`;

const StyledFooter = styled(Flex)`
  padding: 2rem 0;
  border-top: ${mainBorder};
`;

interface Props {
  visible: boolean;
  close: () => void;
  order: Order;
  onChange: (field: string, value: any) => void;
}

function CreateOrderModal({visible, close, order, onChange}: Props) {
  const countryList = useSelector((state: AppState) => state.shipmentOptions.countryList);
  const currencyOptions = useSelector((state: AppState) => state.shipmentOptions.currencyOptions);

  function updateRecipient(field, value) {
    onChange('recipientInformation', {...order.recipientInformation, [field]: value});
  }

  function updateOrderInformation(field, value) {
    onChange('orderInformation', {...order.orderInformation, [field]: value});
  }

  function addLineItem() {
    let newLineItems = [...order.orderLineItems];

    const defaultLineItem: OrderLineItem = {
      sku: '',
      name: '',
      quantity: 0,
      price: {amount: 0, currency: UNITS.CURRENCY_USD}
    };

    newLineItems.push(defaultLineItem);

    onChange('orderLineItems', newLineItems);
  }

  function updateLineItem(i, field, value) {
    let newLineItems = [...order.orderLineItems];

    let item = {...order.orderLineItems[i]};
    item[field] = value;

    newLineItems[i] = item;

    onChange('orderLineItems', newLineItems);
  }

  function removeLineItem(index) {
    let newLineItems = [...order.orderLineItems.slice(0, index), ...order.orderLineItems.slice(index + 1)];

    onChange('orderLineItems', newLineItems);
  }

  return (
    <StyledModal show={visible} backdrop="static" onHide={close}>
      <StyledHeader justify="space-between">
        <FormHeader>New Order</FormHeader>
        <CloseButton onClick={close} />
      </StyledHeader>

      <Container fluid>
        <StyledForm>
          <Row>
            <Col lg={6}>
              <RecipientSection
                recipient={order.recipientInformation}
                countryList={countryList}
                onChange={updateRecipient}
              />
            </Col>
            <Col lg={6}>
              <OrderSection order={order.orderInformation} onChange={updateOrderInformation} />
            </Col>
          </Row>
        </StyledForm>
      </Container>

      <OrderLineItems
        items={order.orderLineItems}
        addItem={addLineItem}
        updateItem={updateLineItem}
        removeItem={removeLineItem}
        currencyList={currencyOptions}
      />

      <StyledFooter justify="center">
        <Button type="secondary" autoWidth margin={buttonLeftRightMargin} onClick={close}>
          Cancel
        </Button>

        <Button autoWidth margin={buttonLeftRightMargin} onClick={uiHelper.notImplemented}>
          Save Order
        </Button>
      </StyledFooter>
    </StyledModal>
  );
}

export default CreateOrderModal;

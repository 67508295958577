import update from 'immutability-helper';

import * as actions from 'action_types/paymentActionTypes';

import PAYMENT_METHOD from 'constants/paymentMethods';
import PAYMENT_TIME from 'constants/paymentTime';

import initialState, {CREDIT_CARD, ACH_ACCOUNT, BILLING_ADDRESS, BILLING_CONTACT_INFORMATION} from './initialState';
import helper from './reducerHelper';

export interface PaymentState {
  selectedPaymentMethod: string;
  paymentTime: string;
  paymentId: string;
  paymentMethods: PaymentMethod[];
  creditCard: CreditCard;
  achAccount: AchAccount;
  billingAddress: Address;
  billingContactInformation: BillingContactInformation;
  errors: object;
  paymentParamsChanged?: number;
}

const userReducer = (state: PaymentState = initialState.payment, action) => {
  return helper.handleActions<PaymentState>(state, action, {
    [actions.LOAD_PAYMENT_METHODS](state, payload: {paymentMethods: PaymentMethod[]}) {
      state.paymentMethods = payload.paymentMethods;
    },
    [actions.MODIFY_CREDIT_CARD](state, payload: {field: string; value: string}) {
      const creditCard = update(state.creditCard, {[payload.field]: {$set: payload.value}});
      state.creditCard = creditCard;
      state.paymentParamsChanged = new Date().getTime();
    },
    [actions.MODIFY_ACH_ACCOUNT](state, payload: {field: string; value: string}) {
      const achAccount = update(state.achAccount, {[payload.field]: {$set: payload.value}});
      state.achAccount = achAccount;
      state.paymentParamsChanged = new Date().getTime();
    },
    [actions.MODIFY_BILLING_ADDRESS](state, payload: {field: string; value: string}) {
      const billingAddress = update(state.billingAddress, {[payload.field]: {$set: payload.value}});
      state.billingAddress = billingAddress;
      state.paymentParamsChanged = new Date().getTime();
    },
    [actions.SELECT_BILLING_ADDRESS](state, payload: {address: Address}) {
      state.billingAddress = payload.address;
    },
    [actions.CHANGE_PAYMENT_METHOD](state, payload: {paymentMethod: string}) {
      state.selectedPaymentMethod = payload.paymentMethod;
    },
    [actions.UPDATE_PAYMENT_ID](state, payload: {paymentId: string}) {
      state.paymentId = payload.paymentId;
    },
    [actions.SET_PAYMENT_ERRORS](state, payload: {errors: object}) {
      state.errors = payload.errors;
    },
    [actions.CLEAR_CREDIT_CARD_AND_ACH_ACCOUNT](state) {
      state.creditCard = {...CREDIT_CARD};
      state.achAccount = {...ACH_ACCOUNT};
      state.billingAddress = {...BILLING_ADDRESS};
    },
    [actions.CLEAR_PAYMENT_STATE](state) {
      clearState(state);
    },
    [actions.CHANGE_PAYMENT_TIME](state, payload: {paymentTime: string}) {
      const paymentTime = payload.paymentTime;

      state.paymentTime = paymentTime;
      if (paymentTime === PAYMENT_TIME.PAY_LATER && state.paymentId) {
        state.paymentId = '';
      }
    },
    [actions.MODIFY_BILLING_CONTACT_INFORMATION](state, payload: {field: string; value: string}) {
      const billingContactInformation = update(state.billingContactInformation, {
        [payload.field]: {$set: payload.value}
      });

      state.billingContactInformation = billingContactInformation;
    }
  });
};

function clearState(state) {
  state.selectedPaymentMethod = PAYMENT_METHOD.CREDIT_CARD;
  state.paymentTime = PAYMENT_TIME.PAY_NOW;
  state.paymentId = '';
  state.paymentMethods = [];
  state.creditCard = {...CREDIT_CARD};
  state.achAccount = {...ACH_ACCOUNT};
  state.billingAddress = {...BILLING_ADDRESS};
  state.billingContactInformation = {...BILLING_CONTACT_INFORMATION};
  state.paymentParamsChanged = undefined;
  state.errors = {};
}

export default userReducer;

import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {Row, Col} from 'components/bootstrap';

import {AppState} from 'reducers';

import DRAFT_STEP from 'constants/draftSteps';
import COMMON from 'constants/common';
import ELECTRONIC_EXPORT_FILING from 'constants/electronicExportFilings';
import SECTION from 'constants/sections';
import FEATURE from 'constants/features';
import PAYMENT_TIME from 'constants/paymentTime';
import DOCUMENT_PACKAGING_TYPE_OPTIONS from 'constants/packagingTypes/packagingTypeOptions';

import formatHelper from 'helpers/formatHelper';
import dateHelper from 'helpers/dateHelper';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import ReviewRow from './components/ReviewRow';
import ReviewText from './components/ReviewText';
import ReviewPickup from './components/ReviewPickup';

interface Props {
  navigateTo: (step: string, section?: string) => void;
}

function ReviewFinish({navigateTo}: Props) {
  const draft = useSelector((state: AppState) => state.draft.current.data as Draft);
  const carrierServices = useSelector((state: AppState) => state.shipmentOptions.carrierServices);
  const rate = useSelector((state: AppState) => state.draft.rate.current);
  const paymentTime = useSelector((state: AppState) => state.payment.paymentTime);

  const pickupDelivery = draft.pickupDelivery;
  const payment = draft.payment;

  function navigateToDetailsSection() {
    const section = draft.isDomestic ? SECTION.DRAFT.DOMESTIC_DETAILS : SECTION.DRAFT.DETAILS;
    navigateTo(DRAFT_STEP.CREATE_SHIPMENT, section);
  }

  function renderFromToDetails() {
    const from = draft.shipmentFrom;
    const fromAddress: Address = from.address;

    const to = draft.shipmentTo;
    const toAddress: Address = to.address;

    return (
      <ReviewRow title="From:" onEditSection={() => navigateTo(DRAFT_STEP.CREATE_SHIPMENT)}>
        <ReviewText>{from.contactName}</ReviewText>
        <ReviewText>{from.companyName}</ReviewText>
        <ReviewText>{fromAddress.addressLine1}</ReviewText>
        {fromAddress.addressLine2 && <ReviewText>{fromAddress.addressLine2}</ReviewText>}
        {fromAddress.addressLine3 && <ReviewText>{fromAddress.addressLine3}</ReviewText>}
        {fromAddress.city && <ReviewText>{fromAddress.city}</ReviewText>}
        {fromAddress.postalCode && <ReviewText>{fromAddress.postalCode}</ReviewText>}
        {fromAddress.division && <ReviewText>{fromAddress.division}</ReviewText>}
        {fromAddress.suburb && <ReviewText>{fromAddress.suburb}</ReviewText>}
        <ReviewText>{from.country}</ReviewText>

        <br />

        <FormHeader>To:</FormHeader>
        <ReviewText>{to.contactName}</ReviewText>
        <ReviewText>{to.companyName}</ReviewText>
        <ReviewText>{toAddress.addressLine1}</ReviewText>
        {toAddress.addressLine2 && <ReviewText>{toAddress.addressLine2}</ReviewText>}
        {toAddress.addressLine3 && <ReviewText>{toAddress.addressLine3}</ReviewText>}
        {toAddress.city && <ReviewText>{toAddress.city}</ReviewText>}
        {toAddress.postalCode && <ReviewText>{toAddress.postalCode}</ReviewText>}
        {toAddress.division && <ReviewText>{toAddress.division}</ReviewText>}
        <ReviewText>{to.country}</ReviewText>
      </ReviewRow>
    );
  }

  function renderPackages() {
    let title = 'Document Pieces';
    let section: any = null;

    if (draft.isDocumentsShipment) {
      const documents = draft.shipmentDetails.documents;

      let documentsType = documents.isDocumentWeightAboveHalfPound ? 'Non-Express Documents' : 'Express Documents';
      let typeOfPackaging = DOCUMENT_PACKAGING_TYPE_OPTIONS.find(item => item.value === documents.typeOfPackaging);

      // Select custom packaging if packaging type not found.
      if(!typeOfPackaging) {
        typeOfPackaging = DOCUMENT_PACKAGING_TYPE_OPTIONS[1];
      }

      section = (
        <>
          <ReviewText>{documentsType}</ReviewText>
          <ReviewText>Number of Packages: {documents.numberOfPackages}</ReviewText>
          <ReviewText>Type of Packaging: {typeOfPackaging?.label}</ReviewText>
        </>
      );
    } else {
      title = 'Packages';

      const packages = draft.shipmentDetails.products.packages;
      const items = draft.shipmentDetails.products.items;

      let totalSum = draft.invoiceTotalSum;
      let totalSumDisplay = COMMON.EMPTY_FIELD;

      if (totalSum > 0) {
        let totalDisplay = formatHelper.displayPrice(totalSum, draft.currency);
        totalSumDisplay = `Total Goods Value ${totalDisplay}`;
      }

      section = (
        <>
          {!isEmpty(packages) &&
            packages.map((item: Package) => {
              const packageDisplay = formatHelper.displayPieceItem(
                item,
                draft.measurementSystem,
                false,
                draft.isImport
              );

              let declaredValueDisplay = '';
              if (draft.isDomestic) {
                const declaredValue = item.declaredValue ? Number(item.declaredValue).toFixed(2) : COMMON.EMPTY_FIELD;
                declaredValueDisplay = `Declared Value: $${declaredValue}`;
              }

              return (
                <Row key={packageDisplay}>
                  <Col>
                    <ReviewText>{packageDisplay}</ReviewText>
                  </Col>
                  {declaredValueDisplay && (
                    <Col>
                      <ReviewText>{declaredValueDisplay}</ReviewText>
                    </Col>
                  )}
                </Row>
              );
            })}

          {!isEmpty(items) &&
            items.map((item: ProductItem) => {
              const commodityCode = item.commodityCode;

              if (!commodityCode) return null;

              let itemValue = formatHelper.displayPrice(item.itemValue, draft.currency);
              let itemText = `${item.quantity} ${item.itemDescription} (${itemValue} Each)`;

              return (
                <Fragment key={commodityCode}>
                  <ReviewText>{itemText}</ReviewText>
                  {item.eccnValue && <ReviewText>ECCN value: {item.eccnValue}</ReviewText>}
                </Fragment>
              );
            })}

          <ReviewText>{totalSumDisplay}</ReviewText>
        </>
      );
    }

    return (
      <ReviewRow title={title} onEditSection={navigateToDetailsSection}>
        {section}
      </ReviewRow>
    );
  }

  function renderTransportationCharges() {
    const predefinedPaymentFields = FEATURE.PAYMENT_AUTH && draft.isDutiable && paymentTime === PAYMENT_TIME.PAY_NOW;

    if (predefinedPaymentFields) {
      return (
        <ReviewRow title="Transportation Charges" onEditSection={() => navigateTo(DRAFT_STEP.PAYMENT)}>
          <ReviewText>Duties and taxes paid by</ReviewText>
          <ReviewText>Receiver</ReviewText>
        </ReviewRow>
      );
    }

    const freightPayment = payment.freightPayment;
    const dutiesAndTaxesPayment = payment.dutiesAndTaxesPayment;

    let transportationPayment = '';

    if (draft.isShipperAccountNumberRequired) {
      transportationPayment += payment.shipperAccountNumber;
    } else {
      transportationPayment += freightPayment;
    }
    if (!draft.isDutiable) transportationPayment = freightPayment;

    let taxesPayment = '';

    if (draft.isDutyAccountNumberRequired) {
      taxesPayment += payment.dutyAccountNumber;
    } else {
      taxesPayment += dutiesAndTaxesPayment;
    }

    return (
      <ReviewRow title="Transportation Charges" onEditSection={() => navigateTo(DRAFT_STEP.PAYMENT)}>
        {freightPayment && (
          <>
            <ReviewText>Transportation charges paid by</ReviewText>
            <ReviewText>{transportationPayment}</ReviewText>
          </>
        )}

        {draft.isAssociatedZipCodeRequired && (
          <>
            <ReviewText>Associated Zip Code</ReviewText>
            <ReviewText>{payment.billingAccountNumberPostalCode}</ReviewText>
          </>
        )}

        {dutiesAndTaxesPayment && (
          <>
            <ReviewText>Duties and taxes paid by</ReviewText>
            <ReviewText>{taxesPayment}</ReviewText>
          </>
        )}
      </ReviewRow>
    );
  }

  function renderServices() {
    let carrierProduct = pickupDelivery.carrierProduct;

    let carrierProductDisplay = carrierServices.find(service => service.id === carrierProduct)?.name || '';

    return (
      <ReviewRow
        title="Services"
        onEditSection={() => navigateTo(DRAFT_STEP.ADDITIONAL_SERVICES, SECTION.DRAFT.SERVICES)}>
        <ReviewText>{carrierProductDisplay}</ReviewText>
      </ReviewRow>
    );
  }

  function renderAdditionalBlock() {
    const products = draft.shipmentDetails.products;

    let eeiFillingSection: any = null;

    if (draft.isExport) {
      let eeiFillingText = '';

      if (products.EINNumber) {
        eeiFillingText = `by EIN: ${products.EINNumber}`;
      } else if (products.ITNNumber) {
        eeiFillingText = `by ITN: ${products.ITNNumber}`;
      } else {
        let exemption = '';

        if (products.electronicExportFiling === ELECTRONIC_EXPORT_FILING.EXEMPTED) {
          exemption = ELECTRONIC_EXPORT_FILING.DEFAULT_EXEMPTION;
        }
        if (products.electronicExportFiling === ELECTRONIC_EXPORT_FILING.OTHER) {
          exemption = products.foreignTradeRegulations;
        }

        eeiFillingText = `Exempted from filing EEI under ${exemption}`;
      }

      eeiFillingSection = <ReviewText>Filling EEI {eeiFillingText}</ReviewText>;
    }

    let certificateOfOrigin = draft.additionalServices.isCertificateOfOriginRequired
      ? 'Certificate of Origin will be added'
      : 'No Certificate of Origin will be added';

    let customsInvoice = draft.isProformaInvoiceType
      ? 'Proforma Invoice for customs will be generated'
      : 'Commercial Invoice for customs will be generated';

    return (
      <ReviewRow title="Shipment Documents" onEditSection={navigateToDetailsSection}>
        <ReviewText>Shipping label will be generated</ReviewText>
        <ReviewText>{certificateOfOrigin}</ReviewText>
        {products.invoiceType && <ReviewText>{customsInvoice}</ReviewText>}
        {eeiFillingSection}
      </ReviewRow>
    );
  }

  function renderFee(fee: Fee) {
    const feeFormatted = formatHelper.displayPrice(fee.amount.amount, draft.currency);
    return (
      <ReviewText key={fee.id}>{fee.description}: {feeFormatted}</ReviewText>
    );
  }

  function renderShipment() {
    let shipmentDate = pickupDelivery.pickup ? pickupDelivery.pickup.date : pickupDelivery.shipmentDate;
    let shipmentDateDisplay = dateHelper.displayDate(shipmentDate);

    let shippingCharge = rate?.optimalRate?.amount ? rate.displayOptimalRate : null;
    let totalCharge = rate?.retailRate?.amount ? rate.displayRetailRate : null;

    let shippingChargeFormatted = formatHelper.displayPrice(shippingCharge, draft.currency);
    let totalChargeFormatted = formatHelper.displayPrice(totalCharge, draft.currency);

    let estimatedDelivery = dateHelper.displayDate(rate?.estimatedDelivery);

    return (
      <ReviewRow
        title="Shipment"
        onEditSection={() => navigateTo(DRAFT_STEP.ADDITIONAL_SERVICES, SECTION.DRAFT.PICKUP)}>
        <ReviewText>Shipment date: {shipmentDateDisplay}</ReviewText>
        <ReviewText>Estimated Delivery Date: {estimatedDelivery}</ReviewText>
        <br />
        <ReviewText>Shipping charge: {shippingChargeFormatted}</ReviewText>
        {rate?.fees.map((fee: Fee) => {
            return renderFee(fee);
        })}
        <ReviewText>Total estimated charges: {totalChargeFormatted}</ReviewText>
      </ReviewRow>
    );
  }

  function render() {
    return (
      <Container>
        {renderFromToDetails()}
        {renderPackages()}
        {renderTransportationCharges()}
        {renderServices()}
        {!draft.isDomestic && renderAdditionalBlock()}
        {renderShipment()}

        <ReviewPickup data={pickupDelivery} navigate={navigateTo} />
      </Container>
    );
  }

  return render();
}

export default ReviewFinish;

import React from 'react';
import styled, {css} from 'styled-components/macro';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';

import authService from 'services/authService';

import Button from 'components/common/Button';
import Toggler from 'components/common/Toggler';
import Flex from 'components/common/Flex';
import StepperComponent from './components/StepperComponent';

import {colors, mainFont, leftPanel, navBarHeight, houseAccountMessageHeight} from 'styles/shared';

const StyledContainer = styled.div`
  ${leftPanel};
  padding: 2.7rem 0 0 3.2rem;

  ${props =>
    props.houseAccountMessageVisible &&
    css`
      top: calc(${navBarHeight} + ${houseAccountMessageHeight});
    `}
`;

const StyledDraftMessage = styled.div`
  ${mainFont};
  letter-spacing: 0.04rem;
  color: ${colors.blue_main};
  margin: 5.5rem 0 2rem -0.5rem;
  width: 20.3rem;
  text-align: center;
`;

const StyledTogglerContainer = styled(Flex)`
  margin-bottom: 3rem;
`;

const StyledLabel = styled.div`
  font-size: 1.4rem;
  line-height: 1.9rem;
  letter-spacing: 0.04rem;
  color: ${colors.blue_main};
`;

interface Props {
  isCSR: boolean;
}

function LeftPanel({isCSR}: Props) {
  const dispatch = useDispatch();

  const currentStep = useSelector((state: AppState) => state.draft.current.step);
  const steps = useSelector((state: AppState) => state.draft.current.steps);
  const isHouseAccountUsed = useSelector((state: AppState) => state.common.isHouseAccountUsed);

  function onSaveDraft() {
    dispatch(commonActions.toggleSaveDraftModal(true));
  }

  function toggleHouseAccount(field, value) {
    authService.toggleHouseAccount(value);
    dispatch(commonActions.toggleHouseAccountUsage(value));
  }

  function render() {
    if (isEmpty(steps)) return null;

    let currentStepObj = steps.find(item => item.step === currentStep);

    let stepNumber = currentStepObj?.stepNumber || 0;

    let houseAccountMessageVisible = isCSR && isHouseAccountUsed;

    return (
      <StyledContainer houseAccountMessageVisible={houseAccountMessageVisible}>
        {isCSR && (
          <StyledTogglerContainer justify="space-around">
            <StyledLabel>Use House Account?</StyledLabel>
            <Toggler name="houseAccount" value={isHouseAccountUsed} onToggle={toggleHouseAccount} />
          </StyledTogglerContainer>
        )}

        {steps.map(item => {
          return <StepperComponent key={item.stepNumber} item={item} currentStepNumber={stepNumber} />;
        })}

        <StyledDraftMessage>
          Would you like to finish Shipment later? You can save Draft and finish it anytime you want!
        </StyledDraftMessage>

        <Button autoWidth onClick={onSaveDraft}>
          Save Draft & Quit
        </Button>
      </StyledContainer>
    );
  }

  return render();
}

export default LeftPanel;

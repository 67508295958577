import {LOAD_QUOTES, CHECK_RATE, CHANGE_QUOTES_PAGE, CLEAR_QUOTE_STATE} from 'action_types/quoteActionTypes';

import initialState from './initialState';
import helper from './reducerHelper';

export interface QuoteState {
  list: Quote[];
  pagination: Pagination;
  currentRates: Rate[];
  error?: string;
}

const quoteReducer = (state: QuoteState = initialState.quote, action) => {
  return helper.handleActions<QuoteState>(state, action, {
    [LOAD_QUOTES](state, payload: {quotes: Quote[]; totalItems: number}) {
      state.list = payload.quotes;
      state.pagination.totalCount = payload.totalItems;
    },
    [CHANGE_QUOTES_PAGE](state, payload: {activePage: number}) {
      state.pagination.activePage = payload.activePage;
    },
    [CHECK_RATE](state, payload: {rates: Rate[]; errorMessage: string}) {
      state.error = payload.errorMessage;
      state.currentRates = payload.rates;
    },
    [CLEAR_QUOTE_STATE](state) {
      state.error = undefined;
      state.currentRates = [];
    }
  });
};

export default quoteReducer;

import React from 'react';
import styled from 'styled-components/macro';

import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import {mainFont, buttonLeftRightMargin, buttonLeftMargin} from 'styles/shared';
import * as Styled from 'styles/modal';

const StyledModalTitle = styled.div`
  font-weight: bold;
  margin-bottom: 1.9rem;
  ${mainFont};
`;

const StyledModalFooter = styled(Flex)`
  margin-top: 3rem;
`;

interface Props {
  title?: string;
  text?: string;
  visible?: boolean;
  action: () => void;
  close: () => void;
}

function Confirm({title, text, visible, action, close}: Props) {
  let displayTitle = title ? title : 'Confirmation';
  let displayMessage = text ? text : 'Are you sure?';

  return (
    <Styled.CustomModal show={visible} backdrop="static" width="45.8rem" padding="4rem 4rem 2rem" onHide={close}>
      <StyledModalTitle>{displayTitle}</StyledModalTitle>
      <h4>{displayMessage}</h4>

      <StyledModalFooter justify="flex-end">
        <Button type="secondary" autoWidth margin={buttonLeftRightMargin} onClick={close}>
          No
        </Button>
        <Button type="primary" autoWidth margin={buttonLeftMargin} onClick={action}>
          Yes
        </Button>
      </StyledModalFooter>
    </Styled.CustomModal>
  );
}

export default Confirm;

import {LOAD_ORDERS} from 'action_types/orderActionTypes';

import draftActions from 'actions/draftActions';

import helper from './actionHelper';
import apis from 'domain/apis';

import authService from 'services/authService';

export default {
  loadOrders,
  getPreparedRequestForOrder
};

function loadOrders() {
  return helper.dispatchAsyncAction(async dispatch => {
    let orders = await apis.order.getOrders();

    dispatch(helper.getAction(LOAD_ORDERS, {orders}));
  });
}

function getPreparedRequestForOrder(orderId) {
  return helper.dispatchAsyncAction(async dispatch => {
    let response: any = await apis.order.createPreparedRequestForOrder(orderId);

    if (response) {
      dispatch(draftActions.createDraftFromOrder(response));

      await authService.logCsrAsDraftOwner(response?.ownedById);

      return true;
    }

    return false;
  });
}

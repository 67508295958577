import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';
import draftActions from 'actions/draftActions';

import FEATURE from 'constants/features';

import Container from 'components/common/Container';
import RadioInput from 'components/common/RadioInput/RadioInput';
import FormHeader from 'components/common/FormHeader';

const StyledAdditionalHeader = styled(FormHeader)`
  margin-bottom: 3rem;
`;

function AdditionalServices() {
  const dispatch = useDispatch();

  const draft = useSelector((state: AppState) => state.draft.current.data as Draft);

  const ndsOptions: RadioInputOption[] = [
    {
      value: true,
      label: (
        <span>
          Yes (<strong>+$20</strong> surcharge)
        </span>
      )
    },
    {value: false, label: 'No'}
  ];

  function onChange(field, value) {
    dispatch(draftActions.modifyDraft('additionalServices', field, value));
  }

  return (
    <Container>
      <StyledAdditionalHeader>Additional Services</StyledAdditionalHeader>

      {draft.isDutiable && (
        <>
          <RadioInput
            name="isNeutralDeliveryServiceRequired"
            label="Would you like Neutral Delivery Service (NDS)?"
            value={draft.additionalServices.isNeutralDeliveryServiceRequired}
            options={ndsOptions}
            onChange={onChange}
          />

          <RadioInput
            name="isCertificateOfOriginRequired"
            label="Would you like Certificate of Origin?"
            value={draft.additionalServices.isCertificateOfOriginRequired}
            onChange={onChange}
          />
        </>
      )}

      {FEATURE.RETURN_LABEL && (
        <RadioInput
          name="isReturnLabel"
          label="Would you like Return Label?"
          value={draft.additionalServices.isReturnLabel}
          options={[
            {value: true, label: 'Yes, return it to the Shipper’s address '},
            {value: false, label: 'No'}
          ]}
          onChange={onChange}
        />
      )}
    </Container>
  );
}

export default AdditionalServices;

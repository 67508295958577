import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';

import FormLabel from 'components/common/FormLabel';
import Button from 'components/common/Button';
import Flex from 'components/common/Flex';
import ActionLink from 'components/common/ActionLink';
import CheckBoxInput from 'components/common/CheckboxInput';

import * as styled from './FullAccessModule.styled';

interface LocalUser {
  email: string;
  password: string;
}

interface Props {
  errors: any;
  signInAction: (user: LocalUser, rememberMe: boolean) => void;
}

function FullAccessModule({errors, signInAction}: Props) {
  const dispatch = useDispatch();

  const [localUser, setLocalUser] = useState<LocalUser>({email: '', password: ''});
  const [rememberMe, setRememberMe] = useState(true);

  useEffect(() => {
    window.addEventListener('keydown', handleSubmit);

    return () => window.removeEventListener('keydown', handleSubmit);
  });

  function onChange(field: string, value) {
    setLocalUser({...localUser, [field]: value});
  }

  function handleSubmit(e) {
    if (e?.code === 'Enter') {
      signIn(null);
    }
  }

  function signIn(e) {
    if (e) e.preventDefault();

    signInAction(localUser, rememberMe);
  }

  function forgotPasswordAction() {
    dispatch(commonActions.toggleResetPasswordModal(true));
  }

  return (
    <>
      <Flex justify="space-between" align="flex-start">
        <FormLabel>Quotes, Shipment Preparation and Shipment History</FormLabel>
      </Flex>

        <styled.textInput
          name="email"
          type="email"
          value={localUser.email}
          onChange={onChange}
          placeholder="Enter your Import/Export No., Email, or Client ID"
          error={errors.email}
        />
        <styled.textInput
          name="password"
          type="password"
          value={localUser.password}
          onChange={onChange}
          placeholder="Password"
          error={errors.password}
        />

      <styled.checkboxContainer justify="space-between" align="center">
        <CheckBoxInput
          name="rememberMe"
          label="Remember me"
          value={rememberMe}
          onToggle={(field, value) => setRememberMe(value)}
        />

        <ActionLink title="Forgot Password?" onClick={forgotPasswordAction} />
      </styled.checkboxContainer>

      <Flex justify="center">
        <Button autoWidth disabled={!localUser.email || !localUser.password} onClick={signIn}>
          Sign In
        </Button>
      </Flex>
    </>
  );
}

export default FullAccessModule;

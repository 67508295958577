import React from 'react';
import styled from 'styled-components/macro';

import Button from 'components/common/Button';

import {colors, mainFont, opacity} from 'styles/shared';

const StyledRow = styled.div`
  display: flex;
  margin: 3rem 0 3rem;
  padding-left: 1.6rem;
`;

const StyledCustomButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 1rem;
  width: auto;
  height: 4.6rem;
  background: ${colors.white};
  box-shadow: 0 0.2rem 0.6rem ${opacity(0.14)};
  border-radius: 0.6rem;
  margin-right: 1rem;
  ${mainFont};
  color: ${colors.orange_main};
`;

interface Props {
  onAddNewOrder: () => void;
  action: () => void;
  disabled: boolean;
}

function ActionsPanel({onAddNewOrder, action, disabled}: Props) {
  function render() {
    return (
      <StyledRow>
        <Button autoWidth disabled={disabled} margin="0 2.7rem 0 0" onClick={action}>
          Ship Selected
        </Button>

        <StyledCustomButton onClick={onAddNewOrder}>new order</StyledCustomButton>
      </StyledRow>
    );
  }

  return render();
}

export default ActionsPanel;

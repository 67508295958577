import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';
import {Row, Col} from 'components/bootstrap';

import AppIcon from 'components/common/AppIcon';

import * as Styled from 'styles/modal';
import {DocumentToPrint} from '../DocumentsSummary';

const StyledHeader = styled(Row)`
  height: 8rem;
  display: flex;
  align-items: center;
`;

const StyledTitle = styled(Col)`
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'space-between')};
  align-items: center;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 3.3rem;
  letter-spacing: 0.03rem;
`;

const StyledCloseContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const StyledIconContainer = styled.div`
  cursor: pointer;
  margin: 0 2rem;
`;

interface Props {
  visible: boolean;
  close: () => void;
  documents: DocumentToPrint[];
}

function PrintModal({visible, close, documents}: Props) {
  const [selectedDocument, setSelectedDocument] = useState<DocumentToPrint | null>(null);

  useEffect(() => {
    setSelectedDocument(documents[0]);
  }, []);

  function selectPreviousDocument() {
    const index = documents.findIndex((document: DocumentToPrint) => document.name === selectedDocument?.name);

    if (index === -1) return;

    if (index === 0) {
      setSelectedDocument(documents[documents.length - 1]);
    } else {
      setSelectedDocument(documents[index - 1]);
    }
  }

  function selectNextDocument() {
    const index = documents.findIndex((document: DocumentToPrint) => document.name === selectedDocument?.name);

    if (index === -1) return;

    if (index === documents.length - 1) {
      setSelectedDocument(documents[0]);
    } else {
      setSelectedDocument(documents[index + 1]);
    }
  }

  function render() {
    if (!selectedDocument) return null;

    const onlyOneDocument = documents.length === 1;

    return (
      <Styled.CustomModal show={visible} backdrop="static" width="70%" height="100%" padding="0" onHide={close}>
        <StyledHeader>
          <StyledTitle center={onlyOneDocument ? 'center' : undefined} xs={9} md={10} lg={{span: 6, offset: 3}}>
            {!onlyOneDocument && (
              <StyledIconContainer onClick={selectPreviousDocument}>
                <AppIcon name="chevron-left" size="lg" />
              </StyledIconContainer>
            )}

            {selectedDocument?.name}

            {!onlyOneDocument && (
              <StyledIconContainer onClick={selectNextDocument}>
                <AppIcon name="chevron-right" size="lg" />
              </StyledIconContainer>
            )}
          </StyledTitle>

          <StyledCloseContainer xs={3} md={2} lg={3}>
            <StyledIconContainer onClick={close}>
              <AppIcon name="close" />
            </StyledIconContainer>
          </StyledCloseContainer>
        </StyledHeader>

        <embed src={selectedDocument?.src} key={selectedDocument?.name} width="100%" height="100%" />
      </Styled.CustomModal>
    );
  }

  return render();
}

export default PrintModal;

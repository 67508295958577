import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';
import userActions from 'actions/userActions';

import MESSAGE from 'constants/literals/messages';

import validationHelper from 'helpers/validationHelper';
import uiHelper from 'helpers/uiHelper';

import FormHeader from 'components/common/FormHeader';
import TextInput from 'components/common/TextInput';
import Flex from 'components/common/Flex';
import Button from 'components/common/Button';

import * as Styled from 'styles/modal';
import {buttonLeftRightMargin, colors, mediaQueries, authFont} from 'styles/shared';

const authPrimaryButtonWidth = 19.2;

const StyledTitle = styled(FormHeader)`
  margin-bottom: 2.4rem;
`;

const StyledText = styled.div`
  ${authFont};
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  color: ${colors.darkBlue_main};
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 0;
`;

const StyledButtons = styled(Flex)`
  margin-top: 3.6rem;

  > button {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media ${mediaQueries.mobileMax} {
    > button {
      width: auto;
      padding: 0 1.6rem;
    }
  }
`;

interface Props {
  visible: boolean;
  close: () => void;
}

function SetPasswordModal({visible, close}: Props) {
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({password: '', confirmPassword: ''});

  function formIsValid() {
    let newErrors = {
      password: '',
      confirmPassword: ''
    };

    if (!password) {
      newErrors.password = 'Password field is required.';
    } else if (password.length < 8) {
      newErrors.password = 'This value is too short. It should have 8 characters or more.';
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = 'Confirm password field is required.';
    }

    if (password && confirmPassword && password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match.';
    }

    setErrors(newErrors);

    return validationHelper.isEmptyErrorObject(newErrors);
  }

  async function createNewPassword(e) {
    if (e) e.preventDefault();

    if (!formIsValid()) return;

    const response: any = await dispatch(userActions.changePassword(password));
    const result = response as ResetPasswordResponse;

    if (result?.success) {
      closeAction();
      uiHelper.showNotice(MESSAGE.SET_PASSWORD);
      return;
    }

    dispatch(
      commonActions.infoAction({
        type: 'error',
        text: result?.message || 'Could not reset password. Please try to reset your password again.',
        close: () => close()
      })
    );
  }

  function closeAction() {
    close();
  }

  function render() {
    const disabled = !password && !confirmPassword;

    return (
      <Styled.CustomModal show={visible} backdrop="static" padding="2.6rem 2.4rem 2rem" onHide={closeAction}>
        <StyledTitle>Set Password</StyledTitle>

        <StyledText>Please set your password. Passwords should be a minimum of 8 characters.</StyledText>

        <StyledTextInput
          name="password"
          label="Password"
          type="password"
          value={password}
          placeholder="********"
          onChange={(field, value) => setPassword(value)}
          error={errors.password}
        />

        <StyledTextInput
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          value={confirmPassword}
          onChange={(field, value) => setConfirmPassword(value)}
          placeholder="********"
          error={errors.confirmPassword}
        />

        <StyledButtons justify="center">
          <Button type="secondary" width={authPrimaryButtonWidth} margin={buttonLeftRightMargin} onClick={closeAction}>
            Cancel
          </Button>
          <Button
            width={authPrimaryButtonWidth}
            margin={buttonLeftRightMargin}
            disabled={disabled}
            onClick={createNewPassword}>
            Set Password
          </Button>
        </StyledButtons>
      </Styled.CustomModal>
    );
  }

  return render();
}

export default SetPasswordModal;

import React from 'react';
import styled from 'styled-components/macro';

import FormLabel from 'components/common/FormLabel';
import Flex from 'components/common/Flex';
import Button from 'components/common/Button';

import * as StyledModal from 'styles/modal';
import {mainFont} from 'styles/shared';

const StyledHeader = styled(FormLabel)`
  margin-bottom: 1.9rem;
  text-align: center;
`;

const StyledText = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  ${mainFont};
`;

interface Props {
  visible: boolean;
  close: () => void;
  cancelOnlyShipment: () => void;
  cancelShipmentAndPickup: () => void;
  goBack: () => void;
}

function CancelShipmentModal({visible, close, cancelOnlyShipment, cancelShipmentAndPickup, goBack}: Props) {
  return (
    <StyledModal.CustomModal show={visible} width="43.3rem" padding="2.6rem" onHide={close}>
      <Flex align="center" direction="column">
        <StyledHeader>The Shipment you want to cancel has associated Pickup!</StyledHeader>

        <StyledText>Would you like to:</StyledText>

        <Button autoWidth margin="0 0 4rem" onClick={cancelShipmentAndPickup}>
          Cancel This Shipment & Pickup
        </Button>
        <Button autoWidth margin="0 0 4rem" onClick={cancelOnlyShipment}>
          Cancel This Shipment Only
        </Button>
        <Button type="secondary" autoWidth onClick={goBack}>
          Go Back
        </Button>
      </Flex>
    </StyledModal.CustomModal>
  );
}

export default CancelShipmentModal;

import React from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';
import {useSelector} from 'react-redux';
import {get} from 'lodash';

import {AppState} from 'reducers';

import TextInput from 'components/common/TextInput';
import PhysicalAddress from 'components/common/PhysicalAddress';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import SelectInput from 'components/common/SelectInput/SelectInput';
import Button from 'components/common/Button';
import Togglers from 'components/prepare_shipment/components/sending/components/Togglers';
import Flex from 'components/common/Flex';

import {buttonLeftMargin, buttonLeftRightMargin} from 'styles/shared';

const StyledContainer = styled.div`
  padding: 0 2.6rem;
`;

const StyledPhoneInput = styled(PhoneNumberInput)`
  margin-top: 0;
`;

const StyledExtension = styled(TextInput)`
  margin-top: 0;
`;

interface Props {
  contact: Sending;
  errors: object;
  close: () => void;
  onChange: (field: string, value: any) => void;
  onSave: () => void;
}

function SaveContact({contact, errors, close, onChange, onSave}: Props) {
  const countryList = useSelector((state: AppState) => state.shipmentOptions.countryList);

  function onAddressChange(field, value) {
    onChange('address', {...contact.address, [field]: value});
  }

  function onAddressSelect(address) {
    let newAddress = {...contact.address, ...address};

    onChange('address', newAddress);
  }

  return (
    <StyledContainer>
      <Row>
        <Col lg={6}>
          <SelectInput
            name="country"
            label="Select Country"
            required={true}
            options={countryList}
            value={contact.country}
            onChange={onChange}
            error={get(errors, 'country')}
          />

          <TextInput
            name="companyName"
            label="Company Name"
            placeholder="ABC Inc."
            required={true}
            value={contact.companyName}
            onChange={onChange}
            error={get(errors, 'companyName')}
          />

          <TextInput
            name="contactName"
            label="Contact Name"
            placeholder="John Doe"
            required={true}
            value={contact.contactName}
            onChange={onChange}
            error={get(errors, 'contactName')}
          />

          <PhysicalAddress
            autocompleteId="contact-address-autocomplete"
            label="Address"
            address={contact.address}
            countrySelected={contact.country}
            oneColumnDisplay={false}
            errors={errors}
            onChange={onAddressChange}
            onAddressSelect={onAddressSelect}
          />
        </Col>

        <Col lg={6}>
          <TextInput
            name="email"
            type="email"
            label="Email Address"
            required={true}
            placeholder="john.doe@gmail.com"
            value={contact.email}
            onChange={onChange}
            error={get(errors, 'email')}
          />

          <Row>
            <Col lg={6}>
              <StyledPhoneInput
                name="phoneNumber"
                label="Phone Number"
                required={true}
                countryCode={contact.country}
                value={contact.phoneNumber}
                onChange={onChange}
                error={get(errors, 'phoneNumber')}
              />
            </Col>
            <Col lg={6}>
              <StyledExtension
                name="phoneNumberExtension"
                label="Extension"
                placeholder="000"
                value={contact.phoneNumberExtension}
                onChange={onChange}
                error={get(errors, 'phoneNumberExtension')}
              />
            </Col>
          </Row>

          <Togglers sending={contact} isDomestic={false} isShipper={true} onChange={onChange} errors={errors} />

          <Flex justify="flex-end">
            <Button autoWidth margin={buttonLeftRightMargin} onClick={onSave}>
              Save Contact
            </Button>
            <Button type="secondary" autoWidth margin={buttonLeftMargin} onClick={close}>
              Cancel
            </Button>
          </Flex>
        </Col>
      </Row>
    </StyledContainer>
  );
}

export default SaveContact;

import React from 'react';

function ExportCodeTooltip() {
  return (
    <span>
      <strong>EXPORT CODE</strong>: This code identifies the type of export <br /> shipment or conditions of the
      exported items (i.e. goods <br /> donated for relief or charity, impelled shipments, <br /> shipments under the
      <strong>Foreign Military Sales program</strong>,<br /> household goods, all other shipments). The overwhelming{' '}
      <br />
      majority of exports use the code: <strong>OS – All Other Exports</strong>.
    </span>
  );
}

export default ExportCodeTooltip;

import React from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import AppIcon, {IconName} from 'components/common/AppIcon/AppIcon';

import {secondaryFont, mediaQueries} from 'styles/shared';

const PADDING = '2.6rem';

const StyledContainer = styled(Container)`
  padding-left: ${PADDING};

  @media ${mediaQueries.mobileMax} {
    padding: ${PADDING};
  }
`;

const StyledHeader = styled(FormHeader)`
  min-height: 4.3rem;

  @media ${mediaQueries.mobileMax} {
    min-height: auto;
    margin-bottom: 1rem;
  }
`;

const StyledText = styled.div`
  ${secondaryFont};
`;

const StyledColumn = styled(Col)`
  padding-bottom: 1.9rem;

  @media ${mediaQueries.tabletMin} {
    padding-top: ${PADDING};
  }
`;

const StyledImageColumn = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  title: string;
  subtitle: string;
  description: string | JSX.Element;
  icon?: IconName;
  image?: string;
}

function StandaloneHeader({title, subtitle, description, icon, image}: Props) {
  return (
    <StyledContainer withoutPadding>
      <Row>
        <StyledColumn lg={4}>
          <StyledHeader>{title}</StyledHeader>

          <StyledText>{subtitle}</StyledText>
        </StyledColumn>

        <StyledColumn lg={4}>
          <StyledHeader></StyledHeader>
          <StyledText>{description}</StyledText>
        </StyledColumn>

        <StyledImageColumn lg={4}>
          {icon && <AppIcon name={icon} width={130} height={120} />}

          {image && <img alt={title} src={`/images/${image}`} height={120} />}
        </StyledImageColumn>
      </Row>
    </StyledContainer>
  );
}

export default StandaloneHeader;

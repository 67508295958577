const CARRIER = {
  DHL: 'DHL',
  UPS: 'UPS',
  FED_EX: 'FEDEX',
  CUSTOMER_ROUTED: 'customer_routed',
  UPS_GROUND: '03',
  FED_EX_GROUND_PICKUP: 'FEDEX_GROUND',
  FED_EX_EXPRESS_PICKUP: 'FEDEX_EXPRESS_SAVER',
  FED_EX_HOME_DELIVERY: 'GROUND_HOME_DELIVERY'
};

export default CARRIER;

import styled from 'styled-components/macro';

import FormHeader from 'components/common/FormHeader';
import TextInput from 'components/common/TextInput';
import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';

import {mediaQueries, colors, mainBorder, helpWidgetHeight} from 'styles/shared';

export const wrapper = styled.div`
  text-align: center;
`;

export const container = styled.div`
  width: 54.6rem;
  margin-top: 2rem;
  background: ${colors.white};
  border: ${mainBorder};
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: 3.2rem 2.6rem 4rem;

  @media ${mediaQueries.mobileMax} {
    width: auto;
    margin-bottom: ${helpWidgetHeight};
  }
`;

export const title = styled(FormHeader)`
  margin-bottom: 3rem;
`;

export const textInput = styled(TextInput)`
  margin: 0rem;
  min-height: 6.7rem;

  & .validation-error {
    white-space: nowrap;
  }
`;

export const checkboxContainer = styled(Flex)`
  margin-bottom: 3rem;
`;

export const divider = styled(Divider)`
  margin-bottom: 2.9rem;
`;

export const buttonsContainer = styled(Flex)`
  @media ${mediaQueries.mobileMax} {
    flex-wrap: wrap;

    > button:first-child {
      margin-bottom: 2rem;
    }
  }
`;

import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {AppState} from 'reducers';
import paymentActions from 'actions/paymentActions';

import COUNTRY_CODE from 'constants/countryCodes/countryCodes';

import PhysicalAddress from 'components/common/PhysicalAddress';

function BillingAddressSection() {
  const dispatch = useDispatch();

  const billingAddress = useSelector((state: AppState) => state.payment.billingAddress);
  const errors = useSelector((state: AppState) => state.payment.errors);

  function onChange(field, value) {
    dispatch(paymentActions.modifyBillingAddress(field, value));
  }

  function onAddressSelect(address) {
    dispatch(paymentActions.selectBillingAddress(address));
  }

  return (
    <PhysicalAddress
      autocompleteId="billing-address-autocomplete"
      label="Billing Address"
      countrySelected={COUNTRY_CODE.USA}
      address={billingAddress}
      oneColumnDisplay={false}
      onChange={onChange}
      onAddressSelect={onAddressSelect}
      errors={errors}
    />
  );
}

export default BillingAddressSection;

import Bowser from 'bowser';

export default {
  isChromeBrowser,
  getBrowser,
  isMobile
};

function isChromeBrowser() {
  const supportedBrowsers = {
    chrome: '>48',
    chromium: '>50'
  };

  const browser = Bowser.getParser(window.navigator.userAgent);

  const isChrome = browser.satisfies(supportedBrowsers);

  return isChrome;
}

function getBrowser() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getBrowser();
}

function isMobile(): boolean {
  const parsedResult = Bowser.parse(window.navigator.userAgent);

  const platform = parsedResult?.platform?.type;

  return platform === 'mobile';
}

import React from 'react';
import styled from 'styled-components/macro';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';

import config from 'config';
import mailHelper from 'helpers/mailHelper';

import FormLabel from 'components/common/FormLabel';
import ActionLink from 'components/common/ActionLink';
import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import * as Styled from 'styles/modal';
import {mainFont} from 'styles/shared';

const StyledText = styled.div`
  ${mainFont};
  margin-bottom: 1.5rem;
`;

const StyledLinkRow = styled.div`
  ${mainFont};
  display: flex;
  margin-top: 1rem;
`;

const StyledButtonContainer = styled(Flex)`
  margin-top: 3rem;
`;

interface Props {
  visible: boolean;
  close: () => void;
}

function PickupOutsideUsaModal({visible, close}: Props) {
  const dispatch = useDispatch();

  function callAction() {
    window.open(`tel:${config.supportPhone.value}`);
  }

  function sendEmail() {
    const supportLink = mailHelper.getHelpEmailLink();

    window.location.href = supportLink;

    close();
  }

  function startChat() {
    dispatch(commonActions.toggleIntercomChat());
    close();
  }

  return (
    <Styled.CustomModal show={visible} backdrop="static" width="58rem" padding="4rem 2.6rem 3rem" onHide={close}>
      <FormLabel>Scheduling a pickup outside of the US</FormLabel>

      <StyledText>If you need a pickup scheduled outside of the US, please contact our customer service:</StyledText>

      <StyledLinkRow>
        Chat: &nbsp; <ActionLink title="Start chat" onClick={startChat} />
      </StyledLinkRow>
      <StyledLinkRow>
        Phone: &nbsp; <ActionLink title={config.supportPhone.label} onClick={callAction} />
      </StyledLinkRow>
      <StyledLinkRow>
        Email: &nbsp; <ActionLink title={config.supportUrl} onClick={sendEmail} />
      </StyledLinkRow>

      <StyledButtonContainer justify="flex-end">
        <Button autoWidth onClick={close}>
          Close
        </Button>
      </StyledButtonContainer>
    </Styled.CustomModal>
  );
}

export default PickupOutsideUsaModal;

import React from 'react';
import styled from 'styled-components/macro';

import {colors, secondaryFont} from 'styles/shared';

const StyledContainer = styled.div`
  background-color: ${colors.darkBlue_main};
  border-radius: 0.6rem;
  padding: 1.6rem;
  margin-bottom: 2rem;
  display: flex;
`;

const StyledCalculations = styled.div`
  flex: 1;
  font-weight: bold;
  ${secondaryFont};
`;

const StyledCalculation = styled.div`
  color: ${colors.white};
  margin-bottom: 0.8rem;
`;

const StyledTotal = styled.div`
  color: ${colors.orange_main};
`;

const StyledSuggestions = styled.div`
  flex: 1;
  align-self: flex-end;
  font-weight: bold;
  color: ${colors.orange_main};
  ${secondaryFont};
`;

interface Props {
  items: ProductItem[];
  totalValue: number;
  currency: string;
}

function DeclaredValueCalculation({items, totalValue, currency}: Props) {
  const displayTotal = `${Number(totalValue).toFixed(2)} ${currency}`;

  return (
    <StyledContainer>
      <StyledCalculations>
        <StyledCalculation>Export Line Items {items.length}</StyledCalculation>

        {items.map((item: ProductItem, index) => {
          const displayAmount = Number(item.itemValue).toFixed(2);

          return (
            <StyledCalculation key={index}>
              {item.quantity} Items X {displayAmount} {currency}
            </StyledCalculation>
          );
        })}

        <StyledTotal>Total Value: {displayTotal}</StyledTotal>
      </StyledCalculations>

      <StyledSuggestions>
        Suggested Declared Value:
        <br />
        {displayTotal}
      </StyledSuggestions>
    </StyledContainer>
  );
}

export default DeclaredValueCalculation;

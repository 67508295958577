import React from 'react';
import styled from 'styled-components/macro';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';

import config from 'config';
import mailHelper from 'helpers/mailHelper';

import ActionLink from 'components/common/ActionLink';

import {mainFont} from 'styles/shared';

const StyledContent = styled.div`
  ${mainFont};
  margin-top: 1.6rem;
`;

interface Props {
  close: () => void;
}

function NewContacts({close}: Props) {
  const dispatch = useDispatch();

  function callAction() {
    window.open(`tel:${config.supportPhone.value}`);
  }

  function sendEmail() {
    const supportLink = mailHelper.getHelpEmailLink();

    window.location.href = supportLink;
  }

  function startChat() {
    dispatch(commonActions.toggleIntercomChat());
    close();
  }

  return (
    <StyledContent>
      You can contact Customer Service to get help immediately. <br /> Call us now:{' '}
      <ActionLink title={config.supportPhone.label} onClick={callAction} /> <br />
      Live chat with a person: <ActionLink title="Start Chat" onClick={startChat} /> <br />
      <br />
      Additionally, you can send us an email and we will get back to you shortly. <br />
      Send us an email: <ActionLink title={config.supportUrl} onClick={sendEmail} />
    </StyledContent>
  );
}

export default NewContacts;

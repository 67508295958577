import React from 'react';
import styled from 'styled-components/macro';
import {Place} from 'react-tooltip';

import Tooltip from 'components/common/Tooltip';

import {colors} from 'styles/shared';

const StyledQuestion = styled.span`
  position: relative;
  display: inline-block;
  margin-left: 0.7rem;
  width: 2.6rem;
  height: 2.6rem;
  cursor: pointer;

  &:before {
    content: '${props => '\\003f'}';
    position: absolute;
    top: -0.3rem;
    right: 0;
    width: 2.6rem;
    height: 2.6rem;
    text-align: center;
    line-height: 2.8rem;
    color: ${colors.white};
    background-color: ${colors.orange_main};
    border-radius: 50%;
    font-size: 1.6rem;
  }
`;

interface Props {
  id: string;
  tooltip: string | JSX.Element;
  placement: Place;
}

QuestionMark.defaultProps = {
  placement: 'right'
};

function QuestionMark({id, tooltip, placement}: Props) {
  return (
    <StyledQuestion data-tip data-for={id}>
      <Tooltip id={id} placement={placement}>
        {tooltip}
      </Tooltip>
    </StyledQuestion>
  );
}

export default QuestionMark;

import React from 'react';
import styled from 'styled-components/macro';

import {colors, mainFont} from 'styles/shared';

const StyledAlertContainer = styled.div`
  width: 100%;
  border-radius: 0.6rem;
  border: 0.1rem solid ${props => (props.type === 'error' ? colors.red_main : colors.darkBlue_main)};
  box-sizing: border-box;
  margin-bottom: 3rem;
  ${mainFont};
`;

const StyledAlertHeader = styled.div`
  background: ${props => (props.type === 'error' ? colors.red_main : colors.darkBlue_main)};
  font-weight: bold;
  color: ${colors.white};
  height: 5.4rem;
  border-bottom: 0.1rem solid ${props => (props.type === 'error' ? colors.red_main : colors.darkBlue_main)};
  padding: 1.6rem;
`;

const StyledAlertBody = styled.div`
  padding: 1.6rem;
  color: ${colors.darkBlue_main};
`;

interface Props extends BaseProps {
  header: string;
  text?: string;
  type?: 'error' | 'info';
}

AlertComponent.defaultProps = {
  text: '',
  type: 'error'
};

function AlertComponent({header, text, type, children}: Props) {
  let errorClassName = type === 'error' ? 'validation-error' : '';

  return (
    <StyledAlertContainer type={type} className={errorClassName}>
      <StyledAlertHeader type={type}>{header}</StyledAlertHeader>

      {text && <StyledAlertBody>{text}</StyledAlertBody>}

      {children && <StyledAlertBody>{children}</StyledAlertBody>}
    </StyledAlertContainer>
  );
}

export default AlertComponent;

import { startCase } from "lodash";

import COUNTRY_CODE from "constants/countryCodes/countryCodes";
import { US_STATES_MAPPING } from "constants/usStates/usStatesMapping";

export default {
  remapAddressComponentsByCountryForGoogleAutofill,
  reformatPostalCodeByCountry,
  reformatUSState
}

// For Google Autofill components in PhysicalAddress
function remapAddressComponentsByCountryForGoogleAutofill(fieldsMap, googleAddressComponents, countryCode: string) {
  if (countryCode === COUNTRY_CODE.TAIWAN ||
    (countryCode === COUNTRY_CODE.ARGENTINA && !googleAddressComponents.locality)) {
    delete fieldsMap.city;
    Object.assign(fieldsMap, {city: fieldsMap.division});
    fieldsMap.city.format = 'long_name';
    delete fieldsMap.division;
  }
  return fieldsMap;
}

function reformatPostalCodeByCountry(postalCode: string, countryCode: string) {
  if (countryCode === COUNTRY_CODE.ARGENTINA) {
      return postalCode.replace(/\D/g, '');
  }
  return postalCode;
}

// Map US States to two letter format
function reformatUSState(state: string): string {
  const trimmedState = state.trim();
  const abbreviationFormat = trimmedState.toUpperCase();

  if (US_STATES_MAPPING.byAbbreviation().hasOwnProperty(abbreviationFormat)) {
    return abbreviationFormat;
  }
  const nameFormat = startCase(trimmedState.toLowerCase());
  const statesMappingByName = US_STATES_MAPPING.byName();

  if (statesMappingByName.hasOwnProperty(nameFormat)) {
    return statesMappingByName[nameFormat];
  }

  return '';
}

import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';
import userActions from 'actions/userActions';

import uiHelper from 'helpers/uiHelper';
import validationHelper from 'helpers/validationHelper';

import authService from 'services/authService';

import Flex from 'components/common/Flex';
import Button from 'components/common/Button';
import Divider from 'components/common/Divider';
import FullAccessModule from 'components/auth/components/full_access_module/FullAccessModule';

import * as Styled from 'styles/modal';
import * as styled from './GuestAuthModal.styled';

interface Props {
  visible: boolean;
  close: () => void;
}

function GuestAuthModal({visible, close}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: AppState) => state.user.current as User);

  const [errors, setErrors] = useState({email: '', password: ''});

  useEffect(() => {
    if (user.isGuest) return;

    //redirect on success
    const redirectUrl = authService.getRedirectUrl();

    close();

    uiHelper.showNotice(`Welcome Back ${user.name}!`, true);

    if (redirectUrl) history.push(redirectUrl);
  }, [user]);

  function signInFromIsValid(userData) {
    let newErrors = {
      email: '',
      password: ''
    };

    if (!userData.email) {
      newErrors.email = 'Email or import/export number field is required.';
    } else if (userData.email.length < 6) {
      newErrors.email = 'This value is too short. It should have 6 characters or more.';
    }

    if (!userData.password) {
      newErrors.password = 'Password field is required.';
    }

    setErrors(newErrors);

    return validationHelper.isEmptyErrorObject(newErrors);
  }

  async function signIn(userData, rememberMe) {
    if (!signInFromIsValid(userData)) return;

    const response: any = await dispatch(userActions.fullAccessSignIn(userData));

    const isSuccessResponse = (object: any): object is PasswordAuthResponse => {
      return object && 'token' in object;
    };

    if (isSuccessResponse(response)) {
      await dispatch(
        userActions.updateTokensAndGetUser({
          token: response.token,
          refreshToken: response.refresh_token,
          updateRefreshToken: rememberMe
        }, true)
      );
    } else {
      const errorResponse = response as AcceptableErrorCodeResponse;

      if (errorResponse?.errorCode === 'NoPasswordSet') {
        let error = '';
        if (validationHelper.isValidEmail(userData.email)) {
          error = 'This email does not match password you have entered.';
        } else {
          error = 'This import/export no. does not match password you have entered.';
        }
        setErrors({email: error, password: ''});
        return;
      }

      if (errorResponse?.errorCode === 'PasswordIncorrect') {
        setErrors({email: 'The password entered does not match the email address on file.', password: ''});
        return;
      }

      setErrors({email: 'Password you have entered does not match the one in our database.', password: ''});
    }
  }

  function signUp() {
    const redirectUrl = authService.getRedirectUrl();
    dispatch(commonActions.toggleGuestSignUpModal(true, redirectUrl));
    close();
  }

  return (
    <Styled.CustomModal show={visible} backdrop="static" padding="3.2rem 2.6rem 4rem" onHide={close}>
      <styled.header>Let’s Get Started!</styled.header>
      <styled.closeButton onClick={close} />

      <FullAccessModule errors={errors} signInAction={signIn} />

      <Divider message="Not an OptimalShip Client?" />

      <Flex justify="center">
        <Button autoWidth onClick={signUp}>
          Sign Up
        </Button>
      </Flex>
    </Styled.CustomModal>
  );
}

export default GuestAuthModal;

import React from 'react';

function Icon() {
  return (
    <svg width="48" height="48" fill="none" viewBox="0 0 48 48">
      <path stroke="#FC7D58" strokeLinecap="round" strokeWidth="2" d="M22 13L10.686 24.314 22 35.627"></path>
      <rect width="24" height="2" x="10" y="23.5" fill="#f8d5ca" rx="1"></rect>
    </svg>
  );
}

export default Icon;

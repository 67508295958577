import styled from 'styled-components/macro';

import TextInput from 'components/common/TextInput';
import Flex from 'components/common/Flex';

export const textInput = styled(TextInput)`
  margin: 0rem;
  min-height: 6.7rem;

  & .validation-error {
    white-space: nowrap;
  }
`;

export const checkboxContainer = styled(Flex)`
  margin-bottom: 3rem;
`;

import httpHelper from 'helpers/httpHelper';
import utils from 'helpers/utils';
import uiHelper from 'helpers/uiHelper';

import adaptPhoneInputForAddressesApi from 'domain/adapters/phoneApiAdapter';

import supportService from 'services/supportService';

import mapper from './mapping';

export default {
  getAddresses,
  createAddress,
  deleteAddress,
  importAddresses,
  updateAddress
};

async function getAddresses(search: string): Promise<{addresses: SavedAddress[]; totalItems: number}> {
  let allAddresses: AddressResponse[] = [];
  let totalItems: number = 0;
  let page: number = 1;

  do {
    const options = {
      autocompleteName: search,
      page
    };

    const response: AddressesResponse = await httpHelper.get('/saved_addresses', options, {
      acceptableErrorCodes: [404],
      totalItems: true
    });

    allAddresses = allAddresses.concat(response.items);
    totalItems = response.totalItems;
    page++;
  } while (allAddresses.length < totalItems);

  return {
    addresses: allAddresses.map(response => mapAddress(response)),
    totalItems
  };
}

async function createAddress(data: Sending): Promise<SavedAddress> {
  const requestPhone = adaptPhoneInputForAddressesApi(data.country, data.phoneNumber, data.phoneNumberExtension);

  const address = data.address;

  const body: AddressRequestDto = {
    id: utils.getRandomUid(),
    nickname: data.contactName,
    address: {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      addressLine3: address.addressLine3,
      postalCode: address.postalCode,
      locality: address.city,
      dependentLocality: address.suburb,
      administrativeArea: address.division,
      countryCode: data.country
    },
    contactName: data.contactName,
    companyName: data.companyName,
    vatNumber: data.VATNumber,
    taxId: data.taxId,
    eoriNumber: data.EORINumber,
    contact: {
      name: data.contactName,
      email: data.email,
      phone: requestPhone
    },
    isResidential: data.isResidential
  };

  return await httpHelper.post('/saved_addresses', body, {});
}

async function deleteAddress(id: string): Promise<SuccessfullResponse> {
  return await httpHelper.delete(`/saved_addresses/${id}`, {});
}

async function importAddresses(formData, user): Promise<AddressResponse[]> {
  try {
    const result: {addresses: AddressResponse[]; notValidAddresses: AddressResponse[]} = await httpHelper.upload(
      '/import_addresses',
      formData
    );

    let saveAddressHandlers: any = [];

    result.addresses.forEach((addressResponse: AddressResponse) => {
      const address = addressResponse.address;
      const responsePhone = addressResponse.contactPhone;
      const requestPhone = adaptPhoneInputForAddressesApi(address.countryCode, responsePhone?.number,
        responsePhone?.extension, responsePhone?.dialCode, true);

      const body: AddressRequestDto = {
        id: addressResponse.id,
        nickname: addressResponse.nickname,
        address: {
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          addressLine3: address.addressLine3,
          postalCode: address.postalCode,
          locality: address.locality,
          dependentLocality: address.dependentLocality,
          administrativeArea: address.administrativeArea,
          countryCode: address.countryCode
        },
        contactName: addressResponse.contactName,
        companyName: addressResponse.companyName,
        vatNumber: addressResponse.vatNumber,
        taxId: addressResponse.taxId,
        eoriNumber: addressResponse.eoriNumber,
        contact: {
          name: addressResponse.contact.name,
          email: addressResponse.contact.email,
          phone: requestPhone
        },
        isResidential: addressResponse.residential
      };

      saveAddressHandlers.push(httpHelper.post('/saved_addresses', body, {}));
    });

    await Promise.all(saveAddressHandlers);

    return result.notValidAddresses;
  } catch (err) {
    uiHelper.logError(err);

    const fileInfo = formData.get('addresses');
    const metaData = {
      fileName: fileInfo?.name,
      fileSize: fileInfo?.size,
      fileType: fileInfo?.type
    };
    supportService.logToRollbar('Import Addresses Error', metaData, user, true);

    return [];
  }
}

async function updateAddress(data: Sending, id: string): Promise<SavedAddress> {
  const requestPhone = adaptPhoneInputForAddressesApi(data.country, data.phoneNumber, data.phoneNumberExtension);

  const address = data.address;

  const body: AddressRequestDto = {
    id,
    nickname: data.contactName,
    address: {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      addressLine3: address.addressLine3,
      postalCode: address.postalCode,
      locality: address.city,
      dependentLocality: address.suburb,
      administrativeArea: address.division,
      countryCode: data.country
    },
    contactName: data.contactName,
    companyName: data.companyName,
    vatNumber: data.VATNumber,
    taxId: data.taxId,
    eoriNumber: data.EORINumber,
    contact: {
      name: data.contactName,
      email: data.email,
      phone: requestPhone
    },
    isResidential: data.isResidential
  };

  return await httpHelper.put(`/saved_addresses/${id}`, body);
}

//helper methods

function mapAddress(addressResponse) {
  const address = mapper.address.mapAddressFromAddressResponse(addressResponse);
  return address;
}

import React, {useState} from 'react';
import {Row, Col} from 'components/bootstrap';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import commonActions from 'actions/commonActions';
import userActions from 'actions/userActions';

import FEATURE from 'constants/features';
import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import PATH from 'constants/path';

import config from 'config';
import validationHelper from 'helpers/validationHelper';

import TextInput from 'components/common/TextInput';
import Button from 'components/common/Button';
import ActionLink from 'components/common/ActionLink';

import * as styled from './SignUpForm.styled';

interface LocalUser {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  email: string;
  password: string;
  repeatPassword: string;
  promoCode: string;
}

interface SignUpErrors {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  email: string;
  password: string;
  repeatPassword: string;
  dhlNumber: string;
}

const INITIAL_USER: LocalUser = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  phoneNumberExtension: '',
  email: '',
  password: '',
  repeatPassword: '',
  promoCode: ''
};

interface Props {
  isGuest: boolean;
  close?: () => void;
}

function SignUpForm({isGuest, close}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [localUser, setLocalUser] = useState<LocalUser>(INITIAL_USER);

  const [errors, setErrors] = useState<SignUpErrors>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    phoneNumberExtension: '',
    email: '',
    password: '',
    repeatPassword: '',
    dhlNumber: ''
  });

  function onChange(field: string, value) {
    setLocalUser({...localUser, [field]: value});
  }

  function callAction() {
    window.open(`tel:${config.supportPhone.value}`);
  }

  function signUpFormIsValid() {
    const newErrors: SignUpErrors = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      phoneNumberExtension: '',
      email: '',
      password: '',
      repeatPassword: '',
      dhlNumber: ''
    };

    if (!localUser.firstName) {
      newErrors.firstName = 'First Name field is required.';
    }
    if (!localUser.lastName) {
      newErrors.lastName = 'Last Name field is required.';
    }
    if (!localUser.phoneNumber) {
      newErrors.phoneNumber = 'Phone field is required.';
    }
    if (localUser.phoneNumberExtension.length > 5) {
      newErrors.phoneNumberExtension = 'Maximum field length of 5 for extension was exceeded.';
    }
    if (!localUser.email) {
      newErrors.email = 'Please enter your E-mail address';
    } else if (!validationHelper.isValidEmail(localUser.email)) {
      newErrors.email = 'Email is not valid.';
    }

    if (FEATURE.INSTANT_SIGN_UP) {
      if (!localUser.password) {
        newErrors.password = 'Password field is required.';
      } else if (localUser.password.length < 8) {
        newErrors.password = 'This value is too short. It should have 8 characters or more.';
      }
      if (!localUser.repeatPassword) {
        newErrors.repeatPassword = 'Confirm password field is required.';
      } else if (localUser.password && localUser.repeatPassword && localUser.password !== localUser.repeatPassword) {
        newErrors.repeatPassword = 'Passwords do not match.';
      }
    }

    setErrors(newErrors);

    return validationHelper.isEmptyErrorObject(newErrors);
  }

  async function signUp(e) {
    if (e) e.preventDefault();

    if (!signUpFormIsValid()) return;

    if (FEATURE.INSTANT_SIGN_UP) {
      await dispatch(userActions.instantSignUp(localUser));
      return;
    }

    const body = {
      json: JSON.stringify(localUser)
    };

    const isSuccess: any = await dispatch(userActions.signUpAction(body));

    if (isSuccess) {
      dispatch(
        commonActions.infoAction({
          text: (
            <div>
              Welcome to OptimalShip!
              <br />
              <br />
              We have received your request and someone from our Customer Service team will reach out to you soon. If
              you would like to contact us sooner, you can reach us at{' '}
              <ActionLink title="972-383-9901" isInheritFont onClick={callAction} />.
            </div>
          ),
          type: 'warning',
          closeTitle: 'Great!',
          close: () => {}
        })
      );

      if (!isGuest) history.push(PATH.SIGN_IN);
      if (close) close();
    }
  }

  function render() {

    return (
      <>
        <styled.title>Sign Up!</styled.title>
        <styled.subtitle>
          If you are ready to give this a try, send us some contact information and we will set up an{' '}
          <strong>OptimalShip sign-in</strong> for you.
          <br />
          <br />
          You will have access to the easiest-to-use online <br /> shipment preparation tools and access to exceptional
          customer service support. All with no monthly fee.
        </styled.subtitle>
        <styled.formContainer>
          <Row>
            <Col lg={6}>
              <styled.textInput
                name="firstName"
                label="First Name"
                value={localUser.firstName}
                required
                onChange={onChange}
                placeholder="First Name"
                error={errors.firstName}
              />
            </Col>
            <Col lg={6}>
              <styled.textInput
                name="lastName"
                label="Last Name"
                value={localUser.lastName}
                required
                onChange={onChange}
                placeholder="Last Name"
                error={errors.lastName}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <styled.phoneNumberInput
                name="phoneNumber"
                label="Telephone Number"
                required
                countryCode={COUNTRY_CODE.USA}
                value={localUser.phoneNumber}
                onChange={onChange}
                error={errors.phoneNumber}
              />
            </Col>
            <Col lg={6}>
              <styled.textInput
                name="phoneNumberExtension"
                label="Extension"
                placeholder="000"
                value={localUser.phoneNumberExtension}
                onChange={onChange}
                error={errors.phoneNumberExtension}
              />
            </Col>
          </Row>

          <styled.textInput
            name="email"
            type="email"
            label="E-mail"
            value={localUser.email}
            required
            onChange={onChange}
            placeholder="shipper@gmail.com"
            error={errors.email}
          />

        {FEATURE.INSTANT_SIGN_UP &&
          <>
            <styled.textInput
              name="password"
              label="Password"
              type="password"
              value={localUser.password}
              required
              placeholder="********"
              onChange={onChange}
              error={errors.password}
            />

            <TextInput
              name="repeatPassword"
              type="password"
              label="Repeat Password"
              value={localUser.repeatPassword}
              required
              placeholder="********"
              onChange={onChange}
              error={errors.repeatPassword}
            />
          </>
        }

          <styled.textInput
            name="promoCode"
            label="Do you have a promo code?"
            value={localUser.promoCode}
            placeholder="Please enter your promo code"
            onChange={onChange}
            error={null}
          />

          <styled.buttonRow justify="center">
            <Button autoWidth onClick={signUp}>
              Sign Up
            </Button>
          </styled.buttonRow>
        </styled.formContainer>
      </>
    );
  }

  return render();
}

export default SignUpForm;

import config from 'config';

import SEARCH_PARAMETER from 'constants/searchParameters';
import PATH from 'constants/path';

import authService from 'services/authService';

export const ALLOWED_URLS = [PATH.GET_QUOTE];

export const ALLOWED_INACTIVE_USER_URLS = [PATH.GET_QUOTE, PATH.HOME, PATH.RESET_PASSWORD, PATH.SIGN_IN, PATH.SIGN_UP, PATH.USER_SETTINGS, PATH.VIEW_HISTORY];
export const DISALLOWED_SUSPENDED_USER_URLS = [PATH.PREPARE_SHIPMENT, PATH.SCHEDULE_PICKUP];

export default {
  getInspectUrl,
  getUserInspectUrl,
  getViewHistoryPageLink,
  getDHLTrackingUrl,
  getLandingUrl
};

function getInspectUrl(requestId) {
  if (!requestId) return '';

  return `${config.baseUrl}/inspection/${requestId}`;
}

function getUserInspectUrl(userId: string) {
  if (!userId) return '';

  return `${config.baseUrl}/inspection/user/${userId}`;
}

function getViewHistoryPageLink(section = '') {
  let link = PATH.VIEW_HISTORY;

  if (!section) return link;

  return `${link}/?${SEARCH_PARAMETER.LOCAL.SECTION}=${section}`;
}

function getDHLTrackingUrl(trackingNumber: string) {
  return `${config.dhlTrackingNumberUrl}?tracking-id=${trackingNumber}&submit=1`;
}

function getLandingUrl(isCSR, isGuest?, isQuickAccessAuth?) {
  if (isGuest || isQuickAccessAuth) return PATH.GET_QUOTE;

  let landingUrl = isCSR ? PATH.USERS : PATH.HOME;

  const redirectUrl = authService.getRedirectUrl();
  if (redirectUrl) {
    authService.saveRedirectUrl(null);
    landingUrl = redirectUrl;
  }

  return landingUrl;
}

import React from 'react';
import styled from 'styled-components/macro';

import ORDER_STATUS from 'constants/orderStatuses';

import uiHelper from 'helpers/uiHelper';

import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import {colors, mainFont} from 'styles/shared';

const StyledContainer = styled(Flex)`
  margin-top: 3rem;
`;

const StyledBodyContainer = styled(Flex)`
  max-width: 70rem;
  color: ${colors.darkBlue_main};
`;

const StyledTitle = styled.div`
  margin: 3rem 0 0.8rem;
  font-size: 2.6rem;
  line-height: 3.5rem;
  letter-spacing: 0.03rem;

  & span {
    font-weight: bold;
  }
`;

const StyledText = styled.div`
  ${mainFont};
  margin-bottom: 3rem;
`;

const StyledLink = styled.span`
  ${mainFont};
  color: ${colors.orange_main};
  cursor: pointer;
`;

const StyledFragment = styled.div`
  text-align: center;
`;

interface Props {
  title: string;
  activeTab: string;
  onTabChange: (tab: string) => void;
}

function NoOrders({title, activeTab, onTabChange}: Props) {
  function getDisplayTitle() {
    if (activeTab === ORDER_STATUS.CANCELLED || activeTab === ORDER_STATUS.PROCESSED) {
      return (
        <>
          You have no <span>{title}</span> orders
        </>
      );
    }

    return (
      <>
        You have no orders <span>{title}</span>
      </>
    );
  }

  function getSubtitle() {
    switch (activeTab) {
      case ORDER_STATUS.AWAITING_PAYMENT:
        return (
          <StyledFragment>
            Some selling channels allow for delayed payments, e.g. auctions, pay by check or money order. These Orders
            will show up here and move to{' '}
            <StyledLink onClick={() => onTabChange(ORDER_STATUS.AWAITING_SHIPMENT)}>Awaiting Shipment</StyledLink> once
            paid.
          </StyledFragment>
        );

      case ORDER_STATUS.ON_HOLD:
        return 'An order may not be ready to ship for many reason. This is a place to “park” them.';

      case ORDER_STATUS.AWAITING_SHIPMENT:
        return (
          <StyledFragment>
            This section shows all the orders you have already shipped. You can create return label or re-ship orders as
            needed.
          </StyledFragment>
        );

      case ORDER_STATUS.PROCESSED:
        return (
          <StyledFragment>
            This section shows all the orders you have already shipped. You can create return label or re-ship orders as
            needed.
          </StyledFragment>
        );

      case ORDER_STATUS.CANCELLED:
        return (
          <StyledFragment>
            Sometimes things don’t work out: a shopper cancels an order or you can’t ship the order. Those orders will
            show up here once cancelled.
          </StyledFragment>
        );

      default:
        return null;
    }
  }

  return (
    <StyledContainer justify="center">
      <StyledBodyContainer direction="column" justify="center" align="center">
        <img alt="Supermarket" src="/images/supermarket.png" />

        <StyledTitle>{getDisplayTitle()}</StyledTitle>

        <StyledText>{getSubtitle()}</StyledText>

        <StyledText>Connect your selling channels to manage all your orders in one place.</StyledText>

        <Button autoWidth onClick={uiHelper.notImplemented}>
          connect a selling channel
        </Button>
      </StyledBodyContainer>
    </StyledContainer>
  );
}

export default NoOrders;

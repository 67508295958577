import React from 'react';

function ShipmentReferenceNumberTooltip() {
  return (
    <span>
      This is NOT a required filed and can be left blank. <br />
      Numbers listed here can include:{' '}
      <strong>
        Purchase <br />
        Order Numbers, Internal Reference Numbers, <br /> Customer Name, Unique Reference Codes
      </strong>
        , etc.
    </span>
  );
}

export default ShipmentReferenceNumberTooltip;

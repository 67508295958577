import React from 'react';

import {colors} from 'styles/shared';

function Icon(props) {
  let color = props.color ? props.color : colors.orange_main;

  return (
    <svg {...props} width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill={color}
        fillRule="evenodd"
        d="M.287.287a.981.981 0 000 1.388L8.613 10 .287 18.325a.981.981 0 101.388 1.387L10 11.387l8.325 8.325a.981.981 0 001.388-1.387L11.387 10l8.326-8.325A.981.981 0 1018.325.287L10 8.612 1.675.287a.981.981 0 00-1.388 0z"
        clipRule="evenodd"></path>
    </svg>
  );
}

export default Icon;

import styled from 'styled-components/macro';

import TextInput from 'components/common/TextInput';
import CheckBoxInput from 'components/common/CheckboxInput';
import Flex from 'components/common/Flex';

import {colors, secondaryFont, authFont} from 'styles/shared';

export const text = styled.div`
  ${authFont};
  margin-top: 2.4rem;

  & span {
    color: ${colors.orange_main};
  }
`;

export const textInput = styled(TextInput)`
  margin-bottom: 0;
  min-height: auto;
`;

export const checkboxInput = styled(CheckBoxInput)`
  margin-top: 2rem;
`;

export const buttonsContainer = styled(Flex)`
  margin-top: 2.7rem;
`;

export const divider = styled.div`
  width: 100%;
  margin: 2rem 0 1.6rem;
  border-bottom: 0.1rem dashed ${colors.darkBlue_light};
`;

export const hint = styled.div`
  text-align: center;
  font-family: 'Nunito Sans Italic';
  ${secondaryFont};

  & span {
    color: ${colors.orange_main};
  }
`;

import React, {useEffect, useState, useRef} from 'react';
import {get} from 'lodash';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';
import draftActions from 'actions/draftActions';
import pickupActions from 'actions/pickupActions';
import commonActions from 'actions/commonActions';

import TUTORIAL from 'constants/tuturials';
import TIMER from 'constants/timers';
import SECTION from 'constants/sections';
import PICKUP_OPTION from 'constants/pickupOptions';

import dateHelper from 'helpers/dateHelper';
import utils from 'helpers/utils';
import uiHelper from 'helpers/uiHelper';

import stateStorageService from 'services/stateStorageService';
import pickupService from 'services/pickupService';

import entities from 'domain/entities';

import COMMON from 'constants/common';

import Container from 'components/common/Container';
import RadioInput from 'components/common/RadioInput/RadioInput';
import FormHeader from 'components/common/FormHeader';
import Button from 'components/common/Button';
import Flex from 'components/common/Flex';
import Calendar from 'components/common/Scheduler/Calendar';
import CalendarTooltip from 'components/tooltips/CalendarTooltip';

import PickupOptions from './components/pickup_options/PickupOptions';
import PickupServices from './components/PickupServices';
import PickupDetails from './components/PickupDetails';
import PickupReadOnly from './components/PickupReadOnly';
import PickupForImport from './components/PickupForImport';

const StyledHeader = styled(Flex)`
  margin-bottom: 3rem;
`;

interface Props {
  validatePickupAction: () => void;
}

function PickupDelivery({validatePickupAction}: Props) {
  const dispatch = useDispatch();

  const draft = useSelector((state: AppState) => state.draft.current.data as Draft);
  const rate = useSelector((state: AppState) => state.draft.rate.current);
  const errors = useSelector((state: AppState) => state.draft.current.errors);
  const draftPickups: Pickup[] = useSelector((state: AppState) => state.draft.pickup.list);
  const pickupData: Optional<PickupData> = useSelector((state: AppState) => state.draft.pickup.dataToSave);
  const pickupToSave: PickupData | null = useSelector((state: any) => state.draft.pickup.dataToSave);
  const refreshDraftPickups = useSelector((state: AppState) => state.draft.current.shouldReviewPickup);

  const [confirmedDates, setConfirmedDates] = useState([]);
  const [existingPickup, setExistingPickup] = useState<Pickup | undefined>();

  const delayTimer = useRef<NodeJS.Timeout | null>(null);
  const hidePickupWarning = useRef<boolean>(false);

  useEffect(() => {
    dispatch(draftActions.loadDraftPickups(draft.shipmentFrom.address, rate?.carrier));

    window.addEventListener('scroll', onScroll, true);

    return () => {
      window.removeEventListener('scroll', onScroll, true);

      if (delayTimer?.current) clearInterval(delayTimer.current);
    };
  }, []);

  // Refresh draft pickups if address has changed
  useEffect(() => {
    if (refreshDraftPickups) dispatch(draftActions.loadDraftPickups(draft.shipmentFrom.address, rate?.carrier));
  }, [refreshDraftPickups]);

  // If an old pickup, changes into edit mode
  // If an old date from a saved draft or quote, changes to current date and notifies user
  useEffect(() => {
    const isPickupRequired = draft.pickupDelivery.isPickupRequired;
    const existingPickupDate = draft.pickupDelivery.pickup?.date;
    const today = new Date(new Date().setHours(0,0,0,0));

    if (isPickupRequired && existingPickupDate && (new Date(existingPickupDate) < today)) {
      clearOldPickupAndSetDataToSave();
    } else if (new Date(draft.pickupDelivery.shipmentDate) < today) {
      onChange('shipmentDate', new Date());

      uiHelper.showNotice('Updating past shipment <br/> date to current date', true);
    }
  }, []);

  // Checks if saved pickup on a duplicate draft has match in already scheduled fetched pickups (same carrier and sending address)
  // If not clears the saved pickup on the draft but retains the user entered values to create a new pickup or add to existing
  // Also clears pickup if changed to an import
  // Saves dates of fetched pickups so we know if there is an exisiting pickup for that date
  useEffect(() => {
    if (!draftPickups) return;

    const savedPickupId = draft.pickupDelivery.pickupId;
    let matchingPickup;

    if (savedPickupId) {
      matchingPickup = draftPickups.find((pickup: Pickup) => {
        return pickup.carrier === rate?.carrier && pickup.id === savedPickupId;
      });
    }

    if (draft.isImport || !matchingPickup) {
      clearOldPickupAndSetDataToSave();
    }

    let initialConfirmedDates: any = pickupService.getConfirmedDates(draftPickups);

    setConfirmedDates(initialConfirmedDates);
  }, [draftPickups, rate?.carrier]);

  // Checks if there is an existing pickup for the selected date
  // If not, checks if new pickup can be scheduled
  useEffect(() => {
    let pickupExists: Pickup | undefined = draftPickups.find((pickup: Pickup) => {
      return pickup.carrier === rate?.carrier && dateHelper.isEqualDates(pickup.date, pickupData?.date);
    });

    setExistingPickup(pickupExists);

    if (!pickupExists && rate?.carrierProduct) checkPickup();
  }, [pickupData?.date, confirmedDates, rate?.carrierProduct]);

  //set default pickup data if pickup required and there is no pickup/pickupId/pickupToSave
  useEffect(() => {
    const pickupDelivery = draft.pickupDelivery;

    if (!pickupDelivery.isPickupRequired) return;

    let hasPickup = pickupDelivery?.pickup || pickupDelivery?.pickupId;

    if (!hasPickup && !pickupToSave) {
      dispatch(draftActions.setPickupDataToSave(entities.pickup.getDefault()));
    }
  }, [draft.pickupDelivery.isPickupRequired]);

  // Sets pickup to false if import
  useEffect(() => {
    if (draft.isImport) {
      hidePickupWarning.current = true;
      onChange('isPickupRequired', false);
    }
  }, []);

  // For imports, switches to pickup not required and shows popup to call CS
  // Sets pickup option so users do not have to scroll back up
  useEffect(() => {
    const pickupDelivery = draft.pickupDelivery;

    if (hidePickupWarning.current) {
      hidePickupWarning.current = false;
      return;
    }

    if ((draft.isImport) && pickupDelivery.isPickupRequired) {
      onChange('isPickupRequired', false);
      showPickupWarning();

      if (!pickupDelivery.custom?.pickupOption) {
        pickupDelivery.custom = {
          pickupOption: PICKUP_OPTION.NOT_READY_SCHEDULE,
          reminder: pickupDelivery.custom?.reminder ?? 0
        };
      }
    }
  }, [rate?.carrier, draft.pickupDelivery.isPickupRequired]);

  function showPickupWarning() {
    dispatch(
      commonActions.infoAction({
        type: 'info',
        text: `Please contact our customer service if you would like to schedule a pickup outside of the United States. You can continue preparing your shipment.`,
        logToRollbar: true,
        rollbarContext: 'Cannot Schedule Pickup Warning',
        close: () => null
      })
    );
  }

  function onScroll() {
    let element = document.getElementById(SECTION.DRAFT.PICKUP);
    if (!element) return;

    let clientRect = element.getBoundingClientRect();

    if (clientRect?.top <= 110) {
      if (stateStorageService.tutorialWasDisplayed(TUTORIAL.PICKUP)) return;

      stateStorageService.setTutorialWasDisplayed(TUTORIAL.PICKUP);

      onHelp();
    }
  }

  function onHelp() {
    dispatch(commonActions.toggleGlobalOverlay());

    delayTimer.current = utils.setTimeout(() => {
      dispatch(commonActions.toggleGlobalOverlay());
      dispatch(commonActions.tutorialInfo({tutorial: TUTORIAL.PICKUP, steps: 3}));
    }, TIMER.HELP_TUTORIAL_DELAY);
  }

  function checkPickup() {
    let data: CheckPickupCapabilityDto = {
      draft,
      carrier: rate?.carrier,
      shipmentId: draft.id,
      carrierProduct: rate?.carrierProduct
    };

    dispatch(pickupActions.checkPickupCapability(data));
  }

  function onChange(field, value) {
    dispatch(draftActions.modifyDraft('pickupDelivery', field, value));
  }

  function addExistingPickup(pickup) {
    onChange('pickupId', pickup.id);
    onChange('pickup', pickup);
    dispatch(draftActions.setPickupDataToSave(undefined));
  }

  async function updatePickupData(field, value) {
    let dataToSave = {...pickupToSave};

    if (field === COMMON.PICKUP_TIME) {
      dataToSave.startTime = value[0];
      dataToSave.endTime = value[1];
    } else {
      dataToSave[field] = value;
    }

    let pickupData = entities.pickup.createFromData(dataToSave);

    await dispatch(draftActions.setPickupDataToSave(pickupData));
  }

  // For updating display of estimated delivery date
  function updatePickupDate(field, value) {
    onChange('shipmentDate', value);
    updatePickupData(field, value);
  }

  function clearOldPickupAndSetDataToSave() {
    editValidPickup(new Date());

    const newPickupDelivery = {...draft.pickupDelivery};

    newPickupDelivery.pickup = undefined;
    newPickupDelivery.pickupId = null;

    dispatch(draftActions.modifyDraft(null, 'pickupDelivery', newPickupDelivery));
  }

  async function editValidPickup(date: Date | undefined = undefined) {
    let pickup = draft.pickupDelivery.pickup;

    if (!pickup) return;

    let dataToSave: PickupData = {
      date: date ?? pickup.date,
      startTime: pickup.startTime,
      endTime: pickup.endTime,
      location: pickup.location,
      numOfPackages: pickup.numOfPackages,
      notes: pickup.notes
    };

    let pickupData = entities.pickup.createFromData(dataToSave);

    await dispatch(draftActions.setPickupDataToSave(pickupData));
  }

  function renderPickupRequiredSection(pickupDelivery) {
    let hasPickup = pickupDelivery?.pickup || pickupDelivery?.pickupId;

    if (!pickupToSave && hasPickup) {
      return <PickupReadOnly pickupData={pickupDelivery.pickup} carrier={rate?.carrier} onEdit={editValidPickup} />;
    }

    if (draft.isImport) return <PickupForImport />;

    const pickupData = existingPickup ?? pickupToSave;

    return (
      <Calendar
        name="date"
        label="Select Shipment date"
        tooltip={CalendarTooltip()}
        confirmedDates={confirmedDates}
        value={pickupData?.date}
        onChange={updatePickupDate}
      />
    );
  }

  function render() {
    const pickupDelivery = draft.pickupDelivery;

    const isPickupRequired = pickupDelivery.isPickupRequired;

    const isImport = draft.isImport ? true : false;

    const pickupDetailsVisible = isPickupRequired && !isImport;

    return (
      <>
        <Container id={SECTION.DRAFT.PICKUP}>
          <StyledHeader justify="space-between" align="center">
            <FormHeader>When would you like to ship it?</FormHeader>
            <Button type="secondary" autoWidth onClick={onHelp}>
              Help
            </Button>
          </StyledHeader>

          <RadioInput
            name="isPickupRequired"
            label="Would you like to schedule a pickup?"
            required={true}
            value={isPickupRequired}
            onChange={onChange}
            error={get(errors, 'pickupDelivery.isPickupRequired', '')}
          />

          {isPickupRequired === true && renderPickupRequiredSection(pickupDelivery)}

          {isPickupRequired === false && (
            <PickupOptions
              pickupOption={pickupDelivery?.custom?.pickupOption ?? ''}
              pickupReminder={pickupDelivery?.custom?.reminder ?? 0}
              shipmentDate={pickupDelivery.shipmentDate}
              onChange={onChange}
              errors={errors}
            />
          )}
        </Container>

        <Container>
          <PickupServices
            carrierProduct={pickupDelivery.carrierProduct}
            shipmentDate={pickupDelivery.shipmentDate}
            isPickupRequired={isPickupRequired}
            isImport={isImport}
            isDomestic={draft.isDomestic}
            onChange={onChange}
          />
        </Container>

        {pickupDetailsVisible && (
          <PickupDetails
            pickupDelivery={pickupDelivery}
            existingPickup={existingPickup}
            addExistingPickup={addExistingPickup}
            validatePickupAction={validatePickupAction}
            updatePickupData={updatePickupData}
            carrier={rate?.carrier || ''}
          />
        )}
      </>
    );
  }

  return render();
}

export default PickupDelivery;

import React from 'react';
import {get} from 'lodash';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';

import {AppState} from 'reducers';
import paymentActions from 'actions/paymentActions';

import TextInput from 'components/common/TextInput';

const StyledTextInput = styled(TextInput)`
  margin-top: 0;
`;

function ACHPayment() {
  const dispatch = useDispatch();

  const achAccount = useSelector((state: AppState) => state.payment.achAccount);
  const errors = useSelector((state: AppState) => state.payment.errors);

  function onChange(field, value) {
    dispatch(paymentActions.modifyAchAccount(field, value));
  }

  return (
    <>
      <StyledTextInput
        name="routingNumber"
        label="Routing Number"
        required
        placeholder="000000000"
        value={achAccount.routingNumber}
        onChange={onChange}
        error={get(errors, 'achAccount.routingNumber')}
      />

      <StyledTextInput
        name="accountNumber"
        label="Account Number"
        required
        placeholder="0000000000"
        value={achAccount.accountNumber}
        onChange={onChange}
        error={get(errors, 'achAccount.accountNumber')}
      />

      <StyledTextInput
        name="nameOnAccount"
        label="Account Holder Name"
        required
        placeholder="John Doe"
        value={achAccount.nameOnAccount}
        onChange={onChange}
        error={get(errors, 'achAccount.nameOnAccount')}
      />

      <StyledTextInput
        name="bankName"
        label="Bank Name"
        required
        placeholder="Type the name of your bank"
        value={achAccount.bankName}
        onChange={onChange}
        error={get(errors, 'achAccount.bankName')}
      />
    </>
  );
}

export default ACHPayment;

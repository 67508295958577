import React from 'react';

function DutiesAndTaxesTooltip() {
  return (
    <span>
      When you bill any applicable duties/taxes directly <br />
      to your account, the most common Customs Terms <br />
      of Trade option is DDP - Delivered Duty Paid. <br />
      Please note there is a $20 charge for this service. <br />
      When the receiver is responsible for any applicable <br />
      duties/taxes, the most common Customs Terms of <br />
      Trade option is DAP - Delivered At Place.
    </span>
  );
}

export default DutiesAndTaxesTooltip;

import React from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';
import {useDispatch} from 'react-redux';

import commonActions from 'actions/commonActions';

import config from 'config';
import mailHelper from 'helpers/mailHelper';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import Flex from 'components/common/Flex';
import ActionLink from 'components/common/ActionLink';
import Button from 'components/common/Button';

import {secondaryFont, mainFont} from 'styles/shared';

const StyledContainer = styled(Container)`
  padding: 0 2.6rem;
`;

const StyledHeader = styled(FormHeader)`
  margin: 2.6rem 0 1rem;
`;

const StyledText = styled.div`
  ${secondaryFont};
  margin-bottom: 6.4rem;
`;

const StyledLinkRow = styled(Flex)`
  ${mainFont};
  line-height: 2.5rem;
`;

const StyledImage = styled.img`
  margin-top: 1rem;
`;

const StyledButtonContainer = styled(Flex)`
  height: 100%;
`;

interface Props {
  close: () => void;
}

function DangerousGoods({close}: Props) {
  const dispatch = useDispatch();

  function callAction() {
    window.open(`tel:${config.supportPhone.value}`);
  }

  function sendEmail() {
    const supportLink = mailHelper.getHelpEmailLink();

    window.location.href = supportLink;
  }

  function startChat() {
    dispatch(commonActions.toggleIntercomChat());
    close();
  }

  return (
    <StyledContainer>
      <Row>
        <Col lg={7}>
          <StyledHeader>Dangerous goods or Alcohol</StyledHeader>

          <Row>
            <Col lg={5}>
              <StyledText>
                Are you shipping dangerous goods or alcohol? Please contact customer service.
              </StyledText>
            </Col>
            <Col lg={{span: 6, offset: 1}}>
              <StyledLinkRow>
                Chat: &nbsp; <ActionLink title="Start chat" onClick={startChat} />
              </StyledLinkRow>
              <StyledLinkRow>
                Phone: &nbsp; <ActionLink title={config.supportPhone.label} onClick={callAction} />
              </StyledLinkRow>
              <StyledLinkRow>
                Email: &nbsp; <ActionLink title={config.supportUrl} onClick={sendEmail} />
              </StyledLinkRow>
            </Col>
          </Row>
        </Col>
        <Col lg={5}>
          <Row>
            <Col lg={{span: 5, offset: 1}}>
              <StyledImage alt="Dangerous Goods" src="/images/dangerous-goods.png" />
            </Col>
            <Col lg={6}>
              <StyledButtonContainer justify="flex-end" align="flex-end">
                <Button onClick={close}>Close Message</Button>
              </StyledButtonContainer>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledContainer>
  );
}

export default DangerousGoods;

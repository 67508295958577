import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';

import supportService from 'services/supportService';

import Button from 'components/common/Button';
import TextAreaInput from 'components/common/TextAreaInput';
import Flex from 'components/common/Flex';

import * as Styled from 'styles/modal';

import {mainFont, buttonLeftRightMargin, buttonLeftMargin} from 'styles/shared';

const StyledSupportInfo = styled.pre`
  ${mainFont};
`;

interface Props {
  visible: boolean;
  close: () => void;
  onSend: (info: any) => Promise<void>;
  supportParameters: SupportParameters;
}

function SupportModal({visible, close, onSend, supportParameters}: Props) {
  const [supportInfo, setSupportInfo] = useState<{} | undefined>();
  const [notes, setNotes] = useState('');

  useEffect(() => {
    initData();
  }, []);

  async function initData() {
    const supportInfoInitial: SupportInfo = await supportService.getSupportInfo(supportParameters);

    setSupportInfo(supportInfoInitial);
  }

  function render() {
    let fullInfo;

    if (supportInfo) {
      fullInfo = {
        ...supportInfo,
        userNotes: notes
      };
    }

    let displaySupportInfo = fullInfo ? JSON.stringify(fullInfo, null, 2) : 'Loading...';

    return (
      <Styled.CustomModal show={visible} backdrop="static" width="60rem" padding="2rem" onHide={close}>
        <TextAreaInput
          name="notes"
          placeholder="Notes for support"
          value={notes}
          onChange={(field, value) => setNotes(value)}
        />

        <h2>Support info</h2>

        <StyledSupportInfo>{displaySupportInfo}</StyledSupportInfo>

        <Flex justify="flex-end">
          <Button type="secondary" autoWidth margin={buttonLeftRightMargin} onClick={close}>
            Cancel
          </Button>
          <Button
            disabled={supportInfo ? false : true}
            autoWidth
            margin={buttonLeftMargin}
            onClick={() => onSend(displaySupportInfo)}>
            Send
          </Button>
        </Flex>
      </Styled.CustomModal>
    );
  }

  return render();
}

export default SupportModal;

import React from 'react';

function VATTooltip() {
  return (
    <span>
      <strong>VAT</strong> - Value Added Tax: An identification <br /> number used in many countries, including <br />{' '}
      the countries of the European Union, for <br /> value added tax purposes. It identifies a <br /> taxable
      business/person OR a non-taxable <br /> legal entity. The United states does not <br /> have a VAT system.
    </span>
  );
}

export default VATTooltip;

import {LOAD_SHIPMENTS, CHANGE_SHIPMENTS_PAGE} from 'action_types/shipmentActonTypes';
import {APP_ERROR} from 'action_types/commonActionTypes';

import helper from './actionHelper';
import apis from 'domain/apis';

export default {
  updateShipmentsPageNumber,
  createShipment,
  loadShipments,
  loadShipment,
  loadShipmentDocument,
  loadCommodityCodeDetails,
  cancelShipment
};

function updateShipmentsPageNumber(activePage: number) {
  return helper.getAction(CHANGE_SHIPMENTS_PAGE, {activePage});
}

function createShipment(data: PrepareShipmentDto) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.shipment.createShipment(data);

    if (response?.success) return response.shipmentId;

    let errorMessage = getErrorFromResponse(response, 'Error on Shipment creation');

    dispatch(helper.getAction(APP_ERROR, {error: errorMessage}));

    return null;
  });
}

function loadShipments(activePage: number, pageSize: number) {
  return helper.dispatchAsyncAction(async dispatch => {
    const result = await apis.shipment.getShipments(activePage, pageSize);

    dispatch(helper.getAction(LOAD_SHIPMENTS, {...result}));
  });
}

function loadShipment(id) {
  return helper.dispatchAsyncAction(async () => {
    const shipment = await apis.shipment.getShipmentById(id);
    return shipment;
  });
}

function loadShipmentDocument(documentId) {
  return helper.dispatchAsyncAction(async () => {
    let response: ShipmentDocument = await apis.shipment.getShipmentDocument(documentId);
    return response;
  });
}

function loadCommodityCodeDetails(code) {
  return helper.dispatchAsyncAction(async () => {
    const response = await apis.geCommodityCodeDetails(code);

    const isErrorResponse = (object: any): object is AcceptableErrorCodeResponse => {
      return 'isHttpError' in object;
    };

    const codeIsNotFound = (object: any): object is [string, string] => {
      return object[0] === object[1];
    };

    if (isErrorResponse(response) || codeIsNotFound(response)) return null;

    return response;
  }, false);
}

function cancelShipment(shipmentId: string, hasAssociatedPickup: boolean) {
  return helper.dispatchAsyncAction(async dispatch => {
    const response = await apis.shipment.cancelShipment(shipmentId, hasAssociatedPickup);

    let errorMessage = 'Error on Shipment canceling';

    if (hasAssociatedPickup) {
      if (response?.shipmentCanceled && response?.pickupCanceled) return true;

      if (response?.shipmentCanceled && !response?.pickupCanceled) {
        errorMessage = 'Error on associated pickup canceling';
      }
    } else {
      if (response?.shipmentCanceled) return true;
    }

    const error = getErrorFromResponse(response, errorMessage);

    dispatch(helper.getAction(APP_ERROR, {error}));

    return false;
  });
}

//helper methods

function getErrorFromResponse(response: PrepareShipmentResponse | CancelShipmentResponse, errorMessage) {
  let errors = response?.errors;

  if (errors?.length > 0) {
    errorMessage = '';

    for (let i = 0; i <= errors.length - 1; i++) {
      errorMessage += errors[i].message;

      if (i !== errors.length - 1) {
        errorMessage += '</br>';
      }
    }
  }

  return errorMessage;
}

import React from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';
import {get} from 'lodash';
import {useSelector} from 'react-redux';

import {AppState} from 'reducers';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import TextInput from 'components/common/TextInput';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import TogglerWithLabels from 'components/common/TogglerWithLabels';
import FormLabel from 'components/common/FormLabel';
import PhysicalAddress from 'components/common/PhysicalAddress';
import SelectInput from 'components/common/SelectInput/SelectInput';
import Flex from 'components/common/Flex';

import {mediaQueries} from 'styles/shared';

const StyledContainer = styled(Container)`
  @media ${mediaQueries.laptopMin} {
    height: 100%;
  }
`;

interface Props {
  origin: StandaloneOrigin;
  errors: object;
  onChange: (field: string, value: any) => void;
}

function SendingFrom({origin, errors, onChange}: Props) {
  const countryList = useSelector((state: AppState) => state.shipmentOptions.countryList);

  function onAddressChange(field, value) {
    onChange('address', {...origin.address, [field]: value});
  }

  function onAddressSelect(address) {
    let newAddress = {...origin.address, ...address};

    onChange('address', newAddress);
  }

  function render() {
    return (
      <StyledContainer>
        <FormHeader>Sending from:</FormHeader>

        <SelectInput
          name="country"
          label="Select Country"
          required={true}
          options={countryList}
          value={origin.country}
          onChange={onChange}
          error={get(errors, 'origin.country', '')}
        />

        <TextInput
          name="companyName"
          label="Company Name"
          placeholder="ABC Inc."
          required={true}
          value={origin.companyName}
          onChange={onChange}
          error={get(errors, 'origin.companyName', '')}
        />

        <TextInput
          name="contactName"
          label="Contact Name"
          placeholder="John Doe"
          required={true}
          value={origin.contactName}
          onChange={onChange}
          error={get(errors, 'origin.contactName', '')}
        />

        <PhysicalAddress
          autocompleteId="origin-autocomplete"
          label="Pickup Address"
          address={origin.address}
          countrySelected={origin.country}
          oneColumnDisplay={true}
          path="origin"
          errors={errors}
          onChange={onAddressChange}
          onAddressSelect={onAddressSelect}
        />

        <TextInput
          name="email"
          type="email"
          label="Email Address"
          required={true}
          placeholder="john.doe@gmail.com"
          value={origin.email}
          onChange={onChange}
          error={get(errors, 'origin.email', '')}
        />

        <Row>
          <Col lg={7}>
            <PhoneNumberInput
              name="phoneNumber"
              label="Phone Number"
              required={true}
              countryCode={origin.country}
              value={origin.phoneNumber}
              onChange={onChange}
              error={get(errors, 'origin.phoneNumber', '')}
            />
          </Col>
          <Col lg={5}>
            <TextInput
              name="phoneNumberExtension"
              label="Extension"
              placeholder="000"
              value={origin.phoneNumberExtension}
              onChange={onChange}
              error={get(errors, 'origin.phoneNumberExtension', '')}
            />
          </Col>
        </Row>

        <Flex justify="space-between" align="flex-start">
          <FormLabel required={true}>Is it Residential?</FormLabel>
          <TogglerWithLabels
            name="isResidential"
            value={origin.isResidential}
            namePrefix="standalone-residential"
            onToggle={onChange}
          />
        </Flex>
      </StyledContainer>
    );
  }

  return render();
}

export default SendingFrom;

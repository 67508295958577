import React, {useRef, useState} from 'react';
import styled, {css} from 'styled-components/macro';
import {isNil} from 'lodash';

import UNIT from 'constants/units';

import validationHelper from 'helpers/validationHelper';
import unitHelper from 'helpers/unitHelper';
import formatHelper from 'helpers/formatHelper';

import Flex from 'components/common/Flex';
import FormLabelContainer from 'components/common/InputComponents/FormLabelContainer';
import InputError from 'components/common/InputComponents/InputError';

import inputStyles from 'styles/customization/input';
import {colors} from 'styles/shared';

const StyledInputContainer = styled.div`
  ${inputStyles.inputWithUnits.inputContainer};
`;

const StyledInputBox = styled(Flex)`
  position: relative;
  width: 100%;
`;

const StyledInputStyled = styled.input`
  border: 0.1rem solid ${props => (props.error ? colors.red_main : colors.darkBlue_light)};
  ${inputStyles.common.inputFieldWithUnits};

  ${props =>
    props.isWarning &&
    css`
      border-color: ${colors.orange_main};
    `}
`;

const StyledSelect = styled.div`
  border: 0.1rem solid ${props => (props.error ? colors.red_main : colors.darkBlue_light)};
  ${inputStyles.common.inputDropdown};

  ${props =>
    props.isWarning &&
    css`
      border-color: ${colors.orange_main};
    `}

  ${props => props.isFocused && inputStyles.common.inputFocus}
`;

interface Props extends BaseProps {
  name: string;
  label?: string;
  onChange: OnChangeHandler;
  value: any;
  measurementSystem: string;
  isWeightUnit?: boolean;
  tooltip: string | JSX.Element;
  error?: string | ValidationError[];
  placeholder?: string;
  required?: boolean;
  skipTabForUnits?: boolean;
  disabled?: boolean;
  isWarning?: boolean;
}

InputWithUnits.defaultProps = {
  required: false,
  tooltip: '',
  placeholder: '00',
  className: '',
  skipTabForUnits: true,
  disabled: false,
  isWarning: false,
  isWeightUnit: false
};

function InputWithUnits({
  name,
  label,
  onChange,
  value,
  error,
  required,
  measurementSystem,
  tooltip,
  placeholder,
  className,
  skipTabForUnits,
  disabled,
  isWarning,
  isWeightUnit
}: Props) {
  const [isInitialValue, setIsInitialValue] = useState(true);
  const [focused, setFocused] = React.useState(false);

  const containerRef = useRef<HTMLInputElement>(null);

  let inputValue = null;

  if (isInitialValue) {
    inputValue = value || '';
  } else {
    if (!isNil(value)) {
      inputValue = value;
    }
  }

  const inputOnChange = e => {
    let val = e.target.value;

    val = String(val).replace(/[/-]/g, '');

    onChange(name, val);
  };

  const onBlur = e => {
    if (String(value).includes(".") && String(value).split(".")[1].length > 3) {
      value = formatHelper.calculateAccuratePrecision(Number(value), 3);
    }

    onChange(name, value);
    setIsInitialValue(false);
    if (focused) setFocused(false);
  };

  const onFocus = () => {
    setFocused(true);
  };

  const inputError: string = validationHelper.getInputError(error);

  let shiftRight = '0';
  if (containerRef?.current?.clientWidth) shiftRight = `${containerRef?.current?.clientWidth / 10}rem`;

  const tabIndex = skipTabForUnits ? '-1' : '0';

  const unitAbbreviation: string = unitHelper.getUnitFromMeasurementSystem(measurementSystem, isWeightUnit);
  let unit = unitAbbreviation;
  if (unit === UNIT.LENGTH_INCH) unit = UNIT.INCH_LABEL;
  if (unit === UNIT.MASS_POUND) unit = UNIT.POUND_LABEL;

  return (
    <StyledInputContainer className={className} ref={containerRef}>
      {label && (
        <FormLabelContainer label={label} name={name} required={required} disabled={disabled} tooltip={tooltip} />
      )}

      <Flex>
        <StyledInputBox shiftRight={shiftRight}>
          <StyledInputStyled
            type="number"
            min="0"
            step="0.001"
            name={name}
            value={inputValue}
            placeholder={placeholder}
            onChange={inputOnChange}
            disabled={disabled}
            onBlur={onBlur}
            autoComplete="nope"
            isWarning={isWarning}
            error={inputError}
            onFocus={onFocus}
          />
        </StyledInputBox>

        <StyledSelect
          id={name}
          name={name}
          value={unit}
          disabled={disabled}
          tabIndex={tabIndex}
          isWarning={isWarning}
          error={inputError}
          isFocused={focused}>
          {unit}
        </StyledSelect>
      </Flex>

      {inputError && <InputError>{inputError}</InputError>}
    </StyledInputContainer>
  );
}

export default InputWithUnits;

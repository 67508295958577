import React from 'react';

function ITNTooltip() {
  return (
    <span>
      If you do not have ITN number, please type EIN <br />
      number so that DHL can file EEI on your behalf.
    </span>
  );
}

export default ITNTooltip;

import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';

import TIMER from 'constants/timers';

import dateHelper from 'helpers/dateHelper';
import config from 'config';
import utils from 'helpers/utils';

import FormLabelContainer from 'components/common/InputComponents/FormLabelContainer';
import Flex from 'components/common/Flex';
import AppIcon from 'components/common/AppIcon';
import Tooltip from 'components/common/Tooltip';

import {colors, mainFont, secondaryFont} from 'styles/shared';

const StyledCalendarContainer = styled(Flex)`
  margin: 2rem 0;
`;

const StyledLabelContainer = styled(FormLabelContainer)`
  margin-bottom: 2rem;
`;

const StyledDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMonths = styled.div`
  max-width: 26rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.2rem;
`;

const StyledMonth = styled.div`
  font-weight: bold;
  color: ${colors.darkBlue_main};
  ${mainFont};
`;

const StyledIconContainer = styled.div`
  cursor: pointer;
  user-select: none;
`;

const StyledWeekdays = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const StyledWeekday = styled.div`
  font-weight: bold;
  color: ${colors.darkBlue_main};
  ${mainFont};
  width: calc(100% / 7);
  text-align: center;
`;

const StyledDays = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const StyledDayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% / 7);
  flex-basis: calc(100% / 7);
  margin-bottom: 1rem;
  height: 4rem;

  .calendar-tooltip {
    position: absolute;
    transform: translateY(-140%);
  }
`;

const StyledDay = styled.div`
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.selected ? colors.white : props.isToday ? colors.orange_main : colors.darkBlue_main)};
  border: ${props => (props.isToday ? `0.1rem solid ${colors.orange_main}` : 'none')};
  border-radius: 50%;
  background-color: ${props => (props.selected ? colors.orange_main : 'none')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${secondaryFont};
  color: ${props => props.disabled && colors.darkBlue_light};
  user-select: none;

  &:hover {
    background-color: ${props => !props.disabled && !props.selected && !props.isToday && colors.grey_concrete};
    border-radius: 50%;
  }
`;

const StyledEvent = styled.div`
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  content: ' ';
  display: block;
  background: ${props => (props.active ? colors.orange_main : colors.darkBlue_light)};
  margin-top: 0.3rem;
`;

interface Props {
  name: string;
  label: string;
  tooltip: string | JSX.Element;
  confirmedDates: any[];
  required?: boolean;
  value: any;
  onChange: OnChangeHandler;
}

Calendar.defaultProps = {
  tooltip: '',
  required: false
};

function Calendar({name, label, required, tooltip, value, onChange, confirmedDates}: Props) {
  const weekdays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const [days, setDays] = useState([]);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [readyToRender, setReadyToRender] = useState(false);

  const timer = useRef<NodeJS.Timeout | null>(null);
  const delayTimer = useRef<NodeJS.Timeout | null>(null);
  const tooltipRef = useRef<any>();

  useEffect(() => {
    let today = new Date();

    let initialMonth = dateHelper.getMonthFromDate(today);
    let initialYear = dateHelper.getYearFromDate(today);

    let initialDays = getDays(initialMonth, initialYear);

    setDays(initialDays);
    setMonth(initialMonth);
    setYear(initialYear);
    setReadyToRender(true);

    return () => {
      if (timer?.current) clearInterval(timer.current);
      if (delayTimer?.current) clearInterval(delayTimer.current);
    };
  }, []);

  // Handles case of shipment/pickup prepped on a weekend
  useEffect(() => {
    if (dateHelper.isSaturdayDay(value) || dateHelper.isSundayDay(value)) {
      onDateChange(dateHelper.getNextMonday(value), false);
    }
  }, []);

  useEffect(() => {
    if (!readyToRender) return;

    setTooltipVisible(true);
  }, [readyToRender]);

  useEffect(() => {
    if (!readyToRender) return;

    let newDays = getDays(month, year);
    setDays(newDays);

    timer.current = utils.setTimeout(() => setTooltipVisible(false), TIMER.CALENDAR_TOOLTIP);
  }, [month, value]);

  useEffect(() => {
    if (!tooltipRef.current) return;

    if (tooltipVisible) {
      utils.setTimeout(() => ReactTooltip.show(tooltipRef.current), TIMER.CALENDAR_TOOLTIP_DELAY);
    } else {
      ReactTooltip.hide(tooltipRef.current);
    }
  }, [tooltipVisible]);

  function getDays(month: number, year: number) {
    let days: any = [];

    let firstDayOfTheMonth = new Date(year, month, 1);

    let initialDay = firstDayOfTheMonth;

    while (!dateHelper.isSundayDay(initialDay)) {
      initialDay = dateHelper.subDays(initialDay, 1);
    }

    let currentDay = initialDay;

    for (let i = 1; i <= 42; i++) {
      days.push({date: currentDay, label: currentDay.getDate()});
      currentDay = dateHelper.addDays(currentDay, 1);
    }

    return days;
  }

  function onMonthChange(forward = true) {
    let nextMonth = month;
    let nextYear = year;

    if (forward) {
      nextMonth++;

      if (nextMonth > 11) {
        nextMonth = 0;
        nextYear++;
      }
    } else {
      nextMonth--;

      if (nextMonth < 0) {
        nextMonth = 11;
        nextYear--;
      }
    }

    setMonth(nextMonth);
    setYear(nextYear);
  }

  function onDateChange(date, disabled) {
    if (disabled) return;

    let newMonth = dateHelper.getMonthFromDate(date);
    let newYear = dateHelper.getYearFromDate(date);

    setMonth(newMonth);
    setYear(newYear);

    onChange(name, date);
  }

  function isDisabledDate(date, isToday) {
    const isSaturday = dateHelper.isSaturdayDay(date);
    const isSunday = dateHelper.isSundayDay(date);

    if (isSaturday || isSunday) return true;

    if (isToday) return false;

    if (dateHelper.isPastDate(date)) return true;

    if (dateHelper.inNextFiveDays(date)) return false;

    return true;
  }

  function render() {
    const monthLabel = months[month].toUpperCase();

    return (
      <StyledCalendarContainer direction="column">
        {label && <StyledLabelContainer label={label} name={name} required={required} tooltip={tooltip} />}

        <StyledDatePicker>
          <StyledMonths>
            <StyledIconContainer onClick={() => onMonthChange(false)}>
              <AppIcon name="left-arrow" />
            </StyledIconContainer>

            <StyledMonth>
              {monthLabel} {year}
            </StyledMonth>

            <StyledIconContainer onClick={onMonthChange}>
              <AppIcon name="right-arrow" />
            </StyledIconContainer>
          </StyledMonths>

          <StyledWeekdays>
            {weekdays.map(day => {
              return <StyledWeekday key={day}>{day}</StyledWeekday>;
            })}
          </StyledWeekdays>

          <StyledDays>
            {days.map((day: any) => {
              const date = day.date;

              let isToday = dateHelper.todayDate(date);

              let selected = dateHelper.isEqualDates(date, value);

              let disabled = isDisabledDate(date, isToday);

              let formatedDate = dateHelper.displayDate(date, config.format.datePicker);

              let event = confirmedDates.find(d => d === formatedDate);

              let index = confirmedDates.findIndex(d => d === formatedDate);

              let showTooltip = false;
              if (tooltipVisible && index === 0) showTooltip = true;

              const tooltipId = `${name}-${day.label}`;

              return (
                <StyledDayContainer key={date}>
                  <StyledDay
                    data-tip
                    data-for={tooltipId}
                    ref={index === 0 ? tooltipRef : null}
                    isToday={isToday}
                    selected={selected}
                    disabled={disabled}
                    onClick={() => onDateChange(date, disabled)}>
                    {day.label}
                  </StyledDay>

                  {showTooltip && (
                    <Tooltip id={tooltipId} placement="top">
                      You have a Pickup scheduled on this day.
                    </Tooltip>
                  )}

                  {event && <StyledEvent active={selected} />}
                </StyledDayContainer>
              );
            })}
          </StyledDays>
        </StyledDatePicker>
      </StyledCalendarContainer>
    );
  }
  return render();
}

export default Calendar;

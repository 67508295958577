import React from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import CurrencyInput from 'components/common/CurrencyInput';
import Flex from 'components/common/Flex';

import {mediaQueries} from 'styles/shared';

const StyledContainer = styled(Container)`
  padding: 2.6rem 0 2.4rem 2.6rem;
  margin-bottom: ${props => props.removeMargin ? "0" : "3rem"};

  @media ${mediaQueries.mobileMax} {
    padding: 2.6rem;
  }
`;

const StyledHeader = styled(FormHeader)`
  margin-bottom: 2rem;
`;

const StyledImageContainer = styled(Flex)`
  height: 100%;
`;

interface Props {
  insuranceValue: number;
  currency: string;
  onChange: OnChangeHandler;
  error?: string;
  isDomestic: boolean;
}

function InsuranceSection({insuranceValue, currency, onChange, error, isDomestic}: Props) {
  const label = isDomestic ? 'Insured Value (per package)' : 'Insured Value';

  return (
    <StyledContainer removeMargin={isDomestic}>
      <Row>
        <Col lg={6}>
          <StyledHeader>Insurance</StyledHeader>

          <CurrencyInput
            name="insuranceValue"
            label={label}
            value={insuranceValue}
            placeholder="00.00"
            currency={currency}
            onChange={onChange}
            error={error}
          />
        </Col>
        <Col lg={6}>
          <StyledImageContainer justify="center" align="flex-end">
            <img alt="Insurance" src="/images/standalone/quote-insurance.png" />
          </StyledImageContainer>
        </Col>
      </Row>
    </StyledContainer>
  );
}

export default InsuranceSection;

import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {get, last} from 'lodash';
import {useSelector} from 'react-redux';

import {AppState} from 'reducers';

import entities from 'domain/entities';

import rateService from 'services/rateService';

import RadioInput from 'components/common/RadioInput/RadioInput';
import TextInput from 'components/common/TextInput';
import RadioWithTextInput from 'components/common/RadioWithTextInput';
import CurrencyInput from 'components/common/CurrencyInput';
import TextAreaInput from 'components/common/TextAreaInput';
import CommodityManager from 'components/common/CommodityManager';
import Devider from '../../Devider';
import InvoiceItem from './InvoiceItem';
import ProhibitedItemsModal from './ProhibitedItemsModal';
import DeclaredValueCalculation from './DeclaredValueCalculation';
import CommodityPanel from './CommodityPanel';

import OptionalChargesTooltip from 'components/tooltips/OptionalChargesTooltip';
import PurposeOfShipmentTooltip from 'components/tooltips/PurposeOfShipmentTooltip';
import ProductDeclaredValueTooltip from 'components/tooltips/ProductDeclaredValueTooltip';
import ThirdPartyPayorTooltip from 'components/tooltips/ThirdPartyPayorTooltip';
import OtherRemarksTooltip from 'components/tooltips/OtherRemarksTooltip';
import PackageMarksTooltip from 'components/tooltips/PackageMarksTooltip';
import ReceiverReferenceNumberTooltip from 'components/tooltips/ReceiverReferenceNumberTooltip';
import InvoiceNumberTooltip from 'components/tooltips/InvoiceNumberTooltip';
import ShipmentReferenceNumberTooltip from 'components/tooltips/ShipmentReferenceNumberTooltip';
import ExportTypeTooltip from 'components/tooltips/ExportTypeTooltip';

import {colors, secondaryFont, mainContainerPadding} from 'styles/shared';

const StyledItemContainer = styled.div`
  background: ${props => (props.isOddRow ? colors.grey_concrete : 'inherit')};
  margin: 0 -${mainContainerPadding};
  padding: 0.1rem ${mainContainerPadding} 0;
`;

const StyledOtherRemarks = styled(RadioInput)`
  margin-bottom: 1.5rem;
`;

const StyledDeclaredValue = styled(CurrencyInput)`
  margin-bottom: 2rem;
`;

const StyledInsuranceCost = styled(CurrencyInput)`
  min-height: 8rem;
`;

const StyledMiscCost = styled(CurrencyInput)`
  margin: 0;
`;

const StyledComingSoon = styled.div`
  color: ${colors.red_main};
  ${secondaryFont};
`;

const StyledCurrencyInput = styled(CurrencyInput)`
  margin: 0;
  min-height: auto;
`;

interface Props {
  errors: object;
  onChange: any;
  onInsuranceChange: OnChangeHandler;
  countryList: any;
}

function InvoiceSection({errors, onChange, onInsuranceChange, countryList}: Props) {
  const draft = useSelector((state: AppState) => state.draft.current.data as Draft);
  const itemQuantityOptions = useSelector((state: AppState) => state.shipmentOptions.itemQuantityOptions);
  const invoiceTypeOptions = useSelector((state: AppState) => state.shipmentOptions.invoiceTypeOptions);
  const proformaInvoiceTypeOptions = useSelector((state: AppState) => state.shipmentOptions.proformaInvoiceTypeOptions);
  const exportTypeOptions = useSelector((state: AppState) => state.shipmentOptions.exportTypeOptions);

  const [prohibitedItemsModalVisible, setProhibitedItemsModalVisible] = useState(false);
  const [commodityIndex, setCommodityIndex] = useState<number | undefined>();

  const products = draft.shipmentDetails.products;

  const shipmentReferenceNumberOptions = [
    {
      value: true,
      label: 'Yes',
      element: (
        <TextInput
          name="shipmentReferenceNumber"
          size="small"
          placeholder="000000000"
          value={products.shipmentReferenceNumber}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.products.shipmentReferenceNumber', '')}
          isWarning={products.isShipmentReferenceNumberExists && !products.shipmentReferenceNumber}
        />
      )
    },
    {value: false, label: 'No'}
  ];

  const invoiceNumberOptions = [
    {
      value: true,
      label: 'Yes',
      element: (
        <TextInput
          name="invoiceNumber"
          size="small"
          placeholder="000000000"
          value={products.invoiceNumber}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.products.invoiceNumber', '')}
          isWarning={products.isInvoiceNumberExists && !products.invoiceNumber}
        />
      )
    },
    {value: false, label: 'No'}
  ];

  const receiverReferenceOptions = [
    {
      value: true,
      label: 'Yes',
      element: (
        <TextInput
          name="receiverReference"
          size="small"
          placeholder="PO number or Order name"
          value={products.receiverReference}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.products.receiverReference', '')}
          isWarning={products.isReceiverReferenceExists && !products.receiverReference}
        />
      )
    },
    {value: false, label: 'No'}
  ];

  const packageMarksOptions = [
    {
      value: true,
      label: 'Yes',
      element: (
        <TextInput
          name="packageMarks"
          size="small"
          placeholder="Describe Package Marks"
          value={products.packageMarks}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.products.packageMarks', '')}
          isWarning={products.isAnyPackageMarks && !products.packageMarks}
        />
      )
    },
    {value: false, label: 'No'}
  ];

  //Fix-API: use textInput for yes option
  const thirdPartyPayorInfoOptions = [
    {
      value: true,
      label: 'Yes',
      element: (
        <>
          {products.thirdPartyPayorInfo && (
            <StyledComingSoon>Coming Soon. Let Support know if you need this label now.</StyledComingSoon>
          )}
        </>
      ),
      elementPullRight: true
    },
    {value: false, label: 'No'}
  ];

  const insuranceOptions: RadioInputOption[] = [
        {
          value: true,
          label: 'Yes',
          element: (
            <StyledCurrencyInput
              name="insuranceValue"
              value={draft.additionalServices.insuranceValue}
              placeholder="What is the value that you want to insure?"
              currency={draft.currency}
              onChange={onInsuranceChange}
              onBlur={onInsuranceBlur}
              error={get(errors, 'additionalServices.insuranceValue', '')}
              isWarning={(draft.additionalServices.doYouNeedInsurance && !draft.additionalServices.insuranceValue) ||
                (draft.shipmentDetails.products.declaredShipmentValue < draft.additionalServices.insuranceValue)}
            />
          )
        },
        {value: false, label: 'No'}
  ];

  function onInsuranceBlur() {
    rateService.displaySurcharge('insurance');
  }


  function addInvoiceItem() {
    let newItems = [...products.items];

    const lastItem: ProductItem | undefined = last(newItems);

    const defaultItem = entities.draft.getDefaultInvoiceItem();

    if (lastItem) {
      defaultItem.units = lastItem.units;
    }

    newItems.push(defaultItem);

    onChange('items', newItems);
  }

  function updateInvoiceItem(i, field, value) {
    let newItems = [...products.items];

    let item = {...products.items[i]};
    item[field] = value;

    newItems[i] = item;

    onChange('items', newItems);
  }

  function removeInvoiceItem(index) {
    let newItems = [...products.items.slice(0, index), ...products.items.slice(index + 1)];

    onChange('items', newItems);
  }

  function toggleProhibitedItemsModal() {
    setProhibitedItemsModalVisible(!prohibitedItemsModalVisible);
  }

  function showCommodityManagerModal(index: number) {
    setCommodityIndex(index);
  }

  function closeCommodityManagerModal() {
    setCommodityIndex(undefined);
  }

  function loadLineItem(lineItem: LineItem) {
    if (commodityIndex === undefined) return;

    const newItems = [...products.items];

    newItems[commodityIndex] = {...lineItem};

    onChange('items', newItems);

    closeCommodityManagerModal();
  }

  function renderNewInvoiceSection() {
    const invoiceType = products.invoiceType;

    return (
      <>
        <RadioInput
          name="invoiceType"
          label="Purpose of the Shipment?"
          required={true}
          tooltip={PurposeOfShipmentTooltip()}
          options={invoiceTypeOptions}
          value={invoiceType}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.products.invoiceType', '')}
        />

        {draft.isProformaInvoiceType && (
          <RadioInput
            name="purposeOfShipment"
            label="Please select one of the following"
            required={true}
            options={proformaInvoiceTypeOptions}
            value={products.purposeOfShipment}
            onChange={onChange}
            error={get(errors, 'shipmentDetails.products.purposeOfShipment', '')}
          />
        )}

        <RadioInput
          name="exportType"
          label="Type of Export"
          required={true}
          tooltip={ExportTypeTooltip()}
          options={exportTypeOptions}
          value={products.exportType}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.products.exportType', '')}
        />

        <RadioWithTextInput
          name="isShipmentReferenceNumberExists"
          label="Do you have Shipment Reference Number?"
          tooltip={ShipmentReferenceNumberTooltip()}
          options={shipmentReferenceNumberOptions}
          value={products.isShipmentReferenceNumberExists}
          onChange={onChange}
        />

        <RadioWithTextInput
          name="isInvoiceNumberExists"
          label="Do you have Invoice Number?"
          tooltip={InvoiceNumberTooltip()}
          options={invoiceNumberOptions}
          value={products.isInvoiceNumberExists}
          onChange={onChange}
        />

        <RadioWithTextInput
          name="isReceiverReferenceExists"
          label="Do you have Receiver Reference?"
          tooltip={ReceiverReferenceNumberTooltip()}
          options={receiverReferenceOptions}
          value={products.isReceiverReferenceExists}
          onChange={onChange}
        />

        <RadioWithTextInput
          name="isAnyPackageMarks"
          label="Do you have Package Marks?"
          tooltip={PackageMarksTooltip()}
          options={packageMarksOptions}
          value={products.isAnyPackageMarks}
          onChange={onChange}
        />

        <StyledOtherRemarks
          name="isAnyOtherRemarks"
          label="Do you have other Remarks that need to be included on the Customs Invoice?"
          tooltip={OtherRemarksTooltip()}
          value={products.isAnyOtherRemarks}
          onChange={onChange}
        />

        {products.isAnyOtherRemarks && (
          <TextAreaInput
            name="otherRemarks"
            placeholder="Write any remark you want to include in Customs Invoice"
            value={products.otherRemarks}
            onChange={onChange}
            error={get(errors, 'shipmentDetails.products.otherRemarks', '')}
          />
        )}

        <RadioWithTextInput
          name="thirdPartyPayorInfo"
          label="Do you have 3rd Party Payor info that needs to be included on the customs invoice?"
          tooltip={ThirdPartyPayorTooltip()}
          options={thirdPartyPayorInfoOptions}
          value={products.thirdPartyPayorInfo}
          onChange={onChange}
        />

        <Devider />
      </>
    );
  }

  function renderAdditionalSection() {
    return (
      <>
        <CurrencyInput
          name="freightCost"
          label="Would you like to add any optional Misc. Charges to the Customs Invoice?"
          tooltip={OptionalChargesTooltip()}
          placeholder="Eg. Freight cost"
          value={products.freightCost}
          currency={draft.currency}
          onChange={onChange}
        />

        <StyledInsuranceCost
          name="insuranceCost"
          value={products.insuranceCost}
          currency={draft.currency}
          placeholder="Eg. Insurance cost"
          onChange={onChange}
        />

        <StyledMiscCost
          name="miscCost"
          value={products.miscCost}
          currency={draft.currency}
          placeholder="Eg. Misc. cost"
          onChange={onChange}
        />
      </>
    );
  }

  function render() {
    const commodityManagerVisible = commodityIndex !== undefined ? true : false;

    return (
      <>
        {renderNewInvoiceSection()}

          <>
            {products.items.map((item: ProductItem, index) => {
              const isLastIndex = index === products.items.length - 1;

              const isOdd = index % 2 === 1;

              const deleteButtonVisible = products.items.length > 1;

              return (
                <StyledItemContainer key={index} isOddRow={isOdd}>
                  <CommodityPanel withMargin={index > 0} openModal={() => showCommodityManagerModal(index)} />

                  <InvoiceItem
                    key={index}
                    index={index}
                    isLastIndex={isLastIndex}
                    deleteButtonVisible={deleteButtonVisible}
                    item={item}
                    showHiddenSection={draft.isLicenseRequired}
                    errors={errors}
                    currency={draft.currency}
                    measurementSystem={draft.measurementSystem}
                    countryList={countryList}
                    itemQuantityOptionsList={itemQuantityOptions}
                    onAddItem={addInvoiceItem}
                    onUpdateItem={updateInvoiceItem}
                    onRemoveItem={removeInvoiceItem}
                    toggleProhibitedItemsModal={toggleProhibitedItemsModal}
                  />
                </StyledItemContainer>
              );
            })}

            <DeclaredValueCalculation
              items={products.items}
              totalValue={draft.invoiceTotalSum}
              currency={draft.currency}
            />
          </>

        <StyledDeclaredValue
          name="declaredShipmentValue"
          label="Declared value of the Shipment"
          value={products.declaredShipmentValue}
          currency={draft.currency}
          required={true}
          tooltip={ProductDeclaredValueTooltip()}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.products.declaredShipmentValue', '')}
        />

      <RadioWithTextInput
        name="doYouNeedInsurance"
        label="Would you like to insure this Shipment?"
        options={insuranceOptions}
        value={draft.additionalServices.doYouNeedInsurance}
        onChange={onInsuranceChange}
      />

          <>
            <Devider />

            {renderAdditionalSection()}

            {prohibitedItemsModalVisible && (
              <ProhibitedItemsModal visible={prohibitedItemsModalVisible} close={toggleProhibitedItemsModal} />
            )}
          </>

        {commodityManagerVisible && (
          <CommodityManager
            visible={commodityManagerVisible}
            close={closeCommodityManagerModal}
            currency={draft.currency}
            onLoad={loadLineItem}
          />
        )}
      </>
    );
  }

  return render();
}

export default InvoiceSection;

import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  return (
    <svg width="44" height="44" fill="none" viewBox="0 0 44 44">
      <circle cx="22" cy="22" r="14" stroke={colors.green_main} strokeWidth="2"></circle>
      <path
        fill={colors.darkBlue_main}
        d="M27.164 25.1c0 1.067-.373 1.96-1.12 2.68-.747.72-1.747 1.16-3 1.32v2.2h-1.68v-2.16a9.357 9.357 0 01-2.5-.52 6.494 6.494 0 01-2.02-1.2l.74-1.54c.68.56 1.373.967 2.08 1.22a6.83 6.83 0 002.32.38c.987 0 1.747-.2 2.28-.6.547-.4.82-.973.82-1.72 0-.44-.147-.793-.44-1.06a2.723 2.723 0 00-1.06-.64 26.61 26.61 0 00-1.78-.52c-1-.253-1.813-.507-2.44-.76a4.138 4.138 0 01-1.62-1.18c-.44-.533-.66-1.24-.66-2.12 0-1.08.387-1.993 1.16-2.74.787-.76 1.82-1.213 3.1-1.36V12.6h1.68v2.18c.773.08 1.5.267 2.18.56.693.293 1.28.667 1.76 1.12l-.74 1.52c-1.2-1.04-2.547-1.56-4.04-1.56-.92 0-1.653.213-2.2.64-.547.413-.82.987-.82 1.72 0 .493.153.9.46 1.22.307.307.687.547 1.14.72.453.16 1.073.333 1.86.52.973.24 1.76.48 2.36.72.6.24 1.113.613 1.54 1.12.427.507.64 1.18.64 2.02z"></path>
    </svg>
  );
}

export default Icon;

import React from 'react';

function ThirdPartyPayorTooltip() {
  return (
    <span>
      Only enter if the party paying for <br />
      the shipment is not either the <br />
      shipper or receiver.
    </span>
  );
}

export default ThirdPartyPayorTooltip;

import {isEmpty, uniq} from 'lodash';

import dateHelper from 'helpers/dateHelper';
import config from 'config';

export default {
  findRelatedPickup,
  getConfirmedDates,
  getPickupLocationOptions
};

function findRelatedPickup(pickups: Pickup[], entityId: string) {
  if (isEmpty(pickups)) return null;

  for (let pickup of pickups) {
    let shipmentExists = pickup.shipments.find(shipmentId => shipmentId === entityId);

    if (shipmentExists) return pickup;
  }

  return null;
}

function getConfirmedDates(pickups: Pickup[]): string[] {
  let initialConfirmedDates: string[] = [];

  pickups.forEach(pickup => {
    let confirmedDate = dateHelper.displayDate(pickup.date, config.format.datePicker);
    initialConfirmedDates.push(confirmedDate);
  });

  return uniq(initialConfirmedDates);
}

function getPickupLocationOptions() {
  return [
    {value: 'Reception', label: 'Reception'},
    {value: 'Back door', label: 'Back Door'},
    {value: 'Front door', label: 'Front Door'},
    {value: 'Loading dock', label: 'Loading Dock'},
    {value: 'Other', label: 'Other'}
  ];
}

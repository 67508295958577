import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  const color = colors.darkBlue_main;

  return (
    <svg width="27" height="33" fill="none" viewBox="0 0 27 33">
      <path
        stroke={color}
        strokeLinecap="round"
        d="M21 .572H3a2.5 2.5 0 00-2.5 2.5v26a2.5 2.5 0 002.5 2.5h18a2.5 2.5 0 002.5-2.5v-26a2.5 2.5 0 00-2.5-2.5z"></path>
      <rect width="10" height="1" x="3" y="23.072" fill={color} rx="0.5"></rect>
      <rect width="18" height="1" x="3" y="4.072" fill={color} rx="0.5"></rect>
      <rect width="4" height="1" x="3" y="8.072" fill={color} rx="0.5"></rect>
      <rect width="5" height="1" x="13" y="8.072" fill={color} rx="0.5"></rect>
      <rect width="6" height="1" x="3" y="12.072" fill={color} rx="0.5"></rect>
      <rect width="4" height="1" x="3" y="19.072" fill={color} rx="0.5"></rect>
      <rect width="4" height="1" x="13" y="19.072" fill={color} rx="0.5"></rect>
      <rect width="8" height="1" x="3" y="15.072" fill={color} rx="0.5"></rect>
      <rect width="3" height="1" x="18" y="15.072" fill={color} rx="0.5"></rect>
      <rect width="10" height="1" x="3" y="27.072" fill={color} rx="0.5"></rect>
      <rect width="5" height="4" x="15.501" y="23.572" stroke={color} rx="0.5"></rect>
      <mask id="path-13-inside-1_5007:19495" fill={colors.white}>
        <path
          fillRule="evenodd"
          d="M9 20.272a.563.563 0 000 .799c.22.22.58.22.8 0l5.2-5.182 5.185 5.201c.22.221.579.221.8 0a.568.568 0 000-.801L15.8 15.091l5.218-5.201a.563.563 0 000-.799.568.568 0 00-.801 0l-5.216 5.199-5.203-5.218a.564.564 0 00-.799 0 .568.568 0 000 .801l5.2 5.215L9 20.272z"
          clipRule="evenodd"></path>
      </mask>
      <path
        fill={colors.white}
        fillRule="evenodd"
        d="M9 20.272a.563.563 0 000 .799c.22.22.58.22.8 0l5.2-5.182 5.185 5.201c.22.221.579.221.8 0a.568.568 0 000-.801L15.8 15.091l5.218-5.201a.563.563 0 000-.799.568.568 0 00-.801 0l-5.216 5.199-5.203-5.218a.564.564 0 00-.799 0 .568.568 0 000 .801l5.2 5.215L9 20.272z"
        clipRule="evenodd"></path>
      <path
        fill={color}
        d="M9 21.071l.705-.708L9 21.07zm0-.799l-.707-.708.706.708zm.8.799l-.705-.708.706.708zm5.2-5.182l.707-.706-.705-.708-.709.706.706.708zm5.185 5.201l-.708.706.708-.706zm.8 0l.707.706-.708-.706zm0-.801l.707-.706-.708.706zM15.8 15.091l-.706-.709-.709.707.707.708.708-.706zm5.218-5.201l-.706-.708.706.708zm0-.799l.706-.708-.706.708zm-.801 0l.705.708-.705-.708zm-5.216 5.199l-.708.706.706.707.708-.705-.706-.708zM9.799 9.072l-.708.706.708-.706zm-.799 0l.709.706L9 9.072zm0 .801l-.708.706L9 9.873zm5.2 5.215l.706.709.709-.707-.706-.708-.709.706zm-4.495 5.275c.17.17.17.447 0 .617l-1.412-1.416a1.563 1.563 0 000 2.215l1.412-1.416zm-.61 0a.432.432 0 01.61 0l-1.412 1.416c.612.61 1.602.61 2.214 0l-1.412-1.416zm5.198-5.182l-5.198 5.182 1.412 1.416 5.198-5.181-1.412-1.417zm6.6 5.203l-5.186-5.2-1.416 1.412 5.186 5.2 1.416-1.412zm-.617 0c.17-.17.447-.17.617 0l-1.416 1.412a1.564 1.564 0 002.215 0l-1.416-1.412zm0 .611a.432.432 0 010-.61l1.416 1.411c.61-.611.61-1.602 0-2.213l-1.416 1.412zm-5.183-5.198l5.183 5.198 1.416-1.412-5.183-5.198-1.416 1.412zm5.22-6.615l-5.218 5.2 1.412 1.417 5.218-5.2-1.412-1.417zm0 .617a.437.437 0 010-.617l1.412 1.416a1.563 1.563 0 000-2.215l-1.412 1.416zm.61 0a.432.432 0 01-.61 0l1.412-1.416a1.568 1.568 0 00-2.213 0l1.411 1.416zm-5.215 5.199l5.215-5.199-1.411-1.416-5.216 5.198 1.412 1.417zM9.09 9.778l5.203 5.217 1.416-1.412-5.203-5.217-1.416 1.412zm.618 0a.436.436 0 01-.618 0l1.416-1.412a1.564 1.564 0 00-2.215 0L9.71 9.778zm0-.611a.432.432 0 010 .611L8.292 8.366a1.568 1.568 0 000 2.213L9.71 9.167zm5.2 5.215l-5.2-5.215-1.417 1.412 5.2 5.215 1.417-1.412zM9.705 20.98l5.201-5.184-1.412-1.416-5.2 5.184 1.411 1.416z"
        mask="url(#path-13-inside-1_5007:19495)"></path>
    </svg>
  );
}

export default Icon;

import React, {useEffect} from 'react';

import authService from 'services/authService';

import ErrorHandler from 'components/error_handlers/ErrorHandler';

function ResetPasswordPage() {
  const message = authService.getResetPasswordError() || 'Invalid password reset link';

  useEffect(() => {
    //clear reset password error on page load
    authService.saveResetPasswordError(null);
  }, []);

  return <ErrorHandler errorText={message} />;
}

export default ResetPasswordPage;

import CARRIER from "constants/carriers";
import WHO_PAYS from "./whoPays";

const WHO_PAYS_TOOLTIP_COPY = {
  [WHO_PAYS.RECEIVER]: {
    [CARRIER.DHL]: '“Receiver” refers to your receiver’s import or export number.',
    [CARRIER.UPS]: '“Receiver” refers to your receiver’s UPS account number.',
    [CARRIER.FED_EX]: '“Receiver” refers to your receiver’s FedEx account number.'
  },
  [WHO_PAYS.THIRD_PARTY]: {
    [CARRIER.DHL]: '“Third Party” refers to any import/export number other than your receiver’s or your own.',
    [CARRIER.UPS]: '“Third Party” refers to any UPS account number other than your receiver’s or your own.',
    [CARRIER.FED_EX]: '“Third Party” refers to any FedEx account number other than your receiver’s or your own.'
  }
};

export default WHO_PAYS_TOOLTIP_COPY;

import React from 'react';
import styled from 'styled-components';
import {isEmpty} from 'lodash';

import CARRIER from 'constants/carriers';

import Flex from 'components/common/Flex';
import FormHeader from 'components/common/FormHeader';
import CheckBoxInput from 'components/common/CheckboxInput';

import {mediaQueries} from 'styles/shared';

const StyledCheckboxesContainer = styled(Flex)`
  margin-bottom: 2.7rem;

  @media ${mediaQueries.mobileMax} {
    padding: 0 1.6rem;
    flex-direction: column;
    align-items: initial;
  }
`;

const StyledCheckbox = styled(CheckBoxInput)`
  margin-left: 1.5rem;

  @media ${mediaQueries.mobileMax} {
    margin-left: 0.8rem;
  }
`;

interface Props {
  rates: Rate[];
  selectedCarriers: string[];
  onToggle: (field: string) => void;
}

function CarrierCheckboxes({rates, selectedCarriers, onToggle}: Props) {
  const upsRates = rates.filter(rate => rate.carrier === CARRIER.UPS);

  return (
    <StyledCheckboxesContainer align="center">
      <FormHeader>Select Carrier:</FormHeader>

      {!isEmpty(upsRates) && (
        <StyledCheckbox
          name={CARRIER.UPS}
          label="UPS"
          value={selectedCarriers.includes(CARRIER.UPS)}
          onToggle={onToggle}
        />
      )}
    </StyledCheckboxesContainer>
  );
}

export default CarrierCheckboxes;

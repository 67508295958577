import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components/macro';
import {get} from 'lodash';

import dateHelper from 'helpers/dateHelper';
import validationHelper from 'helpers/validationHelper';

import pickupService from 'services/pickupService';

import entities from 'domain/entities';
import draftEntity from 'domain/entities/draftEntity';

import pickupActions from 'actions/pickupActions';

import FormHeader from 'components/common/FormHeader';
import Button from 'components/common/Button';
import Calendar from 'components/common/Scheduler/Calendar';
import Slider from 'components/common/Scheduler/Slider';
import RadioInput from 'components/common/RadioInput/RadioInput';
import TextAreaInput from 'components/common/TextAreaInput';
import Flex from 'components/common/Flex';
import CalendarTooltip from 'components/tooltips/CalendarTooltip';

import * as Styled from 'styles/modal';

import {colors, mainFont, buttonLeftMargin, buttonLeftRightMargin} from 'styles/shared';
import CARRIER from '../../../constants/carriers';
import NumberInput from '../NumberInput/NumberInput';

const StyledDate = styled.div`
  margin: 2rem 0;
  ${mainFont};
  color: ${colors.darkBlue_main};
`;

interface ReschedulePickupModalProps {
  title: string;
  visible: boolean;
  pickupId?: string;
  pickup?: PickupData;
  carrier?: string;
  shipment?: Shipment;
  pickupShipments?: String[];
  confirmedDates: Array<any>;
  isDateReadOnly?: boolean;
  close: () => void;
  onSave: (PickupData) => void;
}

function SavePickupModal({
  title,
  visible,
  pickup,
  carrier,
  shipment,
  confirmedDates,
  isDateReadOnly,
  close,
  onSave
}: ReschedulePickupModalProps) {
  const dispatch = useDispatch();

  const [pickupToSave, setPickupToSave] = useState<PickupData>();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [pickupCapability, setPickupCapability] = useState<PickupCapability>();

  useEffect(() => {
    if (!pickup) {
      setPickupToSave(entities.pickup.getDefault());
      return;
    }

    let initialPickupData: PickupData = {
      date: new Date(pickup.date),
      startTime: pickup?.startTime,
      endTime: pickup?.endTime,
      location: pickup.location,
      numOfPackages: pickup.numOfPackages,
      notes: pickup.notes
    };

    let initialPickup = entities.pickup.createFromData(initialPickupData);

    setPickupToSave(initialPickup);
  }, [pickup]);

  useEffect(() => {
    if (!shipment || !visible || !shipment.carrier.productCode) return;

    const checkPickupCapability = async () => {
      let data: CheckPickupCapabilityDto = {
        draft: draftEntity.mapFromShipment(shipment, []),
        carrier: carrier,
        shipmentId: shipment.id,
        carrierProduct: shipment.carrier.productCode
      };

      const response: any = await dispatch(pickupActions.checkPickupCapability(data, true));
      setPickupCapability(response as PickupCapability);
    }

    checkPickupCapability();
  }, [pickupToSave?.date, confirmedDates, shipment?.carrier.productCode]);

  useEffect(() => {
    if (!validateOnChange) return;

    validateData();
  }, [pickupToSave]);

  function onChange(field, value) {
    if (!pickupToSave) return;

    setPickupToSave({...pickupToSave, [field]: value});
    setButtonDisabled(false);
  }

  function onTimeChange(field, value: number[]) {
    if (!pickupToSave) return;

    let updatedPickupData = {...pickupToSave};
    updatedPickupData.startTime = value[0];
    updatedPickupData.endTime = value[1];

    setPickupToSave(updatedPickupData);
    setButtonDisabled(false);
  }

  function saveChanges() {
    if(!validateData()) {
      setValidateOnChange(true);
      return;
    }

    setValidateOnChange(false);

    onSave(pickupToSave);
  }

  function validateData(): boolean {
    const carrierProduct = shipment ? shipment.carrier.productCode : undefined;

    const validationResult: ValidationResult = validationHelper.validatePickupToSave(
      carrier,
      pickupToSave,
      carrierProduct
    );

    setErrors(validationResult.errors);

    return validationResult.valid;
  }

  function render() {
    if (!pickupToSave) return null;

    const displayDate = isDateReadOnly ? dateHelper.displayDate(pickupToSave?.date) : null;

    const disabled = buttonDisabled || !pickupToSave.location;

    return (
      <Styled.CustomModal show={visible} padding="3rem 2.6rem 2rem" onHide={close}>
        <FormHeader>{title}</FormHeader>

        {isDateReadOnly ? (
          <StyledDate>
            Shipment date: <strong>{displayDate}</strong>
          </StyledDate>
        ) : (
          <Calendar
            name="date"
            label="Shipment date"
            tooltip={CalendarTooltip()}
            confirmedDates={confirmedDates}
            value={pickupToSave?.date}
            onChange={onChange}
          />
        )}

        <Slider
          name="time"
          label="Pickup Time:"
          value={[pickupToSave.startTime, pickupToSave.endTime]}
          onChange={onTimeChange}
          carrier={carrier || ''}
          pickupCapability={pickupCapability}
        />

        <RadioInput
          name="location"
          label="Location of pickup"
          required={true}
          value={pickupToSave.location}
          options={pickupService.getPickupLocationOptions()}
          onChange={onChange}
          error={get(errors, 'location', '')}
        />

        { CARRIER.UPS === carrier && (
          <NumberInput
            name="numOfPackages"
            label="Number of packages"
            withSignButtons={false}
            autoHeight
            required={true}
            value={pickupToSave.numOfPackages}
            onChange={onChange}
            error={get(errors, 'numOfPackages', 0)}
          />
        )}

        <TextAreaInput
          name="notes"
          label="Write down note for driver"
          placeholder="Short description"
          value={pickupToSave.notes}
          onChange={onChange}
          error={get(errors, 'notes', '')}
        />

        <Flex justify="center">
          <Button type="secondary" autoWidth margin={buttonLeftRightMargin} onClick={close}>
            Cancel
          </Button>

          <Button autoWidth disabled={disabled} margin={buttonLeftMargin} onClick={saveChanges}>
            Save Changes
          </Button>
        </Flex>
      </Styled.CustomModal>
    );
  }

  return render();
}

export default SavePickupModal;

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';

import FEATURE from 'constants/features';
import PAYMENT_METHOD from 'constants/paymentMethods';

import {AppState} from 'reducers';
import paymentActions from 'actions/paymentActions';

import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';
import FormHeader from 'components/common/FormHeader';
import SelectInput from 'components/common/SelectInput/SelectInput';
import CheckBoxInput from 'components/common/CheckboxInput';
import QuestionMark from 'components/common/Marks/QuestionMark';
import AutoPayTooltip from 'components/tooltips/AutoPayTooltip';

import ACHPayment from '../../ACHPayment';
import PaymentMethodSelection from '../../PaymentMethodSelection';
import BillingAddressSection from '../../BillingAddressSection';
import CreditCardInfo from '../../CreditCardInfo';
import PaymentButton from '../../PaymentButton';

import * as StyledModal from 'styles/modal';

const StyledHeader = styled(Flex)`
  margin-bottom: 0.6rem;
`;

const StyledAutoPayDayInput = styled(SelectInput)`
  margin-top: 4rem;
`;

const StyledCheckboxInput = styled(CheckBoxInput)`
  margin-top: 3rem;
`;

interface Props {
  visible: boolean;
  close: () => void;
  savePaymentMethodAction: (e) => void;
}

function AddPaymentMethodModal({visible, close, savePaymentMethodAction}: Props) {
  const dispatch = useDispatch();

  const paymentMethod = useSelector((state: AppState) => state.payment.selectedPaymentMethod);

  const [autoPay, setAutoPay] = useState<boolean>(false);
  const [autoPayDay, setAutoPayDay] = useState<string>('Wednesday');

  const autoPayDayOptions: BasicOption[] = [
    {value: 'Monday', label: 'Monday'},
    {value: 'Tuesday', label: 'Tuesday'},
    {value: 'Wednesday', label: 'Wednesday'},
    {value: 'Thursday', label: 'Thursday'},
    {value: 'Friday', label: 'Friday'}
  ];

  useEffect(() => {
    return () => {
      //clear state on page leave
      dispatch(paymentActions.clearCreditCardAndAchAccount());
    };
  }, []);

  function toggleAutoPay() {
    setAutoPay(!autoPay);
  }

  function changePaymentMethod(value) {
    dispatch(paymentActions.changePaymentMethod(value));
  }

  function renderAutoPay() {
    if (!FEATURE.PAYMENT_AUTOPAY) return null;

    const autoPayLabel = (
      <Flex align="center">
        <span>Enroll in Autopay</span>
        <QuestionMark id="auto-pay" tooltip={AutoPayTooltip()} />
      </Flex>
    );

    return (
      <>
        <StyledCheckboxInput name="autoPay" label={autoPayLabel} value={autoPay} onToggle={toggleAutoPay} />

        {autoPay && (
          <StyledAutoPayDayInput
            name="autoPayDay"
            label="I would like Autopay to take place on the following day:"
            value={autoPayDay}
            options={autoPayDayOptions}
            onChange={(field, value) => setAutoPayDay(value)}
          />
        )}
      </>
    );
  }

  function render() {
    return (
      <StyledModal.CustomModal show={visible} padding="2.6rem 2.6rem 3rem" onHide={close}>
        <StyledHeader justify="space-between">
          <FormHeader>Add New Payment Method</FormHeader>
          <CloseButton onClick={close} />
        </StyledHeader>

        <PaymentMethodSelection paymentMethod={paymentMethod} onChange={(field, value) => changePaymentMethod(value)} />

        {paymentMethod === PAYMENT_METHOD.CREDIT_CARD && <CreditCardInfo />}
        {paymentMethod === PAYMENT_METHOD.ACH && <ACHPayment />}

        <BillingAddressSection />

        {renderAutoPay()}

        <PaymentButton paymentMethod={paymentMethod} onSaveAction={savePaymentMethodAction} />
      </StyledModal.CustomModal>
    );
  }

  return render();
}

export default AddPaymentMethodModal;

const SHIPMENT_STATUS = {
  NONE: 0,
  CREATED: 1,
  RATED: 2,
  PREPARED: 4,
  SUPPORT_REQUESTED: 8,
  COO_GENERATED: 16,
  LABEL_GENERATED: 32,
  CUSTOMS_INVOICE_GENERATED: 64,
  AWAITING_PICKUP: 128,
  AWAITING_DROPOFF: 256,
  IN_TRANSIT: 512,
  DELIVERED: 1024,
  ERROR: 2048,
  ALL_SUPPORT_ISSUES_RESOLVED: 4096,
  ALL_ERRORS_RESOLVED: 8192,
  CANCELED: 16384
};

export default SHIPMENT_STATUS;

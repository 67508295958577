import React from 'react';
import styled from 'styled-components/macro';
import {useHistory} from 'react-router-dom';

import navigationHelper from 'helpers/navigationHelper';

import Button from 'components/common/Button';
import ErrorHandler from './ErrorHandler';

const StyledExtraSectionContainer = styled.div`
  margin-top: 4.5rem;
`;

const NotFoundPage = () => {
  const history = useHistory();

  function backToShipping() {
    history.push(navigationHelper.getViewHistoryPageLink());
  }

  const extraSection = (
    <StyledExtraSectionContainer>
      <Button onClick={backToShipping} autoWidth>
        Back to Shipping
      </Button>
    </StyledExtraSectionContainer>
  );

  return <ErrorHandler errorText="Looks like this page has not been packed yet!" extraSection={extraSection} />;
};

export default NotFoundPage;

import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';
import orderActions from 'actions/orderActions';

import ORDER_STATUS from 'constants/orderStatuses';

import OrdersNavPanel from './components/orders_nav_panel/OrdersNavPanel';
import OrdersWrapper from './components/wrapper/OrdersWrapper';
import CreateOrderModal from './components/new_order/CreateOrderModal';
import SearchPanel from './components/search_panel/SearchPanel';

import {ordersNavBarHeight, maxContainerWidth} from 'styles/shared';

const StyledContainer = styled.div`
  padding-top: calc(${ordersNavBarHeight} + 2rem);
  width: ${maxContainerWidth};
`;

const RECIPIENT_INFO: Recipient = {
  name: '',
  companyName: '',
  address: {
    addressLine1: '',
    addressLine2: '',
    addressLine3: ''
  },
  city: '',
  state: '',
  zipCode: '',
  country: ''
};

const ORDER_INFO: OrderInformation = {
  id: '',
  autogenerate: false,
  oderDate: undefined,
  paidDate: undefined,
  shippingPaid: 0,
  taxPaid: 0,
  totalPaid: 0
};

function OrdersPage() {
  const dispatch = useDispatch();

  const orders = useSelector((state: AppState) => state.order.list);

  const [activeTab, setActiveTab] = useState(ORDER_STATUS.AWAITING_PAYMENT);
  const [displayOrders, setDisplayOrders] = useState<any[]>([]);
  const [newOrder, setNewOrder] = useState<Order>();

  useEffect(() => {
    onLoadOrders();
  }, []);

  useEffect(() => {
    //Fix-API: use real status
    let selectedOrders = orders.filter(order => order.status !== activeTab);
    setDisplayOrders(selectedOrders);
  }, [orders, activeTab]);

  async function onLoadOrders() {
    await dispatch(orderActions.loadOrders());
  }

  function onTabChange(tab) {
    setActiveTab(tab);
  }

  function addNewOrder() {
    let initialOrder: Order = {
      recipientInformation: RECIPIENT_INFO,
      orderInformation: ORDER_INFO,
      orderLineItems: []
    };

    setNewOrder(initialOrder);
  }

  function cancelOrder() {
    setNewOrder(undefined);
  }

  function updateOrder(field, value) {
    if (!newOrder) return;

    setNewOrder({...newOrder, [field]: value});
  }

  function render() {
    return (
      <>
        <SearchPanel />

        <OrdersNavPanel activeTab={activeTab} orders={orders} onChange={onTabChange} />

        <StyledContainer>
          <OrdersWrapper
            displayOrders={displayOrders}
            activeTab={activeTab}
            onReload={onLoadOrders}
            onTabChange={onTabChange}
            onAddNewOrder={addNewOrder}
          />
        </StyledContainer>

        {newOrder && (
          <CreateOrderModal
            order={newOrder}
            visible={newOrder ? true : false}
            close={cancelOrder}
            onChange={updateOrder}
          />
        )}
      </>
    );
  }

  return render();
}

export default OrdersPage;

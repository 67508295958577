import styled from 'styled-components/macro';

import {mainContainerPadding, colors} from 'styles/shared';
import Button from '../Button';

export const divider = styled.div`
  border-top: 0.1rem solid ${colors.darkBlue_lighter};
  margin: 0 -${mainContainerPadding};
`;

export const button = styled(Button)`
  width: 100%;
`;

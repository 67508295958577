import React from 'react';
import styled from 'styled-components/macro';

import FormHeader from 'components/common/FormHeader';
import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import * as Styled from 'styles/modal';
import {buttonRightMargin, authFont} from 'styles/shared';

const StyledText = styled.div`
  ${authFont};
  margin: 2.4rem 0 3.6rem;
`;

interface Props {
  visible: boolean;
  user: User;
  close: () => void;
  onSend: () => void;
}

function PasswordRecoveryModal({visible, user, close, onSend}: Props) {
  let receiver = user.name;
  if (user.email) receiver += `, ${user.email}`;

  return (
    <Styled.CustomModal show={visible} backdrop="static" width="48.4rem" onHide={close}>
      <FormHeader>Send Recovery Password</FormHeader>
      <StyledText>
        This will send a password reset email to <b>&lt;{receiver}.&gt;</b> When they click on the link they receive in
        the email, they will be auto-signed in to the Prep Tool and they will be able to set a new password. Send the
        email?
      </StyledText>

      <Flex justify="center">
        <Button autoWidth type="secondary" margin={buttonRightMargin} onClick={close}>
          Not Now
        </Button>
        <Button autoWidth onClick={onSend}>
          Send Email
        </Button>
      </Flex>
    </Styled.CustomModal>
  );
}

export default PasswordRecoveryModal;

import React from 'react';

import Flex from 'components/common/Flex';

import * as styled from './CarrierEmptyList.styled';

interface Props {
  isStandaloneQuote: boolean;
}

function CarrierEmptyList({isStandaloneQuote}: Props) {
  function renderChildren() {
    return (
      <Flex justify="space-between" align="center">
        <styled.content isStandaloneQuote={isStandaloneQuote}>
          <styled.title isStandaloneQuote={isStandaloneQuote}>
            Looks like you still need to select your Carrier!
          </styled.title>
          <styled.text>Select your preferred carrier or select both to compare Quotes.</styled.text>
        </styled.content>

        <img alt="Empty Quotes" src="/images/error-handler.png" />
      </Flex>
    );
  }

  if (isStandaloneQuote) {
    return <styled.containerStandalone>{renderChildren()}</styled.containerStandalone>;
  }

  return <styled.containerDefault>{renderChildren()}</styled.containerDefault>;
}

export default CarrierEmptyList;

import React from 'react';
import {get} from 'lodash';
import {useSelector} from 'react-redux';

import {AppState} from 'reducers';

import validationHelper from 'helpers/validationHelper';

import RadioInput from 'components/common/RadioInput/RadioInput';
import TextAreaInput from 'components/common/TextAreaInput';
import AlertComponent from 'components/common/AlertComponent';
import InvoiceSection from './components/InvoiceSection';
import Packages from '../../../packages';
import ElectronicExportFiling from './components/ElectronicExportFiling';
import AnyBatteriesTooltip from 'components/tooltips/AnyBatteriesTooltip';
import ProductGeneralDescriptionTooltip from 'components/tooltips/ProductGeneralDescriptionTooltip';
import SystemUnits from '../SystemUnits';

import PdfFile from 'Documents/Lithium Batteries Interactive Tool 2022.pdf';
import DangerousGoodsToggler from 'components/common/DangerousGoodsToggler';

interface Props {
  draft: Draft;
  onChange: OnChangeHandler;
  onInsuranceChange: OnChangeHandler;
}

function ShippingProductsTab({draft, onChange, onInsuranceChange}: Props) {
  const products = draft.shipmentDetails.products;

  const rate = useSelector((state: AppState) => state.draft.rate.current);
  const errors = useSelector((state: AppState) => state.draft.current.errors);
  const countryList = useSelector((state: AppState) => state.shipmentOptions.countryList);

  const batteryOptions = [
    {value: true, label: 'Yes', linkText: 'Tell me more', linkUrl: openPdfFile},
    {value: false, label: 'No'}
  ];

  function openPdfFile() {
    window.open(PdfFile);
  }

  function render() {
    const batteriesError = get(errors, 'shipmentDetails.products.isAnyBatteries');

    return (
      <div>
        <SystemUnits currency={draft.currency} measurementSystem={draft.measurementSystem} />

        <Packages
          packages={products.packages}
          carrier={rate?.carrier}
          currency={draft.currency}
          isDomestic={draft.isDomestic}
          measurementSystem={draft.measurementSystem}
          errorPath="shipmentDetails.products.packages"
          errors={errors}
          onChange={onChange}
        />

        <RadioInput
          name="isAnyBatteries"
          label="Does any of the packages contain batteries?"
          value={products.isAnyBatteries}
          options={batteryOptions}
          required={true}
          tooltip={AnyBatteriesTooltip()}
          onChange={onChange}
        />

        {batteriesError && <AlertComponent header="Batteries?" text={validationHelper.getInputError(batteriesError)} />}

        <TextAreaInput
          name="generalDescription"
          label="General description of the Shipment"
          value={products.generalDescription}
          placeholder="Short description"
          required={true}
          tooltip={ProductGeneralDescriptionTooltip()}
          onChange={onChange}
          error={get(errors, 'shipmentDetails.products.generalDescription', '')}
        />

        <DangerousGoodsToggler
          value={products.isShipmentContainsDangerousGoods}
          errors={errors}
          onChange={onChange}
        />

        {draft.isExport && (
          <ElectronicExportFiling
            data={products}
            withTooltip={true}
            errorPath="shipmentDetails.products"
            onChange={onChange}
            errors={errors}
          />
        )}

        {!draft.isDomestic && (
          <InvoiceSection
            countryList={countryList}
            errors={errors}
            onChange={onChange}
            onInsuranceChange={onInsuranceChange}
          />
        )}
      </div>
    );
  }

  return render();
}

export default ShippingProductsTab;

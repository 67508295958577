import React from 'react';

import styled from 'styled-components/macro';

import {colors} from 'styles/shared';

const StyledRadioInput = styled.input`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 0.1rem solid ${colors.darkBlue_light};
  margin-right: 0.7rem;
  position: relative;
  display: inline-block;
  outline: none;
  appearance: none;
  cursor: pointer;

  &:after {
    content: '${props => '\\00A0'}';
    position: absolute;
    top: 0.7rem;
    left: 0.7rem;
    width: 1.4rem;
    height: 1.4rem;
    background-color: ${props => (props.checked ? colors.orange_main : '')};
    border-radius: 50%;
  }
`;

interface Props {
  id: string;
  name: string;
  isChecked: boolean;
  value: boolean | string;
  onChange: OnChangeHandler;
}

function RadioButton({id, name, isChecked, value, onChange}: Props) {
  return (
    <StyledRadioInput type="radio" id={id} name={name} checked={isChecked} onChange={() => onChange(name, value)} />
  );
}

export default RadioButton;

import React from 'react';
import styled from 'styled-components/macro';

const StyledFlex = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  align-items: ${props => props.align || 'normal'};
  justify-content: ${props => props.justify || 'normal'};
`;

interface Props extends BaseProps {
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
}

function Flex({direction, align, justify, className, children}: Props) {
  return (
    <StyledFlex direction={direction} align={align} justify={justify} className={className}>
      {children}
    </StyledFlex>
  );
}

export default Flex;

import React, {useState, useEffect} from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';
import {get, isArray} from 'lodash';
import {useDispatch} from 'react-redux';

import shipmentActions from 'actions/shipmentActions';

import LICENSE_TYPE from 'constants/licenseTypes';
import ERROR from 'constants/literals/errors';
import WARNING from 'constants/literals/warnings';
import FEATURE from 'constants/features';

import uiHelper from 'helpers/uiHelper';
import formatHelper from 'helpers/formatHelper';

import TextInput from 'components/common/TextInput';
import NumberInput from 'components/common/NumberInput/NumberInput';
import CurrencyInput from 'components/common/CurrencyInput';
import DatePicker from 'components/common/DatePicker';
import Button from 'components/common/Button';
import SelectInput from 'components/common/SelectInput/SelectInput';
import CheckboxInput from 'components/common/CheckboxInput';
import QuestionMark from 'components/common/Marks/QuestionMark';
import CommoditySearchModal from 'components/common/CommoditySearch';
import Flex from 'components/common/Flex';

import ScheduleBTooltip from 'components/tooltips/ScheduleBTooltip';
import LineItemDescriptionTooltip from 'components/tooltips/LineItemDescriptionTooltip';
import LineItemMadeTooltip from 'components/tooltips/LineItemMadeTooltip';
import EccnTooltip from 'components/tooltips/EccnTooltip';
import ExportCodeTooltip from 'components/tooltips/ExportCodeTooltip';
import LicenseTooltip from 'components/tooltips/LicenseTooltip';
import InputWithUnits from 'components/common/InputWithUnits';

const StyledButtonRow = styled(Flex)`
  padding-bottom: 2.6rem;
`;

const StyledLicenseContainer = styled(Flex)`
  margin-bottom: 3rem;
`;

const StyledLicenseInput = styled(TextInput)`
  flex: 1;
`;

const licenseNumberOptions: BasicOption[] = [
  {value: LICENSE_TYPE.NO_LICENSE_REQUIRED, label: 'No License needed'},
  {value: '1', label: 'License nr. 1'},
  {value: '2', label: 'License nr. 2'},
  {value: '3', label: 'License nr. 3'},
  {value: '4', label: 'License nr. 4'},
  {value: '5', label: 'License nr. 5'}
];

interface Props {
  index: number;
  item: ProductItem;
  deleteButtonVisible: boolean;
  isLastIndex: boolean;
  showHiddenSection: boolean;
  errors: object;
  currency: string;
  measurementSystem: string;
  countryList: [];
  itemQuantityOptionsList: BasicOption[];
  onUpdateItem: (index: number, field: string, value?: string) => void;
  onRemoveItem: (index: number) => void;
  onAddItem: () => void;
  toggleProhibitedItemsModal: () => void;
}

function InvoiceItem({
  index,
  item,
  deleteButtonVisible,
  isLastIndex,
  showHiddenSection,
  errors,
  currency,
  measurementSystem,
  countryList,
  itemQuantityOptionsList,
  onUpdateItem,
  onRemoveItem,
  onAddItem,
  toggleProhibitedItemsModal
}: Props) {
  const dispatch = useDispatch();

  const [commodityError, setCommodityError] = useState('');
  const [searchModalVisible, setSearchModalVisible] = useState(false);

  useEffect(() => {
    let error = get(errors, `shipmentDetails.products.items[${index}].commodityCode`);

    if (!error && commodityError === WARNING.COMMODITY_CODE) return;

    setCommodityError(error);
  }, [errors]);

  function onChange(field, value) {
    if (value === LICENSE_TYPE.NO_LICENSE_REQUIRED) {
      onUpdateItem(index, 'exportLicenseExpiryDate', undefined);
    }
    onUpdateItem(index, field, value);
  }

  async function onBlur() {
    if (!item.commodityCode) return;

    let update = value => {
      let formattedValue = formatHelper.formatCommodityCode(value);
      onUpdateItem(index, 'commodityCode', formattedValue);
    };

    let code = formatHelper.replaceNonDigitsInString(item.commodityCode);
    if (code.length !== 10) {
      update(code);
      setCommodityError(ERROR.COMMODITY_CODE);
      return;
    }

    let response: any = await dispatch(shipmentActions.loadCommodityCodeDetails(code));

    if (!response) {
      update(code);
      setCommodityError(WARNING.COMMODITY_CODE);
      return;
    }

    if (isArray(response)) {
      let formatedCommodityCode = response[0];

      update(formatedCommodityCode);
    } else {
      update(item.commodityCode);
    }

    setCommodityError('');
  }

  function toggleSearchModal() {
    setSearchModalVisible(!searchModalVisible);
  }

  function applyCommodityCode() {
    uiHelper.notImplemented();
  }

  function renderActionButtons() {
    if (!deleteButtonVisible) {
      return (
        <StyledButtonRow justify="center">
          <Button autoWidth disabled={item.isEmpty} onClick={onAddItem}>
            Add Additional Item
          </Button>
        </StyledButtonRow>
      );
    }

    const justifyButtons = isLastIndex ? 'space-between' : 'flex-end';

    return (
      <StyledButtonRow justify={justifyButtons}>
        {isLastIndex && (
          <Button disabled={item.isEmpty} onClick={onAddItem}>
            Add Item
          </Button>
        )}

        <Button type="secondary" onClick={() => onRemoveItem(index)}>
          Delete item
        </Button>
      </StyledButtonRow>
    );
  }

  function render() {
    const actionButton = FEATURE.COMMODITY_CODE_SEARCH
      ? {title: 'Search', action: () => toggleSearchModal()}
      : undefined;

    let showLicenseExtraFields = item.isLicenseDetailsRequired;

    return (
      <>
        <TextInput
          name="commodityCode"
          label="Schedule B"
          required={true}
          tooltip={ScheduleBTooltip()}
          actionButton={actionButton}
          placeholder="0000.00.0000"
          value={item.commodityCode}
          onChange={onChange}
          onBlur={onBlur}
          error={commodityError}
        />

        <TextInput
          name="itemDescription"
          label="Item Description"
          tooltip={LineItemDescriptionTooltip()}
          actionLink={{title: 'View prohibited Items', action: toggleProhibitedItemsModal}}
          placeholder="Short description of the Item"
          required={true}
          value={item.itemDescription}
          onChange={onChange}
          error={get(errors, `shipmentDetails.products.items[${index}].itemDescription`)}
        />

        <Row>
          <Col>
            <NumberInput
              name="quantity"
              label="Quantity"
              required={true}
              withSignButtons={false}
              value={item.quantity}
              onChange={onChange}
              error={get(errors, `shipmentDetails.products.items[${index}].quantity`)}
            />
          </Col>
          <Col>
            <SelectInput
              name="units"
              label="Units"
              required={true}
              options={itemQuantityOptionsList}
              value={item.units}
              onChange={onChange}
              error={get(errors, `shipmentDetails.products.items[${index}].units`)}
            />
          </Col>
        </Row>

        <Row>
        <Col>
            <CurrencyInput
              name="itemValue"
              label="Value per item"
              value={item.itemValue}
              currency={currency}
              required={true}
              onChange={onChange}
              error={get(errors, `shipmentDetails.products.items[${index}].itemValue`)}
            />
          </Col>
          <Col>
            <InputWithUnits
                name="weight"
                label="Weight per item"
                value={item.weight}
                measurementSystem={measurementSystem}
                isWeightUnit={true}
                required={true}
                onChange={onChange}
                skipTabForUnits={false}
                error={get(errors, `shipmentDetails.products.items[${index}].weight`)}
              />
          </Col>
        </Row>

        <Row>
          <Col>
            <SelectInput
              name="itemMade"
              label="Where was the item made?"
              required={true}
              tooltip={LineItemMadeTooltip()}
              options={countryList}
              value={item.itemMade}
              onChange={onChange}
              error={get(errors, `shipmentDetails.products.items[${index}].itemMade`)}
            />
          </Col>
        </Row>

        {showHiddenSection && (
          <>
            <Row>
              <Col lg={showLicenseExtraFields ? 6 : 12}>
                <SelectInput
                  name="exportLicenseNumber"
                  label="Export License Nr."
                  value={item.exportLicenseNumber}
                  tooltip={ExportCodeTooltip()}
                  options={licenseNumberOptions}
                  onChange={onChange}
                  error={get(errors, `shipmentDetails.products.items[${index}].exportLicenseNumber`)}
                />
              </Col>
              {showLicenseExtraFields && (
                <Col lg={6}>
                  <DatePicker
                    name="exportLicenseExpiryDate"
                    label="Export License Expiry Date"
                    placeholder="YYYYMMDD"
                    value={item.exportLicenseExpiryDate}
                    onChange={onChange}
                    error={get(errors, `shipmentDetails.products.items[${index}].exportLicenseExpiryDate`)}
                  />
                </Col>
              )}
            </Row>

            {item.isEccnRequired && (
              <TextInput
                name="eccnValue"
                label="ECCN"
                value={item.eccnValue}
                tooltip={EccnTooltip()}
                onChange={onChange}
                error={get(errors, `shipmentDetails.products.items[${index}].eccnValue`)}
              />
            )}

            {showLicenseExtraFields && (
              <StyledLicenseContainer align="center">
                <CheckboxInput
                  name="isLicenseSymbolRequired"
                  label="License Symbol"
                  value={item.isLicenseSymbolRequired}
                  onToggle={onChange}
                />

                <StyledLicenseInput
                  name="licenseSymbol"
                  placeholder="abc"
                  size="small"
                  value={item.licenseSymbol}
                  onChange={onChange}
                  error={get(errors, `shipmentDetails.products.items[${index}].licenseSymbol`)}
                />

                <QuestionMark id="license-tooltip" tooltip={LicenseTooltip()} />
              </StyledLicenseContainer>
            )}
          </>
        )}

        {renderActionButtons()}

        {searchModalVisible && (
          <CommoditySearchModal visible={searchModalVisible} close={toggleSearchModal} onApply={applyCommodityCode} />
        )}
      </>
    );
  }

  return render();
}

export default InvoiceItem;

import React from 'react';
import {Modal} from 'components/bootstrap';
import styled from 'styled-components/macro';

import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import {mainFont, buttonLeftMargin, buttonLeftRightMargin} from 'styles/shared';
import * as Styled from 'styles/modal';

const StyledContent = styled.div`
  text-align: center;
`;

const StyledModalTitle = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
  ${mainFont};
`;

const StyledModalFooter = styled(Flex)`
  margin-top: 1rem;
`;

interface Props {
  visible: boolean;
  message?: string;
  onCancel: () => void;
  onTry: () => void;
}

function PickupError({visible, message, onCancel, onTry}: Props) {
  const text = message ? message : 'Please try again.';

  return (
    <Styled.CustomModal show={visible} backdrop="static" width="45.8rem" padding="4rem 4rem 2rem" onHide={onCancel}>
      <Modal.Body>
        <StyledContent>
          <StyledModalTitle>Something went wrong!</StyledModalTitle>
          <h4>{text}</h4>

          <img alt="Pickup Error" src={'/images/no-drafts.png'} width="100" height="100" />
        </StyledContent>

        <StyledModalFooter justify="center">
          <Button type="secondary" autoWidth margin={buttonLeftRightMargin} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" autoWidth margin={buttonLeftMargin} onClick={onTry}>
            Try Again
          </Button>
        </StyledModalFooter>
      </Modal.Body>
    </Styled.CustomModal>
  );
}

export default PickupError;

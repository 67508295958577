import DRAFT_STEP from "./draftSteps";

const COMMON = {
  MISSING_TEXT_TODO: 'TODO: Missing text',
  CURRENT_USER_ID: '00000000-0000-0000-0000-000000000000',
  EMPTY_FIELD: '-',
  EMPTY_RATE: '--',
  PICKUP_HISTORY_DAYS: 15,
  ERROR_NOTIFICATIONS: 'notifications',
  DOCUMENT: 'DOC',
  DOMESTIC: 'DOMESTIC',
  INTERNAIONAL: 'INTERNATIONAL',
  IMPORT: 'IMPORT',
  EXPRESS_DOCUMENTS_MAX_WEIGHT: 0.5,
  FOREIGN_TRADE_REGULATION_DEFAULT: '30.36',
  DOMESTIC_QUOTE_SIZE_LIMIT_IN_INCH: 165,
  DOCUMENT_INSURANCE_VALUE: 400,
  PICKUP_STEP: DRAFT_STEP.ADDITIONAL_SERVICES,
  DECLARED_VALUE_WARNING_MIN_VALUE: 1000,
  PICKUP_TIME: 'PICKUP_TIME'
};

export default COMMON;

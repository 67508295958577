import {cloneDeep} from 'lodash';

import DRAFT_STEP from 'constants/draftSteps';
import PAYMENT_METHOD from 'constants/paymentMethods';
import PAYMENT_TIME from 'constants/paymentTime';

const INITIAL_STATE = {
  user: {
    current: undefined,
    list: [],
    billingInfo: undefined
  },
  payment: {
    selectedPaymentMethod: PAYMENT_METHOD.CREDIT_CARD,
    paymentTime: PAYMENT_TIME.PAY_NOW,
    paymentId: '',
    paymentMethods: [],
    creditCard: {
      cardNumber: '',
      cardholderFirstName: '',
      cardholderLastName: '',
      expirationDate: '',
      cardCode: ''
    },
    achAccount: {
      accountNumber: '',
      bankName: '',
      routingNumber: '',
      nameOnAccount: '',
      isSavings: false
    },
    billingAddress: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      postalCode: '',
      division: '',
      suburb: ''
    },
    paymentParamsChanged: undefined,
    billingContactInformation: {
      contactName: '',
      phoneNumber: '',
      phoneNumberExtension: '',
      email: ''
    },
    errors: {}
  },
  pickup: {
    list: [],
    pagination: {
      activePage: 1,
      totalCount: 0,
      pageSize: 30
    }
  },
  shipment: {
    list: [],
    pagination: {
      activePage: 1,
      totalCount: 0,
      pageSize: 10
    }
  },
  draft: {
    list: [],
    pagination: {
      activePage: 1,
      totalCount: 0,
      pageSize: 10
    },
    current: {
      data: undefined, //draft object
      errors: {},
      steps: [
        {stepNumber: 1, step: DRAFT_STEP.CREATE_SHIPMENT, title: 'Create Shipment', valid: false},
        {stepNumber: 2, step: DRAFT_STEP.ADDITIONAL_SERVICES, title: 'Additional Services', valid: false},
        {stepNumber: 3, step: DRAFT_STEP.PAYMENT, title: 'Payment', valid: false},
        {stepNumber: 4, step: DRAFT_STEP.REVIEW_FINISH, title: 'Review & Finish', valid: false}
      ],
      step: DRAFT_STEP.CREATE_SHIPMENT,
      stepWasSubmitted: false,
      originAddressError: false,
      destinationAddressError: false,
      lastModifiedAddressSection: '',
      isDataUploaded: false,
      isExistingDraft: false,
      rateParamsChanged: undefined,
      carrierProductChanged: undefined,
      shouldReviewPickup: false
    },
    pickup: {
      list: [],
      dataToSave: undefined,
      pickupCapability: undefined,
      errors: {},
      pickupWasSubmitted: false
    },
    rate: {
      current: undefined,
      list: [],
      error: undefined
    }
  },
  order: {
    list: []
  },
  quote: {
    list: [],
    pagination: {
      activePage: 1,
      totalCount: 0,
      pageSize: 10
    },
    currentRates: [],
    error: undefined
  },
  address: {
    list: [],
    pagination: {
      activePage: 1,
      totalCount: 0,
      pageSize: 9
    }
  },
  lineItem: {
    list: [],
    pagination: {
      activePage: 1,
      totalCount: 0,
      pageSize: 11
    }
  },
  shipmentOptions: {
    ftrExemptions: [],
    countryOptions: [], //as interface CountryOption
    countryList: [], // {value: '', label: ''}
    signatureServiceOptions: [],
    whoPaysOptions: [],
    termsOfTradeOptions: [],
    currencyOptions: [],
    carrierServices: [],
    itemQuantityOptions: [],
    invoiceTypeOptions: [],
    proformaInvoiceTypeOptions: [],
    eeiFilingTypeOptions: [],
    exportTypeOptions: []
  },
  common: {
    asyncAction: undefined,
    showOverlay: false,
    confirmAction: undefined,
    infoAction: undefined,
    globalMenuActions: undefined,
    tutorialInfo: undefined,
    saveDraftModalVisible: false,
    isHouseAccountUsed: false,
    passwordProtectedDataModalVisible: false,
    resetPasswordModalVisible: false,
    isQuickAccessAuth: false,
    changePasswordModalVisible: false,
    intercomChatVisible: false,
    guestAuthModalVisible: false,
    guestSignUpModalVisible: false,
    errors: [],
    messages: []
  }
};

export const CURRENT_DRAFT = cloneDeep(INITIAL_STATE.draft.current);
export const DRAFT_PICKUP = cloneDeep(INITIAL_STATE.draft.pickup);
export const DRAFT_RATE = cloneDeep(INITIAL_STATE.draft.rate);
export const CREDIT_CARD = cloneDeep(INITIAL_STATE.payment.creditCard);
export const ACH_ACCOUNT = cloneDeep(INITIAL_STATE.payment.achAccount);
export const BILLING_ADDRESS = cloneDeep(INITIAL_STATE.payment.billingAddress);
export const BILLING_CONTACT_INFORMATION = cloneDeep(INITIAL_STATE.payment.billingContactInformation);

export default cloneDeep(INITIAL_STATE);

import React from 'react';
import styled from 'styled-components/macro';
import {lighten} from 'polished';

import {colors, mainBorder, opacity} from 'styles/shared';

const StyledTogglerContainer = styled.label`
  position: relative;
  float: right;
  width: 4.5rem;
  margin: 1.5rem 0;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

const StyledTogglerInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .toggle-slider {
    background-color: ${lighten(0.1, colors.orange_main)};
    border-color: ${lighten(0.1, colors.orange_main)};
  }

  &:checked + .toggle-slider:before {
    background-color: ${colors.orange_main};
    border-color: ${colors.orange_main};
    transform: translateX(3rem);
  }

  &:focus + .toggle-slider {
    border-color: ${lighten(0.1, colors.orange_main)};
  }

  &:focus + .toggle-slider:before {
    border-color: ${lighten(0.1, colors.orange_main)};
    background-color: ${lighten(0.25, colors.orange_main)};
  }
`;

const StyledTogglerSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0.5rem;
  width: 4.5rem;
  height: 1rem;
  border-radius: 3.6rem;
  border: ${mainBorder};
  background-color: ${colors.darkBlue_lighter};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 0.2rem 0.1rem ${opacity(0.1)};

  &:before {
    position: absolute;
    content: '';
    height: 2.6rem;
    width: 2.6rem;
    right: 2.5rem;
    bottom: -0.9rem;
    border: ${mainBorder};
    border-radius: 50%;
    background-color: ${colors.white};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: 0.1rem 0.1rem 0.3rem ${opacity(0.3)};
  }

  &:after {
    position: absolute;
    content: '';
    height: 2.6rem;
    width: 5rem;
    left: -0.5rem;
    bottom: -0.9rem;
  }
`;

interface Props {
  name: string;
  value: boolean;
  namePrefix?: string;
  onToggle: OnToggleHandler;
  disabled?: boolean;
}

Toggler.defaultProps = {
  disabled: false
};

function Toggler({name, value, namePrefix, onToggle, disabled}: Props) {
  let onChange = () => {
    if (disabled) return;
    onToggle(name, !value);
  };

  let uniqId = namePrefix ? `${namePrefix}-${name}` : name;

  return (
    <StyledTogglerContainer htmlFor={uniqId}>
      <StyledTogglerInput type="checkbox" id={uniqId} checked={value} onChange={onChange} />
      <StyledTogglerSlider className="toggle-slider" />
    </StyledTogglerContainer>
  );
}

export default Toggler;

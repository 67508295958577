import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';

import CARRIER from 'constants/carriers';

import validationHelper from 'helpers/validationHelper';

import entities from 'domain/entities';

import PackageItem from './components/PackageItem';

const StyledItemsContainer = styled.div`
  margin-bottom: 1rem;
`;

interface Props {
  packages: Package[];
  carrier?: string;
  currency: string;
  measurementSystem: string;
  isDomestic: boolean;
  onChange: (field: string, value: Package[]) => void;
  errors: object;
  errorPath: string;
}

function Packages({packages, carrier, currency, measurementSystem, isDomestic, onChange, errors, errorPath}: Props) {
  const [totalWeight, setTotalWeight] = useState(0);

  useEffect(() => {
    let total = 0;
    packages.forEach(item => {
      total += item.numberOfPackages * item.weight;
    });

    setTotalWeight(total);
  }, [packages]);

  function addPackageItem() {
    let newPackages = [...packages];

    let defaultPackage = entities.draft.getDefaultPackage();

    newPackages.push(defaultPackage);

    onChange('packages', newPackages);
  }

  function updatePackageItem(i, field, value) {
    let newPackages = [...packages];

    let item = {...packages[i]};
    item[field] = value;

    newPackages[i] = item;

    onChange('packages', newPackages);
  }

  function removePackageItem(index) {
    let newPackages = [...packages];

    newPackages.splice(index, 1);

    onChange('packages', newPackages);
  }

  function render() {
    const carrierValue = carrier ? carrier : isDomestic ? CARRIER.UPS : CARRIER.DHL;

    const totalWeightIsUnderLimit = validationHelper.totalWeightIsUnderLimit(
      totalWeight,
      carrierValue,
      measurementSystem
    );

    return (
      <StyledItemsContainer>
        {packages.map((item: Package, index) => {
          const packagesLength = packages.length;
          const isLastIndex = index === packagesLength - 1;

          return (
            <PackageItem
              key={index}
              index={index}
              isLastIndex={isLastIndex}
              item={item}
              carrier={carrierValue}
              currency={currency}
              isDomestic={isDomestic}
              measurementSystem={measurementSystem}
              itemsCount={packagesLength}
              totalWeightIsUnderLimit={totalWeightIsUnderLimit}
              errors={errors}
              errorPath={errorPath}
              onAddPackage={addPackageItem}
              onUpdatePackage={updatePackageItem}
              onRemovePackage={removePackageItem}
            />
          );
        })}
      </StyledItemsContainer>
    );
  }

  return render();
}

export default Packages;

import React, {useState} from 'react';
import {Row, Col} from 'components/bootstrap';
import {get, upperFirst, assign} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';
import draftActions from 'actions/draftActions';
import addressActions from 'actions/addressActions';

import MESSAGE from 'constants/literals/messages';

import validationHelper from 'helpers/validationHelper';
import uiHelper from 'helpers/uiHelper';

import heapAnalyticsService from 'services/heapAnalyticsService';

import Container from 'components/common/Container';
import TextInput from 'components/common/TextInput';
import FormHeader from 'components/common/FormHeader';
import PhoneNumberInput from 'components/common/PhoneNumberInput';
import PhysicalAddress from 'components/common/PhysicalAddress';
import Button from 'components/common/Button';
import AddressBook from 'components/common/AddressBook';
import AddressAutosuggest from './components/AddressAutosuggest';
import Togglers from './components/Togglers';

import {colors, secondaryFont, buttonRightMargin, buttonLeftRightMargin} from 'styles/shared';
import COUNTRY_CODE from 'constants/countryCodes/countryCodes';
import addressHelper from 'helpers/addressHelper';

const StyledAddressError = styled.div`
  color: ${colors.red_main};
  ${secondaryFont};
  margin-top: -3.75rem;
  margin-bottom: 0.5rem
`;

const StyledAddressButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1.5rem;
`;

const StyledPhoneNumberInput = styled(PhoneNumberInput)`
  margin-bottom: 0;
`;

const StyledPhoneExtension = styled(TextInput)`
  margin-bottom: 0;
`;

interface Props {
  sending: Sending;
  sendingDirection: string;
  isDomestic: boolean;
  isShipper: boolean;
  addressError: boolean;
}

function SendingSection({sending, sendingDirection, isDomestic, isShipper, addressError}: Props) {
  const dispatch = useDispatch();

  const errors = useSelector((state: AppState) => state.draft.current.errors);
  const countryOptions = useSelector((state: AppState) => state.shipmentOptions.countryOptions);

  const [addressBookVisible, setAddressBookVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<SavedAddress | null>(null);
  const [isAddressUpdated, setIsAddressUpdated] = useState(false);

  const path = `shipment${upperFirst(sendingDirection)}`;

  function onChange(field, value) {
    dispatch(draftActions.modifyDraft(path, field, value));
    trackAddressChange();
  }

  function onAddressChange(field, value) {
    onChange('address', {...sending.address, [field]: value});
    trackAddressChange();
  }

  function onAddressSelect(address) {
    let newAddress = {...sending.address, ...address};

    dispatch(draftActions.modifyDraft(path, 'address', newAddress));
    trackAddressChange();
  }

  function autofillAddressField(suggestion: Sending) {
    dispatch(draftActions.modifyDraft(null, path, suggestion));
    trackAddressChange();
  }

  function toggleAddressBook() {
    setAddressBookVisible(!addressBookVisible);
  }

  function showAddressBook() {
    heapAnalyticsService.track('Clicking to open Address Book');

    toggleAddressBook();
  }

  function loadAddressFromAddressBook(selectedAddress: SavedAddress) {
    // Map US States to two letter format for new select inputs
    if (selectedAddress.country === COUNTRY_CODE.USA && selectedAddress.address.division) {
      selectedAddress.address.division = addressHelper.reformatUSState(selectedAddress.address.division);
    }

    dispatch(draftActions.modifyDraft(null, path, selectedAddress));
    setSelectedAddress(selectedAddress);
    setIsAddressUpdated(true);
    toggleAddressBook();
  }

  function trackAddressChange() {
    if (!isAddressUpdated) return;
    setIsAddressUpdated(false);
  }

  async function updateContact() {
    if (!selectedAddress || isAddressUpdated) return;

    const response: any = await dispatch(addressActions.updateAddress(sending, selectedAddress.id));
    const isSuccess = response as boolean;

    if (isSuccess) {
      setSelectedAddress(assign(selectedAddress, sending));
      setIsAddressUpdated(true);
      uiHelper.showNotice(MESSAGE.UPDATE_ADDRESS);
    }
  }

  async function saveContact() {
    const response: any = await dispatch(addressActions.createAddress(sending));
    const address = response as SavedAddress;

    if (address?.id) {
      setSelectedAddress(address);
      setIsAddressUpdated(true);
      uiHelper.showNotice(MESSAGE.CREATE_ADDRESS);
    }
  }

  function renderActionButton() {
    const isUpdateButtonVisible = selectedAddress?.id ? true : false;

    if (isUpdateButtonVisible) {
      return (
        <Button margin={buttonRightMargin} disabled={isAddressUpdated} onClick={updateContact}>
          Update Contact
        </Button>
      );
    }

    const validationResult: ValidationResult = validationHelper.validateContact(sending, countryOptions);

    return (
      <Button margin={buttonRightMargin} disabled={!validationResult.valid} onClick={saveContact}>
        Save Contact
      </Button>
    );
  }

  function renderFirstColumn() {
    return (
      <Col lg={6}>
        <AddressAutosuggest
          name="companyName"
          label="Company Name"
          placeholder="ABC Inc."
          required={true}
          value={sending.companyName}
          onChange={onChange}
          onAutoFill={autofillAddressField}
          error={get(errors, `${path}.companyName`, '')}
        />

        <TextInput
          name="contactName"
          label="Contact Name"
          placeholder="John Doe"
          required={true}
          value={sending.contactName}
          onChange={onChange}
          error={get(errors, `${path}.contactName`, '')}
        />

        {addressError && (
          <StyledAddressError>
            We are unable to locate that address. If you are not sure whether it is valid, we suggest you to double
            check it before proceeding.
          </StyledAddressError>
        )}

        <PhysicalAddress
          autocompleteId={`${path}-autocomplete`}
          label="Physical Address"
          address={sending.address}
          countrySelected={sending.country}
          oneColumnDisplay={false}
          path={path}
          errors={errors}
          onChange={onAddressChange}
          onAddressSelect={onAddressSelect}
        />

        <StyledAddressButtonsContainer>
          {renderActionButton()}

          <Button margin={buttonLeftRightMargin} onClick={showAddressBook}>
            Address Book
          </Button>
        </StyledAddressButtonsContainer>
      </Col>
    );
  }

  function renderSecondColumn() {
    return (
      <Col lg={6}>
        <Row>
          <Col lg={7}>
            <StyledPhoneNumberInput
              name="phoneNumber"
              label="Telephone Number"
              required={true}
              countryCode={sending.country}
              value={sending.phoneNumber}
              onChange={onChange}
              error={get(errors, `${path}.phoneNumber`, '')}
            />
          </Col>
          <Col lg={5}>
            <StyledPhoneExtension
              name="phoneNumberExtension"
              label="Extension"
              placeholder="000"
              value={sending.phoneNumberExtension}
              onChange={onChange}
              error={get(errors, `${path}.phoneNumberExtension`, '')}
            />
          </Col>
        </Row>

        <TextInput
          name="email"
          type="email"
          label="Email Address"
          placeholder="john.doe@gmail.com"
          value={sending.email}
          onChange={onChange}
          error={get(errors, `${path}.email`, '')}
        />

        <Togglers
          sending={sending}
          path={path}
          isDomestic={isDomestic}
          isShipper={isShipper}
          onChange={onChange}
          errors={errors}
        />
      </Col>
    );
  }

  function render() {
    let header = `Sending ${sendingDirection}:`;

    return (
      <Container>
        <Row>
          <Col sm={12}>
            <FormHeader>{header}</FormHeader>
          </Col>
        </Row>

        <Row>
          {renderFirstColumn()}
          {renderSecondColumn()}
        </Row>

        {addressBookVisible && (
          <AddressBook visible={addressBookVisible} close={toggleAddressBook} onLoad={loadAddressFromAddressBook} />
        )}
      </Container>
    );
  }

  return render();
}

export default SendingSection;

import React from 'react';

import styled from 'styled-components/macro';

import FormHeader from 'components/common/FormHeader';
import Button from 'components/common/Button';
import Flex from 'components/common/Flex';

import * as Styled from 'styles/modal';
import {secondaryFont} from 'styles/shared';

const StyledText = styled.div`
  ${secondaryFont};
  margin: 1rem 0 3rem;

  span {
    font-weight: bold;
  }
`;

interface Props {
  visible: boolean;
  isExport: boolean;
  close: () => void;
}

function ImportExportWarningModal({visible, isExport, close}: Props) {
  let label = isExport ? 'Exports' : 'Imports';

  return (
    <Styled.CustomModal show={visible} backdrop="static" width="68.2rem" padding="4rem 4rem 3rem" onHide={close}>
      <FormHeader>Warning!</FormHeader>

      <StyledText>
        Your account isn't currently setup to do <span>{label}</span>. Please contact <span>Customer Support</span> if
        you'd like to be setup for them. In the meantime, you can continue filling out this shipment and then{' '}
        <span>Save Draft & Send to Customer Support</span> for a representative to finish preparing it for you.
      </StyledText>

      <Flex justify="center">
        <Button autoWidth onClick={close}>
          I Understand
        </Button>
      </Flex>
    </Styled.CustomModal>
  );
}

export default ImportExportWarningModal;

import React from 'react';
import styled from 'styled-components/macro';
import {capitalize} from 'lodash';

import PICKUP_TAB from 'constants/pickupTabs';
import COMMON from 'constants/common';

import config from 'config';
import mailHelper from 'helpers/mailHelper';

import {colors, pageSubtitle, secondaryFont} from 'styles/shared';

const StyledContainer = styled.div`
  padding: 3rem 1rem 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.darkBlue_main};
`;

const StyledHeader = styled.div`
  ${pageSubtitle};
  font-weight: bold;
  max-width: 37rem;
  margin-bottom: 2rem;
`;

const StyledSubTitle = styled.div`
  ${secondaryFont};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`;

const StyledLink = styled.a`
  ${secondaryFont};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  color: ${colors.orange_main};
  margin-bottom: 2rem;
`;

interface Props {
  activeTab?: string;
}

function NoPickups({activeTab}: Props) {
  let supportEmail = mailHelper.getSchedulePickupLink();

  let displayDay = activeTab === PICKUP_TAB.FUTURE ? 'Next Seven Days' : capitalize(activeTab);

  let header = `It looks like You do not have Pickup scheduled for ${displayDay}.`;

  if (activeTab === PICKUP_TAB.PAST) {
    header = `It looks like You did not have Pickups for the last ${COMMON.PICKUP_HISTORY_DAYS} days.`;
  }

  return (
    <StyledContainer>
      <StyledHeader>{header}</StyledHeader>

      <StyledSubTitle bold>To schedule a Pickup send us an email:</StyledSubTitle>

      <StyledLink href={supportEmail} target="_self" bold>
        {config.supportUrl}
      </StyledLink>

      <StyledSubTitle>You can also call our Customer Support on</StyledSubTitle>

      <StyledSubTitle>
        + {config.supportPhone.label} or reach us via <StyledLink href="slack://open">Slack</StyledLink>
      </StyledSubTitle>
    </StyledContainer>
  );
}

export default NoPickups;

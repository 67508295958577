export default {
  downloadFailedAddresses
};

function downloadFailedAddresses(addresses: AddressResponse[]) {
  //create CSV file data in an array
  let csvFileData = addresses.map(addressData => {
    const address = addressData.address;
    const contact = addressData.contact;

    return [
      replaceComma(addressData.nickname),
      replaceComma(addressData.companyName),
      replaceComma(contact.email),
      replaceComma(contact.phone),
      replaceComma(addressData.vatNumber),
      replaceComma(addressData.eoriNumber),
      replaceComma(addressData.taxId),
      replaceComma(address.countryCode),
      replaceComma(address.addressLine1),
      replaceComma(address.addressLine2),
      replaceComma(address.addressLine3),
      replaceComma(address.postalCode),
      replaceComma(address.locality),
      replaceComma(address.dependentLocality),
      replaceComma(address.administrativeArea),
      addressData.residential ? 'Y' : undefined
    ];
  });

  //define the heading for each row of the data
  let csv =
    'Name,Company Name,Email,Phone Number,VAT/TAX ID,EORI Number,IE Tax ID,Country,Address Line 1,Address Line 2,Address Line 3,Postal Code,City,Suburb,State/Province,Residential?\n';

  //merge the data with CSV
  csvFileData.forEach(row => {
    csv += row.join(',');
    csv += '\n';
  });

  let hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  hiddenElement.target = '_blank';

  //provide the name for the CSV file to be downloaded
  hiddenElement.download = 'Failed contacts.csv';
  hiddenElement.click();
}

// helper methods

function replaceComma(str) {
  if (!str) return str;
  if (typeof str !== 'string') return str;

  return str.replace(/,/g, ' ');
}

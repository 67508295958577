import React from 'react';
import styled from 'styled-components/macro';
import {Row, Col} from 'components/bootstrap';

import Container from 'components/common/Container';
import Flex from 'components/common/Flex';

import {mainFont, mediaQueries} from 'styles/shared';

const StyledDescription = styled.div`
  ${mainFont};
  font-weight: bold;
  padding-left: 2.6rem;
  height: 100%;
  display: flex;
  align-items: center;

  @media ${mediaQueries.mobileMax} {
    padding: 2rem 1.7rem 0;
  }
`;

function DhlProvider() {
  return (
    <Container withoutPadding>
      <Row>
        <Col lg={6}>
          <StyledDescription>
            DHL Express provides the fastest and most reliable international express service to over 220 countries
          </StyledDescription>
        </Col>
        <Col lg={6}>
          <Flex justify="center">
            <img alt="Authorized DHL Service Provider" src="/images/standalone/authorized-service.png" width="354" />
          </Flex>
        </Col>
      </Row>
    </Container>
  );
}

export default DhlProvider;

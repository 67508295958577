import React from 'react';
import styled from 'styled-components/macro';

import Flex from 'components/common/Flex';

import {errorInfoTitle} from 'styles/shared';

const StyledContainer = styled(Flex)`
  margin-top: 2rem;
`;

const StyledText = styled.div`
  width: 30.5rem;
  ${errorInfoTitle};
  text-align: center;
`;

function NoIssueDetected() {
  return (
    <StyledContainer direction="column" align="center">
      <img src="images/shipments-good.png" alt="All your shipments are looking good!" />

      <StyledText>Yay! All your shipments are looking good!</StyledText>
    </StyledContainer>
  );
}

export default NoIssueDetected;

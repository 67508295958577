import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import {useHistory} from 'react-router-dom';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';
import draftActions from 'actions/draftActions';

import MESSAGE from 'constants/literals/messages';

import navigationHelper from 'helpers/navigationHelper';
import uiHelper from 'helpers/uiHelper';

import FormHeader from 'components/common/FormHeader';
import FormLabel from 'components/common/FormLabel';
import Button from 'components/common/Button';
import TextInput from 'components/common/TextInput';
import Flex from 'components/common/Flex';

import * as Styled from 'styles/modal';
import {mainFont, buttonLeftRightMargin, buttonLeftMargin, buttonRightMargin} from 'styles/shared';

const StyledContent = styled.div`
  width: 55.2rem;
  margin-bottom: 4rem;
`;

const StyledText = styled.div`
  ${mainFont};
  margin: 1rem 1.1rem 3.6rem 0;
  width: 55.2rem;
`;

interface Props {
  visible: boolean;
  saveDraft: () => void;
  saveDraftAndSendToSupport: () => void;
}

function SaveDraftConfirmation({visible, saveDraft, saveDraftAndSendToSupport}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const draft = useSelector((state: AppState) => state.draft.current.data);

  function close() {
    dispatch(commonActions.toggleSaveDraftModal(false));
  }

  function onChange(field, value) {
    dispatch(draftActions.modifyDraft(null, field, value));
  }

  async function save(showMessage = true) {
    await saveDraft();

    history.push(navigationHelper.getViewHistoryPageLink());
    close();

    if (showMessage) {
      uiHelper.showNotice(MESSAGE.SAVE_DRAFT);
    }
  }

  return (
    <Styled.CustomModal show={visible} backdrop="static" width="61.5rem" padding="2.6rem 0 2rem 2.6rem" onHide={close}>
      <StyledContent>
        <FormHeader>Save Draft</FormHeader>

        <StyledText>
          <p>
            Your draft will be saved into Drafts section of View History. You can delete it, edit it or send it to
            Customer Support anytime you want.
          </p>
          <p>
            If you are unable to finish your Shipment, you can send draft to our Customer Support and they will be happy
            to help you!
          </p>
          <p>
            You can also give a name to this draft in order to easily find it later on. Eg. Shipment for Ms. Smith, Tax
            ID missing.
          </p>
        </StyledText>

        <FormLabel>Draft Name</FormLabel>

        <TextInput
          name="name"
          placeholder="Type name of the draft"
          size="small"
          value={draft?.name}
          onChange={onChange}
        />
      </StyledContent>

      <Flex>
        <Button type="secondary" autoWidth margin={buttonRightMargin} onClick={close}>
          Cancel
        </Button>
        <Button autoWidth margin={buttonLeftRightMargin} onClick={save}>
          Save Draft
        </Button>
        <Button autoWidth margin={buttonLeftMargin} onClick={saveDraftAndSendToSupport}>
          Save & Send to Support
        </Button>
      </Flex>
    </Styled.CustomModal>
  );
}

export default SaveDraftConfirmation;

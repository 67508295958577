import React, {Fragment} from 'react';
import {groupBy, minBy, maxBy} from 'lodash';
import styled from 'styled-components/macro';

import config from 'config';
import formatHelper from 'helpers/formatHelper';
import dateHelper from 'helpers/dateHelper';

import ActionsPopup from 'components/common/ActionsPopup';
import AddressCell from './AddressCell';

import {colors, secondaryFont} from 'styles/shared';
import * as Styled from 'styles/table';

const StyledDateRow = styled.tr`
  height: 4rem;
  background: ${colors.grey_light};
  ${secondaryFont};
  font-weight: bold;

  > td {
    padding-left: 1.5rem;
  }
`;

interface Props {
  activePickups: Pickup[];
  pickupActions: DropdownAction[];
  isPastTab: boolean;
}

function NextSevenDaysPickups({activePickups, pickupActions, isPastTab}: Props) {
  const futurePickups = groupBy(activePickups, 'date');

  function getNextSevenDaysPickupsHeader(dateStr, pickups: Pickup[]) {
    const date = new Date(dateStr);
    const displayDate = dateHelper.displayShortDate(date);

    const pickupWithMinStartTime = minBy(pickups, pickup => {
      return pickup.startTime;
    });

    const pickupWithMaxEndTime = maxBy(pickups, pickup => {
      return pickup.endTime;
    });

    const startTimeDisplay =
      pickupWithMinStartTime && formatHelper.displayTimeInAmPmFormat(pickupWithMinStartTime.startTime);
    const endTimeDisplay = pickupWithMaxEndTime && formatHelper.displayTimeInAmPmFormat(pickupWithMaxEndTime.endTime);
    const displayPickupsWindow = `${startTimeDisplay} - ${endTimeDisplay}`;

    return `Pickup: ${displayDate} ${displayPickupsWindow}`;
  }

  return (
    <tbody>
      {Object.keys(futurePickups)
        .sort()
        .reverse()
        .map(key => {
          const pickups = futurePickups[key];

          return (
            <Fragment key={key}>
              <StyledDateRow>
                <td colSpan={5}>{getNextSevenDaysPickupsHeader(key, pickups)}</td>
              </StyledDateRow>

              {pickups.map((pickup: Pickup) => {
                const carrierReferenceNumber = formatHelper.displayValue(pickup.confirmationNumber);

                const dayOfWeek = dateHelper.displayDate(pickup.date, config.format.dayOfWeek);
                const displayDate = `${dayOfWeek}, ${pickup.displayPickupWindow}`;

                return (
                  <Styled.TRowBody key={pickup.id}>
                    <AddressCell pickup={pickup} />
                    <Styled.TBodyCell>{pickup.contactName}</Styled.TBodyCell>
                    <Styled.TBodyCell>{displayDate}</Styled.TBodyCell>
                    <Styled.TBodyCell>{carrierReferenceNumber}</Styled.TBodyCell>

                    {!isPastTab && (
                      <Styled.TBodyCell actions>
                        <ActionsPopup actions={pickupActions} activeItem={pickup} />
                      </Styled.TBodyCell>
                    )}
                  </Styled.TRowBody>
                );
              })}
            </Fragment>
          );
        })}
    </tbody>
  );
}

export default NextSevenDaysPickups;

import helper from './reducerHelper';

import {LOAD_SHIPMENTS, CHANGE_SHIPMENTS_PAGE} from 'action_types/shipmentActonTypes';
import initialState from './initialState';

export interface ShipmentState {
  list: Shipment[];
  pagination: Pagination;
}

const shipmentReducer = (state: ShipmentState = initialState.shipment, action) => {
  return helper.handleActions<ShipmentState>(state, action, {
    [LOAD_SHIPMENTS](state, payload: {shipments: Shipment[]; totalItems: number}) {
      state.list = payload.shipments;
      state.pagination.totalCount = payload.totalItems;
    },
    [CHANGE_SHIPMENTS_PAGE](state, payload: {activePage: number}) {
      state.pagination.activePage = payload.activePage;
    }
  });
};

export default shipmentReducer;

import React, {Fragment} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {AppState} from 'reducers';
import lineItemsActions from 'actions/lineItemsActions';

import formatHelper from 'helpers/formatHelper';

import CheckBoxInput from 'components/common/CheckboxInput';
import Pagination from 'components/common/Pagination';
import EmptyList from './EmptyList';

import * as Styled from 'styles/table';

interface Props {
  items: LineItem[];
  editMode: boolean;
  checkedItems: string[];
  toggleCheckbox: (field: string) => void;
  checkedAll: boolean;
  selectAllToggle: () => void;
  onSelect: (lineItem: LineItem) => void;
}

function LineItemsList({items, editMode, checkedItems, toggleCheckbox, checkedAll, selectAllToggle, onSelect}: Props) {
  const dispatch = useDispatch();

  const pagination = useSelector((state: AppState) => state.lineItem.pagination);
  const countryList = useSelector((state: AppState) => state.shipmentOptions.countryList);

  function onSelectItem(lineItem) {
    if (editMode) return;

    onSelect(lineItem);
  }

  function onPageChange(page) {
    dispatch(lineItemsActions.updateLineItemPageNumber(page));
  }

  function render() {
    const anyItems = items?.length ? true : false;

    const preSelected = items.length !== checkedItems.length && checkedItems.length > 0;

    return (
      <>
        <Styled.Table>
          <thead>
            <Styled.TRowHead topBorder>
              {editMode && (
                <Styled.THeadCell>
                  <CheckBoxInput
                    name="checkedAll"
                    value={checkedAll}
                    preSelected={preSelected}
                    onToggle={selectAllToggle}
                  />
                </Styled.THeadCell>
              )}

              <Styled.THeadCell>Name</Styled.THeadCell>
              <Styled.THeadCell>Description</Styled.THeadCell>
              <Styled.THeadCell>Schedule B</Styled.THeadCell>
              <Styled.THeadCell>Country of manufacture</Styled.THeadCell>
            </Styled.TRowHead>
          </thead>

          <tbody>
            {items.map((item, index) => {
              const id = item.id;

              let isChecked = checkedItems.find(i => i === id) ? true : false;

              let countryMade = countryList.find(country => country.value === item?.itemMade);

              let lastItem = items.length === pagination.pageSize && index + 1 === items.length;

              return (
                <Fragment key={id}>
                  <Styled.TRowBody
                    checked={isChecked}
                    clickable={!editMode}
                    noBorder={lastItem}
                    onClick={() => onSelectItem(item)}>
                    {editMode && (
                      <Styled.TBodyCell>
                        <CheckBoxInput name={id} value={isChecked} onToggle={toggleCheckbox} />
                      </Styled.TBodyCell>
                    )}
                    <Styled.TBodyCell bold>{formatHelper.displayValue(item?.name)}</Styled.TBodyCell>
                    <Styled.TBodyCell>{formatHelper.displayValue(item?.itemDescription)}</Styled.TBodyCell>
                    <Styled.TBodyCell>{formatHelper.displayValue(item?.commodityCode)}</Styled.TBodyCell>
                    <Styled.TBodyCell>{formatHelper.displayValue(countryMade?.label)}</Styled.TBodyCell>
                  </Styled.TRowBody>
                </Fragment>
              );
            })}
          </tbody>
        </Styled.Table>

        {!anyItems && <EmptyList />}

        <Pagination pagination={pagination} onChange={onPageChange} />
      </>
    );
  }

  return render();
}

export default LineItemsList;

import React, {useState, useEffect} from 'react';
import styled from 'styled-components/macro';

import PICKUP_TAB from 'constants/pickupTabs';

import Flex from 'components/common/Flex';
import DropdownAction from 'components/common/Dropdown/DropdownAction';
import PickupButton from 'components/view_history/components/pickups/components/header/components/PickupButton';

const StyledContainer = styled(Flex)`
  margin-top: 1.2rem;
`;

interface Props {
  pickups: object;
  activeTab: string;
  onChange: (tab: string) => void;
}

function PickupHeaderMobile({pickups, activeTab, onChange}: Props) {
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>();

  useEffect(() => {
    const todayPickupsNumber = pickups[PICKUP_TAB.TODAY]?.length || null;
    const tomorrowPickupsNumber = pickups[PICKUP_TAB.TOMORROW]?.length || null;
    const futurePickupsNumber = pickups[PICKUP_TAB.FUTURE]?.length || null;
    const pastPickupsNumber = pickups[PICKUP_TAB.PAST]?.length || null;

    const options: DropdownOption[] = [
      {value: PICKUP_TAB.TODAY, label: 'Today', counter: todayPickupsNumber},
      {value: PICKUP_TAB.TOMORROW, label: 'Tomorrow', counter: tomorrowPickupsNumber},
      {value: PICKUP_TAB.FUTURE, label: 'In Next Seven Days', counter: futurePickupsNumber},
      {value: PICKUP_TAB.PAST, label: 'Past Pickups', counter: pastPickupsNumber}
    ];

    setDropdownOptions(options);
  }, [pickups]);

  function render() {
    if (!dropdownOptions) return null;

    return (
      <StyledContainer justify="space-between">
        <DropdownAction value={activeTab} options={dropdownOptions} onChange={onChange} />
        <PickupButton />
      </StyledContainer>
    );
  }

  return render();
}

export default PickupHeaderMobile;

import React from 'react';

function ReceiverReferenceNumberTooltip() {
  return (
    <span>
      This field is NOT required and will most commonly <br />
      be left blank. This field can list references <br />
      specific to the receiver, or that the receiver <br />
      requires like:{' '}
      <strong>
        Booking Numbers, Receiver Purchase
        <br />
        Order Numbers, Receiver Invoice Numbers
      </strong>
      , etc.
    </span>
  );
}

export default ReceiverReferenceNumberTooltip;

import styled, {css} from 'styled-components/macro';

import {
  colors,
  mainFont,
  defaultInputHeight,
  mainBorder,
  boxShadow,
  opacity,
  minInputContainerHeight
} from 'styles/shared';

export const AutosuggestContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => (props.small ? '0.6rem 0' : '2rem 0')};

  ${props =>
    props.addressInput &&
    css`
      min-height: ${minInputContainerHeight};
    `}

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 100%;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: ${defaultInputHeight};
    width: -webkit-fill-available;
    border: ${mainBorder};
    background-color: ${colors.white};
    ${mainFont};
    z-index: 2;
    max-height: 24.2rem;
    overflow-y: auto;
    box-shadow: ${boxShadow};
    border-radius: 0.6rem;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    ${props =>
      props.withTabs &&
      css`
        box-shadow: 0 -0.6rem 0.6rem ${opacity(0.06)};
      `}
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0.8rem 1.6rem;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${colors.grey_light};
  }
`;

export const AutosuggestTabsPanel = styled.div`
  background: ${colors.grey_concrete};
  height: 4rem;
  display: flex;
  align-items: flex-end;
  padding-left: 1.5rem;
`;

export const AutosuggestTab = styled.div`
  height: 3.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 0.7rem;
  border-top-left-radius: 0.7rem;
  padding: 0.6rem 1rem;
  margin-right: 0.8rem;
  ${mainFont};
  color: ${props => (props.active ? colors.orange_main : colors.white)};
  background: ${props => (props.active ? colors.white : colors.darkBlue_light)};
  cursor: ${props => (props.active ? 'initial' : 'pointer')};
`;

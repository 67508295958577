import React from 'react';
import styled from 'styled-components/macro';

import COMMON from 'constants/common';

import {colors, secondaryFont} from 'styles/shared';

const StyledItem = styled.div`
  margin: 1.5rem 0;
  word-break: break-word;
  ${secondaryFont};
`;

const StyledTitle = styled.div`
  font-weight: bold;
`;

const StyledValue = styled.div`
  color: ${props => (props.isHighlighted ? colors.orange_main : 'inherit')};
`;

interface Props {
  label: string;
  value?: any;
  htmlValue?: boolean;
  isTextHighlighted?: boolean;
}

SummaryItem.defaultProps = {
  value: COMMON.EMPTY_FIELD,
  htmlValue: false
};

function SummaryItem({label, value, htmlValue, isTextHighlighted}: Props) {
  return (
    <StyledItem>
      <StyledTitle>{label}:</StyledTitle>

      {htmlValue ? (
        <StyledValue isHighlighted={isTextHighlighted} dangerouslySetInnerHTML={{__html: value}} />
      ) : (
        <StyledValue isHighlighted={isTextHighlighted}>{value || COMMON.EMPTY_FIELD}</StyledValue>
      )}
    </StyledItem>
  );
}

export default SummaryItem;

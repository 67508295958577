import React from 'react';
import styled from 'styled-components';

import uiHelper from 'helpers/uiHelper';
import validationHelper from 'helpers/validationHelper';

import Container from 'components/common/Container';
import CheckBoxInput from 'components/common/CheckboxInput';
import ActionLink from 'components/common/ActionLink';
import InputError from 'components/common/InputComponents/InputError';

import {authFont} from 'styles/shared';

const StyledText = styled.div`
  margin: 2rem 0;
  ${authFont};
`;

interface Props {
  termsAndConditionsAgreement: boolean;
  onChange: (field: string, value: any) => void;
  error?: string | ValidationError[];
}

function TermsAndConditionsSection({termsAndConditionsAgreement, onChange, error}: Props) {
  const inputError: string = validationHelper.getInputError(error);

  const label = (
    <div>
      I agree to <ActionLink title="Terms & Conditions" isInheritFont onClick={uiHelper.notImplemented} />
    </div>
  );

  return (
    <Container>
      <CheckBoxInput
        name="termsAndConditionsAgreement"
        label={label}
        value={termsAndConditionsAgreement}
        onToggle={onChange}
      />

      {inputError && <InputError>{inputError}</InputError>}

      <StyledText>
        This payment is based on the weight, dimensions and other shipping information provided for the quote. You will
        be responsible for any differences in the final invoice, should they occur. If the invoice comes back less than
        your upfront payment amount, the difference will be credited back to you.
      </StyledText>
    </Container>
  );
}

export default TermsAndConditionsSection;

import React from 'react';
import styled, {css} from 'styled-components/macro';

import inputStyles from 'styles/customization/input';

const StyledContainer = styled.div`
  ${inputStyles.common.inputContainer};
  ${props =>
    props.autoHeight &&
    css`
      min-height: auto;
    `}
`;

interface Props extends BaseProps {
  autoHeight: boolean;
}

function InputContainer({autoHeight, className, children}: Props) {
  return (
    <StyledContainer autoHeight={autoHeight} className={className}>
      {children}
    </StyledContainer>
  );
}

export default InputContainer;

import React from 'react';
import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';

import FormLabel from 'components/common/FormLabel';
import Button from 'components/common/Button';

import {colors, secondaryFont} from 'styles/shared';

const StyledContainer = styled.div`
  margin-top: 2rem;
`;

const StyledPanel = styled.div`
  background: ${colors.grey_concrete};
  border-radius: 0.4rem;
  padding: 0.6rem 0.8rem;
`;

const StyledText = styled.div`
  ${secondaryFont};
  padding-top: 0.2rem;
`;

const StyledDescriptionColumn = styled(Col)`
  border-left: 0.1rem solid ${colors.orange_main};
`;

const StyledButtonColumn = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

interface Props {
  item: any;
  onApply: () => void;
}

function CommodityPanel({item, onApply}: Props) {
  return (
    <StyledContainer>
      <Row>
        <Col lg={4}>
          <FormLabel>Commodity Code</FormLabel>
        </Col>
        <Col lg={8}>
          <FormLabel>Item Description</FormLabel>
        </Col>
      </Row>

      <StyledPanel>
        <Row>
          <Col lg={4}>
            <StyledText>{item.code}</StyledText>
          </Col>
          <StyledDescriptionColumn lg={5}>
            <StyledText>{item.description}</StyledText>
          </StyledDescriptionColumn>
          <StyledButtonColumn lg={3}>
            <Button width={8.2} onClick={onApply}>
              Apply
            </Button>
          </StyledButtonColumn>
        </Row>
      </StyledPanel>
    </StyledContainer>
  );
}

export default CommodityPanel;

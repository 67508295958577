import React from 'react';

function ReferenceNumberTooltip() {
  return (
    <span>
      The reference number can be any special <br /> identifier for the shipment. Such as a <br /> purchase order
      number, or invoice number.
    </span>
  );
}

export default ReferenceNumberTooltip;

import React, {useState} from 'react';
import {Modal} from 'components/bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';
import draftActions from 'actions/draftActions';

import config from 'config';
import uiHelper from 'helpers/uiHelper';
import mailHelper from 'helpers/mailHelper';

import supportService from 'services/supportService';
import stateStorageService from 'services/stateStorageService';

import ActionLink from 'components/common/ActionLink';
import Flex from 'components/common/Flex';
import TextInput from 'components/common/TextInput';
import Button from 'components/common/Button';

import modalStyles from 'styles/customization/modal';
import {colors, mainFont, buttonRightMargin, buttonLeftRightMargin, buttonLeftMargin} from 'styles/shared';

const StyledInfo = styled(Modal)`
  ${modalStyles.infoPopup};

  .modal-dialog.modal-lg {
    width: 47.2rem;

    .modal-content {
      ${mainFont};
    }
  }
`;

const StyledContacts = styled.div`
  margin-top: 2rem;
  ${mainFont};
`;

const StyledFooter = styled(Flex)`
  margin-top: 1.8rem;
  font-weight: normal;
`;

const StyledCloseLink = styled(ActionLink)`
  margin: ${buttonRightMargin};
`;

const StyledActionLink = styled(ActionLink)`
  margin: ${buttonLeftRightMargin};
`;

const StyledInput = styled(TextInput)`
  & div:first-child {
    color: ${colors.white};
  }

  & input {
    background-color: transparent;
    color: ${colors.white};
  }
`;

interface Props {
  text: string | JSX.Element;
  type: 'info' | 'warning' | 'issue' | 'error' | 'critical';
  actionTitle?: string;
  closeTitle: string;
  visible: boolean;
  logToRollbar?: boolean;
  rollbarContext?: string;
  action?: () => void;
  close?: () => void;
}

InfoPopup.defaultProps = {
  closeTitle: 'Close'
};

function InfoPopup({text, type, visible, logToRollbar, rollbarContext, action, close, actionTitle, closeTitle}: Props) {
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user.current as User);
  const draft = useSelector((state: AppState) => state.draft.current.data);
  const rate = useSelector((state: AppState) => state.draft.rate.current);
  const currentStep = useSelector((state: AppState) => state.draft.current.step);
  const isExistingDraft = useSelector((state: AppState) => state.draft.current.isExistingDraft);

  const [note, setNote] = useState<string>('');

  const isError = type === 'error';

  if (!actionTitle) {
    if (type === 'issue') actionTitle = 'See Issues';
  }

  if (logToRollbar || isError) {
    const errorText = JSON.stringify(text);

    let message = isError ? 'Error Message' : 'Popup Message';
    message = rollbarContext ? `${message} from ${rollbarContext}` : message;
    message = `${message}: ${errorText}`;

    const metaData = supportService.getMetadata(
      user,
      draft?.id,
      isExistingDraft,
      rate?.id,
      currentStep,
      stateStorageService.getRequestId(),
      errorText
    );

    supportService.logToRollbar(message, metaData, user, isError);
  }

  function closeAction() {
    if (!close) return;
    close();
  }

  function callAction() {
    window.open(`tel:${config.supportPhone.value}`);
  }

  function sendEmail() {
    const supportLink = mailHelper.getHelpEmailLink();

    window.location.href = supportLink;

    closeAction();
  }

  function startChat() {
    dispatch(commonActions.toggleIntercomChat());
    closeAction();
  }

  async function sendToSupport() {
    if (draft) await saveDraft();

    const info = {
      user,
      userNotes: note
    };

    supportService.logToRollbar('Support Requested', info, user);

    let data = await supportService.getSupportRequestDto(draft?.id, rate?.id, currentStep, JSON.stringify(info));

    let isSuccess: any = await dispatch(commonActions.sendToSupport(data, true));

    if (!isSuccess) return;

    uiHelper.logMessage(info);
    closeAction();
  }

  async function saveDraft() {
    let draftToSave = {
      ...draft,
      currentStep
    };

    if (isExistingDraft) {
      await dispatch(draftActions.updateDraft(draftToSave));
    } else {
      await dispatch(draftActions.createDraft(draftToSave));
    }
  }

  function renderContacts() {
    return (
      <StyledContacts>
        <div>
          You can contact our Customer Support to get help immediately! <br />
        </div>
        Call us now: <ActionLink title={config.supportPhone.label} onClick={callAction} /> <br />
        Live chat with a Person: <ActionLink title="Start chat" onClick={startChat} /> <br />
        Send us an email: <ActionLink title={config.supportUrl} onClick={sendEmail} /> <br />
        <br />
        {type === 'error' && (
          <span>Or have us take a look and come back to you. You can find it later in your drafts.</span>
        )}
      </StyledContacts>
    );
  }

  function renderAdditionalErrorSection() {
    return (
      <>
        <StyledInput
          name="note"
          label="Additional notes"
          placeholder="Type any additional note"
          value={note}
          onChange={(field, value) => setNote(value)}
        />

        <Flex justify="center" align="center">
          {close && <StyledCloseLink title={closeTitle} onClick={close} />}

          <Button autoWidth margin={buttonLeftMargin} onClick={sendToSupport}>
            Save & Send to Support
          </Button>
        </Flex>
      </>
    );
  }

  function renderButtons(isInfoType) {
    if (isInfoType) {
      return (
        <StyledFooter justify="center">
          {close && (
            <Button autoWidth margin={'0 0 1rem'} onClick={close}>
              {closeTitle}
            </Button>
          )}
        </StyledFooter>
      );
    }

    return (
      <StyledFooter justify="flex-end">
        {close && <StyledCloseLink title={closeTitle} onClick={close} />}
        {action && <StyledActionLink title={actionTitle} onClick={action} />}
      </StyledFooter>
    );
  }

  function render() {
    const isInfoType = type === 'info';
    const isCompoundPopup = type === 'error' || type === 'critical' || isInfoType;

    return (
      <StyledInfo show={visible} size={isCompoundPopup && 'lg'} onHide={closeAction} backdrop="static">
        {text}

        {isCompoundPopup && renderContacts()}

        {type === 'error' ? renderAdditionalErrorSection() : renderButtons(isInfoType)}
      </StyledInfo>
    );
  }

  return render();
}

export default InfoPopup;

import {LOAD_CURRENT_USER, LOAD_USERS, LOAD_BILLING_INFO} from 'action_types/userActionTypes';

import initialState from './initialState';
import helper from './reducerHelper';

export interface UserState {
  list: User[];
  current?: User;
  billingInfo?: BillingInformation;
}

const userReducer = (state: UserState = initialState.user, action) => {
  return helper.handleActions<UserState>(state, action, {
    [LOAD_CURRENT_USER](state, payload: {user?: User}) {
      state.current = payload.user;
    },
    [LOAD_USERS](state, payload: {users: User[]}) {
      state.list = payload.users;
    },
    [LOAD_BILLING_INFO](state, payload: {billingInfo?: BillingInformation}) {
      state.billingInfo = payload.billingInfo;
    }
  });
};

export default userReducer;

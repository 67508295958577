import React from 'react';

import {Row, Col} from 'components/bootstrap';
import styled from 'styled-components/macro';

import DRAFT_STEP from 'constants/draftSteps';

import Button from 'components/common/Button';

import {mainContainerPadding} from 'styles/shared';

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.isFirstStep ? 'center' : 'space-between')};
  padding: 2rem ${mainContainerPadding};
  margin-bottom: 5rem;
`;

interface Props {
  currentStep: string;
  onCancel: () => void;
  onSave: () => void;
  overrideSaveButton: boolean;
  saveDraftAndSendToSupport: () => void;
}

function ButtonsRow({currentStep, onCancel, onSave, overrideSaveButton, saveDraftAndSendToSupport}: Props) {
  let isFirstStep = currentStep === DRAFT_STEP.CREATE_SHIPMENT;
  let isLastStep = currentStep === DRAFT_STEP.REVIEW_FINISH;

  let backButtonText = isLastStep ? 'Cancel Shipment' : 'Previous step';

  let saveButtonText = isLastStep ? 'Complete Shipment' : 'Save & Continue';

  return (
    <Row>
      <Col lg={6} sm={12}>
        <StyledButtonContainer isFirstStep={isFirstStep}>
          {!isFirstStep && (
            <Button type="secondary" onClick={onCancel}>
              {backButtonText}
            </Button>
          )}

          {overrideSaveButton && isLastStep ? (
            <Button autoWidth onClick={saveDraftAndSendToSupport}>
              Save & Send to Support
            </Button>
          ) : (
            <Button autoWidth onClick={onSave}>
              {saveButtonText}
            </Button>
          )}
        </StyledButtonContainer>
      </Col>
    </Row>
  );
}

export default ButtonsRow;

export default {
  getReceiverInfo
};

function getReceiverInfo(user?: User) {
  if (!user) return '';

  let receiver = user.name;
  if (user.email) receiver += `, ${user.email}`;
  if (user?.billingInformation?.osClientId) receiver += ` - CID ${user.billingInformation.osClientId}`;

  return receiver;
}

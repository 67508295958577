import React from 'react';
import {useSelector} from 'react-redux';
import {get} from 'lodash';

import {AppState} from 'reducers';

import ELECTRONIC_EXPORT_FILING from 'constants/electronicExportFilings';

import SelectInput from 'components/common/SelectInput/SelectInput';
import RadioWithTextInput from 'components/common/RadioWithTextInput';
import TextInput from 'components/common/TextInput';
import RadioInput from 'components/common/RadioInput/RadioInput';
import EEITooltip from 'components/tooltips/EEITooltip';
import ITNTooltip from 'components/tooltips/ITNTooltip';

interface Props {
  data: Products;
  withTooltip: boolean;
  errorPath: string;
  onChange: OnChangeHandler;
  errors: object;
}

function ElectronicExportFiling({data, withTooltip, errorPath, onChange, errors}: Props) {
  const ftrExemptions = useSelector((state: AppState) => state.shipmentOptions.ftrExemptions);

  const exportFilingOptions: BasicOption[] = [
    {value: ELECTRONIC_EXPORT_FILING.EXEMPTED, label: 'Yes - file as FTR 30.37 (a) exemption'},
    {value: ELECTRONIC_EXPORT_FILING.OTHER, label: 'Choose Other FTR exemption'},
    {value: ELECTRONIC_EXPORT_FILING.NO, label: 'No'}
  ];

  const itnNumberOptions = [
    {
      value: true,
      label: 'Yes',
      element: (
        <TextInput
          name="ITNNumber"
          placeholder="Type in ITN Nr."
          size="small"
          value={data.ITNNumber}
          onChange={onChange}
          error={get(errors, `${errorPath}.ITNNumber`, '')}
          isWarning={data.isItnNumberExists && !data.ITNNumber}
        />
      )
    },
    {
      value: false,
      label: 'No',
      element: (
        <TextInput
          name="EINNumber"
          placeholder="Type in EIN Nr."
          size="small"
          value={data.EINNumber}
          onChange={onChange}
          error={get(errors, `${errorPath}.EINNumber`, '')}
          isWarning={data.isItnNumberExists === false && !data.EINNumber}
        />
      )
    }
  ];

  function render() {
    const electronicExportFiling = data.electronicExportFiling;

    let otherExportFiling = electronicExportFiling === ELECTRONIC_EXPORT_FILING.OTHER;
    let noExportFiling = electronicExportFiling === ELECTRONIC_EXPORT_FILING.NO;

    const eeiTooltip = withTooltip ? EEITooltip() : undefined;

    return (
      <>
        <RadioInput
          name="electronicExportFiling"
          label="Electronic Export Filing"
          description="Are you exempted from filing an EEI (Electronic Export Information)?"
          tooltip={eeiTooltip}
          options={exportFilingOptions}
          value={electronicExportFiling}
          onChange={onChange}
        />

        {otherExportFiling && (
          <SelectInput
            name="foreignTradeRegulations"
            label="Foreign Trade Regulations (FTR) Exemptions"
            required={true}
            value={data.foreignTradeRegulations}
            options={ftrExemptions}
            onChange={onChange}
            error={get(errors, `${errorPath}.foreignTradeRegulations`, '')}
          />
        )}

        {noExportFiling && (
          <RadioWithTextInput
            name="isItnNumberExists"
            label="Do you have ITN Number?"
            required={true}
            options={itnNumberOptions}
            tooltip={ITNTooltip()}
            value={data.isItnNumberExists}
            onChange={onChange}
            error={get(errors, `${errorPath}.isItnNumberExists`, '')}
          />
        )}
      </>
    );
  }

  return render();
}

export default ElectronicExportFiling;

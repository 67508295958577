import React from 'react';
import styled, {css} from 'styled-components/macro';

import {colors, mainFont} from 'styles/shared';

const StyledActionLink = styled.div`
  display: inline;
  color: ${colors.orange_main};
  cursor: pointer;
  ${mainFont};

  ${props =>
    props.isInheritFont &&
    css`
      font-size: inherit;
      line-height: inherit;
    `}

  &:hover {
    text-decoration: underline;
  }
`;

interface Props extends BaseProps {
  title: string;
  onClick: () => void;
  isInheritFont?: boolean;
}

ActionLink.defaultProps = {
  isInheritFont: false
};

function ActionLink({title, onClick, isInheritFont, className}: Props) {
  return (
    <StyledActionLink onClick={onClick} isInheritFont={isInheritFont} className={className}>
      {title}
    </StyledActionLink>
  );
}

export default ActionLink;

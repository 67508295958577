import React from 'react';
import styled from 'styled-components';

import FEATURE from 'constants/features';

import uiHelper from 'helpers/uiHelper';

import Button from 'components/common/Button';
import ActionsPopup from 'components/common/ActionsPopup';
import Flex from 'components/common/Flex';

import {opacity, colors, secondaryFont} from 'styles/shared';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => (props.success ? colors.green_lighter : colors.white)};
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};
  border-radius: 0.8rem;
  padding: 0 2rem 0 1.6rem;
  margin: 2rem 0;
`;

const StyledIconContainer = styled.div`
  width: 7rem;
  margin-right: 4rem;
`;

const StyledText = styled.div`
  ${secondaryFont};
`;

const StyledAutoPayEnabled = styled.div`
  font-weight: bold;
  color: ${colors.green_main};
`;

const StyledActions = styled.div`
  display: flex;
  padding: 2rem 0;
`;

const StyledActionsPopup = styled(ActionsPopup)`
  > div {
    margin: 0 0 0 2.2rem;
  }
`;

const StyledVoidPayment = styled.div`
  cursor: pointer;
  margin-left: 2.6rem;
`;

interface Props {
  used: boolean;
  creditCard?: CreditCard;
  achAccount?: AchAccount;
  selectShipmentPaymentMethod: () => void;
  deletePaymentMethod: () => void;
}

function PaymentItem({used, creditCard, achAccount, selectShipmentPaymentMethod, deletePaymentMethod}: Props) {
  const isCreditCard = creditCard?.cardNumber ? true : false;

  function getActions(isAutoPay) {
    const actions: DropdownAction[] = [];

    if (isAutoPay) {
      actions.push({label: 'Cancel Autopay', action: uiHelper.notImplemented});
    } else {
      actions.push({label: 'Enroll in Autopay', action: uiHelper.notImplemented});
    }

    const paymentType = isCreditCard ? 'Card' : 'ACH';
    actions.push({label: `Delete ${paymentType}`, action: deletePaymentMethod});

    return actions;
  }

  function getButtonTitle() {
    let title = isCreditCard ? 'Use Card' : 'Use ACH';

    if (!used) return title;

    title = isCreditCard ? 'Card Used' : 'ACH Used';

    return title;
  }

  function getPaymentIcon(): string {
    if (!isCreditCard) return 'ach-large.png';

    switch (creditCard?.cardType) {
      case 'Visa':
        return 'visa-large.png';
      case 'MasterCard':
        return 'master-card-large.png';
      case 'AmericanExpress':
        return 'american-express-large.png';
      case 'ApplePay':
        return 'apple-pay-large.png';
      case 'Discover':
      case 'DinersClub':
        return 'discover-large.png';
      case 'JCB':
        return 'jcb-large.png';
      case 'PayPal':
        return 'pay-pal-large.png';
      default:
        return '';
    }
  }

  function renderPaymentDetails(isAutoPay) {
    const autoPay = <StyledAutoPayEnabled>AUTOPAY ENABLED</StyledAutoPayEnabled>;

    if (isCreditCard) {
      return (
        <StyledText>
          <div>Card ending in {creditCard?.cardNumber}</div>
          <div>Expires {creditCard?.expirationDate}</div>
          {isAutoPay && autoPay}
        </StyledText>
      );
    }

    return (
      <StyledText>
        <div>Acc # ...{achAccount?.routingNumber}</div>
        <div>Routing # ...{achAccount?.accountNumber}</div>
        {isAutoPay && autoPay}
      </StyledText>
    );
  }

  function renderAutopayActions(isAutoPay) {
    const actions = getActions(isAutoPay);

    return <StyledActionsPopup actions={actions} activeItem={{} as Draft} labelVisible={false} />;
  }

  function render() {
    const isAutoPay = false;

    const icon = getPaymentIcon();
    const buttonTitle = getButtonTitle();
    const buttonTheme = used ? 'success' : 'main';

    return (
      <StyledContainer success={isAutoPay}>
        <Flex align="center">
          <StyledIconContainer>
            <img src={`../images/credit_cards/${icon}`} alt="Payment Method" />
          </StyledIconContainer>

          {renderPaymentDetails(isAutoPay)}
        </Flex>
        <StyledActions>
          <Button autoWidth theme={buttonTheme} onClick={selectShipmentPaymentMethod}>
            {buttonTitle}
          </Button>

          {FEATURE.PAYMENT_AUTOPAY ? (
            renderAutopayActions(isAutoPay)
          ) : (
            <StyledVoidPayment onClick={deletePaymentMethod}>
              <img src="../images/credit_cards/void-payment.png" alt="Void Payment" />
            </StyledVoidPayment>
          )}
        </StyledActions>
      </StyledContainer>
    );
  }

  return render();
}

export default PaymentItem;

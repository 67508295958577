import React, {useState, useEffect} from 'react';
import {get, isEmpty} from 'lodash';
import {useSelector} from 'react-redux';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';

import ERROR from 'constants/literals/errors';
import SECTION from 'constants/sections';

import validationHelper from 'helpers/validationHelper';

import rateService from 'services/rateService';

import FormLabel from 'components/common/FormLabel';
import AlertComponent from 'components/common/AlertComponent';

import DomesticServices from 'components/common/DomesticRates';
import ExpressService from 'components/common/DomesticRates/components/ExpressService';

import {mainFont, colors} from 'styles/shared';

const StyledText = styled.div`
  margin-bottom: 2rem;
  ${mainFont};

  > span {
    color: ${colors.orange_main};
  }
`;

interface Props {
  carrierProduct: string;
  shipmentDate: any;
  isPickupRequired?: boolean;
  isImport: boolean;
  isDomestic: boolean;
  onChange: OnChangeHandler;
}

function PickupServices({carrierProduct, shipmentDate, isPickupRequired, isImport, isDomestic, onChange}: Props) {
  const rates = useSelector((state: AppState) => state.draft.rate.list);
  const rateError = useSelector((state: AppState) => state.draft.rate.error);
  const errors = useSelector((state: AppState) => state.draft.current.errors);

  const [rateIdWithLowerPrice, setRateIdWithLowerPrice] = useState<string>('');

  useEffect(() => {
    const rateIdLowerPrice = rateService.getRateIdWithLowerPrice(rates);
    setRateIdWithLowerPrice(rateIdLowerPrice || '');
  }, [rates]);

  function renderRateError() {
    const isCarrierProductError = get(errors, 'pickupDelivery.carrierProduct');

    if (!isCarrierProductError && !rateError && !isEmpty(rates)) return null;

    if (isEmpty(rates)) {
      return <AlertComponent header="No Delivery Services Available" text={ERROR.NO_DELIVERY_SERVICES_AVAILABLE} />;
    }

    const errorMessage: string = validationHelper.getInputError(isCarrierProductError);

    return <AlertComponent header="Warning!" text={errorMessage} />;
  }

  function render() {
    let expressServiceButtonDisabled = shipmentDate && isPickupRequired !== undefined ? false : true;
    if (isImport && shipmentDate) expressServiceButtonDisabled = false;

    return (
      <>
        <FormLabel id={SECTION.DRAFT.SERVICES}>When would you like it delivered?</FormLabel>

        <StyledText>
          The discounted Quotes below are for shipping charges. The estimates are based on the shipment information
          provided and are only valid for the set shipment date. Pickup, insurance, and other service fees may not be
          included in this quote.<span>*</span>
        </StyledText>

        {isDomestic ? (
          <DomesticServices
            rates={rates}
            carrierProduct={carrierProduct}
            isBtnDisabled={expressServiceButtonDisabled}
            rateIdWithLowerPrice={rateIdWithLowerPrice}
            isStandaloneQuote={false}
            onChange={onChange}
          />
        ) : (
          rates.map(rate => {
            const rateId = rate.id;
            const isLowerPrice = rateIdWithLowerPrice === rateId;

            return (
              <ExpressService
                key={rateId}
                service={rate}
                carrierProduct={carrierProduct}
                isBtnDisabled={expressServiceButtonDisabled}
                isDomestic={false}
                isLowerPrice={isLowerPrice}
                onChange={onChange}
              />
            );
          })
        )}

        {renderRateError()}
      </>
    );
  }

  return render();
}

export default PickupServices;

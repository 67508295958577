import axios from 'axios';
import {endsWith} from 'lodash';

import {store} from '../index';

import PATH from 'constants/path';

import config from '../config';
import uiHelper from './uiHelper';
import utils from './utils';

import apis from '../domain/apis';
import authService from '../services/authService';
import stateStorageService from '../services/stateStorageService';
import supportService from 'services/supportService';

export default {
  get: httpGet,
  post: httpPost,
  upload: httpUpload,
  patch: httpPatch,
  put: httpPut,
  delete: httpDelete,
  addRequiredParameters
};

function httpGet(url: string, queryParams, options = {}) {
  let axiosData = axios.get(`${getUrl(url)}${getQueryString(queryParams, options)}`, getDefaultRequestOptions(options));

  return processRequest(axiosData, options);
}

function httpPost(url: string, data, options = {}) {
  let request = axios.post(getUrl(url), getRequestBody(data, options), getDefaultRequestOptions(options));

  return processRequest(request, options);
}

function httpUpload(url: string, data, options = {}) {
  let request = axios.post(getNodeUrl(url), data, getDefaultRequestOptions(options));

  return processRequest(request, options);
}

function httpPut(url: string, data, options = {}) {
  let request = axios.put(getUrl(url), JSON.stringify(data), getDefaultRequestOptions(options));

  return processRequest(request, options);
}

function httpPatch(url: string, data, options = {}) {
  let request = axios.patch(getUrl(url), JSON.stringify(data), getDefaultRequestOptions(options));

  return processRequest(request, options);
}

async function httpDelete(url: string, data, options = {}) {
  let request = axios.delete(getUrl(url), getDefaultRequestOptions(options));

  return processRequest(request, options);
}

async function processRequest(axiosRequest, options) {
  try {
    let response = await axiosRequest;

    const requestId = response?.data?.['@_requestId'];

    const metadata = supportService.getMetadata(
      store.getState()?.user?.current,
      store.getState()?.draft?.current?.data?.id,
      store.getState()?.draft?.current?.isExistingDraft,
      store.getState()?.draft?.rate?.current?.id,
      store.getState()?.draft?.current?.step,
      requestId,
      response?.data?.['hydra:description']
    );

    if (requestId) stateStorageService.setRequestId(requestId);

    // if OK return
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      let result: any = response.data['hydra:member'] || response.data;

      if (!result && response.status === 204) return {success: true};

      if (options.totalItems) {
        return {
          items: result,
          totalItems: response.data['hydra:totalItems'] || result.length
        };
      }

      return result;
    }

    const status = response.status;

    if (options.acceptableErrorCodes && options.acceptableErrorCodes.includes(status)) {
      const responseData = response.data;

      const result: AcceptableErrorCodeResponse = {
        isHttpError: true,
        status,
        title: responseData?.['hydra:title'],
        description: responseData?.['hydra:description'],
        errorCode: responseData?.['hydra:errorCode'] || 'InvalidCredentials'
      };
      return result;
    }

    if (status === 401) {
      const refreshToken = authService.getRefreshToken();

      if (refreshToken) {
        await apis.auth.refreshToken(refreshToken);
        window.location.reload();
        return;
      }

      if (shouldRedirectToSignInPage(window.location.href)) {
        utils.setTimeout(() => {
          //save redirect url to cookies for redirection after sign-in
          authService.saveRedirectUrl(window.location.pathname);
          window.location.href = PATH.SIGN_IN;
        }, 1000);
      }
      return;
    }

    if (status === 403) {
      let errorMessage = '403 Forbidden Access ' + (response?.data?.['hydra:description'] ?? '');

      supportService.logToRollbar(errorMessage, metadata, store.getState().user, false, true);

      return;
    }

    if (status === 400 || status === 422 || status === 500) {
      let responseData = response.data;
      let hydraDescription = responseData?.['hydra:description'];
      let errorMessage = `Unhandled error status ${status} ` + (hydraDescription ?? '');

      supportService.logToRollbar(errorMessage, metadata, store.getState().user, false, true);

      if (responseData && hydraDescription) {
        throw new Error(hydraDescription);
      }
    }

    let errorMessage = `Unhandled HTTP response status ${status} ` + (response?.data?.['hydra:description'] ?? '');

    supportService.logToRollbar(errorMessage, metadata, store.getState().user, false, true);

    throw new Error(`It looks like there may have been a problem.`);
  } catch (err: any) {
    uiHelper.showError(err);
    throw err;
  }
}

function getUrl(url) {
  return `${config.baseUrl}${url}`;
}

function getNodeUrl(url) {
  return `${config.baseNodeUrl}${url}`;
}

function getQueryString(params, options) {
  if ((!params || !Object.keys(params).length) && (!options || !Object.keys(options).length)) return '';

  const authContext: AuthContext = authService.getAuthContext();

  if (authContext) {
    if (authContext?.isCsrPersonalAccount) {
      const isCreatedByCSR = stateStorageService.isCreatedByCSR();

      if (isCreatedByCSR) {
        params.createdBy = authContext.creatorId;
      } else {
        params.csrCreated = 1;
      }
    } else {
      params.ownedBy = authContext.ownerId;
    }
  }

  if (options.noCache) {
    params.time = Date.now();
  }

  const esc = encodeURIComponent;

  let query = '?';

  query += Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');

  return query;
}

function getRequestBody(data, options) {
  let authContext: AuthContext = authService.getAuthContext();

  if (!authContext) return JSON.stringify(data);

  let body: any = {
    ...data,
    ownedBy: `/users/${authContext.ownerId}`,
    createdBy: `/users/${authContext.creatorId}`
  };

  if (options.useHouseAccount) {
    body.useHouseAccount = authService.isHouseAccountUsed();
  }

  return JSON.stringify(body);
}

function addRequiredParameters(data, options) {
  if (data === null) return null;

  let authContext: AuthContext = authService.getAuthContext();

  if (!authContext) return data;

  let body: any = {
    ...data,
    ownedBy: `/users/${authContext.ownerId}`,
    createdBy: `/users/${authContext.creatorId}`
  };

  if (options.useHouseAccount) {
    body.useHouseAccount = authService.isHouseAccountUsed();
  }

  return body;
}

function getDefaultRequestOptions(options) {
  let contentType = config.useStubs ? 'application/json' : 'application/ld+json';

  let requestOptions: any = {
    headers: {
      'Content-Type': contentType,
      accept: contentType
    },
    validateStatus: status => true,
    credentials: 'same-origin'
  };

  const authorization = options.auth !== false ? true : false;

  if (authorization) {
    requestOptions.headers.Authorization = getAuthHeader();
  }

  return requestOptions;
}

function getAuthHeader() {
  let jwt = authService.getToken();
  return `Bearer ${jwt}`;
}

//check if url belongs to public page
function shouldRedirectToSignInPage(href) {
  const pageNeedAuth = path => {
    return !endsWith(href, path);
  };

  const shouldRedirect = pageNeedAuth(PATH.SIGN_IN) && pageNeedAuth(PATH.RESET_PASSWORD);
  return shouldRedirect;
}

import React, {useState} from 'react';
import {Row, Col} from 'components/bootstrap';

import ERROR from 'constants/literals/errors';
import COMMON from 'constants/common';
import FEATURE from 'constants/features';
import MESSAGE from 'constants/literals/messages';

import AppIcon from 'components/common/AppIcon/AppIcon';
import FormHeader from 'components/common/FormHeader';
import AlertComponent from 'components/common/AlertComponent';
import ActionLink from 'components/common/ActionLink';
import Flex from 'components/common/Flex';
import Disclaimer from 'components/common/Disclaimer';
import TotalSumDetailsModal from './components/TotalSumDetailsModal';

import * as styled from './TotalSum.styled';
import formatHelper from 'helpers/formatHelper';

interface Props extends BaseProps {
  rate?: Rate;
  isDomestic?: boolean;
  isUSTerritories?: boolean;
  error?: string;
  isIncompleteRate: boolean;
}

function TotalSum({rate, isDomestic, isUSTerritories, isIncompleteRate, error, className}: Props) {
  const [disclaimerVisible, setDisclaimerVisible] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);

  const seeDetailsLinkVisible = !isIncompleteRate && !isUSTerritories;

  function toggleDisclaimer() {
    setDisclaimerVisible(!disclaimerVisible);
  }

  function toggleDetails() {
    setDetailsVisible(!detailsVisible);
  }

  function renderFooter() {
    return (
      <styled.footer>
        {FEATURE.CLIENT_DISCOUNT_MESSAGE && (
          <styled.text>
            <span>*</span>OptimalShip Client’s get discounts up to 60%!
          </styled.text>
        )}

        <Flex>
          {seeDetailsLinkVisible && (
            <>
              <styled.asterisk>*</styled.asterisk>
              <ActionLink title="See Details" onClick={toggleDetails} />
              <styled.separator />
            </>
          )}

          <ActionLink title="Quote Disclaimer" onClick={toggleDisclaimer} />
        </Flex>
      </styled.footer>
    );
  }

  function renderTotal(displayTotalCharge: string) {
    return (
      <>
        <FormHeader>{MESSAGE.TOTAL_CHARGE}:</FormHeader>
        <styled.total>{displayTotalCharge}
        {seeDetailsLinkVisible && <styled.asterisk>*</styled.asterisk>}
        </styled.total>
      </>
    );
  }

  function renderSavings(displaySavings: string) {
    if (!rate?.isSavingsVisible) return null;

    return (
      <>
        <FormHeader>{MESSAGE.SAVINGS}:</FormHeader>
        <styled.amountContainer>{displaySavings}</styled.amountContainer>
      </>
    );
  }

  function render() {
    let displayShippingCharge = rate && !isIncompleteRate ? formatHelper.displayDollarAmount(rate?.displayOptimalRate) : '$00.00';

    let displayTotalCharge = rate && !isIncompleteRate ? formatHelper.displayDollarAmount(rate?.displayRetailRate) : '$00.00';

    const displaySavingsValue =
      rate && !isIncompleteRate ? (rate?.savings ? rate.savingsPercent : COMMON.EMPTY_RATE) : '0';
    const displaySavings = FEATURE.CLIENT_DISCOUNT_MESSAGE ? `${displaySavingsValue}%*` : `${displaySavingsValue}%`;

    return (
      <styled.innerContainer className={className}>
        <Row>
          <Col>
            <FormHeader>{MESSAGE.SHIPPING_CHARGE}:</FormHeader>

            <styled.amountContainer>{displayShippingCharge}</styled.amountContainer>

            {renderTotal(displayTotalCharge)}
            {renderSavings(displaySavings)}
          </Col>
          <styled.imageContainer>
            <AppIcon name="money" />
          </styled.imageContainer>
        </Row>

        {error && <AlertComponent header={ERROR.RATE_ERROR_HEADER} text={error} />}

        {renderFooter()}

        {disclaimerVisible && (
          <Disclaimer visible={disclaimerVisible} close={toggleDisclaimer} isDomestic={isDomestic ? true : false} />
        )}

        {detailsVisible && (
          <TotalSumDetailsModal
            visible={detailsVisible}
            rate={rate}
            displayShippingCharge={displayShippingCharge}
            displaySavings={displaySavings}
            displayTotalCharge={displayTotalCharge}
            close={toggleDetails}
          />
        )}
      </styled.innerContainer>
    );
  }

  return render();
}

export default TotalSum;

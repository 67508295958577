import httpHelper from 'helpers/httpHelper';

import authService from 'services/authService';

export default {
  fullAccessSignIn,
  quickAccessSignIn,
  instantSignUp,
  signUp,
  guestSignIn,
  refreshToken,
  resetPassword,
  changePassword
};

async function fullAccessSignIn(user: {
  email: string;
  password: string;
}): Promise<PasswordAuthResponse | AcceptableErrorCodeResponse> {
  return await httpHelper.post('/authentication_token', user, {auth: false, acceptableErrorCodes: [401, 422]});
}

async function quickAccessSignIn(accountNumber: string): Promise<QuickAccessResponse | AcceptableErrorCodeResponse> {
  return await httpHelper.post(
    '/account_auth_token_actions',
    {accountNumber},
    {auth: false, acceptableErrorCodes: [401, 422]}
  );
}

async function instantSignUp(userData: SignUptDto): Promise<SignUpResponse> {
  const body: SignUpRequest = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    phoneNumber: userData.phoneNumber,
    phoneNumberExtension: userData.phoneNumberExtension,
    email: userData.email,
    password: userData.password
  };

  return await httpHelper.post('/instant_sign_up_actions', body, {auth: false});
}

async function signUp(body): Promise<SuccessfullResponse> {
  return await httpHelper.post('/sign_up_actions', body, {auth: false});
}

async function guestSignIn(): Promise<GuestAuthResponse> {
  return await httpHelper.post('/guest_auth_token_actions', {}, {auth: false});
}

async function refreshToken(token: string): Promise<void> {
  //clear refresh token before proceed
  authService.saveRefreshToken(null);

  const response: RefreshTokenResponse = await httpHelper.post(
    '/authentication_token/refresh',
    {refresh_token: token},
    {auth: false, acceptableErrorCodes: [401]}
  );

  if (response?.token) authService.saveToken(response.token);
  if (response?.refresh_token) authService.saveRefreshToken(response.refresh_token);
}

function resetPassword(emailOrId: string): Promise<ResetPasswordResponse> {
  const body = {
    emailOrId
  };

  return httpHelper.post('/start_password_recovery_actions', body, {auth: false});
}

function changePassword(password: string): Promise<ResetPasswordResponse> {
  const body = {
    password,
    recoveryCode: authService.getRecoveryCode()
  };

  return httpHelper.post('/complete_password_recovery_actions', body);
}

import React from 'react';

import {colors} from 'styles/shared';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" viewBox="0 0 44 44">
      <path fill={colors.orange_main} d="M23 30h8v6l-8-6z"></path>
      <path
        fill={colors.orange_main}
        fillRule="evenodd"
        d="M11 12a2 2 0 00-2 2v16a2 2 0 002 2h22a2 2 0 002-2V14a2 2 0 00-2-2H11zm4.5 8.333c-.898 0-1.625.728-1.625 1.625v.084a1.625 1.625 0 003.25 0v-.084c0-.897-.727-1.625-1.625-1.625zm4.875 1.625a1.625 1.625 0 013.25 0v.084a1.625 1.625 0 01-3.25 0v-.084zm8.125-1.625c-.898 0-1.625.728-1.625 1.625v.084a1.625 1.625 0 003.25 0v-.084c0-.897-.727-1.625-1.625-1.625z"
        clipRule="evenodd"></path>
    </svg>
  );
}

export default Icon;

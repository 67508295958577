import React from 'react';
import styled, {css} from 'styled-components/macro';
import Flatpickr from 'react-flatpickr';

import validationHelper from 'helpers/validationHelper';

import FormLabelContainer from 'components/common/InputComponents/FormLabelContainer';
import InputFooter from 'components/common/InputComponents/InputFooter';

import inputStyles from 'styles/customization/input';
import {colors, minInputContainerHeight, defaultInputHeight} from 'styles/shared';

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => (props.small ? '0.6rem 0' : '2rem 0')};
  min-height: ${props => (props.small ? '' : minInputContainerHeight)};

  .flatpickr-input {
    height: ${props => (props.small ? '3.6rem' : defaultInputHeight)};
    ${inputStyles.common.inputField};

    /* display calendar icon */
    background: url(/images/calendar.png) no-repeat scroll;
    background-position: 100% 50%;

    ${props =>
      props.error &&
      css`
        border-color: ${colors.red_main};
      `}
  }
`;

interface Props extends BaseProps {
  name: string;
  label?: string;
  onChange: OnChangeHandler;
  placeholder?: string;
  value?: any;
  error?: string | ValidationError[];
  disabled?: boolean;
  required?: boolean;
  size?: 'small' | 'big';
  tooltip: string | JSX.Element;
  actionLink?: ActionLink;
  dateFormat?: string;
}

DatePicker.defaultProps = {
  required: false,
  size: 'big',
  tooltip: '',
  actionLink: {
    title: '',
    action: null
  },
  className: '',
  dateFormat: 'Y.m.d'
};

function DatePicker({
  name,
  label,
  onChange,
  placeholder,
  value,
  error,
  disabled,
  required,
  size,
  tooltip,
  actionLink,
  className,
  dateFormat
}: Props) {
  const isSmallSize = size === 'small';

  const inputError: string = validationHelper.getInputError(error);

  const options = {
    minDate: 'today',
    dateFormat
  };

  return (
    <StyledInputContainer small={isSmallSize} error={inputError} className={className}>
      {label && (
        <FormLabelContainer label={label} name={name} required={required} disabled={disabled} tooltip={tooltip} />
      )}

      <Flatpickr
        value={value ? value : ''}
        options={options}
        placeholder={placeholder}
        onChange={date => {
          onChange(name, date[0]);
        }}
      />

      <InputFooter inputError={inputError} actionLink={actionLink} />
    </StyledInputContainer>
  );
}

export default DatePicker;

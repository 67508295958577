import phoneHelper from "helpers/phoneHelper";

export default function adaptPhoneInputForAddressesApi(countryCode: string, phoneNumber?: string, extension?: string, dialCode?: string, isImport?: boolean): Phone | null {
  if (!phoneNumber) return null;

  try {
    if (!isImport) {
      countryCode = phoneHelper.getCountryCodeFromValidNumber(phoneNumber) ?? countryCode
    } else if (dialCode) {
      phoneNumber = phoneHelper.getValidNumberWithDialCode(phoneNumber, '', dialCode);
      if (phoneNumber) countryCode = phoneHelper.getCountryCodeFromValidNumber(phoneNumber) ?? countryCode;
    } else {
      phoneNumber = phoneHelper.getValidNumberWithDialCode(phoneNumber, countryCode);
    }

    if (phoneNumber) {
      const requestPhone: Phone = {
        number: phoneNumber,
        regionCode: countryCode,
        extension: extension
      };
      return requestPhone;
    }
  } catch {}

  return null;
}
